<div class="seccion">
  <h2 class="titulo-seccion">{{ "noticias.noticias" | translate }}</h2>
  <h3 class="titulo-small">{{ "noticias.sloganRevista" | translate }}</h3>
  <p class="parrafo-seccion">
    {{ "noticias.noticiasDesc" | translate }}
  </p>
  <!-- contenedor de la sección -->
  <div class="row" *ngFor="let revista of revistas | async">
    <div class="col-md-3">
      <img class="imagen-revista" [src]="revista.imagen" />
    </div>
    <div class="col-md-7">
      <h2 class="subtitulo-revista">{{ revista.titulo }}</h2>
      <h3 class="titulo-small">
        {{ revista.fechaCreacion!.seconds * 1000 | date: "mediumDate" }}
      </h3>
      <p class="parrafo-revista">
        {{ revista.descripcion }}
      </p>
    </div>
    <div class="col-md-2">
      <button class="btn btn-warning btn-block" (click)="verRevista(revista)">
        {{ "noticias.btnVer" | translate }}
      </button>
    </div>
  </div>

  <app-btn-registro></app-btn-registro>
</div>
