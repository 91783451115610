export const maquinariaEquiposTec: string[] = [
  "Ninguno",
  "Ahoyador",
  "Arrastrador de Troncos",
  "Aserrío Portatil",
  "Barrenador Forestal",
  "Barreno para Suelo",
  "Cargador Estacionario",
  "Cinta Diamétrica",
  "Cinta Métrica",
  "Clinómetro",
  "Cortador de Ramas",
  "Cosechadora con Cadena",
  "Cosechadora con Rueda",
  "Descortezador",
  "Dron",
  "Equipo para Trepa de Árboles",
  "Forcícula para Diámetro",
  "Fumigadora de Espalda",
  "GPS",
  "Guadaña",
  "Hipsómetro Digital",
  "Lupa",
  "Lidar Terrestre",
  "Lidar Aereo",
  "Máquina Forestal Giratoria",
  "Medidor de Humedad del Suelo",
  "Motosierra",
  "Nivel Abney",
  "Serrucho Curvo de Poda",
  "Serrucho para Poda",
  "Taladores Apiladores con Cadena",
  "Taladores Apiladores con Rueda",
  "Telémetro",
  "Transportador de Troncos",
  "Trimmer Podadora de Árboles",
];
