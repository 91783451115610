import { Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "@angular/fire/auth";
import { firstValueFrom, Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { WtReport } from "src/app/woodtracer/model/wt-report";
import { ReportDetailsComponent } from "src/app/woodtracer/report-details/report-details.component";
import { WtReportService } from "src/app/woodtracer/service/wt-report.service";

/**
 * Describe los reportes creados por un usuario.
 */
export interface ReportsByUser {
  /** ID del "Usuario" de la app WoodTracer que creó los reportes. */
  wtUserId: string;
  /** Array con los reportes creados por el usuario. */
  wtReports: WtReport[];
}

/**
 * Este componente muestra un listado de WtReport agrupados por WtUserId ('técnico'),
 * para un usuario 'entidad' de la app WoodTracer.
 *
 * Cómo lo hace: La consulta obtiene todos los reportes existentes para poder calcular
 * los totales, tal como el datagrid los requiere. Adicionalmente carga para cada grupo
 * los datos de la licencia y el WtUser asociado, a través de pipes.
 *
 * @dev Esta no es la estrategia más óptima en cuanto al número de consultas a la base
 * de datos. Debería consultarse los 'técnicos' y tener allí unos acomuladores con los
 * números de reportes que haya creado (que se calcularían en el evento onCreate de las
 * funciones). Se deja por lo pronto, para poder cumplir con los deadlines.
 */
@Component({
  selector: "app-reportes",
  templateUrl: "./reportes.component.html",
  styleUrls: ["./reportes.component.css"],
})
export class ReportesComponent {
  /** Observable con los reportes agrupados por 'técnico'. */
  public allReportsByUser$!: Observable<ReportsByUser[]>;

  /** Acomulador con el total de reportes hechos por todos los 'técnicos'. */
  public totalReports = 0;

  constructor(
    protected crud: WtReportService,
    private modal: NgbModal,
    private authService: AuthService
  ) {
    // Cargar un los WtReports agrupados por 'técnico' para la 'entidad'.
    this.allReportsByUser$ = this.authService.authStateChanged().pipe(
      switchMap((user: User | null) => {
        this.crud.paginate({
          paginate: "infinite",
          sorts: [{ field: "localId", direction: "desc" }],
          filters: [
            { field: "wtCompanyId", queryOperator: "==", value: user?.uid },
          ],
        });

        return this.crud.collection$;
      }),
      map((reports: WtReport[]) =>
        reports.reduce((groups: { [key: string]: WtReport[] }, report) => {
          if (!groups[report.wtUserId]) {
            groups[report.wtUserId] = [];
          }
          groups[report.wtUserId].push(report);
          return groups;
        }, {})
      ),
      map((groups: { [key: string]: WtReport[] }) =>
        Object.keys(groups).map((key) => {
          const reportsByUser: ReportsByUser = {
            wtUserId: key,
            wtReports: groups[key],
          };
          return reportsByUser;
        })
      ),
      tap({
        next: (reports: ReportsByUser[]) => {
          if (reports) {
            if (reports.length > 0) {
              this.totalReports = reports.length;
            }
          }
        },
        error: (err) => console.error(err),
      })
    );
  }

  /**
   * Abre el modal que muestra los reportes asociados al usuario.
   * Se pasa el ID del usuario como parámetro, para que el contenido del modal sea reactivo.
   * Si se pasan simplemente los reportes ya cargados, en caso de que se cargue algún nuevo
   * reporte, no va a poder evidenciarse el cambio en el modal.
   *
   * @param wtUserId
   */
  public openReportModal(wtUserId: string): void {
    const dialog = this.modal.open(ReportDetailsComponent, {
      scrollable: true,
      size: "xl",
    });

    dialog.componentInstance.currentId = wtUserId;

    firstValueFrom(dialog.componentInstance.closeEventEmitter)
      .then(() => {
        dialog.close();
      })
      .catch((error) => console.error(error));
  }
}
