import { Injectable } from "@angular/core";
import {
  Storage,
  StorageReference,
  UploadTask,
  UploadTaskSnapshot,
  fromTask,
  getDownloadURL,
  percentage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "@angular/fire/storage";
import { Observable, Subject, Subscription, finalize, from } from "rxjs";
import { RegistroFiles } from "../models/RegistroFiles";
import { ResponseCurrentUpload } from "../models/storage.interface";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  currentUploads: Subject<ResponseCurrentUpload[]> = new Subject();

  constructor(private readonly storage: Storage) {}

  /**
   * Returns a Promise that resolves to a signed URL for downloading a file from Firebase Storage.
   *
   * @param pathToStorage - The path to the file in Firebase Storage, including the filename.
   * @returns A Promise that resolves to a signed URL for downloading the file.
   */
  async getStoreUrlImagePromise(pathToStorage: string): Promise<string> {
    const fileRef: StorageReference = ref(this.storage, pathToStorage);
    return await getDownloadURL(fileRef);
  }

  /**
   * Returns a Observable that resolves to a signed URL for downloading a file from Firebase Storage.
   *
   * @param pathToStorage - The path to the file in Firebase Storage, including the filename.
   * @returns A Observable that resolves to a signed URL for downloading the file.
   */
  getStoreUrlImageObservable(pathToStorage: string): Observable<string> {
    const fileRef: StorageReference = ref(this.storage, pathToStorage);
    return from(getDownloadURL(fileRef));
  }

  uploadFile(
    filePath: string,
    file: File
  ): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    const refStorage: StorageReference = ref(this.storage, filePath);
    const task: UploadTask = uploadBytesResumable(refStorage, file);

    const sub$: Subscription = fromTask(task)
      .pipe(
        finalize(() => {
          sub$.unsubscribe();
        })
      )
      .subscribe();

    return percentage(task);
  }

  uploadFilePercent(
    filePath: string,
    file: File,
    name: string
  ): ResponseCurrentUpload {
    const refStorage: StorageReference = ref(this.storage, filePath);
    const task: UploadTask = uploadBytesResumable(refStorage, file);

    return {
      currentUploads: percentage(task),
      name,
    };
  }

  uploadMany(files: RegistroFiles, uid: string): void {
    const obs: ResponseCurrentUpload[] = [];

    for (const [key, value] of Object.entries(files)) {
      const file: File = value.file;
      const filePath: string = `registerUser/${uid}/${key}`;
      obs.push(this.uploadFilePercent(filePath, file, key));
    }

    this.currentUploads.next(obs);
  }

  async deleteFile(filePath: string): Promise<void> {
    try {
      const refStorage: StorageReference = ref(this.storage, filePath);
      await deleteObject(refStorage);
    } catch (e) {
      console.error(e);
    }
  }
}
