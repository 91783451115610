import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { CrearProductoComponent } from "../../crear-producto.component";
import { BaseFormComponent } from "../base-form.component";
import { monedaLista } from "src/assets/data/monedas";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";

@Component({
  selector: "app-bonos-carbono",
  templateUrl: "./bonos-carbono.component.html",
  styleUrls: ["./bonos-carbono.component.css"],
})
export class BonosCarbonoComponent extends BaseFormComponent implements OnInit {
  public listaMonedas: string[] = monedaLista;

  constructor(
    public override crearProductoComponent: CrearProductoComponent,
    public override authService: AuthService,
    public override userService: UserService,
    public override formBuilder: FormBuilder,
    public override baseFormService: BaseFormService
  ) {
    super(
      crearProductoComponent,
      authService,
      userService,
      formBuilder,
      baseFormService
    );
  }

  override ngOnInit(): void {
    this.addControls();
    this.updateAllLocations();
  }

  addControls(): void {
    // se agregan controls para formulario equipo-maquinaria

    const fields: string[] = ["fechaFabricacion"];

    const fieldsToRm: string[] = [
      "altura",
      "grosor",
      "alturaBolsa",
      "calibreBolsa",
      "cantidadMinimaVenta",
    ];
    fields.map((field: string) =>
      this.productoForm.addControl(field, this.formBuilder.control(""))
    );

    // controls a eliminar
    fieldsToRm.map((field: string) => this.productoForm.removeControl(field));

    this.visualizar && this.baseFormService.completForm.next(this.productoForm);
  }
}
