import { ValidatorFn } from "@angular/forms";
import { ValidatorError } from "../enums/ValidatorError";

export const mustMatch = (
  controlName: string,
  matchingControlName: string
): ValidatorFn => {
  return (formGroup: any): any => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors["mustMatch"]) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ [ValidatorError.MustMatch]: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
};
