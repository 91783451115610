import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  NgbModal,
  NgbModalConfig,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import {
  firstValueFrom,
  fromEvent,
  Observable,
  PartialObserver,
  Subject,
  zip,
} from "rxjs";
import { debounceTime, map, takeUntil, tap } from "rxjs/operators";
import { categoriasLista } from "src/assets/data/categorias";
import { paises } from "src/assets/data/paises";
import { UserType } from "../../enums/UserTypes";
import { ProductoData } from "../../models/ProductoData";
import { RegistroFilesModel } from "../../models/RegistroFiles";
import { deepCopy } from "../../utils/deepCopy";
import { CambioCorreoComponent } from "./cambio-correo/cambio-correo.component";
import { UserData, UserService } from "src/app/services/user.service";
import { TypeSave } from "src/app/enums/typeSave";
import { UsersAdminService } from "src/app/services/users-admin.service";
import { AuthService } from "src/app/services/auth.service";
import {
  ProductData,
  ProductoService,
} from "src/app/services/producto.service";
import { SubscriptionService } from "src/app/services/subscriptions.service";
import { PermisosService } from "src/app/services/permisos.service";
import { EspeciesService } from "src/app/services/especies.service";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import { Rol } from "src/app/models/rol.interface";
import { Especie } from "src/app/models/especie.interface";
import moment from "moment";
import { SuscripcionActiva } from "src/app/models/subscripcion.interface";

export type UserAdminState = UserData & {
  isEdited: boolean;
  apellidos: string;
  email: string;
  numeroDocumento: string;
  id: string;
  especiesHabilitadas: Array<string>;
  entidades: string[];
  fecha: any;
  suscripcion: boolean;
};

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.css"],
})
export class UsuariosComponent implements AfterViewInit, OnDestroy {
  // referencia elementos dom
  @ViewChild("buscador", { static: true }) buscador!: ElementRef;
  @ViewChild("closebutton", { static: true }) closebutton!: ElementRef;
  @ViewChild("loadingModal") loadingModal!: ElementRef;
  @ViewChild("modalUsuario") userModal!: ElementRef;

  // Users Data
  users: { [userId: string]: UserAdminState } = {};
  usersList: UserAdminState[] = [];
  public currentPage: number = 1;
  public itemsPerPage: number = 20;
  public categorias: string[] = categoriasLista;
  public personalDataForm!: FormGroup;
  public productInfoForm!: FormArray<FormControl<string | null>>;
  public productDetailForm!: FormGroup;
  public productCategoriesForm!: FormArray;

  // valores Selects
  especieOpciones: Especie[] = [];

  // TODO - arreglar esto
  sistemas: string[] = [
    "Cercas Vivas",
    "Sistema Agroforestal",
    "Sistema Silvopastoril",
    "Plantación Forestal",
    "Arboles Aislados",
    "Bosque natural",
  ];

  // variables países
  paises = paises;
  listaPaisesUsuario: string[] = Object.keys(this.paises);
  listaPaisesProducto: string[] = Object.keys(this.paises);
  listaDepartamentoUsuario: string[] = [];
  listaDepartamentoProducto: string[] = [];
  listaMunicipiosProducto: string[] = [];
  listaMunicipiosUsuario: string[] = [];

  // variables temporales
  userToEdit: UserAdminState | undefined;
  productToEdit: ProductoData | undefined;
  listaProductos: ProductoData[] = [];
  showProductForms = false;
  page!: number;
  inicioSubscripcion: Date | undefined;
  finSubscripcion!: Date;
  // TODO - Exportar como enum.
  typeSave = TypeSave;
  tipoGuardar: TypeSave = TypeSave.usuario;
  archivos!: RegistroFilesModel;
  roles: Rol[] = [];
  paginas!: number[];
  modalLoader: any;
  totalPaginas!: number;
  estado: boolean | null = null;
  searcherValue!: string | undefined;

  private unsubscriber: Subject<boolean> = new Subject();

  constructor(
    public usersAdminService: UsersAdminService,
    public authSvc: AuthService,
    private formBuilder: FormBuilder,
    private productoService: ProductoService,
    private userService: UserService,
    private suscripcionSvc: SubscriptionService,
    public rolesSvc: PermisosService,
    private modalService: NgbModal,
    public config: NgbModalConfig,
    private especiesService: EspeciesService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.getEspecies();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

  async getEspecies(): Promise<void> {
    try {
      this.especieOpciones = (await this.especiesService.getAll()) as Especie[];
    } catch (e) {
      console.error(e);
    }
  }

  // Procedimiento que lista los roles de usuarios
  getRoles(): void {
    this.rolesSvc
      .getRoles()
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (roles: DocumentData) => {
            this.roles = roles as Rol[];
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  // Procedimiento que lista los usuarios
  async getUsers(): Promise<void> {
    try {
      if (this.currentPage < 1 || this.currentPage > this.totalPaginas) {
        return;
      }
      this.load();
      const httpsCallResult = await this.usersAdminService.getUsers(
        this.itemsPerPage,
        this.currentPage,
        this.estado,
        this.searcherValue
      );
      const resultUSers = httpsCallResult.data;
      this.totalPaginas = +resultUSers.paginas || 1;

      this.getButtonsPaginate(2, resultUSers.paginas);
      const usuarios = await Promise.all(
        resultUSers.usuarios.map(async (user: any) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          const time = user?.tiempoCreado
            ? user?.tiempoCreado["_seconds"]
            : null;
          if (time) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const seconds = user.tiempoCreado["_seconds"];
            user.fecha = moment(new Date(seconds * 1000)).format("ll");
          }

          const suscripcionActiva: SuscripcionActiva =
            await this.suscripcionSvc.getActiveSubscription(user.id);
          const suscrito: boolean = suscripcionActiva?.suscripcionActual
            ? true
            : false;

          return {
            ...user,
            isEdited: false,
            suscripcion: suscrito,
          };
        })
      );

      this.users = usuarios.reduce(
        (previousValue: { [userId: string]: UserAdminState }, user: any) => {
          previousValue[user.id] = user;
          return previousValue;
        },
        {}
      );

      this.setUsersList(this.users);
      this.load(true);
    } catch (e) {
      this.load(true);
      console.error(e);
    }
  }

  // Procedimiento para seleccionar usuario a editar y setear datos del mismo en los formularios
  // user: usuario seleccionado a editar
  async editUser(user: UserAdminState): Promise<void> {
    try {
      this.personalDataForm.reset();
      this.userToEdit = user;
      this.setData(this.userToEdit);
      this.archivos = {};

      await this.getFiles(this.userToEdit.id);

      if (this.userToEdit.tipo?.includes(UserType.Vendedor)) {
        this.setAllowSpecies();
        this.setCategorias();
        this.getProducts(this.userToEdit.productos);
        this.showProductForms = true;
      } else {
        this.showProductForms = false;
        this.listaProductos = [];
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Procedimiento para setear valores en formularios
  // user: datos de usuario
  setData(user: UserAdminState): void {
    user.tipo = typeof user.tipo === "string" ? [user.tipo] : user.tipo;
    this.personalDataForm.patchValue(user);
  }

  // Procedimiento para setear categorias habilitadas del usuario
  setCategorias(): void {
    this.productCategoriesForm.clear();
    if (this.userToEdit?.categoryProductUser?.length) {
      this.userToEdit.categoryProductUser?.forEach((item) => {
        const formControl = this.formBuilder.control(item);
        this.productCategoriesForm.controls.push(formControl);
      });
    }
  }

  // Procedimiento para setear especies habilitadas del usuario
  setAllowSpecies(): void {
    this.productInfoForm.clear();
    const especiesHabilitadas: string[] =
      this.userToEdit?.especiesHabilitadas || [];
    especiesHabilitadas.forEach((item) => {
      const formControl: FormControl<string | null> =
        this.formBuilder.control(item);
      this.productInfoForm.controls.push(formControl);
    });
  }

  // Procedimiento instanciar formularios usados
  initForms(): void {
    this.personalDataForm = this.formBuilder.group({
      nombres: ["", Validators.required],
      numerodocumento: ["", Validators.required],
      rLegal: [""],
      tamano: ["", Validators.required],
      pais: ["Colombia", Validators.required],
      departamento: ["", Validators.required],
      municipio: ["", Validators.required],
      email: [""],
      telefono: ["", Validators.required],
      direccion: ["", Validators.required],
      activo: [false, Validators.required],
      tipo: [[], Validators.required],
      tiempoActivado: [],
    });
    this.personalDataForm.get("email")?.disable();

    this.productInfoForm = this.formBuilder.array<string | null>([]);
    this.productCategoriesForm = this.formBuilder.array([]);

    this.productDetailForm = this.formBuilder.group({
      categoria: ["", Validators.required],
      pais: ["Colombia", Validators.required],
      departamento: ["", Validators.required],
      municipio: ["", Validators.required],
      especie: ["", Validators.required],
      sistema: [""],
      sello: [false, Validators.required],
    });

    const paisesTmp: any = { ...this.paises };

    this.personalDataForm.valueChanges.subscribe((value) => {
      const { pais, departamento } = value;
      if (pais) {
        this.listaDepartamentoUsuario = pais
          ? Object.keys(paisesTmp[pais])
          : [""];

        if (departamento)
          this.listaMunicipiosUsuario = pais
            ? paisesTmp[pais][departamento]
            : [""];
      }
    });

    this.productDetailForm.valueChanges.subscribe((value) => {
      const { pais, departamento } = value;
      if (pais) {
        this.listaDepartamentoProducto = pais
          ? Object.keys(paisesTmp[pais])
          : [""];

        if (departamento)
          this.listaMunicipiosProducto = pais
            ? paisesTmp[pais][departamento]
            : [""];
      }
    });
  }

  // Procedimiento inicializar observer de buscador
  inicializarBuscador(): void {
    const observer: PartialObserver<string> = {
      next: (value: string) => {
        if (value) {
          this.searcherValue = value;
          this.currentPage = 1;
          this.getUsers();
        } else {
          this.searcherValue = undefined;
          this.getUsers();
        }
      },
      error: (err) => console.warn(err),
    };

    fromEvent(this.buscador?.nativeElement, "keyup")
      .pipe(
        map((srcEl: any) => srcEl?.srcElement?.value),
        debounceTime(1000)
      )
      .subscribe(observer);
  }

  ngAfterViewInit() {
    this.inicializarBuscador();
    this.getRoles();
    this.initForms();
    this.getUsers();
  }

  setUsersList(
    datos: UserAdminState[] | { [userId: string]: UserAdminState },
    parsear: boolean = true
  ): void {
    this.usersList = parsear
      ? (Object.values(datos) as UserAdminState[])
      : (datos as UserAdminState[]);
  }

  getProducts(ids: string[]): void {
    const referencias: Observable<ProductoData>[] = [];

    const observer: PartialObserver<ProductoData[]> = {
      next: (result: ProductoData[]) => {
        this.listaProductos = result ? result : [];
      },
      error: () => console.warn,
    };

    if (ids) {
      ids.forEach((id: string) => {
        const consulta: Observable<ProductData> = this.productoService
          .getProductById(id)
          .pipe(
            map((producto) => {
              producto.id = id;
              producto.indicadorSello = new FormControl(
                producto.sello ? true : false
              );
              return producto;
            })
          );

        referencias.push(consulta);
      });

      zip(...referencias)
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(observer);
    }
  }

  parseDate(time: number): Date {
    return new Date(time);
  }

  editProduct(producto: ProductoData): void {
    if (this.productToEdit === producto) {
      delete this.productToEdit;
    } else {
      this.productToEdit = producto;
      this.productDetailForm.patchValue(this.productToEdit);
    }
  }

  updateUser(callback?: () => void): void {
    if (this.personalDataForm.valid && this.userToEdit?.id) {
      const datos = deepCopy(this.personalDataForm.value);
      const id: string = this.userToEdit.id;
      let activando: boolean = false;
      if (datos.activo && !datos.tiempoActivado) {
        activando = true;
        datos.tiempoActivado = Timestamp.now();
      }

      if (!activando) {
        delete datos.tiempoActivado;
      }

      if (datos.tiempoCreado) {
        delete datos.tiempoCreado;
      }

      this.userService
        .updateUser(datos, id)
        .then(() => {
          callback && callback();
        })
        .catch((err) => {
          console.error(err);
          callback && callback();
        });
    } else {
      window.alert("faltan datos en el formulario");
    }
  }

  updateProduct(callback?: () => void): void {
    if (this.productDetailForm.valid && this.productToEdit?.id) {
      const id: string = this.productToEdit.id;
      this.productDetailForm
        .get("sello")
        ?.setValue(this.productToEdit.indicadorSello?.value);
      const datos: ProductoData = this.productDetailForm.value;
      this.productoService
        .updateProduct(datos, id)
        .then(() => {
          callback && callback();
        })
        .catch((err) => {
          console.error(err);
          callback && callback();
        });
    }
  }

  async setSello(producto: ProductoData, evento: any): Promise<void> {
    try {
      if (!producto?.id) return;

      const { checked } = evento.srcElement;
      await this.productoService.updateProduct({ sello: checked }, producto.id);
      alert(`sello ${checked ? "" : "des"}habilitado`);
    } catch (e) {
      console.error(e);
      alert("error habilitar sello");
    }
  }

  updateSpecies(callback?: () => void): void {
    if (!this.userToEdit?.id) return;

    const values: (string | null)[] = this.productInfoForm.value;
    const valuesClear: string[] = values.filter((item: string | null) =>
      item ? true : false
    ) as string[];

    const datos: { especiesHabilitadas: string[] } = {
      especiesHabilitadas: valuesClear,
    };
    const id: string = this.userToEdit.id;
    this.userService
      .updateUser(datos, id)
      .then(() => {
        callback && callback();
      })
      .catch((err) => {
        console.error(err);
        callback && callback();
      });
  }

  save(callback?: () => void) {
    switch (this.tipoGuardar) {
      case "producto":
        this.updateProduct(callback);
        break;
      case "especies":
        this.updateSpecies(callback);
        break;
      case "usuario":
        this.updateUser(callback);
        break;
      default:
        break;
    }
  }

  selectTipo(tipo: TypeSave, modalRef: TemplateRef<any>): void {
    this.tipoGuardar = tipo;
    this.modalService.open(modalRef);
  }

  cierraPopup(): void {
    this.closebutton.nativeElement.click();
  }

  removeEspecieForm(): void {
    if (this.productInfoForm.length >= 1) {
      this.productInfoForm.removeAt(this.productInfoForm.length - 1);
    }
  }

  async getFiles(id: string): Promise<void> {
    try {
      this.archivos = await firstValueFrom(
        this.userService.getRegisterFiles(id).pipe(takeUntil(this.unsubscriber))
      );
    } catch (e) {
      console.error(e);
    }
  }

  filtrar(opt: string) {
    switch (opt) {
      case "todos":
        this.estado = null;
        break;
      case "activos":
        this.estado = true;
        break;
      case "inactivos":
        this.estado = false;
        break;
      default:
        this.estado = null;
        break;
    }
    this.searcherValue = undefined;
    this.currentPage = 1;
    this.getUsers();
  }

  deleteCategories(categoria: AbstractControl<string | null>): void {
    const filtered = this.productCategoriesForm.controls.filter(
      (c) => c.value !== categoria.value
    );
    this.productCategoriesForm.controls = filtered;
    this.updateCategories();
  }

  deleteEspecie(especie: FormControl<string | null>): void {
    const filtered: FormControl<string | null>[] =
      this.productInfoForm.controls.filter((c) => c.value !== especie.value);

    this.productInfoForm.controls = filtered;
    this.updateEspecies();
  }

  addCategoria(select: HTMLSelectElement): void {
    const { value } = select;
    const exists: AbstractControl<string> | undefined =
      this.productCategoriesForm.controls.find(
        (c: AbstractControl<string>) => c.value === value
      );

    if (!exists && value) {
      const formControl: FormControl<string | null> =
        this.formBuilder.control(value);
      this.productCategoriesForm.controls.push(formControl);
    }
    this.updateCategories();
    select.value = "";
  }

  updateCategories(): void {
    if (!this.userToEdit?.id) return;
    const categorias: string[] = this.productCategoriesForm.controls.reduce(
      (prev: string[], cu) => {
        const value = cu.value;
        if (
          value !== null &&
          value !== undefined &&
          typeof value === "string"
        ) {
          prev.push(value);
        }
        return prev;
      },
      []
    );

    this.userService
      .updateUser({ categoryProductUser: categorias }, this.userToEdit.id)
      .then(() => {
        alert("categorías actualizadas");
      })
      .catch((err) => {
        console.error(err);
        alert("Error al actualizar las categorías");
      });
  }

  updateEspecies(): void {
    if (!this.userToEdit?.id) return;

    const especies: string[] = this.productInfoForm.controls.reduce(
      (prev: string[], cu) => {
        const value = cu.value;
        if (
          value !== null &&
          value !== undefined &&
          typeof value === "string"
        ) {
          prev.push(value);
        }
        return prev;
      },
      []
    );

    this.userService
      .updateUser({ especiesHabilitadas: especies }, this.userToEdit.id)
      .then(() => {
        alert("especies actualizadas");
      })
      .catch((err) => {
        console.error(err);
        alert("Error al actualizar la especie");
      });
  }

  addEspecie(select: HTMLSelectElement) {
    const { value } = select;
    const exists: boolean = this.productInfoForm.value.includes(value);
    if (!exists && value) {
      this.productInfoForm.push(new FormControl(value));
    }
    select.value = "";
    this.updateEspecies();
  }

  gestionarRoles(valor: string, remove?: boolean): void {
    const roles: string[] = this.personalDataForm.get("tipo")?.value;
    if (remove) {
      const filtered: string[] = roles.filter((r: string) => r !== valor);
      this.personalDataForm.get("tipo")?.setValue(filtered);
      this.updateRolesUser();
    } else {
      if (!roles.includes(valor)) {
        roles.push(valor);
        this.updateRolesUser();
      }
    }
  }

  updateRolesUser(): void {
    if (!this.userToEdit?.id) return;

    const tipo = this.personalDataForm.get("tipo")?.value;
    this.userService
      .updateUser({ tipo }, this.userToEdit.id)
      .then(() => {
        alert("Tipo usuario actualizado");
      })
      .catch((err) => {
        console.error(err);
        alert("Error al actualizar el tipo de usuario");
      });
  }

  typeof(item: any) {
    return typeof item;
  }

  abirModal(user: UserAdminState) {
    this.editUser(user);
    this.modalService.open(this.userModal, { size: "xl" });
  }

  getButtonsPaginate(limit: number = 4, paginas: number) {
    const max: number = this.currentPage + limit;
    const min: number = this.currentPage - limit;
    const arr: number[] = [];
    for (let i = min; i <= max; i++) {
      if (i > 0 && i <= paginas) {
        arr.push(i);
      }
    }
    this.paginas = arr;
  }

  // Procedimiento para mostrar modal informativa de carga de datos
  // close: parametro para indicar si cierra la modal que ya está abierta
  load(close?: boolean) {
    if (close && this.modalLoader) {
      this.modalLoader.dismiss();
      delete this.modalLoader;
      return;
    }
    this.modalLoader = this.modalService.open(this.loadingModal, {
      ariaLabelledBy: "loading-modal",
      centered: true,
    });
  }

  abrirCambiarEmail(): void {
    if (!this.userToEdit) return;

    const { id } = this.userToEdit;
    const modal: NgbModalRef = this.modalService.open(CambioCorreoComponent, {
      centered: true,
    });
    modal.componentInstance.idUsuario = id;
    modal.componentInstance.cerrar
      .pipe(
        tap({
          next: () => {
            modal.close();
          },
          error: (e) => {
            console.error(e);
            modal.close();
          },
        })
      )
      .subscribe();
  }

  getIterable(tipo: string | string[]): string[] {
    // Verificar el tipo y devolver un array si es una cadena
    return typeof tipo === "string" ? [tipo] : tipo;
  }
}
