export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDTOtBL8B8t-4YxpqV4VyrxmW1nsk5u3ZM",
    authDomain: "red-forestal-produccion.firebaseapp.com",
    projectId: "red-forestal-produccion",
    storageBucket: "red-forestal-produccion.appspot.com",
    messagingSenderId: "1017532164752",
    appId: "1:1017532164752:web:464a50992568e30f854615",
    measurementId: "G-0VEBYWV4HZ",
  },
  googleAnalytics: {
    trackingId: "G-9V9BZREYJ3",
  },
};
