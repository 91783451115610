import { Pipe, PipeTransform } from "@angular/core";
import { WtLicenseService } from "../service/wt-license.service";
import { TimeStamp } from "src/app/core/types/time-stamp.type";
import { orderBy, QueryConstraint, where } from "@angular/fire/firestore";
import { WtLicense } from "../model/wt-license";

/**
 * Pipe que devuelve un string con el campo 'begins' de un WtLicense, dado su wtUserId.
 */
@Pipe({
  name: "wtUserLicenseDate",
})
export class WtUserLicenseDatePipe implements PipeTransform {
  constructor(private crud: WtLicenseService) {}

  async transform(uid: string): Promise<string> {
    const query: QueryConstraint[] = [];
    query.push(orderBy("begins", "desc"));
    query.push(where("wtUserId", "==", uid));
    return this.crud.fetchCollection(query).then((licenses: WtLicense[]) => {
      if (!licenses || licenses.length === 0) return "sin-licencia";
      if (!licenses[0].begins) return "sin-licencia";

      return (licenses[0].begins as TimeStamp)
        .toDate()
        .toLocaleDateString("es-CO", {
          timeZone: "America/Bogota",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
    });
  }
}
