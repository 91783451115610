import { WhereFilterOp } from "@angular/fire/firestore";
import { SearchMode } from "../interface/search-mode.interface";
import { PaginationStrategy } from "../types/pagination-strategy.type";

/**
 * Grid setup options
 */
export interface Pagination {
  /** If data should be paginated or not and how */
  paginate: PaginationStrategy;
  /** Page number */
  page: number;
  /** Página anterior de la paginación */
  previousPage: number;
  /** Page size */
  size: number;
  /** Array of filters */
  filters: QueryFilter[];
  /** Array of sorts */
  sorts: QuerySort[];
  /** Array with search modes */
  searchModes: SearchMode[];
  /** Search string */
  searchString: string;
}

/**
 * QueryFilter
 */
export interface QueryFilter {
  /** Field to query */
  field: string;
  /** Query operator: <, <=, ==, >, >=, array-contains, in, or array-contains-any */
  queryOperator: WhereFilterOp;
  /** Value to query */
  value: any;
  /** Si el filtro es obligatorio y no puede eliminarse */
  constraint?: boolean;
}

/**
 * QuerySort
 */
export interface QuerySort {
  /** Field to sort */
  field: string;
  /** Sort direction: "asc" or "desc" */
  direction: "asc" | "desc";
}

/**
 * Parámetros por default para la paginación
 */
export const DEFAULT_PAGINATION: Pagination = {
  paginate: "pages",
  page: 0,
  previousPage: 0,
  size: 50,
  filters: [],
  sorts: [],
  searchModes: [],
  searchString: "",
};
