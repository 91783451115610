import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  FirebaseFunctions,
  GenericUsuariosResponse,
} from "../functions/firebase.functions";
import { UserData } from "./user.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { HttpsCallableResult } from "firebase/functions";

type UserAdminData = UserData & {
  id: string;
};

@Injectable({
  providedIn: "root",
})
export class UsersAdminService {
  usuarios: Observable<UserAdminData[]>;

  constructor(
    private firebaseRequest: FirebaseRequestsService,
    private firebaseFunctions: FirebaseFunctions
  ) {
    this.usuarios =
      this.firebaseRequest.getCollectionFirebaseWithQueryObservable<UserAdminData>(
        "usuarios"
      );
  }

  getUsuarios(): Observable<UserAdminData[]> {
    return this.usuarios;
  }

  getUsers(
    limit: number,
    offset: number,
    estado: boolean | null,
    search: string | undefined
  ): Promise<HttpsCallableResult<GenericUsuariosResponse>> {
    return this.firebaseFunctions.getUsers(limit, offset, estado, search);
  }
}
