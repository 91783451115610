import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable, Subject, from, of } from "rxjs";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";
import { Path } from "../enums/Path";
import moment from "moment";
import { User } from "@angular/fire/auth";
import { AuthService } from "../services/auth.service";
import { UserData, UserService } from "../services/user.service";
import { CalificacionService } from "../services/calificacion.service";
import { SubscriptionService } from "../services/subscriptions.service";
import { PermisosService } from "../services/permisos.service";
import { Permiso } from "../models/permisosinterface";
import {
  Suscripcion,
  SuscripcionActiva,
} from "../models/subscripcion.interface";
import { UploadTaskSnapshot } from "firebase/storage";

@Component({
  selector: "app-perfil-usuarios",
  templateUrl: "./perfil-usuarios.component.html",
  styleUrls: ["./perfil-usuarios.component.css"],
})
export class PerfilUsuariosComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  imageSrc!: string;
  nameUser!: string;
  url: string = "assets/imagenes/logo_perfil_usuario.webp";
  datosSuscripcion!: SuscripcionActiva;
  puntuacion: string[] = [];

  public user!: User | null;
  public path: typeof Path = Path;
  public permisos!: any[];
  fechaRegistro!: string;

  constructor(
    private calificacionService: CalificacionService,
    private suscripcionSvc: SubscriptionService,
    public authService: AuthService,
    public userService: UserService,
    public activatedRoute: ActivatedRoute,
    public permisosSvc: PermisosService
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((user: User | null) => {
          if (user) {
            this.user = user;
            this.getUserData(this.user.uid);
            this.fechaRegistro = moment(this.user.metadata.creationTime)
              .locale("es")
              .format("ll");

            return from(this.getBotones());
          }
          return of(null);
        }),
        tap((permisos: Permiso[] | null) => {
          if (permisos !== null) {
            this.permisos = permisos;
          }
        })
      )
      .subscribe();
  }

  /**
   * Returns buttons for nav menu, but filters 'Crear Subasta',
   * only for users in 'departamentos'
   *
   * @param departamentos Array with departamentos where users can 'Crear Subasta'
   * @returns Promise of buttons array
   */
  async getBotones(): Promise<Permiso[]> {
    return this.permisosSvc.getBotones();
  }

  readURL(event: any): void {
    const allowTypes: string[] = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    if (allowTypes.includes(file.type) && this.user) {
      const uid: string = this.user.uid;
      this.userService
        .updateAvatarProfile(uid, event.target.files[0])
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (upload: {
              progress: number;
              snapshot: UploadTaskSnapshot;
            }) => {
              if (upload.snapshot.state === "success") {
                this.getLogo(uid);
              }
            },
            error: (err) => {
              console.error(err);
            },
          })
        )
        .subscribe();
    } else {
      alert("Formato Incorrecto");
    }
  }

  async getLogo(uid: string): Promise<void> {
    try {
      this.url = await this.userService.getLogo(uid);
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  alert(message: string): void {
    window.alert(message);
  }

  async getUserData(uid: string): Promise<void> {
    if (this.user) {
      try {
        await this.getLogo(this.user.uid); // se trae logo
        this.getCalificacion(this.user.uid);
        this.userService
          .getUserById(uid)
          .pipe(
            takeUntil(this.unsubscriber),
            tap({
              next: (user: UserData) => {
                if (this.user) {
                  this.getSubscripcion(this.user.uid);
                  this.nameUser = user.nombres;
                }
              },
              error: (err) => {
                console.error(err);
              },
            })
          )
          .subscribe();
      } catch (err) {
        console.error(err);
      }
    }
  }

  setearDatosSubscripcion(datos: SuscripcionActiva) {
    if (!datos) return;

    const { suscripcionActual } = datos;
    if (suscripcionActual) {
      const fechaFin: moment.Moment = moment(
        suscripcionActual.fechaFin.seconds * 1000
      );

      const datosSubs: Suscripcion = {
        ...suscripcionActual,
        fechaInicio: suscripcionActual.fechaInicio,
        diasRestantes: moment(fechaFin).diff(new Date(), "days"),
      };

      datos.suscripcionActual = datosSubs;

      this.datosSuscripcion = datos;
    } else {
      this.datosSuscripcion = datos;
    }
  }

  async getSubscripcion(id: string): Promise<void> {
    const suscripcion: SuscripcionActiva =
      await this.suscripcionSvc.getActiveSubscription(id);
    if (suscripcion) {
      this.setearDatosSubscripcion(suscripcion);
    }
  }

  getCalificacion(userId: string): void {
    this.calificacionService
      .getUserCalificaciones(userId)
      .pipe(
        takeUntil(this.unsubscriber),
        map((calificaciones: any) => {
          return calificaciones.map((calif: any) => calif.puntuacion || 0);
        }),
        tap({
          next: (calificaciones: number[]) => {
            if (calificaciones.length) {
              const total: number = calificaciones.reduce(
                (cu: number, acc: number) => cu + acc
              );
              this.puntuacion = new Array(
                Math.round(total / calificaciones.length)
              ).fill("*");
            }
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }
}
