import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CalificacionyComentarios } from "../models/CalificacionyComentarios";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  DocumentData,
  DocumentReference,
  orderBy,
  where,
} from "@angular/fire/firestore";

type CalificacionData = CalificacionyComentarios;

@Injectable({
  providedIn: "root",
})
export class CalificacionService {
  constructor(private firebaseRequests: FirebaseRequestsService) {}

  async addCalificacion(data: CalificacionData): Promise<string> {
    try {
      const response: DocumentReference<DocumentData> =
        await this.firebaseRequests.addDocFirebaseWithAutomaticId(
          "calificacionyComentarios",
          data
        );
      return response.id;
    } catch (error) {
      console.error(error);
      throw new Error("Could not add a rating");
    }
  }

  getUserCalificaciones(
    userId: string
  ): Observable<CalificacionyComentarios[]> {
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "calificacionyComentarios",
      [orderBy("tiempoCreado", "desc"), where("idCalificado", "==", userId)]
    ) as Observable<CalificacionyComentarios[]>;
  }
}
