import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "@angular/fire/auth";
import { Timestamp } from "@angular/fire/firestore";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { Select } from "@ngxs/store";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "src/app/state/authentication-state";
import { ServiceUserService } from "src/app/services/service-user.service";
import { AuthService } from "src/app/services/auth.service";
import { FirebaseRequestsService } from "src/app/services/firebase-requests.service";
import { UserData, UserService } from "src/app/services/user.service";
import { NuevoServicioTransportador } from "src/app/models/addServiceData";
import {
  ContractService,
  ResponseObservableContractService,
  ServiceUser,
} from "src/app/models/contractService.interface";
import { UserType } from "src/app/enums/UserTypes";
import { DatosCalificacion } from "src/app/models/CalificacionyComentarios";

@Component({
  selector: "app-transportador",
  templateUrl: "./transportador.component.html",
  styleUrls: ["./transportador.component.css"],
})
export class TransportadorComponent implements OnInit, OnDestroy {
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  private unsubscriber: Subject<void> = new Subject();
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  public currentServiceId!: string;
  public marcasVehiculo: string[] = [
    "Chevrolet",
    "Daf Trucks",
    "Ford",
    "Foton",
    "Freightliner",
    "Fuso",
    "Hino",
    "International",
    "Isuzu",
    "Iveco",
    "JAC",
    "JMC",
    "Kenworth",
    "Mack",
    "Man",
    "Mercedes Benz",
    "Mitsubishi",
    "Nissan",
    "Renault",
    "Scania",
    "Toyota",
    "Volvo",
  ];
  public tiposVehiculo: string[] = [
    "Avión Mixto",
    "Avión de Carga",
    "Avión de Supertrasnporte",
    "Barcaza",
    "Buque Convensional",
    "Buque Multipropósito",
    "Buque de Autotransbordo",
    "Buque Portacontenedores",
    "Buque Panamax",
    "Buque Postpanamax",
    "Camión Rígido de 2 Ejes",
    "Camión Rígido de 3 Ejes",
    "Camión Rígido de 4 Ejes",
    "Tractocamión de 2 Ejes con Semiremolque de 1 Eje",
    "Tractocamión de 2 Ejes con Semiremolque de 2 Ejes",
    "Tractocamión de 3 Ejes con Semiremolque de 1 Eje",
    "Tractocamión de 3 Ejes con Semiremolque de 2 Ejes",
    "Tractocamión de 3 Ejes con Semiremolque de 3 Ejes",
  ];
  typeUser!: UserType | UserType[];
  addDriverServiceForm!: FormGroup;

  serviceDriver: ServiceUser[] = [];
  solicitudes: ResponseObservableContractService[] = [];
  datosCalificacion!: DatosCalificacion;

  constructor(
    private serviceUser: ServiceUserService,
    private authService: AuthService,
    private fb: FormBuilder,
    private firebaseRequests: FirebaseRequestsService,
    private userService: UserService
  ) {
    this.initForm();
    this.getDriverService();
    this.getSolicitudes();
  }

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap((user: User | null) => {
          if (user) {
            this.getUserData(user.uid);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get tipoSolicitudes(): string {
    if (this.typeUser instanceof Array) {
      const transportador: boolean = this.typeUser.includes(
        UserType.Transportador
      );
      const tecnico: boolean = this.typeUser.includes(UserType.Tecnico);
      return transportador && tecnico
        ? "transportador.forestalesTransporte"
        : "transportador.transporte";
    } else {
      return this.typeUser === UserType.Transportador
        ? "transportador.forestalesTransporte"
        : "transportador.transporte";
    }
  }

  async onCreateDriverService(): Promise<void> {
    if (this.addDriverServiceForm.invalid) {
      this.addDriverServiceForm.markAllAsTouched();
      return;
    }

    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      const data: NuevoServicioTransportador = {
        ...this.addDriverServiceForm.value,
        tiempoCreado: Timestamp.now(),
        userId: currentUser.uid,
        user: this.firebaseRequests.getFirebaseDocReference(
          `usuarios/${currentUser.uid}`
        ),
        type: "transportador",
        nameService: "transporte",
      };

      try {
        await this.serviceUser.addService(data);
        this.resetFormAndFiles();
      } catch (error) {
        console.error(error);
      }
    }
  }

  async deleteServicio(id: string): Promise<void> {
    if (id) {
      await this.serviceUser.deleteService(id);
    }
  }

  resetFormAndFiles(): void {
    this.addDriverServiceForm.reset();
  }

  getDriverService(): void {
    const currentUser: User | null = this.authService.getCurrentUser();
    if (currentUser) {
      this.serviceUser
        .getUserServices(currentUser.uid, "transportador")
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (serviciosUsuario: ServiceUser[]) => {
              this.serviceDriver = serviciosUsuario;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  getSolicitudes(): void {
    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      this.serviceUser
        .getServiceRequest(currentUser.uid)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: async (solicitudes: ContractService[]) => {
              const servicios: ResponseObservableContractService[] = [];
              try {
                await Promise.all(
                  solicitudes.map(async (svc: ContractService) => {
                    const servicioUsuario: ServiceUser =
                      await this.firebaseRequests.getDocFirebaseWithIdPromise<ServiceUser>(
                        svc.servicio.path
                      );

                    const user: UserData = await firstValueFrom(
                      this.userService.getUserById(svc.idContratante)
                    );

                    servicios.push({
                      ...svc,
                      servicioUsuario,
                      user,
                    });
                  })
                );
                this.solicitudes = servicios;
              } catch (error) {
                console.error("Error al obtener las solicitudes:", error);
              }
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  marcarCalificado(id: string): void {
    const servicio: string = this.currentServiceId;

    this.serviceUser
      .updateServicio(servicio, {
        calificadoContratado: true,
        idCalificacionContratado: id,
        fechaFinalizacion: Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => console.warn("error al calificar servicio: ", err));
  }

  isValidField(field: string): boolean {
    return (
      (this.addDriverServiceForm.get(field)?.dirty &&
        this.addDriverServiceForm.get(field)?.valid) ||
      false
    );
  }

  isInvalidField(field: string): boolean {
    return (
      ((this.addDriverServiceForm.get(field)?.touched ||
        this.addDriverServiceForm.get(field)?.dirty) &&
        !this.addDriverServiceForm.get(field)?.valid) ||
      false
    );
  }

  getUserData(uid: string): void {
    this.userService
      .getUserById(uid)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: UserData) => {
            this.typeUser = user.tipo;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  initForm(): void {
    this.addDriverServiceForm = this.fb.group({
      truckType: ["", [Validators.required]],
      driverYearExpirience: ["", [Validators.required]],
      driverWorkExperience: ["", [Validators.required]],
      truckWeight: ["", [Validators.required]],
      truckVolume: [""],
      truckBrand: ["", [Validators.required]],
      truckYear: ["", [Validators.required]],
    });
  }

  async setDatosCalificacion(svc: ResponseObservableContractService) {
    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      const datos: DatosCalificacion = {
        idServicio: svc.idServicio,
        idCalificador: currentUser.uid,
        idCalificado: svc.idContratante,
      };

      this.datosCalificacion = datos;
    }
  }
}
