<div>
  <label for="comentariosCalificacion">
    {{ "pqr-comentarios.dejaComentarios" | translate }}
  </label>
  <div>
    <div class="form-group">
      <textarea
        class="form-control"
        id="comentariosCalificacion"
        rows="3"
        [(ngModel)]="pqrData.comentario"
        aria-describedby="indicacionesComentarios"
        name="comentarios"
        placeholder="{{ 'pqr-comentarios.placeholder' | translate }}"
      >
      </textarea>
    </div>
    <button class="btn btn-success btn-sm m-1" (click)="sendPqr()">
      {{ "pqr-comentarios.enviar" | translate }}
    </button>
  </div>
</div>
