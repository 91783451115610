export const subproductoEquipos: string[] = [
  "Accesorios de Herramientas Eléctricas",
  "Alicates y Hombresolo",
  "Arrastradores",
  "Aserríos Estacionarios",
  "Aserríos Portátiles",
  "Barreno y Espigo",
  "Baterías y Cargadores",
  "Baúles y Carros de Herramientas",
  "Bisagra Muebles",
  "Brazos Neumáticos",
  "Brocas",
  "Cabinas de Pintura",
  "Cabinas Presurizadas",
  "Cajas a la Medida",
  "Cajas de Herramientas",
  "Calibradores, Micrómetros y Torquímetros",
  "Canteadoras de costeros",
  "Cargador Estacionario",
  "Centros CNC para sillas",
  "Centros de Mecanizado CNC",
  "Centros de Perforación CNC",
  "Cepillos Eléctricos para Madera",
  "Cepillos para Madera y Formones",
  "Cepillos y Planeadoras",
  "Cinta Métrica, Reglas y Escuadras",
  "CNC de Puentes",
  "CNC para Enchapado",
  "CNC para Nesting",
  "Combos y Kits de Herramientas Eléctricas",
  "Compresores de Aire",
  "Contorneadora",
  "Cortacesped, Cortasetos y Podadoras",
  "Cortina de Agua",
  "Cosechadoras con Cadenas",
  "Cosechadoras con Ruedas",
  "Decantadores",
  "Destornilladores",
  "Destornilladores Eléctricos y Llaves de Impacto",
  "Discos para Sierra",
  "Drones",
  "Embisagradoras",
  "Enchapadora de cantos curvos",
  "Enchapadora de cantos rectos",
  "Enchapadoras de Cantos",
  "Enchapadoras de Cantos dobles",
  "Enchapadoras de Cantos monolaterales",
  "Equipos de Afilado",
  "Equipos de Topografía",
  "Equipos de Triscado",
  "Filtro Seco",
  "Fresadoras, Ruteadoras y Accesorios",
  "Fresas",
  "Grapadoras y Accesorios",
  "Grúas forestales",
  "Guadañas",
  "Guantes y Accesorios para Jardinería",
  "Hachas, Serruchos, Machetes y Tijeras para podar",
  "Herrajes para Closets",
  "Herrajes para Cocina",
  "Herramientas Multiuso, Mototool y Accesorios",
  "Insertos para Fresas",
  "Kit de Herramientas Manuales",
  "Lijadoras Calibradoras",
  "Lijadoras Eléctricas y Accesorios",
  "Lijadoras manuales",
  "Lijas",
  "Limas y Escofinas",
  "Línea de Pintura para Perfiles",
  "Línea SLP (Smart Log Processing)",
  "Líneas de Aserrado",
  "Líneas de empaque",
  "Líneas de Finger Joint",
  "Líneas de Pintura para Planos",
  "Líneas de Prensado",
  "Líneas Llave en Mano",
  "Llaves y Juego de Copas",
  "Maletas y Cinturones Porta Herramientas",
  "Manejo de Trozas",
  "Mangueras, Aspersores y Accesorios para Riego",
  "Manijas y Botones",
  "Máquina Forestal Giratoria",
  "Máquinas para Chapilla",
  "Máquinas para Pintura",
  "Martillos y Mazos",
  "Medidor Láser",
  "Medidores de humedad",
  "Mesas y Bancos De Trabajo",
  "Moldureras",
  "Molinos Trituradores",
  "Monitoreo",
  "Montacargas",
  "Motosierras",
  "Multiherramientas y Navajas",
  "Nivel Láser y de Burbuja",
  "Otras Herramientas de Medición",
  "Otras Herramientas Manuales",
  "Otras Herramientas y Maquinaria",
  "Otros Herrajes",
  "Patas y Rodachines",
  "Polichadoras y Accesorios",
  "Prensas",
  "Prensas para armado de muebles",
  "Prensas, Guías y Plantillas",
  "Pulidoras y Accesorios",
  "Pulverizadoras, Fumigadoras y Regaderas",
  "Rastrillos y Palas",
  "Reaserrado",
  "Recubridora de molduras",
  "Remachadoras",
  "Rieles y Guias de Cajón",
  "Robots de pintura",
  "Rotomartillos y Accesorios",
  "Secaderos",
  "Seccionadoras CNC",
  "Serruchos, Seguetas y Accesorios",
  "Sierra Cinta",
  "Sierra SinFin",
  "Sierras",
  "Sierras de banco",
  "Sierras deshiladoras",
  "Sierras Eléctricas y Accesorios",
  "Sierras escuadradoras",
  "Sierras múltiples",
  "Sierras Optimizadoras CNC",
  "Sierras radiales",
  "Sierras Tronzadoras",
  "Sierras Verticales",
  "Sistemas de Extracción",
  "Software",
  "Tableros y Organizadores de Herramientas",
  "Taladores Apiladores con Cadenas",
  "Taladores Apiladores con Ruedas",
  "Taladros múltiples",
  "Taladros y Accesorios",
  "Tijeras y Cizallas",
  "Tornos CNC",
  "Tractores y Accesorios",
  "Transportador de Troncos",
  "Trituradoras y Briquetadoras",
  "Trompos",
  "Tronzadoras y Accesorios",
];
