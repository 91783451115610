<h2 class="titulo-publicidad text-center">Gestión de la publicidad</h2>

<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <a
      class="nav-link active"
      id="publicidadActiva-tab"
      data-bs-toggle="tab"
      href="#publicidadActiva"
      role="tab"
      aria-controls="publicidadActiva"
      aria-selected="true"
      >Publicidad Activa</a
    >
  </li>
  <li class="nav-item" role="presentation">
    <a
      class="nav-link"
      id="historialPublicidad-tab"
      (click)="clickHistorial()"
      data-bs-toggle="tab"
      href="#historialPublicidad"
      role="tab"
      aria-controls="historialPublicidad"
      aria-selected="false"
      >Historial de publicidad</a
    >
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="publicidadActiva"
    role="tabpanel"
    aria-labelledby="publicidadActiva-tab"
  >
    <h3 class="subtitulo-texto mt-2">Catálogo</h3>
    <div [formGroup]="formulario">
      <div class="border mt-3" *ngFor="let item of items.catalogo">
        <h4 class="p-2">Imagen {{ item | titlecase }}</h4>
        <div class="row">
          <div class="col-sm-6 img-container">
            <img
              [src]="
                getSrcImg(item) ||
                '../../assets/imagenes/espacioPublicitario.webp'
              "
              class="imagenCatalogo mb-2 ml-2"
            />

            <button class="btn btn-primary" (click)="seleccionarFoto(item)">
              Seleccionar Imagen
            </button>

            <input
              hidden
              class="form-control"
              type="file"
              #inputImg
              accept="image/png, image/jpeg, image/webp"
              (change)="readURL($event)"
            />
          </div>
          <div class="col-sm-6">
            <form class="pr-3" [formGroupName]="item">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >Nombre de la empresa</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="empresa"
                  formControlName="nombreEmpresa"
                />
              </div>
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >Enlace</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="http://...."
                  formControlName="enlace"
                />
              </div>
              <div>
                <label for="formFile" class="form-label"
                  >Seleccione el número de días pagos</label
                >
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="diasPagos"
                >
                  <option>Seleccione cantidad</option>
                  <option [value]="dia" *ngFor="let dia of dias">
                    {{ dia + " dia" + (dia > 1 ? "s" : "") }}
                  </option>
                </select>
              </div>
              <div class="mt-2">
                <label for="start" class="pr-2">Fecha de inicio </label>
                <input
                  #fechaInicioInput
                  type="date"
                  id="start"
                  name="start"
                  value="2020-07-22"
                  min="2019-01-01"
                  max="2030-12-31"
                  formControlName="fechaInicio"
                  (change)="setFin($event, item)"
                />
              </div>
              <div class="mt-2">
                <label for="end" class="pr-2">Fecha de terminación </label>
                <input
                  #fechaFin
                  type="date"
                  id="end"
                  name="end"
                  value="2020-07-22"
                  min="2019-01-01"
                  max="2030-12-31"
                  formControlName="fechaTerminacion"
                  readonly
                />
              </div>

              <button
                [disabled]="!formulario.get(item)?.valid"
                type="button"
                class="btn btn-warning mb-3"
                (click)="currentItem = item; btnModal.click()"
              >
                {{ publicidadActiva[item] ? "Actualizar" : "Guardar" }}
              </button>

              <button
                *ngIf="publicidadActiva[item]"
                type="button"
                class="btn btn-danger mb-3 ml-3"
                (click)="cambiarEstado(item)"
              >
                {{ publicidadActiva[item]["activo"] ? "Inactivar" : "Activar" }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <h3 class="subtitulo-texto mt-5">Página principal</h3>

    <div
      class="col-12 border"
      *ngFor="let item of items.slides; let pos = index"
      [formGroup]="formulario"
    >
      <h4 class="text-center">Slide No {{ pos + 1 }}</h4>
      <div class="text-center">
        <img
          [src]="getSrcImg(item) || '../../assets/imagenes/img2.webp'"
          class="img-fluid mt-3"
        />
      </div>
      <div class="text-center m-1">
        <button class="btn btn-primary" (click)="seleccionarFoto(item)">
          Seleccionar Imagen
        </button>
      </div>

      <form class="formulariPublicidad" [formGroupName]="item">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Nombre de la empresa</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="empresa"
            formControlName="nombreEmpresa"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Enlace</label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="http://...."
            formControlName="enlace"
          />
        </div>
        <div>
          <label for="formFile" class="form-label"
            >Seleccione el número de días pagos</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            formControlName="diasPagos"
          >
            <option selected>Seleccione cantidad</option>
            <option [value]="dia" *ngFor="let dia of dias">
              {{ dia + " dia" + (dia > 1 ? "s" : "") }}
            </option>
          </select>
        </div>
        <div class="mt-2">
          <label for="start" class="pr-2">Fecha de inicio </label>
          <input
            #fechaInicioInput
            type="date"
            id="start"
            name="start"
            value="2020-07-22"
            min="2019-01-01"
            max="2030-12-31"
            formControlName="fechaInicio"
            (change)="setFin($event, item)"
          />
        </div>
        <div class="mt-2">
          <label for="end" class="pr-2">Fecha de terminación </label>
          <input
            #fechaFin
            type="date"
            id="end"
            name="end"
            value="2020-07-22"
            min="2019-01-01"
            max="2030-12-31"
            formControlName="fechaTerminacion"
            readonly
          />
        </div>
        <button
          type="button"
          class="btn btn-warning mb-3"
          (click)="currentItem = item; btnModal.click()"
          [disabled]="!formulario.get(item)?.valid"
        >
          {{ publicidadActiva[item] ? "Actualizar" : "Guardar" }}
        </button>

        <button
          *ngIf="publicidadActiva[item]"
          type="button"
          class="btn btn-danger mb-3 ml-3"
          (click)="cambiarEstado(item)"
        >
          {{ publicidadActiva[item]["activo"] ? "Inactivar" : "Activar" }}
        </button>
      </form>
    </div>
  </div>
  <!-- Historial de publicidad-->
  <div
    class="tab-pane fade"
    id="historialPublicidad"
    role="tabpanel"
    aria-labelledby="historialPublicidad-tab"
  >
    <table class="table table-striped table-responsive-sm">
      <thead>
        <tr>
          <th scope="col">Imagen</th>
          <th scope="col">Empresa</th>
          <th scope="col">Nombre</th>
          <th scope="col">Enlace</th>
          <th scope="col">Días Pagos</th>
          <th scope="col">Fecha inicio</th>
          <th scope="col">Fecha terminacion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of historialPublicidad">
          <th scope="row">
            <a [href]="item.imagen" target="_blank">
              <img [src]="item.imagen" class="img-historial" />
            </a>
          </th>
          <td>{{ item.nombreEmpresa }}</td>
          <td>{{ item.nombre | titlecase }}</td>
          <td>
            <a [href]="item.enlace">
              {{ item.enlace }}
            </a>
          </td>
          <td>{{ item.diasPagos }}</td>
          <td>{{ toDate(item.fechaInicio, "ll") }}</td>
          <td>{{ toDate(item.fechaTerminacion, "ll") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!--Sección para administrar los aliados-->
<button
  hidden
  data-bs-toggle="modal"
  data-bs-target="#modalCrearPublicidad"
  #btnModal
></button>

<!--modal, ventana emergente para validar el cargue de una nueva publicidad-->
<div
  class="modal fade"
  id="modalCrearPublicidad"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¿Está seguro?</h5>
      </div>
      <div
        class="modal-body"
        *ngIf="!publicidadActiva[currentItem]; else updateTemplate"
      >
        Va a cargar una nueva imagen para un espacio publicitario, debe estar
        seguro de que la información está correctamente diligenciada. En caso de
        ser así de clic en <b>CONTINUAR</b> en caso contrario de clic en
        <b>CANCELAR</b>.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="guardarPublicidad(currentItem)"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #updateTemplate>
  <div class="modal-body">
    Se va a actualizar la información de la publicidad seleccionada, en caso de
    ser así de clic en <b>CONTINUAR</b> en caso contrario de clic en
    <b>CANCELAR</b>.
  </div>
</ng-template>
