import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Revista } from "src/app/models/Revista";
import { combineLatest, Subject, takeUntil, tap } from "rxjs";
import { TablaComponent } from "./tabla/tabla.component";
import { RevistasService } from "src/app/services/revistas.service";
import { Timestamp } from "@angular/fire/firestore";

interface Files {
  [x: string]: File | undefined;
}

@Component({
  selector: "app-revistas",
  templateUrl: "./revistas.component.html",
  styleUrls: ["./revistas.component.css"],
  providers: [TablaComponent],
})
export class RevistasComponent implements OnInit, OnDestroy {
  @ViewChild("inputGroupFile01") inputPdf!: ElementRef;
  private sub$: Subject<void> = new Subject();
  public form!: FormGroup;
  public imgPrev!: string | null;
  public saving: boolean = false;
  public files: Files = {};
  public savingImage!: number;
  public savingPdf!: number;
  public loadingImage!: boolean;
  public editing: any;
  public cambiarPdf: boolean = false;

  constructor(
    private fb: FormBuilder,
    private revistasSvc: RevistasService,
    @Inject(TablaComponent) public tablaComp: TablaComponent
  ) {
    this.initForm();
  }
  ngOnInit(): void {
    this.revistasSvc.deleteRevista$
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (result: boolean) => {
            if (result) {
              this.clearForm();
            }
          },
          error: (err) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  // función para inicializar formulario de creación de revistas
  initForm(): void {
    this.form = this.fb.group({
      titulo: ["", [Validators.required]],
      descripcion: ["", [Validators.required]],
      imagen: ["", [Validators.required]],
      pdf: ["", [Validators.required]],
    });
  }

  // función para seleccionar archivos y almacenarlos temporalmente mientras se realiza la creación y edicón de la revista
  selectFile(event: Event, fileName: string): void {
    const file: File[] = (event.target as any).files as File[];
    if (!file) {
      return;
    }
    this.files[fileName] = file[0];
    this.form.get(fileName)?.setValue(file[0]?.name);
    if (fileName.includes("imagen")) {
      const reader: FileReader = new FileReader();
      reader.onloadend = () => {
        this.imgPrev = reader.result as string;
      };
      reader.readAsDataURL(file[0]);
    }
  }

  // función para limpiar arvhivo
  resetFile(fileName: string): void {
    this.files[fileName] = undefined;
    this.form.patchValue({ [fileName]: null });

    if (fileName === "pdf" && this.inputPdf?.nativeElement) {
      this.inputPdf.nativeElement.value = "";
    }

    if (fileName.includes("imagen")) {
      this.imgPrev = null;
    }
  }

  async guardar(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.saving = true;
    this.form.disable();
    const data: Revista = {
      ...this.form.value,
      imagen: this.files["imagen"],
      pdf: this.files["pdf"],
      fechaCreacion: Timestamp.now(),
    };

    if (this.editing) {
      if (typeof this.files?.["imagen"] === "string") {
        delete data.imagen;
      }

      if (typeof this.files?.["pdf"] === "string") {
        delete data.pdf;
      }
      delete data.fechaCreacion;
    }

    try {
      const { savingImage, savingPdf, ...rest } = await (this.editing
        ? this.revistasSvc.updateFiles(this.editing.id, data)
        : this.revistasSvc.uploadCreate(data));

      if (savingImage && savingPdf) {
        combineLatest([savingImage, savingPdf])
          .pipe(
            takeUntil(this.sub$),
            tap({
              next: ([imageResult, pdfResult]) => {
                this.savingImage = imageResult;
                this.savingPdf = pdfResult;
                this.checkSave(rest);
              },
              error: (err) => {
                console.error(err);
              },
            })
          )
          .subscribe();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async checkSave(data: Revista): Promise<void> {
    if (this.savingPdf === 100 && this.savingImage === 100 && this.saving) {
      if (!this.editing) {
        await this.revistasSvc.create(data);
      } else {
        await this.revistasSvc.updateData(this.editing.id, data);
      }
      alert(`revista ${this.editing ? "actualizada" : "creada"}`);
      this.saving = false;
      this.form.enable();
      this.clearForm();
    }
  }

  clearForm(): void {
    this.form.reset();
    this.resetFile("imagen");
    this.resetFile("pdf");
    this.cambiarPdf = false;
    this.editing = null;
    this.savingImage = 0;
    this.savingPdf = 0;
  }

  seleccionarRevista(revista: any): void {
    this.editing = revista;
    const { imagen, pdf } = revista;
    this.form.patchValue(revista);
    this.imgPrev = imagen;
    this.files["imagen"] = imagen;
    this.files["pdf"] = pdf;
    this.loadingImage = false;
  }
}
