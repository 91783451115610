import { Injectable } from "@angular/core";
import {
  CollectionReference,
  DocumentReference,
  QueryConstraint,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { environment } from "src/environments/environment";
import { FirestoreServiceInteface } from "../interface/firestore-service.interface";
import { FirebaseRequestsService } from "src/app/services/firebase-requests.service";

/**
 * Servicio de Firestore.
 * M stands for Model.
 *
 * Implementación del FirestoreServiceInterface. Es una una abstracción para la lectura de
 * colecciones y documentos, además de la creación, edición y borrado. Su propósito es que pueda
 * extenderse en cada módulo, indicando el path de la colección que se consulta.
 */
@Injectable()
export class FirestoreService<M extends { id: string }>
  implements FirestoreServiceInteface<M>
{
  basePath = "";

  constructor(protected firebaseRequestService: FirebaseRequestsService) {}

  public collection(path: string | undefined = undefined): CollectionReference {
    const pth: string = path === undefined ? `${this.basePath}` : path;
    return this.firebaseRequestService.getFirebaseCollectionReference(pth);
  }

  public collection$(query: QueryConstraint[]): Observable<M[]> {
    return this.firebaseRequestService
      .getCollectionFirebaseWithQueryObservable<M>(`${this.basePath}`, query)
      .pipe(
        tap({
          next: (r: M[]) => {
            if (!environment.production) {
              console.groupCollapsed(
                `Firestore Streaming [${this.basePath}] [collection$]`
              );
              console.table(r);
              console.groupEnd();
            }
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      );
  }

  public async fetchCollection(query: QueryConstraint[]): Promise<M[]> {
    return this.firebaseRequestService.getCollectionFirebasePromiseWithId<M>(
      `${this.basePath}`,
      query
    );
  }

  public doc(id: string): DocumentReference {
    const pathCollection: string = this.collection().path;
    return this.firebaseRequestService.getFirebaseDocReference(
      `${pathCollection}/${id}`
    );
  }

  public doc$(id: string): Observable<M> {
    return this.firebaseRequestService
      .getDocFirebaseWithIdObservable<M>(`${this.basePath}/${id}`)
      .pipe(
        tap({
          next: (r: M) => {
            if (!environment.production) {
              console.groupCollapsed(
                `Firestore Streaming [${this.basePath}] [doc$] ${id}`
              );
              console.log(r);
              console.groupEnd();
            }
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      );
  }

  public async fetchDoc(id: string): Promise<M> {
    return this.firebaseRequestService.getDocFirebaseWithIdPromise<M>(
      `${this.basePath}/${id}`
    );
  }

  public async create(data: M | Partial<M>): Promise<void> {
    const pathCollection: string = this.collection().path;
    const idDoc: string = uuidv4();

    await this.firebaseRequestService.addMDocFirebaseWithCustomId(
      `${pathCollection}/${idDoc}`,
      Object.assign({}, data, { idDoc })
    );

    if (!environment.production) {
      console.groupCollapsed(
        `Firestore Service [${this.basePath}/${idDoc}] [create]`
      );
      console.log("[Id]", idDoc, data);
      console.groupEnd();
    }
  }

  public async delete(id: string): Promise<void> {
    const pathCollection: string = this.collection().path;

    await this.firebaseRequestService.deleteDocFirebase(
      `${pathCollection}/${id}`
    );

    if (!environment.production) {
      console.groupCollapsed(
        `Firestore Service [${this.basePath}/${id}] [delete]`
      );
      console.log("[Id]", id);
      console.groupEnd();
    }
  }

  public async update(id: string, data: Partial<M>): Promise<void> {
    const pathCollection: string = this.collection().path;

    await this.firebaseRequestService.updateDocFirebase(
      `${pathCollection}/${id}`,
      data
    );

    if (!environment.production) {
      console.groupCollapsed(
        `Firestore Service [${this.basePath}/${id}] [update]`
      );
      console.log("[Id]", id, data);
      console.groupEnd();
    }
  }

  public async upsert(data: M | Partial<M>, id?: string): Promise<void> {
    const pathCollection: string = this.collection().path;
    let newId: string;
    if (id !== undefined) {
      newId = id;
    } else if (data.id !== undefined && data.id !== "0") {
      newId = data.id;
    } else {
      newId = uuidv4();
    }

    await this.firebaseRequestService.updateDocFirebaseWithSetDoc(
      `${pathCollection}/${newId}`,
      data
    );

    if (!environment.production) {
      console.groupCollapsed(
        `Firestore Service [${this.basePath}/${id}] [upsert]`
      );
      console.log("[Id]", id, data);
      console.groupEnd();
    }
  }

  createId(): string {
    return uuidv4();
  }
}
