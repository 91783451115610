import { Injectable } from "@angular/core";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  DocumentData,
  DocumentReference,
  Timestamp,
  limit,
  orderBy,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PqrService {
  constructor(private firebaseRequest: FirebaseRequestsService) {}

  async addPQR(data: {
    comentario: string;
    idUsuario: string;
    email: string;
    nombre: string;
  }): Promise<string> {
    try {
      const documentRef: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId("pqr", {
          ...data,
          fecha: Timestamp.now(),
        });

      return documentRef.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding PQR");
    }
  }

  getComentarios(): Observable<DocumentData[]> {
    return this.firebaseRequest.getCollectionFirebaseWithQueryObservable(
      "pqr",
      [orderBy("fecha", "desc"), limit(5)]
    );
  }
}
