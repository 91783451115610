import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimestampPipe } from "./pipes/timestamp.pipe";
import { ConfirmComponent } from "./confirm/confirm.component";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [ConfirmComponent, TimestampPipe],
  imports: [CommonModule, HttpClientModule, TranslateModule.forChild()],
  exports: [ConfirmComponent, HttpClientModule, TimestampPipe],
})
export class CoreModule {}
