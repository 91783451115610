import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { saveAs } from "file-saver";
import { environment } from "src/environments/environment";
import { firstValueFrom } from "rxjs";

/**
 * Servicio para descargar archivos
 */
@Injectable({
  providedIn: "root",
})
export class FileDownloaderService {
  constructor(private http: HttpClient) {}

  /**
   * Descarga un archivo desde la url especificada
   * y lo guarda con el nombre indicado en fileName
   *
   * @param url Url del archivo para descargar
   * @param fileName Nombre del archivo como se guardará local
   */
  async download(url: string, fileName: string): Promise<void> {
    firstValueFrom(this.http.get(url, { responseType: "blob" }))
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((e) => {
        if (!environment.production) {
          console.groupCollapsed(`FileDownloaderService`);
          console.log("Error al intentar descargar un archivo.");
          console.log("url", url);
          console.log("fileName", fileName);
          console.log(e);
          console.groupEnd();
        }
      });
  }
}
