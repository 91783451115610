<div>
  <h2 class="text-center">
    {{ "calificacion-y-comentarios.felicitaciones" | translate }}
  </h2>
  <div>
    <p class="justify-content">
      {{ "calificacion-y-comentarios.hasCulminado" | translate }}
    </p>
  </div>
  <form [formGroup]="calificacionform" (ngSubmit)="onCreate()">
    <div class="form-group">
      <label for="calificacionVendedor">{{
        "calificacion-y-comentarios.calificacion" | translate
      }}</label>
      <select
        class="form-select"
        aria-label="Default select example"
        id="calificacionVendedor"
        aria-describedby="indicacionesSelect"
        name="puntuacion"
        formControlName="puntuacion"
      >
        <option *ngFor="let item of [1, 2, 3, 4, 5]" [value]="item">
          {{ item }}
        </option>
      </select>
      <small id="indicacionesSelect" class="form-text text-muted">
        {{ "calificacion-y-comentarios.calificaComprador" | translate }}
      </small>
    </div>
    <div class="form-group">
      <label for="comentariosCalificacionComentarios">
        {{ "calificacion-y-comentarios.dejaComentarios" | translate }}
      </label>
      <textarea
        class="form-control"
        id="comentariosCalificacionComentarios"
        rows="3"
        aria-describedby="indicacionesComentarios"
        name="comentarios"
        formControlName="comentarios"
      >
      </textarea>
      <small id="indicacionesComentarios" class="form-text text-muted">
        {{ "calificacion-y-comentarios.describe" | translate }}
      </small>
    </div>
    <div class="modal-footer">
      <button
        #btnCloseModal
        type="button"
        class="btn btn-danger"
        data-bs-dismiss="modal"
      >
        {{ "calificacion-y-comentarios.noEnviar" | translate }}
      </button>
      <button type="submit" class="btn btn-success">
        {{ "calificacion-y-comentarios.enviarCalificacion" | translate }}
      </button>
    </div>
  </form>
</div>
