import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CalificacionyComentarios } from "../models/CalificacionyComentarios";
import { AuthService } from "../services/auth.service";
import { CalificacionService } from "../services/calificacion.service";
import { Timestamp } from "@angular/fire/firestore";

@Component({
  selector: "app-calificacion-y-comentarios",
  templateUrl: "./calificacion-y-comentarios.component.html",
  styleUrls: ["./calificacion-y-comentarios.component.css"],
})
export class CalificacionyComentariosComponent {
  @Input() hideComentarios!: boolean;
  @Input() datosCalificacion: any;
  @Output() idCalificacion: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("btnCloseModal") btnCloseModal!: ElementRef;

  calificacionform: FormGroup = new FormGroup({
    puntuacion: new FormControl("", Validators.required),
    comentarios: new FormControl("", Validators.required),
  });

  constructor(
    public authService: AuthService,
    public calificacionService: CalificacionService
  ) {}

  onCreate = async (): Promise<void> => {
    if (this.calificacionform.invalid) {
      this.calificacionform.markAllAsTouched();
      return;
    }

    const calificacionform: CalificacionyComentarios = {
      ...this.datosCalificacion,
      puntuacion: +this.calificacionform.value.puntuacion,
      comentarios: this.calificacionform.value.comentarios,
      tiempoCreado: Timestamp.now(),
    };

    try {
      const result: string =
        await this.calificacionService.addCalificacion(calificacionform);
      this.idCalificacion.emit(result);
      this.btnCloseModal.nativeElement.click();
      this.calificacionform.reset();
    } catch (error) {
      window.alert("error calificando");
      console.error(error);
    }
  };
}
