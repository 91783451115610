import { Component, OnInit, TemplateRef } from "@angular/core";
import { Observable, PartialObserver } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { mustMatch } from "src/app/validator/must-match.validator";
import validatorHelper from "../../utils/validatorHelper";
import { isUniqueEmail } from "src/app/validator/email.validator";
import {
  NgbModal,
  NgbModalConfig,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Path } from "src/app/enums/Path";
import { UserData, UserService } from "src/app/services/user.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-datos-personales",
  templateUrl: "./datos-personales.component.html",
  styleUrls: ["./datos-personales.component.css"],
})
export class DatosPersonalesComponent implements OnInit {
  public user$!: Observable<UserData>;
  public passwordForm!: FormGroup;
  public personalDataForm!: FormGroup;
  public emailForm!: FormGroup;
  modalCorreo!: NgbModalRef;
  loadingCorreo!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userSvc: UserService,
    private modalService: NgbModal,
    private router: Router,
    private config: NgbModalConfig,
    public activatedRoute: ActivatedRoute,
    public userService: UserService
  ) {
    this.config.backdrop = "static";
    this.config.keyboard = false;
  }

  ngOnInit(): void {
    this.initForms();
    const ID: string = this.activatedRoute.snapshot.params["id"];
    this.user$ = this.userService.getUserById(ID);
    this.setPersonalData();
  }

  initForms(): void {
    // Formulario para cambio de contraseña
    this.passwordForm = this.formBuilder.group(
      {
        currentPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(validatorHelper.PASSWORD_PATTERN),
          ],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(validatorHelper.PASSWORD_PATTERN),
          ],
        ],
        rePassword: [
          "",
          [
            Validators.required,
            Validators.pattern(validatorHelper.PASSWORD_PATTERN),
          ],
        ],
      },
      {
        validators: mustMatch("password", "rePassword"),
      }
    );

    this.emailForm = new FormGroup({
      email: new FormControl(
        "",
        [
          Validators.required,
          Validators.pattern(validatorHelper.EMAIL_PATTERN),
        ],
        [isUniqueEmail(this.userSvc)]
      ),
    });

    // Formulario para datos personales de usuario
    this.personalDataForm = this.formBuilder.group({
      nombres: ["", Validators.required],
      pais: ["", Validators.required],
      departamento: ["", Validators.required],
      municipio: ["", Validators.required],
      email: ["", Validators.required],
      telefono: ["", Validators.required],
      direccion: ["", Validators.required],
    });
    this.personalDataForm.disable();
  }

  setPersonalData(): void {
    const observer: PartialObserver<UserData> = {
      next: (userData: UserData) => this.personalDataForm.patchValue(userData),
      error: (err) => console.warn(err),
    };

    this.user$.subscribe(observer);
  }

  cambiarClave(): void {
    if (this.passwordForm.valid) {
      const getField = (campo: string) => this.passwordForm.value[campo];
      this.authService.changePassword(
        getField("currentPassword"),
        getField("password")
      );
      this.resetFormAndFiles();
    }
  }

  abrirModalCorreo(modalRef: TemplateRef<any>): void {
    this.modalCorreo = this.modalService.open(modalRef, { centered: true });
  }

  cambiarCorreo(callback?: () => void): void {
    this.loadingCorreo = true;
    if (this.emailForm.valid) {
      const { email } = this.emailForm.value;
      this.authService
        .changeEmail(this.activatedRoute.snapshot.params["id"], email)
        .then(() => {
          this.loadingCorreo = false;
          alert("Correo Actualizado");
          this.authService.logout();
          this.router.navigateByUrl(Path.Home);
          if (callback) callback();
        })
        .catch((err) => {
          this.loadingCorreo = false;
          console.error(err);
          alert("Error al actualizar correo");
        });
    }
  }

  isValidField(field: string): boolean {
    return this.passwordForm.get(field)?.valid || false;
  }

  isInvalidField(field: string): boolean {
    return (
      ((this.passwordForm.get(field)?.touched ||
        this.passwordForm.get(field)?.dirty) &&
        !this.passwordForm.get(field)?.valid) ||
      false
    );
  }

  resetFormAndFiles(): void {
    this.passwordForm.reset();
  }
}
