import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CrearProductoComponent } from "../../crear-producto.component";
import { BaseFormComponent } from "../base-form.component";
import { subproductoInsumos } from "src/app/models/subproductoInsumos";
import { monedaLista } from "src/assets/data/monedas";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";

@Component({
  selector: "app-insumos",
  templateUrl: "./insumos.component.html",
  styleUrls: ["./insumos.component.css"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InsumosComponent extends BaseFormComponent implements OnInit {
  public productos: string[] = subproductoInsumos;
  public listaMonedas: string[] = monedaLista;

  constructor(
    public override crearProductoComponent: CrearProductoComponent,
    public override authService: AuthService,
    public override userService: UserService,
    public override formBuilder: FormBuilder,
    public override baseFormService: BaseFormService
  ) {
    super(
      crearProductoComponent,
      authService,
      userService,
      formBuilder,
      baseFormService
    );
  }

  override ngOnInit(): void {
    this.addControls();
    this.updateAllLocations();
  }

  addControls(): void {
    // se agregan controls para formulario

    const fields = [
      { nombre: "producto", validators: [Validators.required] },
      { nombre: "marca", validators: [Validators.required] },
      { nombre: "modelo", validators: [Validators.required] },
      { nombre: "fechaFabricacion", validators: [Validators.required] },
      { nombre: "disponibilidadProducto", validators: [Validators.required] },
      { nombre: "vencimientoProducto", validators: [Validators.required] },
    ];

    const fieldsToRm: string[] = [
      "especie",
      "sistema",
      "altura",
      "grosor",
      "alturaBolsa",
      "calibreBolsa",
    ];

    fields.map((field) =>
      this.productoForm.addControl(
        field.nombre,
        this.formBuilder.control("", field.validators)
      )
    );

    fieldsToRm.map((field: string) => this.productoForm.removeControl(field));

    this.visualizar && this.baseFormService.completForm.next(this.productoForm);
  }
}
