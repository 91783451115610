import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { User } from "@angular/fire/auth";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import moment from "moment";
import { of, Subject, Observable, EMPTY, firstValueFrom } from "rxjs";
import { catchError, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { ComprasService } from "src/app/services/compras.service";
import { ProductoService } from "src/app/services/producto.service";
import { StorageService } from "src/app/services/storage.service";
import { UserService } from "src/app/services/user.service";
import { PedidosSeleccionados } from "src/app/models/pedidosSeleccionados.interface";
import { UserDataHelpers } from "src/app/utils/getApellidosUserData";
import { ProductoData } from "src/app/models/ProductoData";
import { UploadTaskSnapshot } from "firebase/storage";

@Component({
  selector: "app-ventas",
  templateUrl: "./ventas.component.html",
  styleUrls: ["./ventas.component.css"],
})
export class VentasComponent implements OnInit, OnDestroy {
  @ViewChild("closeButton") closeButton!: ElementRef;
  @ViewChild("cerrarModal") cerrarModal!: ElementRef;
  private unsubscriber: Subject<void> = new Subject();
  private pedidoSeleccionado!: PedidosSeleccionados;
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  public misPedidos: any = [];
  public userID!: string;
  public user$: Observable<User | null> = this.authService.authStateChanged();

  /* info modal pedidos*/
  public precio!: number;
  public categoria!: string;
  public especie!: string;

  originalData = [];
  datosCalificacion: any;
  pedidoACalificar: any;
  guiaMostrar: any;
  public detalles:
    | {
        categoria: string;
        precio: number;
        especie: string;
        unidadMedida: string;
        unidadesSolicitadas: string;
        total: number;
        fecha: Date;
        nombre: string;
        email: string;
        telefono: number | string;
      }
    | undefined;
  loading: boolean = false;

  constructor(
    private comprasService: ComprasService,
    private authService: AuthService,
    private userService: UserService,
    private productoService: ProductoService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getPedidos();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private async processProductos(
    data: DocumentData,
    productos: any[],
    userID: string
  ): Promise<any[]> {
    for (let i = 0; i < productos.length; i++) {
      const producto = productos[i];
      if (producto.idVendedor === userID) {
        const [productoInfo, compradorInfo, guiaInfo] = await Promise.all([
          firstValueFrom(this.getProducto(producto.idProducto)),
          firstValueFrom(this.getComprador(data["idComprador"])),
          firstValueFrom(this.getGuia(producto.id)),
        ]);
        producto["producto"] = productoInfo;
        producto["comprador"] = compradorInfo;
        producto["guia"] = guiaInfo
          ? this.sanitizer.bypassSecurityTrustResourceUrl(guiaInfo)
          : null;
      }
    }
    return productos;
  }

  private formatFecha(fecha: { seconds: number }): string {
    return moment(fecha.seconds * 1000)
      .locale("es-US")
      .format("DD/MM/YYYY");
  }

  async getPedidos() {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: User | null) => {
            if (user) {
              this.userID = user.uid;
              this.comprasService
                .getPedidosVendedor(user.uid)
                .pipe(
                  takeUntil(this.unsubscriber),
                  tap({
                    next: async (res: DocumentData[]) => {
                      let cont: number = 1;
                      for (let pos = 0; pos < res.length; pos++) {
                        const pedido = res[pos];
                        pedido["realPos"] = +pos;
                        if (pedido["productos"]) {
                          pedido["productos"] = await this.processProductos(
                            res[pos],
                            pedido["productos"],
                            user.uid
                          );
                        }
                        pedido["fechaFormat"] = this.formatFecha(
                          pedido["fecha"]
                        );
                        pedido["pos"] = cont;
                        cont++;
                      }

                      this.misPedidos = res.sort(
                        (a: any, b: any) => b.fecha.seconds - a.fecha.seconds
                      );
                    },
                    error: (err) => {
                      console.error(err);
                    },
                  })
                )
                .subscribe();
            }
          },
        })
      )
      .subscribe();
  }

  getComprador(id: string): Observable<string> {
    return this.userService.getUserById(id).pipe(
      map((user) => {
        return `${user.nombres || ""} ${UserDataHelpers.getApellidos(user)}`;
      })
    );
  }

  getProducto(id: string): Observable<{ categoria: string; especie: string }> {
    return this.productoService.getProductById(id).pipe(
      map((producto: ProductoData) => {
        const { categoria, especie } = producto || {
          categoria: "",
          especie: "",
        };
        return { categoria, especie };
      })
    );
  }

  getDetalle(
    id: string,
    item: any,
    fecha: Timestamp,
    idComprador: string
  ): void {
    this.productoService
      .getProductById(id)
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((producto: ProductoData) => {
          return this.userService.getUserById(idComprador).pipe(
            takeUntil(this.unsubscriber),
            map((comprador) => ({
              nombre: `${comprador.nombres || ""} ${UserDataHelpers.getApellidos(comprador)}`,
              email: `${comprador.email}`,
              telefono: comprador.telefono,
            })),
            map((comprador) => ({
              producto,
              comprador,
            })),
            catchError((error) => {
              console.error("Error obteniendo comprador:", error);
              return EMPTY;
            })
          );
        }),
        tap(({ producto, comprador }) => {
          this.detalles = {
            fecha: new Date(fecha.seconds * 1000),
            categoria: producto.categoria,
            precio: producto.precio,
            especie: producto.especie,
            unidadMedida: producto.unidadMedida,
            unidadesSolicitadas: item.cantidad,
            total: item.valorTotal,
            nombre: comprador.nombre,
            email: comprador.email ? comprador.email : "",
            telefono: comprador.telefono,
          };
        }),
        catchError((error) => {
          console.error("Error obteniendo producto:", error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  getGuia(idItem: string): Observable<string | null> {
    const ruta: string = `guiaEnvio/${idItem}`;
    return this.storageService
      .getStoreUrlImageObservable(ruta)
      .pipe(catchError(() => of(null)));
  }

  closeModal(): void {
    this.closeButton.nativeElement.click();
  }

  async recibir(): Promise<void> {
    const { itemPos, productPos, valor } = this.pedidoSeleccionado;
    const itemPedido = JSON.parse(JSON.stringify(itemPos));
    const id: string = itemPedido.id;
    const producto: any = itemPedido.productos[productPos];
    producto.vendido = valor;

    if (producto.vendido && !Object.hasOwnProperty.call(producto, "recibido")) {
      producto.estado = "pendiente";
    } else if (producto.vendido && producto.recibido) {
      producto.estado = "Finalizado";
    } else {
      producto.estado = "Rechazado";
    }
    producto.vendidoModificado = true;
    delete producto.comprador;
    delete producto.producto;
    delete itemPedido.realPos;
    delete producto.guia;

    try {
      await this.comprasService.updatePedido(id, itemPedido);
      if (!valor) {
        const idProducto = producto.idProducto;
        const cantidad = producto.cantidad;
        this.productoService.restaurarUnidadesDisponibles(idProducto, cantidad);
      }
    } catch (err) {
      console.warn("error al cambiar estado recibido de pedido: ", err);
    }
  }

  calificar(itemPos: any, productPos: any): void {
    const itemPedido = JSON.parse(JSON.stringify(itemPos));

    this.pedidoACalificar = { itemPedido, productPos };
    const datos = {
      idCompra: itemPedido.id,
      idCalificador: itemPedido.productos[productPos].idVendedor,
      idCalificado: itemPedido.idComprador,
      idItem: itemPedido.productos[productPos].id,
    };
    this.datosCalificacion = datos;
  }

  async marcarCalificado(id: string): Promise<void> {
    const pedido = this.pedidoACalificar.itemPedido;
    const productPos = this.pedidoACalificar.productPos;
    pedido.productos[productPos].calificacionVendedor = id;
    delete pedido.productos[productPos].comprador;
    delete pedido.productos[productPos].producto;

    try {
      await this.comprasService.updatePedido(pedido.id, pedido);
      this.cancelarCalificacion();
    } catch (err) {
      console.warn("error al cambiar estado recibido de pedido: ", err);
    }
  }

  cancelarCalificacion(): void {
    this.cerrarModal.nativeElement.click();
    delete this.pedidoACalificar;
  }

  onFileSelected(pedido: string, file: any): void {
    const guia: File = file.target.files[0];
    this.loading = true;
    this.comprasService
      .uploadGuia(pedido, guia)
      .pipe(
        tap({
          next: async (upload: {
            progress: number;
            snapshot: UploadTaskSnapshot;
          }) => {
            if (upload.snapshot.state === "success") {
              await this.getPedidos();
              this.loading = false;
            }
          },
          error: (err) => {
            console.error(err);
            this.loading = false;
          },
        })
      )
      .subscribe();
  }

  seleccionarPedido(itemPos: any, productPos: any, valor: boolean): void {
    this.pedidoSeleccionado = { itemPos, productPos, valor };
  }
}
