<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    {{
      "crear-producto." + (isLoading ? "creandoProducto" : "creadoExitosamente")
        | translate
    }}
  </h5>
</div>
<div class="modal-body">
  <div class="gif" *ngIf="isLoading || !uploadComplete">
    <img src="../../../../assets/imagenes/loading_gif.gif" alt="cargando" />
  </div>
  {{
    "crear-producto." + (isLoading ? "noCerrarVentana" : "avisoCreado")
      | translate
  }}
</div>
<div class="modal-footer">
  <div class="w-100" *ngFor="let item of keysOf(uploads)">
    <p class="mt-2 mb-0">
      {{ item | titlecase }}
    </p>
    <ngb-progressbar
      type="primary"
      textType="white"
      [value]="uploadPercent(item)"
      [showValue]="true"
    >
    </ngb-progressbar>
  </div>
  <button
    [disabled]="isLoading || !uploadComplete"
    type="button"
    class="btn btn-lg m-auto"
    [ngClass]="{
      'btn-secondary': isLoading || !uploadComplete,
      'btn-warning': uploadComplete
    }"
    (click)="closeModal()"
  >
    <span>
      {{ "formularios.continuar" | translate }}
    </span>
  </button>
</div>
