import { Injectable } from "@angular/core";

declare const gtag: (...args: any[]) => () => void;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  static loadGoogleAnalytics(trackingID: string): void {
    // register google tag manager
    const gTagManagerScript: HTMLScriptElement =
      document.createElement("script");
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    document.head.appendChild(gTagManagerScript);

    // register google analytics
    const gaScript: HTMLScriptElement = document.createElement("script");
    gaScript.innerHTML = `
       window.dataLayer = window.dataLayer || [];
       function gtag() { dataLayer.push(arguments); }
       gtag('js', new Date());
       gtag('config', '${trackingID}');
     `;
    document.head.appendChild(gaScript);
  }

  static trackPageView(trackingId: string, path: string): void {
    gtag("config", trackingId, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      page_path: path,
    });
  }
}
