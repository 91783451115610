import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Revista } from "src/app/models/Revista";
import { Observable, of, Subject } from "rxjs";
import { timestampToDate } from "src/app/core/utils/date-utils";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalPdfComponent } from "../modal-pdf/modal-pdf.component";
import { ModalConfirmacionComponent } from "../modal-confirmacion/modal-confirmacion.component";
import { RevistasService } from "src/app/services/revistas.service";
@Component({
  selector: "app-tabla-revistas",
  templateUrl: "./tabla.component.html",
  styleUrls: ["./tabla.component.css"],
})
export class TablaComponent implements OnInit {
  @Output() revistaToEdit: EventEmitter<Revista> = new EventEmitter();
  public revistas: Observable<Revista[]> = of([]);
  public timestampToDate = timestampToDate;

  public constructor(
    public revistasSvc: RevistasService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.traerRevistas();
  }

  // Función para listar revistas
  traerRevistas() {
    this.revistas = this.revistasSvc.getRevistas();
  }

  seleccionarRevistaEditar(revista: Revista): void {
    this.revistaToEdit.emit(revista);
  }

  abrirPdf(revista: Revista): void {
    const modal: NgbModalRef = this.modalService.open(ModalPdfComponent, {
      scrollable: false,
      size: "xl",
      backdrop: "static",
    });

    const { componentInstance } = modal;
    componentInstance.loading = true;
    componentInstance.pdfUrl = revista.pdf;

    (componentInstance.closeEvent as Subject<boolean>).subscribe((close) => {
      if (close) modal.dismiss();
    });
  }

  openDeleteModal(revista: Revista) {
    const modal: NgbModalRef = this.modalService.open(
      ModalConfirmacionComponent,
      {
        size: "xs",
      }
    );
    const { componentInstance } = modal;
    componentInstance.encabezado = "Eliminación de revista";
    componentInstance.cuerpo = "¿Está seguro de eliminar revista?";

    (componentInstance.closeEvent as Subject<boolean>).subscribe(
      async (confirm) => {
        modal.dismiss();
        if (confirm && revista.id) {
          try {
            await this.revistasSvc.delete(revista.id);
            alert("revista eliminada");
          } catch (e) {
            console.error(e);
          }
        }
      }
    );
  }
}
