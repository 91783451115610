<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="misCompras-tab"
      data-bs-toggle="tab"
      data-bs-target="#misCompras"
      type="button"
      role="tab"
      aria-controls="misCompras"
      aria-selected="true"
    >
      {{ "compras.misCompras" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="mensajes-tab"
      data-bs-toggle="tab"
      data-bs-target="#mensajes"
      type="button"
      role="tab"
      aria-controls="mensajes"
      aria-selected="false"
    >
      {{ "mensajes.mensajes" | translate }}
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="misCompras"
    role="tabpanel"
    aria-labelledby="misCompras-tab"
  >
    <h3 class="titulo-contenido-perfil">
      {{ "compras.misCompras" | translate }}
    </h3>
    <p>
      {{ "compras.encontrarInformacion" | translate }}
    </p>
    <app-certificado-producto
      (abrirModalCertificado)="abrirModalCertificado = $event"
    ></app-certificado-producto>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "compras.pedido" | translate }}</th>
            <th scope="col">{{ "formularios.categoria" | translate }}</th>
            <th scope="col">{{ "formularios.especie" | translate }}</th>
            <th scope="col">{{ "compras.nombreComprador" | translate }}</th>
            <th scope="col">{{ "compras.vendido" | translate }}</th>
            <th scope="col">{{ "compras.enviado" | translate }}</th>
            <th scope="col">{{ "compras.recibido" | translate }}</th>
            <th scope="col">{{ "compras.estado" | translate }}</th>
            <th scope="col">{{ "compras.calificacion" | translate }}</th>
            <th scope="col">{{ "compras.certificado" | translate }}</th>
          </tr>
        </thead>
        <tbody
          class="contenido-tabla"
          *ngFor="
            let item of misPedidos
              | paginate
                : { itemsPerPage: itemsPerPage, currentPage: currentPage };
            let itemPos = index
          "
        >
          <tr *ngFor="let pedido of item.productos; let productoPos = index">
            <th scope="row">{{ item.fechaFormat }}</th>
            <td>{{ pedido.producto?.categoria }}</td>
            <td>{{ pedido.producto?.especie }}</td>
            <td>
              {{ pedido.vendedor }}
            </td>

            <td>
              {{ "formularios." + (pedido.vendido ? "si" : "no") | translate }}
            </td>
            <td>
              <button
                type="button"
                [disabled]="!pedido.guia || pedido.estado === 'Rechazado'"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#modalGuia"
                title="Descargue la guía de envío"
                (click)="guiaMostrar = pedido.guia"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/file-download-solid.svg"
                />
              </button>
            </td>
            <td>
              <button
                (click)="seleccionarPedido(item, productoPos, true)"
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmOrder"
                type="button"
                class="btn btn-success"
                [disabled]="
                  pedido.recibidoModificado ||
                  !pedido.guia ||
                  pedido.estado === 'Rechazado'
                "
                *ngIf="
                  !pedido.recibidoModificado ||
                  (pedido.recibido && pedido.recibidoModificado)
                "
              >
                {{ "formularios.si" | translate }}
              </button>
              <button
                (click)="seleccionarPedido(item, productoPos, false)"
                id="botonNo1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalRefuseOrder"
                class="btn btn-danger"
                [disabled]="
                  pedido.recibidoModificado ||
                  !pedido.guia ||
                  pedido.estado === 'Rechazado'
                "
                *ngIf="
                  (pedido.recibidoModificado && !pedido.recibido) ||
                  !pedido.recibidoModificado
                "
              >
                {{ "formularios.no" | translate }}
              </button>
            </td>
            <td>{{ pedido.estado }}</td>
            <td>
              <button
                [disabled]="
                  pedido.calificacionComprador || pedido.estado === 'pendiente'
                "
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#calificacionModal"
                title="Califique al vendedor"
                (click)="calificar(item, productoPos)"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/thumbs-up-solid.svg"
                />
              </button>
            </td>
            <td>
              <button
                (click)="abrirModalCertificado(item.id, pedido.id)"
                class="btn btn-success"
                title="Descargue el certificado de la compra"
                [disabled]="pedido.estado !== 'Finalizado'"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/file-download-solid.svg"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--paginado para los resultados-->
    <pagination-controls
      class="my-pagination"
      (pageChange)="currentPage = $event"
      previousLabel="Anterior"
      nextLabel="Siguiente"
    >
    </pagination-controls>
  </div>
  <div
    class="tab-pane fade"
    id="mensajes"
    role="tabpanel"
    aria-labelledby="mensajes-tab"
  >
    <h3 class="titulo-contenido-perfil">
      {{ "compras.misMensajes" | translate }}
    </h3>
    <p>{{ "compras.parrafoMensaje" | translate }}</p>
    <app-mensajes [idComprador]="user.uid"></app-mensajes>
  </div>
</div>

<!--Modal calificación y comentarios para el vendedor-->
<div
  class="modal fade"
  id="calificacionModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-calificacion-y-comentarios
          [datosCalificacion]="datosCalificacion"
          (idCalificacion)="marcarCalificado($event)"
        ></app-calificacion-y-comentarios>
      </div>
      <button
        #cerrarModal
        hidden
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
</div>

<!--Modal para visualizar la guía de envío-->
<div
  class="modal fade"
  id="modalGuia"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content" id="modalFile">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "compras.misCompras" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <iframe
          *ngIf="guiaMostrar"
          class="file-modal"
          [src]="guiaMostrar"
          frameborder=""
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        >
        </iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal rechazar pedido-->
<div
  class="modal fade"
  id="modalRefuseOrder"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "compras.rechazar" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          {{ "compras.seguroRechazar" | translate }}
        </p>
        <p>
          {{ "compras.acuerdo" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          #cerrarModal
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="recibir()"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal aceptar pedido-->
<div
  class="modal fade"
  id="modalConfirmOrder"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "compras.aceptarProducto" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          {{ "compras.seguroAceptar" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="recibir()"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
