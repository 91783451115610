<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="factura-tab"
      data-bs-toggle="tab"
      data-bs-target="#factura"
      type="button"
      role="tab"
      aria-controls="factura"
      aria-selected="true"
    >
      Nueva Factura
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="historial-tab"
      data-bs-toggle="tab"
      data-bs-target="#historial"
      type="button"
      role="tab"
      aria-controls="historial"
      aria-selected="false"
    >
      Historial de Facturación
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="contabilidad-tab"
      data-bs-toggle="tab"
      data-bs-target="#contabilidad"
      type="button"
      role="tab"
      aria-controls="contabilidad"
      aria-selected="false"
    >
      Información Contable
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane active"
    id="factura"
    role="tabpanel"
    aria-labelledby="factura-tab"
  >
    <form [formGroup]="factura" (ngSubmit)="onCreate()">
      <h2 class="text-center mt-3">Factura Electrónica de Venta</h2>
      <h4 class="text-center">No. xxxxxxxxxxxxx</h4>
      <div>
        <h3>Datos del Documento</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2 row">
              <label for="fechaDeFacturacion" class="col-sm-4 col-form-label"
                ><b>Fecha de emisión:</b></label
              >
              <div class="col-auto">
                <input
                  #fechaInicio
                  type="date"
                  id="fechaDeFacturacion"
                  name="fechaDeFacturacion"
                  value="2020-07-22"
                  min="2019-01-01"
                  max="2030-12-31"
                  formControlName="fechaFactura"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="tipoOperacion" class="col-sm-4 col-form-label"
                ><b>Tipo de Operación:</b></label
              >
              <div class="col-auto">
                <select
                  id="tipoOperacion"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="tipoOperacion"
                  [ngClass]="{
                    'is-invalid': isInvalidField('tipoOperacion'),
                    'is-valid': isValidField('tipoOperacion')
                  }"
                >
                  <option>05 - Genérica</option>
                </select>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="tipoNegociacion" class="col-sm-4 col-form-label"
                ><b>Tipo de negociación:</b></label
              >
              <div class="col-auto">
                <select
                  id="tipoNegociacion"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="tipoNegociacion"
                  [ngClass]="{
                    'is-invalid': isInvalidField('tipoNegociacion'),
                    'is-valid': isValidField('tipoNegociacion')
                  }"
                >
                  <option>1 - Contado</option>
                  <option>2 - Crédito</option>
                </select>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="tipoNegociacion" class="col-sm-4 col-form-label"
                ><b>Número de factura:</b></label
              >
              <div class="col-auto">
                <input
                  type="number"
                  class="form-control"
                  id="numFactura"
                  formControlName="numeroFactura"
                />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-2 row">
              <label
                for="fechaVencimientoFactura"
                class="col-sm-4 col-form-label"
                ><b>Fecha de vencimiento:</b></label
              >
              <div class="col-auto">
                <input
                  #fechaInicio
                  type="date"
                  id="fechaVencimientoFactura"
                  name="fechaVencimientoFactura"
                  value="2020-07-22"
                  min="2019-01-01"
                  max="2030-12-31"
                  formControlName="fechaVencimientoFactura"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="prefijo" class="col-sm-4 col-form-label"
                ><b>Prefijo:</b></label
              >
              <div class="col-auto">
                <select
                  id="prefijo"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="prefijo"
                  [ngClass]="{
                    'is-invalid': isInvalidField('prefijo'),
                    'is-valid': isValidField('prefijo')
                  }"
                >
                  <option>SEGT (980000000-985000000)</option>
                </select>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="medioDePago" class="col-sm-4 col-form-label"
                ><b>Medio de pago: </b></label
              >
              <div class="col-auto">
                <select
                  id="medioDePago"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="medioDePago"
                  [ngClass]="{
                    'is-invalid': isInvalidField('medioDePago'),
                    'is-valid': isValidField('medioDePago')
                  }"
                >
                  <option>1 - Instrumento no definido</option>
                  <option>10 - Efectivo</option>
                  <option>13 - Crédito ahorro</option>
                  <option>14 - Débito ahorro</option>
                  <option>23 - Cheque bancario</option>
                  <option>31 - Transferencia débito</option>
                  <option>42 - Consignación bancaria</option>
                  <option>47 - Transferencia débito bancaria</option>
                  <option>48 - Tarjeta Crédito</option>
                  <option>49 - Tarjeta débito</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <h3 class="mt-3">Datos del Emisor</h3>
        <div class="row">
          <div class="col-md-4">
            <b>Razón Social:</b> Red Forestal SAS<br />
            <b>Teléfono:</b> (+57) 3165338564 <br />
            <b>Tipo de Contribuyente:</b> Persona jurídica <br />
            <b>Departamento:</b> Bogotá D.C. <br />
          </div>
          <div class="col-md-4">
            <b>NIT:</b> 900.578.597-0<br />
            <b>Email:</b> info@redforestal.com<br />
            <b>Régimen contable:</b> Régimen ordinario<br />
            <B>Municipio</B> Bogotá D.C.<br />
          </div>
          <div class="col-md-4">
            <b>Dirección:</b> Calle 6c #82a-57 <br />
            <div class="mb-2 row">
              <label for="CIUU" class="col-sm-2 col-form-label"
                ><b>CIUU:</b></label
              >
              <div class="col-auto">
                <select
                  id="CIUU"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="actividadEconomica"
                  [ngClass]="{
                    'is-invalid': isInvalidField('actividadEconomica'),
                    'is-valid': isValidField('actividadEconomica')
                  }"
                >
                  <option>7310 - PUBLICIDAD</option>
                  <option>0240 - SERVICIOS</option>
                  <option>7210 - INVESTIGACIONES</option>
                  <option>7490 - OTRAS ACTIVIDADES</option>
                </select>
              </div>
            </div>
            <b>Tipo Responsabilidad:</b> O-38<br />
          </div>
        </div>

        <h3 class="mt-3">Datos del Adquiriente</h3>
        <div class="row pt-2">
          <div class="col-sm-2">
            <label for="busqeudaUsuario" class="form-label"
              ><b>Buscar Usuario</b></label
            >
          </div>
          <div class="col-sm-8">
            <input
              class="form-control"
              list="datalistOptions"
              id="busqeudaUsuario"
              placeholder="Nombre del usuario"
            />
            <datalist id="datalistOptions">
              <option value="San Francisco"></option>
            </datalist>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-success mb-3">
              Buscar cliente
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="tdocumento" class="form-label"
                >Tipo de documento</label
              >
              <input
                type="text"
                class="form-control"
                id="tdocumento"
                placeholder="Example input placeholder"
                formControlName="tdocumento"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="NIT" class="form-label">NIT</label>
              <input
                type="number"
                class="form-control"
                id="NIT"
                placeholder="Example input placeholder"
                formControlName="numeroID"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="nombreCompleto" class="form-label">Nombre</label>
              <input
                type="text"
                class="form-control"
                id="nombreCompleto"
                placeholder="Example input placeholder"
                formControlName="nombreCompleto"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="departamento" class="form-label">Departamento</label>
              <input
                type="text"
                class="form-control"
                id="departamento"
                placeholder="Example input placeholder"
                formControlName="departamento"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="ciudad" class="form-label">Ciudad</label>
              <input
                type="text"
                class="form-control"
                id="ciudad"
                placeholder="Example input placeholder"
                formControlName="ciudad"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="direccion" class="form-label">Dirección</label>
              <input
                type="text"
                class="form-control"
                id="direccion"
                placeholder="Example input placeholder"
                formControlName="direccion"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <label for="numeroTelefono" class="form-label"
                >Número de teléfono</label
              >
              <input
                type="number"
                class="form-control"
                id="numeroTelefono"
                placeholder="Example input placeholder"
                formControlName="numeroTelefono"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Example input placeholder"
                formControlName="email"
              />
            </div>
          </div>
        </div>

        <h3 class="mt-3">Detalle de Productos</h3>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Código</th>
                <th scope="col">Descripción</th>
                <th scope="col">U/M</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Precio Unitario</th>
                <th scope="col">IVA</th>
                <th scope="col">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>
                  <div class="form-group">
                    <select
                      #codigoProducto
                      id="codigoProducto"
                      class="form-select"
                      aria-label="Default select example"
                      name="codigoProducto"
                      formControlName="codigoProducto"
                      [ngClass]="{
                        'is-invalid': isInvalidField('codigoProducto'),
                        'is-valid': isValidField('codigoProducto')
                      }"
                    >
                      <option value="1">001</option>
                      <option value="2">002</option>
                      <option value="3">003</option>
                      <option value="4">004</option>
                      <option value="5">005</option>
                      <option value="6">006</option>
                      <option value="7">007</option>
                      <option value="8">008</option>
                      <option value="9">009</option>
                      <option value="10">010</option>
                    </select>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcionProducto"
                    placeholder="Suscripción 1 mes"
                    formControlName="descripcionProducto"
                    aria-label="Disabled input example"
                    [disabled]="true"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    id="unidadProducto"
                    placeholder="unidad"
                    formControlName="unidadProducto"
                    aria-label="Disabled input example"
                    [disabled]="true"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    id="cantidad"
                    placeholder="cantidad"
                    formControlName="cantidad"
                    [ngClass]="{
                      'is-invalid': isInvalidField('cantidad'),
                      'is-valid': isValidField('cantidad')
                    }"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    id="precioSinIva"
                    placeholder="precio sin iva"
                    formControlName="precioSinIva"
                    aria-label="Disabled input example"
                    [disabled]="true"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    id="IVA"
                    placeholder="IVA"
                    formControlName="valorIVA"
                    aria-label="Disabled input example"
                    [disabled]="true"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    id="precioTotal"
                    placeholder="precioTotal"
                    formControlName="precioTotal"
                    aria-label="Disabled input example"
                    [disabled]="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button type="button" class="btn btn-success">Agregar producto</button>

        <h3 class="mt-3">Datos Totales</h3>

        <div class="row">
          <div class="col-md-4">
            <div class="mb-3">
              <div class="form-group">
                <label for="moneda" type="text" name="moneda" class="form-label"
                  >Moneda</label
                >
                <select
                  type="text"
                  id="moneda"
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="moneda"
                  [ngClass]="{
                    'is-invalid': isInvalidField('moneda'),
                    'is-valid': isValidField('moneda')
                  }"
                >
                  <option>COP</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="totalIVA" class="form-label"
                >Total impuesto detalle</label
              >
              <input
                type="number"
                class="form-control"
                id="totalIVA"
                placeholder="Valor total del IVA"
                formControlName="totalIVA"
                aria-label="Disabled input example"
                [disabled]="true"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="totalCompra" class="form-label"
                >Valor total de la operación</label
              >
              <input
                type="number"
                class="form-control"
                id="totalCompra"
                placeholder="Valor total de la compra"
                formControlName="totalCompra"
                aria-label="Disabled input example"
                [disabled]="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr>
              <td><b>Número de Autorización:</b>18760000001</td>
              <td><b>Rango Autorizado:</b> Desde 980000000</td>
              <td><b>Rango Autorizado:</b> Hasta 985000000</td>
              <td><b>Vigencia:</b> 2030-01-19</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button
          data-bs-toggle="modal"
          data-bs-target="#modalAddFactura"
          [disabled]="factura.invalid"
          type="submit"
          class="btn btn-lg"
          [ngClass]="{
            'btn-secondary': factura.invalid,
            'btn-warning': factura.valid
          }"
        >
          Generar factura
        </button>
      </div>
    </form>
  </div>
  <div
    class="tab-pane fade"
    id="historial"
    role="tabpanel"
    aria-labelledby="historial-tab"
  >
    <h2 class="text-center mt-3">Año - Mes</h2>
    <div class="table-responsive">
      <table class="table mt-3">
        <thead class="thead-dark">
          <tr>
            <th scope="col">No Factura</th>
            <th scope="col">Fecha</th>
            <th scope="col">Nombre</th>
            <th scope="col">ID/NIT</th>
            <th scope="col">Subtotal</th>
            <th scope="col">IVA</th>
            <th scope="col">Total</th>
            <th scope="col">Devolución</th>
            <th scope="col">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">980000002</th>
            <td>16/04/2021</td>
            <td>Cenproforest</td>
            <td>1110477490</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>50,000</td>
            <td>
              <button
                class="btn btn-success"
                title="Editar"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalEditarFactura"
              >
                Edit
              </button>
            </td>
          </tr>
          <tr>
            <th scope="row">980000001</th>
            <td>15/04/2021</td>
            <td>Cenproforest</td>
            <td>1110477490</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>0</td>
            <td>
              <button
                class="btn btn-success"
                title="Editar"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalEditarFactura"
              >
                Edit
              </button>
            </td>
          </tr>
          <tr>
            <th scope="row">980000000</th>
            <td>14/04/2021</td>
            <td>Cenproforest</td>
            <td>1110477490</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>0</td>
            <td>
              <button
                class="btn btn-success"
                title="Editar"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#modalEditarFactura"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4>Total Subtotal ingresos mes:</h4>
    <h4>Total IVA mes:</h4>
    <h4>Total ingresos mes:</h4>
  </div>
  <div
    class="tab-pane fade"
    id="contabilidad"
    role="tabpanel"
    aria-labelledby="contabilidad-tab"
  >
    <h2 class="text-center mt-3">Año</h2>
    <div class="table-responsive">
      <table class="table mt-3">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Mes</th>
            <th scope="col">Subtotal ventas</th>
            <th scope="col">IVA</th>
            <th scope="col">Total</th>
            <th scope="col">Devolución</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Enero</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>50,000</td>
          </tr>
          <tr>
            <td>Febrero</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>0</td>
          </tr>
          <tr>
            <td>Marzo</td>
            <td>43,000</td>
            <td>7,000</td>
            <td>50,000</td>
            <td>0</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4>Total Subtotal ingresos año:</h4>
    <h4>Total IVA año:</h4>
    <h4>Total ingresos año:</h4>
  </div>
</div>

<!--modal, ventana emergente para informar de la creación exitosa de la factura-->
<div
  class="modal fade"
  id="modalAddFactura"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Creación exitosa</h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Se ha generado una nueva factura exitosamente, se enviará una copia al
        correo del cliente.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
<!--modal, ventana emergente para editar factura-->
<div
  class="modal fade"
  id="modalEditarFactura"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Factura</h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="pr-3">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Número de factura</label
            >
            <input
              type="number"
              class="form-control"
              id="numFactura"
              formControlName="numFactura"
            />
            <label for="nombreCompleto" class="form-label">Nombre</label>
            <input
              type="text"
              class="form-control"
              id="nombreCompleto"
              formControlName="nombreCompleto"
            />
            <label for="NIT" class="form-label">NIT</label>
            <input
              type="number"
              class="form-control"
              id="NIT"
              formControlName="numeroID"
            />

            <label for="numeroTelefono" class="form-label"
              >Número de teléfono</label
            >
            <input
              type="number"
              class="form-control"
              id="numeroTelefono"
              formControlName="numeroTelefono"
            />
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              formControlName="email"
            />

            <label for="exampleFormControlInput1" class="form-label"
              >Devolución</label
            >
            <input
              type="number"
              class="form-control"
              id="devolucion"
              formControlName="devolucionFactura"
            />
            <label for="exampleFormControlInput1" class="form-label"
              >Razón de la devolución</label
            >
            <textarea
              type="text"
              class="form-control"
              id="razonDevolucion"
              placeholder="Describa las razones por la cual se devolvieron los recursos"
              formControlName="razonDevolucion"
            ></textarea>
            <label for="medioDePago" class="form-label">Medio de pago:</label>
            <select
              id="medioDePago"
              class="form-select"
              aria-label="Default select example"
              formControlName="medioDePago"
              [ngClass]="{
                'is-invalid': isInvalidField('medioDePago'),
                'is-valid': isValidField('medioDePago')
              }"
            >
              <option>1 - Instrumento no definido</option>
              <option>10 - Efectivo</option>
              <option>13 - Crédito ahorro</option>
              <option>14 - Débito ahorro</option>
              <option>23 - Cheque bancario</option>
              <option>31 - Transferencia débito</option>
              <option>42 - Consignación bancaria</option>
              <option>47 - Transferencia débito bancaria</option>
              <option>48 - Tarjeta Crédito</option>
              <option>49 - Tarjeta débito</option>
            </select>
            <label for="CIUU" class="form-label">CIUU:</label>
            <select
              id="CIUU"
              class="form-select"
              aria-label="Default select example"
              formControlName="actividadEconomica"
              [ngClass]="{
                'is-invalid': isInvalidField('actividadEconomica'),
                'is-valid': isValidField('actividadEconomica')
              }"
            >
              <option>7310 - PUBLICIDAD</option>
              <option>0240 - SERVICIOS</option>
              <option>7210 - INVESTIGACIONES</option>
              <option>7490 - OTRAS ACTIVIDADES</option>
            </select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          Confirmar
        </button>
      </div>
    </div>
  </div>
</div>
