<div *ngIf="control?.errors && control?.touched">
  <small class="aviso-alerta" *ngIf="control?.errors?.['required']">
    {{ "formularios.campoRequerido" | translate }}
  </small>
  <small
    class="aviso-alerta"
    *ngIf="control?.errors?.['min'] || control?.errors?.['menorUno']"
  >
    {{ "crear-producto.valorMinimo1" | translate }}
  </small>

  <small class="aviso-alerta" *ngIf="control?.errors?.['errorComparador']">
    {{ "crear-producto." + control?.errors?.['errorComparador'] | translate }}
  </small>
</div>
