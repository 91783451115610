import { Path } from "../enums/Path";
import { Permiso } from "../models/permisosinterface";

export const permisos: Permiso[] = [
  {
    name: "ventas",
    label: "ventas",
    img: "../../assets/imagenes/shopping-cart-solid.svg",
    path: Path.Ventas,
  },
  {
    name: "compras",
    label: "compras",
    img: "../../assets/imagenes/money-bill-wave-solid.svg",
    path: Path.ComprasUsiario,
  },
  {
    name: "servicios",
    label: "servicios",
    img: "../../assets/imagenes/handshake-solid.svg",
    path: Path.ServiciosUsuario,
  },
  {
    name: "crearProducto",
    label: "crear producto",
    img: "../../assets/imagenes/arbol_vector.svg",
    path: Path.CrearProducto,
  },
  {
    name: "crearSubasta",
    label: "crear subasta",
    img: "../../assets/imagenes/subasta.svg",
    path: Path.Subasta,
  },
  {
    name: "crearServicio",
    label: "crear servicio",
    img: "../../assets/imagenes/iconForest.svg",
    path: Path.CrearServicio,
  },
  {
    name: "contabilidad",
    label: "contabilidad",
    img: "../../assets/imagenes/chart-bar-solid.svg",
    path: Path.Contabilidad,
  },
  {
    name: "favoritos",
    label: "favoritos",
    img: "../../assets/imagenes/heart-solid.svg",
    path: Path.Favoritos,
  },
  {
    name: "miCalificacion",
    label: "mi calificación",
    img: "../../assets/imagenes/star-half-alt-solid.svg",
    path: Path.CalificacionUsuario,
  },
  {
    name: "treeCoin",
    label: "tree coin",
    img: "../../assets/imagenes/donate-solid.svg",
    path: Path.TreeCoinUser,
  },
  {
    name: "perfilUsuarios",
    label: "Perfil Usuarios",
    path: Path.PerfilUsuarios,
    hidden: true,
  },
  {
    name: "carro-compras",
    label: "Carro Compras",
    path: Path.CarroCompras,
    hidden: true,
  },
  {
    name: "licencias",
    label: "Licencias",
    img: "../../assets/imagenes/iconForest.svg",
    path: Path.Licencias,
  },
  {
    name: "reportes",
    label: "Reportes",
    img: "../../assets/imagenes/file-download-solid.svg",
    path: Path.Reportes,
  },
];
