import { Injectable } from "@angular/core";
import { FacturaData } from "../models/factura-data";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { DocumentReference } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class FacturacionService {
  constructor(private firebaseRequest: FirebaseRequestsService) {}

  async addFactura(data: FacturaData): Promise<string> {
    try {
      const collectionReference: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "facturacion",
          data
        );
      return collectionReference.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }
}
