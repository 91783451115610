import { OnDestroy } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { DocumentData } from "@angular/fire/firestore";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { AliadosService } from "../services/aliados.service";
import { Aliado } from "../models/aliado.interface";

interface List {
  [key: string]: Aliado[];
}

@Component({
  selector: "app-alianzas",
  templateUrl: "./alianzas.component.html",
  styleUrls: ["./alianzas.component.css"],
})
export class AlianzasComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<boolean> = new Subject();
  listaAliados: List = { uno: [], dos: [] };

  constructor(private aliados: AliadosService) {}

  ngOnInit(): void {
    this.getAliados();
  }

  getAliados() {
    this.aliados
      .getAllObservsable()
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (value: DocumentData[]) => {
            const lista: List = { uno: [], dos: [] };
            value.forEach((aliado: DocumentData) => {
              lista[aliado["tipo"]] = lista[aliado["tipo"]] || [];
              lista[aliado["tipo"]].push(aliado as Aliado);
            });
            this.listaAliados = lista;
          },
          error(err) {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next(true);
  }

  abrirEnlace(aliado: Aliado): void {
    window.open(aliado.enlace, "_blank");
  }
}
