<div class="container-fluid">
  <!--------Filtro de Búsqueda Responsivo---------------- -->
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <div class="d-grid">
          <button
            class="btn btn-warning collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {{ "catalogo.buscar" | translate }}
          </button>
        </div>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse hide"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <app-filtro></app-filtro>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!--------Filtro de la Búsqueda para PC------------------>
    <div class="col-2">
      <app-filtro></app-filtro>
      <!--sección de publicidad PC-->
      <div class="mt-2">
        <img
          [src]="publicidad.izquierda.src"
          class="img-fluid w-100"
          [ngClass]="{
            'cursor-pointer': publicidad.izquierda?.href ? true : false
          }"
          (click)="goTo(publicidad.izquierda?.href)"
        />
      </div>
      <div class="mt-2">
        <img
          [src]="publicidad.centro.src"
          class="img-fluid w-100"
          [ngClass]="{
            'cursor-pointer': publicidad.centro?.href ? true : false
          }"
          (click)="goTo(publicidad.centro?.href)"
        />
      </div>
      <div class="mt-2">
        <img
          [src]="publicidad.derecha.src"
          class="img-fluid w-100"
          [ngClass]="{
            'cursor-pointer': publicidad.derecha?.href ? true : false
          }"
          (click)="goTo(publicidad.derecha?.href)"
        />
      </div>
    </div>
    <!--------Resultados de la Búsqueda---------------- -->
    <div class="col-10">
      <section class="new-products">
        <h2 class="subtitulo-index">
          {{ "catalogo.resultadosBusqueda" | translate }}
        </h2>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4">
          <app-producto
            *ngFor="let producto of productos"
            [producto]="producto"
          ></app-producto>
        </div>
        <!--paginado para los resultados-->
        <div class="text-center">
          <button
            (click)="getProductos(false)"
            class="btn btn-warning btn-large"
            [disabled]="disableMore"
          >
            {{ "catalogo.verMas" | translate }}
            <div
              class="spinner-border spinner-border-sm"
              role="status"
              *ngIf="this.loading"
            >
              <span class="sr-only">{{ "catalogo.cargando" | translate }}</span>
            </div>
          </button>
        </div>
      </section>
    </div>
    <!---------------------------slider Responsive---------------------------->
    <div class="row mt-2">
      <div class="col-12">
        <h4 class="text-center">
          {{ "catalogo.publicidadPaga" | translate }}
        </h4>
        <ngb-carousel class="picsum-img-wrapper">
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <img
                class="img-fluid w-100"
                [src]="publicidad.izquierda.src"
                [ngClass]="{
                  'cursor-pointer': publicidad.izquierda?.href ? true : false
                }"
                (click)="goTo(publicidad.izquierda?.href)"
              />
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <img
                class="img-fluid w-100"
                [src]="publicidad.centro.src"
                [ngClass]="{
                  'cursor-pointer': publicidad.centro?.href ? true : false
                }"
                (click)="goTo(publicidad.centro?.href)"
              />
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
          <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
              <img
                class="img-fluid w-100"
                [src]="publicidad.derecha.src"
                [ngClass]="{
                  'cursor-pointer': publicidad.derecha?.href ? true : false
                }"
                (click)="goTo(publicidad.derecha?.href)"
              />
            </div>
            <div class="carousel-caption"></div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
