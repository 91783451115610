import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Path } from "../enums/Path";
import { CalificacionComponent } from "./calificacion/calificacion.component";
import { ComprasComponent } from "./compras/compras.component";
import { ContabilidadComponent } from "./contabilidad/contabilidad.component";
import { DatosPersonalesComponent } from "./datos-personales/datos-personales.component";
import { FavoritosComponent } from "./favoritos/favoritos.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { TreeCoinComponent } from "./tree-coin/tree-coin.component";
import { VentasComponent } from "./ventas/ventas.component";
import { CrearProductoComponent } from "./crear-producto/crear-producto.component";
import { CrearServicioComponent } from "./crear-servicio/crear-servicio.component";
import { LicenciasComponent } from "./licencias/licencias.component";
import { ReportesComponent } from "./reportes/reportes.component";

const routes: Routes = [
  {
    path: Path.CalificacionUsuario,
    component: CalificacionComponent,
  },
  {
    path: Path.ComprasUsiario,
    component: ComprasComponent,
  },
  {
    path: Path.Contabilidad,
    component: ContabilidadComponent,
  },
  {
    path: Path.CrearProducto,
    component: CrearProductoComponent,
  },
  {
    path: Path.CrearServicio,
    component: CrearServicioComponent,
  },
  {
    path: `${Path.DatosPersonales}/:id`,
    component: DatosPersonalesComponent,
  },
  {
    path: Path.Favoritos,
    component: FavoritosComponent,
  },
  {
    path: Path.ServiciosUsuario,
    component: ServiciosComponent,
  },
  {
    path: Path.TreeCoinUser,
    component: TreeCoinComponent,
  },
  {
    path: Path.Ventas,
    component: VentasComponent,
  },
  {
    path: Path.Licencias,
    component: LicenciasComponent,
  },
  {
    path: Path.Reportes,
    component: ReportesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PerfilUsuariosRoutingModule {}
