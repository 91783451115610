import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BaseFormService {
  public currentForm: Subject<FormGroup> = new Subject();
  public completForm: Subject<FormGroup> = new Subject();

  constructor() {}

  sendForm(form: FormGroup) {
    this.currentForm.next(form);
  }
}
