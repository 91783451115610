import { ChangeDetectorRef, Component, Input, OnDestroy } from "@angular/core";
import { UploadTaskSnapshot } from "firebase/storage";
import { forkJoin, Subject, switchMap, takeUntil, tap } from "rxjs";
import { ResponseCurrentUpload } from "src/app/models/storage.interface";
import { StorageService } from "src/app/services/storage.service";

interface UploadModal {
  [x: string]: string;
}

@Component({
  selector: "app-modal-crear",
  templateUrl: "./modal-crear.component.html",
  styleUrls: ["./modal-crear.component.css"],
})
export class ModalCrearComponent implements OnDestroy {
  @Input() isLoading!: boolean;
  public uploads: UploadModal = {};
  private closeModalEvent: Subject<boolean> = new Subject<boolean>();
  private unsubscriber: Subject<void> = new Subject();
  uploadAllFiles: Promise<any>[] = [];
  uploadComplete: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    public storageSVC: StorageService
  ) {
    this.storageSVC.currentUploads
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((filesSnap: ResponseCurrentUpload[]) => {
          const arrOb$ = filesSnap.map((filesObs$) => {
            return filesObs$.currentUploads.pipe(
              tap({
                next: (filesSnapInter: {
                  progress: number;
                  snapshot: UploadTaskSnapshot;
                }) => {
                  this.uploads[filesObs$.name] =
                    filesSnapInter.progress.toFixed(1);
                  this.cd.detectChanges();
                },
                error: (err: Error) => {
                  console.error(err);
                },
              })
            );
          });

          return forkJoin(arrOb$);
        }),
        tap({
          next: (
            responses: {
              progress: number;
              snapshot: UploadTaskSnapshot;
            }[]
          ) => {
            if (responses.length) {
              if (responses[0].snapshot.state === "success") {
                this.uploadComplete = true;
              }
            } else {
              this.uploads = {};
              this.uploadComplete = false;
            }
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  closeModal() {
    this.uploads = {};
    this.closeModalEvent.next(true);
    this.closeModalEvent.complete();
  }

  keysOf(object: UploadModal): string[] {
    return Object.keys(object);
  }

  uploadPercent(item: string): number {
    return item ? parseInt(this.uploads[item]) : 0;
  }
}
