<div class="seccion">
  <h2 class="titulo-seccion">
    {{ "certificacion.selloRedForestal" | translate }}
  </h2>
  <p
    class="parrafo-seccion"
    innerHTML="{{ 'certificacion.recuerda' | translate }}"
  ></p>
  <p class="parrafo-seccion">
    {{ "certificacion.verificacion" | translate }}
    <img src="../../assets/imagenes/logo_red_forestal_png.png" /> sobre este.
    {{ "certificacion.identificando" | translate }}
  </p>
  <div class="row row-cols-sm-2 row-cols-xl-4">
    <div class="col">
      <div class="card mb-3 box-shadow">
        <img
          class="card-img-top"
          src="../../assets/imagenes/categoría_Madera_Rolliza.webp"
          alt="Card image cap"
        />
        <div class="card-body">
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <h5 class="card-title-dark">
            {{ "formularios.categoria" | translate }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "certificacion.precioUnidad" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.departamento" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.especie" | translate }}
          </h6>
        </div>
        <div class="card-footer">
          <div class="footer-sello" data-bs-tooltip="Producto Verificado">
            <img
              class="foto-sello"
              src="../../assets/imagenes/logo_red_forestal_png.png"
            />
          </div>
          <div class="footer-perfil">
            <img
              class="foto-perfil"
              src="../../assets/imagenes/logo_perfil_usuario.webp"
              title="Logotipo Vendedor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card mb-3 box-shadow">
        <img
          class="card-img-top"
          src="../../assets/imagenes/categoría_árbol_en_pie.webp"
          alt="Card image cap"
        />
        <div class="card-body">
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <h5 class="card-title-dark">
            {{ "formularios.categoria" | translate }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "certificacion.precioUnidad" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.departamento" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.especie" | translate }}
          </h6>
        </div>
        <div class="card-footer">
          <div class="footer-perfil">
            <img
              class="foto-perfil"
              src="../../assets/imagenes/logo_perfil_usuario.webp"
              title="Logotipo Vendedor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card mb-3 box-shadow">
        <img
          class="card-img-top"
          src="../../assets/imagenes/categoría_Madera_Aserrada.webp"
          alt="Card image cap"
        />
        <div class="card-body">
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <h5 class="card-title-dark">
            {{ "formularios.categoria" | translate }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "certificacion.precioUnidad" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.departamento" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.especie" | translate }}
          </h6>
        </div>
        <div class="card-footer">
          <div class="footer-sello" data-bs-tooltip="Producto Verificado">
            <img
              class="foto-sello"
              src="../../assets/imagenes/logo_red_forestal_png.png"
            />
          </div>
          <div class="footer-perfil">
            <img
              class="foto-perfil"
              src="../../assets/imagenes/logo_perfil_usuario.webp"
              title="Logotipo Vendedor"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card mb-3 box-shadow">
        <img
          class="card-img-top"
          src="../../assets/imagenes/categoría_PFNM.webp"
          alt="Card image cap"
        />
        <div class="card-body">
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <img
            class="svg-icon"
            src="../../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
          />
          <h5 class="card-title-dark">
            {{ "formularios.categoria" | translate }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "certificacion.precioUnidad" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.departamento" | translate }}
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ "formularios.especie" | translate }}
          </h6>
        </div>
        <div class="card-footer">
          <div class="footer-perfil">
            <img
              class="foto-perfil"
              src="../../assets/imagenes/logo_perfil_usuario.webp"
              title="Logotipo Vendedor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sección de pasos para obtener el sello red forestal-->
  <h2 class="titulo-seccion mt-4">
    {{ "certificacion.pasosSello" | translate }}
  </h2>

  <div class="row">
    <div class="col-sm-3">
      <img
        src="../../assets/imagenes/llamanos.png"
        class="img-fluid"
        alt="Imagen de llámanos"
      />
    </div>
    <div class="col-sm-9">
      <h5 class="mt-0 mb-1">{{ "certificacion.paso1" | translate }}</h5>
      <p
        class="parrafo-seccion"
        innerHTML="{{ 'certificacion.paso1Parrafo' | translate }}"
      ></p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-3">
      <img
        src="../../assets/imagenes/inspector.webp"
        class="img-fluid"
        alt="Imagen de inspector"
      />
    </div>
    <div class="col-sm-9">
      <h5 class="mt-0 mb-1">{{ "certificacion.paso2" | translate }}</h5>
      <p class="parrafo-seccion">
        {{ "certificacion.paso2Parrafo" | translate }}
      </p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-3">
      <img
        src="../../assets/imagenes/Administrador.webp"
        class="img-fluid"
        alt="Imagen de administrador"
      />
    </div>
    <div class="col-sm-9">
      <h5 class="mt-0 mb-1">{{ "certificacion.paso3" | translate }}</h5>
      <p class="parrafo-seccion">
        {{ "certificacion.paso3Parrafo" | translate }}
      </p>
    </div>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
