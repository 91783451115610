<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="myServices-tab"
      data-bs-toggle="tab"
      data-bs-target="#myServices"
      type="button"
      role="tab"
      aria-controls="myServices"
      aria-selected="true"
    >
      {{ "tecnico.misServicios" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="createService-tab"
      data-bs-toggle="tab"
      data-bs-target="#createService"
      type="button"
      role="tab"
      aria-controls="createService"
      aria-selected="false"
    >
      {{ "tecnico.crearServicio" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation" *ngIf="!ocultarSolicitudes">
    <button
      class="nav-link"
      id="serviceRequest-tab"
      data-bs-toggle="tab"
      data-bs-target="#serviceRequest"
      type="button"
      role="tab"
      aria-controls="serviceRequest"
      aria-selected="false"
    >
      {{ "tecnico.solicitudes" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="misClasificados-tab"
      data-bs-toggle="tab"
      data-bs-target="#misClasificados"
      type="button"
      role="tab"
      aria-controls="misClasificados"
      aria-selected="false"
    >
      {{ "servicios.misClasificados" | translate }}
    </button>
  </li>
</ul>
<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de consulta y edición de servicios-->
  <div
    class="tab-pane fade show active"
    id="myServices"
    role="tabpanel"
    aria-labelledby="myServices-tab"
  >
    <!--Técnico u Operario Forestal-->
    <h4>{{ "tecnico.tecnico" | translate }}</h4>
    <p>
      {{ "tecnico.aquiPodras" | translate }}
    </p>
    <div class="table-responsive-lg" *ngIf="serviciosUsuario?.length">
      <table class="table mt-3 text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col">{{ "tecnico.tipoServicio" | translate }}</th>
            <th scope="col">{{ "tecnico.anosExperiencia" | translate }}</th>
            <th scope="col">{{ "tecnico.equipoMaquinaria" | translate }}</th>
            <th scope="col">{{ "formularios.accion" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let svc of this.serviciosUsuario; let i = index">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ svc.nameService }}</td>
            <td>{{ svc.yearsExperience }}</td>
            <td>
              <ul>
                <li
                  *ngFor="let item of svc.equipmentMachinery"
                  class="text-left"
                >
                  {{ item }}
                </li>
              </ul>
            </td>
            <td>
              <button
                (click)="currentService = svc.id"
                type="button"
                class="btn btn-danger"
                title="Eliminar producto"
                data-bs-toggle="modal"
                data-bs-target="#modalDeleteTechnicService"
              >
                <img
                  class="img-icon-svg"
                  src="../../../assets/imagenes/trash-alt-solid.svg"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!serviciosUsuario?.length" class="no-servicios">
      <p>{{ "tecnico.noServicios" | translate }}</p>
    </div>
  </div>
  <!--Sección de creación de servicio-->
  <div
    class="tab-pane fade"
    id="createService"
    role="tabpanel"
    aria-labelledby="createService-tab"
  >
    <h4>{{ "tecnico.creaServicios" | translate }}</h4>
    <p>
      {{ "tecnico.aquiPodrasCrear" | translate }}
    </p>
    <!--Formulario para técnicos y operarios forestales-->
    <form
      class="mt-3"
      [formGroup]="addTechnicianServiceForm"
      (ngSubmit)="onCreateForestService()"
    >
      <!--Crear 2, 1 para operario y otro para profesional-->
      <div class="form-group mb-2">
        <label for="nameService">{{
          "tecnico.tipoServicio" | translate
        }}</label>
        <select
          name="nameService"
          class="form-select"
          aria-label="Default select example"
          id="nameService"
          formControlName="nameService"
          [ngClass]="{
            'is-invalid': isInvalidField('nameService'),
            'is-valid': isValidField('nameService')
          }"
          required
        >
          <option value="" selected>
            {{ "tecnico.seleccioneServicio" | translate }}
          </option>
          <option [value]="servicio" *ngFor="let servicio of servicios">
            {{ servicio }}
          </option>
        </select>
      </div>
      <div class="work-experience mb-3">
        <h3>{{ "tecnico.describaExperiencia" | translate }}</h3>
        <div class="form-group">
          <label for="workExperience">{{
            "servicios.experienciaLaboral" | translate
          }}</label>
          <textarea
            type="text"
            name="workExperience"
            class="form-control"
            placeholder="{{ 'tecnico.cualEsServicio' | translate }}"
            id="workExperience"
            formControlName="workExperience"
            [ngClass]="{
              'is-invalid': isInvalidField('workExperience'),
              'is-valid': isValidField('workExperience')
            }"
            required
          ></textarea>
        </div>
        <div class="form-group">
          <label for="yearsExperience">{{
            "tecnico.anosExperiencia" | translate
          }}</label>
          <input
            type="number"
            name="yearsExperience"
            class="form-control"
            placeholder="{{ 'tecnico.anosExperiencia' | translate }}"
            id="yearsExperience"
            formControlName="yearsExperience"
            [ngClass]="{
              'is-invalid': isInvalidField('yearsExperience'),
              'is-valid': isValidField('yearsExperience')
            }"
            required
          />
        </div>
      </div>
      <div class="work-experience mb-3">
        <h3>{{ "tecnico.estudios" | translate }}</h3>
        <div class="form-group">
          <label for="profession">{{ "tecnico.estudios" | translate }}</label>
          <select
            name="profession"
            class="form-select"
            aria-label="Default select example"
            id="profession"
            formControlName="profession"
            [ngClass]="{
              'is-invalid': isInvalidField('profession'),
              'is-valid': isValidField('profession')
            }"
            required
          >
            <option value="" selected>
              {{ "tecnico.seleccioneEstudios" | translate }}
            </option>
            <option [value]="estudio" *ngFor="let estudio of estudios">
              {{ estudio }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="forestCourse">{{
            "tecnico.cursoEspecializado" | translate
          }}</label>
          <input
            type="text"
            class="form-control"
            id="forestCourse"
            name="forestCourse"
            formControlName="forestCourse"
            placeholder="{{ 'tecnico.nombreCurso' | translate }}"
          />
        </div>
        <div class="form-group">
          <label for="profesionalId">{{
            "tecnico.tarjetaProfesional" | translate
          }}</label>
          <select
            name="professionalCard"
            class="form-select"
            aria-label="Default select example"
            id="profesionalId"
            formControlName="professionalCard"
            [ngClass]="{
              'is-invalid': isInvalidField('professionalCard'),
              'is-valid': isValidField('professionalCard')
            }"
            required
          >
            <option selected></option>
            <option>{{ "formularios.no" | translate }}</option>
            <option>{{ "formularios.si" | translate }}</option>
          </select>
        </div>
      </div>
      <div class="work-experience mt-3">
        <h3>{{ "tecnico.equipoMaquinaria" | translate }}</h3>
        <div class="form-group mt-3" formArrayName="equipmentMachinery">
          <label for="equipmentMachinery">{{
            "tecnico.equipos" | translate
          }}</label>
          <select
            *ngFor="let equip of equipmentMachinery; let i = index"
            #campo
            class="form-select mt-1"
            aria-label="Default select example"
            [formControlName]="i"
            [ngClass]="{
              'is-invalid': isInvalidField('equipmentMachinery', i),
              'is-valid': isValidField('equipmentMachinery', i)
            }"
            required
          >
            <option value="" selected>
              {{ "tecnico.seleccioneEquipo" | translate }}
            </option>
            <option
              *ngFor="let maquinaria of maquinariasEquipos"
              [value]="maquinaria"
            >
              {{ maquinaria }}
            </option>
          </select>
        </div>
      </div>
      <div class="btn-add">
        <button
          type="button"
          class="btn btn-success mr-5"
          (click)="addEquipo()"
        >
          <img
            src="../../../assets/imagenes/plus-circle-solid.svg"
            alt="Agrear otro campo"
          />
          <span>{{ "tecnico.agregarOtroCampo" | translate }}</span>
        </button>
        <button type="button" class="btn btn-danger" (click)="removeEquipo()">
          <img
            src="../../../assets/imagenes/minus-circle-solid.svg"
            alt="Quitar un campo"
          />
          <span>{{ "tecnico.quitarUnCampo" | translate }}</span>
        </button>
      </div>
      <div class="work-experience mt-3">
        <h3>{{ "tecnico.medioTransporte" | translate }}</h3>
        <div class="form-group mt-3">
          <label for="carUser">
            {{ "tecnico.queTransporte" | translate }}
          </label>
          <select
            name="carUser"
            class="form-select"
            aria-label="Default select example"
            id="carUser"
            formControlName="carUser"
            [ngClass]="{
              'is-invalid': isInvalidField('carUser'),
              'is-valid': isValidField('carUser')
            }"
            required
          >
            <option>{{ "tecnico.ninguno" | translate }}</option>
            <option>{{ "tecnico.moto" | translate }}</option>
            <option>{{ "tecnico.automovil" | translate }}</option>
            <option>{{ "tecnico.camioneta" | translate }}</option>
          </select>
        </div>
      </div>
      <div class="text-center mt-3">
        <button
          [disabled]="addTechnicianServiceForm.invalid"
          type="submit"
          data-bs-toggle="modal"
          data-bs-target="#modalCrearServicio"
          class="btn btn-warning btn-lg"
          [ngClass]="{
            'btn-secondary': addTechnicianServiceForm.invalid,
            'btn-warning': addTechnicianServiceForm.valid
          }"
        >
          {{ "tecnico.crearServicio" | translate }}
        </button>
      </div>
    </form>
  </div>
  <!--Sección de consulta para servicios solicitados por clientes-->
  <div
    class="tab-pane fade"
    id="serviceRequest"
    role="tabpanel"
    aria-labelledby="serviceRequest-tab"
    *ngIf="!ocultarSolicitudes"
  >
    <h4>{{ "tecnico.serviciosContratados" | translate }}</h4>
    <p>
      {{ "tecnico.aquiEncontraras" | translate }}
    </p>
    <div class="table-responsive-lg">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "formularios.fecha" | translate }}</th>
            <th scope="col">{{ "formularios.nombre" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.municipio" | translate }}</th>
            <th scope="col">{{ "tecnico.servicio" | translate }}</th>
            <th scope="col">{{ "tecnico.estado" | translate }}</th>
            <th scope="col">{{ "tecnico.calificacion" | translate }}</th>
          </tr>
        </thead>
        <tbody class="contenido-tabla">
          <tr *ngFor="let svc of solicitudes">
            <th>
              {{
                svc.fechaContratacion
                  | timestampToString: "es-US" : "DD/MM/YYYY"
              }}
            </th>
            <td>
              {{ svc.user.nombres + " " + (svc.user | transformarApellidos) }}
            </td>
            <td>{{ svc.user.departamento }}</td>
            <td>{{ svc.user.municipio }}</td>
            <td>{{ svc.servicioUsuario.nameService | titlecase }}</td>
            <td>{{ svc.estado | titlecase }}</td>
            <td>
              <button
                [disabled]="
                  svc.calificadoContratado || svc.estado !== 'finalizado'
                "
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#calificacionModalTecnico"
                (click)="currentService = svc.id; setDatosCalificacion(svc)"
              >
                <img src="../../../assets/imagenes/thumbs-up-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--Sección de gestión de clasificados-->
  <div
    class="tab-pane fade"
    id="misClasificados"
    role="tabpanel"
    aria-labelledby="misClasificados-tab"
  >
    <h4 class="texto-subtitulo">
      {{ "servicios.titleClasificados" | translate }}
    </h4>
    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "servicios.tipoServicio" | translate }}</th>
            <th scope="col">{{ "formularios.descripcion" | translate }}</th>
            <th scope="col">{{ "subasta-grid.fechaInicio" | translate }}</th>
            <th scope="col">{{ "subasta-grid.fechaFin" | translate }}</th>
            <th scope="col">{{ "formularios.pais" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.municipio" | translate }}</th>
            <th scope="col">{{ "formularios.accion" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let clasificado of clasificadosList">
            <th scope="row">
              {{ clasificado.nameService }}
            </th>
            <td>{{ clasificado["datosInteres"] }}</td>
            <td>{{ clasificado["fechaInicio"] }}</td>
            <td>{{ clasificado["fechaFin"] }}</td>
            <td>{{ clasificado["pais"] }}</td>
            <td>{{ clasificado["departamento"] }}</td>
            <td>{{ clasificado["municipio"] }}</td>
            <td>
              <button
                type="button"
                class="btn btn-warning"
                title="ver aplicaciones"
                data-bs-toggle="modal"
                data-bs-target="#ModalVerApplyClasificado"
              >
                <img src="../../../assets/imagenes/user-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--modal, ventana emergente para crear servicio-->
<div
  class="modal fade"
  id="modalCrearServicio"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "tecnico.servicioCreado" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-body"
        innerHTML="{{ 'tecnico.servicioCreadoMsj' | translate }}"
      ></div>
      <div class="modal-footer">
        <a type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para eliminar el servicio-->
<div
  class="modal fade"
  id="modalDeleteTechnicService"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "tecnico.eliminarServicio" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-body"
        innerHTML="{{ 'tecnico.realmenteEliminar' | translate }}"
      ></div>
      <div class="modal-footer">
        <a type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </a>

        <a
          (click)="deleteServicio(currentService)"
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
        >
          {{ "formularios.eliminar" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para calificación y comentarios sobre el prestador del servicio-->
<div
  class="modal fade"
  id="calificacionModalTecnico"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-calificacion-y-comentarios
          [datosCalificacion]="datosCalificacion"
          [hideComentarios]="true"
          (idCalificacion)="marcarCalificado($event)"
        ></app-calificacion-y-comentarios>
      </div>
      <button
        #cerrarModal
        hidden
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
</div>
