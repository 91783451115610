/* eslint-disable no-eval */
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const precioValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const precio: AbstractControl | null = control.get("precio");
  const result: boolean = +precio?.value > 0;

  if (result) {
    precio?.setErrors(null);
    return null;
  }

  precio?.setErrors({ menorUno: true });
  return { menorUno: true };
};
