import { FirestoreFailure } from "../failure/failure";
import { Pagination } from "../model/pagination";
import { Stats } from "../model/stats";

/** Estado inicial del CRUD. */
export class InitialState {}
/** Estado 'loading' para cuando está cargando una colección. */
export class LoadingCollectionState {}
/** Estado 'loading' para cuando está cargando un documento. */
export class LoadingDocumentState {}
/** Estado 'loading' cuando se afecta tanto el documento como la colección (Ej: en un delete). */
export class LoadingState {}
/** Estado 'loaded' para cuando está cargando una colección. */
export class CollectionLoadedState {}
/** Estado 'loaded' para cuando está cargando un documento. */
export class DocumentLoadedState {}
/** Estado 'loaded' cuando se afecta tanto el documento como la colección (Ej: en un delete). */
export class LoadedState {}
/** Estado 'modificado' sin guardar los cambios del documento. */
export class DocumentModifiedState {}

export type CrudStateOrFailure =
  | InitialState
  | LoadingCollectionState
  | LoadingDocumentState
  | LoadingState
  | CollectionLoadedState
  | DocumentLoadedState
  | LoadedState
  | DocumentModifiedState
  | FirestoreFailure;

/**
 * Defines a base Crud State.
 * M stands form Model.
 */
export interface CrudState<M> {
  /**
   * Documento --stats-- de la colección que almacena
   * los conteos, estadísticas y demás.
   */
  stats: Stats;

  /**
   * Current pagination options for collection
   */
  pagination: Pagination;

  /**
   * Loaded collection of objects
   */
  collection: Array<M>;

  /**
   * ID del documento activo.
   */
  currentId: string;

  /**
   * Current active document.
   */
  document: M;

  /**
   * Indica el estado (State o Failure) en que se encuentra el CrudState en el instante.
   * Esto permite que un componente pueda responder a los diferentes estados y errores que puedan
   * generarse durante las operaciones CRUD.
   */
  stateOrFailure: CrudStateOrFailure;
}
