<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneMunicipio">{{
        "formularios.municipio" | translate
      }}</label>
      <select
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneMunicipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="seleccioneEspecie">{{
        "formularios.especie" | translate
      }}</label>
      <select
        name="especie"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneEspecie"
        formControlName="especie"
        [ngClass]="{
          'is-invalid': isInvalidField('especie'),
          'is-valid': isValidField('especie')
        }"
        required
      >
        <option value="">
          {{ "formularios.seleccioneEspecie" | translate }}
        </option>
        <option
          *ngFor="let especie of especiesHabilitadas?.sort()"
          [value]="especie"
        >
          {{ especie }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('especie')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadNoMaderable">{{
        "detalle-de-producto.sistemaUnidades" | translate
      }}</label>
      <select
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadNoMaderable"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="">
          {{ "crear-producto.selectUnidadMedida" | translate }}
        </option>
        <option value="plantula">
          {{ "crear-producto.plantula" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="alturaPlanta"
        >{{ "crear-producto.alturaPromedio" | translate }} (cm)
      </label>
      <input
        name="altura"
        class="form-control"
        placeholder="En centímetros"
        id="alturaPlanta"
        formControlName="altura"
        [ngClass]="{
          'is-invalid': isInvalidField('altura'),
          'is-valid': isValidField('altura')
        }"
      />
      <app-errores [control]="productoForm.get('altura')"></app-errores>
    </div>
  </div>
  <div class="text-center">
    <img
      class="img-fluid"
      src="../../../assets/imagenes/CategoriaVivero.png"
      alt="Vivero imagen"
    />
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="alturaBolsa"
        >{{ "crear-producto.alturaBolsa" | translate }} (cm)
      </label>
      <input
        name="alturaBolsa"
        class="form-control"
        placeholder="En centímetros"
        id="alturaBolsa"
        formControlName="alturaBolsa"
        [ngClass]="{
          'is-invalid': isInvalidField('alturaBolsa'),
          'is-valid': isValidField('alturaBolsa')
        }"
      />
      <app-errores [control]="productoForm.get('alturaBolsa')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="grosorBolsa"
        >{{ "crear-producto.grosorBolsa" | translate }} (cm)</label
      >
      <input
        name="grosor"
        class="form-control"
        placeholder="En centímetros"
        id="grosorBolsa"
        formControlName="grosor"
        [ngClass]="{
          'is-invalid': isInvalidField('grosor'),
          'is-valid': isValidField('grosor')
        }"
      />
      <app-errores [control]="productoForm.get('grosor')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="calibreBolsa">{{
        "crear-producto.calibreBolsa" | translate
      }}</label>
      <input
        name="calibreBolsa"
        class="form-control"
        placeholder="Número"
        id="calibreBolsa"
        formControlName="calibreBolsa"
        [ngClass]="{
          'is-invalid': isInvalidField('calibreBolsa'),
          'is-valid': isValidField('calibreBolsa')
        }"
      />
      <app-errores [control]="productoForm.get('calibreBolsa')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantVenta6">{{
        "crear-producto.cantidadDisponible" | translate
      }}</label>
      <input
        name="disponibilidadVenta"
        class="form-control"
        placeholder="Número de plantas"
        id="cantVenta6"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>

    <div class="row" *ngIf="!visualizar">
      <div class="form-group col-md-6">
        <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
        <select
          name="moneda"
          class="form-select"
          aria-label="Default select example"
          id="moneda"
          formControlName="moneda"
          [ngClass]="{
            'is-invalid': isInvalidField('moneda'),
            'is-valid': isValidField('moneda')
          }"
        >
          <option value="">
            {{ "crear-producto.selectMoneda" | translate }}
          </option>
          <option [value]="moneda" *ngFor="let moneda of listaMonedas">
            {{ "crear-producto." + moneda | translate }}
          </option>
        </select>
        <app-errores [control]="productoForm.get('moneda')"></app-errores>
      </div>
      <div class="form-group col-md-6">
        <label for="precioPlanta"
          >{{ "crear-producto.precioPlanta" | translate
          }}<a target="_blank" href="https://issuu.com/redforestal"
            >({{ "crear-producto.preciosReferencia" | translate }})</a
          ></label
        >
        <input
          currencyMask
          [options]="{
            prefix: '$ ',
            inputMode: 0,
            precision: 0,
            thousands: '.'
          }"
          name="precio"
          class="form-control"
          id="precioPlanta"
          formControlName="precio"
          [ngClass]="{
            'is-invalid': isInvalidField('precio'),
            'is-valid': isValidField('precio')
          }"
        />
        <app-errores [control]="productoForm.get('precio')"></app-errores>
      </div>
    </div>

    <div class="form-group col-md-6" *ngIf="frutal">
      <label for="seleccionaInjerto">{{
        "crear-producto.injerto" | translate
      }}</label>
      <select
        name="injerto"
        class="form-select"
        aria-label="Default select example"
        id="seleccionaInjerto"
        formControlName="injerto"
        [ngClass]="{
          'is-invalid': isInvalidField('injerto'),
          'is-valid': isValidField('injerto')
        }"
        required
      >
        <option [value]="1">{{ "crear-producto.SI" | translate }}</option>
        <option [value]="0" selected>
          {{ "crear-producto.NO" | translate }}
        </option>
      </select>
    </div>
    <div
      class="form-group col-md-6"
      *ngIf="frutal && productoForm.get('injerto')?.value === '1'"
    >
      <label for="cantVenta6">{{
        "crear-producto.nombrePatron" | translate
      }}</label>
      <input
        type="text"
        name="nombrePatron"
        class="form-control"
        placeholder="nombre del patrón"
        id="cantVenta6"
        formControlName="nombrePatron"
        [ngClass]="{
          'is-invalid': isInvalidField('nombrePatron'),
          'is-valid': isValidField('nombrePatron')
        }"
      />
      <app-errores [control]="productoForm.get('nombrePatron')"></app-errores>
    </div>
    <div class="form-group col-md-12">
      <label for="otros">{{
        "crear-producto.otrosDatosInteres" | translate
      }}</label>
      <textarea
        class="form-control"
        placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
        name="datosInteres"
        rows="3"
        id="otros"
        formControlName="datosInteres"
      ></textarea>
      <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
    </div>
  </div>
</form>
