import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import moment from "moment";
import { PublicidadService } from "src/app/services/publicidad.service";
import { StorageService } from "src/app/services/storage.service";
import { Observable, Subscription, forkJoin, map, switchMap, tap } from "rxjs";
import { UploadTaskSnapshot } from "firebase/storage";

@Component({
  selector: "app-publicidad",
  templateUrl: "./publicidad.component.html",
  styleUrls: ["./publicidad.component.css"],
})
export class PublicidadComponent {
  @ViewChild("inputImg") inputImg!: ElementRef;
  @ViewChild("btnModal") btnModal!: ElementRef;
  private subscription$!: Subscription;
  formulario!: FormGroup;
  dias: number[] = [1, 7, 15, 30, 90, 180, 365];
  items = {
    catalogo: ["izquierda", "centro", "derecha"],
    slides: ["slide1", "slide2", "slide3"],
  };
  elementoActual!: string;
  imagenes: any = {};
  currentItem: any;
  publicidadActiva: any = {};
  historialPublicidad!: any[];

  constructor(
    private formbuilder: FormBuilder,
    private publicidadService: PublicidadService,
    private storageService: StorageService
  ) {
    this.initForm();
    this.traerPublicidad();
  }

  initForm(): void {
    this.formulario = this.formbuilder.group({
      izquierda: this.generateFormGroup(),
      centro: this.generateFormGroup(),
      derecha: this.generateFormGroup(),
      slide1: this.generateFormGroup(),
      slide2: this.generateFormGroup(),
      slide3: this.generateFormGroup(),
    });
  }

  generateFormGroup(): FormGroup {
    return this.formbuilder.group({
      nombreEmpresa: ["", Validators.required],
      imagen: ["", Validators.required],
      enlace: ["", Validators.required],
      diasPagos: ["", Validators.required],
      fechaInicio: ["", Validators.required],
      fechaTerminacion: ["", Validators.required],
    });
  }

  seleccionarFoto(elemento: string): void {
    this.inputImg.nativeElement.click();
    this.elementoActual = elemento;
  }

  readURL(event: any): void {
    const reader: FileReader = new FileReader();
    reader.onload = () => {
      this.formulario
        .get(this.elementoActual)
        ?.get("imagen")
        ?.setValue(reader.result);
      this.imagenes[this.elementoActual] = event.target.files[0];
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  getSrcImg(item: string): string | boolean {
    return item ? this.formulario.get(item)?.get("imagen")?.value : false;
  }

  setFin(event: any, item: string): void {
    const value = event.srcElement.value;
    const dias = this.formulario.get(item)?.get("diasPagos")?.value;
    if (value && dias) {
      const fechaTerminacion = moment(value)
        .add(dias, "days")
        .format("YYYY-MM-DD");
      this.formulario
        .get(item)
        ?.get("fechaTerminacion")
        ?.setValue(fechaTerminacion);
    }
  }

  guardarPublicidad(item: string): void {
    if (this.formulario.get(item)?.valid) {
      if (!this.publicidadActiva[item]) {
        this.subscription$ = this.subirImagen(item)
          .pipe(
            tap({
              next: async (snap: {
                progress: number;
                snapshot: UploadTaskSnapshot;
              }) => {
                if (
                  snap.progress === 100 &&
                  snap.snapshot.state === "success"
                ) {
                  const data = this.formulario.get(item)?.value;
                  data.imagen = snap.snapshot.ref.fullPath;
                  data.activo = true;
                  data.nombre = item;
                  data.fechaInicio = this.toDate(data.fechaInicio);
                  data.fechaTerminacion = this.toDate(data.fechaTerminacion);
                  try {
                    await this.publicidadService.guardar(data);

                    alert("publicidad guardada");
                    this.publicidadActiva[item] = data;

                    this.subscription$.unsubscribe();
                  } catch (e) {
                    console.error(e);
                  }
                } else if (
                  snap.snapshot.state === "error" ||
                  snap.snapshot.state === "canceled"
                ) {
                  this.subscription$.unsubscribe();
                }
              },
              error: (err) => {
                console.error(err);
                alert("error actualizando imagen");
                this.subscription$.unsubscribe();
              },
            })
          )
          .subscribe();
      } else {
        this.actualizarPublicidad(item);
      }
    }
  }

  toDate(fecha: string, format: string = "") {
    return !format
      ? new Date(moment(fecha).locale("es").format())
      : moment(fecha).locale("es").format(format);
  }

  actualizarPublicidad(item: string) {
    const newValues: any = {};
    Object.keys(this.formulario.get(item)?.value).forEach((key: string) => {
      if (
        this.formulario.get(item)?.value[key] !==
        this.publicidadActiva[item][key]
      ) {
        if (key === "fechaInicio" || key === "fechaTerminacion") {
          newValues[key] = this.toDate(this.formulario.get(item)?.value[key]);
        } else {
          newValues[key] = this.formulario.get(item)?.value[key];
        }
      }
    });

    if (Object.keys(newValues).includes("imagen")) {
      this.subscription$ = this.publicidadService
        .subirFoto(this.publicidadActiva[item].pathImg, this.imagenes[item])
        .pipe(
          tap({
            next: (snap: {
              progress: number;
              snapshot: UploadTaskSnapshot;
            }) => {
              if (snap.progress === 100 && snap.snapshot.state === "success") {
                this.subscription$.unsubscribe();
              } else if (
                snap.snapshot.state === "error" ||
                snap.snapshot.state === "canceled"
              ) {
                this.subscription$.unsubscribe();
              }
            },
            error: (err) => {
              console.error(err);
              alert("error actualizando imagen");
              this.subscription$.unsubscribe();
            },
          })
        )
        .subscribe();
    }

    if (Object.keys(newValues).length) {
      delete newValues.imagen;
      this.publicidadService
        .actualizarPublicidad(this.publicidadActiva[item].id, newValues)
        .then(() => alert("publicidad Actualizada"))
        .catch(console.error);
    }
  }

  subirImagen(nombre: string): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    return this.publicidadService.subirFoto(
      `publicidad/${new Date().getTime()}`,
      this.imagenes[nombre]
    );
  }

  traerPublicidad(): void {
    this.publicidadService
      .traerPublicidad()
      .pipe(
        switchMap((publicidades: DocumentData[]) => {
          const observables = publicidades.map((publicidad: DocumentData) => {
            return this.storageService
              .getStoreUrlImageObservable(publicidad["imagen"])
              .pipe(
                map((url: string) => {
                  publicidad["pathImg"] = publicidad["imagen"];
                  publicidad["imagen"] = url;
                  publicidad["fechaInicio"] = this.timeStampToDate(
                    publicidad["fechaInicio"]
                  );
                  publicidad["fechaTerminacion"] = this.timeStampToDate(
                    publicidad["fechaTerminacion"]
                  );
                  this.publicidadActiva[publicidad["nombre"]] = publicidad;
                  this.formulario
                    .get(publicidad["nombre"])
                    ?.patchValue(publicidad);
                  return publicidad;
                })
              );
          });

          return forkJoin(observables);
        }),
        tap({
          next: () => {},
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  timeStampToDate(timestamp: Timestamp): string {
    return moment(timestamp.seconds * 1000).format("YYYY-MM-DD");
  }

  cambiarEstado(item: string): void {
    const { id, activo } = this.publicidadActiva[item];

    this.publicidadService
      .actualizarPublicidad(id, { activo: !activo })
      .then(() => {
        alert("Estado Actualizado");
        delete this.publicidadActiva[item];
        this.formulario.get(item)?.reset();
      })
      .catch(() => alert("Error al cambiar estado"));
  }

  traerHistorial(): void {
    this.publicidadService
      .traerPublicidad()
      .pipe(
        switchMap((publicidades: DocumentData[]) => {
          const observables = publicidades.map((publicidad: DocumentData) => {
            return this.storageService
              .getStoreUrlImageObservable(publicidad["imagen"])
              .pipe(
                map((url: string) => {
                  publicidad["pathImg"] = publicidad["imagen"];
                  publicidad["imagen"] = url;
                  publicidad["fechaInicio"] = this.timeStampToDate(
                    publicidad["fechaInicio"]
                  );
                  publicidad["fechaTerminacion"] = this.timeStampToDate(
                    publicidad["fechaTerminacion"]
                  );
                  return publicidad;
                })
              );
          });

          return forkJoin(observables);
        }),
        tap({
          next: (publicidades: DocumentData[]) => {
            this.historialPublicidad = publicidades;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  clickHistorial(): void {
    if (!this.historialPublicidad) {
      this.traerHistorial();
    }
  }
}
