<form>
  <div class="form-group">
    <label for="seleccioneCategoria">{{
      "crear-producto.seleccioneCategoria" | translate
    }}</label>
    <select
      name="Categoria"
      class="form-select"
      aria-label="Default select example"
      id="seleccioneCategoria"
      [(ngModel)]="categoria"
      required
    >
      <option selected>
        {{ "crear-producto.seleccioneCategoria" | translate }}
      </option>
      <option *ngFor="let category of categoryUser" [value]="category">
        {{ "categorias-lista." + category | translate }}
      </option>
    </select>
  </div>
</form>

<div [ngSwitch]="categoria">
  <app-bosque *ngSwitchCase="'Bosque'"></app-bosque>
  <app-arbol-en-pie *ngSwitchCase="'Árbol en pie'"></app-arbol-en-pie>
  <app-madera-rolliza *ngSwitchCase="'Madera rolliza'"></app-madera-rolliza>
  <app-madera-aserrada *ngSwitchCase="'Madera aserrada'"></app-madera-aserrada>
  <app-no-maderable
    *ngSwitchCase="'No maderable'"
    [categoria]="categoria"
  ></app-no-maderable>
  <app-vivero [frutal]="false" *ngSwitchCase="'Vivero'"></app-vivero>
  <app-vivero [frutal]="false" *ngSwitchCase="'Vivero ornamental'"></app-vivero>
  <app-vivero [frutal]="true" *ngSwitchCase="'Vivero frutal'"></app-vivero>
  <app-equipo-maquinaria
    *ngSwitchCase="'Equipo y Maquinaria'"
  ></app-equipo-maquinaria>
  <app-insumos *ngSwitchCase="'Insumos'"></app-insumos>
  <app-lands *ngSwitchCase="'Tierras para proyectos'"></app-lands>
  <app-bonos-carbono *ngSwitchCase="'Bonos de carbono'"></app-bonos-carbono>
  <app-producto-terminado
    *ngSwitchCase="'Producto terminado'"
  ></app-producto-terminado>
</div>

<div class="row text-center" *ngIf="categoria">
  <h3 class="texto-subtitulo">
    {{ "crear-producto.cargaImagenes" | translate }}
  </h3>
  <p>
    {{ "crear-producto.recomendamosFormato" | translate }}
  </p>
  <div class="col-md-6">
    <div class="file has-name is-boxed mt-3 border">
      <label class="file-label">{{
        "crear-producto.imagenPrincipal" | translate
      }}</label>
      <label class="btn btn-warning"
        >{{ "crear-producto.imagenPrincipal" | translate }}
        <input
          #fileInputRef
          #file1
          class="file-input"
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileChanged($event, file1, 1)"
          required
        />
      </label>
      <div class="foto-producto border" id="previsualizarFoto">
        <img [src]="getFileUrlForPreview(file1)" />
      </div>
      <label> {{ file1.files?.[0] ? file1.files?.[0]?.name : "" }} </label>
      <button
        type="button"
        class="btn btn-danger"
        [hidden]="!file1.value"
        (click)="removeFile(file1)"
      >
        {{ "formularios.eliminar" | translate }}
      </button>
    </div>
    <div class="file has-name is-boxed mt-3 border">
      <label class="file-label">{{
        "crear-producto.otrasImagenes" | translate
      }}</label>
      <label class="btn btn-warning"
        >{{ "crear-producto.imagenDos" | translate }}
        <input
          #fileInputRef
          #file2
          class="file-input"
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileChanged($event, file2, 2)"
        />
      </label>
      <div class="foto-producto border">
        <img [src]="getFileUrlForPreview(file2)" />
      </div>
      <label> {{ file2.files?.[0] ? file2.files?.[0]?.name : "" }} </label>
      <button
        type="button"
        class="btn btn-danger"
        [hidden]="!file2.value"
        (click)="removeFile(file2)"
      >
        {{ "formularios.eliminar" | translate }}
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div class="file has-name is-boxed mt-3 border">
      <label class="file-label">{{
        "crear-producto.otrasImagenes" | translate
      }}</label>
      <label class="btn btn-warning"
        >{{ "crear-producto.imagenTres" | translate }}
        <input
          #fileInputRef
          #file3
          class="file-input"
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileChanged($event, file3, 3)"
        />
      </label>
      <div class="foto-producto border">
        <img [src]="getFileUrlForPreview(file3)" />
      </div>
      <label> {{ file3.files?.[0] ? file3.files?.[0]?.name : "" }} </label>
      <button
        type="button"
        class="btn btn-danger"
        [hidden]="!file3.value"
        (click)="removeFile(file3)"
      >
        {{ "formularios.eliminar" | translate }}
      </button>
    </div>
    <div class="file has-name is-boxed mt-3 border">
      <label class="file-label">{{
        "crear-producto.otrasImagenes" | translate
      }}</label>
      <label class="btn btn-warning"
        >{{ "crear-producto.imagenCuatro" | translate }}
        <input
          #fileInputRef
          #file4
          class="file-input"
          type="file"
          accept="image/png, image/jpeg"
          (change)="onFileChanged($event, file4, 4)"
        />
      </label>
      <div class="foto-producto border">
        <img [src]="getFileUrlForPreview(file4)" />
      </div>
      <label> {{ file4.files?.[0] ? file4.files?.[0]?.name : "" }} </label>
      <button
        type="button"
        class="btn btn-danger"
        [hidden]="!file4.value"
        (click)="removeFile(file4)"
      >
        {{ "formularios.eliminar" | translate }}
      </button>
    </div>
  </div>
</div>

<div class="text-center" *ngIf="categoria">
  <button
    type="button"
    [disabled]="currentForm.invalid || !hasImg"
    (click)="onCreate()"
    class="btn btn-lg mt-5"
    [ngClass]="{
      'btn-secondary': currentForm.invalid,
      'btn-warning': currentForm.valid
    }"
  >
    {{ "crear-producto.crearProducto" | translate }}
    <span *ngIf="loading === true" class="visually-hidden">{{
      "crear-producto.cargando" | translate
    }}</span>
  </button>
</div>
