import { Injectable } from "@angular/core";
import { catchError, map, switchMap } from "rxjs/operators";
import { StorageService } from "./storage.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { Aliado } from "../models/aliado.interface";
import { DocumentData } from "@angular/fire/firestore";
import { UploadTaskSnapshot } from "firebase/storage";
import { Observable, combineLatest, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AliadosService {
  constructor(
    private firebaseRequests: FirebaseRequestsService,
    private storageService: StorageService
  ) {}

  async create(id: string, data: Aliado): Promise<void> {
    return this.firebaseRequests.addMDocFirebaseWithCustomId(
      `aliados/${id}`,
      data
    );
  }

  saveImg(
    uid: string,
    photo: File
  ): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    const path: string = `aliados/${uid}`;
    return this.storageService.uploadFile(path, photo);
  }

  async getAllPromise(): Promise<DocumentData[]> {
    const aliados: DocumentData[] =
      await this.firebaseRequests.getCollectionFirebasePromiseWithId<DocumentData>(
        "aliados"
      );

    return await Promise.all(
      aliados.map(async (aliado: DocumentData) => {
        try {
          const url: string = await this.storageService.getStoreUrlImagePromise(
            `aliados/${aliado["id"]}`
          );
          aliado["imagen"] = url;

          return aliado;
        } catch (errImg) {
          aliado["imagen"] = "";
          return aliado;
        }
      })
    );
  }

  getAllObservsable(): Observable<DocumentData[]> {
    return this.firebaseRequests
      .getCollectionFirebaseWithQueryObservable<DocumentData>("aliados")
      .pipe(
        switchMap((aliados: DocumentData[]) => {
          const observables = aliados.map((aliado: DocumentData) => {
            return this.storageService
              .getStoreUrlImageObservable(`aliados/${aliado["id"]}`)
              .pipe(
                catchError(() => {
                  return of("");
                }),
                map((url: string) => {
                  aliado["imagen"] = url;

                  return aliado;
                })
              );
          });

          return combineLatest(observables).pipe(
            // Asegurarse de devolver la lista de aliados completa, incluso si hay errores en la obtención de algunas imágenes
            catchError((error) => {
              console.error(
                "Error obteniendo algunas imágenes de aliados:",
                error
              );
              return of(aliados);
            })
          );
        })
      );
  }

  update(id: string, data: Partial<Aliado>): Promise<void> {
    return this.firebaseRequests.updateDocFirebase(`aliados/${id}`, data);
  }

  delete(id: string): Promise<void> {
    return this.firebaseRequests.deleteDocFirebase(`aliados/${id}`);
  }
}
