<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        type="text"
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="municipio">{{ "formularios.municipio" | translate }}</label>
      <select
        type="text"
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="municipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option
          *ngFor="let municipio of listaMunicipios || []"
          [value]="municipio"
        >
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadLand">{{
        "crear-producto.unidadMedida" | translate
      }}</label>
      <select
        type="text"
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadLand"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="">{{ "crear-producto.selectUnit" | translate }}</option>
        <option value="hectarea">
          {{ "crear-producto.hectarea" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
  </div>
  <!-- especific fields for product land -->
  <div class="row">
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantidadLand">{{
        "crear-producto.cantidadDisponible" | translate
      }}</label>
      <input
        type="number"
        name="disponibilidadVenta"
        class="form-control"
        placeholder="{{ 'crear-producto.hectareasQuantity' | translate }}"
        id="cantidadLand"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>

    <div class="form-group col-md-6">
      <label for="terrainInclination">{{
        "crear-producto.slope" | translate
      }}</label>
      <select
        type="text"
        name="terrainInclination"
        class="form-select"
        aria-label="Default select example"
        id="terrainInclination"
        formControlName="terrainInclination"
        [ngClass]="{
          'is-invalid': isInvalidField('terrainInclination'),
          'is-valid': isValidField('terrainInclination')
        }"
      >
        <option value="">{{ "crear-producto.selectSlope" | translate }}</option>
        <option value="plano">
          {{ "crear-producto.plano" | translate }}
        </option>
        <option value="suaveInclinado">
          {{ "crear-producto.suaveInclinado" | translate }}
        </option>
        <option value="inclinado">
          {{ "crear-producto.inclinado" | translate }}
        </option>
        <option value="escarpadoModerado">
          {{ "crear-producto.escarpadoModerado" | translate }}
        </option>
        <option value="escarpado">
          {{ "crear-producto.escarpado" | translate }}
        </option>
        <option value="muyEscarpado">
          {{ "crear-producto.muyEscarpado" | translate }}
        </option>
      </select>
      <app-errores
        [control]="productoForm.get('terrainInclination')"
      ></app-errores>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="waterDisponibility">{{
        "crear-producto.waterAvailability" | translate
      }}</label>
      <select
        type="text"
        name="waterDisponibility"
        class="form-select"
        aria-label="Default select example"
        id="waterDisponibility"
        formControlName="waterDisponibility"
        [ngClass]="{
          'is-invalid': isInvalidField('waterDisponibility'),
          'is-valid': isValidField('waterDisponibility')
        }"
      >
        <option value="ninguno">
          {{ "crear-producto.ninguno" | translate }}
        </option>
        <option value="cienaga">
          {{ "crear-producto.cienaga" | translate }}
        </option>
        <option value="embalse">
          {{ "crear-producto.embalse" | translate }}
        </option>
        <option value="lago">{{ "crear-producto.lago" | translate }}</option>
        <option value="manantial">
          {{ "crear-producto.manantial" | translate }}
        </option>
        <option value="pozo">{{ "crear-producto.pozo" | translate }}</option>
        <option value="rio">{{ "crear-producto.rio" | translate }}</option>
        <option value="quebrada">
          {{ "crear-producto.quebrada" | translate }}
        </option>
      </select>
      <app-errores
        [control]="productoForm.get('waterDisponibility')"
      ></app-errores>
    </div>

    <div class="form-group col-md-6">
      <label for="currentUse">{{
        "crear-producto.currentUseLand" | translate
      }}</label>
      <select
        type="text"
        name="currentUse"
        class="form-select"
        aria-label="Default select example"
        id="currentUse"
        formControlName="currentUse"
        [ngClass]="{
          'is-invalid': isInvalidField('currentUse'),
          'is-valid': isValidField('currentUse')
        }"
      >
        <option value="ninguno">
          {{ "crear-producto.ninguno" | translate }}
        </option>
        <option value="agricola">
          {{ "crear-producto.agricola" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('currentUse')"></app-errores>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6">
      <label for="tempeturature">{{
        "crear-producto.temperature" | translate
      }}</label>
      <input
        type="number"
        name="tempeturature"
        class="form-control"
        id="tempeturature"
        formControlName="tempeturature"
        [ngClass]="{
          'is-invalid': isInvalidField('tempeturature'),
          'is-valid': isValidField('tempeturature')
        }"
      />
      <app-errores [control]="productoForm.get('tempeturature')"></app-errores>
    </div>

    <div class="form-group col-md-6">
      <label for="height">{{ "crear-producto.alturaLote" | translate }}</label>
      <input
        type="number"
        name="height"
        class="form-control"
        id="height"
        formControlName="height"
        [ngClass]="{
          'is-invalid': isInvalidField('height'),
          'is-valid': isValidField('height')
        }"
      />
      <app-errores [control]="productoForm.get('height')"></app-errores>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-md-6">
      <label for="propietaryCertificate">{{
        "crear-producto.landCertificate" | translate
      }}</label>
      <select
        type="text"
        name="propietaryCertificate"
        class="form-select"
        aria-label="Default select example"
        id="propietaryCertificate"
        formControlName="propietaryCertificate"
        [ngClass]="{
          'is-invalid': isInvalidField('propietaryCertificate'),
          'is-valid': isValidField('propietaryCertificate')
        }"
      >
        <option value="SI">
          {{ "crear-producto.SI" | translate }}
        </option>
        <option value="NO">
          {{ "crear-producto.NO" | translate }}
        </option>
        <option value="enProceso">
          {{ "crear-producto.enProceso" | translate }}
        </option>
      </select>
      <app-errores
        [control]="productoForm.get('propietaryCertificate')"
      ></app-errores>
    </div>

    <div class="form-group col-md-6">
      <label for="nearestTown">{{
        "crear-producto.distanciaUrbana" | translate
      }}</label>
      <input
        type="number"
        name="nearestTown"
        class="form-control"
        id="nearestTown"
        formControlName="nearestTown"
        [ngClass]="{
          'is-invalid': isInvalidField('nearestTown'),
          'is-valid': isValidField('nearestTown')
        }"
      />
      <app-errores [control]="productoForm.get('nearestTown')"></app-errores>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6">
      <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
      <select
        name="moneda"
        class="form-select"
        aria-label="Default select example"
        id="moneda"
        formControlName="moneda"
        [ngClass]="{
          'is-invalid': isInvalidField('moneda'),
          'is-valid': isValidField('moneda')
        }"
      >
        <option value="">
          {{ "crear-producto.selectMoneda" | translate }}
        </option>
        <option [value]="moneda" *ngFor="let moneda of listaMonedas">
          {{ "crear-producto." + moneda | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('moneda')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="precioLote">Inversión Requerida</label>
      <input
        currencyMask
        [options]="{
          inputMode: 0,
          precision: 0,
          thousands: '.'
        }"
        name="precio"
        class="form-control"
        id="precioLote"
        formControlName="precio"
        [ngClass]="{
          'is-invalid': isInvalidField('precio'),
          'is-valid': isValidField('precio')
        }"
      />
      <app-errores [control]="productoForm.get('precio')"></app-errores>
    </div>
  </div>
  <div class="form-group col-md-6">
    <label for="producto">{{ "crear-producto.producto" | translate }}</label>
    <select
      type="text"
      name="producto"
      class="form-select"
      aria-label="Default select example"
      id="producto"
      formControlName="producto"
      [ngClass]="{
        'is-invalid': isInvalidField('producto'),
        'is-valid': isValidField('producto')
      }"
    >
      <option value="">Seleccione el producto</option>
      <option value="tierra">Tierras</option>
    </select>
    <app-errores [control]="productoForm.get('producto')"></app-errores>
  </div>

  <div class="form-group col-md-12">
    <label for="otros">{{
      "crear-producto.especificacionesTecnicas" | translate
    }}</label>
    <textarea
      type="text"
      class="form-control"
      placeholder="{{ 'crear-producto.informationLand' | translate }}"
      name="datosInteres"
      rows="3"
      id="otros"
      formControlName="datosInteres"
    ></textarea>
    <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
  </div>
</form>
