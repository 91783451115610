<h3 class="titulo-contenido-perfil">
  {{ "servicios.solicitaServicios" | translate }}
</h3>
<p>
  {{ "servicios.necesitasContratar" | translate }}
</p>
<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="searchServices-tab"
      data-bs-toggle="tab"
      data-bs-target="#searchServices"
      type="button"
      role="tab"
      aria-controls="searchServices"
      aria-selected="true"
    >
      {{ "servicios.buscar" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="hiredServices-tab"
      data-bs-toggle="tab"
      data-bs-target="#hiredServices"
      type="button"
      role="tab"
      aria-controls="hiredServices"
      aria-selected="false"
    >
      {{ "servicios.contratados" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="postServices-tab"
      data-bs-toggle="tab"
      data-bs-target="#postServices"
      type="button"
      role="tab"
      aria-controls="postServices"
      aria-selected="false"
    >
      {{ "servicios.publicarServicio" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="misClasificados-tab"
      data-bs-toggle="tab"
      data-bs-target="#misClasificados"
      type="button"
      role="tab"
      aria-controls="misClasificados"
      aria-selected="false"
    >
      {{ "servicios.misClasificados" | translate }}
    </button>
  </li>
</ul>
<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de consulta de servicios-->
  <div
    class="tab-pane fade show active"
    id="searchServices"
    role="tabpanel"
    aria-labelledby="searchServices-tab"
  >
    <div class="texto-subtitulo">
      <h4>{{ "servicios.buscaServicios" | translate }}</h4>
    </div>
    <form class="form-filtros">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="filter-label">
              {{ "servicios.tipoServicio" | translate }}
            </label>
            <select
            class="form-select"
            aria-label="Default select example"
              (change)="cambiarServicio(serviceType.value)"
              #serviceType
            >
              <option value="">
                {{ "servicios.todosServicios" | translate }}
              </option>
              <option *ngFor="let servicio of tiposServicio" [value]="servicio">
                {{ servicio | titlecase }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="filter-label">
              {{ "servicios.cantidadPagina" | translate }}
            </label>
            <select
            class="form-select"
            aria-label="Default select example"
              (change)="cambiarLimite(pageSize.value)"
              #pageSize
              type="number"
            >
              <option [ngValue]="qty" *ngFor="let qty of [5, 10, 15]">
                {{ qty }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
    <div class="table-responsive-lg" *ngIf="servicios?.length">
      <table class="table text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">No.</th>
            <th scope="col">{{ "formularios.nombre" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.municipio" | translate }}</th>
            <th scope="col">{{ "servicios.servicio" | translate }}</th>
            <th scope="col">{{ "servicios.hojaDeVida" | translate }}</th>
            <th scope="col">{{ "servicios.calificacion" | translate }}</th>
          </tr>
        </thead>
        <tbody class="contenido-tabla">
          <tr
            *ngFor="
              let servicio of servicios;
              let i = index;
              trackBy: trackServices
            "
          >
            <th>
              {{
                this.serviceUser.limitPage * this.serviceUser.currentpage -
                  (this.serviceUser.limitPage - (i + 1))
              }}
            </th>
            <td>
              {{ servicio.user['nombres'] + " " + (servicio.user | transformarApellidos) }}
            </td>
            <td>{{ servicio?.user?.departamento }}</td>
            <td>{{ servicio?.user?.municipio }}</td>
            <td>{{ servicio.nameService | titlecase }}</td>
            <td>
              <button
                type="button"
                class="btn btn-light"
                (click)="setHojaVida(servicio, modalHojaDeVida)"
              >
                <img
                  src="../../../assets/imagenes/eye-solid.svg"
                  alt="Ver Hoja de Vida"
                />
              </button>
            </td>
            <td>
              {{
                servicio?.user?.puntuacion ||
                  ("servicios.sinCalificaciones" | translate)
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btns-paginate">
      <button
        class="btn btn-primary"
        (click)="traerServicios('prev')"
        [disabled]="serviceUser.currentpage === 1"
      >
        <div
          class="spinner-border spinner-border spinner-border-sm"
          role="status"
          *ngIf="loading && lastAct === 'prev'"
        >
          <span class="visually-hidden"></span>
        </div>
        {{ "servicios.anterior" | translate }}
      </button>
      <button
        class="btn btn-primary ml-3"
        (click)="traerServicios('next')"
        [disabled]="serviceUser.fin"
      >
        {{ "servicios.siguiente" | translate }}
        <div
          class="spinner-border spinner-border spinner-border-sm"
          role="status"
          *ngIf="loading && lastAct === 'next'"
        >
          <span class="visually-hidden"></span>
        </div>
      </button>
    </div>
    <div *ngIf="!servicios?.length" class="no-servicios">
      <p>{{ "servicios.noServicios" | translate }}</p>
    </div>
  </div>
  <!--Sección de servicios contratados-->
  <div
    class="tab-pane fade"
    id="hiredServices"
    role="tabpanel"
    aria-labelledby="hiredServices-tab"
  >
    <h4 class="texto-subtitulo">
      {{ "servicios.serviciosContratados" | translate }}
    </h4>
    <p>
      {{ "servicios.aquiEncontraras" | translate }}
    </p>
    <div class="table-responsive-lg" *ngIf="serviciosContratados?.length">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "formularios.fecha" | translate }}</th>
            <th scope="col">{{ "formularios.nombre" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.municipio" | translate }}</th>
            <th scope="col">{{ "servicios.servicio" | translate }}</th>
            <th scope="col">{{ "servicios.terminar" | translate }}</th>
            <th scope="col">{{ "servicios.estado" | translate }}</th>
            <th scope="col">{{ "servicios.calificacion" | translate }}</th>
          </tr>
        </thead>
        <tbody class="contenido-tabla">
          <tr *ngFor="let svc of serviciosContratados">
            <th>{{ svc?.fechaContratacion | timestampToString: "es" : "DD/MM/yyyy" }}</th>
            <td>
              {{ svc?.user?.nombres + " " + (svc.user | transformarApellidos) | titlecase }}
            </td>
            <td>{{ svc?.user?.municipio }}</td>
            <td>{{ svc?.user?.departamento }}</td>
            <td>{{ svc?.servicioUsuario?.nameService }}</td>
            <td>
              <button
                class="btn btn-success"
                (click)="servicioActual = svc; open(modalFinalizar)"
                [disabled]="svc.estado === 'finalizado'"
              >
                {{ "formularios.si" | translate }}
              </button>
            </td>
            <td>{{ svc?.estado | titlecase }}</td>
            <td>
              <button
                [disabled]="
                  svc.calificadoContratado || svc?.estado !== 'finalizado'
                "
                (click)="servicioActual = svc; setDatosCalificacion()"
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#calificacionModal"
              >
                <img src="../../../assets/imagenes/thumbs-up-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!serviciosContratados?.length" class="no-servicios">
      <p>{{ "servicios.noServicios" | translate }}</p>
    </div>
  </div>
  <!--Sección de creación de clasificados-->
  <div
    class="tab-pane fade"
    id="postServices"
    role="tabpanel"
    aria-labelledby="postServices-tab"
  >
    <h4 class="texto-subtitulo">
      {{ "servicios.titlePostService" | translate }}
    </h4>
    <p>
      {{ "servicios.parrafoPostService" | translate }}
    </p>
    <form [formGroup]="postServiceForm" (ngSubmit)="onCreate()">
      <div class="form-group">
        <label for="pais">{{ "formularios.pais" | translate }}</label>
        <select
          #selectPais
          id="pais"
          type="text"
          name="pais"
          class="form-select"
          aria-label="Default select example"
          formControlName="pais"
          [ngClass]="{
            'is-invalid': isInvalidField('pais'),
            'is-valid': isValidField('pais')
          }"
          (change)="onSelectPais(selectPais.value)"
          required
        >
          <option value="">
            {{ "formularios.seleccionePais" | translate }}
          </option>
          <option *ngFor="let pais of listaPaises" [value]="pais">
            {{ pais }}
          </option>
        </select>
        <app-errores [control]="postServiceForm.get('pais')"></app-errores>
      </div>
      <div class="form-group">
        <label for="seleccioneDepartamento">{{
          "formularios.departamento" | translate
        }}</label>
        <select
          #SelectDep
          name="departamento"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneDepartamento"
          formControlName="departamento"
          required
          (change)="selectDepartamento(SelectDep.value)"
        >
          <option value="">
            {{ "formularios.seleccioneDepartamento" | translate }}
          </option>
          <option
            *ngFor="let departamento of listaDepartamentos"
            [value]="departamento"
          >
            {{ departamento }}
          </option>
        </select>
        <app-errores
          [control]="postServiceForm.get('departamento')"
        ></app-errores>
      </div>
      <div class="form-group">
        <label for="seleccioneMunicipio">{{
          "formularios.municipio" | translate
        }}</label>
        <select
          name="municipio"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneMunicipio"
          formControlName="municipio"
        >
          <option value="">
            {{ "formularios.seleccioneMunicipio" | translate }}
          </option>
          <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
            {{ municipio }}
          </option>
        </select>
        <app-errores [control]="postServiceForm.get('municipio')"></app-errores>
      </div>
      <div class="form-group">
        <label for="nameService">{{
          "tecnico.tipoServicio" | translate
        }}</label>
        <select
          name="nameService"
          class="form-select"
          aria-label="Default select example"
          id="nameService"
          formControlName="nameService"
          required
        >
          <option value="" selected>
            {{ "tecnico.seleccioneServicio" | translate }}
          </option>
          <option [value]="servicio" *ngFor="let servicio of tipoServicio">
            {{ servicio }}
          </option>
        </select>
        <app-errores
          [control]="postServiceForm.get('nameService')"
        ></app-errores>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="exampleFormControlInput1">Fecha Inicio</label>
          <input
            class="form-control"
            type="date"
            placeholder="Fecha Inicio"
            formControlName="fechaInicio"
          />
        </div>
        <div class="form-group col-sm-6">
          <label for="exampleFormControlInput1">Fecha Final</label>
          <input
            class="form-control"
            type="date"
            placeholder="Fecha Final"
            formControlName="fechaFin"
          />
          <app-errores
            [control]="postServiceForm.get('fechaFin')"
          ></app-errores>
        </div>
      </div>
      <div class="form-group">
        <label for="otros">{{
          "crear-producto.otrosDatosInteres" | translate
        }}</label>
        <textarea
          class="form-control"
          placeholder="{{ 'servicios.infoServicio' | translate }}"
          name="datosInteres"
          rows="3"
          id="otros"
          formControlName="datosInteres"
        ></textarea>
        <app-errores
          [control]="postServiceForm.get('datosInteres')"
        ></app-errores>
      </div>
      <div class="text-center">
        <button
          [disabled]="postServiceForm.invalid"
          type="submit"
          class="btn mt-3"
          data-bs-toggle="modal"
          data-bs-target="#modalAddClasificado"
          [ngClass]="{
            'btn-secondary': postServiceForm.invalid,
            'btn-warning': postServiceForm.valid
          }"
        >
          {{ "servicios.crearClasificado" | translate }}
        </button>
      </div>
    </form>
  </div>
  <!--Sección de gestión de clasificados-->
  <div
    class="tab-pane fade"
    id="misClasificados"
    role="tabpanel"
    aria-labelledby="misClasificados-tab"
  >
    <h4 class="texto-subtitulo">
      {{ "servicios.titleClasificados" | translate }}
    </h4>
    <p>
      {{ "servicios.parrafoClasificado" | translate }}
    </p>
    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "formularios.fecha" | translate }}</th>
            <th scope="col">{{ "servicios.tipoServicio" | translate }}</th>
            <th scope="col">{{ "formularios.descripcion" | translate }}</th>
            <th scope="col">{{ "formularios.aplicaciones" | translate }}</th>
            <th scope="col">{{ "formularios.accion" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let clasificado of clasificadosUser">
            <th scope="row">
              {{ clasificado["tiempoCreado"] | timestampToString :'es':"dd/MM/yyyy" }}
            </th>
            <td>{{ clasificado["nameService"] }}</td>
            <td>{{ clasificado["datosInteres"] }}</td>
            <td>
              <button
                type="button"
                class="btn btn-warning"
                title="ver aplicaciones"
                data-bs-toggle="modal"
                data-bs-target="#ModalVerApplyClasificado"
              >
                <img src="../../../assets/imagenes/user-solid.svg" />
              </button>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                title="Eliminar Clasificado"
                data-bs-toggle="modal"
                data-bs-target="#ModalEliminarClasificado"
                (click)="clasificadoToDelete(clasificado['id'])"
              >
                <img src="../../../assets/imagenes/trash-alt-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--Sección de modal template-->
<ng-template #modalHojaDeVida let-dismiss="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ "servicios.hojaVidaDe" | translate }}
      {{
        servicioActual?.user?.nombres + " " + servicioActual?.user?.apellidos
      }}
    </h5>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="tecnicoForm" *ngIf="tipoForm === 'tecnico'">
        <div class="form-group">
          <label for="typeService">{{
            "servicios.tipoServicio" | translate
          }}</label>
          <input
            formControlName="nameService"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.estudios" | translate
          }}</label>
          <input
            formControlName="profession"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.cursoEspecializado" | translate
          }}</label>
          <input
            formControlName="forestCourse"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.poseeTarjeta" | translate
          }}</label>
          <input
            formControlName="professionalCard"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="workExperience">{{
            "servicios.experienciaLaboral" | translate
          }}</label>
          <textarea
            formControlName="workExperience"
            class="form-control"
            readonly
          ></textarea>
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.anosExperiencia" | translate
          }}</label>
          <input
            formControlName="yearsExperience"
            type="number"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group" formArrayName="equipmentMachinery">
          <label for="workExperience">{{
            "servicios.equiposMaquinaria" | translate
          }}</label>
          <div class="machinery-list">
            <ul>
              <li
                *ngFor="
                  let equip of abstractControlEquipmentMachinery.controls;
                  let i = index
                "
              >
                {{ equip.value }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.medioTransporte" | translate
          }}</label>
          <input
            formControlName="carUser"
            type="text"
            class="form-control"
            readonly
          />
        </div>
      </form>
      <form
        [formGroup]="transportadorForm"
        *ngIf="tipoForm === 'transportador'"
      >
        <div class="form-group">
          <label for="typeService">{{
            "servicios.tipoServicio" | translate
          }}</label>
          <input
            formControlName="nameService"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.tipoVehiculo" | translate
          }}</label>
          <input
            formControlName="truckType"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.anosExperiencia" | translate
          }}</label>
          <input
            formControlName="driverYearExpirience"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{ "servicios.peso" | translate }}</label>
          <input
            formControlName="truckWeight"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="workExperience">{{
            "servicios.volumen" | translate
          }}</label>
          <textarea
            formControlName="truckVolume"
            class="form-control"
            readonly
          ></textarea>
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.marcaVehiculo" | translate
          }}</label>
          <input
            formControlName="truckBrand"
            type="text"
            class="form-control"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="typeService">{{
            "servicios.modeloVehiculo" | translate
          }}</label>
          <input
            formControlName="truckYear"
            type="text"
            class="form-control"
            readonly
          />
        </div>
      </form>
      <div class="form-group">
        <label for="typeService">{{
          "formularios.telefono" | translate
        }}</label>
        <input
          type="number"
          [value]="servicioActual?.user?.telefono"
          class="form-control"
          readonly
        />
      </div>
      <div class="form-group">
        <label for="typeService">{{ "formularios.correo" | translate }}</label>
        <input
          type="email"
          [value]="servicioActual?.user?.email"
          class="form-control"
          readonly
        />
      </div>
      <div class="form-group">
        <div id="accordion">
          <div class="card">
            <div class="card-header p-0" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{ "servicios.comentarios" | translate }}
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordion"
            >
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let item of calificaciones">
                  <div class="row">
                    <div class="col-2">
                      <img [src]="item.imagen " alt="" />
                    </div>
                    <div class="col-10">
                      {{ item.comentarios }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a type="button" class="btn btn-danger" (click)="dismiss()"> Cerrar </a>
    <button
      (click)="openNewModal(dismiss, confirmarContratacion)"
      *ngIf="currentUser?.uid !== servicioActual?.userId"
      class="btn btn-success"
    >
      {{ "servicios.contratar" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #confirmarContratacion let-dismiss="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalToggleLabel2">
      {{ "servicios.confirmarContratacion" | translate }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body">
    {{ "servicios.seguroContratar" | translate }}
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="dismiss(); open(modalHojaDeVida)">
      {{ "formularios.cancelar" | translate }}
    </button>
    <button class="btn btn-success" (click)="contratar(dismiss)">
      {{ "servicios.confirmar" | translate }}
    </button>
  </div>
</ng-template>

<!--modal, ventana emergente para verificar que se quieren solicitar el pedido-->
<div
  class="modal fade"
  id="modalAddClasificado"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "favoritos.creacionExitosa" | translate }}
        </h5>
        <button
        type="button"
        class="btn btn-danger"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">{{ "formularios.cerrar" | translate }}</span>
      </button>
      </div>
      <div class="modal-body">
        {{ "favoritos.preferidoCreado" | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para calificación y comentarios sobre el prestador del servicio-->
<div
  class="modal fade"
  id="calificacionModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-calificacion-y-comentarios
          [datosCalificacion]="datosCalificacion"
          [hideComentarios]="true"
          (idCalificacion)="marcarCalificado($event)"
        ></app-calificacion-y-comentarios>
      </div>
      <button
        #cerrarModal
        hidden
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
</div>

<ng-template #modalFinalizar let-dismiss="dismiss">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalToggleLabel2">
      {{ "servicios.finalizarServicio" | translate }}
    </h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="dismiss()"
    ></button>
  </div>
  <div class="modal-body">{{ "servicios.seguroFinalizar" | translate }}</div>
  <div class="modal-footer">
    <button
      class="btn btn-danger"
      data-bs-toggle="modal"
      data-bs-dismiss="modal"
      (click)="dismiss()"
    >
      {{ "formularios.cancelar" | translate }}
    </button>
    <button
      class="btn btn-success"
      data-bs-dismiss="modal"
      (click)="finalizarServicio(dismiss)"
    >
      {{ "servicios.confirmar" | translate }}
    </button>
  </div>
</ng-template>

<!--moda, ventana emergente para eliminar clasificado-->
<div
  class="modal fade"
  id="ModalEliminarClasificado"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "favoritos.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ "favoritos.realmenteEliminar" | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          #closebutton
        >
          {{ "formularios.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="borrarClasificado()"
        >
          {{ "formularios.si" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--moda, ventana emergente para ver las aplicaciones al clasificado-->
<div
  class="modal fade"
  id="ModalVerApplyClasificado"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "formularios.aplicaciones" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-bordered text-center">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{{ "formularios.nombre" | translate }}</th>
                <th scope="col">{{ "formularios.correo" | translate }}</th>
                <th scope="col">{{ "formularios.telefono" | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Juan Francisco Guzman Zabala</th>
                <td>info@redforestal.com</td>
                <td>3165338564</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            #closebutton
          >
            {{ "formularios.cerrar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
