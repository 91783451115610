<!-- -------------------------website.features-------------------------->
<div class="website-features">
  <div class="row d-flex align-items-end">
    <div class="col-3">
      <img src="assets/imagenes/ICA_logo.png" />
      <div class="features-text">
        <p>
          <b>{{ "footer.maderaLegal" | translate }}</b
          ><br />{{ "footer.conRegistro" | translate }}
        </p>
      </div>
    </div>
    <div class="col-3">
      <img src="assets/imagenes/camion.webp" />
      <div class="features-text">
        <p>
          <b>{{ "footer.transporteLegal" | translate }}</b
          ><br />{{ "footer.conSalvoconducto" | translate }}
        </p>
      </div>
    </div>
    <div class="col-3">
      <img src="assets/imagenes/Medios-de-Pago.webp" />
      <div class="features-text">
        <p>
          <b>{{ "footer.formasPago" | translate }}</b
          ><br />{{ "footer.desdeCualquierParte" | translate }}
        </p>
      </div>
    </div>
    <div class="col-3">
      <img src="assets/imagenes/logo_red_forestal_png.png" />
      <div class="features-text">
        <p>
          <b>{{ "footer.productoCertificado" | translate }}</b
          ><br />{{ "footer.sello" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
<!--------------------chat Fred ------------------------------------------   -->
<div class="container-chat">
  <a
    class="fred-icon"
    data-bs-toggle="collapse"
    href="#collapseChat"
    role="button"
    aria-expanded="false"
    aria-controls="collapseExample"
  >
    <img src="./assets/imagenes/fred_icon.svg" alt="fred chat" />
    <p class="container-text">{{ "footer.pregunta" | translate }}</p>
  </a>
</div>
<div class="collapse" id="collapseChat">
  <!---------facebook chat------->
  <a class="icon-chat" target="_blanck" href="https://m.me/205792169788757">
    <img class="icon" src="./assets/imagenes/facebook.webp" alt="facebook" />
  </a>
  <!---------telegram chat------->
  <a class="icon-chat" target="_blanck" href="https://t.me/redforestal">
    <img class="icon" src="./assets/imagenes/telegram.webp" alt="telegram" />
  </a>
  <!---------whatsapp chat------->
  <a
    class="icon-chat"
    target="_blanck"
    href="https://api.whatsapp.com/send?phone=573165338564&text=Hola!&nbsp;en&nbsp;qué&nbsp;podemos&nbsp;ayudarte?"
  >
    <img class="icon" src="./assets/imagenes/Whatsapp1.webp" alt="Whatsapp" />
  </a>
</div>

<!----------------footer------------------------------------ -->
<footer>
  <div class="row text-center">
    <div class="col-md-3">
      <h1>{{ "footer.linksInteres" | translate }}</h1>
      <ul>
        <li>
          <a
            class="nav-link"
            [routerLink]="[path.EnlacesDeInteres] | langRoute | async"
          >
            {{ "footer.politicaPrivacidad" | translate }}
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            [routerLink]="[path.EnlacesDeInteres] | langRoute | async"
          >
            {{ "footer.terminosCondiciones" | translate }}
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            [routerLink]="[path.EnlacesDeInteres] | langRoute | async"
          >
            {{ "footer.politicaDevolucion" | translate }}
          </a>
        </li>
        <li>
          <a
            class="nav-link"
            [routerLink]="[path.EnlacesDeInteres] | langRoute | async"
          >
            {{ "footer.manualUsuario" | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h1>{{ "footer.laEmpresa" | translate }}</h1>
      <ul>
        <li>Bogotá D.C., Colombia</li>
        <li>Tranversal 27 #57-84 Apt 302</li>
        <li>info@redforestal.com</li>
        <li>Whatsapp 3165338564</li>
      </ul>
    </div>
    <div class="col-md-3">
      <h1>{{ "footer.siguenosEn" | translate }}</h1>
      <ul>
        <li>
          <a href="https://www.facebook.com/red.forestal1/" target="_blank">
            Facebook
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCIC6Ww7UFkFwHUZoQ-qk5nw"
            target="_blank"
          >
            YouTube
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/red_forestal/" target="_blank">
            Instagram
          </a>
        </li>
        <li>
          <a href="https://twitter.com/redforestal1" target="_blank">
            Twitter
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/red-forestal-sas"
            target="_blank"
          >
            LinkedIn
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-3">
      <h1>{{ "footer.apoyo" | translate }}:</h1>
      <img src="assets/imagenes/app-logos.png" />
      <img src="assets/imagenes/colombiaCompraloNuestro.webp" />
    </div>
  </div>
  <div class="copyright">
    Red Forestal SAS - {{ year }}
    <a rel="license" href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
      ><img
        alt="Licencia de Creative Commons"
        style="border-width: 0"
        src="https://i.creativecommons.org/l/by-nc-nd/4.0/80x15.png" /></a
    ><br />{{ "footer.estaObra" | translate }}
    <a
      rel="license"
      target="_blank"
      href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
      >{{ "footer.licencia" | translate }}</a
    >
  </div>
</footer>
