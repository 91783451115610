export const monedaLista: string[] = [
  "cop",
  "pab",
  "ves",
  "bob",
  "crc",
  "svc",
  "nio",
  "eau",
  "aud",
  "cad",
  "usd",
  "hkd",
  "eur",
  "chf",
  "pyg",
  "hnl",
  "egp",
  "scp",
  "gbp",
  "ars",
  "clp",
  "cup",
  "mxn",
  "uyu",
  "gtq",
  "brl",
  "rub",
  "pen",
  "jpy",
  "cny",
];
