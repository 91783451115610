<div id="containerFluid" class="container-fluid">
  <h2>Perfil del Administrador</h2>
  <!-- usuarios de la plataforma para su búsqueda y posterior edición-->
  <div class="row justify-content-center mt-3 row-cols-7 row-cols-xl-7">
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.usuariosAdmin"
        routerLinkActive="active"
      >
        <img src="../../assets/imagenes/user-solid.svg" />
        <span class="texto-barra">Usuarios</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.Especies"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/arbol_vector.svg" />
        <span class="texto-barra">Especies</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.estadisticasAdmin"
        routerLinkActive="active"
      >
        <img src="../../assets/imagenes/chart-bar-solid.svg" />
        <span class="texto-barra">Estadísticas</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.Permisos"
        routerLinkActive="active"
      >
        <img src="../../assets/imagenes/pen-square-solid.svg" />
        <span class="texto-barra">Permisos</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.comentariosAdmin"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/comments-solid.svg" />
        <span class="texto-barra">Comentarios</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.publicidadAdmin"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/bullhorn-solid.svg" />
        <span class="texto-barra">Publicidad</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.Aliados"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/handshake-solid.svg" />
        <span class="texto-barra">Aliados</span></a
      >
    </div>
    <div class="col">
      <a
        type="button"
        class="btn"
        [routerLink]="path.Revistas"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/file-download-solid.svg" />
        <span class="texto-barra">Revistas</span>
      </a>
    </div>
    <div class="col d-none">
      <a
        type="button"
        class="btn"
        [routerLink]="path.facturacionAdmin"
        routerLinkActive="active"
        ><img src="../../assets/imagenes/money-bill-wave-solid.svg" />
        <span class="texto-barra">Facturador</span></a
      >
    </div>
  </div>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
