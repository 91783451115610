import { Component, OnInit } from "@angular/core";
import { Path } from "./enums/Path";
import { AuthService } from "./services/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { PermisosService } from "./services/permisos.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { environment } from "src/environments/environment";
import { tap } from "rxjs";
import { User } from "@angular/fire/auth";
import { authenticationActions } from "./state/authentication-state";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "red-forestal";
  private ignorePaths = [
    Path.RegistroCompradorNatural,
    Path.RegistroPersonaNatural,
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private permisosSvc: PermisosService
  ) {
    GoogleAnalyticsService.loadGoogleAnalytics(
      environment.googleAnalytics.trackingId
    );

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        GoogleAnalyticsService.trackPageView(
          environment.googleAnalytics.trackingId,
          event.urlAfterRedirects
        );
      }
    });
  }

  ngOnInit() {
    this.authService
      .authStateChanged()
      .pipe(
        tap({
          next: async (user: User | null) => {
            if (user) {
              try {
                await this.permisosSvc.getPermisos(user.uid);
                if (
                  !this.ignorePaths.some((path) =>
                    this.router.url.includes(path)
                  )
                ) {
                  // Este if se necesita para prevenir que el usuario se le cierre la cuenta
                  // al crear la cuenta antes de haberle creado el documento en la base de datos.
                  this.store.dispatch(
                    new authenticationActions.LogIn({ user })
                  );
                }
              } catch (e) {
                console.log(e);
              }
            } else {
              this.store.dispatch(new authenticationActions.LogOut());
            }
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }
}
