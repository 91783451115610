import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportDetailsComponent } from "./report-details/report-details.component";
import { TranslateModule } from "@ngx-translate/core";
import { CoreModule } from "../core/core.module";
import { TimestampPipe } from "../core/pipes/timestamp.pipe";
import { WtUserNamePipe } from "./pipe/wt-user-name.pipe";
import { WtUserEmailPipe } from "./pipe/wt-user-email.pipe";
import { WtUserMovilPipe } from "./pipe/wt-user-movil.pipe";
import { WtUserLicenseDatePipe } from "./pipe/wt-user-license-date.pipe";

@NgModule({
  declarations: [
    ReportDetailsComponent,
    WtUserNamePipe,
    WtUserEmailPipe,
    WtUserMovilPipe,
    WtUserLicenseDatePipe,
  ],
  imports: [CommonModule, CoreModule, TranslateModule],
  exports: [
    TimestampPipe, // From CoreModule.
    WtUserNamePipe,
    WtUserEmailPipe,
    WtUserMovilPipe,
    WtUserLicenseDatePipe,
  ],
})
export class WoodtracerModule {}
