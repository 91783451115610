import { Injectable } from "@angular/core";
import { CalificacionService } from "./calificacion.service";
import { Observable, firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CalificacionInProductService {
  public calificaciones: any = {};

  constructor(private calificacionService: CalificacionService) {}

  // Revisarla con Juan
  async getCalificacion(idUser: string): Promise<any> {
    const obs$Calificaciones: Observable<any> | undefined =
      this.calificacionService.getUserCalificaciones(idUser);
    if (!this.calificaciones[idUser] && idUser && obs$Calificaciones) {
      const calificaciones: any = await firstValueFrom(obs$Calificaciones);
      if (calificaciones.length) {
        const calificacionesFormat = calificaciones.map(
          (calif: any) => calif.puntuacion || 0
        );
        const total: number = calificacionesFormat.reduce(
          (cu: any, acc: any) => cu + acc
        );
        this.calificaciones[idUser] = Math.round(total / calificaciones.length);

        return this.calificaciones[idUser];
      }
    } else {
      return this.calificaciones[idUser];
    }
  }
}
