import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import validatorHelper from "src/app/utils/validatorHelper";
import { isUniqueEmail } from "src/app/validator/email.validator";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-cambio-correo",
  templateUrl: "./cambio-correo.component.html",
  styleUrls: ["./cambio-correo.component.css"],
})
export class CambioCorreoComponent {
  emailForm!: FormGroup;
  idUsuario!: string;
  cerrar: Subject<boolean> = new Subject();
  loading: boolean = false;

  constructor(
    private userSvc: UserService,
    private authService: AuthService
  ) {
    this.initForm();
  }

  initForm() {
    this.emailForm = new FormGroup({
      email: new FormControl(
        "",
        [
          Validators.required,
          Validators.pattern(validatorHelper.EMAIL_PATTERN),
        ],
        [isUniqueEmail(this.userSvc)]
      ),
    });
  }

  async actualizarCorreo(): Promise<void> {
    this.loading = true;
    const { email } = this.emailForm.value;
    try {
      await this.authService.changeEmail(this.idUsuario, email);
      this.loading = false;
      this.cerrar.next(true);
      alert("Correo Actualizado");
    } catch (e) {
      this.loading = false;
      console.error(e);
      alert("Error al actualizar correo");
    }
  }
}
