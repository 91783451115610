import {
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import {
  FormArray,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { User } from "@angular/fire/auth";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import { firstValueFrom, forkJoin, Observable, of, Subject } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { serviciosTecnicos } from "src/app/models/serviciosTecnicos";
import { paises } from "src/assets/data/paises";
import { CrearClasificado } from "src/app/models/crearClasificado";
import { AuthService } from "src/app/services/auth.service";
import { ServiceUserService } from "src/app/services/service-user.service";
import { CalificacionService } from "src/app/services/calificacion.service";
import { StorageService } from "src/app/services/storage.service";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { UserData, UserService } from "src/app/services/user.service";
import { ClasificadosService } from "src/app/services/clasificados.service";
import { TecnicoComponent } from "../crear-servicio/tecnico/tecnico.component";
import {
  CalificacionyComentarios,
  DatosCalificacion,
} from "src/app/models/CalificacionyComentarios";
import {
  ContractService,
  ResponseObservableContractService,
  ServiceUser,
} from "src/app/models/contractService.interface";

interface DatoUsuario {
  [x: string]: UserData;
}

@Component({
  selector: "app-servicios",
  templateUrl: "./servicios.component.html",
  styleUrls: ["./servicios.component.css"],
  providers: [TecnicoComponent],
})
export class ServiciosComponent implements OnDestroy {
  @ViewChild("closebutton", { static: true }) closebutton!: ElementRef;
  private unsubscriber: Subject<void> = new Subject();
  private idEliminar!: string;
  public userProfile$: Observable<User | null> =
    this.authService.authStateChanged();
  public servicios!: any[];
  public serviciosContratados!: ResponseObservableContractService[];
  public transportadorForm!: FormGroup;
  public tecnicoForm!: FormGroup;
  public tipoForm!: string;
  public servicioActual!: any;
  public calificaciones!: CalificacionyComentarios[];
  public currentUser!: User | null;
  public datosUsuarios: DatoUsuario = {};
  public tipoServicio: string[] = serviciosTecnicos;
  public listaDepartamentos: string[] = [];
  public listaMunicipios: string[] = [];
  public clasificadosUser: CrearClasificado[] = [];

  paises: any = paises;
  listaPaises: string[] = Object.keys(this.paises);
  userID!: string;

  paginaActual!: number;

  tiposServicio: string[];
  datosCalificacion!: DatosCalificacion;
  loading!: boolean;
  lastAct: "next" | "prev" | undefined;

  postServiceForm = new FormGroup({
    pais: new FormControl<string>("", Validators.required),
    departamento: new FormControl("", Validators.required),
    municipio: new FormControl("", Validators.required),
    nameService: new FormControl("", Validators.required),
    fechaInicio: new FormControl("", Validators.required),
    fechaFin: new FormControl("", Validators.required),
    datosInteres: new FormControl("", Validators.required),
  });
  clasificados: Array<any> = [];

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private calificacionService: CalificacionService,
    private storageService: StorageService,
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private tecnicoComponent: TecnicoComponent,
    private userService: UserService,
    private clasificadosService: ClasificadosService,
    public serviceUser: ServiceUserService
  ) {
    this.tiposServicio = [
      ...this.tecnicoComponent.servicios,
      "transporte",
    ].sort();
    this.config.backdrop = "static";
    this.config.keyboard = true;
    this.initForm();
    this.traerServicios();
    this.userProfile$
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((res: User | null) => {
          if (res) {
            return this.getClasificados(res.uid).pipe(
              takeUntil(this.unsubscriber)
            );
          }
          return of(null);
        }),
        tap({
          next: (res: any[] | null) => {
            if (res) {
              this.clasificadosUser = res;
            }
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get abstractControlEquipmentMachinery(): FormArray {
    return this.tecnicoForm.get("equipmentMachinery") as FormArray;
  }

  async onCreate(): Promise<void> {
    if (this.postServiceForm.invalid) {
      this.postServiceForm.markAllAsTouched();
      return;
    }

    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      const postServiceForm: CrearClasificado = {
        pais: this.postServiceForm.value.pais || "",
        departamento: this.postServiceForm.value.departamento || "",
        municipio: this.postServiceForm.value.municipio || "",
        nameService: this.postServiceForm.value.nameService || "",
        fechaInicio:
          parseInt(this.postServiceForm.value.fechaInicio as string) || 0,
        fechaFin: parseInt(this.postServiceForm.value.fechaFin as string) || 0,
        datosInteres: this.postServiceForm.value.datosInteres || "",
        tiempoCreado: Timestamp.now(),
        userID: currentUser.uid,
      };

      try {
        await this.clasificadosService.addClasificado(postServiceForm);
        this.resetFormAndFiles();
      } catch (error) {
        console.error(error);
      }
    }
  }

  resetFormAndFiles(): void {
    this.postServiceForm.reset();
  }

  initForm(): void {
    this.tecnicoForm = this.fb.group({
      nameService: ["", Validators.required],
      workExperience: ["", Validators.required],
      yearsExperience: ["", Validators.required],
      profession: ["", Validators.required],
      forestCourse: [""],
      equipmentMachinery: this.fb.array([]),
      professionalCard: ["", Validators.required],
      carUser: ["", Validators.required],
    });

    this.transportadorForm = this.fb.group({
      nameService: ["", Validators.required],
      truckType: ["", [Validators.required]],
      driverYearExpirience: ["", [Validators.required]],
      driverWorkExperience: ["", [Validators.required]],
      truckWeight: ["", [Validators.required]],
      truckVolume: [""],
      truckBrand: ["", [Validators.required]],
      truckYear: ["", [Validators.required]],
    });
  }

  getCalificaciones(userId: string): void {
    this.calificacionService
      .getUserCalificaciones(userId)
      .pipe(
        take(1),
        switchMap((calificaciones: CalificacionyComentarios[]) => {
          return forkJoin(
            calificaciones.map((calif: CalificacionyComentarios) => {
              return this.storageService
                .getStoreUrlImageObservable(
                  `fotoPerfilUser/${calif.idCalificador}_500x500`
                )
                .pipe(
                  catchError(() => {
                    return this.storageService.getStoreUrlImagePromise(
                      `fotoPerfilUser/${calif.idCalificador}`
                    );
                  }),
                  map((url: string) => {
                    calif.imagen = url;
                    return calif;
                  })
                );
            })
          );
        }),
        catchError((error) => {
          console.error(error);
          return of([]); // Devuelve un observable vacío en caso de error
        }),
        tap({
          next: (calificaciones: CalificacionyComentarios[]) => {
            this.calificaciones = calificaciones;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  getPuntuacion(userId: string): Promise<number> {
    return firstValueFrom(
      this.calificacionService.getUserCalificaciones(userId).pipe(
        map((calificaciones: CalificacionyComentarios[]) => {
          let contCalif: number = 0;
          calificaciones.forEach((calif: CalificacionyComentarios) => {
            contCalif += calif.puntuacion;
          });

          return Math.round(contCalif / calificaciones.length);
        })
      )
    );
  }

  async traerServicios(action?: "next" | "prev" | undefined): Promise<void> {
    this.lastAct = action;
    this.loading = true;
    this.currentUser = this.authService.getCurrentUser();
    this.getServiciosContratados();

    this.serviceUser
      .getServices(action)
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((res: ServiceUser[]) => {
          this.datosUsuarios = {};

          const listaServicios = res.map(async (svc: ServiceUser) => {
            const puntuacion: number = await this.getPuntuacion(svc["userId"]);
            const userData: UserData = await firstValueFrom(
              this.userService.getUserById(svc["userId"]).pipe(take(1))
            );

            userData.puntuacion = puntuacion || 0;
            this.datosUsuarios[svc["userId"]] = userData;

            return {
              ...svc,
              user: this.datosUsuarios[svc["userId"]],
            };
          });

          return forkJoin(listaServicios);
        }),
        tap({
          next: (res: any[]) => {
            this.servicios = res;
            this.loading = false;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  finalizarServicio(cb?: () => void): void {
    if (this.servicioActual["estado"] !== "finalizado") {
      const { id } = this.servicioActual;
      this.serviceUser
        .updateServicio(id, { estado: "finalizado" })
        .then(() => {
          alert("Servicio finalizado");
          cb && cb();
        })
        .catch(() => {
          alert("error al finalizar servicio ");
          cb && cb();
        });
    }
  }

  async contratar(cb?: () => void): Promise<void> {
    if (this.servicioActual && this.currentUser) {
      const { id: idServicio, userId: idContratado } = this.servicioActual;
      const data: Partial<ContractService> = {
        idServicio,
        idContratado,
        idContratante: this.currentUser.uid,
        fechaContratacion: Timestamp.now(),
        estado: "en proceso",
        calificado: false,
      };
      const response = await this.serviceUser.contractService(data);
      response && alert("Servicio Contratado");
      cb && cb();
    }
  }

  async setHojaVida(servicio: any, modal: TemplateRef<any>): Promise<void> {
    this.tipoForm = servicio["type"];
    this.servicioActual = servicio;
    const form: FormGroup =
      this[
        `${this.tipoForm === "transportador" ? "transportador" : "tecnico"}Form`
      ];

    this.getCalificaciones(servicio["userId"]);
    if (this.tipoForm === "tecnico") {
      this.tecnicoForm.controls["equipmentMachinery"] = this.fb.array([]);
      if (servicio["equipmentMachinery"]) {
        servicio["equipmentMachinery"].map(() => {
          (this.tecnicoForm.get("equipmentMachinery") as FormArray).push(
            this.fb.control("")
          );
        });
      }
    }

    form.patchValue(servicio);
    this.open(modal);
  }

  getServiciosContratados(): void {
    if (this.currentUser) {
      this.serviceUser
        .getContractedServices(this.currentUser.uid)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (svcs: ResponseObservableContractService[]) => {
              this.serviciosContratados = svcs;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  marcarCalificado(id: string): void {
    const servicio: string = this.servicioActual["id"];
    this.serviceUser
      .updateServicio(servicio, {
        calificadoContratado: true,
        idCalificacionContratado: id,
        fechaFinalizacion: Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => console.warn("error al calificar servicio: ", err));
  }

  open(content: TemplateRef<any>): void {
    this.modalService.open(content, { centered: true });
  }

  trackServices(index: number, item: any): number {
    return item.id;
  }

  cambiarLimite(cantidad: string | number): void {
    this.serviceUser.limitPage = +cantidad;
    this.reset();
  }

  cambiarServicio(tipoServicio: string): void {
    this.serviceUser.serviceType = tipoServicio;
    this.reset();
  }

  reset(): void {
    this.serviceUser.currentpage = 1;
    this.traerServicios();
  }

  setDatosCalificacion(): void {
    if (this.currentUser) {
      const datos: DatosCalificacion = {
        idServicio: this.servicioActual["idServicio"],
        idCalificador: this.currentUser.uid,
        idCalificado: this.servicioActual["idContratado"],
      };

      this.datosCalificacion = datos;
    }
  }

  onSelectPais(pais: string): void {
    if (this.paises[pais]) {
      this.listaDepartamentos = Object.keys(this.paises[pais]);
    }
  }

  selectDepartamento(selectDep: string): void {
    const paisesTmp = { ...this.paises };
    const pais: string = this.postServiceForm.get("pais")?.value || "";
    this.listaMunicipios = paisesTmp[pais] ? paisesTmp[pais][selectDep] : [];
  }

  isValidField(field: string, child?: number): boolean {
    if (child) {
      const campo: AbstractControl = (
        this.postServiceForm?.get(field) as FormArray
      ).at(child);
      return campo.dirty && campo.valid;
    }

    return (
      (this.postServiceForm.get(field)?.dirty &&
        this.postServiceForm.get(field)?.valid) ||
      false
    );
  }

  isInvalidField(field: string, child?: number): boolean {
    if (child) {
      const campo: AbstractControl = (
        this.postServiceForm?.get(field) as FormArray
      ).at(child);
      return (campo.touched || campo.dirty) && !campo.valid;
    }

    return (
      ((this.postServiceForm.get(field)?.touched ||
        this.postServiceForm.get(field)?.dirty) &&
        !this.postServiceForm.get(field)?.valid) ||
      false
    );
  }

  getClasificados(userId: string): Observable<DocumentData[]> {
    return this.clasificadosService.getClasificadoById(userId);
  }

  clasificadoToDelete(id: string | undefined): void {
    if (id) {
      this.idEliminar = id;
    }
  }

  borrarClasificado(): void {
    this.clasificadosService
      .deleteClasificado(this.idEliminar)
      .then(() => this.cerrarPopUp())
      .catch(console.warn);
  }

  cerrarPopUp(): void {
    this.closebutton.nativeElement.click();
  }

  openNewModal(modalCurrent: () => void, newModal: TemplateRef<any>): void {
    modalCurrent();
    this.open(newModal);
  }
}
