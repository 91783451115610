<!--Menú secciones-->
<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="myProducts-tab"
      data-bs-toggle="tab"
      data-bs-target="#myProducts"
      type="button"
      role="tab"
      aria-controls="myProducts"
      aria-selected="true"
    >
      {{ "crear-producto.misProductos" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="createProduct-tab"
      data-bs-toggle="tab"
      data-bs-target="#createProduct"
      type="button"
      role="tab"
      aria-controls="createProduct"
      aria-selected="false"
    >
      {{ "crear-producto.crearProducto" | translate }}
    </button>
  </li>
</ul>

<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de Productos Creados-->
  <div
    class="tab-pane fade show active"
    id="myProducts"
    role="tabpanel"
    aria-labelledby="myProducts-tab"
  >
    <app-mis-productos></app-mis-productos>
  </div>

  <!--Sección de creación de Producto-->
  <div
    class="tab-pane fade"
    id="createProduct"
    role="tabpanel"
    aria-labelledby="createProduct-tab"
  >
    <h3 class="titulo-contenido-perfil">
      {{ "crear-producto.nuevoProducto" | translate }}
    </h3>
    <!-- ******formulario inhabilitado para usuarios suscritos ********-->
    <app-limite-pedidos
      [productos]="true"
      *ngIf="!estadoSuscripcion && productosMes >= 3; else formulario"
    >
    </app-limite-pedidos>
    <!--******formulario habilitado para usuarios suscritos ********-->
    <ng-template #formulario>
      <div>
        <p>
          {{ "crear-producto.eresUsuario" | translate }}
          <b *ngIf="estadoSuscripcion">{{
            "crear-producto.suscrito" | translate
          }}</b>
          <b *ngIf="!estadoSuscripcion">{{
            "crear-producto.beneficiario" | translate
          }}</b>
          {{ "crear-producto.detalles" | translate }}
        </p>
        <app-forms></app-forms>
      </div>
    </ng-template>
  </div>
</div>
