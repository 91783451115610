<h2 class="text-center">Comentarios de usuarios</h2>

<div class="container">
  <div
    class="row"
    *ngFor="let comentario of comentarios; trackBy: trackByComentario"
  >
    <div class="col-sm-2">
      <img
        [src]="comentario.foto || '../../assets/imagenes/ods2.png'"
        class="img-fluid"
        alt="imagen perfil"
      />
    </div>
    <div class="col-sm-5">
      <h5 class="titulo-comentario">
        {{ comentario.nombre }} - {{ comentario.email }}
      </h5>
      <small>{{ comentario.fecha | timestampToString }}</small>
    </div>
    <div class="col-sm-5">
      <p class="texto-comentario">
        {{ comentario.comentario }}
      </p>
    </div>
  </div>
</div>
<nav aria-label="Page navigation example" class="mt-3">
  <ul class="pagination justify-content-center">
    <li class="page-item" [ngClass]="{ disabled: disablePrev }">
      <a class="page-link" tabindex="-1" aria-disabled="true" (click)="prevo()"
        >Previous</a
      >
    </li>
    <li
      class="page-item"
      *ngFor="let page of pages; let i = index"
      [ngClass]="{ active: currentPage === i }"
    >
      <a class="page-link" (click)="setPage(i + 1)">{{ i + 1 }}</a>
    </li>

    <li class="page-item" [ngClass]="{ disabled: disableNext }">
      <a class="page-link" (click)="nexto()">Next</a>
    </li>
  </ul>
</nav>
