/* eslint-disable no-eval */
import { ValidationErrors, ValidatorFn } from "@angular/forms";

export const comparador = (
  controlName: string,
  comparator: ">" | "<" | ">=" | "<=" | "==",
  matchingControlName: string
): ValidatorFn => {
  return (formGroup: any): ValidationErrors | null => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (
      (!matchingControl && !matchingControl) ||
      (matchingControl?.errors &&
        !matchingControl?.errors["errorComparador"]) ||
      (control?.errors && !control?.errors["errorComparador"])
    ) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    if (eval(`${+control?.value} ${comparator} ${+matchingControl?.value} `)) {
      control?.setErrors(null);
      matchingControl?.setErrors(null);
      return null;
    } else {
      control?.setErrors({ errorComparador: "mayorCantididadMinimaVenta" });
      matchingControl?.setErrors({
        errorComparador: "menorCantididadDisponible",
      });
      return { errorComparador: true };
    }
  };
};
