<div *ngIf="user$ | async as user">
  <h3 class="titulo-contenido-perfil">
    {{ "datos-personales.datosPersonales" | translate }}
  </h3>
  <p>
    {{ "datos-personales.aqui" | translate }}
  </p>
  <p>
    {{ "datos-personales.anadir" | translate }}
    <a
      target="_blank"
      href="https://api.whatsapp.com/send?phone=573165338564&text=Hola!&nbsp;en&nbsp;qué&nbsp;podemos&nbsp;ayudarte?"
      >3165338564</a
    >
  </p>
  <form [formGroup]="personalDataForm" (ngSubmit)="cambiarClave()">
    <fieldset disabled>
      <div class="form-group">
        <label for="nombresVendedor">{{
          "datos-personales.nombres" | translate
        }}</label>
        <input
          type="text"
          id="nombresVendedor"
          class="form-control"
          formControlName="nombres"
        />
      </div>
      <div class="form-group">
        <label for="paisVendedor">{{ "formularios.pais" | translate }}</label>
        <input
          type="text"
          id="paisVendedor"
          class="form-control"
          formControlName="pais"
        />
      </div>
      <div class="form-group">
        <label for="departamentoVendedor">{{
          "formularios.departamento" | translate
        }}</label>
        <input
          type="text"
          id="departamentoVendedor"
          class="form-control"
          formControlName="departamento"
        />
      </div>
      <div class="form-group">
        <label for="municipioVendedor">{{
          "formularios.municipio" | translate
        }}</label>
        <input
          type="text"
          id="municipioVendedor"
          class="form-control"
          formControlName="municipio"
        />
      </div>
      <div class="form-group">
        <label for="emailVendedor">{{
          "datos-personales.email" | translate
        }}</label>
        <input
          type="text"
          id="emailVendedor"
          class="form-control"
          formControlName="email"
          autocomplete="username"
        />
      </div>
    </fieldset>
    <div class="form-group">
      <div class="form-group">
        <label for="formGroupExampleInput">{{
          "formularios.telefono" | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          id="formGroupExampleInput"
          formControlName="telefono"
          aria-describedby="telHelpBlock"
        />
        <small id="telHelpBlock" class="form-text text-muted">
          {{ "datos-personales.cambiasteNumero" | translate }}
        </small>
      </div>
      <div class="form-group">
        <label for="formGroupExampleInput2">{{
          "datos-personales.direccion" | translate
        }}</label>
        <input
          type="text"
          class="form-control"
          id="formGroupExampleInput2"
          formControlName="direccion"
          aria-describedby="dirHelpBlock"
        />
        <small id="dirHelpBlock" class="form-text text-muted">
          {{ "datos-personales.cambiasteDomicilio" | translate }}
        </small>
      </div>
    </div>
    <div class="row" [formGroup]="passwordForm">
      <div class="form-group col-md-4">
        <label for="contraseñaAnterior">{{
          "datos-personales.contrasenaAnterior" | translate
        }}</label>
        <input
          type="password"
          id="contraseñaAnterior"
          class="form-control"
          aria-describedby="passwordHelpInline"
          formControlName="currentPassword"
          autocomplete="current-password"
          [ngClass]="{
            'is-invalid': isInvalidField('currentPassword'),
            'is-valid': isValidField('currentPassword')
          }"
        />
      </div>
      <div class="form-group col-md-4">
        <label for="nuevaContraseña">{{
          "datos-personales.nuevaContrasena" | translate
        }}</label>
        <input
          type="password"
          id="nuevaContraseña"
          class="form-control"
          aria-describedby="passwordHelpInline"
          formControlName="password"
          autocomplete="new-password"
          [ngClass]="{
            'is-invalid': isInvalidField('password'),
            'is-valid': isValidField('password')
          }"
        />
        <small id="passwordHelpInline" class="text-muted">
          {{ "datos-personales.contrasenaDebeTener" | translate }}
        </small>
      </div>
      <div class="form-group col-md-4">
        <label for="confirmarContraseña">{{
          "datos-personales.confirmarContrasena" | translate
        }}</label>
        <input
          type="password"
          id="confirmarContraseña"
          class="form-control"
          aria-describedby="passwordHelpInline"
          data-match="#nuevaContraseña"
          formControlName="rePassword"
          autocomplete="new-password"
          [ngClass]="{
            'is-invalid': isInvalidField('rePassword'),
            'is-valid': isValidField('rePassword')
          }"
        />
      </div>
    </div>
    <div class="col-auto text-center">
      <button
        type="submit"
        class="btn btn-warning"
        [disabled]="passwordForm.invalid"
      >
        {{ "datos-personales.actualizar" | translate }}
      </button>
    </div>
  </form>

  <form
    [formGroup]="emailForm"
    (ngSubmit)="abrirModalCorreo(modalCorreo)"
    class="mt-2 email-form"
  >
    <div class="row">
      <div class="col text-center">
        <h4>{{ "datos-personales.actualizacionCorreo" | translate }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="form-group">
          <!-- <label for="emailVendedor">Email</label> -->
          <input
            type="text"
            placeholder="email"
            id="updateEmailVendedor"
            class="form-control"
            formControlName="email"
            autocomplete="username"
            [ngClass]="{
              'is-invalid':
                emailForm.get('email')?.invalid &&
                emailForm.get('email')?.touched,
              'is-valid': emailForm.get('email')?.valid
            }"
          />
          <div
            *ngIf="
              emailForm.get('email')?.errors && emailForm.get('email')?.touched
            "
          >
            <p *ngIf="emailForm.get('email')?.hasError('required')">
              {{ "formularios.campoRequerido" | translate }}
            </p>
            <p *ngIf="emailForm.get('email')?.hasError('pattern')">
              {{ "formularios.correoBienEscrito" | translate }}
            </p>
            <p *ngIf="emailForm.get('email')?.hasError('isUniqueEmail')">
              {{ "formularios.emailUsado" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="text-center">
          <button
            type="submit"
            class="w-100 btn btn-warning"
            [disabled]="emailForm.invalid"
          >
            {{ "datos-personales.actualizarEmail" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #modalCorreo let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center w-100">
      <b>{{ "datos-personales.estasSeguro" | translate }}</b>
    </h4>
  </div>
  <div class="modal-body text-center">
    <small>
      {{ "datos-personales.seCerraraSesion" | translate }}
    </small>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.dismiss()"
      [disabled]="loadingCorreo"
    >
      {{ "formularios.cancelar" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="loadingCorreo"
      (click)="cambiarCorreo(modal.dismiss)"
    >
      <span> {{ "datos-personales.aceptar" | translate }} </span>
      <div
        *ngIf="loadingCorreo"
        class="spinner-border spinner-border-sm text-light"
        role="status"
      ></div>
    </button>
  </div>
</ng-template>
