import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PreguntasComponent } from "../preguntas/preguntas.component";
import { TranslateModule } from "@ngx-translate/core";
import { ApplicationPipesModule } from "../application-pipes/application-pipes-module";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LimitePedidosComponent } from "../limite-pedidos/limite-pedidos.component";

@NgModule({
  declarations: [PreguntasComponent, LimitePedidosComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ApplicationPipesModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [PreguntasComponent, LimitePedidosComponent],
})
export class ComponentesGlobalesModule {}
