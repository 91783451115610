<div class="table-responsive">
  <table class="table text-center">
    <thead class="thead-dark">
      <tr>
        <th scope="col">{{ "formularios.fecha" | translate }}</th>
        <th scope="col">{{ "mensajes.verProducto" | translate }}</th>
        <th scope="col">{{ "mensajes.respondido" | translate }}</th>
        <th scope="col">{{ "mensajes.mensaje" | translate }}</th>
        <th scope="col">{{ "mensajes.verMensaje" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mensaje of mensajes">
        <th class="date">{{ mensaje.fechaUltimoMensaje | timestampToString }}</th>
        <td class="text-center">
          <a
            class="btn btn-sm btn-success"
            [routerLink]="
              [path.DetalleDeProducto, mensaje.idProducto] | langRoute | async
            "
            target="_blank"
          >
            <img
              class="icon-svg"
              src="../../assets/imagenes/box-archive-solid.svg"
              alt="ojo"
            />
          </a>
        </td>
        <td>
          {{
            mensaje?.respuestas?.length ? ("formularios.si" | translate) : "NO"
          }}
        </td>
        <td>
          <p class="message">{{ mensaje.lastMsj }}</p>
        </td>
        <td class="text-center">
          <button class="btn btn-sm btn-success" (click)="verMensajes(mensaje)">
            <img
              class="icon-svg"
              src="../../assets/imagenes/eye-solid.svg"
              alt="ojo"
            />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
