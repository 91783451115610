<h3 class="titulo-contenido-perfil">
  {{ "favoritos.productosPreferidos" | translate }}
</h3>
<p>
  {{ "favoritos.ofertas" | translate }}
</p>
<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="myFavourites-tab"
      data-bs-toggle="tab"
      data-bs-target="#myFavourites"
      type="button"
      role="tab"
      aria-controls="myFavourites"
      aria-selected="true"
    >
      {{ "favoritos.misFavoritos" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="newFavourite-tab"
      data-bs-toggle="tab"
      data-bs-target="#newFavourite"
      type="button"
      role="tab"
      aria-controls="newFavourite"
      aria-selected="false"
    >
      {{ "favoritos.crearFavorito" | translate }}
    </button>
  </li>
</ul>
<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de Mis Favoritos Creados-->
  <div
    class="tab-pane fade show active"
    id="myFavourites"
    role="tabpanel"
    aria-labelledby="myFavourites-tab"
  >
    <h4>{{ "favoritos.listado" | translate }}</h4>
    <div class="table-responsive-lg">
      <table class="table table-hover text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">No.</th>
            <th scope="col">{{ "formularios.categoria" | translate }}</th>
            <th scope="col">{{ "formularios.pais" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.especie" | translate }}</th>
            <th scope="col">{{ "formularios.accion" | translate }}</th>
          </tr>
        </thead>
        <tbody class="contenido-tabla">
          <tr
            *ngFor="
              let pref of preferidos
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage };
              let i = index
            "
          >
            <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
            <td>{{ pref["categoria"] }}</td>
            <td>{{ pref["pais"] }}</td>
            <td>{{ pref["departamento"] }}</td>
            <td>{{ pref["especie"] }}</td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                title="Eliminar preferido"
                data-bs-toggle="modal"
                data-bs-target="#ModalEliminarPreferido"
                (click)="prefToDelete(pref['id'])"
              >
                <img src="../../../assets/imagenes/trash-alt-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--paginado para los resultados-->
    <pagination-controls
      class="my-pagination"
      (pageChange)="currentPage = $event"
      previousLabel="Anterior"
      nextLabel="Siguiente"
    >
    </pagination-controls>
  </div>
  <!--Sección Crear Favorito-->
  <div
    class="tab-pane fade"
    id="newFavourite"
    role="tabpanel"
    aria-labelledby="newFavourite-tab"
  >
    <h4>{{ "favoritos.creacionProducto" | translate }}</h4>
    <form [formGroup]="preferForm" (ngSubmit)="onCreate()">
      <div class="form-group">
        <label for="seleccioneCategoria">{{
          "favoritos.seleccioneCategoria" | translate
        }}</label>
        <select
          name="Categoria"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneCategoria"
          formControlName="categoria"
          [ngClass]="{
            'is-invalid': isInvalidField('categoria'),
            'is-valid': isValidField('categoria')
          }"
          required
        >
          <option [value]="categoria" *ngFor="let categoria of categorias">
            {{ "categorias-lista." + categoria | translate }}
          </option>
        </select>
        <div
          *ngIf="
            preferForm.get('categoria')?.errors &&
            preferForm.get('categoria')?.touched
          "
        >
          <p *ngIf="preferForm.get('categoria')?.hasError('categoria')">
            {{ "formularios.campoRequerido" | translate }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <label for="pais">{{ "favoritos.pais" | translate }}</label>
        <select
          #selectPais
          id="pais"
          type="text"
          name="pais"
          class="form-select"
          aria-label="Default select example"
          formControlName="pais"
          [ngClass]="{
            'is-invalid': isInvalidField('pais'),
            'is-valid': isValidField('pais')
          }"
          required
          (change)="onSelectPais(selectPais.value)"
        >
          <option value="">
            {{ "formularios.seleccionePais" | translate }}
          </option>
          <option *ngFor="let pais of listaPaises" [value]="pais">
            {{ pais }}
          </option>
        </select>
        <div
          *ngIf="
            preferForm.get('pais')?.errors && preferForm.get('pais')?.touched
          "
        >
          <p *ngIf="preferForm.get('pais')?.hasError('pais')">
            {{ "formularios.campoRequerido" | translate }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <label for="seleccioneDepartamento">{{
          "favoritos.departamento" | translate
        }}</label>
        <select
          #SelectDep
          name="departamento"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneDepartamento"
          formControlName="departamento"
          (change)="selectDepartamento(SelectDep.value)"
        >
          <option value="">
            {{ "favoritos.seleccionaDepartamento" | translate }}
          </option>
          <option
            *ngFor="let departamento of listaDepartamentos"
            [value]="departamento"
          >
            {{ departamento }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="seleccioneMunicipio">{{
          "formularios.municipio" | translate
        }}</label>
        <select
          name="municipio"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneMunicipio"
          formControlName="municipio"
        >
          <option value="">
            {{ "formularios.seleccioneMunicipio" | translate }}
          </option>
          <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
            {{ municipio }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="seleccioneEspecie">{{
          "formularios.especie" | translate
        }}</label>
        <select
          name="especie"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneEspecie"
          formControlName="especie"
        >
          <option value="0">
            {{ "formularios.seleccioneEspecie" | translate }}
          </option>
          <option
            [value]="especie.nombre"
            *ngFor="let especie of especieOpciones"
          >
            {{ especie.nombre }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="seleccioneSistema">{{
          "favoritos.sistema" | translate
        }}</label>
        <select
          name="Sistema"
          class="form-select"
          aria-label="Default select example"
          id="seleccioneSistema"
          formControlName="sistema"
        >
          <option value="Cercas Vivas">Cercas Vivas</option>
          <option value="Sistema Agroforestal">Sistema Agroforestal</option>
          <option value="Sistema Silvopastoril">Sistema Silvopastoril</option>
          <option value="Plantación Forestal">Plantación Forestal</option>
          <option value="Arboles Aislados">Arboles Aislados</option>
          <option value="Bosque natural">Bosque natural</option>
        </select>
      </div>
      <div class="text-center">
        <button
          [disabled]="preferForm.invalid"
          type="submit"
          class="btn mt-3"
          data-bs-toggle="modal"
          data-bs-target="#modalAddPrefer"
          [ngClass]="{
            'btn-secondary': preferForm.invalid,
            'btn-warning': preferForm.valid
          }"
        >
          {{ "favoritos.crearPreferido" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<!--modal, ventana emergente para añadir un preferido-->
<div
  class="modal fade"
  id="modalAddPrefer"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "favoritos.creacionExitosa" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        {{ "favoritos.preferidoCreado" | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--moda, ventana emergente para eliminar preferido-->
<div
  class="modal fade"
  id="ModalEliminarPreferido"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "favoritos.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ "favoritos.realmenteEliminar" | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          #closebutton
        >
          {{ "formularios.no" | translate }}
        </button>
        <button type="button" class="btn btn-success" (click)="borrarPref()">
          {{ "formularios.si" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
