import { Pipe, PipeTransform } from "@angular/core";
import { UserData } from "src/app/services/user.service";

@Pipe({
  name: "transformarApellidos",
})
export class TransformarApellidosPipe implements PipeTransform {
  transform(user: UserData): string {
    if ("apellidos" in user) {
      return user.apellidos;
    }

    return "";
  }
}
