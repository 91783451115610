const AUTORIDADAMBIENTAL: string[] = [
  "Autoridad Nacional de Licencias Ambientales (ANLA)",
  "Corporación Autónoma Regional de Boyacá (CORPOBOYACÁ)",
  "Corporación Autónoma Regional de Caldas (CORPOCALDAS)",
  "Corporación Autónoma Regional de Chivor (CORPOCHIVOR)",
  "Corporación Autónoma Regional de Cundinamarca (CAR)",
  "Corporación Autónoma Regional de la Frontera Nororiental (CORPONOR)",
  "Corporación Autónoma Regional de La Guajira (CORPOGUAJIRA)",
  "Corporación Autónoma Regional de la Orinoquia (CORPORINOQUIA)",
  "Corporación Autónoma Regional de las Cuencas de los Ríos Negro y Nare (CORNARE)",
  "Corporación Autónoma Regional de los Valles del Sinú y del San Jorge (CVS)",
  "Corporación Autónoma Regional de Nariño (CORPONARIÑO)",
  "Corporación Autónoma Regional de Risaralda (CARDER)",
  "Corporación Autónoma Regional de Santander (CAS)",
  "Corporación Autónoma Regional de Sucre (CARSUCRE)",
  "Corporación Autónoma Regional del Alto Magdalena - CAM",
  "Corporación Autónoma Regional del Atlántico (CRA)",
  "Corporación Autónoma Regional del Canal del Dique (CARDIQUE)",
  "Corporación Autónoma Regional del Cauca (CRC)",
  "Corporación Autónoma Regional del Centro de Antioquia (CORANTIOQUIA)",
  "Corporación Autónoma Regional del Cesar (CORPOCESAR)",
  "Corporación Autónoma Regional del Guavio (CORPOGUAVIO)",
  "Corporación Autónoma Regional del Magdalena (CORPAMAG)",
  "Corporación Autónoma Regional del Quindío (CRQ)",
  "Corporación Autónoma Regional del Sur de Bolívar (CSB)",
  "Corporación Autónoma Regional del Tolima (CORTOLIMA)",
  "Corporación Autónoma Regional del Valle del Cauca (CVC)",
  "Corporación Autónoma Regional para el Desarrollo Sostenible del Chocó (CODECHOCÓ)",
  "Corporación Autónoma Regional para la Defensa de la Meseta de Bucaramanga (CDMB)",
  "Corporación para el Desarrollo Sostenible de La Mojana y El San Jorge (CORPOMOJANA)",
  "Corporación para el Desarrollo Sostenible del Archipiélago de San Andrés, Providencia y Santa Catalina (CORALINA)",
  "Corporación para el Desarrollo Sostenible del Área de Manejo Especial de La Macarena (CORMACARENA)",
  "Corporación para el Desarrollo Sostenible del Norte y el Oriente Amazónico (CDA)",
  "Corporación para el Desarrollo Sostenible del Sur de la Amazonia (CORPOAMAZONIA)",
  "Corporación para el Desarrollo Sostenible del Urabá (CORPOURABA)",
  "Instituto Colombiano Agropecuario (ICA)",
  "Ministerio de Ambiente y Desarrollo Sostenible",
  "Secretaría Distrital de Ambiente de Bogotá (SDA)",
];

export { AUTORIDADAMBIENTAL };
