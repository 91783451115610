<!--Menú secciones-->
<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="Social-tab"
      data-bs-toggle="tab"
      data-bs-target="#Social"
      type="button"
      role="tab"
      aria-controls="Social"
      aria-selected="true"
    >
      Bosques y Comunidades
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="enterprenaur-tab"
      data-bs-toggle="tab"
      data-bs-target="#enterprenaur"
      type="button"
      role="tab"
      aria-controls="enterprenaur"
      aria-selected="false"
    >
      Emprendimientos Forestales
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="forestschool-tab"
      data-bs-toggle="tab"
      data-bs-target="#forestschool"
      type="button"
      role="tab"
      aria-controls="forestschool"
      aria-selected="false"
    >
      Escuela Forestal
    </button>
  </li>
</ul>
<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de bosques y comunidades-->
  <div
    class="tab-pane active"
    id="Social"
    role="tabpanel"
    aria-labelledby="Social-tab"
  >
    <h2 class="titulo">Invierte en tu proyecto favorito</h2>
    <p>
      Por cada tres (3) meses de suscripción pagos recibiras una moneda digital
      <b>"TreeCoin"</b> en tu perfil que podrás usar para donar un (1) árbol que
      será plantado en la zona que elijas de tu país. De esta manera podrás
      apoyar la creación de nuevos bosques protectores-productores en
      comunidades con altas tasas de desnutrición infantil. Estas comunidades te
      lo agradecerán.
    </p>
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-sm-6">
              <div class="card" style="width: 18rem">
                <div class="card-header">
                  Árboles Donados
                  <span class="badge badge-success">000000</span>
                </div>
                <img
                  class="card-img-top"
                  src="../../../assets/imagenes/categoria_vivero.webp"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Guajira</h5>
                  <button type="button" class="btn btn-warning">
                    Donar un árbol
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h3 class="subtitle">Detalles del Proyecto</h3>
              <ol>
                <li>Número de familias beneficiadas:</li>
                <li>Descripción del proyecto:</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-sm-6">
              <div class="card" style="width: 18rem">
                <div class="card-header">
                  Árboles Donados
                  <span class="badge badge-success">000000</span>
                </div>
                <img
                  class="card-img-top"
                  src="../../../assets/imagenes/categoría_Madera_Aserrada.webp"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Boyacá</h5>
                  <button type="button" class="btn btn-warning">
                    Donar un árbol
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h3 class="subtitle">Detalles del Proyecto</h3>
              <ol>
                <li>Número de familias beneficiadas:</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-sm-6">
              <div class="card" style="width: 18rem">
                <div class="card-header">
                  Árboles Donados
                  <span class="badge badge-success">000000</span>
                </div>
                <img
                  class="card-img-top"
                  src="../../../assets/imagenes/categoría_Madera_Rolliza.webp"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">Meta</h5>
                  <button type="button" class="btn btn-warning">
                    Donar un árbol
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h3 class="subtitle">Detalles del Proyecto</h3>
              <ol>
                <li>Número de familias beneficiadas:</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <!--Sección de emprendimientos forestales-->
  <div
    class="tab-pane fade"
    id="enterprenaur"
    role="tabpanel"
    aria-labelledby="enterprenaur-tab"
  >
    <h2 class="titulo">Invierte en tu Emprendimiento Favorito</h2>
    <p>
      Por cada tres (3) meses de suscripción pagos recibiras una moneda digital
      <b>"TreeCoin"</b> que equivale a $5.000 pesos colombianos (COP) en tu
      perfil que podrás usar para donar a un emprendimiento forestal. Elige el
      emprendimiento que más te llame la atención y ayúdalo para que se haga
      realidad.
    </p>
  </div>
  <!--Sección de escuela forestal-->
  <div
    class="tab-pane fade"
    id="forestschool"
    role="tabpanel"
    aria-labelledby="forestschool-tab"
  >
    <h2 class="titulo">Invierte en tu Video Favorito</h2>
    <p>
      Por cada tres (3) meses de suscripción pagos recibiras una moneda digital
      <b>"TreeCoin"</b> en tu perfil que podrás usar para donar en la creación
      de un video forestal educativo. Elige el tema forestal que más te llame la
      atención y ayúdanos a que se haga realidad. Los recursos destinados a este
      tema serviran para patrocinar el proceso de grabación y su posterior
      publicación en redes sociales como Facebook y YouTube para que sirvan de
      material educativo gratuito a productores agropecuarios y comunidades
      étnicas.
    </p>
  </div>
</div>
