import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { User } from "@angular/fire/auth";
// Importacion de servicios
import { AuthenticationStateModel } from "../state/authentication-state";
import { AuthenticationState } from "../state/authentication-state/state";
import { Path } from "../enums/Path";
import { UserType } from "../enums/UserTypes";
import { TranslateService } from "@ngx-translate/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { AuthService } from "../services/auth.service";
import { UserData, UserService } from "../services/user.service";
import { NavigateService } from "../services/navigate.service";
import { LangTranslateService } from "../services/lang-translate.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"],
})
export class NavBarComponent implements OnDestroy {
  // observables
  @Select(AuthenticationState)
  authenticationState$!: Observable<AuthenticationStateModel>;

  // flags
  private sub$: Subject<void> = new Subject();
  public user: User | null = null;
  public userIsActive: boolean | string = false;
  public path: typeof Path = Path;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public translate: TranslateService,
    public navigate: NavigateService,
    public langService: LangTranslateService,
    public localize: LocalizeRouterService
  ) {
    this.authenticationState$
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (state: AuthenticationStateModel) => {
            this.userIsActive = state.userIsActive;
            this.user = state.user;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  async seleccionPerfil(): Promise<void> {
    const user: User | null = this.authService.getCurrentUser();

    if (!user) return;

    try {
      const usuarioData: UserData = await this.userService.getUserData(
        user.uid
      );
      let ruta: string = "";
      const { tipo } = usuarioData;
      const tiposUser = typeof tipo === "string" ? [tipo] : tipo;
      ruta = tiposUser.includes(UserType.Administrador)
        ? Path.Administrador
        : Path.PerfilUsuarios;
      this.router.navigate([this.langService.lang, ruta]);
    } catch (err) {
      console.error(err);
    }
  }

  changeLanguage(): void {
    const route: string[] = this.router.url.split("/");
    const [, lang] = route;
    const newLang: "es" | "en" = lang === "es" ? "en" : "es";
    route[1] = newLang;
    this.router.navigateByUrl(route.join("/"));
    this.translate.setDefaultLang(newLang);
    this.langService.setLang(newLang);
  }

  async onLogout(): Promise<void> {
    try {
      await this.authService.logout();
    } catch (error) {
      console.error(error);
    }
  }
}
