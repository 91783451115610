<div class="container-fluid">
  <h2>{{ "crear-servicio.creacion" | translate }}</h2>
  <p>
    {{ "crear-servicio.estaSeccion" | translate }}
  </p>

  <div *ngIf="typeUser?.includes(typeTemplate.Transportador)">
    <app-transportador></app-transportador>
  </div>
  <div *ngIf="typeUser?.includes(typeTemplate.Tecnico)">
    <app-tecnico></app-tecnico>
  </div>
</div>
