<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneMunicipio">{{
        "formularios.municipio" | translate
      }}</label>
      <select
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneMunicipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="seleccioneEspecie">{{
        "formularios.especie" | translate
      }}</label>
      <select
        name="especie"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneEspecie"
        formControlName="especie"
        [ngClass]="{
          'is-invalid': isInvalidField('especie'),
          'is-valid': isValidField('especie')
        }"
        required
      >
        <option value="">
          {{ "formularios.seleccioneEspecie" | translate }}
        </option>
        <option
          *ngFor="let especie of especiesHabilitadas?.sort()"
          [value]="especie"
        >
          {{ especie }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('especie')"></app-errores>
    </div>
    <div class="row">
      <img
        class="img-fluid"
        src="../../../assets/imagenes/arbolEnPieProduct.webp"
        alt="Madera rolliza"
      />
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadNoMaderable">{{
        "crear-producto.unidadMedida" | translate
      }}</label>
      <select
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadNoMaderable"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="">
          {{ "crear-producto.selectUnidadMedida" | translate }}
        </option>
        <option value="hectarea">
          {{ "crear-producto.hectarea" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="alturaBosque"
        >{{ "crear-producto.alturaPromedio" | translate }} (m)
        <a
          href="https://www.youtube.com/watch?v=6hGYPNQKo9E&t=23s"
          target="_blank"
          >{{ "crear-producto.verVideo" | translate }}</a
        ></label
      >
      <input
        type="number"
        name="altura"
        class="form-control"
        placeholder="{{ 'crear-producto.enMetros' | translate }}"
        id="alturaBosque"
        formControlName="altura"
        [ngClass]="{
          'is-invalid': isInvalidField('altura'),
          'is-valid': isValidField('altura')
        }"
      />
      <app-errores [control]="productoForm.get('altura')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="diametroBosque"
        >{{ "crear-producto.diametroPromedio" | translate }} (cm)
        <a
          href="https://www.youtube.com/watch?v=-b3J3M2A6FU&t=557s"
          target="_blank"
          >{{ "crear-producto.verVideo" | translate }}</a
        ></label
      >
      <input
        type="number"
        name="grosor"
        class="form-control"
        placeholder="{{ 'crear-producto.enCentimetros' | translate }}"
        id="diametroBosque"
        formControlName="grosor"
        [ngClass]="{
          'is-invalid': isInvalidField('grosor'),
          'is-valid': isValidField('grosor')
        }"
      />
      <app-errores [control]="productoForm.get('grosor')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="edadBosque">{{
        "crear-producto.edadBosque" | translate
      }}</label>
      <input
        type="number"
        name="edad"
        class="form-control"
        placeholder="En años"
        id="edadBosque"
        formControlName="edad"
        [ngClass]="{
          'is-invalid': isInvalidField('edad'),
          'is-valid': isValidField('edad')
        }"
      />
      <app-errores [control]="productoForm.get('edad')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="areaBosque">{{
        "crear-producto.areaDisponible" | translate
      }}</label>
      <input
        name="disponibilidadVenta"
        class="form-control"
        placeholder="En hectáreas"
        id="areaBosque"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="arbolesHectarea">{{
        "crear-producto.numeroArboles" | translate
      }}</label>
      <input
        type="number"
        name="arbolesPorArea"
        class="form-control"
        placeholder="Por hectárea"
        id="arbolesHectarea"
        formControlName="arbolesPorArea"
        [ngClass]="{
          'is-invalid': isInvalidField('arbolesPorArea'),
          'is-valid': isValidField('arbolesPorArea')
        }"
      />
      <app-errores [control]="productoForm.get('arbolesPorArea')"></app-errores>
    </div>

    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantVenta5">{{
        "crear-producto.cantidadMinima" | translate
      }}</label>
      <input
        name="cantidadMinimaVenta"
        class="form-control"
        placeholder="En hectáreas"
        id="cantVenta5"
        formControlName="cantidadMinimaVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('cantidadMinimaVenta'),
          'is-valid': isValidField('cantidadMinimaVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('cantidadMinimaVenta')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="carbonBonus"
        >{{ "crear-producto.volumenTotal" | translate }} (M³)</label
      >
      <input
        name="volumenTotal"
        class="form-control"
        id="carbonBonus"
        formControlName="volumenTotal"
        placeholder="{{ 'crear-producto.enMetrosCubicos' | translate }}"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores [control]="productoForm.get('volumenTotal')"></app-errores>
    </div>
    <div class="row" *ngIf="!visualizar">
      <div class="form-group col-md-6">
        <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
        <select
          name="moneda"
          class="form-select"
          aria-label="Default select example"
          id="moneda"
          formControlName="moneda"
          [ngClass]="{
            'is-invalid': isInvalidField('moneda'),
            'is-valid': isValidField('moneda')
          }"
        >
          <option value="">
            {{ "crear-producto.selectMoneda" | translate }}
          </option>
          <option [value]="moneda" *ngFor="let moneda of listaMonedas">
            {{ "crear-producto." + moneda | translate }}
          </option>
        </select>
        <app-errores [control]="productoForm.get('moneda')"></app-errores>
      </div>
      <div class="form-group col-md-6">
        <label for="precioVenta5"
          >{{ "crear-producto.precioHectarea" | translate
          }}<a target="_blank" href="https://issuu.com/redforestal"
            >({{ "crear-producto.preciosReferencia" | translate }})</a
          ></label
        >
        <input
          currencyMask
          [options]="{ inputMode: 0, precision: 0, thousands: '.' }"
          name="precio"
          class="form-control"
          id="precioVenta5"
          formControlName="precio"
          [ngClass]="{
            'is-invalid': isInvalidField('precio'),
            'is-valid': isValidField('precio')
          }"
        />
        <app-errores [control]="productoForm.get('precio')"></app-errores>
      </div>
    </div>
    <div class="form-group col-md-6 mt-1">
      <label for="carbonBonus">{{
        "crear-producto.dineroCarbono" | translate
      }}</label>
      <select
        name="carbonBonus"
        class="form-select"
        aria-label="Default select example"
        id="carbonBonus"
        formControlName="carbonBonus"
        [ngClass]="{
          'is-invalid': isInvalidField('carbonBonus'),
          'is-valid': isValidField('carbonBonus')
        }"
        required
      >
        <option value="SI">{{ "formularios.si" | translate }}</option>
        <option value="NO">{{ "formularios.no" | translate }}</option>
      </select>
      <app-errores [control]="productoForm.get('carbonBonus')"></app-errores>
    </div>
    <div class="form-group col-md-12">
      <label for="otros">{{
        "crear-producto.otrosDatosInteres" | translate
      }}</label>
      <textarea
        class="form-control"
        placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
        name="datosInteres"
        rows="3"
        id="otros"
        formControlName="datosInteres"
      ></textarea>
      <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
    </div>
  </div>
</form>
