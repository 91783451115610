<div class="container-fluid">
  <!---------------------------slider---------------------------->
  <div class="row">
    <div class="col-12">
      <h1>{{ "index.encabezado" | translate }}</h1>
      <ngb-carousel class="picsum-img-wrapper">
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              [src]="slides.slide1.src"
              [ngClass]="{
                'cursor-pointer': slides.slide1?.href ? true : false
              }"
              (click)="openHref(slides.slide1?.href)"
            />
          </div>
          <div class="carousel-caption"></div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              [src]="slides.slide2.src"
              [ngClass]="{
                'cursor-pointer': slides.slide2?.href ? true : false
              }"
              (click)="openHref(slides.slide2?.href)"
            />
          </div>
          <div class="carousel-caption"></div>
        </ng-template>
        <ng-template ngbSlide>
          <div class="picsum-img-wrapper">
            <img
              [src]="slides.slide3.src"
              [ngClass]="{
                'cursor-pointer': slides.slide3?.href ? true : false
              }"
              (click)="openHref(slides.slide3?.href)"
            />
          </div>
          <div class="carousel-caption"></div>
        </ng-template>
      </ngb-carousel>
      <!-- botón registro-->
      <app-btn-registro></app-btn-registro>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-----------------categories------>
      <section class="featured-categories">
        <h2 classs="subtitulo-index">{{ "index.categoriasP" | translate }}</h2>
        <div
          class="row row-cols-2 row-cols-sm-2 row-cols-md-6 row-cols-lg-6 row-cols-xl-6"
        >
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Bosque')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoría_Bosque.webp"
                  class="card-img-top"
                  alt="Imagen categorìa bosque"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.bosque" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Árbol en pie')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoría_árbol_en_pie.webp"
                  class="card-img-top"
                  alt="Imagen categorìa àrbol en pie"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.arbolPie" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Madera aserrada')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoría_Madera_Aserrada.webp"
                  class="card-img-top"
                  alt="Imagen categorìa madera aserrada"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.maderaAserrada" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Madera rolliza')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoría_Madera_Rolliza.webp"
                  class="card-img-top"
                  alt="Imagen categorìa madera rolliza"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.maderaRolliza" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('No maderable')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoría_PFNM.webp"
                  class="card-img-top"
                  alt="Imagen categorìa PFNM"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.noMaderable" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Vivero')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/categoria_vivero.webp"
                  class="card-img-top"
                  alt="Imagen categorìa vivero"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.viveroForestal" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Vivero ornamental')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/vivero_ornamental.webp"
                  class="card-img-top"
                  alt="Imagen categorìa vivero"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.viveroOrnamental" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Vivero frutal')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/vivero_frutal.webp"
                  class="card-img-top"
                  alt="Imagen categorìa bosque"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.viveroFrutal" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Producto terminado')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/producto_transformado.webp"
                  class="card-img-top"
                  alt="Imagen categorìa àrbol en pie"
                />
                <div class="card-footer">
                  <p class="text">
                    {{ "index.productoTerminado" | translate }}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Equipo y Maquinaria')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/maquinas_forestales.webp"
                  class="card-img-top"
                  alt="Imagen categorìa madera aserrada"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.equipoMaquinaria" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Insumos')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/isumos_category.webp"
                  class="card-img-top"
                  alt="Imagen categorìa madera rolliza"
                />
                <div class="card-footer">
                  <p class="text">{{ "index.insumos" | translate }}</p>
                </div>
              </a>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <a
                (click)="redireccionFiltro('Tierras para proyectos')"
                [routerLink]="[path.Catalogo] | langRoute | async"
              >
                <img
                  src="assets/imagenes/tierra.webp"
                  class="card-img-top"
                  alt="Imagen tierra para proyectos"
                />
                <div class="card-footer">
                  <p class="text">
                    {{ "categorias-lista.Tierras para proyectos" | translate }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="productos-todos">
          <button
            type="button"
            class="btn btn-warning btn-lg"
            (click)="redireccionFiltro('')"
            [routerLink]="[path.Catalogo] | langRoute | async"
          >
            {{ "index.todosProductos" | translate }}
          </button>
          <h3 class="mt-3">
            {{ "index.informacionCategorias" | translate }}
            <a class="text" [routerLink]="['categorias'] | langRoute | async">
              {{ "index.aqui" | translate }}
            </a>
          </h3>
        </div>
      </section>
      <!--------nuevos-productos de usuarios, últimos 5---------------- -->
      <section class="new-products">
        <h2 classs="subtitulo-index">
          {{ "index.nuevosProductos" | translate }}
        </h2>
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
        >
          <ng-container *ngFor="let nuevoProducto of nuevosProductos">
            <app-producto [producto]="nuevoProducto"></app-producto>
          </ng-container>
        </div>
      </section>
    </div>
  </div>
</div>
