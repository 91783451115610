import {
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { DocumentReference, Timestamp } from "@angular/fire/firestore";
import { Subject, takeUntil, tap } from "rxjs";
import { DatosGenerales } from "src/app/models/datosGenerales.interface";
import { CotizacionPdfService } from "src/app/services/cotizacion-pdf.service";
import { CotizacionService } from "src/app/services/cotizacion.service";

@Component({
  selector: "app-cotizacion",
  templateUrl: "./cotizacion.component.html",
  styleUrls: ["./cotizacion.component.css"],
  providers: [NgbModalConfig, NgbModal],
})
export class CotizacionComponent implements OnInit, OnDestroy {
  @ViewChild("content") modal!: ElementRef;
  @ViewChild("loading") loadingModal!: ElementRef;
  @ViewChild("modalCerrar") cerrarModal!: ElementRef;
  @Output() callBack: EventEmitter<(args: any) => void> = new EventEmitter();
  @Input() currentUserId!: string;
  private unsubscriber: Subject<void> = new Subject();
  closeResult: string = "";
  userUid!: string;
  datosEmpresa: any;
  dataCotizacion!: {
    productos: { listaProductos: any; total: any };
    datosUsuario: {
      nombre: string;
      ciudad: string;
      telefono: any;
      direccion: any;
      email: any;
      uid: any;
      tdocumento: any;
      numerodocumento: any;
    };
    fecha: Timestamp;
    consecutivo: any;
  };
  loader: any;
  modalCerrar: any;
  modalCotizacion: any;
  cotizacionId!: string;

  constructor(
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private cotizacionSvc: CotizacionService,
    private cotizacionPdf: CotizacionPdfService
  ) {
    this.config.backdrop = "static";
    this.config.keyboard = false;
    this.cotizacionSvc
      .getDatosConsecutivo()
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (datos: DatosGenerales) => {
            this.datosEmpresa = datos;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.callBack.emit(this.generate.bind(this));
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  open(): void {
    this.modalCotizacion = this.modalService.open(this.modal, {
      size: "lg",
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });

    this.modalCotizacion.result
      .then((result: any) => {
        this.closeResult = `Closed with: ${result}`;
      })
      .catch((reason: any) => (this.closeResult = `Dismissed ${reason}`));
  }

  generate(cotizacionData: any) {
    this.loading();
    this.dataCotizacion = this.processData(cotizacionData);
    this.addCotizacion();
  }

  processData(cotizacionData: any): any {
    const { productos, usuario, total } = cotizacionData;
    const {
      nombres,
      apellidos,
      municipio,
      departamento,
      telefono,
      direccion,
      email,
      uid,
      tdocumento,
      numerodocumento,
    } = usuario || {};

    const documentData = {
      productos: {
        listaProductos: productos,
        total,
      },
      datosUsuario: {
        nombre: `${nombres} ${apellidos}`,
        ciudad: `${municipio} (${departamento})`,
        telefono,
        direccion,
        email,
        tdocumento,
        numerodocumento,
        uid,
      },
      fecha: Timestamp.now(),
      consecutivo: this.datosEmpresa.consecutivoCotizaciones,
    };

    return documentData;
  }

  async addCotizacion(): Promise<void> {
    try {
      const cotizacion: DocumentReference =
        await this.cotizacionSvc.addCotizacion({ ...this.dataCotizacion });
      if (cotizacion.id) {
        this.cotizacionId = cotizacion.id;
        this.loading(true);
        this.open();
      }
    } catch (e) {
      console.error(e);
    }
  }

  loading(close?: boolean): void {
    if (close && this.loader) {
      this.loader.close();
    } else {
      this.loader = this.modalService.open(this.loadingModal, {
        centered: true,
        size: "sm",
      });
    }
  }

  close(cerrar?: boolean) {
    if (cerrar) {
      this.modalCerrar.close();
      this.modalCotizacion.close();
      return;
    }

    this.modalCerrar = this.modalService.open(this.cerrarModal, {
      centered: true,
      size: "sm",
    });
  }

  generarPdf(accion: "open" | "download" | "print") {
    this.cotizacionPdf.generarCotizacionPdf(this.cotizacionId, accion);
  }
}
