export const productoTerminadoSub: string[] = [
  "Accesorios y Artículos de Oficina",
  "Alfardas y Estacones",
  "Archivadores",
  "Artesanías",
  "Artículos agrícolas",
  "ArtículosHogar",
  "Asoleadoras y Sillas Playeras",
  "Bancas de Jardín",
  "Bibliotecas",
  "Biscicletas",
  "Cabeceros para Cama",
  "Camas",
  "Camas Infantiles y Cunas",
  "Cantos para Tablero",
  "Centros de Entretenimiento y Muebles para TV",
  "Closets y/o Armarios",
  "Cocina Integral",
  "Comedor",
  "Comedores Infantiles",
  "Consolas y Bifés",
  "Escritorios",
  "Estibas",
  "Gafas",
  "Hamacas y Sillones Columpio",
  "Implementos de Cocina",
  "Juegos de Comedor",
  "Juegos de Sala",
  "Juguetes",
  "Lamparas",
  "Lockers",
  "Madera Maciza",
  "Mesas",
  "Mesas de Centro y Auxiliares",
  "Mesas de Comedor",
  "Mesas de Noche",
  "Mesas para Exterior y Terraza",
  "Muebles Infantiles",
  "Muebles para Exterior y Terrazas",
  "Muebles para Terraza y Exterior",
  "Muebles y Sillas para Bar",
  "Pergolas, Toldos y Carpas",
  "Pisos",
  "Puertas",
  "Relojes",
  "Repisas",
  "Sala",
  "Sillas",
  "Sillas de Comedor",
  "Sillas para Exterior y Terraza",
  "Sillas para Oficina y Escritorio",
  "Sillas Reclinables y Descanso",
  "Sillas Tandem y para Salas de Espera",
  "Sillones y Poltronas",
  "Sofá Camas",
  "Sofás",
  "Tablero Alistonado",
  "Tableros Acrílicos, de Corcho y Pizarras",
  "Tableros Aglomerados y Meláminicos",
  "Tableros MDF",
  "Tableros para Construcción",
  "Ventanas",
  "Zapateros y Recibidores",
  "Casas",
];
