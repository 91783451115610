<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="municipio">{{ "formularios.municipio" | translate }}</label>
      <select
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="municipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option
          *ngFor="let municipio of listaMunicipios || []"
          [value]="municipio"
        >
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="productoNoMaderable">{{
        "crear-producto.producto" | translate
      }}</label>
      <select
        name="producto"
        class="form-select"
        aria-label="Default select example"
        id="productoNoMaderable"
        formControlName="producto"
        [ngClass]="{
          'is-invalid': isInvalidField('producto'),
          'is-valid': isValidField('producto')
        }"
      >
        <option *ngFor="let producto of productos" [value]="producto">
          {{ "crear-producto." + producto | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('producto')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadNoMaderable">{{
        "crear-producto.unidadMedida" | translate
      }}</label>
      <select
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadNoMaderable"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="tonelada">
          {{ "crear-producto.tonelada" | translate }}
        </option>
        <option value="kilo">{{ "crear-producto.kilo" | translate }}</option>
        <option value="libra">{{ "crear-producto.libra" | translate }}</option>
        <option value="gramo">{{ "crear-producto.gramo" | translate }}</option>
        <option value="galón">{{ "crear-producto.galon" | translate }}</option>
        <option value="litro">{{ "crear-producto.litro" | translate }}</option>
        <option value="mililitro">
          {{ "crear-producto.mililitro" | translate }}
        </option>
        <option value="bulto50">
          {{ "crear-producto.bulto50" | translate }}
        </option>
        <option value="bulto40">
          {{ "crear-producto.bulto40" | translate }}
        </option>
        <option value="bulto30">
          {{ "crear-producto.bulto30" | translate }}
        </option>
        <option value="bulto25">
          {{ "crear-producto.bulto25" | translate }}
        </option>
        <option value="bulto15">
          {{ "crear-producto.bulto15" | translate }}
        </option>
        <option value="bulto10">
          {{ "crear-producto.bulto10" | translate }}
        </option>
        <option value="metro">{{ "crear-producto.metro" | translate }}</option>
        <option value="metros400">
          {{ "crear-producto.metros400" | translate }}
        </option>
        <option value="metros200">
          {{ "crear-producto.metros200" | translate }}
        </option>
        <option value="metros100">
          {{ "crear-producto.metros100" | translate }}
        </option>
        <option value="metros50">
          {{ "crear-producto.metros50" | translate }}
        </option>
        <option value="metros25">
          {{ "crear-producto.metros25" | translate }}
        </option>
        <option value="metros20">
          {{ "crear-producto.metros20" | translate }}
        </option>
        <option value="litros20">
          {{ "crear-producto.litros20" | translate }}
        </option>
        <option value="litros4">
          {{ "crear-producto.litros4" | translate }}
        </option>
        <option value="unidades1000">
          {{ "crear-producto.unidades1000" | translate }}
        </option>
        <option value="unidades100">
          {{ "crear-producto.unidades100" | translate }}
        </option>
        <option value="kit">{{ "crear-producto.kit" | translate }}</option>
        <option value="bandeja">
          {{ "crear-producto.bandeja" | translate }}
        </option>
        <option value="unidad">
          {{ "crear-producto.unidad" | translate }}
        </option>
        <option value="caja">{{ "crear-producto.caja" | translate }}</option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantidadNoMaderable">{{
        "crear-producto.cantidadDisponible" | translate
      }}</label>
      <input
        name="disponibilidadVenta"
        class="form-control"
        placeholder="Para vender"
        id="cantidadNoMaderable"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantVenta4">{{
        "crear-producto.cantidadMinima" | translate
      }}</label>
      <input
        name="cantidadMinimaVenta"
        class="form-control"
        placeholder="De unidades"
        id="cantVenta4"
        formControlName="cantidadMinimaVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('cantidadMinimaVenta'),
          'is-valid': isValidField('cantidadMinimaVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('cantidadMinimaVenta')"
      ></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="cantidadNoMaderable">{{
        "crear-producto.marca" | translate
      }}</label>
      <input
        type="text"
        name="marca"
        class="form-control"
        placeholder="Marca"
        id="cantidadNoMaderable"
        formControlName="marca"
        [ngClass]="{
          'is-invalid': isInvalidField('marca'),
          'is-valid': isValidField('marca')
        }"
      />
      <app-errores [control]="productoForm.get('marca')"></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="cantidadNoMaderable">{{
        "crear-producto.modelo" | translate
      }}</label>
      <input
        type="text"
        name="modelo"
        class="form-control"
        placeholder="modelo"
        id="cantidadNoMaderable"
        formControlName="modelo"
        [ngClass]="{
          'is-invalid': isInvalidField('modelo'),
          'is-valid': isValidField('modelo')
        }"
      />
      <app-errores [control]="productoForm.get('modelo')"></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="fechaFabricacion">{{
        "crear-producto.fechaFabricacion" | translate
      }}</label>

      <input
        formControlName="fechaFabricacion"
        [ngClass]="{
          'is-invalid': isInvalidField('fechaFabricacion'),
          'is-valid': isValidField('fechaFabricacion')
        }"
        class="form-control"
        name="fechaFabricacion"
        formControlName="fechaFabricacion"
        placeholder="{{ 'crear-producto.fechaFabricacion' | translate }}"
        ngbDatepicker
        #fechaFabricacion="ngbDatepicker"
        (click)="fechaFabricacion.toggle()"
      />

      <app-errores
        [control]="productoForm.get('fechaFabricacion')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="disponibilidadProducto">{{
        "crear-producto.disponibilidadProducto" | translate
      }}</label>
      <select
        name="disponibilidadProducto"
        class="form-select"
        aria-label="Default select example"
        id="disponibilidadProducto"
        formControlName="disponibilidadProducto"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadProducto'),
          'is-valid': isValidField('disponibilidadProducto')
        }"
        required
      >
        <option value="inmediata">
          {{ "crear-producto.inmediata" | translate }}
        </option>
        <option value="encargo">
          {{ "crear-producto.encargo" | translate }}
        </option>
      </select>
      <app-errores
        [control]="productoForm.get('disponibilidadProducto')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="vencimientoProducto">{{
        "crear-producto.fechaVencimiento" | translate
      }}</label>

      <input
        formControlName="vencimientoProducto"
        [ngClass]="{
          'is-invalid': isInvalidField('vencimientoProducto'),
          'is-valid': isValidField('vencimientoProducto')
        }"
        class="form-control"
        name="vencimientoProducto"
        formControlName="vencimientoProducto"
        placeholder="{{ 'crear-producto.fechaVencimiento' | translate }}"
        ngbDatepicker
        #vencimientoProducto="ngbDatepicker"
        (click)="vencimientoProducto.toggle()"
      />

      <app-errores
        [control]="productoForm.get('vencimientoProducto')"
      ></app-errores>
    </div>

    <div class="row" *ngIf="!visualizar">
      <div class="form-group col-md-6">
        <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
        <select
          name="moneda"
          class="form-select"
          aria-label="Default select example"
          id="moneda"
          formControlName="moneda"
          [ngClass]="{
            'is-invalid': isInvalidField('moneda'),
            'is-valid': isValidField('moneda')
          }"
        >
          <option value="">
            {{ "crear-producto.selectMoneda" | translate }}
          </option>
          <option [value]="moneda" *ngFor="let moneda of listaMonedas">
            {{ "crear-producto." + moneda | translate }}
          </option>
        </select>
        <app-errores [control]="productoForm.get('moneda')"></app-errores>
      </div>
      <div class="form-group col-md-6">
        <label for="precioVenta5"
          >{{ "crear-producto.precioUnidad" | translate
          }}<a target="_blank" href="https://issuu.com/redforestal"
            >( {{ "crear-producto.preciosReferencia" | translate }} )</a
          ></label
        >
        <input
          currencyMask
          [options]="{
            inputMode: 0,
            precision: 0,
            thousands: '.'
          }"
          name="precio"
          class="form-control"
          id="precioVenta5"
          formControlName="precio"
          [ngClass]="{
            'is-invalid': isInvalidField('precio'),
            'is-valid': isValidField('precio')
          }"
        />
        <app-errores [control]="productoForm.get('precio')"></app-errores>
      </div>
    </div>

    <div class="form-group col-md-12">
      <label for="otros">{{
        "crear-producto.especificacionesTecnicas" | translate
      }}</label>
      <textarea
        class="form-control"
        placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
        name="datosInteres"
        rows="3"
        id="otros"
        formControlName="datosInteres"
      ></textarea>
      <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
    </div>
  </div>
</form>
