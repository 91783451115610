import { Component } from "@angular/core";
import { Path } from "src/app/enums/Path";
import { Subject } from "rxjs";

@Component({
  selector: "app-modal-auth",
  templateUrl: "./modal-auth.component.html",
  styleUrls: ["./modal-auth.component.css"],
})
export class ModalAuthComponent {
  public path: typeof Path = Path;
  public closeEvent: Subject<boolean> = new Subject<boolean>();

  constructor() {}
}
