<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneMunicipio">{{
        "formularios.municipio" | translate
      }}</label>
      <select
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneMunicipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="seleccioneEspecie">{{
        "formularios.especie" | translate
      }}</label>
      <select
        name="especie"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneEspecie"
        formControlName="especie"
        [ngClass]="{
          'is-invalid': isInvalidField('especie'),
          'is-valid': isValidField('especie')
        }"
        required
      >
        <option value="">
          {{ "formularios.seleccioneEspecie" | translate }}
        </option>
        <option
          *ngFor="let especie of especiesHabilitadas?.sort()"
          [value]="especie"
        >
          {{ especie }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('especie')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="productoNoMaderable">{{
        "crear-producto.producto" | translate
      }}</label>
      <select
        name="producto"
        class="form-select"
        aria-label="Default select example"
        id="productoNoMaderable"
        formControlName="producto"
        [ngClass]="{
          'is-invalid': isInvalidField('producto'),
          'is-valid': isValidField('producto')
        }"
      >
        <option *ngFor="let producto of productos" [value]="producto">
          {{ "crear-producto." + producto | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('producto')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadNoMaderable">{{
        "crear-producto.unidadMedida" | translate
      }}</label>
      <select
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadNoMaderable"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="unidad">
          {{ "crear-producto.unidad" | translate }}
        </option>
        <option value="tablero">
          {{ "crear-producto.tablero" | translate }}
        </option>
        <option value="kit">{{ "crear-producto.kit" | translate }}</option>
        <option value="metroCuadrado">
          {{ "crear-producto.metroCuadrado" | translate }}
        </option>
        <option value="metroCubico">
          {{ "crear-producto.metroCubico" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantidadNoMaderable">{{
        "crear-producto.cantidadDisponible" | translate
      }}</label>
      <input
        name="disponibilidadVenta"
        class="form-control"
        placeholder="Para vender"
        id="cantidadNoMaderable"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="cantidadNoMaderable">{{
        "crear-producto.marca" | translate
      }}</label>
      <input
        type="text"
        name="marca"
        class="form-control"
        placeholder="Marca"
        id="cantidadNoMaderable"
        formControlName="marca"
        [ngClass]="{
          'is-invalid': isInvalidField('marca'),
          'is-valid': isValidField('marca')
        }"
      />

      <app-errores [control]="productoForm.get('marca')"></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="cantidadNoMaderable">{{
        "crear-producto.modelo" | translate
      }}</label>
      <input
        type="text"
        name="modelo"
        class="form-control"
        placeholder="{{ 'crear-producto.modelo' | translate }}"
        id="cantidadNoMaderable"
        formControlName="modelo"
        [ngClass]="{
          'is-invalid': isInvalidField('modelo'),
          'is-valid': isValidField('modelo')
        }"
      />
      <app-errores [control]="productoForm.get('modelo')"></app-errores>
    </div>
    <div class="form-group col-6">
      <label for="estado">{{ "crear-producto.estado" | translate }}</label>
      <select
        name="estado"
        class="form-select"
        aria-label="Default select example"
        id="estado"
        formControlName="estadoProducto"
        [ngClass]="{
          'is-invalid': isInvalidField('estadoProducto'),
          'is-valid': isValidField('estadoProducto')
        }"
        required
      >
        <option value="nuevo">{{ "crear-producto.nuevo" | translate }}</option>
        <option value="usado">{{ "crear-producto.usado" | translate }}</option>
      </select>
      <app-errores [control]="productoForm.get('estadoProducto')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="fechaFabricacion">{{
        "crear-producto.fechaFabricacion" | translate
      }}</label>
      <input
        class="form-control"
        name="fDesde"
        formControlName="fechaFabricacion"
        [ngClass]="{
          'is-invalid': isInvalidField('fechaFabricacion'),
          'is-valid': isValidField('fechaFabricacion')
        }"
        placeholder="{{ 'crear-producto.fechaFabricacion' | translate }}"
        ngbDatepicker
        #fechaFabricacion="ngbDatepicker"
        (click)="fechaFabricacion.toggle()"
      />
      <app-errores
        [control]="productoForm.get('fechaFabricacion')"
      ></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="estado">{{
        "crear-producto.disponibilidadProducto" | translate
      }}</label>
      <select
        name="disponibilidadProducto"
        class="form-select"
        aria-label="Default select example"
        id="disponibilidadProducto"
        formControlName="disponibilidadProducto"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadProducto'),
          'is-valid': isValidField('disponibilidadProducto')
        }"
        required
      >
        <option value="inmediata">
          {{ "crear-producto.inmediata" | translate }}
        </option>
        <option value="encargo">
          {{ "crear-producto.encargo" | translate }}
        </option>
      </select>
      <app-errores
        [control]="productoForm.get('disponibilidadProducto')"
      ></app-errores>
    </div>
    <div class="row" *ngIf="!visualizar">
      <div class="form-group col-md-6">
        <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
        <select
          name="moneda"
          class="form-select"
          aria-label="Default select example"
          id="moneda"
          formControlName="moneda"
          [ngClass]="{
            'is-invalid': isInvalidField('moneda'),
            'is-valid': isValidField('moneda')
          }"
        >
          <option value="">
            {{ "crear-producto.selectMoneda" | translate }}
          </option>
          <option [value]="moneda" *ngFor="let moneda of listaMonedas">
            {{ "crear-producto." + moneda | translate }}
          </option>
        </select>
        <app-errores [control]="productoForm.get('moneda')"></app-errores>
      </div>
      <div class="form-group col-md-6">
        <label for="precioVenta4"
          >{{ "crear-producto.precioUnidad" | translate
          }}<a target="_blank" href="https://issuu.com/redforestal"
            >({{ "crear-producto.preciosReferencia" | translate }})</a
          ></label
        >
        <input
          currencyMask
          [options]="{
            inputMode: 0,
            precision: 0,
            thousands: '.'
          }"
          name="precio"
          class="form-control"
          id="precioVenta4"
          formControlName="precio"
          [ngClass]="{
            'is-invalid': isInvalidField('precio'),
            'is-valid': isValidField('precio')
          }"
        />
        <app-errores [control]="productoForm.get('precio')"></app-errores>
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="cantidadNoMaderable">{{
        "crear-producto.garantia" | translate
      }}</label>
      <input
        type="number"
        name="garantia"
        class="form-control"
        id="cantidadNoMaderable"
        formControlName="garantia"
        placeholder="{{ 'crear-producto.mesesGarantia' | translate }}"
        [ngClass]="{
          'is-invalid': isInvalidField('garantia'),
          'is-valid': isValidField('garantia')
        }"
      />
      <app-errores [control]="productoForm.get('garantia')"></app-errores>
    </div>
    <div class="form-group col-md-12">
      <label for="otros">{{
        "crear-producto.especificacionesTecnicas" | translate
      }}</label>
      <textarea
        class="form-control"
        placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
        name="datosInteres"
        rows="3"
        id="otros"
        formControlName="datosInteres"
      ></textarea>
      <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
    </div>
  </div>
</form>
