import { Component, OnDestroy, OnInit } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { Select } from "@ngxs/store";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "src/app//state/authentication-state";
import { UserData, UserService } from "src/app/services/user.service";
import { UserType } from "src/app/enums/UserTypes";

@Component({
  selector: "app-crear-servicio",
  templateUrl: "./crear-servicio.component.html",
  styleUrls: ["./crear-servicio.component.css"],
})
export class CrearServicioComponent implements OnInit, OnDestroy {
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  typeTemplate: typeof UserType = UserType;
  typeUser!: UserType | UserType[];

  private unsubscriber: Subject<void> = new Subject();
  public user!: User;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap((user: User | null) => {
          if (user) {
            this.user = user;
            this.getUserData(this.user.uid);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getUserData(uid: string): void {
    this.userService
      .getUserById(uid)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: UserData) => {
            this.typeUser = user.tipo;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }
}
