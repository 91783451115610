/* eslint-disable @typescript-eslint/naming-convention */
const paises = {
  "Alemania / Deutschland": {
    "Baden-Wurtemberg": ["Karlsruhe", "Stuttgart", "Tubinga", "Friburgo"],
    "Baja Sajonia": [
      "Ammerland",
      "Aurich",
      "Grafschaft Bentheim",
      "Celle",
      "Cloppenburg",
      "Cuxhaven",
      "Diepholz",
      "Emsland",
      "Friesland",
      "Gifhorn",
      "Goslar",
      "Gotinga",
      "Hameln-Pyrmont",
      "Región Hannover",
      "Harburg",
      "Heidekreis",
      "Helmstedt",
      "Hildesheim",
      "Holzminden",
      "Leer",
      "Lüchow-Dannenberg",
      "Lüneburg",
      "Nienburg/Weser",
      "Northeim",
      "Oldemburgo",
      "Osnabrück",
      "Osterholz",
      "Peine",
      "Rotenburg",
      "Schaumburg",
      "Stade",
      "Uelzen",
      "Vechta",
      "Verden",
      "Wesermarsch",
      "Wittmund",
      "Wolfenbüttel",
    ],
    Baviera: [
      "Baja Franconia (Unterfranken) (Wurzburgo)",
      "Alta Franconia (Oberfranken) (Bayreuth)",
      "Franconia Central (Mittelfranken) (Ansbach)",
      "Suabia (Schwaben) (Augsburgo)",
      "Alto Palatinado (Oberpfalz) (Ratisbona)",
      "Alta Baviera (Oberbayern) (Múnich)",
      "Baja Baviera (Niederbayern) (Landshut)",
    ],
    Berlín: ["Berlín"],
    Brandeburgo: [
      "Barnim",
      "Dahme-Spreewald",
      "Elbe-Elster",
      "Havelland",
      "Märkisch-Oderland",
      "Oberhavel",
      "Oberspreewald-Lausitz",
      "Oder-Spree",
      "Ostprignitz-Ruppin",
      "Potsdam-Mittelmark",
      "Prignitz",
      "Spree-Neiße",
      "Teltow-Fläming",
      "Uckermark",
      "Brandeburgo",
      "Cottbus",
      "Fráncfort del Óder",
      "Potsdam",
    ],
    Bremen: ["Bremen", "Bremerhaven"],
    Hamburgo: [
      "Altona",
      "Bergedorf",
      "Eimsbüttel",
      "Hamburg-Mitte",
      "Hamburg-Nord",
      "Harburg",
      "Wandsbek",
    ],
    Hesse: ["Darmstadt", "Gießen", "Kassel"],

    "Mecklemburgo-Pomerania Occidental": [
      "Mecklemburgo Noroccidental",
      "Ludwigslust-Parchim",
      "Distrito de Rostock",
      "Meseta de lagos de Mecklemburgo",
      "Pomerania Occidental-Rügen",
      "Pomerania Occidental-Greifswald",
      "Ciudad autónoma de Schwerin",
      "Ciudad autónoma de Rostock",
    ],
    "Renania del Norte-Westfalia": [
      "Arnsberg",
      "Colonia",
      "Detmold",
      "Düsseldorf ",
      "Münster",
    ],
    "Renania-Palatinado": [
      "Bad Doberan",
      "Demmin",
      "Güstrow",
      "Ludwigslust",
      "Mecklemburgo-Strelitz",
      "Müritz",
      "Pomerania Anterior Septentrional(Nordvorpommern)",
      "Mecklemburgo Noroccidental(Nordwestmecklenburg)",
      "Pomerania Anterior Oriental(Ostvorpommern)",
      "Parchim",
      "Rügen",
      "Uecker-Randow",
      "Schwerin ",
      "Rostock",
    ],
    Sajonia: ["Chemnitz", "Dresde", "Leipzig"],
    "Sajonia-Anhalt": [
      "Altmarkkreis Salzwedel (SAW)",
      "Anhalt-Bitterfeld (ABI)",
      "Burgenland (BLK)",
      "Börde (BK)",
      "Harz (HZ)",
      "Jerichower Land (JL)",
      "Mansfeld-Südharz (MSH)",
      "Saale (SK)",
      "Salzland (SLK)",
      "Stendal (SDL)",
      "Wittenberg (WB)",
      "Dessau-Roßlau (DE)",
      "Halle (Saale) (HAL)",
      "Magdeburgo (MD)",
    ],
    Sarre: [
      "Merzig-Wadern",
      "Neunkirchen",
      "Saarbrücken",
      "Saarlouis",
      "Sarre-Palatinado",
      "Sankt Wendel",
    ],
    "Schleswig-Holstein": [
      "Dithmarschen (Heide, HEI)",
      "Herzogtum Lauenburg (Ratzeburg, RZ)",
      "Nordfriesland (Husum, NF)",
      "Ostholstein (Eutin, OH)",
      "Pinneberg (Pinneberg, PI)",
      "Plön (Plön, PLÖ)",
      "Rendsburg-Eckernförde (Rendsburg, RD)",
      "Schleswig-Flensburg (Schleswig, SL)",
      "Segeberg (Bad Segeberg, SE)",
      "Steinburg (Itzehoe, IZ)",
      "Stormarn (Bad Oldesloe, OD)",
      "Flensburgo (FL)",
      "Kiel (KI)",
      "Lübeck (HL)",
      "Neumünster (NMS)",
    ],
    Turingia: [
      "Altenburger Land",
      "Eichsfeld",
      "Gotha",
      "Greiz",
      "Hildburghausen",
      "Ilm-Kreis",
      "Kyffhäuserkreis",
      "Nordhausen",
      "Saale-Holzland-Kreis",
      "Saale-Orla-Kreis",
      "Saalfeld-Rudolstadt",
      "Schmalkalden-Meiningen",
      "Sömmerda",
      "Sonneberg",
      "Unstrut-Hainich-Kreis",
      "Wartburgkreis",
      "Weimarer Land",
      "Eisenach (EA)",
      "Erfurt (EF)",
      "Gera (G)",
      "Jena (J)",
      "Suhl (SHL)",
      "Weimar (WE)",
    ],
  },
  "Arabia Saudita": {
    Oriental: [
      "Dammam",
      "Al-Ahsa",
      "Hofuf",
      "Mubarraz",
      "Jubail",
      "Qatif",
      "Ras Tanura",
      "Safwa",
      "Al-Qatif",
      "Al-Hasa",
      "Al-Hofuf",
      "Al-Mubarraz",
    ],
    Asir: [
      "Abha",
      "Khamis Mushait",
      "Ahad Rufaidah",
      "Al-Majardah",
      "Al-Makhwah",
      "Al-Namas",
      "Al-Qahmah",
      "Bariq",
      "Billasmar",
      "Sarat Abidah",
      "Tathlith",
      "Tanomah",
      "Wadi Bin Hashbal",
      "Al-Hareeq",
    ],
    "Al Bahah": [
      "Al Bahah",
      "Almandaq",
      "Baljurashi",
      "Qilwah",
      "Almikhwah",
      "Alaqiq",
      "Bani Hassan",
      "Thamud",
      "Ghamid",
    ],
    "Al Hudud ash Shamaliyah": ["Arar", "Rafha", "Turayf", "Al Qurayyat"],
    "Al Jawf": [
      "Sakakah",
      "Al-Qurayyat",
      "Al-Hazm",
      "Tabarjal",
      "Al-Jawf",
      "Domat Al-Jandal",
      "Al-Mafrakah",
    ],
    "Al Madinah": [
      "Medina",
      "Yanbu",
      "Al-Hinakiyah",
      "Badr",
      "Mahd Al-Dhahab",
      "Khaybar",
      "Al-'Ula",
      "Wadi Al-Farigh",
      "Al-Mahwit",
      "Al-Jamum",
      "Al-Munawwarah",
      "Ad-Dilam",
      "Khulais",
      "Rumah",
      "Tabuk",
    ],
    "Al Qasim": [
      "Buraidah",
      "Unaizah",
      "Al-Rass",
      "Al-Mithnab",
      "Al-Badaye",
      "Al Bukayriyah",
      "Al-Shamli",
      "Al-Nabhaniya",
      "Al-Majma'ah",
      "Dharma",
      "Al-Qassim",
      "Al-Asyah",
      "Al-Zulfi",
    ],
    " Ar Riyad": [
      "Riad",
      "Diriyah",
      "Al-Aflaj",
      "Al-Kharj",
      "Al-Muzahmiyya",
      "Al-Majma'ah",
      "Zulfi",
      "Thadiq",
      "Huraymila",
      "Al-Ghat",
      "Hawtat Bani Tamim",
      "Afif",
      "Al-Quwayiyah",
      "Shaqraa",
      "Rumah",
      "Dawadmi",
      "Ad-Dilam",
      "Al-Hareeq",
      "Al-Sulayyil",
      "Al-Washm",
      "Al-Muzahmiyya",
      "Al-Kharj",
      "Thadiq",
      "Al-Dawadmi",
    ],
    " Ha'il ": [
      "Ha'il",
      "Baqa",
      "Al-Ghazalah",
      "Al-Ghannamiyyah",
      "Al-Shinan",
      "Al-Jawf",
      "Ash-Shinan",
      "Samraa",
      "Al-Makhwah",
      "Al-Hait",
      "Al-Namass",
      "Al-Ula",
    ],
    Jizan: [
      "Abu Arish",
      "Ad-Darb",
      "Al-Ardah",
      "Al-Dhahran Al-Janub",
      "Al-Eidabi",
      "Al-Harithah",
      "Al-Kamil",
      "Al-Mahayil Asir",
      "Al-Rith",
      "Farasan",
      "Jazan",
      "Samtah",
      "Sabya",
      "Sharurah",
      "Baish",
      "Damad",
    ],
    Makkah: [
      "La Meca",
      "Jeddah",
      "Taif",
      "Al-Aziziyah",
      "Al Lith",
      "Al Jumum",
      "Al Kamil",
      "Al Kharrarah",
      "Al Munsiyah",
      "Al Qunfudhah",
      "Ar Ranyah",
      "Ar Rass",
      "At Taif",
      "Badr",
      "Bishah",
      "Duba",
      "Jeddah",
      "Al-Muqarram",
      "Al-Harthyah",
      "Al-Samer",
      "Khulais",
      "Mahd Al-Dhahab",
      "Makkah",
      "Jeddah",
      "Taif",
      "Al-Aziziyah",
      "Al Lith",
      "Namas",
      "Qunfudhah",
      "Ranyah",
      "Raydah",
      "Ad-Darb",
      "Al-Hada",
      "Al-Mikhwah",
      "Al-Qura",
      "Al-Tuwal",
      "Bani Saad",
      "Ghat",
      "Rahimah",
      "Turubah",
    ],
    Najran: [
      "Najran",
      "Habuna",
      "Al-Hasiya",
      "Sharurah",
      "Al Kharkhir",
      "Al-Milwah",
      "Badr Al-Janub",
      "Thar",
      "Yadamah",
      "Habuna",
    ],
    Tabuk: [
      "Tabuk",
      "Haql",
      "Duba",
      "Taima",
      "Umluj",
      "Al-Wajh",
      "Al-Bad",
      "Ash-Shamasiyah",
    ],
  },
  Argentina: {
    "Buenos Aires": ["Buenos Aires"],
    Catamarca: [
      "Ambato",
      "Ancasti",
      "Andalgalá",
      "Antofagasta de la Sierra",
      "Belén",
      "Capayán",
      "Capital",
      "El Alto",
      "Fray Mamerto Esquiú",
      "La Paz",
      "Paclín",
      "Pomán",
      "Santa María",
      "Santa Rosa",
      "Tinogasta",
      "Valle Viejo",
    ],
    "Ciudad Autónoma de Buenos Aires": ["Ciudad Autónoma de Buenos Aires"],
    Chaco: [
      "Almirante Brown",
      "Bermejo",
      "Chacabuco",
      "Comandante Fernández",
      "Doce de Octubre",
      "Dos de Abril",
      "Fray Justo Santa María de Oro",
      "General Belgrano",
      "General Donovan",
      "General Güemes",
      "Independencia",
      "Libertad",
      "Libertador General San Martín",
      "Maipú",
      "Mayor Luis Jorge Fontana",
      "Nueve de Julio",
      "O'Higgins",
      "Presidencia de la Plaza",
      "Primero de Mayo",
      "Quitilipi",
      "San Fernando",
      "San Lorenzo",
      "Sargento Cabral",
      "Tapenagá",
      "Veinticinco de Mayo",
    ],
    Chubut: [
      "Atlántico",
      "Biedma",
      "Cushamen",
      "Escalante",
      "Florentino Ameghino",
      "Futaleufú",
      "Gaiman",
      "Gastre",
      "Languiñeo",
      "Mártires",
      "Paso de Indios",
      "Rawson",
      "Río Senguer",
      "Sarmiento",
      "Tehuelches",
      "Telsen",
    ],
    Córdoba: [
      "Calamuchita",
      "Capital",
      "Colón",
      "Cruz del Eje",
      "General Roca",
      "General San Martín",
      "Ischilín",
      "Juárez Celman",
      "Marcos Juárez",
      "Minas",
      "Pocho",
      "Presidente Roque Sáenz Peña",
      "Punilla",
      "Río Cuarto",
      "Río Primero",
      "Río Seco",
      "Río Segundo",
      "San Alberto",
      "San Javier",
      "San Justo",
      "Santa María",
      "Sobremonte",
      "Tercero Arriba",
      "Totoral",
      "Tulumba",
      "Unión",
    ],
    Corrientes: [
      "Bella Vista",
      "Berón de Astrada",
      "Capital",
      "Concepción",
      "Curuzú Cuatiá",
      "Empedrado",
      "Esquina",
      "General Alvear",
      "General Paz",
      "Goya",
      "Itatí",
      "Ituzaingó",
      "Lavalle",
      "Mburucuyá",
      "Mercedes",
      "Monte Caseros",
      "Paso de los Libres",
      "Saladas",
      "San Cosme",
      "San Luis del Palmar",
      "San Martín",
      "San Miguel",
      "San Roque",
      "Santo Tomé",
      "Sauce",
    ],

    Entre_Ríos: [
      "Colón",
      "Concordia",
      "Diamante",
      "Federación",
      "Federal",
      "Feliciano",
      "Gualeguay",
      "Gualeguaychú",
      "Islas del Ibicuy",
      "La Paz",
      "Nogoyá",
      "Paraná",
      "San Salvador",
      "Tala",
      "Uruguay",
      "Victoria",
      "Villaguay",
    ],
    Formosa: [
      "Bermejo",
      "Formosa",
      "Laishí",
      "Matacos",
      "Patiño",
      "Pilagás",
      "Pilcomayo",
      "Pirané",
      "Ramón Lista",
    ],
    Jujuy: [
      "Cochinoca",
      "Dr. Manuel Belgrano",
      "El Carmen",
      "Humahuaca",
      "Ledesma",
      "Palpalá",
      "Rinconada",
      "San Antonio",
      "San Pedro",
      "Santa Bárbara",
      "Santa Catalina",
      "Susques",
      "Tilcara",
      "Tumbaya",
      "Valle Grande",
      "Yavi",
    ],
    La_Pampa: [
      "Atreucó",
      "Caleu Caleu",
      "Capital",
      "Catriló",
      "Chalileo",
      "Chapaleufú",
      "Chical Co",
      "Conhelo",
      "Curacó",
      "Guatraché",
      "Hucal",
      "Lihuel Calel",
      "Limay Mahuida",
      "Loventué",
      "Maracó",
      "Puelén",
      "Quemú Quemú",
      "Rancul",
      "Realicó",
      "Toay",
      "Trenel",
      "Utracán",
    ],
    "La Rioja": [
      "Arauco",
      "Capital",
      "Castro Barros",
      "Chamical",
      "Chilecito",
      "Coronel Felipe Varela",
      "Famatina",
      "General Ángel V. Peñaloza",
      "General Belgrano",
      "General Juan Facundo Quiroga",
      "General Lamadrid",
      "General Ocampo",
      "General San Martín",
      "Independencia",
      "Rosario Vera Peñaloza",
      "San Blas de los Sauces",
      "Sanagasta",
      "Vinchina",
    ],
    Mendoza: [
      "Capital",
      "General Alvear",
      "Godoy Cruz",
      "Guaymallén",
      "Junín",
      "La Paz",
      "Las Heras",
      "Lavalle",
      "Luján de Cuyo",
      "Maipú",
      "Malargüe",
      "Rivadavia",
      "San Carlos",
      "General San Martín",
      "San Rafael",
      "Santa Rosa",
      "Tunuyán",
      "Tupungato",
    ],
    Misiones: [
      "25 de Mayo",
      "Apóstoles",
      "Cainguás",
      "Candelaria",
      "Capital",
      "Concepción",
      "Eldorado",
      "General Manuel Belgrano",
      "Guaraní",
      "Iguazú",
      "Leandro N. Alem",
      "Libertador General San Martín",
      "Montecarlo",
      "Oberá",
      "San Ignacio",
      "San Javier",
      "San Pedro",
    ],
    Neuquén: [
      "Aluminé",
      "Añelo",
      "Catán Lil",
      "Chos Malal",
      "Collón Curá",
      "Confluencia",
      "Huiliches",
      "Lácar",
      "Loncopué",
      "Los Lagos",
      "Minas",
      "Ñorquín",
      "Pehuenches",
      "Picún Leufú",
      "Picunches",
      "Zapala",
    ],
    "Río Negro": [
      "Adolfo Alsina",
      "Avellaneda",
      "Bariloche",
      "Conesa",
      "El Cuy",
      "General Roca",
      "Nueve de Julio",
      "Ñorquincó",
      "Pichi Mahuida",
      "Pilcaniyeu",
      "San Antonio",
      "Valcheta",
      "Veinticinco de Mayo",
    ],
    Salta: [
      "Anta",
      "Cachi",
      "Cafayate",
      "Cerrillos",
      "Chicoana",
      "General Güemes",
      "General José de San Martín",
      "Guachipas",
      "Iruya",
      "La Caldera",
      "La Candelaria",
      "la Capital",
      "La Poma",
      "La Viña",
      "Los Andes",
      "Metán",
      "Molinos",
      "Orán",
      "Rivadavia",
      "Rosario de la Frontera",
      "Rosario de Lerma",
      "San Carlos",
      "Santa Victoria",
    ],
    "San Juan": [
      "25 de mayo",
      "9 de julio",
      "Albardón",
      "Angaco",
      "Calingasta",
      "Capital",
      "Caucete",
      "Chimbas",
      "Iglesia",
      "Jáchal",
      "Pocito",
      "Rawson",
      "Rivadavia",
      "San Martín",
      "Santa Lucía",
      "Sarmiento",
      "Ullum",
      "Valle Fértil",
      "Zonda",
    ],
    "San Luis": [
      "Ayacucho",
      "Belgrano",
      "Chacabuco",
      "Coronel Pringles",
      "General Pedernera",
      "Gobernador Dupuy",
      "Juan Martín de",
      "Pueyrredón",
      "Junín",
      "Libertador General",
      "San Martín",
      "San Luis",
    ],
    "Santa Cruz": [
      "Corpen Aike",
      "Deseado",
      "Güer Aike",
      "Lago Argentino",
      "Lago Buenos Aires",
      "Magallanes",
      "Río Chico",
    ],
    "Santa Fe": [
      "9 de Julio",
      "Belgrano",
      "Caseros",
      "Castellanos",
      "Constitución",
      "Garay",
      "General López",
      "General Obligado",
      "Iriondo",
      "La Capital",
      "Las Colonias",
      "Rosario",
      "San Cristóbal",
      "San Javier",
      "San Jerónimo",
      "San Justo",
      "San Lorenzo",
      "San Martín",
      "Vera",
    ],
    "Santiago del Estero": [
      "Aguirre",
      "Alberdi",
      "Atamisqui",
      "Avellaneda",
      "Banda",
      "Belgrano",
      "Choya",
      "Copo",
      "Figueroa",
      "General Taboada",
      "Guasayán",
      "Jiménez",
      "Juan Felipe Ibarra",
      "Juan Francisco Borges",
      "Loreto",
      "Mitre",
      "Moreno",
      "Ojo de Agua",
      "Pellegrini",
      "Quebrachos",
      "Río Hondo",
      "Rivadavia",
      "Robles",
      "Salavina",
      "San Martín",
      "Sarmiento",
      "Silípica",
    ],
    "Tierra del Fuego, Antártida e Islas del Atlántico Sur": [
      "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
    ],
    Tucumán: [
      "Burruyacú",
      "Capital",
      "Chicligasta",
      "Cruz Alta",
      "Famaillá",
      "Graneros",
      "Juan Bautista Alberdi",
      "La Cocha",
      "Leales",
      "Lules",
      "Monteros",
      "Río Chico",
      "Simoca",
      "Tafí del Valle",
      "Tafí Viejo",
      "Trancas",
      "Yerba Buena",
    ],
  },
  Argelia: {
    Adrar: [
      "Adrar ",
      "Akabli ",
      "Aougrout ",
      "Aoulef",
      "Bordj Badji Mokhtar ",
      "Bouda ",
      "Charouine ",
      "Deldoul",
      "Fenoughil",
      "In Zghmir ",
      "Ksar Kaddour ",
      "Metarfa ",
      "Ouled Ahmed Tammi ",
      "Ouled Aïssa ",
      "Ouled Saïd ",
      "Reggane ",
      "Sali ",
      "Sebaa ",
      "Talmine ",
      "Tamantit ",
      "Tamekten ",
      "Tamest ",
      "Timiaouine ",
      "Timimoun ",
      "Tinerkouk",
      "Tit ",
      "Tsabit ",
      "Zaouiet Kounta",
    ],
    Chlef: [
      "Abou El Hassan ",
      "Aïn Merane",
      "Bénairia ",
      "Beni Bouateb",
      "Beni Haoua ",
      "Beni Rached",
      "Boukadir ",
      "Bouzeghaia",
      "Breira",
      "Chettia ",
      "Chlef ",
      "Dahra ",
      "El Hadjadj ",
      "El Karimia ",
      "El Marsa ",
      "Harchoun",
      "Harenfa ",
      "Labiod Medjadja ",
      "Moussadek ",
      "Oued Fodda ",
      "Oued Goussine",
      "Oued Sly ",
      "Ouled Abbes ",
      "Ouled Ben Abdelkader ",
      "Ouled Fares ",
      "Oum Drou ",
      "Sendjas ",
      "Sidi Abderrahmane ",
      "Sidi Akkacha ",
      "Sobha ",
      "Tadjena ",
      "Talassa ",
      "Taougrite ",
      "Ténès ",
      "Zeboudja ",
    ],
    Laghouat: [
      "Aflou ",
      "Aïn Madhi ",
      "Aïn Sidi Ali ",
      "Beidha ",
      "Bennasser Benchohra",
      "Brida ",
      "El Assafia ",
      "El Ghicha",
      "El Houaita",
      "Gueltat Sidi Saad ",
      "Hadj Mechri",
      "Hassi Delaa ",
      "Hassi R'Mel ",
      "Kheneg ",
      "Ksar El Hirane ",
      "Laghouat ",
      "Oued Morra ",
      "Oued M'Zi ",
      "Sebgag ",
      "Sidi Bouzid ",
      "Sidi Makhlouf ",
      "Tadjemout ",
      "Tadjrouna ",
      "Taouila ",
    ],
    "Oum / el-Bouaghi": [
      "Aïn Babouche ",
      "Aïn Béïda ",
      "Aïn Diss",
      "Aïn Fakroun ",
      "Aïn Kercha ",
      "Aïn M'lila ",
      "Aïn Zitoun ",
      "Behir Chergui",
      "Berriche ",
      "Bir Chouhada",
      "Dhalaa",
      "El Amiria",
      "El Belala ",
      "El Djazia ",
      "El Fedjouz Boughrara Saoudi ",
      "El Harmilia ",
      "Fkirina ",
      "Hanchir Toumghan",
      "Ksar Sbahi",
      "Meskiana ",
      "Oued Nini ",
      "Ouled Gacem ",
      "Ouled Hamla ",
      "Ouled Zouaï ",
      "Oum El Bouaghi ",
      "Rahia ",
      "Sigus ",
      "Souk Naamane",
      "Zorg",
    ],
    Batna: [
      "Abdelkader Azil",
      "Aïn Djasser",
      "Aïn Touta",
      "Aïn Yagout",
      "Arris",
      "Barika",
      "Batna",
      "Ben Foudala El Hakania",
      "Bitam",
      "Boulhilat",
      "Boumaguer",
      "Boumia",
      "Bouzina",
      "Chemora",
      "Chir",
      "Djerma",
      "Djezar",
      "El Hassi",
      "El Madher",
      "Fesdis",
      "Foum Toub",
      "Ghassira",
      "Gosbat",
      "Guigba",
      "Hidoussa",
      "Ichmoul",
      "Inoughissen",
      "Kimmel",
      "Ksar Bellezma",
      "Lazrou",
      "Lemsane",
      "Maafa",
      "M'doukel",
      "Menaa",
      "Merouana",
      "N'Gaous",
      "Oued Chaaba",
      "Oued El Ma",
      "Oued Taga",
      "Ouled Ammar",
      "Ouled Aouf",
      "Ouled Fadel",
      "Ouled Sellam",
      "Ouled Si Slimane",
      "Ouyoun El Assafir",
      "Rahbat",
      "Ras El Aioun",
      "Sefiane",
      "Seggana",
      "Seriana",
      "Talkhamt",
      "Taxlent",
      "Tazoult",
      "Teniet El Abed",
      "Tighanimine",
      "Tigherghar",
      "Tilatou",
      "Timgad",
      "T'Kout",
      "Zanet El Beida",
    ],
    Bugía: [
      "Adekar",
      "Aït R'zine",
      "Aït-Smail",
      "Akbou",
      "Akfadou",
      "Amalou",
      "Amizour",
      "Aokas",
      "Barbacha",
      "Bugía",
      "Beni Djellil",
      "Beni Ksila",
      "Beni Maouche",
      "Beni Mellikeche",
      "Boudjellil",
      "Bouhamza",
      "Boukhelifa",
      "Chellata",
      "Chemini",
      "Darguina",
      "Draâ El-Kaïd",
      "El Kseur",
      "Fenaïa Ilmaten",
      "Ferraoun",
      "Ighil Ali",
      "Ighram",
      "Kendira",
      "Kherrata",
      "Leflaye",
      "M'cisna",
      "Melbou",
      "Oued Ghir",
      "Ouzellaguen",
      "Seddouk",
      "Semaoun",
      "Sidi Aïch",
      "Sidi Ayad",
      "Souk El Ténine",
      "Souk Oufella",
      "Tala Hamza",
      "Tamokra",
      "Tamridjet",
      "Taourirt Ighil",
      "Taskriout",
      "Tazmalt",
      "Tibane",
      "Tichy",
      "Tifra",
      "Timezrit",
      "Tinabdher",
      "Tizi N'Berber",
      "Toudja",
    ],
    Biskra: [
      "Aïn Naga",
      "Ain Zaatout",
      "Besbes",
      "Biskra",
      "Bordj Ben Azzouz",
      "Bouchagroune",
      "Branis",
      "Chetma",
      "Djemorah",
      "Doucen",
      "Ech Chaïba",
      "El Feidh",
      "El Ghrous",
      "El Hadjeb",
      "El Haouch",
      "El Kantara",
      "El Mizaraa",
      "El Outaya",
      "Foughala",
      "Khenguet Sidi Nadji",
      "Lichana",
      "Lioua",
      "M'Chouneche",
      "Mekhadma",
      "M'Lili",
      "Ouled Djellal",
      "Oumache",
      "Ourlal",
      "Ras El Miaad",
      "Sidi Khaled",
      "Sidi Okba",
      "Tolga",
      "Zeribet El Oued",
    ],
    Béchar: [
      "Abadla",
      "Béchar",
      "Béni Abbès",
      "Beni Ikhlef",
      "Beni Ounif",
      "Boukaïs",
      "El Ouata",
      "Erg Ferradj",
      "Igli",
      "Kenadsa",
      "Kerzaz",
      "Ksabi",
      "Lahmar",
      "Mechraa Houari Boumedienne",
      "Meridja",
      "Mougheul",
      "Ouled Khoudir",
      "Tabelbala",
      "Taghit",
      "Tamtert",
      "Timoudi",
    ],
    Blida: [
      "Aïn Romana",
      "Beni Mered",
      "Beni Tamou",
      "Benkhelil",
      "Blida",
      "Bouarfa",
      "Boufarik",
      "Bougara",
      "Bouinan",
      "Chebli",
      "Chiffa",
      "Chréa",
      "Djebabra",
      "El Affroun",
      "Guerouaou",
      "Hammam Melouane",
      "Larbaâ",
      "Meftah",
      "Mouzaia",
      "Oued Alleug",
      "Oued Djer",
      "Ouled Slama",
      "Ouled Yaïch",
      "Souhane",
      "Soumaa",
    ],
    Bouira: [
      "Aghbalou",
      "Ahl El Ksar",
      "Aïn Bessem",
      "Aïn El Hadjar",
      "Aïn El Turc",
      "Aïn Laloui",
      "Aït Laziz",
      "Aomar",
      "Ath Mansour",
      "Bechloul",
      "Bir Ghbalou",
      "Bordj Okhriss",
      "Bouderbala",
      "Bouira",
      "Boukram",
      "Chorfa",
      "Dechmia",
      "Dirrah",
      "Djebahia",
      "El Adjiba",
      "El Asnam",
      "El Hachimia",
      "El Hakimia",
      "El Khabouzia",
      "El Mokrani",
      "Guerrouma",
      "Hadjera Zerga",
      "Haizer",
      "Hanif",
      "Kadiria",
      "Lakhdaria",
      "Maala",
      "Maamora",
      "M'Chedallah",
      "Mezdour",
      "Oued El Berdi",
      "Ouled Rached",
      "Raouraoua",
      "Ridane",
      "Saharidj",
      "Souk El Khemis",
      "Sour El Ghozlane",
      "Taghzout",
      "Taguedit",
      "Zbarbar",
    ],
    Tamanrasset: [
      "Abalessa",
      "Foggaret Ezzaouia",
      "Idles",
      "In Amguel",
      "In Ghar",
      "In Guezzam",
      "In Salah",
      "Tamanrasset",
      "Tazrouk",
      "Tinzaouten",
    ],
    Tébessa: [
      "Aïn Zerga",
      "Bedjene",
      "Bekkaria",
      "Bir Dheb",
      "Bir el-Ater",
      "Bir Mokkadem",
      "Boukhadra",
      "Boulhaf Dir",
      "Cheria",
      "El Aouinet",
      "El Houidjbet",
      "El Kouif",
      "El Ma Labiodh",
      "El Meridj",
      "El Mezeraa",
      "El Ogla",
      "Ferkane",
      "Guorriguer",
      "Hammamet",
      "Morsott",
      "Negrine",
      "Ogla Melha",
      "Ouenza",
      "Oum Ali",
      "Safsaf El Ouesra",
      "Stah Guentis",
      "Tébessa",
      "Tlidjene",
    ],
    Tremecén: [
      "Aïn Fetah",
      "Aïn Fezza",
      "Aïn Ghoraba",
      "Aïn Kebira",
      "Aïn Nehala",
      "Aïn Tallout",
      "Aïn Youcef",
      "Amieur",
      "Azaïls",
      "Bab El Assa",
      "Beni Bahdel",
      "Beni Boussaïd",
      "Beni Khellad",
      "Beni Mester",
      "Beni Ouarsous",
      "Beni Semiel",
      "Beni Snous",
      "Bensekrane",
      "Bouhlou",
      "Chetouane",
      "Dar Yaghmouracene",
      "Djebala",
      "El Aricha",
      "El Bouihi",
      "El Fehoul",
      "El Gor",
      "Fellaoucene",
      "Ghazaouet",
      "Hammam Boughrara",
      "Hennaya",
      "Honaïne",
      "Maghnia",
      "Mansourah",
      "Marsa Ben M'Hidi",
      "Msirda Fouaga",
      "Nedroma",
      "Oued Lakhdar",
      "Ouled Mimoun",
      "Ouled Riyah",
      "Remchi",
      "Sabra",
      "Sebaa Chioukh",
      "Sebdou",
      "Sidi Abdelli",
      "Sidi Djillali",
      "Sidi Medjahed",
      "Souahlia",
      "Souani",
      "Souk Tlata",
      "Terny Beni Hdiel",
      "Tienet",
      "Tlemcen",
      "Zenata",
    ],
    Tiaret: [
      "Aïn Bouchekif",
      "Aïn Deheb",
      "Aïn El Hadid",
      "Aïn Kermes",
      "Aïn Zarit",
      "Bougara",
      "Chehaima",
      "Dahmouni",
      "Djebilet Rosfa",
      "Djillali Ben Amar",
      "Faidja",
      "Frenda",
      "Guertoufa",
      "Hamadia",
      "Ksar Chellala",
      "Madna",
      "Mahdia",
      "Mechraa Safa",
      "Medrissa",
      "Medroussa",
      "Meghila",
      "Mellakou",
      "Nadorah",
      "Naima",
      "Oued Lilli",
      "Rahouia",
      "Rechaiga",
      "Sebaïne",
      "Sebt",
      "Serghine",
      "Si Abdelghani",
      "Sidi Abderrahmane",
      "Sidi Ali Mellal",
      "Sidi Bakhti",
      "Sidi Hosni",
      "Sougueur",
      "Tagdemt",
      "Takhemaret",
      "Tiaret",
      "Tidda",
      "Tousnina",
      "Zmalet El Emir Abdelkader",
    ],
    "Tizi Uzu": [
      "Abi Youcef",
      "Aghribs",
      "Agouni Gueghrane",
      "Aïn El Hammam",
      "Aïn Zaouia",
      "Aït Aouggacha",
      "Aït Bouaddou",
      "Aït Boumahdi",
      "Aït Chafâa",
      "Aït Khellili",
      "Aït Mahmoud",
      "Aït Oumalou",
      "Aït Toudert",
      "Aït Yahia",
      "Aït Yahia Moussa",
      "Akbil",
      "Akerrou",
      "Assi Youcef",
      "Azazga",
      "Azeffoun",
      "Beni Aïssi",
      "Beni Douala",
      "Beni Yenni",
      "Beni Ziki",
      "Beni Zmenzer",
      "Boghni",
      "Boudjima",
      "Bounouh",
      "Bouzguen",
      "Djebel Aissa Mimoun",
      "Draâ Ben Khedda",
      "Draâ El Mizan",
      "Freha",
      "Frikat",
      "Iboudraren",
      "Idjeur",
      "Iferhounène",
      "Ifigha",
      "Iflissen",
      "Illilten",
      "Illoula Oumalou",
      "Imsouhel",
      "Irdjen",
      "Larbaâ Nath Irathen",
      "Maâtkas",
      "Makouda",
      "Mechtras",
      "Mekla",
      "Mezrara",
      "M'Kira",
      "Ouacif",
      "Ouadhia",
      "Ouaguenoun",
      "Sidi Nâamane",
      "Souamaâ",
      "Souk El Thenine",
      "Tadmaït",
      "Tigzirt",
      "Timizart",
      "Tirmitine",
      "Tizi Gheniff",
      "Tizi N'Tleta",
      "Tizi Ouzou",
      "Tizi Rached",
      "Yakouren",
      "Yatafen",
      "Zekri",
    ],
    Argel: [
      "Aïn Benian",
      "Aïn Taya",
      "Baba Hassen",
      "Bab El Oued",
      "Bab Ezzouar",
      "Bachdjerrah",
      "Baraki",
      "Ben Aknoun",
      "Beni Messous",
      "Birkhadem",
      "Bir Mourad Raïs",
      "Birtouta",
      "Bologhine",
      "Bordj El Bahri",
      "Bordj El Kiffan",
      "Bourouba",
      "Bouzereah",
      "Casbah",
      "Chéraga",
      "Dar El Beïda",
      "Dely Ibrahim",
      "Djasr Kasentina",
      "Douera",
      "Draria",
      "El Achour",
      "El Biar",
      "El Djazaïr",
      "El Hammamet",
      "El Harrach",
      "El Madania",
      "El Magharia",
      "El Marsa",
      "El Mouradia",
      "Harraoua",
      "Hussein Dey",
      "Hydra",
      "Khraicia",
      "Kouba",
      "Les Eucalyptus",
      "Mahelma",
      "Mohamed Belouizdad",
      "Mohammadia",
      "Oued Koriche",
      "Oued Smar",
      "Ouled Chebel",
      "Ouled Fayet",
      "Rahmania",
      "Raïs Hamidou",
      "Reghaïa",
      "Rouïba",
      "Saoula",
      "Sidi M'Hamed",
      "Sidi Moussa",
      "Souidania",
      "Staoueli",
      "Tessala El Merdja",
      "Zéralda",
    ],
    Djelfa: [
      "Aïn Chouhada",
      "Aïn El Ibel",
      "Aïn Feka",
      "Aïn Maabed",
      "Aïn Oussara",
      "Amourah",
      "Benhar",
      "Beni Yagoub",
      "Birine",
      "Bouira Lahdab",
      "Charef",
      "Dar Chioukh",
      "Deldoul",
      "Djelfa",
      "Douis",
      "El Guedid",
      "El Idrissia",
      "El Khemis",
      "Faidh El Botma",
      "Guernini",
      "Guettara",
      "Had-Sahary",
      "Hassi Bahbah",
      "Hassi El Euch",
      "Hassi Fedoul",
      "Messaad",
      "M'Liliha",
      "Moudjebara",
      "Oum Laadham",
      "Sed Rahal",
      "Selmana",
      "Sidi Baizid",
      "Sidi Ladjel",
      "Tadmit",
      "Zaafrane",
      "Zaccar",
    ],
    Jijel: [
      "Bordj Tahar",
      "Boucif Ouled Askeur",
      "Boudriaa Ben Yadjis",
      "Bouraoui Belhadef",
      "Chahna",
      "Chekfa",
      "Djemaa Beni Habibi",
      "Djimla",
      "El Ancer",
      "El Aouana",
      "El Kennar Nouchfi",
      "El Milia",
      "Emir Abdelkader",
      "Eraguene",
      "Ghebala",
      "Jijel",
      "Kaous",
      "Kheïri Oued Adjoul",
      "Ouadjana",
      "Ouled Rabah",
      "Ouled Yahia Khedrouche",
      "Selma Benziada",
      "Settara",
      "Sidi Abdelaziz",
      "Sidi Maarouf",
      "Taher",
      "Texenna",
      "Ziama Mansouriah",
    ],
    Sétif: [
      "Aïn Abessa",
      "Aïn Arnat",
      "Aïn Azel",
      "Aïn El Kebira",
      "Aïn Lahdjar",
      "Aïn Legraj",
      "Aïn Oulmene",
      "Aïn Roua",
      "Aïn Sebt",
      "Aït Naoual Mezada",
      "Aït Tizi",
      "Amoucha",
      "Babor",
      "Bazer Sakhra",
      "Beidha Bordj",
      "Belaa",
      "Beni Aziz",
      "Beni Chebana",
      "Beni Fouda",
      "Beni Hocine",
      "Beni Mouhli",
      "Beni Ourtilane",
      "Bir El Arch",
      "Bir Haddada",
      "Bouandas",
      "Bougaa",
      "Bousselam",
      "Boutaleb",
      "Dehamcha",
      "Djemila",
      "Draa Kebila",
      "El Eulma",
      "El Ouldja",
      "El Ouricia",
      "Guellal",
      "Guelta Zerka",
      "Guenzet",
      "Guidjel",
      "Hamma",
      "Hammam Guergour",
      "Hammam Soukhna",
      "Harbil",
      "Ksar El Abtal",
      "Maaouia",
      "Maoklane",
      "Mezloug",
      "Oued El Barad",
      "Ouled Addouane",
      "Ouled Sabor",
      "Ouled Si Ahmed",
      "Ouled Tebben",
      "Rasfa",
      "Salah Bey",
      "Serdj El Ghoul",
      "Sétif",
      "Tachouda",
      "Talaifacene",
      "Taya",
      "Tella",
      "Tizi N'Bechar",
    ],
    Saida: [
      "Aïn El Hadjar",
      "Aïn Sekhouna",
      "Aïn Soltane",
      "Doui Thabet",
      "El Hassasna",
      "Hounet",
      "Maamora",
      "Moulay Larbi",
      "Ouled Brahim",
      "Ouled Khaled",
      "Saïda",
      "Sidi Ahmed",
      "Sidi Amar",
      "Sidi Boubekeur",
      "Tircine",
      "Youb",
    ],
    Skikda: [
      "Aïn Bouziane",
      "Aïn Charchar",
      "Aïn Kechra",
      "Aïn Zouit",
      "Azzaba",
      "Bekkouche Lakhdar",
      "Ben Azzouz",
      "Beni Bechir",
      "Beni Oulbane",
      "Beni Zid",
      "Bin El Ouiden",
      "Bouchtata",
      "Cheraia",
      "Collo",
      "Djendel Saadi Mohamed",
      "El Ghedir",
      "El Hadaiek",
      "El Harrouch",
      "El Marsa",
      "Emdjez Edchich",
      "Es Sebt",
      "Filfila",
      "Hamadi Krouma",
      "Kanoua",
      "Kerkera",
      "Kheneg Mayoum",
      "Oued Zehour",
      "Ouldja Boulballout",
      "Ouled Attia",
      "Ouled Hbaba",
      "Oum Toub",
      "Ramdane Djamel",
      "Salah Bouchaour",
      "Sidi Mezghiche",
      "Skikda",
      "Tamalous",
      "Zerdaza",
      "Zitouna",
    ],
    "Sidi / Bel / Abbes": [
      "Aïn Adden",
      "Aïn El Berd",
      "Aïn Kada",
      "Aïn Thrid",
      "Aïn Tindamine",
      "Amarnas",
      "Badredine El Mokrani",
      "Belarbi",
      "Chelia",
      "Ben Badis",
      "Bir El Hammam",
      "Boudjebaa El Bordj",
      "Boukhanafis",
      "Chettouane Belaila",
      "Dhaya",
      "El Haçaiba",
      "Hassi Dahou",
      "Hassi Zahana",
      "Lamtar",
      "Makedra",
      "Marhoum",
      "M'Cid",
      "Merine",
      "Mezaourou",
      "Mostefa Ben Brahim",
      "Moulay Slissen",
      "Oued Sebaa",
      "Oued Sefioun",
      "Oued Taourira",
      "Ras El Ma",
      "Redjem Demouche",
      "Sehala Thaoura",
      "Sfisef",
      "Sidi Ali Benyoub",
      "Sidi Ali Boussidi",
      "Sidi Bel Abbès",
      "Sidi Brahim",
      "Sidi Chaib",
      "Sidi Daho des Zairs",
      "Sidi Hamadouche",
      "Sidi Khaled",
      "Sidi Lahcene",
      "Sidi Yacoub",
      "Tabia",
      "Tafissour",
      "Taoudmout",
      "Teghalimet",
      "Telagh",
      "Tenira",
      "Tessala",
      "Tilmouni",
      "Zerouala",
    ],
    Annaba: [
      "Aïn Berda",
      "Annaba",
      "Barrahal",
      "Chetaïbi",
      "Cheurfa",
      "El Bouni",
      "El Eulma",
      "El Hadjar",
      "Oued El Aneb",
      "Seraïdi",
      "Sidi Amar",
      "Treat",
    ],
    Guelma: [
      "Aïn Ben Beida",
      "Aïn Larbi",
      "Aïn Makhlouf",
      "Aïn Reggada",
      "Aïn Sandel",
      "Belkheir",
      "Ben Djerrah",
      "Beni Mezline",
      "Bordj Sabat",
      "Bouati Mahmoud",
      "Bouchegouf",
      "Bouhachana",
      "Bouhamdane",
      "Boumahra Ahmed",
      "Dahouara",
      "Djeballah Khemissi",
      "El Fedjoudj",
      "Guelaat Bou Sbaa",
      "Guelma",
      "Hammam Debagh",
      "Hammam N'Bail",
      "Heliópolis",
      "Houari Boumédiène",
      "Khezarra",
      "Medjez Amar",
      "Medjez Sfa",
      "Nechmaya",
      "Oued Cheham",
      "Oued Fragha",
      "Oued Zenati",
      "Ras El Agba",
      "Roknia",
      "Sellaoua Announa",
      "Tamlouka",
    ],
    Constantina: [
      "Aïn Abid",
      "Aïn Smara",
      "Beni Hamiden",
      "Constantina",
      "Didouche Mourad",
      "El Khroub",
      "Hamma Bouziane",
      "Ibn Badis",
      "Ibn Ziad",
      "Messaoud Boudjriou",
      "Ouled Rahmoune",
      "Zighoud Youcef",
    ],
    Médéa: [
      "Aïn Boucif",
      "Aïn Ouksir",
      "Aissaouia",
      "Aziz",
      "Baata",
      "Benchicao",
      "Beni Slimane",
      "Berrouaghia",
      "Bir Ben Laabed",
      "Boghar",
      "Bou Aiche",
      "Bouaichoune",
      "Bouchrahil",
      "Boughezoul",
      "Bouskene",
      "Chahbounia",
      "Chellalat El Adhaoura",
      "Cheniguel",
      "Derrag",
      "Deux Bassins",
      "Djouab",
      "Draa Essamar",
      "El Azizia",
      "El Guelb El Kebir",
      "El Hamdania",
      "El Omaria",
      "El Ouinet",
      "Hannacha",
      "Kef Lakhdar",
      "Khams Djouamaa",
      "Ksar El Boukhari",
      "Maghraoua",
      "Médéa",
      "Meftaha",
      "Mezerana",
      "Mihoub",
      "Moudjbar",
      "Ouamri",
      "Oued Harbil",
      "Ouled Antar",
      "Ouled Bouachra",
      "Ouled Brahim",
      "Ouled Deide",
      "Ouled Hellal",
      "Ouled Maaref",
      "Oum El Djalil",
      "Ouzera",
      "Rebaia",
      "Saneg",
      "Sedraia",
      "Seghouane",
      "Sidi Damed",
      "Sidi Errabia",
      "Sidi Naamane",
      "Sidi Zahar",
      "Sidi Ziane",
      "Si Mahdjoub",
      "Souagui",
      "Tablat",
      "Tafraout",
      "Tamesguida",
      "Tizi Mahdi",
      "Tlatet Eddouar",
      "Zoubiria",
    ],
    Mostaganem: [
      "Abdelmalek Ramdane",
      "Achaacha",
      "Aïn Boudinar",
      "Aïn Nouïssy",
      "Aïn Sidi Cherif",
      "Aïn Tedles",
      "Bouguirat",
      "El Hassaine",
      "Fornaka",
      "Hadjadj",
      "Hassi Mameche",
      "Khadra",
      "Kheireddine",
      "Mansourah",
      "Mazagran",
      "Mesra",
      "Mostaganem",
      "Nekmaria",
      "Oued El Kheir",
      "Ouled Boughalem",
      "Ouled Maallah",
      "Safsaf",
      "Sayada",
      "Sidi Ali",
      "Sidi Belattar",
      "Sidi Lakhdar",
      "Sirat",
      "Souaflia",
      "Sour",
      "Stidia",
      "Tazgait",
      "Touahria",
    ],
    "M'Sila": [
      "Aïn El Hadjel",
      "Aïn El Melh",
      "Aïn Errich",
      "Aïn Fares",
      "Aïn Khadra",
      "Belaiba",
      "Beni Ilmane",
      "Ben Srour",
      "Benzouh",
      "Berhoum",
      "Bir Foda",
      "Bou Saâda",
      "Bouti Sayah",
      "Chellal",
      "Dehahna",
      "Djebel Messaad",
      "El Hamel",
      "El Houamed",
      "Hammam Dhalaa",
      "Khettouti Sed El Djir",
      "Khoubana",
      "Maadid",
      "Maarif",
      "Magra",
      "M'Cif",
      "Medjedel",
      "Mohammed Boudiaf",
      "M'Sila",
      "M'Tarfa",
      "Ouanougha",
      "Ouled Addi Guebala",
      "Ouled Atia",
      "Ouled Derradj",
      "Ouled Madhi",
      "Ouled Mansour",
      "Ouled Sidi Brahim",
      "Ouled Slimane",
      "Oultem",
      "Sidi Aïssa",
      "Sidi Ameur",
      "Sidi Hadjeres",
      "Sidi M'hamed",
      "Slim",
      "Souamaa",
      "Tamsa",
      "Tarmount",
      "Zarzour",
    ],
    Muaskar: [
      "Aïn Fekan",
      "Aïn Ferah",
      "Aïn Fras",
      "Aïn Frass",
      "Alaïmia",
      "Aouf",
      "Beniane",
      "Bou Hanifia",
      "Bou Henni",
      "Chorfa",
      "El Bordj",
      "El Gaada",
      "El Ghomri",
      "El Guettana",
      "El Keurt",
      "El Menaouer",
      "Ferraguig",
      "Froha",
      "Gharrous",
      "Ghriss",
      "Guerdjoum",
      "Hachem",
      "Hacine",
      "Khalouia",
      "Makdha",
      "Mamounia",
      "Maoussa",
      "Mascara",
      "Matemore",
      "Mocta Douz",
      "Mohammadia",
      "Nesmoth",
      "Oggaz",
      "Oued El Abtal",
      "Oued Taria",
      "Ras El Aïn Amirouche",
      "Sedjerara",
      "Sehailia",
      "Sidi Abdeldjebar",
      "Sidi Abdelmoumen",
      "Sidi Boussaid",
      "Sidi Kada",
      "Sig",
      "Tighennif",
      "Tizi",
      "Zahana",
      "Zelmata",
    ],
    Uargla: [
      "Aïn Beida",
      "Benaceur",
      "Blidet Amor",
      "El Allia",
      "El Borma",
      "El Hadjira",
      "Hassi Ben Abdellah",
      "Hassi Messaoud",
      "Megarine",
      "M'Naguer",
      "Nezla",
      "N'Goussa",
      "Ouargla",
      "Rouissat",
      "Sidi Khouiled",
      "Sidi Slimane",
      "Taibet",
      "Tamacine",
      "Tebesbest",
      "Touggourt",
      "Zaouia El Abidia",
    ],
    Orán: [
      "Aïn El Bia",
      "Aïn El Kerma",
      "Aïn El Turk",
      "Arzew",
      "Ben Freha",
      "Bethioua",
      "Bir El Djir",
      "Boufatis",
      "Bousfer",
      "Boutlelis",
      "El Ançor",
      "El Braya",
      "El Kerma",
      "Es Sénia",
      "Gdyel",
      "Hassi Ben Okba",
      "Hassi Bounif",
      "Hassi Mefsoukh",
      "Marsat El Hadjadj",
      "Mers El Kébir",
      "Misserghin",
      "Orán",
      "Oued Tlelat",
      "Sidi Benyebka",
      "Sidi Chami",
      "Tafraoui",
    ],
    "El Bayadh": [
      "Aïn El Orak",
      "Arbaouat",
      "Boualem",
      "Bougtoub",
      "Boussemghoun",
      "Brezina",
      "Cheguig",
      "Chellala",
      "El Abiodh Sidi Cheikh",
      "El Bayadh",
      "El Bnoud",
      "El Kheiter",
      "El Mehara",
      "Ghassoul",
      "Kef El Ahmar",
      "Kraakda",
      "Rogassa",
      "Sidi Ameur",
      "Sidi Slimane",
      "Sidi Tifour",
      "Stitten",
      "Tousmouline",
    ],
    Illizi: [
      "Bordj El Haouas",
      "Bordj Omar Driss",
      "Debdeb",
      "Djanet",
      "Illizi",
      "In Amenas",
    ],
    "Bordj / Bou / Arréridj": [
      "Aïn Taghrout",
      "Aïn Tesra",
      "Belimour",
      "Ben Daoud",
      "Bir Kasdali",
      "Bordj Bou Arreridj",
      "Bordj Ghedir",
      "Bordj Zemoura",
      "Colla",
      "Djaafra",
      "El Ach",
      "El Achir",
      "El Anceur",
      "El Hamadia",
      "El Main",
      "El M'hir",
      "Ghilassa",
      "Haraza",
      "Hasnaoua",
      "Khelil",
      "Ksour",
      "Mansoura",
      "Medjana",
      "Ouled Brahim",
      "Ouled Dahmane",
      "Ouled Sidi Brahim",
      "Rabta",
      "Ras El Oued",
      "Sidi Embarek",
      "Taglait",
      "Tassameurt",
      "Tefreg",
      "Teniet En Nasr",
      "Tixter",
    ],
    Bumerdés: [
      "Afir",
      "Ammal",
      "Baghlia",
      "Ben Choud",
      "Beni Amrane",
      "Bordj Menaïel",
      "Boudouaou",
      "Boudouaou El Bahri",
      "Bumerdés",
      "Bouzegza Keddara",
      "Chabet el Ameur",
      "Corso",
      "Dellys",
      "Djinet",
      "El Kharrouba",
      "Hammedi",
      "Issers",
      "Khemis El Khechna",
      "Larbatache",
      "Leghata",
      "Naciria",
      "Ouled Aïssa",
      "Ouled Hedadj",
      "Ouled Moussa",
      "Sidi Daoud",
      "Si Mustapha",
      "Souk El Had",
      "Taourga",
      "Thenia",
      "Tidjelabine",
      "Timezrit",
      "Zemmouri",
    ],
    "El Tarf": [
      "Aïn El Assel",
      "Aïn Kerma",
      "Asfour",
      "Ben Mehidi",
      "Berrihane",
      "Besbes",
      "Bougous",
      "Bouhadjar",
      "Bouteldja",
      "Chebaita Mokhtar",
      "Chefia",
      "Chihani",
      "Dréan",
      "Echatt",
      "El Aïoun",
      "El Kala",
      "El Tarf",
      "Hammam Beni Salah",
      "Lac des Oiseaux",
      "Oued Zitoun",
      "Raml Souk",
      "Souarekh",
      "Zerizer",
      "Zitouna",
    ],
    Tinduf: ["Oum El Assel", "Tinduf "],
    Tissemsilt: [
      "Ammari",
      "Beni Chaib",
      "Beni Lahcene",
      "Bordj Bou Naama",
      "Bordj El Emir Abdelkader",
      "Boucaid",
      "Khemisti",
      "Larbaâ",
      "Lardjem",
      "Layoune",
      "Lazharia",
      "Maacem",
      "Melaab",
      "Ouled Bessem",
      "Sidi Abed",
      "Sidi Boutouchent",
      "Sidi Lantri",
      "Sidi Slimane",
      "Tamalaht",
      "Theniet El Had",
      "Tissemsilt",
      "Youssoufia",
    ],
    "El Oued": [
      "Bayadha",
      "Beni Guecha",
      "Debila",
      "Djamaa",
      "Douar El Ma",
      "Kohmaetemmee",
      "El Ogla",
      "El Oued",
      "Guemar",
      "Hamraia",
      "Hassani Abdelkrim",
      "Hassi Khalifa",
      "Kouinine",
      "Magrane",
      "Mih Ouansa",
      "M'Rara",
      "Nakhla",
      "Oued El Alenda",
      "Oum Touyour",
      "Ourmas",
      "Reguiba",
      "Robbah",
      "Sidi Amrane",
      "Sidi Aoun",
      "Sidi Khellil",
      "Still",
      "Taghzout",
      "Taleb Larbi",
      "Tendla",
      "Trifaoui",
    ],
    Jenchela: [
      "Aïn Touila",
      "Babar",
      "Baghaï",
      "Bouhmama",
      "Chechar",
      "Chélia",
      "Djellal",
      "El Hamma",
      "El Mahmal",
      "El Oueldja",
      "Ensigha",
      "Kais",
      "Khenchela",
      "Khirane",
      "M'Sara",
      "M'Toussa",
      "Ouled Rechache",
      "Remila",
      "Tamza",
      "Taouzient",
      "Yabous",
    ],
    "Souk Ahras": [
      "Aïn Soltane",
      "Aïn Zana",
      "Bir Bou Haouch",
      "Dréa",
      "Hanancha",
      "Heddada",
      "Khedara",
      "Khemissa",
      "M'Daourouch",
      "Mechroha",
      "Merahna",
      "Oued Keberit",
      "Ouillen",
      "Ouled Driss",
      "Ouled Moumen",
      "Oum El Adhaim",
      "Ragouba",
      "Safel El Ouiden",
      "Sedrata",
      "Sidi Fredj",
      "Souk Ahras",
      "Taoura",
      "Terraguelt",
      "Tiffech",
      "Zaarouria",
      "Zouabi",
    ],
    Tipasa: [
      "Aghbal",
      "Ahmar El Aïn",
      "Aïn Tagourait",
      "Attatba",
      "Beni Milleuk",
      "Bouharoun",
      "Bou Ismaïl",
      "Bourkika",
      "Chaiba",
      "Cherchell",
      "Damous",
      "Douaouda",
      "Fouka",
      "Gouraya",
      "Hadjeret Ennous",
      "Hadjout",
      "Khemisti",
      "Koléa",
      "Larhat",
      "Menaceur",
      "Merad",
      "Messelmoun",
      "Nador",
      "Sidi Amar",
      "Sidi Ghiles",
      "Sidi Rached",
      "Sidi Semiane",
      "Tipaza",
    ],
    Mila: [
      "Ahmed Rachedi",
      "Aïn Beida Harriche",
      "Aïn Mellouk",
      "Aïn Tine",
      "Amira Arrès",
      "Benyahia Abderrahmane",
      "Bouhatem",
      "Chelghoum Laïd",
      "Chigara",
      "Derradji Bousselah",
      "Elayadi Barbes",
      "El Mechira",
      "Ferdjioua",
      "Grarem Gouga",
      "Hamala",
      "Mila",
      "Minar Zarza",
      "Oued Athmania",
      "Oued Endja",
      "Oued Seguen",
      "Ouled Khalouf",
      "Rouached",
      "Sidi Khelifa",
      "Sidi Mérouane",
      "Tadjenanet",
      "Tassadane Haddada",
      "Teleghma",
      "Terrai Bainen",
      "Tessala Lemtaï",
      "Tiberguent",
      "Yahia Beni Guecha",
      "Zeghaia",
    ],
    "Aín Defla": [
      "Aïn Benian",
      "Aïn Bouyahia",
      "Aïn Defla",
      "Aïn Lechiekh",
      "Aïn Soltane",
      "Aïn Torki",
      "Arib",
      "Bathia",
      "Belaas",
      "Ben Allal",
      "Birbouche",
      "Bir Ould Khelifa",
      "Bordj Emir Khaled",
      "Boumedfaa",
      "Bourached",
      "Djelida",
      "Djemaa Ouled Cheikh",
      "Djendel",
      "El Abadia",
      "El Amra",
      "El Attaf",
      "El Hassania",
      "El Maine",
      "Hammam Righa",
      "Hoceinia",
      "Khemis Miliana",
      "Mekhatria",
      "Miliana",
      "Oued Chorfa",
      "Oued Djemaa",
      "Rouina",
      "Sidi Lakhdar",
      "Tacheta Zougagha",
      "Tarik Ibn Ziad",
      "Tiberkanine",
      "Zeddine",
    ],
    Naama: [
      "Aïn Ben Khelil",
      "Aïn Sefra",
      "Assela",
      "Djeniene Bourezg",
      "El Biod",
      "Kasdir",
      "Makman Ben Amer",
      "Méchria",
      "Moghrar",
      "Naâma",
      "Sfissifa",
      "Tiout",
    ],
    "Aín Temushent": [
      "Aghlal",
      "Aïn El Arbaa",
      "Aïn Kihal",
      "Aïn Témouchent",
      "Aïn Tolba",
      "Aoubellil",
      "Béni Saf",
      "Bouzedjar",
      "Chaabat El Leham",
      "Chentouf",
      "El Amria",
      "El Emir Abdelkader",
      "El Malah",
      "El Messaid",
      "Hammam Bou Hadjar",
      "Hassasna",
      "Hassi El Ghella",
      "Oued Berkeche",
      "Oued Sabah",
      "Ouled Boudjemaa",
      "Ouled Kihal",
      "Oulhaça El Gheraba",
      "Sidi Ben Adda",
      "Sidi Boumedienne",
      "Sidi Ouriache",
      "Sidi Safi",
      "Tamzoura",
      "Terga",
    ],
    Gardaya: [
      "Berriane",
      "Bounoura",
      "Dhayet Bendhahoua",
      "El Atteuf",
      "El Guerrara",
      "El Menia",
      "Ghardaïa",
      "Hassi Fehal",
      "Hassi Gara",
      "Mansoura",
      "Metlili",
      "Sebseb",
      "Zelfana",
    ],
    Relizan: [
      "Aïn Rahma",
      "Aïn Tarek",
      "Ammi Moussa",
      "Belassel Bouzegza",
      "Bendaoud",
      "Beni Dergoun",
      "Beni Zentis",
      "Dar Ben Abdellah",
      "Djidioua",
      "El Guettar",
      "El Hamadna",
      "El Hassi",
      "El Matmar",
      "El Ouldja",
      "Had Echkalla",
      "Hamri",
      "Kalaa",
      "Lahlef",
      "Mazouna",
      "Mediouna",
      "Mendes",
      "Merdja Sidi Abed",
      "Ouarizane",
      "Oued El Djemaa",
      "Oued Essalem",
      "Oued Rhiou",
      "Ouled Aiche",
      "Ouled Sidi Mihoub",
      "Ramka",
      "Relizane",
      "Sidi Khettab",
      "Sidi Lazreg",
      "Sidi M'Hamed Ben Ali",
      "Sidi M'Hamed Benaouda",
      "Sidi Saada",
      "Souk El Had",
      "Yellel",
      "Zemmora",
    ],
  },
  "Australia / Commonwealth of Australia": {
    "Australian Antarctic Territory": ["Australian Antarctic Territory"],
    "Isla de Navidad": ["Isla de Navidad"],
    "Isla Norfolk": ["Isla Norfolk"],
    "Islas Ashmore y Cartier": ["Islas Ashmore y Cartier"],
    "Islas Cocos": ["Islas Cocos"],
    "Islas del Mar del Coral": ["Islas del Mar del Coral"],
    "Islas Heard y McDonald": ["Islas Heard y McDonald"],
    "Jervis Bay Territory": ["Jervis Bay Territory"],
    "New South Wales": [
      "Albury",
      "Armidale",
      "Balranald",
      "Balranald South",
      "Barmedman",
      "Barmedman East",
      "Bathurst",
      "Bega",
      "Bellingen",
      "Berrima",
      "Bingara",
      "Bombala",
      "Boorowa",
      "Bourke",
      "Braidwood",
      "Breewarrina",
      "Breewarrina East",
      "Campbelltown",
      "Carcoar",
      "Casino",
      "Cassillis",
      "Cobar",
      "Cobar East",
      "Condobolin",
      "Cooma",
      "Coonabarabran",
      "Coonamble",
      "Cootamundry",
      "Cootamundry Central",
      "Corowa",
      "Cowra",
      "Deniliquin",
      "Dubbo",
      "Dungog",
      "Eden",
      "Forbes",
      "Glen Innes",
      "Gosford",
      "Goulburn",
      "Grafton",
      "Grenfell",
      "Gundagai",
      "Gunnedah",
      "Gunning",
      "Hay",
      "Hay North",
      "Hillston",
      "Hillston North",
      "Inverell",
      "Kempsey",
      "Kiama",
      "Lismore",
      "Lithgow",
      "Liverpool",
      "Maitland",
      "Metropolitan",
      "Milton",
      "Molong",
      "Moree",
      "Moruya",
      "Mudgee",
      "Murrurundi",
      "Murwillumbah",
      "Muswellbrook",
      "Narrabri",
      "Narrandera",
      "Newcastle",
      "Nowra",
      "Nyngan",
      "Orange",
      "Parkes",
      "Parramatta",
      "Paterson",
      "Penrith",
      "Picton",
      "Port Macquarie",
      "Queanbeyan",
      "Raymonod Terrace",
      "Rylstone",
      "Scone",
      "Singleton",
      "Stroud",
      "Tamworth",
      "Taree",
      "Tenterfield",
      "Tumbarumba",
      "Tumbarumba North",
      "Tumut",
      "Urana",
      "Wagga Wagga",
      "Walcha",
      "Walgett",
      "Walgett North",
      "Warialda",
      "Warren",
      "Wellington",
      "Wentworth",
      "Wilcannia",
      "Windsor",
      "Wollombi",
      "Wollongong",
      "Wyalong",
      "Yass",
      "Young",
    ],
    "Northern Territory": [
      "Darwin",
      "Palmerston",
      "Alice Springs",
      "Katherine",
      "Nhulunbuy",
      "Tennant Creek",
      "Wadeye",
      "Jabiru",
      "Yulara",
    ],
    Queensland: [
      "Burke (named after Burke and Wills)",
      "Cook",
      "Darling Downs",
      "Leichhardt",
      "Maranoa",
      "Mitchell",
      "Moreton",
      "North Gregory",
      "North Kennedy",
      "Port Curtis",
      "South Gregory",
      "South Kennedy",
      "Warrego",
      "Wide Bay/Burnett",
    ],
    "South Australia": [
      "County of Adelaide",
      "County of Albert",
      "County of Alfred",
      "County of Blachford",
      "County of Bosanquet",
      "County of Buccleuch",
      "County of Buckingham",
      "County of Burra",
      "County of Buxton",
      "County of Cardwell",
      "County of Carnarvon",
      "County of Chandos",
      "County of Dalhousie",
      "County of Daly",
      "County of Derby",
      "County of Dufferin",
      "County of Eyre",
      "County of Fergusson",
      "County of Flinders",
      "County of Frome",
      "County of Gawler",
      "County of Granville",
      "County of Grey",
      "County of Hamley",
      "County of Hanson",
      "County of Herbert",
      "County of Hindmarsh",
      "County of Hopetoun",
      "County of Hore-Ruthven",
      "County of Jervois",
      "County of Kimberley",
      "County of Kintore",
      "County of Le Hunte",
      "County of Light",
      "County of Lytton",
      "County of MacDonnell",
      "County of Manchester",
      "County of Musgrave",
      "County of Newcastle",
      "County of Robe",
      "County of Robinson",
      "County of Russell",
      "County of Stanley",
      "County of Sturt",
      "County of Taunton",
      "County of Victoria",
      "County of Way",
      "County of York",
      "County of Young",
      "Hundred of McBeath",
      "Hundred of Murray",
      "Hundred of Cooper",
      "Hundred of Randell",
      "Hundred of Giles",
      "Hundred of Morphett",
      "Hundred of Perawillia",
      "Hundred of Poondulta",
      "Hundred of Mundawora",
      "Hundred of Wiltowonga North",
      "Hundred of Pintumba",
      "Hundred of Koerabko",
      "Hundred of Wiltowonga South",
      "Hundred of Winikeberick",
      "Hundred of Baudin",
      "Hundred of Tassie",
      "Hundred of French",
      "Hundred of Haig",
      "Hundred of Beatty",
      "Hundred of Jellicoe",
      "Hundred of Cannawigara",
      "Hundred of Geegeela",
      "Hundred of Sturdee",
      "Hundred of Maude",
      "Hundred of Jutland",
      "Hundred of Allenby",
      "Hundred of Von Doussa",
      "Hundred of Krichauff",
      "Hundred of Paech",
      "Hundred of Basedow",
      "Hundred of Pflaum",
      "Hundred of Homburg",
      "Hundred of North Rhine",
      "Hundred of South Rhine",
      "Hundred of Schomburgk",
      "Hundred of Scherk",
      "Hundred of Adelaide",
      "Hundred of Barossa",
      "Hundred of Kuitpo",
      "Hundred of Munno Para",
      "Hundred of Noarlunga",
      "Hundred of Onkaparinga",
      "Hundred of Para Wirra",
      "Hundred of Port Adelaide",
      "Hundred of Talunga",
      "Hundred of Willunga",
      "Hundred of Yatala",
      "Hundred of Bakara",
      "Hundred of Bandon",
      "Hundred of Cadell",
      "Hundred of Chesson",
      "Hundred of Forster",
      "Hundred of Holder",
      "Hundred of Mantung",
      "Hundred of Mindarie",
      "Hundred of Moorook",
      "Hundred of Murbko",
      "Hundred of Nildottie",
      "Hundred of Paisley",
      "Hundred of Waikerie",
      "Hundred of Allen",
      "Hundred of Bookpurnong",
      "Hundred of Gordon",
      "Hundred of Kekwick",
      "Hundred of McGorrery",
      "Hundred of Murtho",
      "Hundred of Paringa",
      "Hundred of Pyap",
      "Hundred of Barndioota",
      "Hundred of Cotabena",
      "Hundred of Warrakimbo",
      "Hundred of Wonoka",
      "Hundred of Woolyana",
      "Hundred of Pildappa",
      "Hundred of Bowhill",
      "Hundred of Carcuma",
      "Hundred of Coneybeer",
      "Hundred of Hooper",
      "Hundred of Kirkpatrick",
      "Hundred of Lewis",
      "Hundred of Livingston",
      "Hundred of Marmon Jabuk",
      "Hundred of McPherson",
      "Hundred of Molineux",
      "Hundred of Peake",
      "Hundred of Price",
      "Hundred of Roby",
      "Hundred of Sherlock",
      "Hundred of Strawbridge",
      "Hundred of Vincent",
      "Hundred of Wilson",
      "Hundred of Archibald",
      "Hundred of Makin",
      "Hundred of McCallum",
      "Hundred of Pendleton",
      "Hundred of Senior",
      "Hundred of Shaugh",
      "Hundred of Stirling",
      "Hundred of Tatiara",
      "Hundred of Willalooka",
      "Hundred of Wirrega",
      "Hundred of Apoinga",
      "Hundred of Baldina",
      "Hundred of Bright",
      "Hundred of Bundey",
      "Hundred of Hallett",
      "Hundred of King",
      "Hundred of Kingston",
      "Hundred of Kooringa",
      "Hundred of Lindley",
      "Hundred of Mongolata",
      "Hundred of Rees",
      "Hundred of Tomkinson",
      "Hundred of Barna",
      "Hundred of Buckleboo",
      "Hundred of Caralue",
      "Hundred of Cortlinye",
      "Hundred of Cunyarie",
      "Hundred of Kelly",
      "Hundred of Moseley",
      "Hundred of O'Connor",
      "Hundred of Panitya",
      "Hundred of Pinkawillinie",
      "Hundred of Solomon",
      "Hundred of Wilcherry",
      "Hundred of Yalanda",
      "Hundred of Colebatch",
      "Hundred of Coombe",
      "Hundred of Field",
      "Hundred of Glyde",
      "Hundred of Laffer",
      "Hundred of McNamara",
      "Hundred of Messent",
      "Hundred of Neville",
      "Hundred of Petherick",
      "Hundred of Richards",
      "Hundred of Santo",
      "Hundred of Wells",
      "Hundred of Borda",
      "Hundred of Cassini",
      "Hundred of Dudley",
      "Hundred of Duncan",
      "Hundred of Gosse",
      "Hundred of Haines",
      "Hundred of MacGillivray",
      "Hundred of McDonald",
      "Hundred of Menzies",
      "Hundred of Newland",
      "Hundred of Ritchie",
      "Hundred of Seddon",
      "Hundred of Auld",
      "Hundred of Bews",
      "Hundred of Billiatt",
      "Hundred of Cotton",
      "Hundred of Day",
      "Hundred of Fisk",
      "Hundred of Kingsford",
      "Hundred of Parilla",
      "Hundred of Peebinga",
      "Hundred of Pinnaroo",
      "Hundred of Quirke",
      "Hundred of Black Rock Plain",
      "Hundred of Coomooroo",
      "Hundred of Erskine",
      "Hundred of Eurelia",
      "Hundred of Mannanarie",
      "Hundred of Morgan",
      "Hundred of Oladdie",
      "Hundred of Pekina",
      "Hundred of Tarcowie",
      "Hundred of Walloway",
      "Hundred of Yalpara",
      "Hundred of Yongala",
      "Hundred of Barunga",
      "Hundred of Cameron",
      "Hundred of Clinton",
      "Hundred of Kadina",
      "Hundred of Kulpara",
      "Hundred of Mundoora",
      "Hundred of Ninnes",
      "Hundred of Redhill",
      "Hundred of Tickera",
      "Hundred of Tiparra",
      "Hundred of Wallaroo",
      "Hundred of Wiltunga",
      "Hundred of Wokurna",
      "Hundred of Carawa",
      "Hundred of Hague",
      "Hundred of Haslam",
      "Hundred of Koolgera",
      "Hundred of Nunnyah",
      "Hundred of Perlubie",
      "Hundred of Petina",
      "Hundred of Pureba",
      "Hundred of Wallala",
      "Hundred of Walpuppie",
      "Hundred of Yantanabie",
      "Hundred of Anna",
      "Hundred of Bagot",
      "Hundred of Bower",
      "Hundred of Brownlow",
      "Hundred of Dutton",
      "Hundred of Eba",
      "Hundred of English",
      "Hundred of Fisher",
      "Hundred of Hay",
      "Hundred of Neales",
      "Hundred of Skurray",
      "Hundred of Carribie",
      "Hundred of Coonarie",
      "Hundred of Cunningham",
      "Hundred of Curramulka",
      "Hundred of Dalrymple",
      "Hundred of Kilkerran",
      "Hundred of Koolywurtie",
      "Hundred of Maitland",
      "Hundred of Melville",
      "Hundred of Minlacowie",
      "Hundred of Moorowie",
      "Hundred of Muloowurtie",
      "Hundred of Para Wurlie",
      "Hundred of Ramsay",
      "Hundred of Warrenben",
      "Hundred of Wauraltee",
      "Hundred of Cummins",
      "Hundred of Flinders",
      "Hundred of Hutchison",
      "Hundred of Koppio",
      "Hundred of Lake Wangary",
      "Hundred of Lincoln",
      "Hundred of Louth",
      "Hundred of Mortlock",
      "Hundred of Sleaford",
      "Hundred of Stokes",
      "Hundred of Uley",
      "Hundred of Ulipa",
      "Hundred of Wanilla",
      "Hundred of Warrow",
      "Hundred of Yaranyacka",
      "Hundred of Appila",
      "Hundred of Baroota",
      "Hundred of Booleroo",
      "Hundred of Coonatto",
      "Hundred of Darling",
      "Hundred of Davenport",
      "Hundred of Gregory",
      "Hundred of Pinda",
      "Hundred of Telowie",
      "Hundred of Willochra",
      "Hundred of Willowie",
      "Hundred of Winninowie",
      "Hundred of Wongyarra",
      "Hundred of Woolundunga",
      "Hundred of Alma",
      "Hundred of Balaklava",
      "Hundred of Dalkey",
      "Hundred of Dublin",
      "Hundred of Grace",
      "Hundred of Inkerman",
      "Hundred of Mudla Wirra",
      "Hundred of Port Gawler",
      "Hundred of Bendleby",
      "Hundred of Eurilpa",
      "Hundred of McCulloch",
      "Hundred of Uroonda",
      "Hundred of Wirreanda",
      "Hundred of Yanyarrie",
      "Hundred of Yednalue",
      "Hundred of Benara",
      "Hundred of Blanche",
      "Hundred of Caroline",
      "Hundred of Gambier",
      "Hundred of Grey",
      "Hundred of Hindmarsh",
      "Hundred of Kennion",
      "Hundred of Kongorong",
      "Hundred of Lake George",
      "Hundred of MacDonnell",
      "Hundred of Mayurra",
      "Hundred of Mingbool",
      "Hundred of Monbulla",
      "Hundred of Mount Muirhead",
      "Hundred of Nangwarry",
      "Hundred of Penola",
      "Hundred of Riddoch",
      "Hundred of Rivoli Bay",
      "Hundred of Short",
      "Hundred of Symon",
      "Hundred of Young",
      "Hundred of Katarapko",
      "Hundred of Loveday",
      "Hundred of Adams",
      "Hundred of Arkaba",
      "Hundred of Moralana",
      "Hundred of Warcowie",
      "Hundred of Cavenagh",
      "Hundred of Coglin",
      "Hundred of Minburra",
      "Hundred of Nackara",
      "Hundred of Paratoo",
      "Hundred of Waroonee",
      "Hundred of Alexandrina",
      "Hundred of Bremer",
      "Hundred of Encounter Bay",
      "Hundred of Goolwa",
      "Hundred of Kondoparinga",
      "Hundred of Macclesfield",
      "Hundred of Myponga",
      "Hundred of Nangkita",
      "Hundred of Strathalbyn",
      "Hundred of Waitpinga",
      "Hundred of Yankalilla",
      "Hundred of Bice",
      "Hundred of Caldwell",
      "Hundred of Lucy",
      "Hundred of May",
      "Hundred of Miller",
      "Hundred of Russell",
      "Hundred of Trunch",
      "Hundred of Wookata",
      "Hundred of Boonerdo",
      "Hundred of Boothby",
      "Hundred of Brooker",
      "Hundred of Butler",
      "Hundred of Campoona",
      "Hundred of Charleston",
      "Hundred of Darke",
      "Hundred of Dixson",
      "Hundred of Glynn",
      "Hundred of Hambidge",
      "Hundred of Hawker",
      "Hundred of Heggaton",
      "Hundred of Hincks",
      "Hundred of James",
      "Hundred of Jamieson",
      "Hundred of Mangalo",
      "Hundred of Mann",
      "Hundred of McGregor",
      "Hundred of Miltalie",
      "Hundred of Minbrie",
      "Hundred of Moody",
      "Hundred of Murlong",
      "Hundred of Nicholls",
      "Hundred of Palkagee",
      "Hundred of Pascoe",
      "Hundred of Playford",
      "Hundred of Roberts",
      "Hundred of Rudall",
      "Hundred of Smeaton",
      "Hundred of Tooligie",
      "Hundred of Verran",
      "Hundred of Warren",
      "Hundred of Wilton",
      "Hundred of Yadnarie",
      "Hundred of Gumbowie",
      "Hundred of Hardy",
      "Hundred of Ketchowla",
      "Hundred of Parnaroo",
      "Hundred of Terowie",
      "Hundred of Wonna",
      "Hundred of Bagster",
      "Hundred of Burgoyne",
      "Hundred of Cohen",
      "Hundred of Giles",
      "Hundred of Keith",
      "Hundred of Kevin",
      "Hundred of Magarey",
      "Hundred of Nash",
      "Hundred of Cocata",
      "Hundred of Cootra",
      "Hundred of Corrobinnie",
      "Hundred of Hill",
      "Hundred of Kappakoola",
      "Hundred of Koongawa",
      "Hundred of Mamblin",
      "Hundred of Minnipa",
      "Hundred of Palabie",
      "Hundred of Peella",
      "Hundred of Pinbong",
      "Hundred of Pordia",
      "Hundred of Pygery",
      "Hundred of Wannamana",
      "Hundred of Warramboo",
      "Hundred of Wudinna",
      "Hundred of Yaninee",
      "Hundred of Belvidere",
      "Hundred of Gilbert",
      "Hundred of Julia Creek",
      "Hundred of Kapunda",
      "Hundred of Light",
      "Hundred of Moorooroo",
      "Hundred of Nuriootpa",
      "Hundred of Saddleworth",
      "Hundred of Waterloo",
      "Hundred of Beeamma",
      "Hundred of Binnum",
      "Hundred of Duffield",
      "Hundred of Geegeela",
      "Hundred of Glen Roy",
      "Hundred of Hynam",
      "Hundred of Lacepede",
      "Hundred of Landseer",
      "Hundred of Lochaber",
      "Hundred of Marcollat",
      "Hundred of Minecrow",
      "Hundred of Murrabinna",
      "Hundred of Parsons",
      "Hundred of Peacock",
      "Hundred of Woolumbool",
      "Hundred of Castine",
      "Hundred of Copley",
      "Hundred of Gillen",
      "Hundred of Handyside",
      "Hundred of Jenkins",
      "Hundred of Barwell",
      "Hundred of Blesing",
      "Hundred of Colton",
      "Hundred of Cowan",
      "Hundred of Hudd",
      "Hundred of Kappawanta",
      "Hundred of Kiana",
      "Hundred of McIntosh",
      "Hundred of McLachlan",
      "Hundred of Mitchell",
      "Hundred of Peachna",
      "Hundred of Pearce",
      "Hundred of Shannon",
      "Hundred of Squire",
      "Hundred of Talia",
      "Hundred of Tinline",
      "Hundred of Ulyerra",
      "Hundred of Ward",
      "Hundred of Way",
      "Hundred of Boolcunda",
      "Hundred of Crozier",
      "Hundred of Cudlamudla",
      "Hundred of Kanyaka",
      "Hundred of Moockra",
      "Hundred of Palmer",
      "Hundred of Pichi Richi",
      "Hundred of Wyacca",
      "Hundred of Yarrah",
      "Hundred of Bowaka",
      "Hundred of Bray",
      "Hundred of Coles",
      "Hundred of Comaum",
      "Hundred of Conmurra",
      "Hundred of Fox",
      "Hundred of Jessie",
      "Hundred of Joanna",
      "Hundred of Joyce",
      "Hundred of Killanoola",
      "Hundred of Mount Benson",
      "Hundred of Naracoorte",
      "Hundred of Robertson",
      "Hundred of Ross",
      "Hundred of Smith",
      "Hundred of Spence",
      "Hundred of Townsend",
      "Hundred of Waterhouse",
      "Hundred of Addison",
      "Hundred of Bockelberg",
      "Hundred of Campbell",
      "Hundred of Carina",
      "Hundred of Chandada",
      "Hundred of Condada",
      "Hundred of Cungena",
      "Hundred of Downer",
      "Hundred of Finlayson",
      "Hundred of Forrest",
      "Hundred of Inkster",
      "Hundred of Kaldoonera",
      "Hundred of Karcultaby",
      "Hundred of Moorkitabie",
      "Hundred of Murray",
      "Hundred of Ripon",
      "Hundred of Rounsevell",
      "Hundred of Scott",
      "Hundred of Tarlton",
      "Hundred of Travers",
      "Hundred of Wallis",
      "Hundred of Witera",
      "Hundred of Wrenfordsley",
      "Hundred of Wright",
      "Hundred of Baker",
      "Hundred of Bonney",
      "Hundred of Burdett",
      "Hundred of Coolinong",
      "Hundred of Ettrick",
      "Hundred of Jeffries",
      "Hundred of Malcolm",
      "Hundred of Seymour",
      "Hundred of Younghusband",
      "Hundred of Andrews",
      "Hundred of Ayers",
      "Hundred of Blyth",
      "Hundred of Boucaut",
      "Hundred of Clare",
      "Hundred of Everard",
      "Hundred of Goyder",
      "Hundred of Hall",
      "Hundred of Hanson",
      "Hundred of Hart",
      "Hundred of Koolunga",
      "Hundred of Milne",
      "Hundred of Stanley",
      "Hundred of Stow",
      "Hundred of Upper Wakefield",
      "Hundred of Yackamoorundie",
      "Hundred of Angas",
      "Hundred of Brinkley",
      "Hundred of Finniss",
      "Hundred of Freeling",
      "Hundred of Kanmantoo",
      "Hundred of Mobilong",
      "Hundred of Monarto",
      "Hundred of Ridley",
      "Hundred of Tungkillo",
      "Hundred of Bunyeroo",
      "Hundred of Carr",
      "Hundred of Edeowie",
      "Hundred of Nilpena",
      "Hundred of Oratunga",
      "Hundred of Parachilna",
      "Hundred of Anne",
      "Hundred of Belalie",
      "Hundred of Blacker",
      "Hundred of Bonython",
      "Hundred of Booyoolie",
      "Hundred of Bundaleer",
      "Hundred of Caltowie",
      "Hundred of Catt",
      "Hundred of Chillundie",
      "Hundred of Crystal Brook",
      "Hundred of Goode",
      "Hundred of Guthrie",
      "Hundred of Horn",
      "Hundred of Howe",
      "Hundred of Moule",
      "Hundred of Napperby",
      "Hundred of Narridy",
      "Hundred of O'Loughlin",
      "Hundred of Pethick",
      "Hundred of Pirie",
      "Hundred of Reynolds",
      "Hundred of Wallanippie",
      "Hundred of Wandana",
      "Hundred of Wandearah",
      "Hundred of Whyte",
      "Hundred of Yangya",
      "Hundred of Bartlett",
      "Hundred of Ash",
      "Hundred of Batchelor",
      "Hundred of Cultana",
      "Hundred of Moonabie",
      "Hundred of Nilginee",
      "Hundred of Poynton",
      "Hundred of Randell",
      "Hundred of Markaranka",
      "Hundred of Parcoola",
      "Hundred of Pooginook",
      "Hundred of Stuart",
    ],
    Tasmania: [
      "Arthur Land District",
      "Buckingham Land District",
      "Cornwall Land District",
      "Cumberland Land District",
      "Devon Land District",
      "Dorset Land District",
      "Flinders Land District",
      "Franklin Land District",
      "Glamorgan Land District",
      "Kent Land District",
      "King Land District",
      "Lincoln Land District",
      "Monmouth Land District",
      "Montagu Land District",
      "Montgomery Land District",
      "Pembroke Land District",
      "Russell Land District",
      "Somerset Land District",
      "Wellington Land District, Tasmania",
      "Westmoreland Land District",
    ],
    "Territorio federal de Australia": ["Territorio federal de Australia"],
    Victoria: [
      "Anglesey",
      "Benambra",
      "Bendigo",
      "Bogong",
      "Borung",
      "Bourke",
      "Buln Buln",
      "Croajingolong",
      "Dalhousie",
      "Dargo",
      "Delatite",
      "Dundas",
      "Evelyn",
      "Follett",
      "Gladstone",
      "Grant",
      "Grenville",
      "Gunbower",
      "Hampden",
      "Heytesbury",
      "Kara Kara",
      "Karkarooc",
      "Lowan",
      "Millewa",
      "Moira",
      "Mornington",
      "Normanby",
      "Polwarth",
      "Ripon",
      "Rodney",
      "Talbot",
      "Tambo",
      "Tanjil",
      "Tatchera",
      "Villiers",
      "Weeah",
      "Wonnangatta",
    ],
    "Western Australia": [
      "Ashburton",
      "Avon",
      "Balladonia",
      "Balwina",
      "Bulara",
      "Bulga",
      "Buningonia",
      "Canning",
      "Cockburn Sound",
      "Dampier",
      "De Grey",
      "De Witt",
      "Delisser",
      "Dempster",
      "Doongan",
      "Dundas",
      "Easton",
      "Edel",
      "Edjudina",
      "Erivilla",
      "Esperance",
      "Fitzgerald",
      "Fitzroy",
      "Forrest",
      "Fraser",
      "Gascoyne",
      "Giles",
      "Gregory",
      "Hampton",
      "Hann",
      "Hardey",
      "Hay",
      "Jarmura",
      "Jaurdi",
      "Jilbadji",
      "Kaluwiri",
      "Kent",
      "King",
      "Kojonup",
      "Koondra",
      "Kyarra",
      "Leake",
      "Luman",
      "Lyndon",
      "Lyons",
      "Malcolm",
      "Mardarbilla",
      "Marmion",
      "Marndoc",
      "Meda",
      "Melbourne",
      "Milyuga",
      "Mundrabilla",
      "Murchison",
      "Murray",
      "Nabberu",
      "Nelson",
      "Neridup",
      "Ngalbain",
      "Ninghan",
      "Nookawarra",
      "Nuleri",
      "Numalgun",
      "Nurina",
      "Nuyts",
      "Oldfield",
      "Omalinde",
      "Pardu",
      "Peawah",
      "Plantagenet",
      "Roe",
      "Sussex",
      "Swan",
      "Teano",
      "Thadoona",
      "Tugaila",
      "Ularring",
      "Victoria",
      "Wanman",
      "Warramboo",
      "Weld",
      "Wellington",
      "Wells",
      "Williams",
      "Windell",
      "Yamarna",
      "Yelina",
      "Yilgarn",
      "Yowalga",
      "Yurabi",
    ],
  },
  "Austria / Österreich": {
    "Alta Austria (Oberösterreich)": ["Alta Austria (Oberösterreich)"],
    "Baja Austria (Niederösterreich)": [
      "Amstetten (Amstetten)",
      "Baden (Baden)",
      "Bruck an der Leitha (Bruck an der Leitha)",
      "Gänserndorf (Gänserndorf)",
      "Gmünd (Gmünd)",
      "Hollabrunn (Hollabrunn)",
      "Horn (Horn)",
      "Korneuburg (Korneuburg)",
      "Krems-Land (Krems an der Donau)",
      "Lilienfeld (Lilienfeld)",
      "Melk (Melk)",
      "Mistelbach (Mistelbach an der Zaya)",
      "Mödling (Mödling)",
      "Neunkirchen (Neunkirchen)",
      "St. Pölten-Land (Sankt Pölten)",
      "Scheibbs (Scheibbs)",
      "Tulln (Tulln an der Donau)",
      "Waidhofen an der Thaya (Waidhofen an der Thaya)",
      "Wiener Neustadt-Land (Wiener Neustadt)",
      "Wien-Umgebung (Korneuburg)",
      "Zwettl (Zwettl)",
    ],
    Burgenland: [
      "Eisenstadt-Umgebung",
      "Güssing",
      "Jennersdorf",
      "Mattersburg",
      "Neusiedl am See",
      "Oberpullendorf (hún. Felsőpulya)",
      "Oberwart (hún. Felsőőr)",
    ],
    "Carintia (Kärnten)": ["Carintia (Kärnten)"],
    "Estiria (Steiermark)": [
      "Bruck-Mürzzuschlag",
      "Deutschlandsberg",
      "Graz",
      "Graz-Umgebung",
      "Hartberg-Fürstenfeld",
      "Leibnitz",
      "Leoben",
      "Liezen con subdistricto de Gröbming",
      "Murau",
      "Murtal",
      "Südoststeiermark",
      "Voitsberg",
      "Weiz",
    ],
    "Salzburgo (Salzburg)": [
      "Hallein (Tennengau)",
      "Salzburg-Stadt (Salzburgo)",
      "Salzburg-Umgebung (Flachgau, también Salzburg Land)",
      "St. Johann im Pongau (Pongau)",
      "Tamsweg (Lungau)",
      "Zell am See (Pinzgau)",
    ],
    Tirol: [
      "Imst (Imst)",
      "Innsbruck",
      "Innsbruck-Land (Innsbruck)",
      "Kitzbühel (Kitzbühel)",
      "Kufstein (Kufstein)",
      "Landeck (Landeck)",
      "Lienz (Lienz)",
      "Reutte (Reutte)",
      "Schwaz (Schwaz)",
    ],
    "Viena (Wien)": ["Viena (Wien)"],
    Vorarlberg: ["Bludenz", "Bregenz", "Dornbirn", "Feldkirch"],
  },
  Angola: {
    Bengo: [
      "Caxito",
      "Ambriz",
      "Dande",
      "Dembos",
      "Nambuangongo",
      "Bula Atumba",
      "Pango Aluquém",
    ],
    Benguela: [
      "Benguela",
      "Lobito",
      "Catumbela",
      "Baía Farta",
      "Bocoio",
      "Balombo",
      "Caimbambo",
      "Chongorói",
      "Cubal",
      "Ganda",
    ],
    Bié: [
      "Kuito",
      "Andulo",
      "Chitembo",
      "Catabola",
      "Camacupa",
      "Nharea",
      "Cuemba",
      "Chinguar",
    ],
    Cabinda: ["Cabinda", "Belize", "Buco-Zau", "Cacongo"],
    Cuando_Cubango: [
      "Menongue",
      "Cuito Cuanavale",
      "Cuchi",
      "Mavinga",
      "Calai",
      "Dirico",
    ],
    Cuanza_Norte: [
      "N'dalatando",
      "Cambambe",
      "Bolongongo",
      "Quiculungo",
      "Samba Caju",
      "Gonguembo",
      "Lucala",
      "Ambaca",
    ],
    Cuanza_Sul: [
      "Sumbe",
      "Porto Amboim",
      "Gabela",
      "Quibala",
      "Cassongue",
      "Ebo",
      "Libolo",
      "Mussende",
      "Seles",
      "Quilenda",
    ],
    Cunene: ["Ondjiva", "Xangongo", "Namacunde", "Cahama", "Curoca"],
    Huambo: [
      "Huambo",
      "Caála",
      "Bailundo",
      "Longonjo",
      "Ekunha",
      "Tchicala Tcholoanga",
      "Chinjenje",
    ],
    Huíla: [
      "Lubango",
      "Humpata",
      "Chicomba",
      "Matala",
      "Jamba",
      "Caconda",
      "Caluquembe",
      "Cuvango",
      "Quilengues",
      "Quipungo",
    ],
    Luanda: [
      "Luanda",
      "Belas",
      "Cazenga",
      "Cacuaco",
      "Viana",
      "Icolo e Bengo",
      "Quiçama",
    ],
    Lunda_Norte: [
      "Dundo",
      "Cambulo",
      "Cuilo",
      "Capenda Camulemba",
      "Chitato",
      "Lubalo",
    ],
    Lunda_Sur: ["Saurimo", "Cacolo", "Dala", "Muconda"],
    Malanje: [
      "Malanje",
      "Cacuso",
      "Calandula",
      "Cangandala",
      "Cuaba Nzogo",
      "Cambundi-Catembo",
      "Luquembo",
      "Quela",
    ],
    Moxico: [
      "Luena",
      "Luchazes",
      "Bundas",
      "Léua",
      "Alto Zambeze",
      "Camanongue",
    ],
    Namibe: ["Moçâmedes", "Tômbwa", "Bibala", "Virei"],
    Uíge: [
      "Uíge",
      "Maquela do Zombo",
      "Negage",
      "Damba",
      "Bungo",
      "Quimbele",
      "Sanza Pombo",
      "Songo",
    ],
    Zaire: ["M'banza-Kongo", "Soyo", "Nzeto", "Cuimba", "Tomboco", "Nóqui"],
  },
  "Bélgica / Belgien": {
    Flandes: [
      "Amberes",
      "Brabante Flamenco",
      "Flandes Occidental",
      "Flandes Oriental",
      "Limburgo",
    ],
    Valonia: ["Brabante Valón", "Henao", "Lieja", "Luxemburgo", "Namur"],
    Bruselas: [
      "Anderlecht",
      "Auderghem",
      "Berchem-Sainte-Agathe",
      "Bruselas",
      "Etterbeek",
      "Evere",
      "Forest",
      "Ganshoren",
      "Ixelles",
      "Jette",
      "Koekelberg",
      "Saint-Gilles",
      "Saint-Josse-ten-Noode",
      "Schaerbeek",
      "Sint-Jans-Molenbeek",
      "Uccle",
      "Watermael-Boitsfort",
      "Woluwe-Saint-Lambert",
      "Woluwe-Saint-Pierre",
    ],
  },
  Bolivia: {
    Beni: [
      "Antonio Vaca Díez",
      "Cercado",
      "General José Ballivián Segurola",
      "Iténez",
      "Mamoré",
      "Marbán",
      "Mojos",
      "Yacuma",
    ],
    Chuquisaca: [
      "Belisario Boeto",
      "Hernando Siles",
      "Jaime Zudáñez",
      "Juana Azurduy",
      "Luis Calvo",
      "Nor Cinti",
      "Oropeza",
      "Sud Cinti",
      "Tomina",
      "Yamparáez",
    ],
    Cochabamba: [
      "Arani",
      "Arque",
      "Ayopaya",
      "Bolívar",
      "Campero",
      "Capinota",
      "Carrasco",
      "Cercado",
      "Chapare",
      "Esteban Arce",
      "Germán Jordán",
      "Mizque",
      "Punata",
      "Quillacollo",
      "Tapacarí",
      "Tiraque",
    ],
    "La Paz": [
      "Abel Iturralde",
      "Aroma",
      "Bautista Saavedra",
      "Caranavi",
      "Eliodoro Camacho",
      "Franz Tamayo",
      "Gualberto Villaroel",
      "Ingavi",
      "Inquisivi",
      "José Manuel Pando",
      "Larecaja",
      "Loayza",
      "Los Andes",
      "Manco Kapac",
      "Muñecas",
      "Nor Yungas",
      "Omasuyos",
      "Pacajes",
      "Pedro Domingo Murillo",
      "Sud Yungas",
    ],
    Oruro: [
      "Atahuallpa",
      "Carangas",
      "Cercado",
      "Eduardo Avaroa",
      "Ladislao Cabrera",
      "Litoral",
      "Nor Carangas",
      "Pantaleón Dalence",
      "Poopó",
      "Puerto de Mejillones",
      "Sajama",
      "San Pedro de Totora",
      "Saucarí",
      "Sebastian Pagador",
      "Sud Carangas",
      "Tomas Barrón",
    ],
    Pando: [
      "Abuná",
      "Federico Román",
      "Madre de Dios",
      "Manuripi",
      "Nicolás Suárez",
    ],
    Potosí: [
      "Alonzo de Ibáñez",
      "Antonio Quijarro",
      "Bernardino Bilbao",
      "Charcas",
      "Chayanta",
      "Cornelio Saavedra",
      "Daniel Saavedra",
      "Enrique Baldivieso",
      "José María Linares",
      "Modesto Omiste",
      "Nor Chichas",
      "Nor Lípez",
      "Rafael Bustillo",
      "Sud Chichas",
      "Sud Lipez",
      "Tomás Frías",
    ],
    "Santa Cruz": [
      "Andrés Ibáñez",
      "Ángel Sandoval",
      "Caballero",
      "Chiquitos capital",
      "Cordillera",
      "Florida",
      "Germán Busch",
      "Guarayos",
      "Ichilo",
      "Ignacio Warnes",
      "José Miguel de Velasco",
      "Ñuflo de Chávez",
      "Santistevan",
      "Sara",
      "Vallegrande",
    ],
    Tarija: [
      "Aniceto Arce",
      "Burdet O’Connor",
      "Cercado",
      "Eustaquio Méndez",
      "Gran Chaco",
      "José María Avilés",
    ],
  },
  Benín: {
    Alibori: [
      "Banikoara",
      "Gogounou",
      "Kandi",
      "Karimama",
      "Malanville",
      "Segbana",
    ],
    Atakora: [
      "Boukoumbé",
      "Cobly",
      "Kérou",
      "Kouandé",
      "Matéri",
      "Natitingou",
      "Péhunco",
      "Tanguiéta",
      "Toucountouna",
    ],
    Atlantique: [
      "Abomey-Calavi",
      "Allada",
      "Kpomassè",
      "Ouidah",
      "Sô-Ava",
      "Toffo",
      "Tori-Bossito",
      "Zè",
    ],
    Borgou: [
      "Bembéréké",
      "Kalalé",
      "N'Dali",
      "Nikki",
      "Parakou",
      "Pèrèrè",
      "Sinendé",
      "Tchaourou",
    ],
    Collines: [
      "Bantè",
      "Dassa-Zoumè",
      "Glazoué",
      "Ouèssè",
      "Savalou",
      "Djeffa",
      "Bétérou",
      "Quémé",
      "N'Dali",
      "Béthom",
      "Djougou",
      "Copargo",
      "Parakou",
    ],
    Donga: ["Bassila", "Copargo", "Djougou", "Ouaké", "Kouandé", "Boukoumbé"],
    Kouffo: [
      "Aplahoué",
      "Djakotomey",
      "Dogbo",
      "Klouékanmè",
      "Lalo",
      "Toviklin",
    ],
    Litoral: [
      "Cotonú",
      "Sèmè-Kpodji",
      "Abomey-Calavi",
      "Ouidah",
      "Porto-Novo",
      "Adjarra",
      "Aguégués",
      "Akpro-Missérété",
      "Avrankou",
      "Bopa",
      "Houéyogbé",
      "Kpomassè",
      "Sô-Ava",
      "Toffo",
      "Tori-Bossito",
    ],
    Mono: [
      "Athiémé",
      "Bopa",
      "Comè",
      "Grand-Popo",
      "Houéyogbé",
      "Lokossa",
      "Ifangni",
    ],
    Ouémé: [
      "Adjarra",
      "Adjohoun",
      "Aguégués",
      "Akpro-Missérété",
      "Avrankou",
      "Bonou",
      "Dangbo",
      "Porto Novo",
      "Sèmè-Kpodji",
    ],
    Plateau: ["Adja-Ouèrè", "Ifangni", "Kétou", "Pobè", "Sakété"],
    Zou: [
      "Abomey",
      "Agbangnizoun",
      "Bohicon",
      "Covè",
      "Djidja",
      "Ouinhi",
      "Za-Kpota",
      "Zagnanado",
      "Zogbodomey",
    ],
  },
  Brasil: {
    Acre: [
      "Acrelandia",
      "Assis Brasil",
      "Brasiléia",
      "Bujari",
      "Capixaba",
      "Cruzeiro do Sul",
      "Epitaciolândia",
      "Feijó",
      "Jordão",
      "Mancio Lima",
      "Manoel Urbano",
      "Marechal Thaumaturgo",
      "Plácido de Castro",
      "Porto Acre",
      "Porto Walter",
      "Río Branco",
      "Rodrigues Alves",
      "Santa Rosa do Purus",
      "Sena Madureira",
      "Senador Guiomard",
      "Tarauaca",
      "Xapuri",
    ],
    Alagoas: [
      "Arapiraca",
      "Campo Alegre",
      "Coruripe",
      "Delmiro Gouveia",
      "Maceió",
      "Palmeira dos Índios",
      "Penedo",
      "Rio Largo",
      "São Miguel dos Campos",
      "União dos Palmares",
    ],
    Amapá: [
      "Amapá",
      "Calçoene",
      "Cutias",
      "Ferreira Gomes",
      "Itaubal",
      "Laranjal do Jari",
      "Macapá",
      "Mazagão",
      "Oiapoque",
      "Pedra Branca do Amapari",
      "Porto Grande",
      "Pracuúba",
      "Santana",
      "Serra do Navio",
      "Tartarugalzinho",
      "Vitória do Jari",
    ],
    Amazonas: [
      "Alvarães",
      "Amaturá",
      "Anamã",
      "Anori",
      "Apuí",
      "Atalaia do Norte",
      "Autazes",
      "Barcelos",
      "Barreirinha",
      "Benjamin Constant",
      "Beruri",
      "Boa Vista do Ramos",
      "Boca do Acre",
      "Borba",
      "Caapiranga",
      "Canutama",
      "Carauari",
      "Careiro",
      "Careiro da Várzea",
      "Coari",
      "Codajás",
      "Eirunepé",
      "Envira",
      "Fonte Boa",
      "Guajará",
      "Humaitá",
      "Ipixuna",
      "Iranduba",
      "Itacoatiara",
      "Itamarati",
      "Itapiranga",
      "Japurá",
      "Juruá",
      "Jutaí",
      "Lábrea",
      "Manacapuru",
      "Manaquiri",
      "Manaus",
      "Manicoré",
      "Maraã",
      "Maués",
      "Nhamundá",
      "Nova Olinda do Norte",
      "Novo Airão",
      "Novo Aripuanã",
      "Parintins",
      "Pauini",
      "Presidente Figueiredo",
      "Rio Preto da Eva",
      "Santa Isabel do Rio Negro",
      "Santo Antônio do Içá",
      "São Gabriel da Cachoeira",
      "São Paulo de Olivença",
      "São Sebastião do Uatumã",
      "Silves",
      "Tabatinga",
      "Tapauá",
      "Tefé",
      "Tonantins",
      "Uarini",
      "Urucará",
      "Urucurituba",
    ],
    Bahía: ["Bahía "],
    Ceará: [
      "Caucaia",
      "Crato",
      "Fortaleza",
      "Iguatu",
      "Itapipoca",
      "Juazeiro do Norte",
      "Maracanaú",
      "Maranguape",
      "Quixadá",
      "Sobral",
    ],
    "Distrito Federal": ["Distrito Federal "],
    "Espírito Santo": ["Espírito Santo"],
    Goiás: [
      " Formosa",
      " Novo Gama",
      " Águas Lindas de Goiás",
      " Anápolis",
      " Aparecida de Goiânia",
      " Catalão",
      " Goiânia",
      " Itumbiara",
      " Jataí",
      " Luziânia",
      " Planaltina",
      " Rio Verde",
      " Senador Canedo",
      " Trindade",
      " Valparaíso de Goiás",
    ],
    Maranhão: ["Maranhão"],
    "Mato Grosso": [
      " Cáceres",
      " Rondonópolis",
      " Sinop",
      "Barra do Garças",
      "Cuiabá",
      "Lucas do Rio Verde",
      "Primavera do Leste",
      "Sorriso",
      "Tangará da Serra",
      "Várzea Grande",
    ],
    "Mato Grosso del Sur": [
      "Campo Grande",
      "Dourados ",
      "Três Lagoas ",
      "Corumbá ",
      "Ponta Porã ",
      "Sidrolândia ",
      "Naviraí ",
      "Nova Andradina ",
      "Aquidauana ",
      "Maracaju ",
    ],
    "Minas Gerais": [
      "Alfenas",
      "Araguari",
      "Araxá",
      "Barbacena",
      "Belo Horizonte",
      "Betim",
      "Caratinga",
      "Cataguases",
      "Conselheiro Lafaiete",
      "Contagem",
      "Coronel Fabriciano",
      "Curvelo",
      "Divinópolis",
      "Esmeraldas",
      "Formiga",
      "Governador Valadares",
      "Ibirité",
      "Ipatinga",
      "Itabira",
      "Itajubá",
      "Itaúna",
      "Ituiutaba",
      "Janaúba",
      "Januária",
      "João Monlevade",
      "Juiz de Fora",
      "Lagoa Santa",
      "Lavras",
      "Manhuaçu",
      "Mariana",
      "Montes Claros",
      "Muriaé",
      "Nova Lima",
      "Nova Serrana",
      "Ouro Preto",
      "Pará de Minas",
      "Paracatu",
      "Passos",
      "Patos de Minas",
      "Patrocínio",
      "Pedro Leopoldo",
      "Poços de Caldas",
      "Ponte Nova",
      "Pouso Alegre",
      "Ribeirão das Neves",
      "Sabará",
      "Santa Luzia",
      "São João del Rei",
      "São Sebastião do Paraíso",
      "Sete Lagoas",
      "Teófilo Otoni",
      "Timóteo",
      "Três Corações",
      "Ubá",
      "Uberaba",
      "Uberlândia",
      "Unaí",
      "Varginha",
      "Vespasiano",
      "Viçosa",
    ],
    Pará: ["Pará "],
    Paraíba: ["Paraíba "],
    Paraná: [
      "Curitiba ",
      "Londrina ",
      "Maringá ",
      "Foz do Iguaçu ",
      "Ponta Grossa ",
      "Cascavel ",
      "São José dos Pinhais ",
      "Colombo (Paraná) ",
      "Guarapuava ",
      "Paranaguá ",
    ],
    Pernambuco: [
      "Cabo de Santo Agostinho",
      "Caruaru",
      "Jaboatão dos Guararapes",
      "Olinda",
      "Paulista",
      "Petrolina",
      "Recife",
    ],
    Piauí: [
      "Altos",
      "Barras",
      "Campo Maior",
      "Esperantina",
      "Floriano",
      "Parnaíba",
      "Picos",
      "Piripiri",
      "Teresina",
      "União",
    ],
    "Río de Janeiro": [
      "Angra dos Reis",
      "Aperibé",
      "Araruama",
      "Areal",
      "Armação de Búzios",
      "Arraial do Cabo",
      "Barra do Piraí",
      "Barra Mansa",
      "Belford Roxo",
      "Bom Jardim",
      "Bom Jesus do Itabapoana",
      "Cabo Frío",
      "Cachoeiras de Macacu",
      "Cambuci",
      "Campos dos Goytacazes",
      "Cantagalo",
      "Carapebus",
      "Cardoso Moreira",
      "Carmo",
      "Casimiro de Abreu",
      "Comendador Levy Gasparian",
      "Conceição de Macabu",
      "Cordeiro",
      "Duas Barras",
      "Duque de Caxias",
      "Engenheiro Paulo de Frontin",
      "Guapimirim",
      "Iguaba Grande",
      "Itaboraí",
      "Itaguaí",
      "Italva",
      "Itaocara",
      "Itaperuna",
      "Itatiaia",
      "Japeri",
      "Laje do Muriaé",
      "Macaé",
      "Macuco",
      "Magé",
      "Mangaratiba",
      "Maricá",
      "Mendes",
      "Mesquita",
      "Miguel Pereira",
      "Miracema",
      "Natividade",
      "Nilópolis",
      "Niterói",
      "Nova Friburgo",
      "Nova Iguaçu",
      "Paracambi",
      "Paraíba do Sul",
      "Parati",
      "Paty do Alferes",
      "Petrópolis",
      "Pinheiral",
      "Piraí",
      "Porciúncula",
      "Porto Real",
      "Quatis",
      "Queimados",
      "Quissamã",
      "Resende",
      "Rio Bonito",
      "Rio Claro",
      "Rio das Flores",
      "Rio das Ostras",
      "Río de Janeiro",
      "Santa Maria Madalena",
      "Santo Antônio de Pádua",
      "São Fidélis",
      "São Francisco de Itabapoana",
      "São Gonçalo",
      "São João da Barra",
      "São João de Meriti",
      "São José de Ubá",
      "São José do Vale do Rio Preto",
      "São Pedro da Aldeia",
      "São Sebastião do Alto",
      "Sapucaia",
      "Saquarema",
      "Seropédica",
      "Silva Jardim",
      "Sumidouro",
      "Tanguá",
      "Teresópolis",
      "Trajano de Morais",
      "Três Rios",
      "Valença",
      "Varre-Sai",
      "Vassouras",
      "Volta Redonda",
    ],
    "Río Grande del Norte": ["Río Grande del Norte "],
    "Río Grande del Sur": [
      "Alegrete",
      "Alvorada",
      "Bagé",
      "Bento Gonçalves",
      "Cachoeirinha",
      "Canoas",
      "Capão da Canoa",
      "Caxias do Sul",
      "Erechim",
      "Farroupilha",
      "Gravataí",
      "Guaíba",
      "Novo Hamburgo",
      "Passo Fundo",
      "Pelotas",
      "Porto Alegre",
      "Río Grande",
      "Santa Cruz do Sul",
      "Santa María",
      "Santana do Livramento",
      "São Leopoldo",
      "Sapucaia do Sul",
      "Torres",
      "Uruguayana",
      "Viamão",
    ],
    Rondônia: [
      "Porto Velho",
      "Ji Paraná",
      " Ariquemes",
      " Vilhena",
      " Cacoal",
      " Rolim de Moura",
      " Jaru",
      " Guajará Mirim",
      " Ouro Preto do Oeste",
      " Pimenta Bueno",
    ],
    Roraima: [
      "Boa Vista",
      "Rorainópolis",
      "Caracaraí",
      "Mucajaí",
      "Cantá",
      "Alto Alegre",
      "Pacaraima",
      "Bonfim",
      "Amajari",
      "Iracema",
      "Normandia",
      "Uiramutã",
      "Caroebe",
      "São João da Baliza",
      "São Luís",
    ],
    "Santa Catarina": ["Santa Catarina "],
    "São Paulo": [
      "Americana",
      "Araçatuba",
      "Araraquara",
      "Araras",
      "Atibaia",
      "Barueri",
      "Bauru",
      "Botucatu",
      "Bragança Paulista",
      "Campinas",
      "Carapicuíba",
      "Cotia",
      "Cubatão",
      "Diadema",
      "Embu das Artes",
      "Ferraz de Vasconcelos",
      "Franca",
      "Francisco Morato",
      "Franco da Rocha",
      "Guarujá",
      "Guarulhos",
      "Hortolândia",
      "Indaiatuba",
      "Itapecerica da Serra",
      "Itapetininga",
      "Itapevi",
      "Itaquaquecetuba",
      "Itatiba",
      "Itu",
      "Jacareí",
      "Jaú",
      "Jundiaí",
      "Limeira",
      "Marília",
      "Mauá",
      "Mogi das Cruzes",
      "Mogi Guaçu",
      "Osasco",
      "Pindamonhangaba",
      "Piracicaba",
      "Praia Grande",
      "Presidente Prudente",
      "Ribeirão Preto",
      "Río Claro",
      "San Pablo",
      "Santa Bárbara d'Oeste",
      "Santo André",
      "Santos",
      "São Bernardo do Campo",
      "São Caetano do Sul",
      "São Carlos",
      "São José do Rio Preto",
      "São José dos Campos",
      "São Vicente",
      "Sorocaba",
      "Sumaré",
      "Suzano",
      "Taboão da Serra",
      "Taubaté",
      "Valinhos",
    ],
    Sergipe: [
      " Estância",
      " Lagarto",
      " Nossa Senhora da Glória",
      " Nossa Senhora do Socorro",
      " Aracaju",
      " Itabaiana",
      " Itabaianinha",
      " São Cristóvão",
      " Simão Dias",
      " Tobias Barreto",
    ],
    Tocantins: [
      " Porto Nacional",
      " Araguaína",
      " Araguatins",
      " Colinas do Tocantins",
      " Dianópolis",
      " Guaraí",
      " Gurupi",
      " Palmas",
      " Paraíso do Tocantins",
      " Tocantinópolis",
    ],
  },
  Canadá: {
    Alberta: [
      "Athabasca County",
      "Beaver County",
      "Big Lakes County",
      "Birch Hills County",
      "Brazeau County",
      "Camrose County",
      "Cardston County",
      "Clear Hills County",
      "Clearwater County",
      "County of Barrhead No. 11",
      "County of Forty Mile No. 8",
      "County of Grande Prairie No. 1",
      "County of Minburn No. 27",
      "County of Newell",
      "County of Northern Lights",
      "County of Paintearth No. 18",
      "County of St. Paul No. 19",
      "County of Stettler No. 6",
      "County of Two Hills No. 21",
      "County of Vermilion River",
      "County of Warner No. 5",
      "County of Wetaskiwin No. 10",
      "Cypress County",
      "Flagstaff County",
      "Foothills County",
      "Kneehill County",
      "Lac Ste. Anne County",
      "Lacombe County",
      "Lamont County",
      "Leduc County",
      "Lethbridge County",
      "Mountain View County",
      "Municipal District of Acadia No. 34",
      "Municipal District of Bighorn No. 8",
      "Municipal District of Bonnyville No. 87",
      "Municipal District of Fairview No. 136",
      "Municipal District of Greenview No. 16",
      "Municipal District of Lesser Slave River No. 124",
      "Municipal District of Opportunity No. 17",
      "Municipal District of Peace No. 135",
      "Municipal District of Pincher Creek No. 9",
      "Municipal District of Provost No. 52",
      "Municipal District of Ranchland No. 66",
      "Municipal District of Smoky River No. 130",
      "Municipal District of Spirit River No. 133",
      "Municipal District of Taber",
      "Municipal District of Wainwright No. 61",
      "Municipal District of Willow Creek No. 26",
      "Northern Sunrise County",
      "Parkland County",
      "Ponoka County",
      "Red Deer County",
      "Rocky View County",
      "Saddle Hills County",
      "Smoky Lake County",
      "Starland County",
      "Sturgeon County",
      "Thorhild County",
      "Vulcan County",
      "Westlock County",
      "Wheatland County",
      "Woodlands County",
      "Yellowhead County",
    ],
    "Columbia Británica": [
      "Alberni-Clayoquot",
      "Bulkley-Nechako",
      "Capital",
      "Cariboo",
      "Central Kootenay",
      "Central Okanagan",
      "Columbia-Shuswap",
      "Comox-Strathcona",
      "Cowichan Valley",
      "East Kootenay",
      "Fraser Valley",
      "Fraser-Fort George",
      "Gran Vancouver",
      "Kitimat-Stikine",
      "Kootenay Boundary",
      "Mount Waddington",
      "Nanaimo",
      "North Okanagan",
      "Northern Rockies",
      "Okanagan-Similkameen",
      "Peace River",
      "Powell River",
      "Skeena-Queen Charlotte",
      "Squamish-Lillooet",
      "Strathcona",
      "Sunshine Coast",
      "Thompson-Nicola",
    ],
    Manitoba: [
      "Altona",
      "Arborg",
      "Beausejour",
      "Brandon",
      "Carberry",
      "Carman",
      "Churchill",
      "Dauphin",
      "Dunnottar",
      "Flin Flon",
      "Gillam",
      "Grand Rapids",
      "Lac du Bonnet",
      "Leaf Rapids",
      "Lynn Lake",
      "Melita",
      "Minnedosa",
      "Morden",
      "Morris",
      "Neepawa",
      "Niverville",
      "Portage la Prairie",
      "Powerview-Pine Falls",
      "Selkirk",
      "Snow Lake",
      "Ste. Anne",
      "Steinbach",
      "Stonewall",
      "St-Pierre-Jolys",
      "Swan River",
      "Teulon",
      "The Pas",
      "Thompson",
      "Virden",
      "Winkler",
      "Winnipeg",
      "Winnipeg Beach",
    ],
    "New Brunswick": [
      "Alma",
      "Aroostook",
      "Atholville",
      "Balmoral",
      "Bas-Caraquet",
      "Bath",
      "Bathurst",
      "Beaubassin East",
      "Belledune",
      "Beresford",
      "Bertrand",
      "Blacks Harbour",
      "Blackville",
      "Bouctouche",
      "Cambridge-Narrows",
      "Campbellton",
      "Campobello Island",
      "Canterbury",
      "Cap-Pelé",
      "Caraquet",
      "Centreville",
      "Charlo",
      "Chipman",
      "Cocagne",
      "Dalhousie",
      "Dieppe",
      "Doaktown",
      "Dorchester",
      "Drummond",
      "Edmundston",
      "Eel River Crossing",
      "Florenceville-Bristol",
      "Fredericton",
      "Fredericton Junction",
      "Gagetown",
      "Grand Bay-Westfield",
      "Grand Falls",
      "Grand Manan",
      "Grande-Anse",
      "Hampton",
      "Hanwell",
      "Hartland",
      "Harvey",
      "Haut-Madawaska[d]",
      "Hillsborough",
      "Kedgwick[e]",
      "Lac Baker",
      "Lamèque",
      "Le Goulet",
      "Maisonnette",
      "McAdam",
      "Meductic",
      "Memramcook",
      "Millville",
      "Minto",
      "Miramichi",
      "Moncton",
      "Nackawic",
      "Neguac",
      "New Maryland",
      "Nigadoo",
      "Norton",
      "Oromocto",
      "Paquetville",
      "Perth-Andover",
      "Petitcodiac",
      "Petit-Rocher",
      "Plaster Rock",
      "Pointe-Verte",
      "Port Elgin",
      "Quispamsis",
      "Rexton",
      "Richibucto",
      "Riverside-Albert",
      "Riverview",
      "Rivière-Verte",
      "Rogersville",
      "Rothesay",
      "Sackville",
      "Saint Andrews",
      "Saint John",
      "Saint-André",
      "Saint-Antoine",
      "Sainte-Anne-de-Madawaska",
      "Sainte-Marie-Saint-Raphaël",
      "Saint-Isidore",
      "Saint-Léolin",
      "Saint-Léonard",
      "Saint-Louis de Kent",
      "Saint-Quentin",
      "Salisbury",
      "Shediac",
      "Shippagan",
      "St. George",
      "St. Martins",
      "St. Stephen",
      "Stanley",
      "Sussex",
      "Sussex Corner",
      "Tide Head",
      "Tracadie",
      "Tracy",
      "Upper Miramichi",
      "Woodstock",
    ],
    "Newfoundland and Labrador": [
      "Admirals Beach",
      "Anchor Point",
      "Appleton",
      "Aquaforte",
      "Arnold's Cove",
      "Avondale",
      "Badger",
      "Baie Verte",
      "Baine Harbour",
      "Bauline",
      "Bay Bulls",
      "Bay de Verde",
      "Bay L'Argent",
      "Bay Roberts",
      "Baytona",
      "Beachside",
      "Bellburns",
      "Belleoram",
      "Birchy Bay",
      "Bird Cove",
      "Bishop's Cove",
      "Bishop's Falls",
      "Bonavista",
      "Botwood",
      "Branch",
      "Brent's Cove",
      "Brighton",
      "Brigus",
      "Bryant's Cove",
      "Buchans",
      "Burgeo",
      "Burin",
      "Burlington",
      "Burnt Islands",
      "Campbellton",
      "Cape Broyle",
      "Cape St. George",
      "Carbonear",
      "Carmanville",
      "Cartwright",
      "Centreville-Wareham-Trinity",
      "Chance Cove",
      "Change Islands",
      "Channel-Port aux Basques",
      "Chapel Arm",
      "Charlottetown",
      "Clarenville",
      "Clarke's Beach",
      "Coachman's Cove",
      "Colinet",
      "Colliers",
      "Come By Chance",
      "Comfort Cove-Newstead",
      "Conception Bay South",
      "Conception Harbour",
      "Conche",
      "Cook's Harbour",
      "Cormack",
      "Corner Brook",
      "Cottlesville",
      "Cow Head",
      "Cox's Cove",
      "Crow Head",
      "Cupids",
      "Daniel's Harbour",
      "Deer Lake",
      "Dover",
      "Duntara",
      "Eastport",
      "Elliston",
      "Embree",
      "Englee",
      "English Harbour East",
      "Fermeuse",
      "Ferryland",
      "Flatrock",
      "Fleur de Lys",
      "Flower's Cove",
      "Fogo Island",
      "Forteau",
      "Fortune",
      "Fox Cove-Mortier",
      "Fox Harbour",
      "Frenchman's Cove",
      "Gallants",
      "Gambo",
      "Gander",
      "Garnish",
      "Gaskiers-Point La Haye",
      "Gaultois",
      "Gillams",
      "Glenburnie-Birchy Head-Shoal Brook",
      "Glenwood",
      "Glovertown",
      "Goose Cove East",
      "Grand Bank",
      "Grand Falls-Windsor",
      "Grand le Pierre",
      "Greenspond",
      "Hampden",
      "Hant's Harbour",
      "Happy Adventure",
      "Happy Valley-Goose Bay",
      "Harbour Breton",
      "Harbour Grace",
      "Harbour Main-Chapel's Cove-Lakeview",
      "Hare Bay",
      "Hawke's Bay",
      "Heart's Content",
      "Heart's Delight-Islington",
      "Heart's Desire",
      "Hermitage-Sandyville",
      "Holyrood",
      "Hopedale",
      "Howley",
      "Hughes Brook",
      "Humber Arm South",
      "Indian Bay",
      "Irishtown-Summerside",
      "Isle aux Morts",
      "Jackson's Arm",
      "Keels",
      "King's Cove",
      "King's Point",
      "Kippens",
      "Labrador City",
      "Lamaline",
      "L'Anse-au-Clair",
      "L'Anse-au-Loup",
      "Lark Harbour",
      "LaScie",
      "Lawn",
      "Leading Tickles",
      "Lewin's Cove",
      "Lewisporte",
      "Little Bay",
      "Little Bay East",
      "Little Bay Islands",
      "Little Burnt Bay",
      "Logy Bay-Middle Cove-Outer Cove",
      "Long Harbour-Mount Arlington Heights",
      "Lord's Cove",
      "Lourdes",
      "Lumsden",
      "Lushes Bight-Beaumont-Beaumont North",
      "Main Brook",
      "Makkovik",
      "Mary's Harbour",
      "Marystown",
      "Massey Drive",
      "McIvers",
      "Meadows",
      "Middle Arm",
      "Miles Cove",
      "Millertown",
      "Milltown-Head of Bay d'Espoir",
      "Ming's Bight",
      "Morrisville",
      "Mount Carmel-Mitchells Brook-St. Catherines",
      "Mount Moriah",
      "Mount Pearl",
      "Musgrave Harbour",
      "Musgravetown",
      "Nain",
      "New Perlican",
      "New-Wes-Valley",
      "Nipper's Harbour",
      "Norman's Cove-Long Cove",
      "Norris Arm",
      "Norris Point",
      "North River",
      "North West River",
      "Northern Arm",
      "Old Perlican",
      "Pacquet",
      "Paradise",
      "Parkers Cove",
      "Parson's Pond",
      "Pasadena",
      "Peterview",
      "Petty Harbour-Maddox Cove",
      "Pilley's Island",
      "Pinware",
      "Placentia",
      "Point au Gaul",
      "Point Lance",
      "Point Leamington",
      "Point May",
      "Point of Bay",
      "Pool's Cove",
      "Port Anson",
      "Port au Choix",
      "Port au Port East",
      "Port au Port West-Aguathuna-Felix Cove",
      "Port Blandford",
      "Port Hope Simpson",
      "Port Kirwan",
      "Port Rexton",
      "Port Saunders",
      "Portugal Cove South",
      "Portugal Cove–St. Philip's",
      "Postville",
      "Pouch Cove",
      "Raleigh",
      "Ramea",
      "Red Bay",
      "Red Harbour",
      "Reidville",
      "Rencontre East",
      "Renews-Cappahayden",
      "Rigolet",
      "River of Ponds",
      "Riverhead",
      "Robert's Arm",
      "Rocky Harbour",
      "Roddickton-Bide Arm",
      "Rose Blanche-Harbour le Cou",
      "Rushoon",
      "Sally's Cove",
      "Salmon Cove",
      "Salvage",
      "Sandringham",
      "Sandy Cove",
      "Seal Cove (Fortune Bay)",
      "Seal Cove (White Bay)",
      "Small Point-Adam's Cove-Blackhead-Broad Cove",
      "South Brook",
      "South River",
      "Southern Harbour",
      "Spaniard's Bay",
      "Springdale",
      "St. Alban's",
      "St. Anthony",
      "St. Bernard's-Jacques Fontaine",
      "St. Brendan's",
      "St. Bride's",
      "St. George's",
      "St. Jacques-Coomb's Cove",
      "St. John's",
      "St. Joseph's",
      "St. Lawrence",
      "St. Lewis",
      "St. Lunaire-Griquet",
      "St. Mary's",
      "St. Pauls",
      "St. Shott's",
      "St. Vincent's-St. Stephen's-Peter's River",
      "Steady Brook",
      "Stephenville",
      "Stephenville Crossing",
      "Summerford",
      "Sunnyside",
      "Terra Nova",
      "Terrenceville",
      "Tilt Cove",
      "Torbay",
      "Traytown",
      "Trepassey",
      "Trinity",
      "Trinity Bay North",
      "Triton",
      "Trout River",
      "Twillingate",
      "Upper Island Cove",
      "Victoria",
      "Wabana",
      "Wabush",
      "West St. Modeste",
      "Westport",
      "Whitbourne",
      "Whiteway",
      "Winterland",
      "Winterton",
      "Witless Bay",
      "Woodstock",
      "Woody Point",
      "York Harbour",
    ],
    "Northwest Territories": [
      "Aklavik",
      "Behchokǫ̀",
      "Délı̨nę",
      "Enterprise",
      "Fort Good Hope",
      "Fort Liard",
      "Fort McPherson",
      "Fort Providence",
      "Fort Resolution",
      "Fort Simpson",
      "Fort Smith",
      "Gamèti",
      "Hay River",
      "Inuvik",
      "Norman Wells",
      "Paulatuk",
      "Sachs Harbour",
      "Tsiigehtchic",
      "Tuktoyaktuk",
      "Tulita",
      "Ulukhaktok",
      "Wekweeti",
      "Whatì",
      "Yellowknife",
    ],
    "Nova Scotia": [
      "Amherst",
      "Annapolis Royal",
      "Annapolis",
      "Antigonish",
      "Antigonish",
      "Argyle",
      "Barrington",
      "Berwick",
      "Bridgewater",
      "Cape Breton",
      "Chester",
      "Clare",
      "Clark's Harbour",
      "Colchester",
      "Cumberland",
      "Digby",
      "Digby",
      "East Hants",
      "Guysborough",
      "Halifax",
      "Inverness",
      "Kentville",
      "Kings",
      "Lockeport",
      "Lunenburg",
      "Lunenburg",
      "Mahone Bay",
      "Middleton",
      "Mulgrave",
      "New Glasgow",
      "Oxford",
      "Pictou",
      "Pictou",
      "Port Hawkesbury",
      "Queens",
      "Richmond",
      "Shelburne",
      "Shelburne",
      "St. Mary's",
      "Stellarton",
      "Stewiacke",
      "Trenton",
      "Truro",
      "Victoria",
      "West Hants",
      "Westville",
      "Windsor",
      "Wolfville",
      "Yarmouth",
      "Yarmouth",
    ],
    Nunavut: [
      "Arctic Bay",
      "Arviat",
      "Baker Lake",
      "Cambridge Bay",
      "Cape Dorset",
      "Chesterfield Inlet",
      "Clyde River",
      "Coral Harbour",
      "Gjoa Haven",
      "Grise Fiord",
      "Hall Beach",
      "Igloolik",
      "Iqaluit",
      "Kimmirut",
      "Kugaaruk",
      "Kugluktuk",
      "Naujaat",
      "Pangnirtung",
      "Pond Inlet",
      "Qikiqtarjuaq",
      "Rankin Inlet",
      "Resolute",
      "Sanikiluaq",
      "Taloyoak",
      "Whale Cove",
    ],
    Ontario: [
      "Bruce",
      "Dufferin",
      "Durham",
      "Elgin",
      "Essex",
      "Frontenac",
      "Grey",
      "Haliburton",
      "Halton",
      "Hastings",
      "Huron",
      "Lambton",
      "Lanark",
      "Leeds and Grenville",
      "Lennox and Addington",
      "Middlesex",
      "Muskoka",
      "Niagara",
      "Northumberland",
      "Oxford",
      "Peel",
      "Perth",
      "Peterborough",
      "Prescott and Russell",
      "Renfrew",
      "Simcoe",
      "Stormont, Dundas and Glengarry",
      "Waterloo",
      "Wellington",
      "York",
    ],
    "Prince Edward Island": [
      "Abram-Village",
      "Afton",
      "Alberton",
      "Alexandra",
      "Annandale-Little Pond-Howe Bay",
      "Bedeque and Area",
      "Belfast",
      "Bonshaw",
      "Borden-Carleton",
      "Brackley",
      "Breadalbane",
      "Central Kings",
      "Central Prince",
      "Charlottetown",
      "Clyde River",
      "Cornwall",
      "Crapaud",
      "Darlington",
      "Eastern Kings",
      "Greenmount-Montrose",
      "Hampshire",
      "Hazelbrook",
      "Hunter River",
      "Kensington",
      "Kingston",
      "Kinkora",
      "Linkletter",
      "Lot 11 and Area",
      "Malpeque Bay",
      "Meadowbank",
      "Miltonvale Park",
      "Miminegash",
      "Miscouche",
      "Morell",
      "Mount Stewart",
      "Murray Harbour",
      "Murray River",
      "New Haven-Riverdale",
      "North Rustico",
      "North Shore",
      "North Wiltshire",
      "Northport",
      "O'Leary",
      "Sherbrooke",
      "Souris",
      "Souris West",
      "St. Felix",
      "St. Louis",
      "St. Nicholas",
      "St. Peters Bay",
      "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico",
      "Stratford",
      "Summerside",
      "Three Rivers",
      "Tignish",
      "Tignish Shore",
      "Tyne Valley",
      "Union Road",
      "Victoria",
      "Warren Grove",
      "Wellington",
      "West River",
      "York",
    ],
    Quebec: [
      "Abitibi-Témiscamingue",
      "Bas-Saint-Laurent",
      "Capitale-Nationale",
      "Centre-du-Québec",
      "Chaudière-Appalaches",
      "Côte-Nord",
      "Estrie",
      "Gaspésie–Îles-de-la-Madeleine",
      "Lanaudière",
      "Laurentides",
      "Laval",
      "Mauricie",
      "Montérégie",
      "Montreal",
      "Nord-du-Québec",
      "Outaouais",
      "Saguenay–Lac-Saint-Jean",
    ],
    Saskatchewan: [
      "Abbey",
      "Aberdeen",
      "Abernethy",
      "Alameda",
      "Albertville",
      "Alice Beach",
      "Alida",
      "Allan",
      "Alvena",
      "Annaheim",
      "Aquadeo",
      "Arborfield",
      "Archerwill",
      "Arcola",
      "Arran",
      "Asquith",
      "Assiniboia",
      "Atwater",
      "Avonlea",
      "Aylesbury",
      "Aylsham",
      "Balcarres",
      "Balgonie",
      "Bangor",
      "Battleford",
      "Beatty",
      "Beaver Flat",
      "Beechy",
      "Belle Plaine",
      "Bengough",
      "Bethune",
      "Bienfait",
      "Big River",
      "Big Shell",
      "Biggar",
      "Birch Hills",
      "Bird's Point",
      "Bjorkdale",
      "Bladworth",
      "Blaine Lake",
      "Borden",
      "Bracken",
      "Bradwell",
      "Bredenbury",
      "Briercrest",
      "Broadview",
      "Brock",
      "Broderick",
      "Brownlee",
      "Bruno",
      "B-Say-Tah",
      "Buchanan",
      "Buena Vista",
      "Bulyea",
      "Burstall",
      "Cabri",
      "Cadillac",
      "Calder",
      "Candle Lake",
      "Canora",
      "Canwood",
      "Carievale",
      "Carlyle",
      "Carnduff",
      "Caronport",
      "Carrot River",
      "Central Butte",
      "Ceylon",
      "Chamberlain",
      "Chaplin",
      "Chitek Lake",
      "Choiceland",
      "Chorney Beach",
      "Christopher Lake",
      "Churchbridge",
      "Clavet",
      "Climax",
      "Cochin",
      "Coderre",
      "Codette",
      "Coleville",
      "Colonsay",
      "Conquest",
      "Consul",
      "Coronach",
      "Coteau Beach",
      "Craik",
      "Craven",
      "Creelman",
      "Cudworth",
      "Cupar",
      "Cut Knife",
      "Dalmeny",
      "Davidson",
      "Debden",
      "Delisle",
      "Denholm",
      "Denzil",
      "Dilke",
      "Dinsmore",
      "Disley",
      "Dodsland",
      "Dorintosh",
      "Drake",
      "Drinkwater",
      "Dubuc",
      "Duck Lake",
      "Duff",
      "Dundurn",
      "Duval",
      "Dysart",
      "Earl Grey",
      "Eastend",
      "Eatonia",
      "Ebenezer",
      "Echo Bay",
      "Edam",
      "Edenwold",
      "Elbow",
      "Elfros",
      "Elrose",
      "Endeavour",
      "Englefeld",
      "Ernfold",
      "Esterhazy",
      "Estevan",
      "Eston",
      "Etters Beach",
      "Eyebrow",
      "Fairlight",
      "Fenwood",
      "Fillmore",
      "Findlater",
      "Flaxcombe",
      "Fleming",
      "Flin Flon",
      "Foam Lake",
      "Forget",
      "Fort Qu'Appelle",
      "Fort San",
      "Fosston",
      "Fox Valley",
      "Francis",
      "Frobisher",
      "Frontier",
      "Gainsborough",
      "Gerald",
      "Glaslyn",
      "Glen Ewen",
      "Glen Harbour",
      "Glenavon",
      "Glenside",
      "Golden Prairie",
      "Goodeve",
      "Goodsoil",
      "Goodwater",
      "Govan",
      "Grand Coulee",
      "Grandview Beach",
      "Gravelbourg",
      "Grayson",
      "Greig Lake",
      "Grenfell",
      "Gull Lake",
      "Hafford",
      "Hague",
      "Halbrite",
      "Hanley",
      "Harris",
      "Hawarden",
      "Hazenmore",
      "Hazlet",
      "Hepburn",
      "Herbert",
      "Heward",
      "Hodgeville",
      "Holdfast",
      "Hubbard",
      "Hudson Bay",
      "Humboldt",
      "Hyas",
      "Imperial",
      "Indian Head",
      "Invermay",
      "Island View",
      "Ituna",
      "Jansen",
      "Kamsack",
      "Kannata Valley",
      "Katepwa",
      "Keeler",
      "Kelliher",
      "Kelvington",
      "Kenaston",
      "Kendal",
      "Kennedy",
      "Kenosee Lake",
      "Kerrobert",
      "Killaly",
      "Kincaid",
      "Kindersley",
      "Kinistino",
      "Kinley",
      "Kipling",
      "Kisbey",
      "Kivimaa-Moonlight Bay",
      "Krydor",
      "Kyle",
      "Lafleche",
      "Laird",
      "Lake Lenore",
      "Lampman",
      "Lancer",
      "Landis",
      "Lang",
      "Langenburg",
      "Langham",
      "Lanigan",
      "Lashburn",
      "Leader",
      "Leask",
      "Lebret",
      "Lemberg",
      "Leoville",
      "Leross",
      "Leroy",
      "Leslie Beach",
      "Liberty",
      "Limerick",
      "Lintlaw",
      "Lipton",
      "Lloydminster",
      "Loon Lake",
      "Loreburn",
      "Love",
      "Lucky Lake",
      "Lumsden",
      "Lumsden Beach",
      "Luseland",
      "Macklin",
      "MacNutt",
      "Macoun",
      "Macrorie",
      "Maidstone",
      "Major",
      "Makwa",
      "Manitou Beach",
      "Mankota",
      "Manor",
      "Maple Creek",
      "Marcelin",
      "Marengo",
      "Margo",
      "Markinch",
      "Marquis",
      "Marsden",
      "Marshall",
      "Martensville",
      "Maryfield",
      "Maymont",
      "McLean",
      "McTaggart",
      "Meacham",
      "Meadow Lake",
      "Meath Park",
      "Medstead",
      "Melfort",
      "Melville",
      "Melville Beach",
      "Mendham",
      "Meota",
      "Mervin",
      "Metinota",
      "Midale",
      "Middle Lake",
      "Milden",
      "Milestone",
      "Minton",
      "Mistatim",
      "Mistusinne",
      "Montmartre",
      "Moose Jaw",
      "Moosomin",
      "Morse",
      "Mortlach",
      "Mossbank",
      "Muenster",
      "Naicam",
      "Neilburg",
      "Netherhill",
      "Neudorf",
      "Neville",
      "Nipawin",
      "Nokomis",
      "Norquay",
      "North Battleford",
      "North Grove",
      "North Portal",
      "Odessa",
      "Ogema",
      "Osage",
      "Osler",
      "Outlook",
      "Oxbow",
      "Paddockwood",
      "Pangman",
      "Paradise Hill",
      "Parkside",
      "Paynton",
      "Pebble Baye",
      "Pelican Pointe",
      "Pelly",
      "Pennant",
      "Pense",
      "Perdue",
      "Pierceland",
      "Pilger",
      "Pilot Butte",
      "Pleasantdale",
      "Plenty",
      "Plunkett",
      "Ponteix",
      "Porcupine Plain",
      "Preeceville",
      "Prelate",
      "Prince Albert",
      "Prud'homme",
      "Punnichy",
      "Qu'Appelle",
      "Quill Lake",
      "Quinton",
      "Radisson",
      "Radville",
      "Rama",
      "Raymore",
      "Redvers",
      "Regina",
      "Regina Beach",
      "Rhein",
      "Richard",
      "Richmound",
      "Ridgedale",
      "Riverhurst",
      "Rocanville",
      "Roche Percee",
      "Rockglen",
      "Rose Valley",
      "Rosetown",
      "Rosthern",
      "Rouleau",
      "Ruddell",
      "Rush Lake",
      "Saltcoats",
      "Saskatchewan Beach",
      "Saskatoon",
      "Sceptre",
      "Scott",
      "Sedley",
      "Semans",
      "Senlac",
      "Shamrock",
      "Shaunavon",
      "Sheho",
      "Shell Lake",
      "Shellbrook",
      "Shields",
      "Silton",
      "Simpson",
      "Sintaluta",
      "Smeaton",
      "Smiley",
      "South Lake",
      "Southey",
      "Spalding",
      "Speers",
      "Spiritwood",
      "Springside",
      "Spy Hill",
      "St. Benedict",
      "St. Brieux",
      "St. Gregor",
      "St. Louis",
      "St. Walburg",
      "Star City",
      "Stenen",
      "Stewart Valley",
      "Stockholm",
      "Storthoaks",
      "Stoughton",
      "Strasbourg",
      "Strongfield",
      "Sturgis",
      "Success",
      "Sun Valley",
      "Sunset Cove",
      "Swift Current",
      "Tantallon",
      "Tessier",
      "Theodore",
      "Thode",
      "Tisdale",
      "Tobin Lake",
      "Togo",
      "Tompkins",
      "Torquay",
      "Tramping Lake",
      "Tugaske",
      "Turtle View",
      "Turtleford",
      "Tuxford",
      "Unity",
      "Val Marie",
      "Valparaiso",
      "Vanguard",
      "Vanscoy",
      "Vibank",
      "Viscount",
      "Vonda",
      "Wadena",
      "Wakaw",
      "Wakaw Lake",
      "Waldeck",
      "Waldheim",
      "Waldron",
      "Wapella",
      "Warman",
      "Waseca",
      "Watrous",
      "Watson",
      "Wawota",
      "Webb",
      "Wee Too Beach",
      "Weekes",
      "Weirdale",
      "Weldon",
      "West End",
      "Weyburn",
      "White City",
      "White Fox",
      "Whitewood",
      "Wilcox",
      "Wilkie",
      "Willow Bunch",
      "Windthorst",
      "Wiseton",
      "Wolseley",
      "Wood Mountain",
      "Wynyard",
      "Yarbo",
      "Yellow Grass",
      "Yorkton",
      "Young",
      "Zealandia",
      "Zelma",
      "Zenon Park",
    ],
    Yukón: [
      "Pueblo de Watson Lake solamente",
      "Beaver Creek",
      "Burwash Landing",
      "Carcross4",
      "Carmacks",
      "Dawson City",
      "Destruction Bay",
      "Faro",
      "Haines Junction",
      "Ibex Valley1",
      "Marsh Lake1",
      "Mayo",
      "Mt. Lorne1",
      "Old Crow",
      "Pelly Crossing",
      "Ross River",
      "Tagish",
      "Teslin3",
      "Watson Lake2",
      "Whitehorse",
      "Whitehorse",
    ],
  },
  Camerún: {
    Adamaoua: ["Djérem", "Faro", "Mayo-Banyo", "Mbéré"],
    "Región / del / Centro": [
      "Haute-Sanaga",
      "Lekié",
      "Mbam-et-Inoubou",
      "Mbam-et-Kim",
      "Méfou-et-Afamba",
      "Méfou-et-Akono",
      "Nyong-et-Kéllé",
      "Nyong-et-Mfoumou",
      "Nyong-et-So'o",
      "Nyong-et-Mfoumou",
    ],
    "Región / del / Este": [
      "Boumba-et-Ngoko",
      "Haut-Nyong",
      "Kadey",
      "Lom-et-Djérem",
    ],
    "Región del Extremo Norte": [
      "Diamaré",
      "Logone-et-Chari",
      "Mayo-Danay",
      "Mayo-Kani",
      "Mayo-Sava",
    ],
    "Región del Litoral": ["Moungo", "Nkam", "Sanaga-Maritime", "Wouri"],
    "Región del Norte": [
      "Bénoué",
      "Faro",
      "Mayo-Louti",
      "Mayo-Rey",
      "Mayo-Tsanaga",
    ],
    "Región del Noroeste": [
      "Bui",
      "Donga-Mantung",
      "Menchum",
      "Mezam",
      "Momo",
      "Ngo-Ketunjia",
    ],
    "Región del Oeste": [
      "Bamboutos",
      "Haut-Nkam",
      "Hauts-Plateaux",
      "Koung-Khi",
      "Menoua",
      "Mifi",
      "Ndé",
      "Noun",
      "Penja",
    ],
    "Región del Sur": ["Dja-et-Lobo", "Mvila", "Océan"],
    "Región del Suroeste": [
      "Fako",
      "Kupe-Manenguba",
      "Lebialem",
      "Manyu",
      "Meme",
      "Ndian",
    ],
  },
  Chile: {
    "Antártica Chilena": ["Magallanes", "Tierra del Fuego", "Última Esperanza"],
    Antofagasta: ["Antofagasta", "El Loa", "Tocopilla"],
    Araucanía: ["Cautín", "Malleco"],
    "Arica y Parinacota": ["Arica", "Parinacota"],
    Atacama: ["Chañaral", "Copiapó", "Huasco"],
    "Aysén del General": ["Aysén"],
    "Bernardo O'Higgins": ["Cardenal Caro", "Colchagua"],
    Biobío: ["Arauco", "Biobío", "Concepción"],
    "Carlos Ibáñez del Campo": ["Capitán Prat", "Coyhaique", "General Carrera"],
    Coquimbo: ["Choapa", "Elqui", "Limarí"],
    "Libertador General": ["Cachapoal"],
    "Los Lagos": ["Chiloé", "Llanquihue", "Osorno", "Palena"],
    "Los Ríos": ["Ranco", "Valdivia"],
    "Magallanes y de la Antártica Chilena": ["Antártica Chilena"],
    Maule: ["Cauquenes", "Curicó", "Linares", "Talca"],
    "Metropolitana de Santiago": [
      "Chacabuco",
      "Cordillera",
      "Maipo",
      "Melipilla",
      "Santiago",
      "Talagante",
    ],
    Ñuble: ["Diguillín", "Itata", "Punilla"],
    Tarapacá: ["Iquique", "Tamarugal"],
    Valparaíso: [
      "Isla de Pascua",
      "Los Andes",
      "Marga Marga",
      "Petorca",
      "Quillota",
      "San Antonio",
      "San Felipe de Aconcagua",
      "Valparaíso",
    ],
  },
  "China / Zhōngguó": {
    Ānhuī: [
      "Héféi Shì",
      "Ānqìng Shì",
      "Bèngbù Shì",
      "Bózhōu Shì",
      "Xuānchéng Shì",
      "Chízhōu Shì",
      "Chúzhōu Shì",
      "Fǔyáng Shì",
      "Huáiběi Shì",
      "Huáinán Shì",
      "Huángshān Shì",
      "Lù'ān Shì",
      "Mǎ'ānshān Shì",
      "Sùzhōu Shì",
      "Tónglíng Shì",
      "Wúhú Shì",
    ],
    Àomén: ["Àomén"],
    Běijīng: [
      "Dōngchéng Qū",
      "Xīchéng Qū",
      "Cháoyáng Qū",
      "Hǎidiàn Qū",
      "Fēngtái Qū",
      "Shíjǐngshān Qū",
      "Méntóugōu Qū",
      "Fángshān Qū",
      "Tōngzhōu Qū",
      "Shùnyì Qū",
      "Chāngpíng Qū",
      "Dàxīng Qū",
      "Pínggǔ Qū",
      "Huáiróu Qū",
      "Mìyún Qū",
      "Yánqìng Qū",
    ],
    Chóngqìng: [
      "Banan",
      "de Qianjiang",
      "Distrito Changshou",
      "Distrito Shapingba",
      "Shuangqiao",
      "Wansheng",
      "Distrito Wanzhou",
      "Yubei",
      "Yongchuan",
      "Distrito Yuzhong",
      "Distrito Beibei",
      "Dadukou",
      "Distrito Fuling",
      "Distrito Hechuan",
      "Distrito Jiangbei",
      "Distrito Jiangjin",
      "Distrito Jiulongpo",
      "de Nan'an",
      "Nanchuan",
      "Qijiang",
      "Tongliang",
      "Bishan",
      "Chengkou",
      "Dazu",
      "Dianjiang",
      "Fengdu",
      "Fengjie",
      "Kai",
      "Liangping",
      "Rongchang",
      "Tongliang",
      "Tongnan",
      "Wulong",
      "Wushan",
      "Wuxi",
      "Yunyang",
      "Zhong",
      "Pengshui",
      "Shizhu",
      "Xiushan",
      "Youyang",
    ],
    Fújiàn: [
      "Xiàmén Shì",
      "Fúzhōu Shì",
      "Lóngyán Shì",
      "Nánpíng Shì",
      "Níngdé shì",
      "Pútián Shì",
      "Quánzhōu Shì",
      "Sānmíng Shì",
      "Zhāngzhōu Shì",
    ],
    Gānsù: [
      "Jiǔquán Shì",
      "Jiāyùguān Shì",
      "Zhāngyè Shì",
      "Jiǔquán Shì",
      "Wǔwēi Shì",
      "Báiyín Shì",
      "Lánzhōu Shì",
      "Línxià Huízú Zìzhìzhōu",
      "Gānnán Zāngzú Zìzhìzhōu",
      "Dìngxī Shì",
      "Lǒngnán Shì",
      "Tiānshuǐ Shì",
      "Píngliàng Shì",
      "Qìngyáng Shì",
    ],
    Guǎngdōng: [
      "Qīngyuǎn Shì",
      "Sháoguān Shì",
      "Héyuán Shì",
      "Méizhōu Shì",
      "Cháozhōu Shì",
      "Zhàoqìng Shì",
      "Yúnfú Shì",
      "Fóshān Shì",
      "Guǎngzhōu Shì",
      "Dōngguǎn Shì",
      "Hùizhōu Shì",
      "Shànwěi Shì",
      "Jiēyáng Shì",
      "Shàntóu Shì",
      "Zhànjiāng Shì",
      "Màomíng Shì",
      "Yángjiāng Shì",
      "Jiāngmén Shì",
      "Zhōngshān Shì",
      "Zhūhǎi Shì",
      "Shēnzhèn Shì",
    ],
    Guǎngxī: [
      "Bǎisè Shì",
      "Héchí Shì",
      "Liǔzhōu Shì",
      "Guìlín Shì",
      "Hézhōu Shì",
      "Chóngzuǒ Shì",
      "Nánníng Shì",
      "Láibīn Shì",
      "Guìgǎng Shì",
      "Wúzhōu Shì",
      "Fángchénggǎng Shì",
      "Qīnzhōu Shì",
      "Běihǎi Shì",
      "Yùlín Shì",
    ],
    Gùizhōu: [
      "Bìjíe Shì",
      "Zūnyì Shì",
      "Tóngrén Shì",
      "Liùpánshuǐ Shì",
      "Ānshùn Shì",
      "Guìyáng Shì",
      "Qiánxī'nán Bùyīzú Miáozú Zìzhìzhōu",
      "Qiánnán Bùyīzú Miáozú Zìzhìzhōu",
      "Qiándōngnán Miáozú Dòngzú Zìzhìzhōu",
    ],
    Hǎinán: [
      "Hǎikǒu Shì",
      "Sānyà Shì",
      "Dānzhōu Shì",
      "Sānshā Shì",
      "Qiónghǎi Shì",
      "Wànníng Shì",
      "Wǔzhǐshān Shì",
      "Dōngfāng Shì",
      "Wénchāng Shì",
      "Língāo Xiàn",
      "Chéngmài Xiàn",
      "Dìng'ān Xiàn",
      "Túnchāng Xiàn",
      "Chāngjiāng Lízú Zìzhìxiàn",
      "Báishā Lízú Zìzhìxiàn",
      "Qióngzhōng Lízú Miáozú Zìzhìxiàn",
      "Língshuǐ Lízú Zìzhìxiàn",
      "Bǎotíng Lízú Miáozú Zìzhìxiàn",
      "Lèdōng Lízú Zìzhìxiàn",
      "Yángpǔ Jīngjì Kāifā Qū",
    ],
    Héběi: [
      "Shíjiāzhuāng Shì",
      "Bǎodìng Shì",
      "Cāngzhōu Shì",
      "Chéngdé Shì",
      "Hándān Shì",
      "Héngshǔi Shì",
      "Lángfāng Shì",
      "Qínhuángdǎo Shì",
      "Tángshān Shì",
      "Xíngtái Shì",
      "Zhāngjiākǒu Shì",
    ],
    Hēilóngjiāng: [
      "Hā'ěrbīn Shì",
      "Dàqìng Shì",
      "Hègǎng Shì",
      "Hēihé Shì",
      "Jiāmùsī Shì",
      "Jīxī Shì",
      "Mǔdānjiāng Shì",
      "Qíqíhā'ěr Shì",
      "Qītáihé Shì",
      "Shuāngyāshān Shì",
      "Suíhuà Shì",
      "Yīchūn Shì",
      "Dàxīng'ānlǐng Dìqū",
    ],
    Hénán: [
      "Zhèngzhōu Shì",
      "Ānyáng Shì",
      "Hèbì Shì",
      "Jiāozuò Shì",
      "Kāifēng Shì",
      "Luòhé Shì",
      "Luòyáng Shì",
      "Nányáng Shì",
      "Píngdǐngshān Shì",
      "Púyáng Shì",
      "Sānménxiá Shì",
      "Shāngqiū Shì",
      "Xīnxiāng Shì",
      "Xìnyáng Shì",
      "Xǔchāng Shì",
      "Zhōukǒu Shì",
      "Zhùmǎdiàn Shì",
      "Jǐyuán Shì",
    ],
    Húběi: [
      "Wǔhàn Shì",
      "Èzhōu Shì",
      "Huánggāng Shì",
      "Huángshí Shì",
      "Jīngmén Shì",
      "Jīngzhōu Shì",
      "Shíyàn Shì",
      "Suízhōu Shì",
      "Xiāngyáng Shì",
      "Xiánníng Shì",
      "Xiàogǎn Shì",
      "Yíchāng Shì",
      "Ēnshī Tǔjiāzú Miáozú Zìzhìzhōu",
      "Tiānmén",
      "Qiánjiāng",
      "Xiāntáo",
      "Shénnóngjià Línqū",
    ],
    Húnán: [
      "Chángshā Shì",
      "Chángdé Shì",
      "Chénzhōu Shì",
      "Héngyáng Shì",
      "Huáihuà Shì",
      "Lóudǐ Shì",
      "Shàoyáng Shì",
      "Xiāngtán Shì",
      "Yìyáng Shì",
      "Yǒngzhōu Shì",
      "Yuèyáng Shì",
      "Zhāngjiājiè Shì",
      "Zhūzhōu Shì",
      "Xiāngxī Tǔjiāzú Miáozú Zìzhìzhōu",
    ],
    Jiāngsū: [
      "Nánjīng Shì",
      "Chángzhōu Shì",
      "Huái'ān Shì",
      "Liányúngǎng Shì",
      "Nántōng Shì",
      "Sùqiān Shì",
      "Sūzhōu Shì",
      "Tàizhōu Shì",
      "Wúxī Shì",
      "Xúzhōu Shì",
      "Yánchéng Shì",
      "Yángzhōu Shì",
      "Zhènjiāng Shì",
    ],
    Jiāngxī: [
      "Nánchāng Shì",
      "Fǔzhōu Shì",
      "Gànzhōu Shì",
      "Jí'ān Shì",
      "Jǐngdézhèn Shì",
      "Jiǔjiāng Shì",
      "Píngxiāng Shì",
      "Shàngráo Shì",
      "Xīnyú Shì",
      "Yíchūn Shì",
      "Yīngtán Shì",
    ],
    Jílín: [
      "Chángchūn Shì",
      "Báichéng Shì",
      "Báishān Shì",
      "Jílín Shì",
      "Liáoyuán Shì",
      "Sìpíng Shì",
      "Sōngyuán Shì",
      "Tōnghuà Shì",
      "Yánbiān Cháoxiǎnzú Zìzhìzhōu",
    ],
    Liáoníng: [
      "Shěnyáng Shì",
      "Dàlián Shì",
      "Ānshān Shì",
      "Běnxī Shì",
      "Cháoyáng Shì",
      "Dāndōng Shì",
      "Fǔshùn Shì",
      "Fùxīn Shì",
      "Húludǎo Shì",
      "Jǐnzhōu Shì",
      "Liáoyáng Shì",
      "Pánjǐn Shì",
      "Tiělǐng Shì",
      "Yíngkǒu shì",
    ],
    Nèiměnggǔ: [
      "Hūhéhàotè shì",
      "Bāotóu shì",
      "Wūhǎi shì",
      "Chìfēng shì",
      "Tōngliáo shì",
      "È'ěrduōsī shì",
      "Hūlúnbèi'ěr shì",
      "Bāyànnào'ěr shì",
      "Wūlánchábù shì",
      "Xīlínguōlè méng",
      "Ālāshàn méng",
      "Xīng'ān méng",
    ],
    Níngxià: [
      "Yínchuān Shì",
      "Shízuǐshān Shì",
      "Wúzhōng Shì",
      "Zhōngwèi Shì",
      "Gùyuán Shì",
    ],
    Qīnghǎi: [
      "Xīníng Shì",
      "Hǎidōng Dìqū",
      "Hǎixī Měnggǔzú Zàngzú Zìzhìzhōu",
      "Hǎiběi Zàngzú Zìzhìzhōu",
      "Hǎinán Zàngzú Zìzhìzhōu",
      "Huángnán Zàngzú Zìzhìzhōu",
      "Yùshù Zàngzú Zìzhìzhōu",
      "Guǒluò Zàngzú Zìzhìzhōu",
    ],
    Shāndōng: [
      "Jǐnán Shì",
      "Qīngdǎo Shì",
      "Bīnzhōu Shì",
      "Dézhōu Shì",
      "Dōngyíng Shì",
      "Hézé Shì",
      "Jìníng Shì",
      "Láiwú Shì",
      "Liáochéng Shì",
      "Línyí Shì",
      "Rìzhào Shì",
      "Tài'ān Shì",
      "Wéifāng Shì",
      "Wēihǎi Shì",
      "Yāntái Shì",
      "Zǎozhuāng Shì",
      "Zībó Shì",
    ],
    Shànghǎi: [
      "Huángpǔ Qū",
      "Xúhuì Qū",
      "Chángníng Qū",
      "Jìng'ān Qū",
      "Pǔtuó Qū",
      "Zháběi Qū",
      "Hóngkǒu qū",
      "Yángpǔ Qū",
      "Bǎoshān Qū",
      "Mǐnháng Qū",
      "Jiādìng Qū",
      "Jīnshān Qū",
      "Sōngjiāng Qū",
      "Qīngpǔ Qū",
      "Fèngxián Qū",
    ],
    Shǎnxī: [
      "Xī'ān Shì",
      "Ānkāng Shì",
      "Bǎojī Shì",
      "Hànzhōng Shì",
      "Shāngluò Shì",
      "Tóngchuān Shì",
      "Wèinán Shì",
      "Xiányáng Shì",
      "Yán'ān Shì",
      "Yúlín Shì",
    ],
    Shānxī: [
      "Tàiyuán Shì",
      "Chángzhì Shì",
      "Dàtóng Shì",
      "Jìnchéng Shì",
      "Jìnzhōng Shì",
      "Línfén Shì",
      "Lǚliáng Shì",
      "Shuòzhōu Shì",
      "Xīnzhōu Shì",
      "Yángquán Shì",
      "Yùnchéng Shì",
    ],
    Sìchuān: [
      "Gānzī Zàngzú Zìzhìzhōu",
      "Ābà Zàngzú Qiāngzú Zìzhìzhōu",
      "Miányáng Shì",
      "Gǔangyúan Shì",
      "Nánchōng Shì",
      "Bāzhōng Shì",
      "Dázhōu Shì",
      "Yǎ'ān Shì",
      "Chéngdū Shì",
      "Déyáng Shì",
      "Sùiníng Shì",
      "Guǎng'ān Shì",
      "Méishān Shì",
      "Zīyáng Shì",
      "Lèshān Shì",
      "Nèijiāng Shì",
      "Zìgòng Shì",
      "Yíbīn Shì",
      "Lúzhōu Shì",
      "Liángshān Yízú Zìzhìzhōu",
      "Pānzhīhūa Shì",
    ],
    Tiānjīn: [
      "Heping",
      "Hebei",
      "Hedong",
      "Hexi",
      "Nankai",
      "Hongqiao",
      "Jinnan",
      "Dongli",
      "Xiqing ",
      "Beichen ",
      "Baodi ",
      "Wuqing",
      "Jizhou",
      "Jinghai",
      "Ninghe",
      "Binhái",
    ],
    Xiānggǎng: ["Xiānggǎng"],
    Xīnjiāng: [
      "Wūlǔmùqí Shì",
      "Kèlāmǎyī Shì",
      "Shíhézǐ Shì",
      "Túmùshūkè Shì",
      "Ālā'ěr Shì",
      "Wǔjiāqú Shì",
      "Tǔlǔfān Dìqū",
      "Hāmì Dìqū",
      "Hétián Dìqū",
      "Ākèsū Dìqū",
      "Kāshí Dìqū",
      "Tǎchéng Dìqū",
      "Ālètài Dìqū",
      "Kèzīlèsū Kē'ěrkèzī Zìzhìzhōu",
      "Bāyīnguōlèng Měnggǔ Zìzhìzhōu",
      "Chāngjí Huízú Zìzhìzhōu",
      "Bó'ěrtǎlā Měnggǔ Zìzhìzhōu",
      "Yīlí Hāsàkè Zìzhìzhōu",
    ],
    Xīzàng: [
      "Ālǐ Dìqū",
      "Nàqū Dìqū",
      "Chāngdū Shì",
      "Rìkāzé Shì",
      "Lāsà Shì",
      "Shānnán Dìqū",
      "Línzhī Shì",
    ],
    Yúnnán: [
      "Kūnmíng Shì",
      "Qǔjìng Shì",
      "Yùxī Shì",
      "Bǎoshān Shì",
      "Zhāotōng Shì",
      "Lìjiāng Shì",
      "Qǔjìng Shì",
      "Líncāng Shì",
      "Déhóng Dǎizú Jǐngpōzú Zìzhìzhōu",
      "Nùjiāng Lìsùzú Zìzhìzhōu",
      "Díqìng Zàngzú Zìzhìzhōu",
      "Dàlǐ Báizú Zìzhìzhōu",
      "Chǔxióng Yízú Zìzhìzhōu",
      "Hónghé Hānízú Yízú Zìzhìzhōu",
      "Wénshān Zhuàngzú Miáozú Zìzhìzhōu",
      "Xīshuāngbǎnnà Dǎizú Zìzhìzhōu",
    ],
    Zhèjiāng: [
      "Hángzhōu Shì",
      "Níngbō Shì",
      "Húzhōu Shì Shì",
      "Jiāxīng Shì Shì",
      "Jīnhuá Shì",
      "Líshuǐ Shì",
      "Qúzhōu Shì",
      "Shàoxīng Shì",
      "Tāizhōu Shì",
      "Wēnzhōu Shì",
      "Zhōushān Shì",
    ],
  },
  Colombia: {
    Amazonas: [
      "El Encanto",
      "La Chorrera",
      "La Pedrera",
      "La Victoria",
      "Leticia",
      "Miriti Paraná",
      "Puerto Alegría",
      "Puerto Arica",
      "Puerto Nariño",
      "Puerto Santander",
      "Tarapacá",
    ],

    Antioquia: [
      "Abejorral",
      "Abriaquí",
      "Alejandría",
      "Amagá",
      "Amalfi",
      "Andes",
      "Angelópolis",
      "Angostura",
      "Anorí",
      "Anza",
      "Apartadó",
      "Arboletes",
      "Argelia",
      "Armenia",
      "Barbosa",
      "Bello",
      "Belmira",
      "Betania",
      "Betulia",
      "Briceño",
      "Buriticá",
      "Cáceres",
      "Caicedo",
      "Caldas",
      "Campamento",
      "Cañasgordas",
      "Caracolí",
      "Caramanta",
      "Carepa",
      "Carolina",
      "Caucasia",
      "Chigorodó",
      "Cisneros",
      "Ciudad Bolívar",
      "Cocorná",
      "Concepción",
      "Concordia",
      "Copacabana",
      "Dabeiba",
      "Don Matías",
      "Ebéjico",
      "El Bagre",
      "El Carmen de Viboral",
      "El Santuario",
      "Entrerrios",
      "Envigado",
      "Fredonia",
      "Frontino",
      "Giraldo",
      "Girardota",
      "Gómez Plata",
      "Granada",
      "Guadalupe",
      "Guarne",
      "Guatapé",
      "Heliconia",
      "Hispania",
      "Itagui",
      "Ituango",
      "Jardín",
      "Jericó",
      "La Ceja",
      "La Estrella",
      "La Pintada",
      "La Unión",
      "Liborina",
      "Maceo",
      "Marinilla",
      "Medellín",
      "Montebello",
      "Murindó",
      "Mutatá",
      "Nariño",
      "Nechí",
      "Necoclí",
      "Olaya",
      "Peñol",
      "Peque",
      "Pueblorrico",
      "Puerto Berrío",
      "Puerto Nare",
      "Puerto Triunfo",
      "Remedios",
      "Retiro",
      "Rionegro",
      "Sabanalarga",
      "Sabaneta",
      "Salgar",
      "San Andrés de Cuerquía",
      "San Carlos",
      "San Francisco",
      "San Jerónimo",
      "San José de La Montaña",
      "San Juan de Urabá",
      "San Luis",
      "San Pedro",
      "San Pedro de Uraba",
      "San Rafael",
      "San Roque",
      "San Vicente",
      "Santa Bárbara",
      "Santa Rosa de Osos",
      "Santafé de Antioquia",
      "Santo Domingo",
      "Segovia",
      "Sonsón",
      "Sopetrán",
      "Támesis",
      "Tarazá",
      "Tarso",
      "Titiribí",
      "Toledo",
      "Turbo",
      "Uramita",
      "Urrao",
      "Valdivia",
      "Valparaíso",
      "Vegachí",
      "Venecia",
      "Vigía del Fuerte",
      "Yalí",
      "Yarumal",
      "Yolombó",
      "Yondó",
      "Zaragoza",
    ],
    Arauca: [
      "Arauca",
      "Arauquita",
      "Cravo Norte",
      "Fortul",
      "Puerto Rondón",
      "Saravena",
      "Tame",
    ],
    "Archipiélago de San Andrés, Providencia y Santa Catalina": [
      "Providencia",
      "San Andrés",
    ],
    Atlántico: [
      "Baranoa",
      "Barranquilla",
      "Campo de La Cruz",
      "Candelaria",
      "Galapa",
      "Juan de Acosta",
      "Luruaco",
      "Malambo",
      "Manatí",
      "Palmar de Varela",
      "Piojó",
      "Polonuevo",
      "Ponedera",
      "Puerto Colombia",
      "Repelón",
      "Sabanagrande",
      "Sabanalarga",
      "Santa Lucía",
      "Santo Tomás",
      "Soledad",
      "Suan",
      "Tubará",
      "Usiacurí",
    ],
    "Bogotá D.C.": ["Bogotá D.C."],
    Bolívar: [
      "Achí",
      "Altos del Rosario",
      "Arenal",
      "Arjona",
      "Arroyohondo",
      "Barranco de Loba",
      "Calamar",
      "Cantagallo",
      "Cartagena",
      "Cicuco",
      "Clemencia",
      "Córdoba",
      "El Carmen de Bolívar",
      "El Guamo",
      "El Peñón",
      "Hatillo de Loba",
      "Magangué",
      "Mahates",
      "Margarita",
      "María la Baja",
      "Mompós",
      "Montecristo",
      "Morales",
      "Norosí",
      "Pinillos",
      "Regidor",
      "Río Viejo",
      "San Cristóbal",
      "San Estanislao",
      "San Fernando",
      "San Jacinto",
      "San Jacinto del Cauca",
      "San Juan Nepomuceno",
      "San Martín de Loba",
      "San Pablo de Borbur",
      "Santa Catalina",
      "Santa Rosa",
      "Santa Rosa del Sur",
      "Simití",
      "Soplaviento",
      "Talaigua Nuevo",
      "Tiquisio",
      "Turbaco",
      "Turbaná",
      "Villanueva",
      "Zambrano",
    ],
    Boyacá: [
      "Almeida",
      "Aquitania",
      "Arcabuco",
      "Belén",
      "Berbeo",
      "Betéitiva",
      "Boavita",
      "Boyacá",
      "Briceño",
      "Buena Vista",
      "Busbanzá",
      "Caldas",
      "Campohermoso",
      "Cerinza",
      "Chinavita",
      "Chiquinquirá",
      "Chíquiza",
      "Chiscas",
      "Chita",
      "Chitaraque",
      "Chivatá",
      "Chivor",
      "Ciénega",
      "Cómbita",
      "Coper",
      "Corrales",
      "Covarachía",
      "Cubará",
      "Cucaita",
      "Cuítiva",
      "Duitama",
      "El Cocuy",
      "El Espino",
      "Firavitoba",
      "Floresta",
      "Gachantivá",
      "Gameza",
      "Garagoa",
      "Guacamayas",
      "Guateque",
      "Guayatá",
      "Güicán",
      "Iza",
      "Jenesano",
      "Jericó",
      "La Capilla",
      "La Uvita",
      "La Victoria",
      "Labranzagrande",
      "Macanal",
      "Maripí",
      "Miraflores",
      "Mongua",
      "Monguí",
      "Moniquirá",
      "Motavita",
      "Muzo",
      "Nobsa",
      "Nuevo Colón",
      "Oicatá",
      "Otanche",
      "Pachavita",
      "Páez",
      "Paipa",
      "Pajarito",
      "Panqueba",
      "Pauna",
      "Paya",
      "Paz de Río",
      "Pesca",
      "Pisba",
      "Puerto Boyacá",
      "Quípama",
      "Ramiriquí",
      "Ráquira",
      "Rondón",
      "Saboyá",
      "Sáchica",
      "Samacá",
      "San Eduardo",
      "San José de Pare",
      "San Luis de Gaceno",
      "San Mateo",
      "San Miguel de Sema",
      "San Pablo de Borbur",
      "Santa María",
      "Santa Rosa de Viterbo",
      "Santa Sofía",
      "Santana",
      "Sativanorte",
      "Sativasur",
      "Siachoque",
      "Soatá",
      "Socha",
      "Socotá",
      "Sogamoso",
      "Somondoco",
      "Sora",
      "Soracá",
      "Sotaquirá",
      "Susacón",
      "Sutamarchán",
      "Sutatenza",
      "Tasco",
      "Tenza",
      "Tibaná",
      "Tibasosa",
      "Tinjacá",
      "Tipacoque",
      "Toca",
      "Togüí",
      "Tópaga",
      "Tota",
      "Tunja",
      "Tununguá",
      "Turmequé",
      "Tuta",
      "Tutazá",
      "Umbita",
      "Ventaquemada",
      "Villa de Leyva",
      "Viracachá",
      "Zetaquira",
    ],
    Caldas: [
      "Aguadas",
      "Anserma",
      "Aranzazu",
      "Belalcázar",
      "Chinchiná",
      "Filadelfia",
      "La Dorada",
      "La Merced",
      "Manizales",
      "Manzanares",
      "Marmato",
      "Marquetalia",
      "Marulanda",
      "Neira",
      "Norcasia",
      "Pácora",
      "Palestina",
      "Pensilvania",
      "Riosucio",
      "Risaralda",
      "Salamina",
      "Samaná",
      "San José",
      "Supía",
      "Victoria",
      "Villamaría",
      "Viterbo",
    ],
    Caquetá: [
      "Albania",
      "Belén de Los Andaquies",
      "Cartagena del Chairá",
      "Curillo",
      "El Doncello",
      "El Paujil",
      "Florencia",
      "La Montañita",
      "Milán",
      "Morelia",
      "Puerto Rico",
      "San José del Fragua",
      "San Vicente del Caguán",
      "Solano",
      "Solita",
      "Valparaíso",
    ],
    Casanare: [
      "Aguazul",
      "Chámeza",
      "Hato Corozal",
      "La Salina",
      "Maní",
      "Monterrey",
      "Nunchía",
      "Orocué",
      "Paz de Ariporo",
      "Pore",
      "Recetor",
      "Sabanalarga",
      "Sácama",
      "San Luis de Gaceno",
      "Támara",
      "Tauramena",
      "Trinidad",
      "Villanueva",
      "Yopal",
    ],
    Cauca: [
      "Almaguer",
      "Argelia",
      "Balboa",
      "Bolívar",
      "Buenos Aires",
      "Cajibío",
      "Caldono",
      "Caloto",
      "Corinto",
      "El Tambo",
      "Florencia",
      "Guachené",
      "Guapi",
      "Inzá",
      "Jambaló",
      "La Sierra",
      "La Vega",
      "López",
      "Mercaderes",
      "Miranda",
      "Morales",
      "Padilla",
      "Páez",
      "Patía",
      "Piamonte",
      "Piendamó",
      "Popayán",
      "Puerto Tejada",
      "Puracé",
      "Rosas",
      "San Sebastián",
      "Santa Rosa",
      "Santander de Quilichao",
      "Silvia",
      "Sotara",
      "Suárez",
      "Sucre",
      "Timbío",
      "Timbiquí",
      "Toribio",
      "Totoró",
      "Villa Rica",
    ],
    Cesar: [
      "Aguachica",
      "Agustín Codazzi",
      "Astrea",
      "Becerril",
      "Bosconia",
      "Chimichagua",
      "Chiriguaná",
      "Curumaní",
      "El Copey",
      "El Paso",
      "Gamarra",
      "González",
      "La Gloria",
      "La Jagua de Ibirico",
      "La Paz",
      "Manaure",
      "Pailitas",
      "Pelaya",
      "Pueblo Bello",
      "Río de Oro",
      "San Alberto",
      "San Diego",
      "San Martín",
      "Tamalameque",
      "Valledupar",
    ],
    Chocó: [
      "Acandí",
      "Alto Baudo",
      "Atrato",
      "Bagadó",
      "Bahía Solano",
      "Bajo Baudó",
      "Belén de Bajira",
      "Bojaya",
      "Carmen del Darien",
      "Cértegui",
      "Condoto",
      "El Cantón del San Pablo",
      "El Carmen de Atrato",
      "El Litoral del San Juan",
      "Istmina",
      "Juradó",
      "Lloró",
      "Medio Atrato",
      "Medio Baudó",
      "Medio San Juan",
      "Nóvita",
      "Nuquí",
      "Quibdó",
      "Río Iro",
      "Río Quito",
      "Riosucio",
      "San José del Palmar",
      "Sipí",
      "Tadó",
      "Unguía",
      "Unión Panamericana",
    ],
    Córdoba: [
      "Ayapel",
      "Buenavista",
      "Canalete",
      "Cereté",
      "Chimá",
      "Chinú",
      "Ciénaga de Oro",
      "Cotorra",
      "La Apartada",
      "Lorica",
      "Los Córdobas",
      "Momil",
      "Montelíbano",
      "Montería",
      "Moñitos",
      "Planeta Rica",
      "Pueblo Nuevo",
      "Puerto Escondido",
      "Puerto Libertador",
      "Purísima",
      "Sahagún",
      "San Andrés Sotavento",
      "San Antero",
      "San Bernardo del Viento",
      "San Carlos",
      "San José de Uré",
      "San Pelayo",
      "Tierralta",
      "Tuchín",
      "Valencia",
    ],
    Cundinamarca: [
      "Agua de Dios",
      "Albán",
      "Anapoima",
      "Anolaima",
      "Apulo",
      "Arbeláez",
      "Beltrán",
      "Bituima",
      "Bojacá",
      "Cabrera",
      "Cachipay",
      "Cajicá",
      "Caparrapí",
      "Caqueza",
      "Carmen de Carupa",
      "Chaguaní",
      "Chía",
      "Chipaque",
      "Choachí",
      "Chocontá",
      "Cogua",
      "Cota",
      "Cucunubá",
      "El Colegio",
      "El Peñón",
      "El Rosal",
      "Facatativá",
      "Fomeque",
      "Fosca",
      "Funza",
      "Fúquene",
      "Fusagasugá",
      "Gachala",
      "Gachancipá",
      "Gachetá",
      "Gama",
      "Girardot",
      "Granada",
      "Guachetá",
      "Guaduas",
      "Guasca",
      "Guataquí",
      "Guatavita",
      "Guayabal de Siquima",
      "Guayabetal",
      "Gutiérrez",
      "Jerusalén",
      "Junín",
      "La Calera",
      "La Mesa",
      "La Palma",
      "La Peña",
      "La Vega",
      "Lenguazaque",
      "Macheta",
      "Madrid",
      "Manta",
      "Medina",
      "Mosquera",
      "Nariño",
      "Nemocón",
      "Nilo",
      "Nimaima",
      "Nocaima",
      "Pacho",
      "Paime",
      "Pandi",
      "Paratebueno",
      "Pasca",
      "Puerto Salgar",
      "Pulí",
      "Quebradanegra",
      "Quetame",
      "Quipile",
      "Ricaurte",
      "San Antonio del Tequendama",
      "San Bernardo",
      "San Cayetano",
      "San Francisco",
      "San Juan de Río Seco",
      "Sasaima",
      "Sesquilé",
      "Sibaté",
      "Silvania",
      "Simijaca",
      "Soacha",
      "Sopó",
      "Subachoque",
      "Suesca",
      "Supatá",
      "Susa",
      "Sutatausa",
      "Tabio",
      "Tausa",
      "Tena",
      "Tenjo",
      "Tibacuy",
      "Tibirita",
      "Tocaima",
      "Tocancipá",
      "Topaipí",
      "Ubalá",
      "Ubaque",
      "Une",
      "Útica",
      "Venecia",
      "Vergara",
      "Vianí",
      "Villa de San Diego de Ubate",
      "Villagómez",
      "Villapinzón",
      "Villeta",
      "Viotá",
      "Yacopí",
      "Zipacón",
      "Zipaquirá",
    ],
    Guainía: [
      "Barranco Minas",
      "Cacahual",
      "Inírida",
      "La Guadalupe",
      "Mapiripana",
      "Morichal",
      "Pana Pana",
      "Puerto Colombia",
      "San Felipe",
    ],
    Guaviare: ["Calamar", "El Retorno", "Miraflores", "San José del Guaviare"],
    Huila: [
      "Acevedo",
      "Agrado",
      "Aipe",
      "Algeciras",
      "Altamira",
      "Baraya",
      "Campoalegre",
      "Colombia",
      "Elías",
      "Garzón",
      "Gigante",
      "Guadalupe",
      "Hobo",
      "Iquira",
      "Isnos",
      "La Argentina",
      "La Plata",
      "Nátaga",
      "Neiva",
      "Oporapa",
      "Paicol",
      "Palermo",
      "Palestina",
      "Pital",
      "Pitalito",
      "Rivera",
      "Saladoblanco",
      "San Agustín",
      "Santa María",
      "Suaza",
      "Tarqui",
      "Tello",
      "Teruel",
      "Tesalia",
      "Timaná",
      "Villavieja",
      "Yaguará",
    ],
    "La Guajira": [
      "Albania",
      "Barrancas",
      "Dibula",
      "Distracción",
      "El Molino",
      "Fonseca",
      "Hatonuevo",
      "La Jagua del Pilar",
      "Maicao",
      "Manaure",
      "Riohacha",
      "San Juan del Cesar",
      "Uribia",
      "Urumita",
      "Villanueva",
    ],
    Magdalena: [
      "Algarrobo",
      "Aracataca",
      "Ariguaní",
      "Cerro San Antonio",
      "Chivolo",
      "Ciénaga",
      "Concordia",
      "El Banco",
      "El Piñon",
      "El Retén",
      "Fundación",
      "Guamal",
      "Nueva Granada",
      "Pedraza",
      "Pijiño del Carmen",
      "Pivijay",
      "Plato",
      "Pueblo Viejo",
      "Remolino",
      "Sabanas de San Angel",
      "Salamina",
      "San Sebastián de Buenavista",
      "San Zenón",
      "Santa Ana",
      "Santa Bárbara de Pinto",
      "Santa Marta",
      "Sitionuevo",
      "Tenerife",
      "Zapayán",
      "Zona Bananera",
    ],
    Meta: [
      "Acacias",
      "Barranca de Upía",
      "Cabuyaro",
      "Castilla la Nueva",
      "Cubarral",
      "Cumaral",
      "El Calvario",
      "El Castillo",
      "El Dorado",
      "Fuente de Oro",
      "Granada",
      "Guamal",
      "La Macarena",
      "Lejanías",
      "Mapiripán",
      "Mesetas",
      "Puerto Concordia",
      "Puerto Gaitán",
      "Puerto Lleras",
      "Puerto López",
      "Puerto Rico",
      "Restrepo",
      "San Carlos de Guaroa",
      "San Juan de Arama",
      "San Juanito",
      "San Martín",
      "Uribe",
      "Villavicencio",
      "Vista Hermosa",
    ],
    Nariño: [
      "Albán",
      "Aldana",
      "Ancuyá",
      "Arboleda",
      "Barbacoas",
      "Belén",
      "Buesaco",
      "Chachagüí",
      "Colón",
      "Consaca",
      "Contadero",
      "Córdoba",
      "Cuaspud",
      "Cumbal",
      "Cumbitara",
      "El Charco",
      "El Peñol",
      "El Rosario",
      "El Tablón de Gómez",
      "El Tambo",
      "Francisco Pizarro",
      "Funes",
      "Guachucal",
      "Guaitarilla",
      "Gualmatán",
      "Iles",
      "Imués",
      "Ipiales",
      "La Cruz",
      "La Florida",
      "La Llanada",
      "La Tola",
      "La Unión",
      "Leiva",
      "Linares",
      "Los Andes",
      "Magüí",
      "Mallama",
      "Mosquera",
      "Nariño",
      "Olaya Herrera",
      "Ospina",
      "Pasto",
      "Policarpa",
      "Potosí",
      "Providencia",
      "Puerres",
      "Pupiales",
      "Ricaurte",
      "Roberto Payán",
      "Samaniego",
      "San Andrés de Tumaco",
      "San Bernardo",
      "San Lorenzo",
      "San Pablo",
      "San Pedro de Cartago",
      "Sandoná",
      "Santa Bárbara",
      "Santacruz",
      "Sapuyes",
      "Taminango",
      "Tangua",
      "Túquerres",
      "Yacuanquer",
    ],
    "Norte de Santander": [
      "Abrego",
      "Arboledas",
      "Bochalema",
      "Bucarasica",
      "Cachirá",
      "Cácota",
      "Chinácota",
      "Chitagá",
      "Convención",
      "Cúcuta",
      "Cucutilla",
      "Durania",
      "El Carmen",
      "El Tarra",
      "El Zulia",
      "Gramalote",
      "Hacarí",
      "Herrán",
      "La Esperanza",
      "La Playa",
      "Labateca",
      "Los Patios",
      "Lourdes",
      "Mutiscua",
      "Ocaña",
      "Pamplona",
      "Pamplonita",
      "Puerto Santander",
      "Ragonvalia",
      "Salazar",
      "San Calixto",
      "San Cayetano",
      "Santiago",
      "Sardinata",
      "Silos",
      "Teorama",
      "Tibú",
      "Toledo",
      "Villa Caro",
      "Villa del Rosario",
    ],
    Putumayo: [
      "Colón",
      "Leguízamo",
      "Mocoa",
      "Orito",
      "Puerto Asís",
      "Puerto Caicedo",
      "Puerto Guzmán",
      "San Francisco",
      "San Miguel",
      "Santiago",
      "Sibundoy",
      "Valle de Guamez",
      "Villagarzón",
    ],
    Quindío: [
      "Armenia",
      "Buenavista",
      "Calarcá",
      "Circasia",
      "Córdoba",
      "Filandia",
      "Génova",
      "La Tebaida",
      "Montenegro",
      "Pijao",
      "Quimbaya",
      "Salento",
    ],
    Risaralda: [
      "Apía",
      "Balboa",
      "Belén de Umbría",
      "Dosquebradas",
      "Guática",
      "La Celia",
      "La Virginia",
      "Marsella",
      "Mistrató",
      "Pereira",
      "Pueblo Rico",
      "Quinchía",
      "Santa Rosa de Cabal",
      "Santuario",
    ],
    Santander: [
      "Aguada",
      "Albania",
      "Aratoca",
      "Barbosa",
      "Barichara",
      "Barrancabermeja",
      "Betulia",
      "Bolívar",
      "Bucaramanga",
      "Cabrera",
      "California",
      "Capitanejo",
      "Carcasí",
      "Cepitá",
      "Cerrito",
      "Charalá",
      "Charta",
      "Chimá",
      "Chipatá",
      "Cimitarra",
      "Concepción",
      "Confines",
      "Contratación",
      "Coromoro",
      "Curití",
      "El Carmen de Chucurí",
      "El Guacamayo",
      "El Peñón",
      "El Playón",
      "Encino",
      "Enciso",
      "Florián",
      "Floridablanca",
      "Galán",
      "Gambita",
      "Girón",
      "Guaca",
      "Guadalupe",
      "Guapotá",
      "Guavatá",
      "Güepsa",
      "Hato",
      "Jesús María",
      "Jordán",
      "La Belleza",
      "La Paz",
      "Landázuri",
      "Lebríja",
      "Los Santos",
      "Macaravita",
      "Málaga",
      "Matanza",
      "Mogotes",
      "Molagavita",
      "Ocamonte",
      "Oiba",
      "Onzaga",
      "Palmar",
      "Palmas del Socorro",
      "Páramo",
      "Piedecuesta",
      "Pinchote",
      "Puente Nacional",
      "Puerto Parra",
      "Puerto Wilches",
      "Rionegro",
      "Sabana de Torres",
      "San Andrés",
      "San Benito",
      "San Gil",
      "San Joaquín",
      "San José de Miranda",
      "San Miguel",
      "San Vicente de Chucurí",
      "Santa Bárbara",
      "Santa Helena del Opón",
      "Simacota",
      "Socorro",
      "Suaita",
      "Sucre",
      "Suratá",
      "Tona",
      "Valle de San José",
      "Vélez",
      "Vetas",
      "Villanueva",
      "Zapatoca",
    ],
    Sucre: [
      "Buenavista",
      "Caimito",
      "Chalán",
      "Coloso",
      "Corozal",
      "Coveñas",
      "El Roble",
      "Galeras",
      "Guaranda",
      "La Unión",
      "Los Palmitos",
      "Majagual",
      "Morroa",
      "Ovejas",
      "Palmito",
      "Sampués",
      "San Benito Abad",
      "San Juan de Betulia",
      "San Luis de Sincé",
      "San Marcos",
      "San Onofre",
      "San Pedro",
      "Santiago de Tolú",
      "Sincelejo",
      "Sucre",
      "Tolú Viejo",
    ],
    Tolima: [
      "Alpujarra",
      "Alvarado",
      "Ambalema",
      "Anzoátegui",
      "Armero",
      "Ataco",
      "Cajamarca",
      "Carmen de Apicala",
      "Casabianca",
      "Chaparral",
      "Coello",
      "Coyaima",
      "Cunday",
      "Dolores",
      "Espinal",
      "Falan",
      "Flandes",
      "Fresno",
      "Guamo",
      "Herveo",
      "Honda",
      "Ibagué",
      "Icononzo",
      "Lérida",
      "Líbano",
      "Mariquita",
      "Melgar",
      "Murillo",
      "Natagaima",
      "Ortega",
      "Palocabildo",
      "Piedras",
      "Planadas",
      "Prado",
      "Purificación",
      "Rio Blanco",
      "Roncesvalles",
      "Rovira",
      "Saldaña",
      "San Antonio",
      "San Luis",
      "Santa Isabel",
      "Suárez",
      "Valle de San Juan",
      "Venadillo",
      "Villahermosa",
      "Villarrica",
    ],
    "Valle del Cauca": [
      "Alcalá",
      "Andalucía",
      "Ansermanuevo",
      "Argelia",
      "Bolívar",
      "Buenaventura",
      "Bugalagrande",
      "Caicedonia",
      "Cali",
      "Calima",
      "Candelaria",
      "Cartago",
      "Dagua",
      "El Águila",
      "El Cairo",
      "El Cerrito",
      "El Dovio",
      "Florida",
      "Ginebra",
      "Guacarí",
      "Guadalajara de Buga",
      "Jamundí",
      "La Cumbre",
      "La Unión",
      "La Victoria",
      "Obando",
      "Palmira",
      "Pradera",
      "Restrepo",
      "Riofrío",
      "Roldanillo",
      "San Pedro",
      "Sevilla",
      "Toro",
      "Trujillo",
      "Tuluá",
      "Ulloa",
      "Versalles",
      "Vijes",
      "Yotoco",
      "Yumbo",
      "Zarzal",
    ],
    Vaupés: ["Caruru", "Mitú", "Pacoa", "Papunaua", "Taraira", "Yavaraté"],
    Vichada: ["Cumaribo", "La Primavera", "Puerto Carreño", "Santa Rosalía"],
  },
  "Costa Rica": {
    Alajuela: [
      "Alajuela",
      "Atenas",
      "Cantón",
      "Grecia",
      "Guatuso",
      "Los Chiles",
      "Naranjo",
      "Orotina",
      "Palmares",
      "Poás",
      "Río Cuarto",
      "San Carlos",
      "San Mateo",
      "San Ramón",
      "Sarchí",
      "Upala",
      "Zarcero",
    ],
    Cartago: [
      "Alvarado",
      "Cartago",
      "El Guarco",
      "Jiménez",
      "La Unión",
      "Oreamuno",
      "Paraíso",
      "Turrialba",
    ],
    Guanacaste: [
      "Abangares",
      "Bagaces",
      "Cañas",
      "Carrillo",
      "Hojancha",
      "La Cruz",
      "Liberia",
      "Nandayure",
      "Nicoya",
      "Santa Cruz",
      "Tilarán",
    ],
    Heredia: [
      "Barva",
      "Belén",
      "Flores",
      "Heredia",
      "San Isidro",
      "San Pablo",
      "San Rafael",
      "Santa Bárbara",
      "Santo Domingo",
      "Sarapiquí",
    ],
    Limón: ["Guácimo", "Limón", "Matina", "Pococí", "Siquirres", "Talamanca"],
    Puntarenas: [
      "Buenos Aires",
      "Corredores",
      "Coto Brus",
      "Esparza",
      "Garabito",
      "Golfito",
      "Montes de Oro",
      "Osa",
      "Parrita",
      "Puntarenas",
      "Quepos",
    ],
    "San José": [
      "Acosta",
      "Alajuelita",
      "Aserrí",
      "Curridabat",
      "Desamparados",
      "Dota",
      "Escazú",
      "Goicoechea",
      "León Cortés Castro",
      "Montes de Oca",
      "Mora",
      "Moravia",
      "Pérez Zeledón",
      "Puriscal",
      "San José",
      "Santa Ana",
      "Tarrazú",
      "Tibás",
      "Turrubares",
      "Vázquez de Coronado",
    ],
  },
  "Corea Del Sur": {
    Gangwon: ["Chuncheon", "Donghae", "Gangneung", "Sokcho", "Taebaek"],
    Gyeonggi: [
      "Ansan",
      "Anseong",
      "Anyang",
      "Bucheon",
      "Dongducheon",
      "Gimpo",
      "Goyang",
      "Gunpo",
      "Guri",
      "Hanam",
      "Hwaseong",
      "Icheon",
      "Namyangju",
      "Osan",
      "Paju",
      "Pocheon",
      "Pyeongtaek",
      "Seongnam",
      "Siheung",
      "Suwon",
      "Uijeongbu",
      "Uiwang",
      "Yangju",
      "Yongin",
    ],
    Gyeongsangbuk: [
      "Changwon",
      "Geoje",
      "Gimhae",
      "Jinju",
      "Miryang",
      "Sacheon",
      "Tongyeong",
    ],
    Gyeongsangnam: [
      " Changwon",
      " Geoje",
      " Gimhae",
      " Jinju",
      " Masan",
      " Sacheon",
      " Tongyeong",
      " Yangsan",
      " Changnyeong",
      " Haman",
      " Changwon",
      " Goseong",
      " Hadong",
      " Hamyang",
      " Hapcheon",
      " Jinju",
      " Miryang",
      " Namhae",
      " Sancheong",
      " Sacheon",
      " Tongyeong",
      " Uiryeong",
      " Yangsan",
    ],
    Jeju: ["Jeju"],
    "Jeolla del Norte": ["Gunsan", "Iksan", "Jeongeup", "Jeonju"],
    "Jeolla del Sur": ["Gwangyang", "Mokpo", "Naju", "Suncheon", "Yeosu"],
    "Chungcheong del Norte": ["Cheongju", "Chungju", "Jecheon", "Goesan"],
    "Chungcheong del Sur": [
      "Asan",
      "Boryeong",
      "Buyeo",
      "Cheonan",
      "Dangjin",
      "Gongju",
      "Nonsan",
      "Seosan",
      "Yesan",
    ],
    Seúl: [
      "Dobong-gu",
      "Banghak-dong",
      "Chang-dong",
      "Dobong-dong",
      "Maseok-dong",
      "Sanggye-dong",
      "Ssangmun-dong",
      "Uijeongbu-dong",
      "Dongdaemun-gu",
      "Cheongnyangni-dong",
      "Hoegi-dong",
      "Hwigyeong-dong",
      "Jegi-dong",
      "Jangan-dong",
      "Sinseol-dong",
      "Yongdu-dong",
      "Dongjak-gu",
      "Daebang-dong",
      "Heukseok-dong",
      "Noryangjin-dong",
      "Sadang-dong",
      "Sangdo-dong",
      "Sindaebang-dong",
      "Socho-dong",
      "Eunpyeong-gu",
      "Bulgwang-dong",
      "Eungam-dong",
      "Galhyeon-dong",
      "Seodaegu-dong",
      "Shinchon-dong",
      "Yeokchon-dong",
      "Gangbuk-gu",
      "Bumsan-dong",
      "Suyu-dong",
      "Ui-dong",
      "Wondang-dong",
      "Gangdong-gu",
      "Gangil-dong",
      "Godeok-dong",
      "Myeongil-dong",
      "Neung-dong",
      "Sangil-dong",
      "Seongnae-dong",
      "Gangnam-gu",
      "Apgujeong-dong",
      "Cheongdam-dong",
      "Daechi-dong",
      "Dogok-dong",
      "Nonhyeon-dong",
      "Samseong-dong",
      "Sinsa-dong",
      "Gangseo-gu",
      "Balsan-dong",
      "Deungchon-dong",
      "Gaehwa-dong",
      "Gonghang-dong",
      "Hwagok-dong",
      "Magok-dong",
      "Geumcheon-gu",
      "Doksan-dong",
      "Gasan-dong",
      "Geumcheon-gu Office-dong",
      "Guro-dong",
      "Hyehwa-dong",
      "Guro-gu",
      "Cheonwang-dong",
      "Guro 1(il)-dong",
      "Guro 2(i)-dong",
      "Guro 3(sam)-dong",
      "Guro 4(sa)-dong",
      "Guro Digital Complex-dong",
      "Gwanak-gu",
      "Bongcheon-dong",
      "Nakseongdae-dong",
      "Noryangjin-dong",
      "Sillim-dong",
      "Sinlim-dong",
      "Yeomchang-dong",
      "Gwangjin-gu",
      "Guui-dong",
      "Hwayang-dong",
      "Jayang-dong",
      "Neung-dong",
      "Seongsu-dong",
      "Wangsimni-dong",
      "Jongno-gu",
      "Changsin-dong",
      "Cheongunhyoja-dong",
      "Gahoe-dong",
      "Gwonnong-dong",
      "Pungmun-dong",
      "Sajik-dong",
      "Samcheong-dong",
    ],
    Busan: [
      "Busanjin-gu",
      "Dong-gu",
      "Dongnae-gu",
      "Gangseo-gu",
      "Geumjeong-gu",
      "Haeundae-gu",
      "Jung-gu",
      "Nam-gu",
      "Saha-gu",
      "Sasang-gu",
      "Seo-gu",
      "Suyeong-gu",
      "Yeongdo-gu",
      "Yeonje-gu",
    ],
    Daegu: [
      "Buk-gu",
      "Dong-gu",
      "Jung-gu",
      "Nam-gu",
      "Seo-gu",
      "Suseong-gu",
      "Dalseo-gu",
      "Dalseong-gun",
    ],
    Incheon: [
      "Bupyeong-gu",
      "Dong-gu",
      "Gyeyang-gu",
      "Jung-gu",
      "Michuhol-gu",
      "Nam-gu",
      "Seo-gu",
      "Yeonsu-gu",
    ],
    Gwangju: ["Buk-gu", "Dong-gu", "Gwangsan-gu", "Nam-gu", "Seo-gu"],
    Daejeon: ["Dong-gu", "Jung-gu", "Seo-gu", "Yuseong-gu", "Daedeok-gu"],
    Ulsan: ["Buk-gu", "Dong-gu", "Jung-gu", "Nam-gu", "Ulju-gun"],
  },
  Croacia: {
    Zagreb: [
      "Dugo Selo",
      "Ivanić-Grad",
      "Jastrebarsko",
      "Samobor",
      "Sveta Nedelja",
      "Sveti Ivan Zelina",
      "Velika Gorica",
      "Vrbovec",
      "Zaprešić",
      "Bedenica",
      "Bistra",
      "Brckovljani",
      "Brdovec",
      "Dubrava",
      "Dubravica",
      "Farkaševac",
      "Gradec",
      "Jakovlje",
      "Klinča Sela",
      "Kloštar Ivanić",
      "Krašić",
      "Kravarsko",
      "Križ",
      "Luka",
      "Marija Gorica",
      "Orle",
      "Pisarovina",
      "Pokupsko",
      "Preseka",
      "Pušća",
      "Rakovec",
      "Rugvica",
      "Stupnik",
      "Žumberak ",
    ],
    Krapina_Zagorje: [
      "Bedekovčina ",
      "Budinščina ",
      "Desinić ",
      "Đurmanec ",
      "Gornja Stubica ",
      "Hrašćina ",
      "Hum na Sutli ",
      "Jesenje ",
      "Konjščina ",
      "Kraljevec na Sutli ",
      "Krapinske toplice ",
      "Kumrovec ",
      "Lobor ",
      "Mače ",
      "Marija Bistrica ",
      "Mihovljan ",
      "Novi Golubovec ",
      "Petrovsko ",
      "Radoboj ",
      "Stubičke toplice ",
      "Sveti Križ Začretje ",
      "Tuhelj ",
      "Veliko Trgovišće ",
      "Zagorska Sela ",
      "Zlatar Bistrica ",
    ],
    Sisak_Moslavina: [
      "Glina ",
      "Hrvatska Kostajnica ",
      "Kutina ",
      "Novska ",
      "Petrinja ",
      "Sisak ",
      "Donji Kukuruzari ",
      "Dvor ",
      "Gvozd ",
      "Hrvatska Dubica ",
      "Jasenovac ",
      "Lekenik ",
      "Lipovljani ",
      "Majur ",
      "Martinska Ves ",
      "Popovača ",
      "Sunja ",
      "Topusko ",
      "Velika Ludina ",
    ],
    Karlovac: [
      "Karlovac",
      "Ozalj",
      "Ogulin",
      "Slunj",
      "Duga Resa",
      "Barilović",
      "Bosiljevo",
      "Cetingrad",
      "Draganić",
      "Generalski Stol",
      "Josipdol",
      "Kamanje",
      "Krnjak",
      "Lasinja",
      "Netretić",
      "Plaški",
      "Rakovica",
      "Ribnik",
      "Saborsko",
      "Tounj",
      "Vojnić",
      "Žakanje",
    ],
    Varaždin: [
      "Ivanec",
      "Lepoglava",
      "Ludbreg",
      "Novi Marof",
      "Varaždin",
      "Varaždinske Toplice",
      "Bednja",
      "Breznica",
      "Breznički Hum",
      "Beretinec",
      "Cestica",
      "Donja Voća",
      "Gornji Kneginec",
      "Jalžabet",
      "Klenovnik",
      "Ljubešćica",
      "Mali Bukovec",
      "Martijanec",
      "Maruševec",
      "Petrijanec",
      "Sračinec",
      "Sveti Đurđ",
      "Sveti Ilija",
      "Trnovec Bartolovečki",
      "Veliki Bukovec",
      "Vidovec",
      "Vinica",
      "Visoko",
    ],
    Koprivnica_Križevci: [
      "Đurđevac",
      "Koprivnica",
      "Križevci",
      "Drnje",
      "Đelekovec",
      "Ferdinandovac",
      "Gola",
      "Hlebine",
      "Kalinovac",
      "Kalnik",
      "Kloštar Podravski",
      "Koprivnički Bregi",
      "Koprivnički Ivanec",
      "Legrad",
      "Molve",
      "Novigrad Podravski",
      "Novo Virje",
      "Peteranec",
      "Podravske Sesvete",
      "Rasinja",
      "Sokolovac",
      "Sveti Ivan Žabno",
      "Sveti Petar Orehovec",
      "Virje",
    ],
    Bjelovar_Bilogora: [
      "Bjelovar",
      "Čazma",
      "Daruvar",
      "Garešnica",
      "Grubišno Polje",
      "Berek",
      "Dežanovac",
      "Đulovac",
      "Hercegovac",
      "Ivanska",
      "Kapela",
      "Končanica",
      "Nova Rača",
      "Rovišće",
      "Severin",
      "Sirač",
      "Šandrovac",
      "Štefanje",
      "Velika Pisanica",
      "Veliki Grđevac",
      "Veliko Trojstvo",
      "Velika Trnovitica",
      "Zrinski Topolovac",
    ],
    "Primorje / Gorski / Kotar": [
      "Rijeka",
      "Bakar",
      "Cres",
      "Crikvenica",
      "Bribir",
      "Čabar",
      "Delnice",
      "Kastav",
      "Kraljevica",
      "Krk",
      "Mali Lošinj",
      "Novi Vinodolski",
      "Opatija",
      "Rab",
      "Vrbovsko",
      "Rijeka.",
      "Baška",
      "Brod Moravice",
      "Čavle",
      "Dobrinj",
      "Fužine",
      "Jelenje",
      "Klana",
      "Kostrena",
      "Lokve",
      "Lopar",
      "Lovran",
      "Malinska-Dubašnica",
      "Matulji",
      "Mošćenička Draga",
      "Mrkopalj",
      "Omišalj",
      "Punat",
      "Ravna Gora",
      "Skrad",
      "Vinodolska",
      "Viškovo",
      "Vrbnik",
    ],
    "Lika / Senj": [
      "Gospić",
      "Novalja",
      "Otočac",
      "Senj",
      "Brinje",
      "Donji Lapac",
      "Karlobag",
      "Lovinac",
      "Perušić",
      "Plitvička jezera",
      "Udbina",
      "Vrhovine",
    ],
    Virovitica_Podravina: [
      "Virovitica ",
      "Slatina",
      "Orahovica",
      "Pitomača",
      "Špišić Bukovica",
      "Lukač",
      "Gradina",
      "Suhopolje",
      "Sopje",
      "Voćin",
      "Čađavica",
      "Nova Bukovica",
      "Crnac",
      "Mikleuš",
      "Čačinci",
      "Zdenci",
    ],
    Požega_Eslavonia: [
      "Kutjevo",
      "Lipik",
      "Pakrac",
      "Pleternica",
      "Požega",
      "Brestovac",
      "Čaglin",
      "Jakšić",
      "Kaptol",
      "Velika",
    ],
    Brod_Posavina: [
      "Nova Gradiška",
      "Slavonski Brod",
      "Bebrina",
      "Brodski Stupnik",
      "Bukovlje",
      "Cernik",
      "Davor",
      "Donji Andrijevci",
      "Dragalić",
      "Garčin",
      "Gornja Vrba",
      "Gornji Bogićevci",
      "Gundinci",
      "Klakar",
      "Nova Kapela",
      "Okučani",
      "Oprisavci",
      "Oriovac",
      "Podcrkavlje",
      "Rešetari",
      "Sibinj",
      "Sikirevci",
      "Slavonski Šamac",
      "Stara Gradiška",
      "Staro Petrovo Selo",
      "Velika Kopanica",
      "Vrbje",
      "Vrpolje",
    ],
    Zadar: [
      "Zadar",
      "Benkovac",
      "Biograd na Moru",
      "Nin",
      "Obrovac",
      "Pag",
      "Bibinje",
      "Galovac",
      "Gračac",
      "Jasenice",
      "Kali",
      "Kolan",
      "Kukljica",
      "Lišane Ostrovičke",
      "Novigrad",
      "Pakoštane",
      "Pašman",
      "Polača",
      "Poličnik",
      "Posedarje",
      "Povljana",
      "Preko",
      "Privlaka",
      "Ražanac",
      "Sali",
      "Stankovci",
      "Starigrad",
      "Sukošan",
      "Sveti Filip i Jakov",
      "Škabrnja",
      "Tkon",
      "Vir",
      "Vrsi",
      "Zemunik Donji",
    ],
    Osijek_Baranya: [
      "Osijek",
      "Beli Manastir",
      "Belišće",
      "Donji Miholjac",
      "Đakovo",
      "Martin",
      "Našice",
      "Valpovo",
      "Antunovac",
      "Bilje",
      "Bizovac",
      "Čeminac",
      "Čepin",
      "Darda",
      "Donja Motičina",
      "Draž",
      "Drenje",
      "Đurđenovac",
      "Erdut",
      "Ernestinovo",
      "Feričanci",
      "Gorjani",
      "Jagodnjak",
      "Kneževi Vinogradi",
      "Koška",
      "Levanjska Varoš",
      "Magadenovac",
      "Marijanci",
      "Petlovac",
      "Petrijevci",
      "Podravska Moslavina",
      "Podgorač",
      "Popovac",
      "Punitovci",
      "Satnica Đakovačka",
      "Semeljci",
      "Strizivojna",
      "Šodolovci",
      "Trnava ",
      "Viljevo",
      "Viškovci",
      "Vladislavci",
      "Vuka",
    ],
    Šibenik_Knin: [
      "Drniš",
      "Knin",
      "Skradin",
      "Šibenik",
      "Vodice",
      "Bilice",
      "Civljane",
      "Ervenik",
      "Kijevo",
      "Kistanje",
      "Promina",
      "Biskupija",
      "Pirovac",
      "Primošten",
      "Rogoznica",
      "Ružić",
      "Tribunj",
      "Tisno",
      "Murter-Kornati",
      "Unešić",
    ],
    Vukovar_Sirmia: [
      "Vukovar ",
      "Vinkovci",
      "Županja",
      "Ilok",
      "Otok",
      "Andrijaševci",
      "Babina Greda",
      "Bogdanovci",
      "Borovo",
      "Bošnjaci",
      "Cerna",
      "Drenovci",
      "Gradište",
      "Gunja",
      "Ivankovo",
      "Jarmina",
      "Lovas",
      "Markušica",
      "Negoslavci",
      "Nijemci",
      "Nuštar",
      "Privlaka",
      "Stari Jankovci",
      "Stari Mikanovci",
      "Tompojevci",
      "Tordinci",
      "Tovarnik",
      "Trpinja",
      "Vođinci",
      "Vrbanja",
    ],
    Split_Dalmacia: [
      "Hvar",
      "Imotski",
      "Kaštela",
      "Komiža",
      "Makarska",
      "Omiš",
      "Sinj",
      "Solin",
      "Split",
      "Stari Grad",
      "Supetar",
      "Trilj",
      "Trogir",
      "Vis",
      "Vrgorac",
      "Vrlika",
      "Baška Voda",
      "Bobovisca",
      "Bol",
      "Brela",
      "Cista Provo",
      "Dicmo",
      "Dugi Rat",
      "Dugopolje",
      "Gradac",
      "Hrvace",
      "Jelsa",
      "Klis",
      "Lećevica",
      "Lokvičići",
      "Lovreć",
      "Marina",
      "Milna",
      "Muć",
      "Nerežišća",
      "Okrug",
      "Otok",
      "Podbablje",
      "Podgora",
      "Podstrana",
      "Postira",
      "Prgomet",
      "Primorski Dolac",
      "Proložac",
      "Pučišća",
      "Runovići",
      "Seget",
      "Selca",
      "Sućuraj",
      "Sutivan",
      "Šestanovac",
      "Šolta",
      "Tučepi",
      "Zadvarje",
      "Zagvozd",
      "Zmijavci",
    ],
    Istria: [
      "Pula ",
      "Pazin ",
      "Poreč ",
      "Buje ",
      "Buzet ",
      "Labin ",
      "Novigrad ",
      "Rovinj ",
      "Umag ",
      "Vodnjan ",
      "Bale",
      "Barban",
      "Brtonigla",
      "Cerovlje",
      "Fažana",
      "Funtana",
      "Gračišće",
      "Grožnjan",
      "Kanfanar",
      "Karojba",
      "Kaštelir-Labinci",
      "Kršan",
      "Lanišće",
      "Ližnjan",
      "Lupoglav",
      "Marčana",
      "Medulin",
      "Motovun",
      "Oprtalj",
      "Pićan",
      "Raša",
      "Sveta Nedelja",
      "Sveti Lovreč",
      "Sveti Petar u Šumi",
      "Svetvinčenat",
      "Tar-Vabriga",
      "Tinjan",
      "Višnjan",
      "Vižinada",
      "Vrsar",
      "Žminj.",
    ],
    Dubrovnik_Neretva: [
      "Dubrovnik ",
      "Korčula",
      "Metković",
      "Opuzen",
      "Ploče",
      "Blato",
      "Dubrovačko ",
      "Janjina",
      "Konavle",
      "Kula Norinska",
      "Lastovo",
      "Lumbarda",
      "Mljet",
      "Orebić",
      "Pojezerje",
      "Slivno",
      "Smokvica",
      "Ston",
      "Trpanj",
      "Vela Luka",
      "Zažablje",
      "Župa ",
    ],
    Međimurje: [
      "Čakovec",
      "Mursko Središće",
      "Prelog",
      "Belica",
      "Dekanovec",
      "Domašinec",
      "Donja Dubrava",
      "Donji Kraljevec",
      "Donji Vidovec",
      "Goričan",
      "Gornji Mihaljevec",
      "Kotoriba",
      "Mala Subotica",
      "Nedelišće",
      "Orehovica",
      "Podturen",
      "Pribislavec",
      "Selnica",
      "Strahoninec",
      "Sveta Marija",
      "Sveti Juraj na Bregu",
      "Sveti Martin na Muri",
      "Šenkovec",
      "Štrigova",
      "Vratišinec",
    ],
  },
  Cuba: {
    Artemisa: [
      "Alquízar",
      "Artemisa",
      "Bahía Honda",
      "Bauta",
      "Caimito",
      "Candelaria",
      "Guanajay",
      "Güira de Melena",
      "Mariel",
      "San Antonio de los Baños",
      "San Cristóbal",
    ],
    Camagüey: [
      "Camagüey",
      "Céspedes",
      "Esmeralda",
      "Florida",
      "Guáimaro",
      "Jimaguayú",
      "Minas",
      "Najasa",
      "Nuevitas",
      "Santa Cruz del Sur",
      "Sibanicú",
      "Sierra de Cubitas",
      "Vertientes",
    ],
    "Ciego de Ávila": [
      "Baraguá",
      "Bolivia",
      "Chambas",
      "Ciego de Ávila",
      "Ciro Redondo",
      "Florencia",
      "Majagua",
      "Morón",
      "Primero de Enero",
      "Venezuela",
    ],
    Cienfuegos: [
      "Abreus",
      "Aguada de Pasajeros",
      "Cienfuegos",
      "Congojas",
      "Cruces",
      "Cumanayagua",
      "Lajas",
      "Palmira",
      "Rodas",
    ],
    Granma: [
      "Bartolomé Masó",
      "Bayamo",
      "Buey Arriba",
      "Campechuela",
      "Cauto Cristo",
      "Guisa",
      "Jiguaní",
      "Manzanillo",
      "Media Luna",
      "Niquero",
      "Pilón",
      "Río Cauto",
      "Yara",
    ],
    Guantánamo: [
      "Baracoa",
      "Caimanera",
      "El Salvador",
      "Guantánamo",
      "Imías",
      "Maisí",
      "Manuel Tames",
      "Niceto Pérez",
      "San Antonio del Sur",
      "Yateras",
    ],
    Holguín: [
      "Antilla",
      "Báguanos",
      "Banes",
      "Cacocum",
      "Calixto García",
      "Cueto",
      "Frank País",
      "Gibara",
      "Holguín",
      "Mayarí",
      "Moa",
      "Rafael Freyre",
      "Sagua de Tánamo",
      "Urbano Noris",
    ],
    "Isla de la Juventud": ["Isla de la Juventud"],
    "La Habana": [
      "Arroyo Naranjo",
      "Boyeros",
      "Centro Habana",
      "Cerro",
      "Cotorro",
      "Diez de Octubre",
      "Guanabacoa",
      "La Habana del Este",
      "La Habana Vieja",
      "La Lisa",
      "Marianao",
      "Playa",
      "Plaza de la Revolución",
      "Regla",
      "San Miguel del Padrón",
    ],
    "Las Tunas": [
      "Amancio",
      "Colombia",
      "Jesús Menéndez",
      "Jobabo",
      "Las Tunas",
      "Majibacoa",
      "Manatí",
      "Puerto Padre",
    ],
    Matanzas: [
      "Calimete",
      "Cárdenas",
      "Ciénaga de Zapata",
      "Colón",
      "Jagüey Grande",
      "Jovellanos",
      "Limonar",
      "Los Arabos",
      "Martí",
      "Matanzas (Capital de la provincia)",
      "Pedro Betancourt",
      "Perico",
      "Unión de Reyes",
    ],
    Mayabeque: [
      "Batabanó",
      "Bejucal",
      "Güines",
      "Jaruco",
      "Madruga",
      "Melena del Sur",
      "Nueva Paz",
      "Quivicán",
      "San José de las Lajas",
      "San Nicolás de Bari",
      "Santa Cruz del Norte",
    ],
    "Pinar del Río": [
      "Consolación del Sur",
      "Guane",
      "La Palma",
      "Los Palacios",
      "Mantua",
      "Minas de Matahambre",
      "Pinar del Río",
      "San Juan y Martínez",
      "San Luis",
      "Sandino",
      "Viñales",
    ],
    "Sancti Spíritus": [
      "Cabaiguán",
      "Fomento",
      "Jatibonico",
      "La Sierpe",
      "Sancti Spíritus",
      "Taguasco",
      "Trinidad",
      "Yaguajay",
    ],
    "Santiago de Cuba": [
      "Contramaestre",
      "Guamá",
      "Mella",
      "Palma Soriano",
      "San Luis",
      "Santiago de Cuba",
      "Segundo Frente",
      "Songo-La Maya",
      "Tercer Frente",
    ],
    "Villa Clara": [
      "Caibarién",
      "Camajuaní",
      "Cifuentes",
      "Corralillo",
      "Encrucijada",
      "Manicaragua",
      "Placetas",
      "Quemado de Güines",
      "Ranchuelo",
      "Remedios",
      "Sagua la Grande",
      "Santa Clara",
      "Santo Domingo",
    ],
  },
  Dinamarca: {
    Hovedstaden: [
      "Albertslund",
      "Allerød",
      "Ballerup",
      "Bornholm Regional",
      "Brøndby",
      "Copenhague",
      "Dragør",
      "Egedal",
      "Fredensborg",
      "Frederiksberg",
      "Frederikssund",
      "Furesø",
      "Gentofte",
      "Gladsaxe",
      "Glostrup",
      "Gribskov",
      "Halsnæs",
      "Helsingør",
      "Herlev",
      "Hillerød",
      "Hvidovre",
      "Høje-Taastrup",
      "Hørsholm",
      "Ishøj",
      "Lyngby-Taarbæk",
      "Rudersdal",
      "Rødovre",
      "Tårnby",
      "Vallensbæk",
    ],
    Midtjylland: [
      "Århus",
      "Favrskov",
      "Hedensted",
      "Herning",
      "Holstebro",
      "Horsens",
      "Ikast-Brande",
      "Lemvig",
      "Norddjurs",
      "Odder",
      "Randers",
      "Ringkøbing-Skjern",
      "Samsø",
      "Silkeborg",
      "Skanderborg",
      "Skive",
      "Struer",
      "Syddjurs",
      "Viborg",
    ],
    Sjælland: [
      "Faxe",
      "Greve",
      "Guldborgsund",
      "Holbæk",
      "Kalundborg",
      "Køge",
      "Lejre",
      "Lolland",
      "Næstved",
      "Odsherred",
      "Ringsted",
      "Roskilde",
      "Slagelse",
      "Solrød",
      "Sorø",
      "Stevns",
      "Vordingborg",
    ],
    Syddanmark: [
      "Aabenraa",
      "Assens",
      "Billund",
      "Esbjerg",
      "Faaborg-Midtfyn",
      "Fanø",
      "Fredericia",
      "Haderslev",
      "Kerteminde",
      "Kolding",
      "Langeland",
      "Middelfart",
      "Nordfyn",
      "Nyborg",
      "Odense",
      "Svendborg",
      "Sønderborg",
      "Tønder",
      "Varde",
      "Vejen",
      "Vejle",
      "Ærø",
    ],
  },
  Ecuador: {
    Azuay: [
      "Chordeleg",
      "Cuenca",
      "El Pan",
      "Girón",
      "Guachapala",
      "Gualaceo",
      "Nabón",
      "Oña",
      "Paute",
      "Ponce Enriquez",
      "Pucará",
      "San Fernando",
      "Santa Isabel",
      "Sevilla de Oro",
      "Sígsig",
    ],
    Bolívar: [
      "Caluma",
      "Chillanes",
      "Chimbo",
      "Echeandía",
      "Guaranda",
      "Las Naves",
      "San Miguel",
    ],
    Cañar: [
      "Azogues",
      "Biblián",
      "Cañar",
      "Déleg",
      "El Tambo",
      "La Troncal",
      "Suscal",
    ],
    Carchi: [
      "Bolívar",
      "Espejo",
      "Mira",
      "Montúfar",
      "San Pedro de Huaca",
      "Tulcán",
    ],
    Chimborazo: [
      "Alausí",
      "Chambo",
      "Chunchi",
      "Colta",
      "Cumandá",
      "Guamote",
      "Guano",
      "Pallatanga",
      "Penipe",
      "Riobamba",
    ],
    Cotopaxi: [
      "La Maná",
      "Latacunga",
      "Pangua",
      "Pujilí",
      "Salcedo",
      "Saquisilí",
      "Sigchos",
    ],
    "El Oro": [
      "Arenillas",
      "Atahualpa",
      "Balsas",
      "Chilla",
      "El Guabo",
      "Huaquillas",
      "Las Lajas",
      "Machala",
      "Marcabelí",
      "Pasaje",
      "Piñas",
      "Portovelo",
      "Santa Rosa",
      "Zaruma",
    ],
    Esmeraldas: [
      "Atacames",
      "Eloy Alfaro",
      "Esmeraldas",
      "Muisne",
      "Quinindé",
      "Rioverde",
      "San Lorenzo",
    ],
    Galápagos: ["Isabela", "San Cristóbal", "Santa Cruz"],
    Guayas: [
      "Alfredo Baquerizo Moreno",
      "Balao",
      "Balzar",
      "Colimes",
      "Coronel Marcelino Maridueña",
      "Daule",
      "Durán",
      "El Empalme",
      "El Triunfo",
      "General Antonio Elizalde",
      "Guayaquil",
      "Isidro Ayora",
      "Lomas de Sargentillo",
      "Milagro",
      "Naranjal",
      "Naranjito",
      "Nobol",
      "Palestina",
      "Pedro Carbo",
      "Playas",
      "Salitre",
      "Samborondón",
      "Santa Lucía",
      "Simón Bolívar",
      "Yaguachi",
    ],
    Imbabura: [
      "Antonio Ante",
      "Cotacachi",
      "Ibarra",
      "Otavalo",
      "Pimampiro",
      "Urcuquí",
    ],
    Loja: [
      "Calvas",
      "Catamayo",
      "Celica",
      "Chaguarpamba",
      "Espíndola",
      "Gonzanamá",
      "Loja",
      "Macará",
      "Olmedo",
      "Paltas",
      "Pindal",
      "Puyango",
      "Quilanga",
      "Saraguro",
      "Sozoranga",
      "Zapotillo",
    ],
    "Los Ríos": [
      "Baba",
      "Babahoyo",
      "Buena Fe",
      "Mocache",
      "Montalvo",
      "Palenque",
      "Puebloviejo",
      "Quevedo",
      "Quinsaloma",
      "Urdaneta",
      "Valencia",
      "Ventanas",
      "Vinces",
    ],
    Manabí: [
      "Bolívar",
      "Chone",
      "El Carmen",
      "Flavio Alfaro",
      "Jama",
      "Jaramijó",
      "Jipijapa",
      "Junín",
      "Manta",
      "Montecristi",
      "Olmedo",
      "Paján",
      "Pedernales",
      "Pichincha",
      "Portoviejo",
      "Puerto López",
      "Rocafuerte",
      "San Vicente",
      "Santa Ana",
      "Sucre",
      "Tosagua",
      "Veinticuatro de Mayo",
    ],
    "Morona Santiago": [
      "Gualaquiza",
      "Huamboya",
      "Limón Indanza",
      "Logroño",
      "Morona",
      "Pablo Sexto",
      "Palora",
      "San Juan Bosco",
      "Santiago",
      "Sucúa",
      "Taisha",
      "Tiwintza",
    ],
    Napo: [
      "Archidona",
      "Carlos Julio Arosemena Tola",
      "El Chaco",
      "Quijos",
      "Tena",
    ],
    Orellana: ["Aguarico", "Orellana", "La Joya de los Sachas", "Loreto"],
    Pastaza: ["Arajuno", "Mera", "Pastaza", "Santa Clara"],
    Pichincha: [
      "Cayambe",
      "Distrito Metropolitano de Quito",
      "Mejía",
      "Pedro Moncayo",
      "Pedro Vicente Maldonado",
      "Puerto Quito",
      "San Miguel de Los Bancos",
      "Sangolquí Rumiñahui",
    ],
    "Santa Elena": ["La Libertad", "Salinas", "Santa Elena"],
    "Santo Domingo de los Tsáchilas": ["La Concordia", "Santo Domingo"],
    Sucumbíos: [
      "Cascales",
      "Cuyabeno",
      "Gonzalo Pizarro",
      "Lago Agrio",
      "Putumayo",
      "Shushufindi",
      "Sucumbíos",
    ],
    Tungurahua: [
      "Ambato",
      "Baños",
      "Cevallos",
      "Mocha",
      "Patate",
      "Pelileo",
      "Píllaro",
      "Quero",
      "Tisaleo",
    ],
    "Zamora Chinchipe": [
      "Centinela del Cóndor",
      "Chinchipe",
      "El Pangui",
      "Nangaritza",
      "Palanda",
      "Paquisha",
      "Yacuambi",
      "Yantzaza",
      "Zamora",
    ],
  },
  Egipto: {
    Alejandría: [
      "Ādārh Shurṭah Mīnā' al-Iskandariyah",
      "Ad-Dukhaylah",
      "Al-'Āmriyah",
      "Al-'Aṭṭārīn",
      "Al-Jumruk",
      "Al-Labān",
      "Al-Manshiyah",
      "Al-Muntazah",
      "Ar-Raml",
      "As-Sāḥal ash-Shamāli",
      "Bāb Sharqi",
      "Burj al-'Arab",
      "Karmūz",
      "Madīnat Burj al-'Arab al-Jadīdah",
      "Mīnā al-Baṣal",
      "Muḥarram Bik",
      "Sīdi Jābir",
    ],
    Asiut: [
      "Abnūb",
      "Abū Tīj",
      "Al-Badārī",
      "Al-Fatḥ",
      "Al-Ghanāyim",
      "Al-Qūṣiyah",
      "Asyūṭ",
      "Dayrūṭ",
      "Madīnat Asyūṭ al-Jadīdah",
      "Manfalūṭ",
      "Sāḥīl Salim",
      "Ṣidfa",
    ],
    Asuán: [
      "Abu Simbel",
      "Aswān",
      "Daraw",
      "Idfū",
      "Kawm Umbū",
      "Madīnat Aswān al-Jadīdah",
      "Madīnat Tūshka al-Jadīdah",
      "Naṣr",
    ],
    Behera: [
      "Abū al-Maṭāmīr",
      "Abū Ḥummuṣ",
      "Ad-Dilinjāt",
      "Al-Maḥmūdiyah",
      "Ar-Raḥmāniyah",
      "Badr",
      "Damanhūr",
      "Gharb an-Nūbāriyah",
      "Ḥawsh 'Īsā",
      "Idkū",
      "Ityāy al-Bārūd",
      "Kafr ad-Dawwār",
      "Kafr ad-Dawwār",
      "Kawm Ḥamādah",
      "Rashīd",
      "Shubrākhīt",
      "Wadi an-Natrun",
    ],
    "Beni Suef": [
      "Al-Fashn",
      "Al-Wāsiṭā",
      "Banī Suwayf",
      "Bibā",
      "Ihnāsiyā",
      "Madīnat Banī Suwayf al-Jadīdah",
      "Nasser",
      "Sumusṭā al-Waqf",
    ],
    Caliubia: [
      "Al-Khānkah",
      "Al-Khuṣūṣ",
      "Al-Qanāṭir al-Khayriyah",
      "Al-'Ubūr",
      "Banhā",
      "Kafr Shukr",
      "Qahā",
      "Qalyūb",
      "Qalyūb",
      "Shubrā al-Khaymah",
      "Sibīn al-Qanāṭir",
      "Ṭūkh",
    ],
    Dacalia: [
      "Ajā",
      "Al-Jamāliyah",
      "Al-Kurdy",
      "El Mansoura",
      "Al-Manzilah",
      "Al-Maṭariyah",
      "As-Sinbillāwayn",
      "Banī Ubayd",
      "Bilqās",
      "Dikirnis",
      "Jamaṣah",
      "Maḥallat Damanah",
      "Minyat an-Naṣr",
      "Mīt Ghamr",
      "Mīt Salsīl",
      "Nabarūh",
      "Shirbīn",
      "Ṭalkhā",
      "Timay al-Imdīd",
    ],
    Damieta: [
      "Az-Zarqā'",
      "Damietta",
      "Fāraskūr",
      "Kafr al-Baṭṭīkh",
      "Kafr Sa'd",
      "Madīnat Dumyāṭ al-Jadīdah",
      "Ra's al-Bar",
    ],
    "El Cairo": ["El Cairo"],
    Fayún: [
      "Fayoum",
      "Ibshawāy",
      "Iṭsā",
      "Madīnat al-Fayyūm al-Jadīdah",
      "Sinnūris",
      "Ṭāmiyah",
      "Yūsuf aṣ-Ṣiddīq",
    ],
    Guiza: [
      "Ad-Duqqī ",
      "Al-Ahrām",
      "Al-'Ajūzah",
      "Al-'Ayyāṭ",
      "Al-Badrashayn",
      "Al-Ḥawāmidiyah",
      "Giza",
      "Al-'Umrāniyah",
      "Al-Wāḥāt al-Baḥariyah",
      "Al-Warrāq",
      "Ash-Shaykh Zāyid",
      "Aṣ-Ṣaff",
      "Aṭfīḥ",
      "Aṭ-Ṭālbīah",
      "Awsīm",
      "Būlāq al-Dakrūr",
      "Imbābah",
      "Kirdāsah",
      "Madīnat Sittah Uktūbar",
    ],
    Ismailia: [
      "Abū Ṣuwīr",
      "Ismailía",
      "Al-Qanṭarah",
      "Al-Qanṭarah Sharq",
      "Al-Qaṣāṣīn al-Jadīdah",
      "At-Tall al-Kabīr",
      "Fayid",
    ],
    "Kafr el Sheij": [
      "Al-Burulus",
      "Al-Ḥāmūl",
      "Ar-Riyād",
      "Biyalā",
      "Disūq",
      "Fuwah",
      "Kafr ash-Shaykh",
      "Metoubes",
      "Qallīn",
      "Sīdī Sālim",
    ],
    Luxor: ["Luxor"],
    "Mar Rojo": [
      "Al-Ghurdaqah",
      "Al-Quṣayr",
      "Ash-Shalātīn",
      "Ḥalāyib",
      "Marsā 'Alam",
      "Ras Ghārib",
      "Safājā",
    ],
    Matrú: [
      "Aḍ-Ḍab'ah",
      "Al-'Alamayn",
      "Al-Ḥammām",
      "An-Najīlah",
      "As-Sāḥal ash-Shamāli",
      "As-Sallūm",
      "Marsā Maṭrūḥ",
      "Sīdī Barrānī ",
      "Sīwa",
    ],
    Menia: [
      "Abū Qurqās",
      "Al-'Idwah",
      "Al-Minyā",
      "Banī Mazār",
      "Dayr Mawās",
      "Madīnat al-Minyā al-Jadīdah",
      "Maghāghah",
      "Malawiṭ Gharb",
      "Mallawī",
      "Mallawī",
      "Maṭāy",
      "Samālūṭ",
    ],
    Menufia: [
      "Al-Bājūr",
      "Ashmūn",
      "Ash-Shuhadā'",
      "Birkat as-Sab'",
      "Madīnat as-Sādāt",
      "Minūf",
      "Quwaysinā",
      "Shibīn al-Kawm",
      "Sirs al-Layyānah",
      "Talā",
    ],
    "Nuevo Valle": [
      "Al-Waḥat al-Kharijah",
      "Balat",
      "Shurṭah al-Dakhlah",
      "Shurṭah al-Farafirah",
      "Shurṭah Baris",
    ],
    "Occidental/Gharbia": [
      "Al-Maḥallah al-Kubrā",
      "As-Sanṭah",
      "Basyūn",
      "Kafr az-Zayyāt",
      "Quṭūr",
      "Samannūd",
      "Ṭanṭā",
      "Ziftā",
    ],
    "Oriental/Sharqia": [
      "Abū Ḥammād",
      "Abū Kabīr",
      "Al-Ḥusayniyah",
      "Al-Ibrāhīmiya",
      "Al-Qanāyāt",
      "Al-Qurayn",
      "Aṣ-Ṣaliḥiyah al-Jadīdah",
      "Awlād Ṣaqr",
      "Az-Zaqāzīq",
      "Bilbays",
      "Diyarb Najm",
      "Fāqūs",
      "Hihyā",
      "Kafr Ṣaqr",
      "Madīnat 'Ashirh min-Ramaḍān",
      "Mashtūl as-Sūq",
      "Minyā al-Qamḥ",
      "Munshāh Abū 'Umar",
      "Ṣān al-Ḥajar",
    ],
    "Puerto Saíd": [
      "Aḍ-Ḍawāḥy",
      "Al-'Arab",
      "Al-Janūb",
      "Al-Manākh",
      "Al-Manāṣrah",
      "Ash-Sharq",
      "Az-Zuhūr",
      "Būr Fuād",
      "Mubārak - Sharq at-Tafrī'tah",
    ],
    Quena: [
      "Abū Ṭisht",
      "Al-Waqf",
      "Dishnā",
      "Farshūṭ",
      "Madīnat Qinā al-Jadīdah",
      "Naj' Ḥammādī",
      "Naqādah",
      "Qifṭ",
      "Qena",
      "Qūṣ",
    ],
    "Sinaí del Norte": [
      "Al-'Arīsh",
      "Al-Ḥasanah",
      "Ash-Shaykh Zuwayd",
      "B'īr al-'Abd",
      "Nakhl",
      "Rafaḥ",
      "Shurṭah al-Qasīmah",
      "Shurṭah Rumānah",
    ],
    "Sinaí del Sur": [
      "Abū Radīs",
      "El Tor",
      "Dahab",
      "Nuwaybi'a",
      "Ras Sidr",
      "Sānt Kātirīn",
      "Sharm ash-Shaykh",
      "Shurṭah Ṭābā",
    ],
    Suez: ["Al-Arba'īn", "Al-Janāyin", "Suez", "Atāqah", "Fayṣal"],
    Suhag: [
      "Akhmīm",
      "Al-Balyanā",
      "Al-Kawthar",
      "Al-Marāghah",
      "Al-Munsha'āh",
      "Al-'Usayrāt",
      "Dar as-Salām",
      "Jirjā",
      "Juhaynah al-Gharbiyah",
      "Madīnat Sawhāj al-Jadīdah",
      "Sāqultah",
      "Sohag",
      "Ṭahṭā",
      "Ṭimā",
    ],
  },
  "El Salvador": {
    Ahuachapán: [
      "Ahuachapán",
      "Apaneca",
      "Atiquizaya",
      "Concepción de Ataco",
      "El Refugio",
      "Guaymango",
      "Jujutla",
      "San Francisco Menéndez",
      "San Lorenzo",
      "San Pedro Puxtla",
      "Tacuba",
      "Turín",
    ],
    Cabañas: [
      "Cinquera",
      "Dolores",
      "Guacotecti",
      "Ilobasco",
      "Jutiapa",
      "San Isidro",
      "Sensuntepeque",
      "Tejutepeque",
      "Victoria",
    ],
    Chalatenango: [
      "Agua Caliente",
      "Arcatao",
      "Azacualpa",
      "Cancasque",
      "Chalatenango",
      "Citalá",
      "Comapala",
      "Concepción Quezaltepeque",
      "Dulce Nombre de María",
      "El Carrizal",
      "El Paraíso",
      "La Laguna",
      "La Palma",
      "La Reina",
      "Las Flores",
      "Las Vueltas",
      "Nombre de Jesús",
      "Nueva Concepción",
      "Nueva Trinidad",
      "Ojos de Agua",
      "Potonico",
      "San Antonio de la Cruz",
      "San Antonio Los Ranchos",
      "San Fernando",
      "San Francisco Lempa",
      "San Francisco Morazán",
      "San Ignacio",
      "San Isidro Labrador",
      "San Luis del Carmen",
      "San Miguel de Mercedes",
      "San Rafael",
      "Santa Rita",
      "Tejutla",
    ],
    Cuscatlán: [
      "Candelaria",
      "Cojutepeque",
      "El Carmen",
      "El Rosario",
      "Monte San Juan",
      "Oratorio de Concepción",
      "San Bartolomé Perulapía",
      "San Cristóbal",
      "San José Guayabal",
      "San Pedro Perulapán",
      "San Rafael Cedros",
      "San Ramón",
      "Santa Cruz Analquito",
      "Santa Cruz Michapa",
      "Suchitoto",
      "Tenancingo",
    ],
    "La Libertad": [
      "Antiguo Cuscatlán",
      "Chiltiupán",
      "Ciudad Arce",
      "Colón",
      "Comasagua",
      "Huizúcar",
      "Jayaque",
      "Jicalapa",
      "La Libertad",
      "Nuevo Cuscatlán",
      "Quezaltepeque",
      "Sacacoyo",
      "San José Villanueva",
      "San Juan Opico",
      "San Matías",
      "San Pablo Tacachico",
      "Santa Tecla",
      "Talnique",
      "Tamanique",
      "Teotepeque",
      "Tepecoyo",
      "Zaragoza",
    ],
    "La Paz": [
      "Cuyultitán",
      "El Rosario",
      "Jerusalén",
      "Mercedes La Ceiba",
      "Olocuilta",
      "Paraíso de Osorio",
      "San Antonio Masahuat",
      "San Emigdio",
      "San Francisco Chinameca",
      "San Juan Nonualco",
      "San Juan Talpa",
      "San Juan Tepezontes",
      "San Luis La Herradura",
      "San Luis Talpa",
      "San Miguel Tepezontes",
      "San Pedro Masahuat",
      "San Pedro Nonualco",
      "San Rafael Obrajuelo",
      "Santa María Ostuma",
      "Santiago Nonualco",
      "Tapalhuaca",
      "Zacatecoluca",
    ],
    "La Unión": [
      "Bolívar",
      "Conchagua",
      "El Carmen",
      "Intipucá",
      "La Unión",
      "Meanguera del Golfo",
      "San Alejo",
      "San José",
      "Yayantique",
      "Yucuaiquín",
    ],
    Morazán: [
      "Arambala",
      "Cacaopera",
      "Chilanga",
      "Corinto",
      "Delicias de Concepción",
      "El Divisadero",
      "El Rosario",
      "Gualococti",
      "Guatajiagua",
      "Joateca",
      "Jocoaitique",
      "Jocoro",
      "Lolotiquillo",
      "Meanguera",
      "Osicala",
      "Perquín",
      "San Carlos",
      "San Fernando",
      "San Francisco Gotera",
      "San Isidro",
      "San Simón",
      "Sensembra",
      "Sociedad",
      "Torola",
      "Yamabal",
      "Yoloaiquín",
    ],
    "San Miguel": [
      "Carolina",
      "Chapeltique",
      "Chinameca",
      "Chirilagua",
      "Ciudad Barrios",
      "Comacarán",
      "El Tránsito",
      "Lolotique",
      "Moncagua",
      "Nueva Guadalupe",
      "Nuevo Edén de San Juan",
      "Quelepa",
      "San Antonio",
      "San Gerardo",
      "San Jorge",
      "San Luis de la Reina",
      "San Miguel",
      "San Rafael Oriente",
      "Sesori",
      "Uluazapa",
    ],
    "San Salvador": [
      "Aguilares",
      "Apopa",
      "Ayutuxtepeque",
      "Ciudad Delgado",
      "Cuscatancingo",
      "El Paisnal",
      "Guazapa",
      "Ilopango",
      "Mejicanos",
      "Nejapa",
      "Panchimalco",
      "Rosario de Mora",
      "San Marcos",
      "San Martín",
      "San Salvador",
      "Santiago Texacuangos",
      "Santo Tomás",
      "Soyapango",
      "Tonacatepeque",
    ],
    "San Vicente": [
      "Apastepeque",
      "Guadalupe",
      "San Cayetano Istepeque",
      "San Esteban Catarina",
      "San Ildefonso",
      "San Lorenzo",
      "San Sebastián",
      "San Vicente",
      "Santa Clara",
      "Santo Domingo",
      "Tecoluca",
      "Tepetitán",
      "Verapaz",
    ],
    "Santa Ana": [
      "Candelaria de la Frontera",
      "Chalchuapa",
      "Coatepeque",
      "El Congo",
      "El Porvenir",
      "Masahuat",
      "Metapán",
      "San Antonio Pajonal",
      "San Sebastián Salitrillo",
      "Santa Ana",
      "Santa Rosa Guachipilín",
      "Santiago de la Frontera",
      "Texistepeque",
    ],
    Sonsonate: [
      "Acajutla",
      "Armenia",
      "Caluco",
      "Cuisnahuat",
      "Izalco",
      "Juayúa",
      "Nahuizalco",
      "Nahulingo",
      "Salcoatitán",
      "San Antonio del Monte",
      "San Julián",
      "Santa Catarina Masahuat",
      "Santa Isabel Ishuatán",
      "Santo Domingo de Guzmán",
      "Sonsonate",
      "Sonzacate",
    ],
    Usulután: [
      "Alegría",
      "Berlín",
      "California",
      "Concepción Batres",
      "El Triunfo",
      "Ereguayquín",
      "Estanzuelas",
      "Jiquilisco",
      "Jucuapa",
      "Jucuarán",
      "Mercedes Umaña",
      "Nueva Granada",
      "Ozatlán",
      "Puerto El Triunfo",
      "San Agustín",
      "San Buenaventura",
      "San Dionisio",
      "San Francisco Javier",
      "Santa Elena",
      "Santa María",
      "Santiago de María",
      "Tecapán",
      "Usulután",
    ],
  },
  Eslovenia: {
    Alta_Carniola: [
      "Bled",
      "Bohinj",
      "Cerklje na Gorenjskem",
      "Gorenja vas-Poljane",
      "Jesenice",
      "Jezersko",
      "Kranj",
      "Kranjska Gora",
      "Naklo",
      "Preddvor",
      "Radovljica",
      "Šenčur",
      "Škofja Loka",
      "Tržič",
      "Železniki",
      "Žiri",
      "Žirovnica",
    ],
    Gorizia: [
      "Ajdovščina",
      "Bovec",
      "Brda",
      "Cerkno",
      "Idrija",
      "Kanal ob Soči",
      "Kobarid",
      "Miren-Kostanjevica",
      "Nova Gorica",
      "Šempeter-Vrtojba",
      "Tolmin",
      "Vipava",
    ],
    Eslovenia_Sudoriental: [
      "Črnomelj ",
      "Dolenjske Toplice ",
      "MKočevje ",
      "Kostel ",
      "Loški Potok ",
      "Metlika",
      "Mirna ",
      "Mirna Peč ",
      "Mokronog-Trebelno ",
      "Novo Mesto ",
      "Osilnica ",
      "Ribnica ",
      "Semič ",
      "Sodražica ",
      "Straža",
      "Šentjernej ",
      "Šentrupert ",
      "Škocjan ",
      "Šmarješke Toplice",
      "Trebnje ",
      "MŽužemberk ",
    ],
    Carintia_eslovena: [
      "Črna na Koroškem",
      "Dravograd",
      "Mežica",
      "Mislinja",
      "Muta",
      "Podvelka",
      "Prevalje",
      "Radlje ob Dravi",
      "Ravne na Koroškem",
      "Ribnica na Pohorju",
      "Slovenj Gradec",
      "Vuzenica",
    ],
    Litoral_Carniola_Interior: [
      "Bloke ",
      "Cerknica ",
      "Ilirska Bistrica ",
      "Loška Dolina ",
      "Pivka ",
      "Postojna ",
    ],
    Litoral_Karst: [
      "Divača",
      "Hrpelje-Kozina",
      "Izola",
      "Komen",
      "Koper",
      "Piran",
      "Sežana",
    ],
    Eslovenia_Central: [
      "Borovnica",
      "Brezovica",
      "Dobrepolje",
      "Dobrova-Polhov Gradec",
      "Dol pri Ljubljani",
      "Domžale",
      "Grosuplje",
      "Horjul",
      "Ig",
      "Ivančna Gorica",
      "Kamnik",
      "Komenda",
      "Litija",
      "Liubliana (Ljubljana)",
      "Logatec",
      "Log-Dragomer",
      "Lukovica",
      "Medvode",
      "Mengeš",
      "Moravče",
      "Škofljica",
      "Šmartno pri Litiji",
      "Trzin",
      "Velike Lašče",
      "Vodice",
      "Vrhnika",
    ],
    Drava: [
      "Benedikt",
      "Cerkvenjak",
      "Destrnik",
      "Dornava",
      "Duplek",
      "Gorišnica",
      "Hajdina",
      "Hoče-Slivnica",
      "Juršinci",
      "Kidričevo",
      "Kungota",
      "Lenart",
      "Lovrenc na Pohorju",
      "Majšperk",
      "Maribor",
      "Markovci",
      "Miklavž na Dravskem polju",
      "Oplotnica",
      "Ormož",
      "Pesnica",
      "Podlehnik",
      "Ptuj",
      "Rače-Fram",
      "Ruše",
      "Selnica ob Dravi",
      "Šentilj",
      "Slovenska Bistrica",
      "Starše",
      "Sveta Ana",
      "Sveti Andraž v Slovenskih goricah",
      "Trnovska vas",
      "Videm",
      "Zavrč",
      "Žetale",
    ],
    Mura: [
      "Apače",
      "Beltinci",
      "Cankova",
      "Črenšovci",
      "Dobrovnik",
      "Gornja Radgona",
      "Gornji Petrovci",
      "Grad",
      "Hodoš",
      "Kobilje",
      "Križevci",
      "Kuzma",
      "Lendava",
      "Ljutomer",
      "Moravske Toplice",
      "Murska Sobota",
      "Odranci",
      "Puconci",
      "Radenci",
      "Razkrižje",
      "Rogašovci",
      "Šalovci",
      "Sveti Jurij ob Ščavnici",
      "Tišina",
      "Turnišče",
      "Velika Polana",
      "Veržej",
    ],
    Savinia: [
      "Bistrica ob Sotli",
      "Braslovče",
      "Celje",
      "Dobje",
      "Dobrna",
      "Gornji Grad",
      "Kozje",
      "Laško",
      "Ljubno",
      "Luče",
      "Mozirje",
      "Nazarje",
      "Podčetrtek",
      "Polzela",
      "Prebold",
      "Radeče",
      "Rogaška Slatina",
      "Rogatec",
      "Solčava",
      "Slovenske Konjice",
      "Šentjur",
      "Šmarje pri Jelšah",
      "Šmartno ob Paki",
      "Šoštanj",
      "Štore",
      "Tabor",
      "Velenje",
      "Vitanje",
      "Vojnik",
      "Vransko",
      "Zreče",
      "Žalec",
    ],
    Bajo_Sava: ["Brežice", "Kostanjevica na Krki", "Krško", "Sevnica"],
    Sava_Central: ["Hrastnik", "Trbovlje", "Zagorje ob Savi"],
  },
  Etiopía: {
    Afar: [
      "Afambo",
      "Asayita",
      "Chifra",
      "Dubti",
      "Elidar",
      "Kori",
      "Mille",
      "Abala",
      "Afdera",
      "Berhale",
      "Dallol",
      "Erebti",
      "Koneba",
      "Megale",
      "Amibara",
      "Awash Fentale",
      "Bure Mudaytu",
      "Dulecha",
      "Gewane",
      "Aura",
      "Ewa",
      "Gulina",
      "Teru",
      "Yalo",
      "Artuma",
      "Dalifage",
      "Dewe",
      "Fursi",
      "Hadele",
      "Ele",
      "Simurobi",
      "Telalak",
      "Woreda",
    ],
    Amhara: [
      "Agew Awi",
      "Bahir Dar Zuria",
      "Dangila",
      "Libo Kemekem",
      "Metemma",
      "Quara",
      "Awi",
      "East Belessa",
      "North Achefer",
      "North Gondar",
      "South Achefer",
      "West Gondar",
      "Alefa",
      "Bahir Dar",
      "Burie Zuria",
      "Dera",
      "Fogera",
      "Gondar",
      "Guangua",
      "Lake Tana",
      "Lay Armachiho",
      "Quara",
      "West Belessa",
    ],
    "Benishangul/Gumaz": ["Asosa", "Pawe", "Kamashi", " Metekel", "Mao-Komo"],
    Gambela: [
      "Harar",
      "Oromía",
      "Somalí (Ogadén)",
      "Naciones y Pueblos del Sur",
      "Pueblos del Suroeste",
      "Tigray",
    ],
    Harar: [
      "Alemaya",
      "Asbe Tefri",
      "Babile",
      "Chiro",
      "Deder",
      "Erer",
      "Goro Dola",
      "Gursum",
      "Harari",
      "Harar ",
      "Dire Dawa ",
    ],
    Oromía: [
      "Arsi",
      "Bale",
      "Borena",
      "East Hararghe",
      "West Hararghe",
      "Guji",
      "Wollega",
      "Horo Guduru Welega",
      "Illubabor",
      "Jimma",
      "Kelem Welega",
      "East Shewa",
      "West Shewa",
      "North Shewa",
      "South West Shewa",
      "East Wollega",
      "West Wollega",
      "Kelam Welega",
      "Mirab Shewa",
      "Addis Ababa",
    ],
    Somalí: [
      "Afder",
      "Dollo",
      "Fafan",
      "Gode",
      "Jijiga",
      "Liben",
      "Nogob",
      "Siti",
      "Warder",
    ],
    "Naciones y Pueblos del Sur": [
      "Bench Sheko",
      "Dawro",
      "Gamo Gofa",
      "Gedeo",
      "Gurage",
      "Hadiya",
      "Keffa",
      "Kembata Tembaro",
      "Sidama",
      "Silte",
      "Hawassa",
    ],
    "Pueblos del Suroeste": [
      "Bodi",
      "Kafa",
      "Keffa",
      "Mejenger",
      "Bench",
      "Sheko",
      "Surma",
      "Gimira",
      "Dime",
      "Mursi",
      "Nyangatom",
      "Hamer",
      "Dizi",
      "Me'en",
      "Ari",
      "Konso",
      "Male",
      "Chara",
      "Gamo",
      "Gofa",
      "Dawro",
      "Koore",
    ],
    Tigray: [
      "Abi Adi",
      "Adigrat",
      "Adwa",
      "Aksum",
      "Alamata",
      "Asgede Tsimbla",
      "Dansha",
      "Edaga Hamus",
      "Hawzen",
      "Humera",
      "Korem",
      "La'ilay Maychew",
      "Mekelle",
      "Raya Azebo",
      "Saesi Tsaedaemba",
      "Samre",
      "Shire",
      "Tahtay Maychew",
      "Wukro",
      "Zana",
    ],
    Sidama: [
      "Aleta Wendo",
      "Bensa",
      "Dale",
      "Dara",
      "Hawassa Zuria",
      "Hula",
      "Loka Abaya",
      "Malga",
      "Shebedino",
      "Sidama Zuria",
      "Wensho",
    ],
    "Adís/Abeba": [
      "Addis Ketema",
      "Akaki-Kality",
      "Arada",
      "Bole",
      "Gullele",
      "Kirkos",
      "Kolfe Keranio",
      "Lideta",
      "Nifas Silk-Lafto",
      "Yeka",
      "Dire Dawa",
    ],
  },
  España: {
    Andalucía: [
      "Almería",
      "Cádiz",
      "Córdoba",
      "Granada",
      "Huelva",
      "Jaén",
      "Málaga",
      "Sevilla",
    ],
    Aragón: ["Huesca", "Teruel", "Zaragoza"],
    "Asturias (Principado de)": ["Asturias"],
    "Balears (Illes)": ["Balears (Illes)"],
    Canarias: ["Palmas (Las)", "Santa Cruz de Tenerife"],
    Cantabria: ["Cantabria"],
    "Castilla y León": [
      "Ávila",
      "Burgos",
      "León",
      "Palencia",
      "Salamanca",
      "Segovia",
      "Soria",
      "Valladolid",
      "Zamora",
    ],
    "Castilla-La Mancha": [
      "Albacete",
      "Ciudad Real",
      "Cuenca",
      "Guadalajara",
      "Toledo",
    ],
    Cataluña: ["Barcelona", "Girona", "Lleida", "Tarragona"],
    "Ceuta (Ciudad de)": ["Ceuta"],
    "Comunitat Valenciana": [
      "Alicante/Alacant",
      "Castellón/Castelló",
      "Valencia/València",
    ],
    Extremadura: ["Badajoz", "Cáceres"],
    Galicia: ["Coruña (A)", "Lugo", "Ourense", "Pontevedra"],
    "Madrid (Comunidad de)": ["Madrid"],
    "Melilla (Ciudad de)": ["Melilla"],
    "Murcia (Región de)": ["Murcia"],
    "Navarra (Comunidad Foral de)": ["Navarra"],
    "País Vasco": ["Araba/Álava", "Bizkaia", "Gipuzkoa"],
    "Rioja (La)": ["Rioja (La)"],
  },
  "Emiratos Árabes Unidos": {
    "Abu Dhabi ": [
      "Al Ain",
      "Al Bahia",
      "Al Bateen",
      "Al Falah",
      "Al Karamah",
      "Al Khubeirah",
      "Al Maqtaa",
      "Al Markaziyah (Central Abu Dhabi)",
      "Al Maryah Island",
      "Al Matar",
      "Al Mina",
      "Al Moroor",
      "Al Mushrif",
      "Al Muzoon",
      "Al Nahyan",
      "Al Qubesat",
      "Al Raha",
      "Al Rahba",
      "Al Reef",
      "Al Reem Island",
      "Al Rowdah",
      "Al Samha",
      "Al Shamkha",
      "Al Shawamekh",
      "Al Wahdah",
      "Baniyas",
      "Bateen Airport Area",
      "Between Two Bridges",
      "Capital Centre",
      "Capital District",
      "City of Lights",
      "Corniche Area",
      "Danet Abu Dhabi",
      "Defence Street",
      "Delma Street",
      "Eastern Mangrove",
      "Electra Street",
      "Khalidiyah",
      "Khalifa City A",
      "Khalifa City B",
      "Khalifa City C",
      "Marina Village",
      "Masdar City",
      "Mohamed Bin Zayed City",
      "Muroor Road",
      "Mussafah",
      "New Shahama",
      "Officers Club Area",
      "Qasr Al Bahr",
      "Qasr El Shatie",
      "Saadiyat Island",
      "Shakhbout City",
      "Yas Island",
    ],
    Dubai: [
      "Al Awir",
      "Al Bada'a",
      "Al Baraha",
      "Al Barsha",
      "Al Garhoud",
      "Al Hamriya",
      "Al Karama",
      "Al Khail",
      "Al Mamzar",
      "Al Manara",
      "Al Mankhool",
      "Al Mizhar",
      "Al Muhaisnah",
      "Al Murar",
      "Al Quoz",
      "Al Qusais",
      "Al Rashidiya",
      "Al Rigga",
      "Al Safa",
      "Al Satwa",
      "Al Shindagha",
      "Al Souk Al Kabir",
      "Al Sufouh",
      "Al Twar",
      "Al Warqaa",
      "Al Wasl",
      "Arabian Ranches",
      "Business Bay",
      "City of Arabia",
      "Discovery Gardens",
      "Downtown Dubai",
      "Dubai Festival City",
      "Dubai Hills Estate",
      "Dubai International City",
      "Dubai Investment Park",
      "Dubai Marina",
      "Dubai Media City",
      "Dubai Silicon Oasis",
      "Dubai Sports City",
      "Dubai Studio City",
      "Dubai World Central",
      "Dubailand",
      "Emirates Hills",
      "Falcon City of Wonders",
      "Green Community",
      "Hatta",
      "Jebel Ali",
      "Jumeirah",
      "Jumeirah Beach Residence (JBR)",
      "Jumeirah Golf Estates",
      "Jumeirah Islands",
      "Jumeirah Lakes Towers (JLT)",
      "Jumeirah Park",
      "Jumeirah Village Circle (JVC)",
      "Jumeirah Village Triangle (JVT)",
      "Knowledge Village",
      "Mirdif",
      "Motor City",
      "Nad Al Hammar",
      "Nad Al Sheba",
      "Oud Al Muteena",
      "Palm Jumeirah",
      "Port Saeed",
      "Ras Al Khor",
      "Sheikh Zayed Road",
      "The Gardens",
      "The Greens",
      "The Lakes",
      "The Meadows",
      "The Springs",
      "The Views",
      "Umm Al Sheif",
      "Umm Hurair",
      "Umm Ramool",
      "Umm Suqeim",
      "Wafi City",
    ],
    Sharjah: [
      "Al Abar",
      "Al Azra",
      "Al Buhaira",
      "Al Dhaid",
      "Al Falaj",
      "Al Fayha",
      "Al Ghubaiba",
      "Al Gharayen",
      "Al Gharb",
      "Al Ghuwair",
      "Al Goaz",
      "Al Hazana",
      "Al Heera",
      "Al Jazzat",
      "Al Jubail",
      "Al Khan",
      "Al Majaz",
      "Al Mamzar",
      "Al Mirgab",
      "Al Mujarrah",
      "Al Musalla",
      "Al Nabba",
      "Al Nahda",
      "Al Nasserya",
      "Al Nekhailat",
      "Al Noaf",
      "Al Qadisiya",
      "Al Qasba",
      "Al Qasimia",
      "Al Ramla",
      "Al Ramtha",
      "Al Rifaah",
      "Al Rolla",
      "Al Sajaa",
      "Al Shahba",
      "Al Sharq",
      "Al Shuwaihean",
      "Al Soor",
      "Al Sweihat",
      "Al Taawun",
      "Al Tai",
      "Al Tala'a",
      "Al Turrfa",
      "Al Yarmook",
      "Al Zahia",
      "Bu Danig",
      "Halwan",
      "Hamriyah",
      "Hoshi",
      "Maysaloon",
      "Mughaidir",
      "Muwailih Commercial",
      "Muwailih Residential",
      "Nasma Residences",
      "Rolla Square",
      "Sharjah Airport Free Zone (SAIF Zone)",
      "Sharjah Industrial Area",
      "Sharjah Waterfront City",
      "University City",
    ],
    Ajman: [
      "Ajman Industrial Area",
      "Ajman Corniche",
      "Al Bustan",
      "Al Butain",
      "Al Hamriyah",
      "Al Helio",
      "Al Jarrf",
      "Al Jurf",
      "Al Mowaihat",
      "Al Muwaiji",
      "Al Nakhil",
      "Al Owan",
      "Al Rashidya",
      "Al Rumailah",
      "Al Zahra",
      "Corniche Ajman",
      "Emirates City",
      "Garden City",
      "Masfout",
      "Maysaloon",
      "Mushairef",
      "Nuaimiyah",
      "Rawda Al Rashid",
      "Rumailah",
      "Sheikh Khalifa Bin Zayed City",
    ],
    "Umm Al Quwain": [
      "Al Dar Al Baida",
      "Al Haditha",
      "Al Hawiyah",
      "Al Khalidiya",
      "Al Maidan",
      "Al Raas",
      "Al Raudah",
      "Al Riqqah",
      "Al Salamah",
      "Al Soor",
      "Al Warda",
      "Falaj Al Moalla",
      "Khor Al Beidah",
      "Masfut",
      "Umm Al Quwain Industrial Area",
    ],
    "Ras Al Khaimah": [
      "Adhan",
      "Al Daqdaqa",
      "Al Dhait",
      "Al Fahlain",
      "Al Ghail",
      "Al Ghalilah",
      "Al Hamraniyah",
      "Al Hudaibah",
      "Al Jazeera",
      "Al Juwais",
      "Al Kharran",
      "Al Mairid",
      "Al Mamourah",
      "Al Mataf",
      "Al Nakheel",
      "Al Rams",
      "Al Seer",
      "Al Turfa",
      "Al Uraibi",
      "Al Zait",
      "Dahan",
      "Diqdaqah",
      "Falieah",
      "Ghail",
      "Julphar",
      "Khatt",
      "Khor Khwair",
      "Mamourah",
      "Masafi",
      "Mina Al Arab",
      "Muntasir",
      "Nakheel",
      "Qawasim",
      "Qusaidat",
      "Rams",
      "Saqr Port",
      "Shamal Julphar",
      "Shawkha",
      "Wadi Al Qor",
      "Wadi Shaam",
      "Zaahib",
      "Zafir",
    ],
    Fujairah: [
      "Al Bithnah",
      "Al Faseel",
      "Al Gurfah",
      "Al Halah",
      "Al Hail",
      "Al Hayl Industrial Area",
      "Al Madhab",
      "Al Manama",
      "Al Merashid",
      "Al Qurayyah",
      "Dibba",
      "Dibba Al-Fujairah",
      "Fujairah City",
      "Khawr Fakkan",
      "Masafi",
      "Merbah",
      "Siji",
    ],
  },
  "Estados unidos / United States of America": {
    Alabama: [
      "Autauga",
      "Baldwin",
      "Barbour",
      "Bibb",
      "Blount",
      "Bullock",
      "Butler",
      "Calhoun",
      "Chambers",
      "Cherokee",
      "Chilton",
      "Choctaw",
      "Clarke",
      "Clay",
      "Cleburne",
      "Coffee",
      "Colbert",
      "Conecuh",
      "Coosa",
      "Covington",
      "Crenshaw",
      "Cullman",
      "Dale",
      "Dallas",
      "Dekalb",
      "Elmore",
      "Escambia",
      "Etowah",
      "Fayette",
      "Franklin",
      "Geneva",
      "Greene",
      "Hale",
      "Henry",
      "Houston",
      "Jackson",
      "Jefferson",
      "Lamar",
      "Lauderdale",
      "Lawrence",
      "Lee",
      "Limestone",
      "Lowndes",
      "Macon",
      "Madison",
      "Marengo",
      "Marion",
      "Marshall",
      "Mobile",
      "Monroe",
      "Montgomery",
      "Morgan",
      "Perry",
      "Pickens",
      "Pike",
      "Randolph",
      "Russell",
      "Saint Clair",
      "Shelby",
      "Sumter",
      "Talladega",
      "Tallapoosa",
      "Tuscaloosa",
      "Walker",
      "Washington",
      "Wilcox",
      "Winston",
    ],
    Alaska: [
      "Borough organizado",
      "Aleutianas Orientales",
      "Anchorage",
      "Bahía de Bristol",
      "Denali",
      "Fairbanks North Star",
      "Haines",
      "Juneau",
      "Península de Kenai",
      "Ketchikan Gateway",
      "Isla Kodiak",
      "Lake and Peninsula",
      "Matanuska-Susitna",
      "North Slope",
      "Northwest Arctic",
      "Petersburg",
      "Sitka",
      "Skagway",
      "Wrangell",
      "Yakutat",
    ],
    Arizona: [
      "Apache",
      "Cochise",
      "Coconino",
      "Gila",
      "Graham",
      "Greenlee",
      "La Paz",
      "Maricopa",
      "Mohave",
      "Navajo",
      "Pima",
      "Pinal",
      "Santa Cruz",
      "Yavapai",
      "Yuma",
    ],
    Arkansas: [
      "Arkansas",
      "Ashley",
      "Baxter",
      "Benton",
      "Boone",
      "Bradley",
      "Calhoun",
      "Carroll",
      "Chicot",
      "Clark",
      "Clay",
      "Cleburne",
      "Cleveland",
      "Columbia",
      "Conway",
      "Craighead",
      "Crawford",
      "Crittenden",
      "Cross",
      "Dallas",
      "Desha",
      "Drew",
      "Faulkner",
      "Franklin",
      "Fulton",
      "Garland",
      "Grant",
      "Greene",
      "Hempstead",
      "Hot Spring",
      "Howard",
      "Independence",
      "Izard",
      "Jackson",
      "Jefferson",
      "Johnson",
      "Lafayette",
      "Lawrence",
      "Lee",
      "Lincoln",
      "Little River",
      "Logan",
      "Lonoke",
      "Madison",
      "Marion",
      "Miller",
      "Mississippi",
      "Monroe",
      "Montgomery",
      "Nevada",
      "Newton",
      "Ouachita",
      "Perry",
      "Phillips",
      "Pike",
      "Poinsett",
      "Polk",
      "Pope",
      "Prairie",
      "Pulaski",
      "Randolph",
      "Saint Francis",
      "Saline",
      "Scott",
      "Searcy",
      "Sebastian",
      "Sevier",
      "Sharp",
      "Stone",
      "Union",
      "Van Buren",
      "Washington",
      "White",
      "Woodruff",
      "Yell",
    ],
    California: [
      "Alameda",
      "Alpine",
      "Amador",
      "Butte",
      "Calaveras",
      "Colusa",
      "Contra Costa",
      "Del Norte",
      "El Dorado",
      "Fresno",
      "Glenn",
      "Humboldt",
      "Imperial",
      "Inyo",
      "Kern",
      "Kings",
      "Lake",
      "Lassen",
      "Los Angeles",
      "Madera",
      "Marin",
      "Mariposa",
      "Mendocino",
      "Merced",
      "Modoc",
      "Mono",
      "Monterey",
      "Napa",
      "Nevada",
      "Orange",
      "Placer",
      "Plumas",
      "Riverside",
      "Sacramento",
      "San Benito",
      "San Bernardino",
      "San Diego",
      "San Francisco",
      "San Joaquin",
      "San Luis Obispo",
      "San Mateo",
      "Santa Barbara",
      "Santa Clara",
      "Santa Cruz",
      "Shasta",
      "Sierra",
      "Siskiyou",
      "Solano",
      "Sonoma",
      "Stanislaus",
      "Sutter",
      "Tehama",
      "Trinity",
      "Tulare",
      "Tuolumne",
      "Ventura",
      "Yolo",
      "Yuba",
    ],
    Colorado: [
      "Adams",
      "Alamosa",
      "Arapahoe",
      "Archuleta",
      "Baca",
      "Bent",
      "Boulder",
      "Broomfield",
      "Chaffee",
      "Cheyenne",
      "Clear Creek",
      "Conejos",
      "Costilla",
      "Crowley",
      "Custer",
      "Delta",
      "Denver",
      "Dolores",
      "Douglas",
      "Eagle",
      "El Paso",
      "Elbert",
      "Fremont",
      "Garfield",
      "Gilpin",
      "Grand",
      "Gunnison",
      "Hinsdale",
      "Huerfano",
      "Jackson",
      "Jefferson",
      "Kiowa",
      "Kit Carson",
      "La Plata",
      "Lake",
      "Larimer",
      "Las Animas",
      "Lincoln",
      "Logan",
      "Mesa",
      "Mineral",
      "Moffat",
      "Montezuma",
      "Montrose",
      "Morgan",
      "Otero",
      "Ouray",
      "Park",
      "Phillips",
      "Pitkin",
      "Prowers",
      "Pueblo",
      "Rio Blanco",
      "Rio Grande",
      "Routt",
      "Saguache",
      "San Juan",
      "San Miguel",
      "Sedgwick",
      "Summit",
      "Teller",
      "Washington",
      "Weld",
      "Yuma",
    ],
    "Commonwealth of Kentucky": [
      "Adair",
      "Allen",
      "Anderson ",
      "Ballard ",
      "Barren ",
      "Bath ",
      "Bell ",
      "Boone ",
      "Bourbon ",
      "Boyd ",
      "Boyle ",
      "Bracken ",
      "Breathitt ",
      "Breckinridge ",
      "Bullitt ",
      "Butler ",
      "Caldwell ",
      "Calloway ",
      "Campbell ",
      "Carlisle ",
      "Carroll ",
      "Carter ",
      "Casey ",
      "Christian ",
      "Clark ",
      "Clay ",
      "Clinton ",
      "Crittenden ",
      "Cumberland ",
      "Daviess ",
      "Edmonson ",
      "Elliott ",
      "Estill ",
      "Fayette ",
      "Fleming ",
      "Floyd ",
      "Franklin ",
      "Fulton ",
      "Gallatin ",
      "Garrard ",
      "Grant ",
      "Graves ",
      "Grayson ",
      "Green ",
      "Greenup ",
      "Hancock ",
      "Hardin ",
      "Harlan ",
      "Harrison ",
      "Hart ",
      "Henderson ",
      "Henry ",
      "Hickman ",
      "Hopkins ",
      "Jackson ",
      "Jefferson ",
      "Jessamine ",
      "Johnson ",
      "Kenton ",
      "Knott ",
      "Knox ",
      "Larue ",
      "Laurel ",
      "Lawrence ",
      "Lee ",
      "Leslie ",
      "Letcher ",
      "Lewis ",
      "Lincoln ",
      "Livingston ",
      "Logan ",
      "Lyon ",
      "Madison ",
      "Magoffin ",
      "Marion ",
      "Marshall ",
      "Martin ",
      "Mason ",
      "Mccracken ",
      "Mccreary ",
      "Mclean ",
      "Meade ",
      "Menifee ",
      "Mercer ",
      "Metcalfe ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "Muhlenberg ",
      "Nelson ",
      "Nicholas ",
      "Ohio ",
      "Oldham ",
      "Owen ",
      "Owsley ",
      "Pendleton ",
      "Perry ",
      "Pike ",
      "Powell ",
      "Pulaski ",
      "Robertson ",
      "Rockcastle ",
      "Rowan ",
      "Russell ",
      "Scott ",
      "Shelby ",
      "Simpson ",
      "Spencer ",
      "Taylor ",
      "Todd ",
      "Trigg ",
      "Trimble ",
      "Union ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Whitley ",
      "Wolfe ",
      "Woodford ",
    ],
    "Commonwealth of Massachusetts": [
      "Barnstable ",
      "Berkshire ",
      "Bristol ",
      "Dukes ",
      "Essex ",
      "Franklin ",
      "Hampden ",
      "Hampshire ",
      "Middlesex ",
      "Nantucket ",
      "Norfolk ",
      "Plymouth ",
      "Suffolk ",
      "Worcester ",
    ],
    "Commonwealth of Pennsylvania": [
      "Adams ",
      "Allegheny ",
      "Armstrong ",
      "Beaver ",
      "Bedford ",
      "Berks ",
      "Blair ",
      "Bradford ",
      "Bucks ",
      "Butler ",
      "Cambria ",
      "Cameron ",
      "Carbon ",
      "Centre ",
      "Chester ",
      "Clarion ",
      "Clearfield ",
      "Clinton ",
      "Columbia ",
      "Crawford ",
      "Cumberland ",
      "Dauphin ",
      "Delaware ",
      "Elk ",
      "Erie ",
      "Fayette ",
      "Forest ",
      "Franklin ",
      "Fulton ",
      "Greene ",
      "Huntingdon ",
      "Indiana ",
      "Jefferson ",
      "Juniata ",
      "Lackawanna ",
      "Lancaster ",
      "Lawrence ",
      "Lebanon ",
      "Lehigh ",
      "Luzerne ",
      "Lycoming ",
      "Mckean ",
      "Mercer ",
      "Mifflin ",
      "Monroe ",
      "Montgomery ",
      "Montour ",
      "Northampton ",
      "Northumberland ",
      "Perry ",
      "Philadelphia ",
      "Pike ",
      "Potter ",
      "Schuylkill ",
      "Snyder ",
      "Somerset ",
      "Sullivan ",
      "Susquehanna ",
      "Tioga ",
      "Union ",
      "Venango ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Westmoreland ",
      "Wyoming ",
      "York ",
    ],
    "Commonwealth of Virginia": [
      "Accomack ",
      "Albemarle ",
      "Alleghany ",
      "Amelia ",
      "Amherst ",
      "Appomattox ",
      "Arlington ",
      "Augusta ",
      "Bath ",
      "Bedford ",
      "Bland ",
      "Botetourt ",
      "Brunswick ",
      "Buchanan ",
      "Buckingham ",
      "Campbell ",
      "Caroline ",
      "Carroll ",
      "Charles City ",
      "Charlotte ",
      "Chesterfield ",
      "Clarke ",
      "Craig ",
      "Culpeper ",
      "Cumberland ",
      "Dickenson ",
      "Dinwiddie ",
      "Essex ",
      "Fairfax ",
      "Fauquier ",
      "Floyd ",
      "Fluvanna ",
      "Franklin ",
      "Frederick ",
      "Giles ",
      "Gloucester ",
      "Goochland ",
      "Grayson ",
      "Greene ",
      "Greensville ",
      "Halifax ",
      "Hanover ",
      "Henrico ",
      "Henry ",
      "Highland ",
      "Isle Of Wight ",
      "James City ",
      "King And Queen ",
      "King George ",
      "King William ",
      "Lancaster ",
      "Lee ",
      "Loudoun ",
      "Louisa ",
      "Lunenburg ",
      "Madison ",
      "Mathews ",
      "Mecklenburg ",
      "Middlesex ",
      "Montgomery ",
      "Nelson ",
      "New Kent ",
      "Northampton ",
      "Northumberland ",
      "Nottoway ",
      "Orange ",
      "Page ",
      "Patrick ",
      "Pittsylvania ",
      "Powhatan ",
      "Prince Edward ",
      "Prince George ",
      "Prince William ",
      "Pulaski ",
      "Rappahannock ",
      "Richmond ",
      "Roanoke ",
      "Rockbridge ",
      "Rockingham ",
      "Russell ",
      "Scott ",
      "Shenandoah ",
      "Smyth ",
      "Southampton ",
      "Spotsylvania ",
      "Stafford ",
      "Surry ",
      "Sussex ",
      "Tazewell ",
      "Warren ",
      "Washington ",
      "Westmoreland ",
      "Wise ",
      "Wythe ",
      "York ",
      "Alexandria City ",
      "Bedford City ",
      "Bristol City ",
      "Buena Vista City ",
      "Charlottesville City ",
      "Chesapeake City ",
      "Colonial Heights City ",
      "Covington City ",
      "Danville City ",
      "Emporia City ",
      "Fairfax City ",
      "Falls Church City ",
      "Franklin City ",
      "Fredericksburg City ",
      "Galax City ",
      "Hampton City ",
      "Harrisonburg City ",
      "Hopewell City ",
      "Lexington City ",
      "Lynchburg City ",
      "Manassas City ",
      "Manassas Park City ",
      "Martinsville City ",
      "Newport News City ",
      "Norfolk City ",
      "Norton City ",
      "Petersburg City ",
      "Poquoson City ",
      "Portsmouth City ",
      "Radford City ",
      "Richmond City ",
      "Roanoke City ",
      "Salem City ",
      "Staunton City ",
      "Suffolk City ",
      "Virginia Beach City ",
      "Waynesboro City ",
      "Williamsburg City ",
      "Winchester City ",
    ],
    Connecticut: [
      "Fairfield ",
      "Hartford ",
      "Litchfield ",
      "Middlesex ",
      "New Haven ",
      "New London ",
      "Tolland ",
      "Windham ",
    ],
    Delaware: ["Kent ", "New Castle ", "Sussex "],
    Florida: [
      "Alachua ",
      "Baker ",
      "Bay ",
      "Bradford ",
      "Brevard ",
      "Broward ",
      "Calhoun ",
      "Charlotte ",
      "Citrus ",
      "Clay ",
      "Collier ",
      "Columbia ",
      "De Soto ",
      "Dixie ",
      "Duval ",
      "Escambia ",
      "Flagler ",
      "Franklin ",
      "Gadsden ",
      "Gilchrist ",
      "Glades ",
      "Gulf ",
      "Hamilton ",
      "Hardee ",
      "Hendry ",
      "Hernando ",
      "Highlands ",
      "Hillsborough ",
      "Holmes ",
      "Indian River ",
      "Jackson ",
      "Jefferson ",
      "Lafayette ",
      "Lake ",
      "Lee ",
      "Leon ",
      "Levy ",
      "Liberty ",
      "Madison ",
      "Manatee ",
      "Marion ",
      "Martin ",
      "Miami-dade ",
      "Monroe ",
      "Nassau ",
      "Okaloosa ",
      "Okeechobee ",
      "Orange ",
      "Osceola ",
      "Palm Beach ",
      "Pasco ",
      "Pinellas ",
      "Polk ",
      "Putnam ",
      "Santa Rosa ",
      "Sarasota ",
      "Seminole ",
      "Saint Johns ",
      "Saint Lucie ",
      "Sumter ",
      "Suwannee ",
      "Taylor ",
      "Union ",
      "Volusia ",
      "Wakulla ",
      "Walton ",
      "Washington ",
    ],
    Georgia: [
      "Appling ",
      "Atkinson ",
      "Bacon ",
      "Baker ",
      "Baldwin ",
      "Banks ",
      "Barrow ",
      "Ben Hill ",
      "Berrien ",
      "Bibb ",
      "Bleckley ",
      "Brantley ",
      "Brooks ",
      "Bryan ",
      "Bulloch ",
      "Burke ",
      "Butts ",
      "Calhoun ",
      "Camden ",
      "Candler ",
      "Carroll ",
      "Catoosa ",
      "Charlton ",
      "Chatham ",
      "Chattahoochee ",
      "Chattooga ",
      "Cherokee ",
      "Clarke ",
      "Clay ",
      "Clayton ",
      "Clinch ",
      "Cobb ",
      "Coffee ",
      "Colquitt ",
      "Columbia ",
      "Cook ",
      "Coweta ",
      "Crawford ",
      "Crisp ",
      "Dade ",
      "Dawson ",
      "Decatur ",
      "Dekalb ",
      "Dodge ",
      "Dooly ",
      "Dougherty ",
      "Douglas ",
      "Early ",
      "Echols ",
      "Effingham ",
      "Elbert ",
      "Emanuel ",
      "Evans ",
      "Fannin ",
      "Fayette ",
      "Floyd ",
      "Forsyth ",
      "Franklin ",
      "Fulton ",
      "Gilmer ",
      "Glascock ",
      "Glynn ",
      "Gordon ",
      "Grady ",
      "Greene ",
      "Gwinnett ",
      "Habersham ",
      "Hall ",
      "Hancock ",
      "Haralson ",
      "Harris ",
      "Hart ",
      "Heard ",
      "Henry ",
      "Houston ",
      "Irwin ",
      "Jackson ",
      "Jasper ",
      "Jeff Davis ",
      "Jefferson ",
      "Jenkins ",
      "Johnson ",
      "Jones ",
      "Lamar ",
      "Lanier ",
      "Laurens ",
      "Lee ",
      "Liberty ",
      "Lincoln ",
      "Long ",
      "Lowndes ",
      "Lumpkin ",
      "Macon ",
      "Madison ",
      "Marion ",
      "Mcduffie ",
      "Mcintosh ",
      "Meriwether ",
      "Miller ",
      "Mitchell ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "Murray ",
      "Muscogee ",
      "Newton ",
      "Oconee ",
      "Oglethorpe ",
      "Paulding ",
      "Peach ",
      "Pickens ",
      "Pierce ",
      "Pike ",
      "Polk ",
      "Pulaski ",
      "Putnam ",
      "Quitman ",
      "Rabun ",
      "Randolph ",
      "Richmond ",
      "Rockdale ",
      "Schley ",
      "Screven ",
      "Seminole ",
      "Spalding ",
      "Stephens ",
      "Stewart ",
      "Sumter ",
      "Talbot ",
      "Taliaferro ",
      "Tattnall ",
      "Taylor ",
      "Telfair ",
      "Terrell ",
      "Thomas ",
      "Tift ",
      "Toombs ",
      "Towns ",
      "Treutlen ",
      "Troup ",
      "Turner ",
      "Twiggs ",
      "Union ",
      "Upson ",
      "Walker ",
      "Walton ",
      "Ware ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Wheeler ",
      "White ",
      "Whitfield ",
      "Wilcox ",
      "Wilkes ",
      "Wilkinson ",
      "Worth ",
      "Bartow ",
    ],
    "Hawaii, Moku'āina o Hawai'i": [
      "Hawaii ",
      "Honolulu ",
      "Kalawao ",
      "Kauai ",
      "Maui ",
    ],
    Idaho: [
      "Ada ",
      "Adams ",
      "Bannock ",
      "Bear Lake ",
      "Benewah ",
      "Bingham ",
      "Blaine ",
      "Boise ",
      "Bonner ",
      "Bonneville ",
      "Boundary ",
      "Butte ",
      "Camas ",
      "Canyon ",
      "Caribou ",
      "Cassia ",
      "Clark ",
      "Clearwater ",
      "Custer ",
      "Elmore ",
      "Franklin ",
      "Fremont ",
      "Gem ",
      "Gooding ",
      "Idaho ",
      "Jefferson ",
      "Jerome ",
      "Kootenai ",
      "Latah ",
      "Lemhi ",
      "Lewis ",
      "Lincoln ",
      "Madison ",
      "Minidoka ",
      "Nez Perce ",
      "Oneida ",
      "Owyhee ",
      "Payette ",
      "Power ",
      "Shoshone ",
      "Teton ",
      "Twin Falls ",
      "Valley ",
      "Washington ",
    ],
    Illinois: [
      "Adams ",
      "Alexander ",
      "Bond ",
      "Boone ",
      "Brown ",
      "Bureau ",
      "Calhoun ",
      "Carroll ",
      "Cass ",
      "Champaign ",
      "Christian ",
      "Clark ",
      "Clay ",
      "Clinton ",
      "Coles ",
      "Cook ",
      "Crawford ",
      "Cumberland ",
      "Dekalb ",
      "De Witt ",
      "Douglas ",
      "Dupage ",
      "Edgar ",
      "Edwards ",
      "Effingham ",
      "Fayette ",
      "Ford ",
      "Franklin ",
      "Fulton ",
      "Gallatin ",
      "Greene ",
      "Grundy ",
      "Hamilton ",
      "Hancock ",
      "Hardin ",
      "Henderson ",
      "Henry ",
      "Iroquois ",
      "Jackson ",
      "Jasper ",
      "Jefferson ",
      "Jersey ",
      "Jo Daviess ",
      "Johnson ",
      "Kane ",
      "Kankakee ",
      "Kendall ",
      "Knox ",
      "Lake ",
      "Lasalle ",
      "Lawrence ",
      "Lee ",
      "Livingston ",
      "Logan ",
      "Mcdonough ",
      "Mchenry ",
      "Mclean ",
      "Macon ",
      "Macoupin ",
      "Madison ",
      "Marion ",
      "Marshall ",
      "Mason ",
      "Massac ",
      "Menard ",
      "Mercer ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "Moultrie ",
      "Ogle ",
      "Peoria ",
      "Perry ",
      "Piatt ",
      "Pike ",
      "Pope ",
      "Pulaski ",
      "Putnam ",
      "Randolph ",
      "Richland ",
      "Rock Island ",
      "St. Clair ",
      "Saline ",
      "Sangamon ",
      "Schuyler ",
      "Scott ",
      "Shelby ",
      "Stark ",
      "Stephenson ",
      "Tazewell ",
      "Union ",
      "Vermilion ",
      "Wabash ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "White ",
      "Whiteside ",
      "Will ",
      "Williamson ",
      "Winnebago ",
      "Woodford ",
    ],
    Indiana: [
      "Adams ",
      "Allen ",
      "Bartholomew ",
      "Benton ",
      "Blackford ",
      "Boone ",
      "Brown ",
      "Carroll ",
      "Cass ",
      "Clark ",
      "Clay ",
      "Clinton ",
      "Crawford ",
      "Daviess ",
      "Dearborn ",
      "Decatur ",
      "Dekalb ",
      "Delaware ",
      "Dubois ",
      "Elkhart ",
      "Fayette ",
      "Floyd ",
      "Fountain ",
      "Franklin ",
      "Fulton ",
      "Gibson ",
      "Grant ",
      "Greene ",
      "Hamilton ",
      "Hancock ",
      "Harrison ",
      "Hendricks ",
      "Henry ",
      "Howard ",
      "Huntington ",
      "Jackson ",
      "Jasper ",
      "Jay ",
      "Jefferson ",
      "Jennings ",
      "Johnson ",
      "Knox ",
      "Kosciusko ",
      "Lagrange ",
      "Lake ",
      "Laporte ",
      "Lawrence ",
      "Madison ",
      "Marion ",
      "Marshall ",
      "Martin ",
      "Miami ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "Newton ",
      "Noble ",
      "Ohio ",
      "Orange ",
      "Owen ",
      "Parke ",
      "Perry ",
      "Pike ",
      "Porter ",
      "Posey ",
      "Pulaski ",
      "Putnam ",
      "Randolph ",
      "Ripley ",
      "Rush ",
      "St. Joseph ",
      "Scott ",
      "Shelby ",
      "Spencer ",
      "Starke ",
      "Steuben ",
      "Sullivan ",
      "Switzerland ",
      "Tippecanoe ",
      "Tipton ",
      "Union ",
      "Vanderburgh ",
      "Vermillion ",
      "Vigo ",
      "Wabash ",
      "Warren ",
      "Warrick ",
      "Washington ",
      "Wayne ",
      "Wells ",
      "White ",
      "Whitley ",
    ],
    Iowa: [
      "Adair ",
      "Adams ",
      "Allamakee ",
      "Appanoose ",
      "Audubon ",
      "Benton ",
      "Black Hawk ",
      "Boone ",
      "Bremer ",
      "Buchanan ",
      "Buena Vista ",
      "Butler ",
      "Calhoun ",
      "Carroll ",
      "Cass ",
      "Cedar ",
      "Cerro Gordo ",
      "Cherokee ",
      "Chickasaw ",
      "Clarke ",
      "Clay ",
      "Clayton ",
      "Clinton ",
      "Crawford ",
      "Dallas ",
      "Davis ",
      "Decatur ",
      "Delaware ",
      "Des Moines ",
      "Dickinson ",
      "Dubuque ",
      "Emmet ",
      "Fayette ",
      "Floyd ",
      "Franklin ",
      "Fremont ",
      "Greene ",
      "Grundy ",
      "Guthrie ",
      "Hamilton ",
      "Hancock ",
      "Hardin ",
      "Harrison ",
      "Henry ",
      "Howard ",
      "Humboldt ",
      "Ida ",
      "Iowa ",
      "Jackson ",
      "Jasper ",
      "Jefferson ",
      "Johnson ",
      "Jones ",
      "Keokuk ",
      "Kossuth ",
      "Lee ",
      "Linn ",
      "Louisa ",
      "Lucas ",
      "Lyon ",
      "Madison ",
      "Mahaska ",
      "Marion ",
      "Marshall ",
      "Mills ",
      "Mitchell ",
      "Monona ",
      "Monroe ",
      "Montgomery ",
      "Muscatine ",
      "O'brien ",
      "Osceola ",
      "Page ",
      "Palo Alto ",
      "Plymouth ",
      "Pocahontas ",
      "Polk ",
      "Pottawattamie ",
      "Poweshiek ",
      "Ringgold ",
      "Sac ",
      "Scott ",
      "Shelby ",
      "Sioux ",
      "Story ",
      "Tama ",
      "Taylor ",
      "Union ",
      "Van Buren ",
      "Wapello ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Winnebago ",
      "Winneshiek ",
      "Woodbury ",
      "Worth ",
      "Wright ",
    ],
    Kansas: [
      "Adair ",
      "Adams ",
      "Allamakee ",
      "Appanoose ",
      "Audubon ",
      "Benton ",
      "Black Hawk ",
      "Boone ",
      "Bremer ",
      "Buchanan ",
      "Buena Vista ",
      "Butler ",
      "Calhoun ",
      "Carroll ",
      "Cass ",
      "Cedar ",
      "Cerro Gordo ",
      "Cherokee ",
      "Chickasaw ",
      "Clarke ",
      "Clay ",
      "Clayton ",
      "Clinton ",
      "Crawford ",
      "Dallas ",
      "Davis ",
      "Decatur ",
      "Delaware ",
      "Des Moines ",
      "Dickinson ",
      "Dubuque ",
      "Emmet ",
      "Fayette ",
      "Floyd ",
      "Franklin ",
      "Fremont ",
      "Greene ",
      "Grundy ",
      "Guthrie ",
      "Hamilton ",
      "Hancock ",
      "Hardin ",
      "Harrison ",
      "Henry ",
      "Howard ",
      "Humboldt ",
      "Ida ",
      "Iowa ",
      "Jackson ",
      "Jasper ",
      "Jefferson ",
      "Johnson ",
      "Jones ",
      "Keokuk ",
      "Kossuth ",
      "Lee ",
      "Linn ",
      "Louisa ",
      "Lucas ",
      "Lyon ",
      "Madison ",
      "Mahaska ",
      "Marion ",
      "Marshall ",
      "Mills ",
      "Mitchell ",
      "Monona ",
      "Monroe ",
      "Montgomery ",
      "Muscatine ",
      "O'brien ",
      "Osceola ",
      "Page ",
      "Palo Alto ",
      "Plymouth ",
      "Pocahontas ",
      "Polk ",
      "Pottawattamie ",
      "Poweshiek ",
      "Ringgold ",
      "Sac ",
      "Scott ",
      "Shelby ",
      "Sioux ",
      "Story ",
      "Tama ",
      "Taylor ",
      "Union ",
      "Van Buren ",
      "Wapello ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Winnebago ",
      "Winneshiek ",
      "Woodbury ",
      "Worth ",
      "Wright ",
    ],
    "Louisiana, État de Louisiane": [
      "Acadia ",
      "Allen ",
      "Ascension ",
      "Assumption ",
      "Avoyelles ",
      "East Baton Rouge ",
      "West Baton Rouge ",
      "Beauregard ",
      "Bienville ",
      "Bossier ",
      "Caddo ",
      "Calcasieu ",
      "Caldwell ",
      "Cameron ",
      "East Carroll ",
      "West Carroll ",
      "Catahoula ",
      "Claiborne ",
      "Concordia ",
      "De Soto ",
      "Evangeline ",
      "East Feliciana ",
      "West Feliciana ",
      "Franklin ",
      "Grant ",
      "Iberia ",
      "Iberville ",
      "Jackson ",
      "Jefferson ",
      "Jefferson Davis ",
      "La Salle ",
      "Lafayette ",
      "Lafourche ",
      "Lincoln ",
      "Livingston ",
      "Madison ",
      "Morehouse ",
      "Natchitoches ",
      "Orléans ",
      "Ouachita ",
      "Plaquemines ",
      "Pointe Coupée ",
      "Rapides ",
      "Red River ",
      "Richland ",
      "Sabine ",
      "Saint-bernard ",
      "Saint-charles ",
      "Sainte-héléna ",
      "Saint-jacques ",
      "Saint-jean-baptiste ",
      "Saint-landry ",
      "Saint-martin ",
      "Saint-mary ",
      "Saint-tammany ",
      "Tangipahoa ",
      "Tensas ",
      "Terrebonne ",
      "Union ",
      "Vermilion ",
      "Vernon ",
      "Washington ",
      "Webster ",
      "Winn ",
    ],
    Maine: [
      "Androscoggin ",
      "Aroostook ",
      "Cumberland ",
      "Franklin ",
      "Hancock ",
      "Kennebec ",
      "Knox ",
      "Lincoln ",
      "Oxford ",
      "Penobscot ",
      "Piscataquis ",
      "Sagadahoc ",
      "Somerset ",
      "Waldo ",
      "Washington ",
      "York ",
    ],
    Maryland: [
      "Baltimore City ",
      "Allegany ",
      "Anne Arundel ",
      "Baltimore ",
      "Calvert ",
      "Caroline ",
      "Carroll ",
      "Cecil ",
      "Charles ",
      "Dorchester ",
      "Frederick ",
      "Garrett ",
      "Harford ",
      "Howard ",
      "Kent ",
      "Montgomery ",
      "Prince George ",
      "Queen Anne ",
      "St. Mary ",
      "Somerset ",
      "Talbot ",
      "Washington ",
      "Wicomico ",
      "Worcester ",
    ],
    Michigan: [
      "Alcona ",
      "Alger ",
      "Allegan ",
      "Alpena ",
      "Antrim ",
      "Arenac ",
      "Baraga ",
      "Barry ",
      "Bay ",
      "Benzie ",
      "Berrien ",
      "Branch ",
      "Calhoun ",
      "Cass ",
      "Charlevoix ",
      "Cheboygan ",
      "Chippewa ",
      "Clare ",
      "Clinton ",
      "Crawford ",
      "Delta ",
      "Dickinson ",
      "Eaton ",
      "Emmet ",
      "Genesee ",
      "Gladwin ",
      "Gogebic ",
      "Grand Traverse ",
      "Gratiot ",
      "Hillsdale ",
      "Houghton ",
      "Huron ",
      "Ingham ",
      "Ionia ",
      "Iosco ",
      "Iron ",
      "Isabella ",
      "Jackson ",
      "Kalamazoo ",
      "Kalkaska ",
      "Kent ",
      "Keweenaw ",
      "Lake ",
      "Lapeer ",
      "Leelanau ",
      "Lenawee ",
      "Livingston ",
      "Luce ",
      "Mackinac ",
      "Macomb ",
      "Manistee ",
      "Marquette ",
      "Mason ",
      "Mecosta ",
      "Menominee ",
      "Midland ",
      "Missaukee ",
      "Monroe ",
      "Montcalm ",
      "Montmorency ",
      "Muskegon ",
      "Newaygo ",
      "Oakland ",
      "Oceana ",
      "Ogemaw ",
      "Ontonagon ",
      "Osceola ",
      "Oscoda ",
      "Otsego ",
      "Ottawa ",
      "Presque Isle ",
      "Roscommon ",
      "Saginaw ",
      "Sainte-claire ",
      "Saint-joseph ",
      "Sanilac ",
      "Schoolcraft ",
      "Shiawassee ",
      "Tuscola ",
      "Van Buren ",
      "Washtenaw ",
      "Wayne ",
      "Wexford ",
    ],
    Minnesota: [
      "Aitkin ",
      "Anoka ",
      "Becker ",
      "Beltrami ",
      "Benton ",
      "Big Stone ",
      "Blue Earth ",
      "Brown ",
      "Carlton ",
      "Carver ",
      "Cass ",
      "Chippewa ",
      "Chisago ",
      "Clay ",
      "Clearwater ",
      "Cook ",
      "Cottonwood ",
      "Crow Wing ",
      "Dakota ",
      "Dodge ",
      "Douglas ",
      "Faribault ",
      "Fillmore ",
      "Freeborn ",
      "Goodhue ",
      "Grant ",
      "Hennepin ",
      "Houston ",
      "Hubbard ",
      "Isanti ",
      "Itasca ",
      "Jackson ",
      "Kanabec ",
      "Kandiyohi ",
      "Kittson ",
      "Koochiching ",
      "Lac Qui Parle ",
      "Lake ",
      "Lake Of The Woods ",
      "Le Sueur ",
      "Lincoln ",
      "Lyon ",
      "Mcleod ",
      "Mahnomen ",
      "Marshall ",
      "Martin ",
      "Meeker ",
      "Mille Lacs ",
      "Morrison ",
      "Mower ",
      "Murray ",
      "Nicollet ",
      "Nobles ",
      "Norman ",
      "Olmsted ",
      "Otter Tail ",
      "Pennington ",
      "Pine ",
      "Pipestone ",
      "Polk ",
      "Pope ",
      "Ramsey ",
      "Red Lake ",
      "Redwood ",
      "Renville ",
      "Rice ",
      "Rock ",
      "Roseau ",
      "St. Louis ",
      "Scott ",
      "Sherburne ",
      "Sibley ",
      "Stearns ",
      "Steele ",
      "Stevens ",
      "Swift ",
      "Todd ",
      "Traverse ",
      "Wabasha ",
      "Wadena ",
      "Waseca ",
      "Washington ",
      "Watonwan ",
      "Wilkin ",
      "Winona ",
      "Wright ",
      "Yellow Medicine ",
    ],
    Mississippi: [
      "Adams ",
      "Alcorn ",
      "Amite ",
      "Attala ",
      "Benton ",
      "Bolivar ",
      "Calhoun ",
      "Carroll ",
      "Chickasaw ",
      "Choctaw ",
      "Claiborne ",
      "Clarke ",
      "Clay ",
      "Coahoma ",
      "Copiah ",
      "Covington ",
      "Desoto ",
      "Forrest ",
      "Franklin ",
      "George ",
      "Greene ",
      "Grenada ",
      "Hancock ",
      "Harrison ",
      "Hinds ",
      "Holmes ",
      "Humphreys ",
      "Issaquena ",
      "Itawamba ",
      "Jackson ",
      "Jasper ",
      "Jefferson ",
      "Jefferson Davis ",
      "Jones ",
      "Kemper ",
      "Lafayette ",
      "Lamar ",
      "Lauderdale ",
      "Lawrence ",
      "Leake ",
      "Lee ",
      "Leflore ",
      "Lincoln ",
      "Lowndes ",
      "Madison ",
      "Marion ",
      "Marshall ",
      "Monroe ",
      "Montgomery ",
      "Neshoba ",
      "Newton ",
      "Noxubee ",
      "Oktibbeha ",
      "Panola ",
      "Pearl River ",
      "Perry ",
      "Pike ",
      "Pontotoc ",
      "Prentiss ",
      "Quitman ",
      "Rankin ",
      "Scott ",
      "Sharkey ",
      "Simpson ",
      "Smith ",
      "Stone ",
      "Sunflower ",
      "Tallahatchie ",
      "Tate ",
      "Tippah ",
      "Tishomingo ",
      "Tunica ",
      "Union ",
      "Walthall ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Wilkinson ",
      "Winston ",
      "Yalobusha ",
      "Yazoo ",
    ],
    Missouri: [
      "Adair ",
      "Andrew ",
      "Atchison ",
      "Audrain ",
      "Barry ",
      "Barton ",
      "Bates ",
      "Benton ",
      "Bollinger ",
      "Boone ",
      "Buchanan ",
      "Butler ",
      "Caldwell ",
      "Callaway ",
      "Camden ",
      "Cape Girardeau ",
      "Carroll ",
      "Carter ",
      "Cass ",
      "Cedar ",
      "Chariton ",
      "Christian ",
      "Clark ",
      "Clay ",
      "Clinton ",
      "Cole ",
      "Cooper ",
      "Crawford ",
      "Dade ",
      "Dallas ",
      "Daviess ",
      "Dekalb ",
      "Dent ",
      "Douglas ",
      "Dunklin ",
      "Franklin ",
      "Gasconade ",
      "Gentry ",
      "Greene ",
      "Grundy ",
      "Harrison ",
      "Henry ",
      "Hickory ",
      "Holt ",
      "Howard ",
      "Howell ",
      "Iron ",
      "Jackson ",
      "Jasper ",
      "Jefferson ",
      "Johnson ",
      "Knox ",
      "Laclede ",
      "Lafayette ",
      "Lawrence ",
      "Lewis ",
      "Lincoln ",
      "Linn ",
      "Livingston ",
      "Mcdonald ",
      "Macon ",
      "Madison ",
      "Maries ",
      "Marion ",
      "Mercer ",
      "Miller ",
      "Mississippi ",
      "Moniteau ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "New Madrid ",
      "Newton ",
      "Nodaway ",
      "Oregon ",
      "Osage ",
      "Ozark ",
      "Pemiscot ",
      "Perry ",
      "Pettis ",
      "Phelps ",
      "Pike ",
      "Platte ",
      "Polk ",
      "Pulaski ",
      "Putnam ",
      "Ralls ",
      "Randolph ",
      "Ray ",
      "Reynolds ",
      "Ripley ",
      "St. Charles ",
      "St. Clair ",
      "Ste. Genevieve ",
      "St. Francois ",
      "St. Louis City ",
      "Saline ",
      "Schuyler ",
      "Scotland ",
      "Scott ",
      "Shannon ",
      "Shelby ",
      "Stoddard ",
      "Stone ",
      "Sullivan ",
      "Taney ",
      "Texas ",
      "Vernon ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Worth ",
      "Wright ",
      "St. Louis ",
    ],
    Montana: [
      "Beaverhead ",
      "Big Horn ",
      "Blaine ",
      "Broadwater ",
      "Carbon ",
      "Carter ",
      "Cascade ",
      "Chouteau ",
      "Custer ",
      "Daniels ",
      "Dawson ",
      "Deer Lodge ",
      "Fallon ",
      "Fergus ",
      "Flathead ",
      "Gallatin ",
      "Garfield ",
      "Glacier ",
      "Golden Valley ",
      "Granite ",
      "Hill ",
      "Jefferson ",
      "Judith Basin ",
      "Lake ",
      "Lewis And Clark ",
      "Liberty ",
      "Lincoln ",
      "Mccone ",
      "Madison ",
      "Meagher ",
      "Mineral ",
      "Missoula ",
      "Musselshell ",
      "Park ",
      "Petroleum ",
      "Phillips ",
      "Pondera ",
      "Powder River ",
      "Powell ",
      "Prairie ",
      "Ravalli ",
      "Richland ",
      "Roosevelt ",
      "Rosebud ",
      "Sanders ",
      "Sheridan ",
      "Silver Bow ",
      "Stillwater ",
      "Sweet Grass ",
      "Teton ",
      "Toole ",
      "Treasure ",
      "Valley ",
      "Wheatland ",
      "Wibaux ",
      "Yellowstone ",
    ],
    Nebraska: [
      "Adams ",
      "Antelope ",
      "Arthur ",
      "Banner ",
      "Blaine ",
      "Boone ",
      "Box Butte ",
      "Boyd ",
      "Brown ",
      "Buffalo ",
      "Burt ",
      "Butler ",
      "Cass ",
      "Cedar ",
      "Chase ",
      "Cherry ",
      "Cheyenne ",
      "Clay ",
      "Colfax ",
      "Cuming ",
      "Custer ",
      "Dakota ",
      "Dawes ",
      "Dawson ",
      "Deuel ",
      "Dixon ",
      "Dodge ",
      "Douglas ",
      "Dundy ",
      "Fillmore ",
      "Franklin ",
      "Frontier ",
      "Furnas ",
      "Gage ",
      "Garden ",
      "Garfield ",
      "Gosper ",
      "Grant ",
      "Greeley ",
      "Hall ",
      "Hamilton ",
      "Harlan ",
      "Hayes ",
      "Hitchcock ",
      "Holt ",
      "Hooker ",
      "Howard ",
      "Jefferson ",
      "Johnson ",
      "Kearney ",
      "Keith ",
      "Keya Paha ",
      "Kimball ",
      "Knox ",
      "Lancaster ",
      "Lincoln ",
      "Logan ",
      "Loup ",
      "Madison ",
      "Mcpherson ",
      "Merrick ",
      "Morrill ",
      "Nance ",
      "Nemaha ",
      "Nuckolls ",
      "Otoe ",
      "Pawnee ",
      "Perkins ",
      "Phelps ",
      "Pierce ",
      "Platte ",
      "Polk ",
      "Red Willow ",
      "Richardson ",
      "Rock ",
      "Saline ",
      "Sarpy ",
      "Saunders ",
      "Scotts Bluff ",
      "Seward ",
      "Sheridan ",
      "Sherman ",
      "Sioux ",
      "Stanton ",
      "Thayer ",
      "Thomas ",
      "Thurston ",
      "Valley ",
      "Washington ",
      "Wayne ",
      "Webster ",
      "Wheeler ",
      "York ",
    ],
    Nevada: [
      "Churchill ",
      "Clark ",
      "Douglas ",
      "Elko ",
      "Esmeralda ",
      "Eureka ",
      "Humboldt ",
      "Lander ",
      "Lincoln ",
      "Lyon ",
      "Mineral ",
      "Nye ",
      "Pershing ",
      "Storey ",
      "Washoe ",
      "White Pine ",
      "Carson City",
    ],
    "New Hampshire": [
      "Belknap ",
      "Carroll ",
      "Cheshire ",
      "Coos ",
      "Grafton ",
      "Hillsborough ",
      "Merrimack ",
      "Rockingham ",
      "Strafford ",
      "Sullivan ",
    ],
    "New Jersey": [
      "Atlantic ",
      "Bergen ",
      "Burlington ",
      "Camden ",
      "Cape May ",
      "Cumberland ",
      "Essex ",
      "Gloucester ",
      "Hudson ",
      "Hunterdon ",
      "Mercer ",
      "Middlesex ",
      "Monmouth ",
      "Morris ",
      "Ocean ",
      "Passaic ",
      "Salem ",
      "Somerset ",
      "Sussex ",
      "Union ",
      "Warren ",
    ],
    "New Mexico": [
      "Bernalillo ",
      "Catron ",
      "Chaves ",
      "Cibola ",
      "Colfax ",
      "Curry ",
      "De Baca ",
      "Dona Ana ",
      "Eddy ",
      "Grant ",
      "Guadalupe ",
      "Harding ",
      "Hidalgo ",
      "Lea ",
      "Lincoln ",
      "Los Alamos ",
      "Luna ",
      "Mckinley ",
      "Mora ",
      "Otero ",
      "Quay ",
      "Rio Arriba ",
      "Roosevelt ",
      "San Juan ",
      "San Miguel ",
      "Sandoval ",
      "Santa Fe ",
      "Sierra ",
      "Socorro ",
      "Taos ",
      "Torrance ",
      "Union ",
      "Valencia ",
    ],
    "New York": [
      "Albany ",
      "Allegany ",
      "Bronx ",
      "Broome ",
      "Cattaraugus ",
      "Cayuga ",
      "Chautauqua ",
      "Chemung ",
      "Chenango ",
      "Clinton ",
      "Columbia ",
      "Cortland ",
      "Delaware ",
      "Dutchess ",
      "Erie ",
      "Essex ",
      "Franklin ",
      "Fulton ",
      "Genesee ",
      "Greene ",
      "Hamilton ",
      "Herkimer ",
      "Jefferson ",
      "Kings ",
      "Lewis ",
      "Livingston ",
      "Madison ",
      "Monroe ",
      "Montgomery ",
      "Nassau ",
      "New York ",
      "Niagara ",
      "Oneida ",
      "Onondaga ",
      "Ontario ",
      "Orange ",
      "Orleans ",
      "Oswego ",
      "Otsego ",
      "Putnam ",
      "Queens ",
      "Rensselaer ",
      "Richmond ",
      "Rockland ",
      "Saratoga ",
      "Schenectady ",
      "Schoharie ",
      "Schuyler ",
      "Seneca ",
      "St. Lawrence ",
      "Steuben ",
      "Suffolk ",
      "Sullivan ",
      "Tioga ",
      "Tompkins ",
      "Ulster ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Westchester ",
      "Wyoming ",
      "Yates ",
    ],
    "North Carolina": [
      "Alamance ",
      "Alexander ",
      "Alleghany ",
      "Anson ",
      "Ashe ",
      "Avery ",
      "Beaufort ",
      "Bertie ",
      "Bladen ",
      "Brunswick ",
      "Buncombe ",
      "Burke ",
      "Cabarrus ",
      "Caldwell ",
      "Camden ",
      "Carteret ",
      "Caswell ",
      "Catawba ",
      "Chatham ",
      "Cherokee ",
      "Chowan ",
      "Clay ",
      "Cleveland ",
      "Columbus ",
      "Craven ",
      "Cumberland ",
      "Currituck ",
      "Dare ",
      "Davidson ",
      "Davie ",
      "Duplin ",
      "Durham ",
      "Edgecombe ",
      "Forsyth ",
      "Franklin ",
      "Gaston ",
      "Gates ",
      "Graham ",
      "Granville ",
      "Greene ",
      "Guilford ",
      "Halifax ",
      "Harnett ",
      "Haywood ",
      "Henderson ",
      "Hertford ",
      "Hoke ",
      "Hyde ",
      "Iredell ",
      "Jackson ",
      "Johnston ",
      "Jones ",
      "Lee ",
      "Lenoir ",
      "Lincoln ",
      "Macon ",
      "Madison ",
      "Martin ",
      "Mcdowell ",
      "Mecklenburg ",
      "Mitchell ",
      "Montgomery ",
      "Moore ",
      "Nash ",
      "New Hanover ",
      "Northampton ",
      "Onslow ",
      "Orange ",
      "Pamlico ",
      "Pasquotank ",
      "Pender ",
      "Perquimans ",
      "Person ",
      "Pitt ",
      "Polk ",
      "Randolph ",
      "Richmond ",
      "Robeson ",
      "Rockingham ",
      "Rowan ",
      "Rutherford ",
      "Sampson ",
      "Scotland ",
      "Stanly ",
      "Stokes ",
      "Surry ",
      "Swain ",
      "Transylvania ",
      "Tyrrell ",
      "Union ",
      "Vance ",
      "Wake ",
      "Warren ",
      "Washington ",
      "Watauga ",
      "Wayne ",
      "Wilkes ",
      "Wilson ",
      "Yadkin ",
      "Yancey ",
    ],
    "North Dakota": [
      "Adams ",
      "Barnes ",
      "Benson ",
      "Billings ",
      "Bottineau ",
      "Bowman ",
      "Burke ",
      "Burleigh ",
      "Cass ",
      "Cavalier ",
      "Dickey ",
      "Divide ",
      "Dunn ",
      "Eddy ",
      "Emmons ",
      "Foster ",
      "Golden Valley ",
      "Grand Forks ",
      "Grant ",
      "Griggs ",
      "Hettinger ",
      "Kidder ",
      "La Moure ",
      "Logan ",
      "Mchenry ",
      "Mcintosh ",
      "Mckenzie ",
      "Mclean ",
      "Mercer ",
      "Morton ",
      "Mountrail ",
      "Nelson ",
      "Oliver ",
      "Pembina ",
      "Pierce ",
      "Ramsey ",
      "Ransom ",
      "Renville ",
      "Richland ",
      "Rolette ",
      "Sargent ",
      "Sheridan ",
      "Sioux ",
      "Slope ",
      "Stark ",
      "Steele ",
      "Stutsman ",
      "Towner ",
      "Traill ",
      "Walsh ",
      "Ward ",
      "Wells ",
      "Williams ",
    ],
    Ohio: [
      "Adams ",
      "Allen ",
      "Ashland ",
      "Ashtabula ",
      "Athens ",
      "Auglaize ",
      "Belmont ",
      "Brown ",
      "Butler ",
      "Carroll ",
      "Champaign ",
      "Clark ",
      "Clermont ",
      "Clinton ",
      "Columbiana ",
      "Coshocton ",
      "Crawford ",
      "Cuyahoga ",
      "Darke ",
      "Defiance ",
      "Delaware ",
      "Erie ",
      "Fairfield ",
      "Fayette ",
      "Franklin ",
      "Fulton ",
      "Gallia ",
      "Geauga ",
      "Greene ",
      "Guernsey ",
      "Hamilton ",
      "Hancock ",
      "Hardin ",
      "Harrison ",
      "Henry ",
      "Highland ",
      "Hocking ",
      "Holmes ",
      "Huron ",
      "Jackson ",
      "Jefferson ",
      "Knox ",
      "Lake ",
      "Lawrence ",
      "Licking ",
      "Logan ",
      "Lorain ",
      "Lucas ",
      "Madison ",
      "Mahoning ",
      "Marion ",
      "Medina ",
      "Meigs ",
      "Mercer ",
      "Miami ",
      "Monroe ",
      "Montgomery ",
      "Morgan ",
      "Morrow ",
      "Muskingum ",
      "Noble ",
      "Ottawa ",
      "Paulding ",
      "Perry ",
      "Pickaway ",
      "Pike ",
      "Portage ",
      "Preble ",
      "Putnam ",
      "Richland ",
      "Ross ",
      "Sandusky ",
      "Scioto ",
      "Seneca ",
      "Shelby ",
      "Stark ",
      "Summit ",
      "Trumbull ",
      "Tuscarawas ",
      "Union ",
      "Van Wert ",
      "Vinton ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Williams ",
      "Wood ",
      "Wyandot ",
    ],
    Oklahoma: [
      "Adair ",
      "Alfalfa ",
      "Atoka ",
      "Beaver ",
      "Beckham ",
      "Blaine ",
      "Bryan ",
      "Caddo ",
      "Canadian ",
      "Carter ",
      "Cherokee ",
      "Choctaw ",
      "Cimarron ",
      "Cleveland ",
      "Coal ",
      "Comanche ",
      "Cotton ",
      "Craig ",
      "Creek ",
      "Custer ",
      "Delaware ",
      "Dewey ",
      "Ellis ",
      "Garfield ",
      "Garvin ",
      "Grady ",
      "Grant ",
      "Greer ",
      "Harmon ",
      "Harper ",
      "Haskell ",
      "Hughes ",
      "Jackson ",
      "Jefferson ",
      "Johnston ",
      "Kay ",
      "Kingfisher ",
      "Kiowa ",
      "Latimer ",
      "Le Flore ",
      "Lincoln ",
      "Logan ",
      "Love ",
      "Major ",
      "Marshall ",
      "Mayes ",
      "Mcclain ",
      "Mccurtain ",
      "Mcintosh ",
      "Murray ",
      "Muskogee ",
      "Noble ",
      "Nowata ",
      "Okfuskee ",
      "Oklahoma ",
      "Okmulgee ",
      "Osage ",
      "Ottawa ",
      "Pawnee ",
      "Payne ",
      "Pittsburg ",
      "Pontotoc ",
      "Pottawatomie ",
      "Pushmataha ",
      "Roger Mills ",
      "Rogers ",
      "Seminole ",
      "Sequoyah ",
      "Stephens ",
      "Texas ",
      "Tillman ",
      "Tulsa ",
      "Wagoner ",
      "Washington ",
      "Washita ",
      "Woods ",
      "Woodward ",
    ],
    Oregon: [
      "Baker ",
      "Benton ",
      "Clackamas ",
      "Clatsop ",
      "Columbia ",
      "Coos ",
      "Crook ",
      "Curry ",
      "Deschutes ",
      "Douglas ",
      "Gilliam ",
      "Grant ",
      "Harney ",
      "Hood River ",
      "Jackson ",
      "Jefferson ",
      "Josephine ",
      "Klamath ",
      "Lake ",
      "Lane ",
      "Lincoln ",
      "Linn ",
      "Malheur ",
      "Marion ",
      "Morrow ",
      "Multnomah ",
      "Polk ",
      "Sherman ",
      "Tillamook ",
      "Umatilla ",
      "Union ",
      "Wallowa ",
      "Wasco ",
      "Washington ",
      "Wheeler ",
      "Yamhill ",
    ],
    "Rhode Island": [
      "Bristol ",
      "Kent ",
      "Newport ",
      "Providence ",
      "Washington ",
    ],
    "South Carolina": [
      "Abbeville ",
      "Aiken ",
      "Allendale ",
      "Anderson ",
      "Bamberg ",
      "Barnwell ",
      "Beaufort ",
      "Berkeley ",
      "Calhoun ",
      "Charleston ",
      "Cherokee ",
      "Chester ",
      "Chesterfield ",
      "Clarendon ",
      "Colleton ",
      "Darlington ",
      "Dillon ",
      "Dorchester ",
      "Edgefield ",
      "Fairfield ",
      "Florence ",
      "Georgetown ",
      "Greenville ",
      "Greenwood ",
      "Hampton ",
      "Horry ",
      "Jasper ",
      "Kershaw ",
      "Lancaster ",
      "Laurens ",
      "Lee ",
      "Lexington ",
      "Marion ",
      "Marlboro ",
      "Mccormick ",
      "Newberry ",
      "Oconee ",
      "Orangeburg ",
      "Pickens ",
      "Richland ",
      "Saluda ",
      "Spartanburg ",
      "Sumter ",
      "Union ",
      "Williamsburg ",
      "York ",
    ],
    "South Dakota": [
      "Aurora ",
      "Beadle ",
      "Bennett ",
      "Bon Homme ",
      "Brookings ",
      "Brown ",
      "Brule ",
      "Buffalo ",
      "Butte ",
      "Campbell ",
      "Charles Mix ",
      "Clark ",
      "Clay ",
      "Codington ",
      "Corson ",
      "Custer ",
      "Davison ",
      "Day ",
      "Deuel ",
      "Dewey ",
      "Douglas ",
      "Edmunds ",
      "Fall River ",
      "Faulk ",
      "Grant ",
      "Gregory ",
      "Haakon ",
      "Hamlin ",
      "Hand ",
      "Hanson ",
      "Harding ",
      "Hughes ",
      "Hutchinson ",
      "Hyde ",
      "Jackson ",
      "Jerauld ",
      "Jones ",
      "Kingsbury ",
      "Lake ",
      "Lawrence ",
      "Lincoln ",
      "Lyman ",
      "Mccook ",
      "Mcpherson ",
      "Marshall ",
      "Meade ",
      "Mellette ",
      "Miner ",
      "Minnehaha ",
      "Moody ",
      "Pennington ",
      "Perkins ",
      "Potter ",
      "Roberts ",
      "Sanborn ",
      "Shannon ",
      "Spink ",
      "Stanley ",
      "Sully ",
      "Todd ",
      "Tripp ",
      "Turner ",
      "Union ",
      "Walworth ",
      "Yankton ",
      "Ziebach ",
    ],
    Tennessee: [
      "Anderson ",
      "Bedford ",
      "Benton ",
      "Bledsoe ",
      "Blount ",
      "Bradley ",
      "Campbell ",
      "Cannon ",
      "Carroll ",
      "Carter ",
      "Cheatham ",
      "Chester ",
      "Claiborne ",
      "Clay ",
      "Cocke ",
      "Coffee ",
      "Crockett ",
      "Cumberland ",
      "Davidson ",
      "Decatur ",
      "Dekalb ",
      "Dickson ",
      "Dyer ",
      "Fayette ",
      "Fentress ",
      "Franklin ",
      "Gibson ",
      "Giles ",
      "Grainger ",
      "Greene ",
      "Grundy ",
      "Hamblen ",
      "Hamilton ",
      "Hancock ",
      "Hardeman ",
      "Hardin ",
      "Hawkins ",
      "Haywood ",
      "Henderson ",
      "Henry ",
      "Hickman ",
      "Houston ",
      "Humphreys ",
      "Jackson ",
      "Jefferson ",
      "Johnson ",
      "Knox ",
      "Lake ",
      "Lauderdale ",
      "Lawrence ",
      "Lewis ",
      "Lincoln ",
      "Loudon ",
      "Macon ",
      "Madison ",
      "Marion ",
      "Marshall ",
      "Maury ",
      "Mcminn ",
      "Mcnairy ",
      "Meigs ",
      "Monroe ",
      "Montgomery ",
      "Moore ",
      "Morgan ",
      "Obion ",
      "Overton ",
      "Perry ",
      "Pickett ",
      "Polk ",
      "Putnam ",
      "Rhea ",
      "Roane ",
      "Robertson ",
      "Rutherford ",
      "Scott ",
      "Sequatchie ",
      "Sevier ",
      "Shelby ",
      "Smith ",
      "Stewart ",
      "Sullivan ",
      "Sumner ",
      "Tipton ",
      "Trousdale ",
      "Unicoi ",
      "Union ",
      "Van Buren ",
      "Warren ",
      "Washington ",
      "Wayne ",
      "Weakley ",
      "White ",
      "Williamson ",
      "Wilson ",
    ],
    Texas: [
      "Anderson ",
      "Andrews ",
      "Angelina ",
      "Aransas ",
      "Archer ",
      "Armstrong ",
      "Atascosa ",
      "Austin ",
      "Bailey ",
      "Bandera ",
      "Bastrop ",
      "Baylor ",
      "Bee ",
      "Bell ",
      "Bexar ",
      "Blanco ",
      "Borden ",
      "Bosque ",
      "Bowie ",
      "Brazoria ",
      "Brazos ",
      "Brewster ",
      "Briscoe ",
      "Brooks ",
      "Brown ",
      "Burleson ",
      "Burnet ",
      "Caldwell ",
      "Calhoun ",
      "Callahan ",
      "Cameron ",
      "Camp ",
      "Carson ",
      "Cass ",
      "Castro ",
      "Chambers ",
      "Cherokee ",
      "Childress ",
      "Clay ",
      "Cochran ",
      "Coke ",
      "Coleman ",
      "Collin ",
      "Collingsworth ",
      "Colorado ",
      "Comal ",
      "Comanche ",
      "Concho ",
      "Cooke ",
      "Coryell ",
      "Cottle ",
      "Crane ",
      "Crockett ",
      "Crosby ",
      "Culberson ",
      "Dallam ",
      "Dallas ",
      "Dawson ",
      "Deaf Smith ",
      "Delta ",
      "Denton ",
      "Dewitt ",
      "Dickens ",
      "Dimmit ",
      "Donley ",
      "Duval ",
      "Eastland ",
      "Ector ",
      "Edwards ",
      "El Paso ",
      "Ellis ",
      "Erath ",
      "Falls ",
      "Fannin ",
      "Fayette ",
      "Fisher ",
      "Floyd ",
      "Foard ",
      "Fort Bend ",
      "Franklin ",
      "Freestone ",
      "Frio ",
      "Gaines ",
      "Galveston ",
      "Garza ",
      "Gillespie ",
      "Glasscock ",
      "Goliad ",
      "Gonzales ",
      "Gray ",
      "Grayson ",
      "Gregg ",
      "Grimes ",
      "Guadalupe ",
      "Hale ",
      "Hall ",
      "Hamilton ",
      "Hansford ",
      "Hardeman ",
      "Hardin ",
      "Harris ",
      "Harrison ",
      "Hartley ",
      "Haskell ",
      "Hays ",
      "Hemphill ",
      "Henderson ",
      "Hidalgo ",
      "Hill ",
      "Hockley ",
      "Hood ",
      "Hopkins ",
      "Houston ",
      "Howard ",
      "Hudspeth ",
      "Hunt ",
      "Hutchinson ",
      "Irion ",
      "Jack ",
      "Jackson ",
      "Jasper ",
      "Jeff Davis ",
      "Jefferson ",
      "Jim Hogg ",
      "Jim Wells ",
      "Johnson ",
      "Jones ",
      "Karnes ",
      "Kaufman ",
      "Kendall ",
      "Kenedy ",
      "Kent ",
      "Kerr ",
      "Kimble ",
      "King ",
      "Kinney ",
      "Kleberg ",
      "Knox ",
      "La Salle ",
      "Lamar ",
      "Lamb ",
      "Lampasas ",
      "Lavaca ",
      "Lee ",
      "Leon ",
      "Liberty ",
      "Limestone ",
      "Lipscomb ",
      "Live Oak ",
      "Llano ",
      "Loving ",
      "Lubbock ",
      "Lynn ",
      "Madison ",
      "Marion ",
      "Martin ",
      "Mason ",
      "Matagorda ",
      "Maverick ",
      "Mcculloch ",
      "Mclennan ",
      "Mcmullen ",
      "Medina ",
      "Menard ",
      "Midland ",
      "Milam ",
      "Mills ",
      "Mitchell ",
      "Montague ",
      "Montgomery ",
      "Moore ",
      "Morris ",
      "Motley ",
      "Nacogdoches ",
      "Navarro ",
      "Newton ",
      "Nolan ",
      "Nueces ",
      "Ochiltree ",
      "Oldham ",
      "Orange ",
      "Palo Pinto ",
      "Panola ",
      "Parker ",
      "Parmer ",
      "Pecos ",
      "Polk ",
      "Potter ",
      "Presidio ",
      "Rains ",
      "Randall ",
      "Reagan ",
      "Real ",
      "Red River ",
      "Reeves ",
      "Refugio ",
      "Roberts ",
      "Robertson ",
      "Rockwall ",
      "Runnels ",
      "Rusk ",
      "Sabine ",
      "San Augustine ",
      "San Jacinto ",
      "San Patricio ",
      "San Saba ",
      "Schleicher ",
      "Scurry ",
      "Shackelford ",
      "Shelby ",
      "Sherman ",
      "Smith ",
      "Somervell ",
      "Starr ",
      "Stephens ",
      "Sterling ",
      "Stonewall ",
      "Sutton ",
      "Swisher ",
      "Tarrant ",
      "Taylor ",
      "Terrell ",
      "Terry ",
      "Throckmorton ",
      "Titus ",
      "Tom Green ",
      "Travis ",
      "Trinity ",
      "Tyler ",
      "Upshur ",
      "Upton ",
      "Uvalde ",
      "Val Verde ",
      "Van Zandt ",
      "Victoria ",
      "Walker ",
      "Waller ",
      "Ward ",
      "Washington ",
      "Webb ",
      "Wharton ",
      "Wheeler ",
      "Wichita ",
      "Wilbarger ",
      "Willacy ",
      "Williamson ",
      "Wilson ",
      "Winkler ",
      "Wise ",
      "Wood ",
      "Yoakum ",
      "Young ",
      "Zapata ",
      "Zavala ",
    ],
    Utah: [
      "Beaver ",
      "Box Elder ",
      "Cache ",
      "Carbon ",
      "Daggett ",
      "Davis ",
      "Duchesne ",
      "Emery ",
      "Garfield ",
      "Grand ",
      "Iron ",
      "Juab ",
      "Kane ",
      "Millard ",
      "Morgan ",
      "Piute ",
      "Rich ",
      "Salt Lake ",
      "San Juan ",
      "Sanpete ",
      "Sevier ",
      "Summit ",
      "Tooele ",
      "Uintah ",
      "Utah ",
      "Wasatch ",
      "Washington ",
      "Wayne ",
      "Weber ",
    ],
    Vermont: [
      "Addison ",
      "Bennington ",
      "Caledonia ",
      "Chittenden ",
      "Essex ",
      "Franklin ",
      "Grand Isle ",
      "Lamoille ",
      "Orange ",
      "Orleans ",
      "Rutland ",
      "Washington ",
      "Windham ",
      "Windsor ",
    ],
    "Washington D. C.": [
      "Adams ",
      "Asotin ",
      "Benton ",
      "Chelan ",
      "Clallam ",
      "Clark ",
      "Columbia ",
      "Cowlitz ",
      "Douglas ",
      "Ferry ",
      "Franklin ",
      "Garfield ",
      "Grant ",
      "Grays Harbor ",
      "Island ",
      "Jefferson ",
      "King ",
      "Kitsap ",
      "Kittitas ",
      "Klickitat ",
      "Lewis ",
      "Lincoln ",
      "Mason ",
      "Okanogan ",
      "Pacific ",
      "Pend Oreille ",
      "Pierce ",
      "San Juan ",
      "Skagit ",
      "Skamania ",
      "Snohomish ",
      "Spokane ",
      "Stevens ",
      "Thurston ",
      "Wahkiakum ",
      "Walla Walla ",
      "Whatcom ",
      "Whitman ",
      "Yakima ",
    ],
    "West Virginia": [
      "Barbour ",
      "Berkeley ",
      "Boone ",
      "Braxton ",
      "Brooke ",
      "Cabell ",
      "Calhoun ",
      "Clay ",
      "Doddridge ",
      "Fayette ",
      "Gilmer ",
      "Grant ",
      "Greenbrier ",
      "Hampshire ",
      "Hancock ",
      "Hardy ",
      "Harrison ",
      "Jackson ",
      "Jefferson ",
      "Kanawha ",
      "Lewis ",
      "Lincoln ",
      "Logan ",
      "Mcdowell ",
      "Marion ",
      "Marshall ",
      "Mason ",
      "Mercer ",
      "Mineral ",
      "Mingo ",
      "Monongalia ",
      "Monroe ",
      "Morgan ",
      "Nicholas ",
      "Ohio ",
      "Pendleton ",
      "Pleasants ",
      "Pocahontas ",
      "Preston ",
      "Putnam ",
      "Raleigh ",
      "Randolph ",
      "Ritchie ",
      "Roane ",
      "Summers ",
      "Taylor ",
      "Tucker ",
      "Tyler ",
      "Upshur ",
      "Wayne ",
      "Webster ",
      "Wetzel ",
      "Wirt ",
      "Wood ",
      "Wyoming ",
    ],
    Wisconsin: [
      "Adams ",
      "Ashland ",
      "Barron ",
      "Bayfield ",
      "Brown ",
      "Buffalo ",
      "Burnett ",
      "Calumet ",
      "Chippewa ",
      "Clark ",
      "Columbia ",
      "Crawford ",
      "Dane ",
      "Dodge ",
      "Door ",
      "Douglas ",
      "Dunn ",
      "Eau Claire ",
      "Florence ",
      "Fond Du Lac ",
      "Forest ",
      "Grant ",
      "Green ",
      "Green Lake ",
      "Iowa ",
      "Iron ",
      "Jackson ",
      "Jefferson ",
      "Juneau ",
      "Kenosha ",
      "Kewaunee ",
      "La Crosse ",
      "Lafayette ",
      "Langlade ",
      "Lincoln ",
      "Manitowoc ",
      "Marathon ",
      "Marinette ",
      "Marquette ",
      "Menominee ",
      "Milwaukee ",
      "Monroe ",
      "Oconto ",
      "Oneida ",
      "Outagamie ",
      "Ozaukee ",
      "Pepin ",
      "Pierce ",
      "Polk ",
      "Portage ",
      "Price ",
      "Racine ",
      "Richland ",
      "Rock ",
      "Rusk ",
      "Sainte-croix ",
      "Sauk ",
      "Sawyer ",
      "Shawano ",
      "Sheboygan ",
      "Taylor ",
      "Trempealeau ",
      "Vernon ",
      "Vilas ",
      "Walworth ",
      "Washburn ",
      "Washington ",
      "Waukesha ",
      "Waupaca ",
      "Waushara ",
      "Winnebago ",
      "Wood ",
    ],
    Wyoming: [
      "Albany Condado",
      "Big Horn Condado",
      "Campbell Condado",
      "Carbon Condado",
      "Converse Condado",
      "Crook Condado",
      "Fremont Condado",
      "Goshen Condado",
      "Hot Springs Condado",
      "Johnson Condado",
      "Laramie Condado",
      "Lincoln Condado",
      "Natrona Condado",
      "Niobrara Condado",
      "Park Condado",
      "Platte Condado",
      "Sheridan Condado",
      "Sublette Condado",
      "Sweetwater Condado",
      "Teton Condado",
      "Uinta Condado",
      "Washakie Condado",
      "Weston Condado",
    ],
  },
  Estonia: {
    Harju: ["Tallin", "Keila", "Maardu", "Paldiski", "Saue", "Loksa", "Kehra"],
    Hiiu: ["Kärdla", "Kõrgessaare", "Käina", "Emmaste"],
    Ida_Viru: [
      "Narva",
      "Kohtla-Järve",
      "Sillamäe",
      "Jõhvi",
      "Ahtme",
      "Kiviõli",
      "Toila",
      "Narva-Jõesuu",
    ],
    Järva: ["Paide", "Türi", "Koeru", "Aravete"],
    Lääne: ["Haapsalu", "Lihula", "Kullamaa", "Nõva", "Noarootsi", "Risti"],
    Lääne_Viru: [
      "Rakvere",
      "Tapa",
      "Kunda",
      "Haljala",
      "Kadrina",
      "Vinni",
      "Tamsalu",
    ],
    Põlva: [
      "Põlva",
      "Võru",
      "Kanepi",
      "Räpina",
      "Mustvee",
      "Kallaste",
      "Orava",
    ],
    Pärnu: [
      "Pärnu",
      "Sindi",
      "Häädemeeste",
      "Põltsamaa",
      "Pärnu-Jaagupi",
      "Lavassaare",
    ],
    Rapla: ["Rapla", "Kehtna", "Märjamaa", "Kohila", "Kaiu"],
    Saare: ["Kuressaare", "Orissaare", "Muhu", "Salme"],
    Tartu: ["Tartu", "Elva", "Otepää", "Kallaste", "Puhja", "Konguta"],
    Valga: ["Valga", "Tõrva", "Otepää", "Valka", "Helme"],
    Võru: ["Võru", "Antsla", "Urvaste", "Rõuge", "Vastseliina"],
    Viljandi: ["Viljandi", "Mõisaküla", "Võhma", "Abja-Paluoja"],
    Jõgeva: ["Mustvee", "Põltsamaa", "Puurmani", "Tabivere", "Torma"],
  },
  Filipinas: {
    Ilocos: [
      "Ilocos Sur",
      "La Unión",
      "Alaminos",
      "Batac City",
      "Candón",
      "Dagupan",
      "Laoag",
      "Urdaneta de  ",
      "San Carlos",
      "San Fernando City",
      "Vigan",
    ],
    "La Cordillera": [
      "Cordillera Central",
      "Cordillera del Norte",
      "Cordillera del Sur",
      "Cordillera Oriental",
      "Cordillera de Zambales",
      "Cordillera de Caraballo",
      "Sierra Madre Oriental",
      "Sierra Madre Occidental",
      "Baguio",
      "Tabuk",
      "Bontoc",
      "Sagada",
      "Tuguegarao",
      "Kalinga",
      "Tabuk",
      "Banaue",
      "Hungduan",
      "Mayoyao",
      "Kiangan",
      "Tinoc",
      "Itogon",
      "La Trinidad",
      "Sablan",
      "Tublay",
      "Atok",
      "Buguias",
      "Mankayan",
      "Bauko",
      "Mountain Province",
      "Benguet",
      "Apayao",
      "Ifugao",
      "Abra",
    ],
    Cagayán: [
      "Basco",
      "Ihuvok II ",
      "Ihuvok I ",
      "San Antonio",
      "San Joaquín",
      "Chanarian",
      "Kayhuvokan",
      "Itbayat",
      "San José ",
      "San Rafael",
      "Santa Lucía",
      "Santa María",
      "Santa Rosa",
      "Mahatao",
      "Hanib",
      "Kaumbakan",
      "Panatayan",
      "Uvoy",
      "Ivaná",
      "Radiwan",
      "Salagao",
      "San Vicente ",
      "Tuhel ",
      "Sabtang",
      "Chavayan",
      "Malakdang ",
      "Nakanmuan",
      "Savidug",
      "Sinakan",
      "Sumnanga",
      "Uyugan",
      "Kayvaluganan ",
      "Imnajbu",
      "Itbud",
      "Kayuganan",
      "Abulug",
      "Alinunu",
      "Bagu",
      "Banguian",
      "Calog Norte",
      "Calog Sur",
      "Canayun",
      "Centro ",
      "Dana-Ili",
      "Guiddam",
      "Libertad",
      "Lucban",
      "Pinili",
      "Santa Filomena",
      "Santo Tomas",
      "Siguiran",
      "Simayung",
      "Sirit",
      "San Agustín",
      "San Julián",
      "Santa Rosa",
      "Sawang",
      "Murut",
      "Alcalá",
      "Abbeg",
      "Afusing Bato",
      "Afusing Daga",
      "Agani",
      "Baculod",
      "Baybayog",
      "Cabuluan",
      "Calantac",
      "Carallangan",
      "Centro Norte ",
      "Centro Sur ",
      "Dalaoig",
      "Damurog",
      "Jurisdiction",
      "Malalatan",
      "Maraburab",
      "Masin",
      "Pagbangkeruan",
      "Pared",
      "Piggatan",
      "Pinopoc",
      "Pussian",
      "San Esteban",
      "Tamban",
      "Tupang",
      "Allacapan",
      "Bessang",
      "Binobongan",
      "Bulo",
      "Burot",
      "Capagaran ",
      "Capalutan",
      "Capanickian Norte",
      "Capanickian Sur",
      "Cataratan",
      "Centro East ",
      "Centro West ",
      "Daan-Ili",
      "Dagupan",
      "Dalayap",
      "Gagaddangan",
      "Iringan",
      "Labben",
      "Maluyo",
      "Mapurao",
      "Matucay",
      "Nagattatan",
      "Pacac",
      "San Juan",
      "Silangan",
      "Tamboli",
      "Tubel",
      "Utan",
      "Amulung",
      "Abolo",
      "Agguirit",
      "Alitungtung",
      "Annabuculan",
      "Annafatan",
      "Anquiray",
      "Babayuan",
      "Baccuit",
      "Bacring",
      "Baculud",
      "Balauini",
      "Bauan",
      "Bayabat",
      "Calamagui",
      "Calintaan",
      "Caratacat",
      "Casingsingan Norte",
      "Casingsingan Sur",
      "Catarauan",
      "Centro",
      "Concepción",
      "Cordova",
      "Dadda",
      "Dafunganay",
      "Dugayung",
      "Estefania",
      "Gabut",
      "Gangauan",
      "Goran",
      "Jurisdicción",
      "La Suerte",
      "Logung",
      "Magogod",
      "Manalo",
      "Marobbob",
      "Masical",
      "Monte Alegre",
      "Nabbialan",
      "Nagsabaran",
      "Nangalasauan",
      "Nanuccauan",
      "Pacac-Grande",
      "Pacac-Pequeño",
      "Palacu",
      "Palayag",
      "San Juan",
      "Tana",
      "Unag",
      "Backiling",
      "Bangag",
      "Binalan",
      "Bisagu",
      "Bukig",
      "Bulala Norte",
      "Bulala Sur",
      "Caagaman",
      "Centro 1 ",
      "Centro 2 ",
      "Centro 3 ",
      "Centro 4 ",
      "Centro 5 ",
      "Centro 6 ",
      "Centro 7 ",
      "Centro 8 ",
      "Centro 9 ",
      "Centro 10 ",
      "Centro 11 ",
      "Centro 12 ",
      "Centro 13 ",
      "Centro 14 ",
      "Centro 15 ",
      "Dodan",
      "Isla Fuga",
      "Gaddang",
      "Linao",
      "Mabanguc",
      "Macanaya ",
      "Maura",
      "Minanga",
      "Navagan",
      "Paddaya",
      "Paruddun Norte",
      "Paruddun Sur",
      "Plaza",
      "Punta",
      "San Antonio",
      "Sanja",
      "Tallungan",
      "Toran",
      "Zinarag",
      "Adaoag",
      "Agaman",
      "Alba",
      "Annayatan",
      "Asassi",
      "Asinga-Via",
      "Awallan",
      "Bacagan",
      "Bagunot",
      "Barsat East",
      "Barsat West",
      "Bitag Grande",
      "Bitag Pequeño",
      "Bunugan",
      "Canagatan",
      "Carupian",
      "Catugay",
      "Población",
      "Dabbac Grande",
      "Dalin",
      "Dalla",
      "Hacienda Intal",
      "Ibulo",
      "Imurong",
      "J. Pallagao",
      "Lasilat",
      "Masical",
      "Mocag",
      "Nangalinan",
      "Remus",
      "San Antonio",
      "San Francisco",
      "San Isidro",
      "San José",
      "San Miguel",
      "San Vicente",
      "Santa Margarita",
      "Santor",
      "Taguing",
      "Taguntungan",
      "Tallang",
      "Temblique",
      "Taytay",
      "Tungel",
      "Mabini",
      "Agaman Norte",
      "Agaman Sur",
      "C. Verzosa",
      "Ammubuan",
      "Baran",
      "Cabaritan East",
      "Cabaritan West",
      "Cabayu",
      "Cabuluan East",
      "Cabuluan West",
      "Centro East ",
      "Centro West ",
      "Fugu",
      "Mabuttal East",
      "Mabuttal West",
      "Nararagan",
      "Palloc",
      "Payagan East",
      "Payagan West",
      "San Juan",
      "Santa Cruz",
      "Zitanga",
      "Ballang",
      "Balza",
      "Cabaritan",
      "Calamegatan",
      "Centro ",
      "Centro West",
      "Dalaya",
      "Fula",
      "Leron",
      "M.Antiporda",
      "Maddalero",
      "Mala Este",
      "Mala Weste",
      "Minanga Este",
      "Paddaya Este",
      "Pattao",
      "Quinawegan",
      "Remebella",
      "San Isidro",
      "Santa Isabel",
      "Santa María",
      "Tabbac",
      "Villa Cielo",
      "Alucao Weste ",
      "Minanga Weste",
      "Paddaya Weste",
      "San Juan",
      "San Vicente",
      "Villa Gracia",
      "Villa Leonora",
      "Isla de Calayán ",
      "Cabudadan ",
      "Dadao ",
      "Dibay ",
      "Dilam ",

      "Población/Centro I",
      "Magsidel ",
      "Isla de Camiguin ",
      "Balatubat",
      "Minabel ",
      "Naguilian ",
      "Isla de Babuyan Claro ",
      "Babuyan Claro",
      "Isla de Dalupiri ",
      "Dalupiri",
      "Abagao",
      "Afunan Cabayu",
      "Agusi",
      "Alilinu",
      "Baggao",
      "Bantay",
      "Bulala",
      "Casili Norte",
      "Catotoran Norte",
      "Centro Norte ",
      "Centro Sur ",
      "Cullit",
      "Dacal-Lafugu",
      "Dammang Norte",
      "Dugo",
      "Fusina",
      "Gang-ngo",
      "Jurisdiction",
      "Luec",
      "Minanga",
      "Paragat",
      "Tagum",
      "Tuluttuging",
      "Ziminila",
      "Casili Sur",
      "Catotoran Sur",
      "Dammang Sur ",
      "Sapping",
      "Alimoan",
      "Bacsay Cataraoan Norte",
      "Bacsay Cataraoan Sur",
      "Bacsay Mapulapula",
      "Bilibigao",
      "Buenavista",
      "Cadcadir East ",
      "Cadcadir West ",
      "Camalaggoan/D. Leaño",
      "Capannikian",
      "Centro I ",

      "Centro III",
      "Centro IV",
      "Centro V ",
      "Centro VI ",
      "Centro VII",
      "Centro VIII ",
      "Culao",
      "Dibalio",
      "Kilkiling ",
      "Lablabig",
      "Luzon",
      "Mabnang ",
      "Magdalena",
      "Malilitao",
      "Nagsabaran",
      "Pata East",
      "Pata West",
      "Pinas",
      "Santiago",
      "San Antonio",
      "San Isidro",
      "San Vicente",
      "Santa Maria",
      "Santo Niño ",
      "Santo Tomas",
      "Tabbugan",
      "Taggat Norte",
      "Taggat Sur",
      "Union ",
    ],
    Enrile: [
      "Alibago",
      "Barangay I",
      "Barangay II",
      "Barangay III ",
      "Divisoria",
      "Inga",
      "Lanna",
      "Lemu Norte",
      "Liwan Norte",
      "Liwan Sur",
      "Maddarulug Norte",
      "Magalalag East",
      "Maracuru",
      "Barangay IV ",
      "Roma Norte",
      "Barangay III-A",
      "Batu",
      "Lemu Sur",
      "Maddarulug Sur",
      "Magalalag West ",
      "Roma Sur",
      "San Antonio",
    ],
    Gattaran: [
      "Abra",
      "Aguiguican",
      "Bangatan Ngagan",
      "Baracaoit",
      "Baraoidan",
      "Barbarit",
      "Basao",
      "Bolos Point",
      "Cabayu",
      "Calaoagan Bassit",
      "Calaoagan Dackel",
      "Capiddigan",
      "Capissayan Norte",
      "Capissayan Sur",
      "Casicallan Sur",
      "Casicallan Norte",
      "Centro Norte ",
      "Centro Sur ",
      "Cullit",
      "Cumao",
      "Cunig",
      "Dummun",
      "Fugu",
      "Ganzano",
      "Guising",
      "Langgan",
      "Lapogan",
      "L. Adviento",
      "Mabuno",
      "Nabaccayan",
      "Naddungan",
      "Nagatutuan",
      "Nassiping",
      "Newagac",
      "Palagao Norte",
      "Palagao Sur",
      "Piña Este",
      "Piña Weste",
      "San Vicente",
      "Santa Maria",
      "Sidem",
      "Santa Ana",
      "Tagumay",
      "Takiki",
      "Taligan",
      "Tanglagan",
      "T. Elizaga ",
      "Tubungan Este",
      "Tubungan Weste",
      "San Carlos",
    ],
    Gonzaga: [
      "Amunitan",
      "Batangan",
      "Baua",
      "Cabanbanan Norte",
      "Cabanbanan Sur",
      "Cabiraoan",
      "Callao",
      "Calayan",
      "Caroan",
      "Casitan",
      "Flourishing ",
      "Ipil",
      "Isca",
      "Magrafil",
      "Minanga",
      "Rebecca ",
      "Paradise ",
      "Pateng",
      "Progressive ",
      "San Jose",
      "Santa Clara",
      "Santa Cruz",
      "Santa Maria",
      "Smart ",
      "Tapel",
    ],
    Iguig: [
      "Ajat ",
      "Atulu",
      "Baculud",
      "Bayo",
      "Campo",
      "San Esteban",
      "Dumpao",
      "Gammad",
      "Santa Teresa",
      "Garab",
      "Malabbac",
      "Manaoag ",
      "Minanga Norte",
      "Minanga Sur",
      "Nattanzan ",
      "Redondo",
      "Salamague",
      "San Isidro ",
      "San Lorenzo",
      "Santa Barbara",
      "Santa Rosa",
      "Santiago",
      "San Vicente ",
    ],
    "Lal-lo": [
      "Abagao",
      "Alaguia",
      "Bagumbayan",
      "Bangag",
      "Bical",
      "Bicud",
      "Binag",
      "Cabayabasan",
      "Cagoran",
      "Cambong",
      "Catayauan",
      "Catugan",
      "Centro ",
      "Cullit",
      "Dagupan",
      "Dalaya",
      "Fabrica",
      "Fusina",
      "Jurisdiction",
      "Lalafugan",
      "Logac",
      "Magallungon",
      "Magapit",
      "Malanao",
      "Maxingal",
      "Naguilian",
      "Paranum",
      "Rosario",
      "San Antonio",
      "San Jose",
      "San Juan",
      "San Lorenzo",
      "San Mariano",
      "Santa Maria",
      "Tucalana",
    ],
    Lasam: [
      "Aggunetan",
      "Alannay",
      "Battalan",
      "Calapangan Norte",
      "Calapangan Sur",
      "Callao Norte",
      "Callao Sur",
      "Cataliganan",
      "Finugo Norte",
      "Gabun",
      "Ignacio B. Jurado ",
      "Magsaysay",
      "Malinta",
      "Minanga Sur",
      "Minanga Norte",
      "Nicolas Agatep",
      "Peru",
      "Centro I ",
      "San Pedro",
      "Sicalao",
      "Tagao",
      "Tucalan Passing",
      "Viga",
      "Cabatacan East ",
      "Cabatacan West",
      "Nabannagan East",
      "Nabannagan West",

      "Centro III ",
      "New Orlins",
    ],
    Pamplona: [
      "Abbangkeruan",
      "Allasitan",
      "Bagu",
      "Balingit",
      "Bidduang",
      "Cabaggan",
      "Capalalian",
      "Casitan",
      "Centro",
      "Curva",
      "Gattu",
      "Masi",
      "Nagattatan",
      "Nagtupacan",
      "San Juan",
      "Santa Cruz (Pimpila)",
      "Tabba",
      "Tupanna",
    ],
    Piat: [
      "Apayao",
      "Aquib",
      "Dugayung",
      "Gumarueng",
      "Macapil",
      "Maguilling",
      "Minanga",
      "Población I",
      "Santa Bárbara",
      "Santo Domingo",
      "Sicatna",
      "Villa Rey (San Gaspar)",
      "Warat",
      "Baung",
      "Calaoagan",
      "Catarauan",
      "Población II",
      "Villa Reyno",
    ],
    Rizal: [
      "Anagguan",
      "Anurturu",
      "Anungu",
      "Baluncanag",
      "Batu",
      "Cambabangan",
      "Capacuan",
      "Dunggan",
      "Duyun",
      "Gaddangao",
      "Gaggabutan East",
      "Illuru Norte",
      "Lattut",
      "Linno (Villa Cruz)",
      "Liuan",
      "Mabbang",
      "Mauanan",
      "Masi (Zinundungan)",
      "Minanga",
      "Nanauatan",
      "Nanungaran",
      "Pasingan",
      "Población",
      "San Juan (Zinundungan)",
      "Sinicking",
      "Battut",
      "Bural (Zinundungan)",
      "Gaggabutan West",
      "Illuru Sur",
    ],
    "Sánchez Mira": [
      "Bangan",
      "Callungan",
      "Centro I (Pob.)",
      "Centro II (Pob.)",
      "Dacal",
      "Dagueray",
      "Dammang",
      "Kittag",
      "Langagan",
      "Magacan",
      "Marzan",
      "Masisit",
      "Nagrangtayan",
      "Namuac",
      "San Andrés",
      "Santiago",
      "Santor",
      "Tokitok",
    ],
    "Santa Práxedes": [
      "Cadongdongan",
      "Capacuan",
      "Centro I (Pob.)",
      "Centro II (Pob.)",
      "Macatel",
      "Portabaga",
      "San Juan",
      "San Miguel",
      "Salungsong",
      "Sicul",
    ],
    "Santa Teresita": [
      "Alucao",
      "Buyun",
      "Centro East (Pob.)",
      "Centro West",
      "Dungeg",
      "Luga",
      "Masi",
      "Mision",
      "Simpatuyo",
      "Villa",
      "Aridawen",
      "Caniogan",
      "Simbaluca",
    ],
    "Santo Niño": [
      "Abariongan Ruar",
      "Abariongan Uneg",
      "Balagan",
      "Balanni",
      "Cabayo",
      "Calapangan",
      "Calassitan",
      "Campo",
      "Centro Norte (Pob.)",
      "Centro Sur (Pob.)",
      "Dungao",
      "Lattac",
      "Lipatan",
      "Lubo",
      "Mabitbitnong",
      "Mapitac",
      "Masical",
      "Matalao",
      "Nag-uma (Nagbayugan)",
      "Namuccayan",
      "Niug Norte",
      "Niug Sur",
      "Palusao",
      "San Manuel",
      "San Roque",
      "Santa Felicitas",
      "Santa Maria",
      "Sidiran",
      "Tabang",
      "Tamucco",
      "Virginia",
    ],
    Solana: [
      "Andarayan North",
      "Lannig",
      "Bangag",
      "Bantay",
      "Basi East",
      "Bauan East",
      "Cadaanan",
      "Calamagui",
      "Carilucud",
      "Cattaran",
      "Centro Northeast (Pob.)",
      "Centro Northwest (Pob.)",
      "Centro Southeast (Pob.)",
      "Centro Southwest (Pob.)",
      "Lanna",
      "Lingu",
      "Maguirig",
      "Nabbotuan",
      "Nangalisan",
      "Natappian West",
      "Natappian East",
      "Padul",
      "Palao",
      "Parug-parug",
      "Pataya",
      "Sampaguita",
      "Maddarulug (Santo Domingo)",
      "Ubong",
      "Dassun",
      "Furagui",
      "Gadu",
      "Iraga",
      "Andarayan South",
      "Basi West",
      "Bauan West",
      "Calillauan",
      "Gen. Eulogio Balao",
      "Malalam-Malacabibi",
    ],
    Tuao: [
      "Accusilian",
      "Alabiao",
      "Alabug (Food Basket of Tuao)",
      "Angang",
      "Bagumbayan",
      "Barancuag",
      "Battung",
      "Bicok",
      "Bugnay",
      "Bulagao",
      "Cagumitan",
      "Cato *Centro 1 *Centro 2",
      "Culong",
      "Dagupan",
      "Fugu",
      "Lakambini",
      "Lallayug",
      "Malummin",
      "Mambacag",
      "San Vicente",
      "Mungo",
      "Naruangan",
      "Palca",
      "Pata",
      "San Juan",
      "San Luis (Gurengad)",
      "Santo Tomas",
      "Taribubu",
      "Villa Laida",
      "Malalinta",
    ],
    Tuguegarao: [
      "Annafunan East",
      "Annafunan West",
      "Atulayan Norte",
      "Atulayan Sur",
      "Bagay",
      "Buntun",
      "Caggay",
      "Capatan",
      "Carig",
      "Caritan Centro",
      "Caritan Norte",
      "Caritan Sur",
      "Cataggaman Nuevo",
      "Cataggaman Pardo",
      "Cataggaman Viejo",
      "Centro 1 (Pob.)",
      "Centro 2 (Pob.)",
      "Centro 3 (Pob.)",
      "Centro 4 (Pob.)",
      "Centro 5 (Pob.)",
      "Centro 6 (Pob.)",
      "Centro 7 (Pob.)",
      "Centro 8 (Pob.)",
      "Centro 9 (Pob.)",
      "Centro 10 (Pob.)",
      "Centro 11 (Pob.)",
      "Centro 12 (Pob.)",
      "Dadda",
      "Gosi Norte",
      "Gosi Sur",
      "Larion Alto",
      "Larion Bajo",
      "Leonarda",
      "Libag Norte",
      "Libag Sur",
      "Linao East",
      "Linao Norte",
      "Linao West",
      "Nambbalan Norte",
      "Nambbalan Sur",
      "Pallua Norte",
      "Pallua Sur",
      "Pengue (Pengue-Ruyu)",
      "Reyes",
      "San Gabriel",
      "Tagga",
      "Tanza",
      "Ugac Norte",
      "Ugac Sur",
    ],
    "Luzón Central": [
      "Aurora",
      "Bataan.",
      "Bulacán.",
      "Nueva Écija.",
      "Pampanga.",
      "Tarlac.",
      "Zambales",
    ],
    "Gran Manila": [
      "Caloocan",
      "Las Piñas",
      "Makáti",
      "Malabón",
      "Mandaluyong",
      "manil",
      "Marikina",
      "Muntinlupa",
      "Navotas",
      "Parañaque",
      "Pásay",
      "Pásig",
      "Pateros",
      "Quezon",
      "San Juan",
      "Taguig",
      "Valenzuela",
    ],
    Calabarzon: [
      "Batangas City",
      "Lipa City",
      "Tanauan City",
      "Santa Rosa City",
      "Cabuyao City",
      "Calamba City",
      "San Pablo City",
      "Biñan City",
      "Santa Cruz",
      "Alaminos",
      "Bay",
      "Calauan",
      "Cavinti",
      "Famy",
      "Kalayaan",
      "Liliw",
      "Los Baños",
      "Luisiana",
      "Lumban",
      "Mabitac",
      "Magdalena",
      "Majayjay",
      "Nagcarlan",
      "Paete",
      "Pagsanjan",
      "Pakil",
      "Pangil",
      "Pila",
      "Rizal",
      "San Pedro",
      "Santa Maria",
      "Siniloan",
      "Victoria",
      "Agoncillo",
      "Alitagtag",
      "Balayan",
      "Balete",
      "Cuenca",
      "Ibaan",
      "Laurel",
      "Lemery",
      "Lian",
      "Mabini",
      "Malvar",
      "Mataas na Kahoy",
      "Nasugbu",
      "Padre Garcia",
      "Rosario",
      "San Jose",
      "San Juan",
      "San Luis",
      "San Nicolas",
      "San Pascual",
      "Santa Teresita",
      "Santo Tomas",
      "Taal",
      "Talisay",
      "Taysan",
      "Tingloy",
      "Tuy",
    ],
    MIMAROPA: [
      "Occidental Mindoro",
      "Abra de Ilog",
      "Calintaan",
      "Looc",
      "Lubang",
      "Magsaysay",
      "Mamburao",
      "Paluan",
      "Rizal",
      "Sablayan",
      "San Jose",
      "Santa Cruz",
      "Oriental Mindoro",
      "Bansud",
      "Bongabong",
      "Bulalacao",
      "Calapan",
      "Gloria",
      "Mansalay",
      "Naujan",
      "Pinamalayan",
      "Pola",
      "Puerto Galera",
      "Roxas",
      "San Teodoro",
      "Socorro",
      "Victoria",
      "Marinduque",
      "Boac",
      "Buenavista",
      "Gasan",
      "Mogpog",
      "Santa Cruz",
      "Torrijos",
      "Romblon",
      "Alcantara",
      "Banton",
      "Cajidiocan",
      "Calatrava",
      "Concepcion",
      "Corcuera",
      "Ferrol",
      "Looc",
      "Magdiwang",
      "Odiongan",
      "Romblon",
      "San Agustin",
      "San Andres",
      "San Fernando",
      "San Jose",
      "Palawan",
      "Aborlan",
      "Agutaya",
      "Araceli",
      "Balabac",
      "Bataraza",
      "Brooke's Point",
      "Busuanga",
      "Cagayancillo",
      "Coron",
      "Culion",
      "Cuyo",
      "Dumaran",
      "El Nido",
      "Kalayaan",
      "Linapacan",
      "Magsaysay",
      "Narra",
      "Quezon",
      "Rizal",
      "Roxas",
      "San Vicente",
      "Sofronio Española",
      "Taytay",
    ],
    Bicolandia: [
      "Legazpi City",
      "Ligao City",
      "Tabaco City",
      "Iriga City",
      "Naga City",
      "Sorsogon City",
      "Masbate City",
      "Bacoor",
      "Bulan",
      "Daraga",
      "Guinobatan",
      "Malilipot",
      "Manito",
      "Oas",
      "Pilar",
      "Polangui",
      "Rapu-Rapu",
      "Santo Domingo",
      "Bacacay",
      "Camalig",
      "Jovellar",
      "Libon",
      "Malinao",
      "Sto. Tomas",
      "Tiwi",
      "Aroroy",
      "Baleno",
      "Balud",
      "Batuan",
      "Cawayan",
      "Claveria",
      "Dimasalang",
      "Esperanza",
      "Masbate",
      "Milagros",
      "Mobo",
      "Monreal",
      "Palanas",
      "Pio V. Corpuz",
      "Placer",
      "San Fernando",
      "San Jacinto",
      "San Pascual",
      "Uson",
    ],
    "Bisayas Orientales": [
      "Bilirán",
      "Sámar Oriental",
      "Leyte",
      "Sámar del Norte",
      "Sámar",
      "Leyte del Sur",
    ],
    "Bisayas Occidentales": [
      "Aclan",
      "Antique",
      "Cápiz",
      "Guimarás",
      "Iloílo",
      "Negros Occidental",
    ],
    "Bisayas Centrales": ["Bohol.", "Cebú.", "Negros Oriental.", "Siquijor."],
    Zamboanga: [
      "Zamboanga City",
      "Alicia",
      "Buug",
      "Diplahan",
      "Imelda",
      "Ipil",
      "Kabasalan",
      "Mabuhay",
      "Malangas",
      "Naga",
      "Olutanga",
      "Payao",
      "Roseller T. Lim",
      "Siay",
      "Talusan",
      "Titay",
      "Tungawan",
    ],
    "Mindanao del Norte": [
      "Cagayan de Oro City",
      "El Salvador City",
      "Gingoog City",
      "Alubijid",
      "Balingasag",
      "Balingoan",
      "Binuangan",
      "Claveria",
      "Gitagum",
      "Initao",
      "Jasaan",
      "Kinoguitan",
      "Lagonglong",
      "Laguindingan",
      "Libertad",
      "Lugait",
      "Magsaysay",
      "Manticao",
      "Medina",
      "Naawan",
      "Opol",
      "Salay",
      "Sugbongcogon",
      "Tagoloan",
      "Talisayan",
      "Villanueva",
    ],
    Caraga: [
      "Bislig City",
      "Tandag City",
      "Bayugan City",
      "Cabadbaran City",
      "Surigao City",
      "Butuan City",
      "Buenavista",
      "Carmen",
      "Jabonga",
      "Kitcharao",
      "Las Nieves",
      "Magallanes",
      "Nasipit",
      "Remedios T. Romualdez",
      "Santiago",
      "Tubay",
      "Loreto",
      "Rosario",
      "San Francisco",
      "San Luis",
      "Sison",
      "Tagana-an",
      "Alegria",
      "Bacuag",
      "Burgos",
      "Claver",
      "Dapa",
      "Del Carmen",
      "General Luna",
      "Gigaquit",
      "Mainit",
      "Malimono",
      "Pilar",
      "Placer",
      "San Benito",
      "San Isidro",
      "Santa Monica",
      "Socorro",
      "Tubod",
    ],
    Bangsamoro: [
      "Basilan",
      "Lanao del Sur",
      "Maguindanao",
      "Sulu",
      "Tawi-Tawi",
      "Cotabato City",
      "Marawi City",
    ],
    Dávao: [
      "Davao City",
      "Digos City",
      "Bansalan",
      "Don Marcelino",
      "Hagonoy",
      "Jose Abad Santos",
      "Kiblawan",
      "Magsaysay",
      "Malalag",
      "Malita",
      "Matanao",
      "Padada",
      "Santa Cruz",
      "Sulop",
      "Baguio",
      "Carmen",
      "Kabacan",
      "M'lang",
      "Makilala",
      "Magpet",
      "Kidapawan City",
      "Montevista",
      "Nabunturan",
      "New Bataan",
      "Pantukan",
    ],
    Mindanao: [
      "Agusan del Norte",
      "Agusan del Sur",
      "Basilan",
      "Bukidnon",
      "Camiguin",
      "Compostela Valley",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Occidental",
      "Davao Oriental",
      "Dinagat Islands",
      "Lanao del Norte",
      "Lanao del Sur",
      "Maguindanao",
      "Misamis Occidental",
      "Misamis Oriental",
      "Sarangani",
      "South Cotabato",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tawi-Tawi",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Sibugay",
    ],
  },
  "Finlandia / Finland": {
    Åland: [
      "Brändö",
      "Eckerö",
      "Finström",
      "Föglö",
      "Geta",
      "Hammarland",
      "Jomala",
      "Kumlinge",
      "Kökar",
      "Lemland",
      "Lumparland",
      "Mariehamn",
      "Saltvik",
      "Sottunga",
      "Sund",
      "Vardö",
    ],
    "Finlandia Meridional": [
      "Etelä-Karjala / Södra Karelen",
      "Päijät-Häme / Päijänne Tavastland",
      "Kanta-Häme / Egentliga Tavastland",
      "Uusimaa / Nyland",
      "Itä-Uusimaa / Östra Nyland",
      "Kymenlaakso / Kymmenedalen",
      "Tavastia",
      "Carelia",
      "Uusimaa",
    ],
    "Finlandia Occidental": [
      "Keski-Suomi / Mellersta Finland",
      "Varsinais-Suomi / Egentliga Finland",
      "Pohjanmaa / Österbotten",
      "Keski-Pohjanmaa / Mellersta Österbotten",
      "Etelä-Pohjanmaa / Södra Österbotten",
      "Pirkanmaa / Birkaland",
      "Satakunta / Satakunda",
      "Tavastia",
      "Satakunta",
      "Ostrobotnia",
      "Varsinais-Suomi",
    ],
    "Finlandia Oriental": [
      "Carelia del Norte (Pohjois-Karjala)",
      "Savonia del Norte (Pohjois-Savo)",
      "Savonia del Sur (Etelä-Savo)",
      "Carelia",
      "Savonia",
    ],
    Laponia: [
      "Enontekiö",
      "Inari",
      "Kemi",
      "Kemijärvi",
      "Keminmaa",
      "Kittilä",
      "Kolari",
      "Muonio",
      "Pelkosenniemi",
      "Pello",
      "Posio",
      "Ranua",
      "Rovaniemi",
      "Salla",
      "Savukoski",
      "Simo",
      "Sodankylä",
      "Tervola",
      "Tornio",
      "Utsjoki",
      "Ylitornio",
    ],
    Oulu: ["Ostrobotnia del Norte (Pohjois-Pohjanmaa)", "Kainuu"],
  },
  "Francia / France": {
    Alsacia: ["Alto Rin", "Bajo Rin"],
    "Alta-Normandía": ["Eure", "Sena Marítimo"],
    Aquitania: [
      "Dordoña",
      "Gironda",
      "Landas",
      "Lot y Garona",
      "Pirineos Atlánticos",
    ],
    Auvernia: ["Allier", "Alto Loira", "Cantal", "Puy-de-Dôme"],
    "Baja-Normandía": ["Calvados", "La Mancha", "Orne"],
    Borgoña: ["Côte-d'Or", "Nièvre", "Saona y Loira", "Yonne"],
    Bretaña: ["Côtes-d'Armor", "Finisterre", "Ille y Vilaine", "Morbihan"],
    Centro: [
      "Cher",
      "Eure y Loir",
      "Indre",
      "Indre y Loira",
      "Loir y Cher",
      "Loiret",
    ],
    "Champaña-Árdenas": ["Alto Marne", "Aube", "Las Árdenas", "Marne"],
    Córcega: ["Alta Córcega", "Córcega Meridional"],
    "Franco Condado": ["Alto Saona", "Doubs", "Jura", "Territorio de Belfort"],
    "Isla de Francia": [
      "Altos del Sena",
      "Essonne",
      "París",
      "Sena y Marne",
      "Sena-Saint Denis",
      "Valle del Marne",
      "Valle del Oise",
      "Yvelines",
    ],
    "Languedoc-Rosellón": [
      "Aude",
      "Gard",
      "Hérault",
      "Lozère",
      "Pirineos Orientales",
    ],
    Lemosín: ["Alto Vienne", "Corrèze", "Creuse"],
    Lorena: ["Meurthe y Mosela", "Mosa", "Mosela", "Vosges"],
    "Mediodía-Pirineos": [
      "Alto Garona",
      "Altos Pirineos",
      "Ariège",
      "Aveyron",
      "Gers",
      "Lot",
      "Tarn",
      "Tarn y Garona",
    ],
    "Norte-Paso de Calais": ["Norte", "Paso de Calais"],
    "Países del Loira": [
      "Loira Atlántico",
      "Maine y Loira",
      "Mayenne",
      "Sarthe",
      "Vendée",
    ],
    Picardía: ["Aisne", "Oise", "Somme"],
    "Poitou-Charentes": [
      "Charente",
      "Charente Marítimo",
      "Deux-Sèvres",
      "Vienne",
    ],
    "Provenza-Alpes-Costa Azul": [
      "Alpes de Alta Provenza",
      "Alpes Marítimos",
      "Altos Alpes",
      "Bocas del Ródano",
      "Var",
      "Vaucluse",
    ],
    "Ródano-Alpes": [
      "Ain",
      "Alta Saboya",
      "Ardèche",
      "Drôme",
      "Isère",
      "Loira",
      "Ródano",
      "Saboya",
    ],
  },
  Gabón: {
    Estuaire: [
      "Komo-Océan",
      "Noya",
      "Komo",
      "Komo /Mondah",
      "Owendo",
      "Libreville",
    ],
    "Haut/Ogooué": [
      "Mpassa",
      "Lébombi-Léyou",
      "Sébé-Brikolo",
      "Lékoko",
      "Ogooué-Létili",
      "Djouori-Agnili",
      "Lékoni-Lékori",
      "Plateaux",
      "Djoué",
      "Lékabi-Léwolo",
      "Bayi-Brikolo",
    ],
    "Moyen/Ogooué": ["Ogooué et des Lacs", "Abanga-Bigné"],
    Ngounié: [
      "Douya-Onoye",
      "Tsamba-Magotsi",
      "Boumi-Louétsi",
      "Ogoulou",
      "Dola",
      "Ndolou",
      "Louétsi-Wano",
      "Louétsi-Bibaka",
      "Mougalaba",
    ],
    Nyanga: [
      "Mougoutsi",
      "Basse-Banio",
      "Douigny",
      "Doutsila",
      "Haute-Banio",
      "Mongo",
    ],
    "Ogooué/Ivindo": ["Ivindo", "Lopé", "Zadié", "Mvoung"],
    "Ogooué/Lolo": [
      "Lolo-Bouénguidi",
      "Mulundu",
      "Lombo-Bouénguidi	",
      "Offoué-Onoye",
    ],
    "Ogooué/Maritime": ["Bendjé", "Ndougou", "Etimboué"],
    "Woleu/Ntem": ["Woleu", "Ntem", "Haut-Ntem", "Okano", "Haut-Komo"],
  },
  Gambia: {
    "Lower River": [
      "Jarra Central",
      "Jarra East",
      "Jarra West",
      "Kiang Central",
      "Kiang East",
      "Kiang West",
      "Janjanbureh",
      "Mansa Konko",
    ],
    "Central River": [
      "Fulladu West",
      "Janjanbureh",
      "Lower Saloum",
      "Niamina Dankunku",
      "Niamina East",
      "Niamina West",
      "Niani",
      "Nianija",
      "Sami",
      "Upper Saloum",
      "Kerewan",
    ],

    "North Bank": [
      "Central Baddibu",
      "Jokadu",
      "Lower Baddibu",
      "Lower Niumi",
      "Upper Baddibu",
      "Upper Niumi",
      "Basse",
    ],
    "Upper River": ["Fulladu East", "Kantora", "Sandu", "Wuli", "Brikama"],
    Western: [
      "Foni Bintang-Karenai",
      "Foni Bondali",
      "Foni Brefet",
      "Foni Jarrol",
      "Foni Kansala",
      "Kombo Central",
      "Kombo Este",
      "Kombo Norte/Saint Mary",
      "Kombo Sur",
      "Banjul",
      "Basse",
      "Brikama",
      "Janjanbureh",
      "Kanifing",
      "Kerewan",
      "Kuntaur",
      "Mansa Konko",
    ],
  },
  Guatemala: {
    "Alta Verapaz": [
      "Cahabón",
      "Chahal",
      "Chisec",
      "Cobán",
      "Fray Bartolomé de las Casas",
      "Lanquín",
      "Panzós",
      "Raxruhá",
      "San Cristóbal Verapaz",
      "San Juan Chamelco",
      "San Pedro Carchá",
      "Santa Catalina La Tinta",
      "Santa Cruz Verapaz",
      "Senahú",
      "Tactic",
      "Tamahú",
      "Tucuru",
    ],
    "Baja Verapaz": [
      "Cubulco",
      "Granados",
      "Purulhá",
      "Rabinal",
      "Salamá",
      "San Jerónimo",
      "San Miguel Chicaj",
      "Santa Cruz el Chol",
    ],
    Chimaltenango: [
      " Acatenango",
      " Chimaltenango",
      " El Tejar",
      " Parramos",
      " Patzicía",
      " Patzún",
      " Pochuta",
      " San Andrés Itzapa",
      " San José Poaquíl",
      " San Juan Comalapa",
      " San Martín Jilotepeque",
      " San Pedro Yepocapa",
      " Santa Apolonia",
      " Santa Cruz Balanyá",
      " Tecpán Guatemala",
      " Zaragoza",
    ],
    Chiquimula: [
      "Camotán",
      "Chiquimula",
      "Concepción Las Minas",
      "Esquipulas",
      "Ipala",
      "Jocotán",
      "Olopa",
      "Quezaltepeque",
      "San Jacinto",
      "San José la Arada",
      "San Juan Ermita",
    ],
    "El Progreso": [
      " El Jícaro",
      " Guastatoya",
      " Morazán",
      " San Agustín Acasaguastlán",
      " San Antonio La Paz",
      " San Cristóbal Acasaguastlán",
      " Sanarate",
      " Sansare",
    ],
    Escuintla: [
      "Escuintla Cabecera",
      "Guanagazapa",
      "La Democracia",
      "La Gomera",
      "Masagua",
      "Nueva Concepción",
      "Palín",
      "Pueblo Nuevo Tiquisate",
      "Puerto de Iztapa",
      "Puerto de San José",
      "San Vicente Pacaya",
      "Santa Lucía Cotzumalguapa",
      "Sipacate",
      "Siquinalá",
    ],
    Guatemala: [
      "Amatitlán",
      "Chinautla",
      "Chuarrancho",
      "Fraijanes",
      "Guatemala Cabecera y Capital",
      "Mixco",
      "Palencia",
      "San José del Golfo",
      "San José Pinula",
      "San Juan Sacatepequéz",
      "San Miguel Petapa",
      "San Pedro Ayampuc",
      "San Pedro Sacatepequéz",
      "San Raymundo",
      "Santa Catarina Pinula",
      "Villa Canales",
      "Villa Nueva",
    ],
    Huehuetenango: [
      " Aguacatánmunicipio",
      " Barillasmunicipio",
      " Chiantlamunicipio",
      " Colotenangomunicipio",
      " Concepción Huista",
      " Cuilco",
      " Huehuetenango",
      " Ixtahuacánmunicipio​",
      " Jacaltenangomunicipio",
      " La Democraciamunicipio",
      " La Libertadmunicipio",
      " Malacatancito",
      " Nentón",
      " Petatán",
      " San Antonio Huista",
      " San Gaspar Ixchilmunicipio",
      " San Juan Atitán",
      " San Juan Ixcoy",
      " San Mateo Ixtatánmunicipio",
      " San Miguel Acatán",
      " San Pedro Necta",
      " San Pedro Solomamunicipio",
      " San Rafael La Independencia",
      " San Rafael Petzal",
      " San Sebastián Coatán",
      " San Sebastián Huehuetenango",
      " Santa Ana Huista",
      " Santa Bárbara",
      " Santa Eulalia",
      " Santiago Chimaltenango",
      " Tectitánmunicipio",
      " Todos Santos Cuchumatánmunicipio",
      " Unión Cantinilmunicipio",
    ],
    Izabal: [
      "El Estor",
      "Livingston",
      "Los Amates",
      "Morales",
      "Puerto Barrios",
    ],
    Jalapa: [
      "Jalapa",
      "Mataquescuintla",
      "Monjas",
      "San Carlos Alzatate",
      "San Luis Jilotepeque",
      "San Manuel Chaparrón",
      "San Pedro Pinula",
    ],
    Jutiapa: [
      " Agua Blanca",
      " Asunción Mita",
      " Atescatempa",
      " Comapa",
      " Conguaco",
      " El Adelanto",
      " El Progreso",
      " Jalpatagua",
      " Jerez",
      " Jutiapa",
      " Moyuta",
      " Pasaco",
      " Quesada",
      " San José Acatempa",
      " Santa Catarina Mita",
      " Yupiltepeque",
      " Zapotitlán",
    ],
    Petén: [
      " Dolores",
      " El Chal",
      " Flores",
      " La Libertad",
      " Las Cruces",
      " Melchor de Mencos",
      " Poptún",
      " San Andrés",
      " San Benito",
      " San Francisco",
      " San José",
      " San Luis",
      " Santa Ana",
      " Sayaxché",
    ],
    Quetzaltenango: [
      " Almolonga",
      " Cabricán",
      " Cajolá",
      " Cantel",
      " Coatepeque",
      " Colomba",
      " Concepción Chiquirichapa",
      " El Palmar",
      " Flores Costa Cuca",
      " Génova",
      " Huitán",
      " La Esperanza",
      " Olintepeque",
      " Palestina de Los Altos",
      " Quetzaltenango",
      " Salcajá",
      " San Carlos Sija",
      " San Francisco La Unión",
      " San Juan Ostuncalco",
      " San Martín Sacatepéquez",
      " San Mateo",
      " San Miguel Sigüilá",
      " Sibilia",
      " Zunil",
    ],
    Quiché: ["Quiché"],
    Retalhuleu: [
      " Champerico",
      " El Asintal",
      " Nuevo San Carlos",
      " Retalhuleu",
      " San Andrés Villa Seca",
      " San Felipe",
      " San Martín Zapotitlán",
      " San Sebastián",
      " Santa Cruz Muluá",
    ],
    Sacatepéquez: [
      " Alotenango",
      " Antigua Guatemala",
      " Ciudad Vieja",
      " Jocotenango",
      " Magdalena Milpas Altas",
      " Pastores",
      " San Antonio Aguas Calientes",
      " San Bartolomé Milpas Altas",
      " San Lucas Sacatepéquez",
      " San Miguel Dueñas",
      " Santa Catarina Barahona",
      " Santa Lucía Milpas Altas",
      " Santa María de Jesús",
      " Santiago Sacatepéquez",
      " Santo Domingo Xenacoj",
      " Sumpango",
    ],
    "San Marcos": [
      " Ayutla",
      " Catarina",
      " Comitancillo",
      " Concepción Tutuapa",
      " El Quetzal",
      " El Rodeo",
      " El Tumbador",
      " Esquipulas Palo Gordo",
      " Ixchiguán",
      " La Blanca",
      " La Reforma",
      " Malacatán",
      " Nuevo Progreso",
      " Ocós",
      " Pajapita",
      " Río Blanco",
      " San Antonio Sacatepéquez",
      " San Cristóbal Cucho",
      " San José Ojetenam",
      " San Lorenzo",
      " San Marcos",
      " San Miguel Ixtahuacán",
      " San Pablo",
      " San Pedro Sacatepéquez",
      " San Rafael Pie de la Cuesta",
      " Sibinal",
      " Sipacapa",
      " Tacaná",
      " Tajumulco",
      " Tejutla",
    ],
    "Santa Rosa": [
      " Barberena",
      " Casillas",
      " Chiquimulilla",
      " Cuilapa",
      " Guazacapán",
      " Monterrico",
      " Nueva Santa Rosa",
      " Oratorio",
      " Pueblo Nuevo Viñas",
      " San Juan Tecuaco",
      " San Rafael Las Flores",
      " Santa Cruz Naranjo",
      " Santa María Ixhuatán",
      " Santa Rosa de Lima",
      " Taxisco",
    ],
    Sololá: [
      " Concepción",
      " Nahualá",
      " Panajachel",
      " San Andrés Semetabaj",
      " San Antonio Palopó",
      " San José Chacayá",
      " San Juan La Laguna",
      " San Lucas Tolimán",
      " San Marcos La Laguna",
      " San Pablo La Laguna",
      " San Pedro La Laguna",
      " Santa Catarina Ixtahuacán",
      " Santa Catarina Palopó",
      " Santa Clara La Laguna",
      " Santa Cruz La Laguna",
      " Santa Lucía Utatlán",
      " Santa María Visitación",
      " Santiago Atitlán",
      " Sololá",
    ],
    Suchitepéquez: [
      " Chicacao",
      " Cuyotenango",
      " Mazatenango",
      " Patulul",
      " Pueblo Nuevo",
      " Río Bravo",
      " Samayac",
      " San Antonio Suchitepéquez",
      " San Bernardino",
      " San Francisco Zapotitlán",
      " San Gabriel",
      " San José El Ídolo",
      " San José La Máquina",
      " San Juan Bautista",
      " San Lorenzo",
      " San Miguel Panán",
      " San Pablo Jocopilas",
      " Santa Bárbara",
      " Santo Domingo Suchitepéquez",
      " Santo Tomás La Unión",
      " Zunilito",
    ],
    Totonicapán: [
      " Momostenango",
      " San Andrés Xecul",
      " San Bartolo",
      " San Cristóbal Totonicapán",
      " San Francisco El Alto",
      " Santa Lucía La Reforma",
      " Santa María Chiquimula",
      " Totonicapán",
    ],
    Zacapa: [
      "Cabañas",
      "Estanzuela",
      "Gualán",
      "Huité",
      "La Unión",
      "Río Hondo",
      "San Diego",
      "San Jorge22​",
      "Teculután",
      "Usumatlán",
      "Zacapa",
    ],
  },
  "Guinea Ecuatorial": {
    Annobón: ["San Antonio de Palé", "Mabana"],
    "Bioko del Norte": ["Malabo", "Baney", "Rebola"],
    "Bioko del Sur": ["Luba", "Riaba"],
    "Centro Sur": ["Evinayong", "Niefang", "Akurenam", "Bicurga", "Nkimi"],
    Djibloho: ["Ciudad de la Paz", "Las Palmas del Río Muní"],
    "Guinea Ecuatorial": ["Guinea Ecuatorial"],
    "Kié-Ntem": [
      "Ebebiyín",
      "Micomiseng",
      "Nsork-Nzomo",
      "Ncue",
      "Bidjabidján",
      "Nsang",
    ],
    Litoral: [
      "Bata",
      "Mbini",
      "Kogo",
      "Machinda",
      "Bitika",
      "Corisco",
      "Río Campo",
    ],
    "Wele-Nzas": [
      "Mongomo",
      "Añisok",
      "Nsork",
      "Akonibe",
      "Mengomeyén",
      "Ayene",
    ],
  },
  Ghana: {
    "Región de la Gran Acra": ["Ciudad de Accra"],
    "Región de Ashanti": [
      "Kumasi",
      "Ejisu",
      "Obuasi",
      "Sekyere Central",
      "Sekyere East",
      "Sekyere South",
      "Bosome Freho",
      "Atwima Nwabiagya",
      "Atwima Mponua",
      "Amansie Central",
      "Amansie East",
      "Amansie West",
      "Asante Akim Central",
      "Asante Akim North",
      "Asante Akim South",
      "Afigya Kwabre North",
      "Afigya Kwabre South",
    ],
    "Brong/Ahafo": [
      "Sunyani ",
      "Berekum",
      "Dormaa",
      "Techiman",
      "Kintampo",
      "Wenchi",
      "Tain",
      "Jaman North",
      "Jaman South",
    ],
    "Región Central": [
      "Cape Coast ",
      "Agona Swedru",
      "Assin Fosu",
      "Winneba",
      "Elmina",
    ],
    "Región del Este": ["Koforidua ", "Akim Oda", "Nkawkaw", "Kibi"],
    "Región del Gran Accra": [
      "Accra",
      "Tema",
      "Ga West",
      "Ga Central",
      "Ga South",
      "Adenta",
      "La-Nkwantanang-Madina",
    ],
    "Región del Norte": [
      "Tamale",
      "Yendi",
      "Bole",
      "Walewale",
      "Gambaga",
      "Nalerigu",
      "Salaga",
    ],
    "Región del Oeste": [
      "Sekondi-Takoradi ",
      "Tarkwa",
      "Sefwi Wiawso",
      "Elubo",
    ],
    "Región de la Alta Costa": ["Ho ", "Hohoe", "Kpandu"],
    "Región de la Baja Costa": [
      "Cape Coast ",
      "Mfantsiman",
      "Komenda-Edina-Eguafo-Abirem ",
      "Twifo-Ati Morkwa",
    ],
    "Región de la Sabana": ["Damongo ", "Sawla-Tuna-Kalba", "Bole", "Wa"],
    "Región de la Volta": [
      "Ho ",
      "Keta",
      "Ketu",
      "Hohoe",
      "Jasikan",
      "Kpando",
      "Anloga",
    ],
    "Región del Alto Oeste": ["Wa ", "Jirapa", "Nadowli"],
    "Región del Medio Oeste": ["Dunkwa-on-Offin ", "Asankragua", "Tarkwa"],
    "Región del Alto Este": ["Koforidua ", "Akosombo", "Kibi"],
    "Región del Medio Este": ["Koforidua ", "Suhum", "Nsawam"],
  },
  Grecia: {
    Atíca: [
      "Atenas Septentriona ",
      "Atenas Occidenta ",
      "Atenas Central",
      "Atenas Meridional",
      "Ática Oriental",
      "El Pireo",
      "Islas",
      "Ática Occidental",
    ],
    Grecia_Central: ["Beocia", "Eubea", "Euritania", "Fócide", "Ftiótide"],
    Macedonia_Central: [
      "Emacia",
      "Tesalónica",
      "Kilkís",
      "Pella	",
      "Piería",
      "Serres",
      "Calcídica",
    ],
    Creta: ["La Canea", "Heraclión", "Lasithi", "Rétino"],
    Macedonia_Oriental_y_Tracia: [
      "Drama",
      "Evros",
      "Tasos",
      "Kavala",
      "Xánthi",
      "Ródope",
    ],
    Epiro: ["Tesprotia", "Ioánina", "Arta", "Préveza"],
    Islas_Jónicas: ["Ítaca", "Corfú", "Cefalonia", "Léucade", "Zante"],
    Egeo_Septentrional: ["Quíos", "Icaria", "Lemnos", "Lesbos", "Samos"],
    Peloponeso: ["Arcadia", "Argólida ", "Corintia ", "Laconia", "Mesenia"],
    Egeo_Meridional: [
      "Andros",
      "Kálimnos",
      "Kárpatos",
      "Ceos-Citnos",
      "Cos",
      "Milos",
      " Míkonos",
      " Naxos",
      " Paros",
      " Rodas",
      " Siros",
      " Santorini",
      " Tinos",
    ],
    Tesalia: ["Karditsa", "Larisa", "Magnesia", "Espóradas", "Tríkala"],
    Grecia_Occidental: ["Acaya", "Etolia-Acarnania", "Élide"],
    Macedonia_Occidental: ["Flórina", "Grevená", "Kastoriá", "Kozani"],
  },
  Honduras: {
    Atlántida: [
      "Arizona",
      "El Porvenir",
      "Esparta",
      "Jutiapa",
      "La Ceiba",
      "La Masica",
      "San Francisco",
      "Tela",
    ],
    Choluteca: [
      "Apacilagua",
      "Choluteca",
      "Concepción de María",
      "Duyure",
      "El Corpus",
      "El Triunfo",
      "Marcovia",
      "Morolica",
      "Namasigüe",
      "Orocuina",
      "Pespire",
      "San Antonio de Flores",
      "San Isidro",
      "San José",
      "San Marcos de Colón",
      "Santa Ana de Yusguare",
    ],
    Colón: [
      "Balfate",
      "Bonito Oriental",
      "Iriona",
      "Limón",
      "Sabá",
      "Santa Fe",
      "Santa Rosa de Aguán",
      "Sonaguera",
      "Tocoa",
      "Trujillo",
    ],
    Comayagua: [
      "Ajuterique",
      "Comayagua",
      "El Rosario",
      "Esquías",
      "Humuya",
      "La Libertad",
      "La Trinidad",
      "Lamaní",
      "Las Lajas",
      "Lejamaní",
      "Meámbar",
      "Minas de Oro",
      "Ojos de Agua",
      "San Jerónimo",
      "San José de Comayagua",
      "San José del Potrero",
      "San Luis",
      "San Sebastián",
      "Siguatepeque",
      "Taulabé",
      "Villa de San Antonio",
    ],
    Copán: [
      "Cabañas",
      "Concepción",
      "Copán Ruinas",
      "Corquín",
      "Cucuyagua",
      "Dolores",
      "Dulce Nombre",
      "El Paraíso",
      "Florida",
      "La Jigua",
      "La Unión",
      "Nueva Arcadia",
      "San Agustín",
      "San Antonio",
      "San Jerónimo",
      "San José",
      "San Juan de Opoa",
      "San Nicolás",
      "San Pedro",
      "Santa Rita",
      "Santa Rosa de Copán",
      "Trinidad de Copán",
      "Veracruz",
    ],
    Cortés: [
      "Choloma",
      "La Lima",
      "Omoa",
      "Pimienta",
      "Potrerillos",
      "Puerto Cortés",
      "San Antonio de Cortés",
      "San Francisco de Yojoa",
      "San Manuel",
      "San Pedro Sula",
      "Santa Cruz de Yojoa",
      "Villanueva",
    ],
    "El Paraíso": [
      "Alauca",
      "Danlí",
      "El Paraíso",
      "Güinope",
      "Jacaleapa",
      "Liure",
      "Morocelí",
      "Oropolí",
      "Potrerillos",
      "San Antonio de Flores",
      "San Lucas",
      "San Matías",
      "Soledad",
      "Teupasenti",
      "Texiguat",
      "Trojes",
      "Vado Ancho",
      "Yauyupe",
      "Yuscarán",
    ],
    "Francisco Morazán": [
      "Alubarén",
      "Cantarranas",
      "Cedros",
      "Curarén",
      "Distrito Central",
      "El Porvenir",
      "Guaimaca",
      "La Libertad",
      "La Venta",
      "Lepaterique",
      "Maraita",
      "Marale",
      "Nueva Armenia",
      "Ojojona",
      "Orica",
      "Reitoca",
      "Sabanagrande",
      "San Antonio de Oriente",
      "San Buenaventura",
      "San Ignacio",
      "San Miguelito",
      "Santa Ana",
      "Santa Lucía",
      "Talanga",
      "Tatumbla",
      "Valle de Ángeles",
      "Vallecillo",
      "Villa de San Francisco",
    ],
    "Gracias a Dios": [
      "Ahuas",
      "Brus Laguna",
      "Juan Francisco Bulnes",
      "Puerto Lempira",
      "Ramón Villeda Morales",
      "Wampusirpi",
    ],
    Intibucá: [
      "Camasca",
      "Colomoncagua",
      "Concepción",
      "Dolores",
      "Intibucá",
      "Jesús de Otoro",
      "La Esperanza",
      "Magdalena",
      "Masaguara",
      "San Antonio",
      "San Francisco de Opalaca",
      "San Isidro",
      "San Juan",
      "San Marcos de la Sierra",
      "San Miguel Guancapla",
      "Santa Lucía",
      "Yamaranguila",
    ],
    "Islas de la Bahía": [
      "Banco Vivorillo",
      "Guanaja",
      "J. Santos Guardiola",
      "Roatán",
      "Útila",
    ],
    "La Paz": [
      "Aguanqueterique",
      "Cabañas",
      "Cane",
      "Chinacla",
      "Guajiquiro",
      "La Paz",
      "Lauterique",
      "Marcala",
      "Mercedes de Oriente",
      "Opatoro",
      "San Antonio del Norte",
      "San José",
      "San Juan",
      "San Pedro de Tutule",
      "Santa Ana",
      "Santa Elena",
      "Santa María",
      "Santiago de Puringla",
      "Yarula",
    ],
    Lempira: [
      "Belén",
      "Candelaria",
      "Cololaca",
      "Erandique",
      "Gracias",
      "Gualcince",
      "Guarita",
      "La Campa",
      "La Iguala",
      "La Unión",
      "La Virtud",
      "Las Flores",
      "Lepaera",
      "Mapulaca",
      "Piraera",
      "San Andrés",
      "San Francisco",
      "San Juan Guarita",
      "San Manuel Colohete",
      "San Marcos de Caiquín",
      "San Rafael",
      "San Sebastián",
      "Santa Cruz",
      "Talgua",
      "Tambla",
      "Tomalá",
      "Valladolid",
      "Virginia",
    ],
    Ocotepeque: [
      "Belén Gualcho",
      "Concepción",
      "Dolores Merendón",
      "Fraternidad",
      "La Encarnación",
      "La Labor",
      "Lucerna",
      "Mercedes",
      "Ocotepeque",
      "San Fernando",
      "San Francisco del Valle",
      "San Jorge",
      "San Marcos",
      "Santa Fe",
      "Sensenti",
      "Sinuapa",
    ],
    Olancho: [
      "Campamento",
      "Catacamas",
      "Concordia",
      "Dulce Nombre de Culmí",
      "El Rosario",
      "Esquipulas del Norte",
      "Gualaco",
      "Guarizama",
      "Guata",
      "Guayape",
      "Jano",
      "Juticalpa",
      "La Unión",
      "Mangulile",
      "Manto",
      "Patuca",
      "Salamá",
      "San Esteban",
      "San Francisco de Becerra",
      "San Francisco de la Paz",
      "Santa María del Real",
      "Silca",
      "Yocón",
    ],
    "Santa Bárbara": [
      "Arada",
      "Atima",
      "Azacualpa",
      "Ceguaca",
      "Chinda",
      "Concepción del Norte",
      "Concepción del Sur",
      "El Níspero",
      "Gualala",
      "Ilama",
      "Las Vegas",
      "Macuelizo",
      "Naranjito",
      "Nueva Frontera",
      "Nuevo Celilac",
      "Petoa",
      "Protección",
      "Quimistán",
      "San Francisco de Ojuera",
      "San José de las Colinas",
      "San Luis",
      "San Marcos",
      "San Nicolás",
      "San Pedro Zacapa",
      "San Vicente Centenario",
      "Santa Bárbara",
      "Santa Rita",
      "Trinidad",
    ],
    Valle: [
      "Alianza",
      "Amapala",
      "Aramecina",
      "Caridad",
      "Goascorán",
      "Langue",
      "Nacaome",
      "San Francisco de Coray",
      "San Lorenzo",
    ],
    Yoro: [
      "Arenal",
      "El Negrito",
      "El Progreso",
      "Jocón",
      "Morazán",
      "Olanchito",
      "Santa Rita",
      "Sulaco",
      "Victoria",
      "Yorito",
      "Yoro",
    ],
  },
  "India / Bhārat Gaṇarājya": {
    "Andhra Pradesh": [
      "Anantapur",
      "Chittoor",
      "East Godavari",
      "Guntur",
      "Kadapa",
      "Krishna",
      "Kurnool",
      "Nellore",
      "Prakasam",
      "Srikakulam",
      "Visakhapatnam",
      "Vizianagaram",
      "West Godavari",
    ],
    "Arunachal Pradesh": [
      "Anjaw",
      "Changlang",
      "Dibang Valley",
      "East Kameng",
      "East Siang ",
      "Kamle ",
      "Kra Daadi ",
      "Kurung Kumey ",
      "Lepa-Rada ",
      "Lohit",
      "Longding ",
      "Lower Dibang Valley ",
      "Lower Siang",
      "Lower Subansiri ",
      "Namsai ",
      "Pakke-Kessang ",
      "Papum Pare",
      "Papum Pare ",
      "Shi-Yomi",
      "Siang ",
      "Tawang",
      "Tirap ",
      "Upper Siang ",
      "Upper Subansiri",
      "West Kameng",
      "West Siang",
    ],
    Assam: [
      "Baksa",
      "Barpeta",
      "Biswanath",
      "Bongaigaon",
      "Cachar",
      "Charaideo",
      "Chirang",
      "Darrang",
      "Dhemaji",
      "Dhubri",
      "Dibrugarh",
      "Dima Hasao",
      "Distrito metropolitano de Kamrup",
      "Goalpara",
      "Golaghat",
      "Hailakandi",
      "Hojai",
      "Jorhat",
      "Kamrup",
      "Karbi Anglong",
      "Karbi Anglong occidental",
      "Karimganj",
      "Kokrajhar",
      "Lakhimpur",
      "Majuli",
      "Morigaon",
      "Nagaon",
      "Nalbari",
      "Salmara-Mankachar meridional",
      "Sivasagar",
      "Sonitpur",
      "Tinsukia",
      "Udalguri",
    ],
    "Bengala Occidental": [
      "Alipurduar",
      "Bankura",
      "Birbhum",
      "Cooch Behar",
      "Dakshin Dinajpur",
      "Darjeeling",
      "East Midnapore",
      "Hooghly",
      "Howrah",
      "Jalpaiguri",
      "Jhargram",
      "Kalimpong",
      "Kolkata",
      "Maldah",
      "Murshidabad",
      "Nadia",
      "North 24 Parganas",
      "North Dinajpur",
      "Paschim Bardhaman",
      "Purba Bardhaman",
      "Purulia",
      "South 24 Parganas",
      "West Midnapore",
    ],
    Bihar: [
      "Araria",
      "Arwal",
      "Aurangabad",
      "Banka",
      "Begusarai",
      "Bhagalpur",
      "Bhojpur",
      "Buxar",
      "Darbhanga",
      "East Champaran (Motihari)",
      "Gaya",
      "Gopalganj",
      "Jamui",
      "Jehanabad",
      "Kaimur (Bhabua)",
      "Katihar",
      "Khagaria",
      "Kishanganj",
      "Lakhisarai",
      "Madhepura",
      "Madhubani",
      "Munger (Monghyr)",
      "Muzaffarpur",
      "Nalanda",
      "Nawada",
      "Patna",
      "Purnia (Purnea)",
      "Rohtas",
      "Saharsa",
      "Samastipur",
      "Saran",
      "Sheikhpura",
      "Sheohar",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Vaishali",
      "West Champaran",
    ],
    Chandigarh: ["Chandigarh"],
    Chhattisgarh: [
      "Balod",
      "Baloda Bazar",
      "Balrampur",
      "Bastar",
      "Bemetara",
      "Bijapur",
      "Bilaspur",
      "Dantewada",
      "Dhamtari",
      "Durg",
      "Gariaband",
      "Gaurella-Pendra-Marwahi",
      "Janjgir-Champa",
      "Jashpur",
      "Kabirdham",
      "Kanker",
      "Kondagaon",
      "Korba",
      "Koriya",
      "Mahasamund",
      "Mungeli",
      "Narayanpur",
      "Raigarh",
      "Raipur",
      "Rajnandgaon",
      "Sukma",
      "Surajpur",
      "Surguja",
    ],
    "Dadra y Nagar Haveli y Damán y Diu": [
      "Dadra y Nagar Haveli y Damán y Diu",
    ],
    Delhi: [
      "New Delhi",
      "Central Delhi",
      "East Delhi",
      "North Delhi",
      "North East Delhi",
      "North West Delhi",
      "Shahdara",
      "South Delhi",
      "South East Delhi",
      "South West Delhi",
      "West Delhi",
    ],
    Goa: ["North Goa", "South Goa"],
    Gujarat: [
      "Ahmedabad",
      "Amreli",
      "Anand",
      "Aravalli",
      "Banaskantha",
      "Bharuch",
      "Bhavnagar",
      "Botad",
      "Chhota Udaipur",
      "Dahod",
      "Dang",
      "Devbhoomi Dwarka",
      "Gandhinagar",
      "Gir Somnath",
      "Jamnagar",
      "Junagadh",
      "Kachchh",
      "Kheda",
      "Mahisagar",
      "Mehsana",
      "Morbi",
      "Narmada",
      "Navsari",
      "Panchmahal",
      "Patan",
      "Porbandar",
      "Rajkot",
      "Sabarkantha",
      "Surat",
      "Surendranagar",
      "Tapi",
      "Vadodara",
      "Valsad",
    ],
    Haryana: [
      "Ambala",
      "Bhiwani",
      "Charkhi Dadri",
      "Faridabad",
      "Fatehabad",
      "Gurugram",
      "Hisar",
      "Jhajjar",
      "Jind",
      "Kaithal",
      "Karnal",
      "Kurukshetra",
      "Mahendragarh",
      "Nuh",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Rewari",
      "Rohtak",
      "Sirsa",
      "Sonipat",
      "Yamunanagar",
    ],
    "Himachal Pradesh": [
      "Bilaspur",
      "Chamba",
      "Hamirpur",
      "Kangra",
      "Kinnaur",
      "Kullu",
      "Lahaul & Spiti",
      "Mandi",
      "Shimla",
      "Sirmaur",
      "Solan",
      "Una",
    ],
    "Islas Andamán y Nicobar": [
      "Nicobar",
      "North and Middle Andaman",
      "South Andaman",
    ],
    "Jammu and Kashmir": [
      "Anantnag",
      "Bandipora",
      "Baramulla",
      "Budgam",
      "Doda",
      "Ganderbal",
      "Jammu",
      "Kathua",
      "Kishtwar",
      "Kulgam",
      "Kupwara",
      "Poonch",
      "Pulwama",
      "Rajouri",
      "Ramban",
      "Reasi",
      "Samba",
      "Shopian",
      "Srinagar",
      "Srinagar",
      "Udhampur",
    ],
    Jharkhand: [
      "Garhwa",
      "Palamu",
      "Latehar.",
      "Chatra",
      "Hazaribagh",
      "Koderma",
      "Giridih",
      "Ramgarh",
      "Bokaro",
      "Dhanbad",
      "Gumla",
      "Lohardaga",
      "Simdega",
      "RAnchi",
      "Khunti",
      "West Singhbhum",
      "Saraikela Kharsawan",
      "East Singhbhum",
      "Jamtara",
      "Deoghar",
      "Dumka",
      "Pakur",
      "Godda",
      "Sahebganj",
    ],
    Karnataka: [
      "Bagalkot",
      "Bangalore Rural",
      "Bangalore Urban",
      "Belagavi",
      "Bellary",
      "Bidar",
      "Bijapur",
      "Chamarajanagar",
      "Chikkaballapur",
      "Chikmagalur",
      "Chitradurga",
      "Dakshina Kannada",
      "Davanagere",
      "Dharwad",
      "Gadag",
      "Gulbarga",
      "Hassan",
      "Haveri",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysore",
      "Raichur",
      "Ramanagara",
      "Shimoga",
      "Tumkur",
      "Udupi",
      "Uttara Kannada",
      "Vijayanagara",
      "Yadagiri",
    ],
    Kerala: [
      "Alappuzha",
      "Ernakulam",
      "Idukki",
      "Kannur",
      "Kasaragod",
      "Kollam",
      "Kottayam",
      "Kozhikode",
      "Malappuram",
      "Palakkad",
      "Pathanamthitta",
      "Thiruvananthapuram",
      "Thrissur",
      "Wayanad",
    ],
    Ladakh: ["Kargil", "Leh"],
    Laquedivas: ["Laquedivas"],
    "Madhya Pradesh": [
      "Agar Malwa",
      "Alirajpur",
      "Anuppur",
      "Ashok Nagar",
      "Balaghat",
      "Barwani",
      "Betul",
      "Bhind",
      "Bhopal",
      "Burhanpur",
      "Chachauda",
      "Chhatarpur",
      "Chhindwara",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhar",
      "Dindori",
      "Guna",
      "Gwalior",
      "Harda",
      "Hoshangabad",
      "Indore",
      "Jabalpur",
      "Jhabua",
      "Katni",
      "Khandwa",
      "Khargone",
      "Maihar",
      "Mandla",
      "Mandsaur",
      "Morena",
      "Nagda",
      "Narsinghpur",
      "Neemuch",
      "Niwari",
      "Panna",
      "Raisen",
      "Rajgarh",
      "Ratlam",
      "Rewa",
      "Sagar",
      "Satna",
      "Sehore",
      "Seoni",
      "Shahdol",
      "Shajapur",
      "Sheopur",
      "Shivpuri",
      "Sidhi",
      "Singrauli",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
    ],
    Maharashtra: [
      "Ahmednagar",
      "Akola",
      "Amravati",
      "Aurangabad",
      "Beed",
      "Bhandara",
      "Buldana",
      "Chandrapur",
      "Dhule",
      "Gadchiroli",
      "Gondia",
      "Hingoli",
      "Jalgaon",
      "Jalna",
      "Kolhapur",
      "Latur",
      "Mumbai City",
      "Mumbai Suburban",
      "Nagpur",
      "Nanded",
      "Nandurbar",
      "Nashik",
      "Osmanabad",
      "Palghar",
      "Parbhani",
      "Pune",
      "Raigad",
      "Ratnagiri",
      "Sangli",
      "Satara",
      "Sindhudurg",
      "Solapur",
      "Thane",
      "Wardha",
      "Washim",
      "Yavatmal",
    ],
    Manipur: [
      "Bishnupur",
      "Chandel",
      "Churachandpur",
      "Imphal East",
      "Imphal West",
      "Jiribam",
      "Kakching",
      "Kamjong",
      "Kangpokpi (Sadar Hills)",
      "Noney",
      "Pherzawl",
      "Senapati",
      "Tamenglong",
      "Tengnoupal",
      "Thoubal",
      "Ukhrul",
    ],
    Meghalaya: [
      "East Garo Hills",
      "East Jaintia Hills",
      "East Khasi Hills",
      "North Garo Hills",
      "Ri Bhoi",
      "South Garo Hills",
      "South West Garo Hills",
      "South West Khasi Hills",
      "West Garo Hills",
      "West Jaintia Hills",
      "West Khasi Hills",
    ],
    Mizoram: [
      "Aizawl",
      "Kolasib",
      "Lawngtlai",
      "Lunglei",
      "Mamit",
      "Saiha",
      "Serchhip",
      "Champhai",
      "Hnahthial",
      "Khawzawl",
      "Saitual",
    ],
    Nagaland: [
      "Dimapur",
      "Kiphire",
      "Kohima",
      "Longleng",
      "Mokokchung",
      "Mon",
      "Noklak",
      "Peren",
      "Phek",
      "Tuensang",
      "Wokha",
      "Zunheboto",
    ],
    Odisha: [
      "Angul",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhadrak",
      "Boudh",
      "Cuttack",
      "Deogarh",
      "Dhenkanal",
      "Gajapati",
      "Ganjam",
      "Jagatsinghapur",
      "Jajpur",
      "Jharsuguda",
      "Kalahandi",
      "Kandhamal",
      "Kendrapara",
      "Kendujhar (Keonjhar)",
      "Khordha",
      "Koraput",
      "Malkangiri",
      "Mayurbhanj",
      "Nabarangpur",
      "Nayagarh",
      "Nuapada",
      "Puri",
      "Rayagada",
      "Sambalpur",
      "Sonepur",
      "Sundargarh",
    ],
    Pondicherry: ["Karaikal", "Mahé", "Puducherry", "Yanam"],
    Punjab: [
      "Amritsar",
      "Barnala",
      "Bathinda",
      "Faridkot",
      "Fatehgarh Sahib",
      "Fazilka",
      "Ferozepur",
      "Gurdaspur",
      "Hoshiarpur",
      "Jalandhar",
      "Kapurthala",
      "Ludhiana",
      "Mansa",
      "Moga",
      "Muktsar",
      "Nawanshahr (Shahid Bhagat Singh Nagar)",
      "Pathankot",
      "Patiala",
      "Rupnagar",
      "Sahibzada Ajit Singh Nagar (Mohali)",
      "Sangrur",
      "Tarn Taran",
    ],
    Rajasthan: [
      "Ajmer",
      "Alwar",
      "Banswara",
      "Baran",
      "Barmer",
      "Bharatpur",
      "Bhilwara",
      "Bikaner",
      "Bundi",
      "Chittorgarh",
      "Churu",
      "Dausa",
      "Dholpur",
      "Dungarpur",
      "Hanumangarh",
      "Jaipur",
      "Jaisalmer",
      "Jalore",
      "Jhalawar",
      "Jhunjhunu",
      "Jodhpur",
      "Karauli",
      "Kota",
      "Nagaur",
      "Pali",
      "Pratapgarh",
      "Rajsamand",
      "Sawai Madhopur",
      "Sikar",
      "Sirohi",
      "Sri Ganganagar",
      "Tonk",
      "Udaipur",
    ],
    Sikkim: ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
    "Tamil Nadu": [
      "Ariyalur",
      "Chengalpattu",
      "Chennai",
      "Coimbatore",
      "Cuddalore",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Kallakurichi",
      "Kanchipuram",
      "Kanyakumari",
      "Karur",
      "Krishnagiri",
      "Madurai",
      "Nagapattinam",
      "Namakkal",
      "Nilgiris",
      "Perambalur",
      "Pudukkottai",
      "Ramanathapuram",
      "Ranipet",
      "Salem",
      "Sivaganga",
      "Tenkasi",
      "Thanjavur",
      "Theni",
      "Thoothukudi (Tuticorin)",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tirupathur",
      "Tiruppur",
      "Tiruvallur",
      "Tiruvannamalai",
      "Tiruvarur",
      "Vellore",
      "Viluppuram",
      "Virudhunagar",
    ],
    Telangana: [
      "Adilabad",
      "Bhadradri Kothagudem",
      "Hyderabad",
      "Jagitial",
      "Jangaon",
      "Jayashankar Bhupalpally",
      "Jogulamba Gadwal",
      "Kamareddy",
      "Karimnagar",
      "Khammam",
      "Komaram Bheem",
      "Mahabubabad",
      "Mahabubnagar",
      "Mancherial",
      "Medak",
      "Medchal–Malkajgiri",
      "Mulugu",
      "Nagarkurnool",
      "Nalgonda",
      "Narayanpet",
      "Nirmal",
      "Nizamabad",
      "Peddapalli",
      "Rajanna Sircilla",
      "Ranga Reddy",
      "Sangareddy",
      "Siddipet",
      "Suryapet",
      "Vikarabad",
      "Wanaparthy",
      "Warangal Rural",
      "Warangal Urban",
      "Yadadri Bhuvanagiri",
    ],
    Tripura: [
      "Dhalai",
      "Gomati",
      "Khowai",
      "North Tripura",
      "Sipahijala",
      "South Tripura",
      "Unakoti",
      "West Tripura",
    ],
    "Uttar Pradesh": [
      "Agra",
      "Aligarh",
      "Allahabad",
      "Ambedkar Nagar",
      "Amethi (Chatrapati Sahuji Mahraj Nagar)",
      "Amroha (J.P. Nagar)",
      "Auraiya",
      "Azamgarh",
      "Baghpat",
      "Bahraich",
      "Ballia",
      "Balrampur",
      "Banda",
      "Barabanki",
      "Bareilly",
      "Basti",
      "Bhadohi",
      "Bijnor",
      "Budaun",
      "Bulandshahr",
      "Chandauli",
      "Chitrakoot",
      "Deoria",
      "Etah",
      "Etawah",
      "Faizabad",
      "Farrukhabad",
      "Fatehpur",
      "Firozabad",
      "Gautam Buddha Nagar",
      "Ghaziabad",
      "Ghazipur",
      "Gonda",
      "Gorakhpur",
      "Hamirpur",
      "Hapur (Panchsheel Nagar)",
      "Hardoi",
      "Hathras",
      "Jalaun",
      "Jaunpur",
      "Jhansi",
      "Kannauj",
      "Kanpur Dehat",
      "Kanpur Nagar",
      "Kanshiram Nagar (Kasganj)",
      "Kaushambi",
      "Kushinagar (Padrauna)",
      "Lakhimpur - Kheri",
      "Lalitpur",
      "Lucknow",
      "Maharajganj",
      "Mahoba",
      "Mainpuri",
      "Mathura",
      "Mau",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muzaffarnagar",
      "Pilibhit",
      "Pratapgarh",
      "RaeBareli",
      "Rampur",
      "Saharanpur",
      "Sambhal (Bhim Nagar)",
      "Sant Kabir Nagar",
      "Shahjahanpur",
      "Shamali (Prabuddh Nagar)",
      "Shravasti",
      "Siddharth Nagar",
      "Sitapur",
      "Sonbhadra",
      "Sultanpur",
      "Unnao",
      "Varanasi",
    ],
    Uttarakhand: [
      "Almora",
      "Bageshwar",
      "Chamoli",
      "Champawat",
      "Dehradun",
      "Haridwar",
      "Nainital",
      "Pauri Garhwal",
      "Pithoragarh",
      "Rudraprayag",
      "Tehri Garhwal",
      "U S Nagar",
      "Uttarkashi",
    ],
  },
  Indonesia: {
    Aceh: [
      "Aceh Barat",
      "Aceh Barat Daya",
      "Aceh Besar",
      "Aceh Jaya",
      "Aceh Selatan",
      "Aceh Singkil",
      "Aceh Tamiang",
      "Aceh Tengah",
      "Aceh Tenggara",
      "Aceh Timur",
      "Banda Aceh",
      "Langsa",
      "Lhokseumawe",
      "Sabang",
      "Subulussalam",
    ],
    Bali: [
      "Badung",
      "Bangli",
      "Buleleng",
      "Gianyar",
      "Jembrana",
      "Karangasem",
      "Klungkung",
      "Tabanan",
      "Denpasar",
    ],
    "Kepulauan Bangka Belitung (Islas Bangka Belitung)": [
      "Bangka",
      "Bangka Barat",
      "Bangka Selatan",
      "Bangka Tengah",
      "Belitung",
      "Belitung Timur",
    ],
    Banten: [
      "Lebak",
      "Pandeglang",
      "Serang",
      "Tangerang",
      "Cilegon",
      "Serang",
      "Tangerang Selatan",
    ],
    Bengkulu: [
      "Bengkulu Selatan",
      "Bengkulu Tengah",
      "Bengkulu Utara",
      "Kaur",
      "Kepahiang",
      "Lebong",
      "Mukomuko",
      "Rejang Lebong",
      "Bengkulu",
    ],
    Gorontalo: [
      "Boalemo",
      "Bone Bolango",
      "Gorontalo",
      "Gorontalo Utara",
      "Pohuwato",
    ],
    Jakarta: [
      "Jakarta Central",
      "Jakarta del Norte",
      "Jakarta del Sur",
      "Jakarta del Este",
      "Jakarta del Oeste",
    ],
    Jambi: [
      "Bungo",
      "Kerinci",
      "Merangin",
      "Muaro Jambi",
      "Sarolangun",
      "Batanghari",
      "Tanjung Jabung Barat",
      "Tanjung Jabung Timur",
      "Tebo",
      "Jambi ",
      "Sungai Penuh ",
    ],
    "Jawa Barat (Java Occidental)": [
      "Bandung",
      "Banjar",
      "Bekasi",
      "Bogor",
      "Ciamis",
      "Cianjur",
      "Cirebon",
      "Garut",
      "Indramayu",
      "Karawang",
      "Kuningan",
      "Majalengka",
      "Pangandaran",
      "Purwakarta",
      "Subang",
      "Sukabumi",
      "Sumedang",
      "Tasikmalaya",
    ],
    "Jawa Tengah (Java Central)": [
      "Banjarnegara",
      "Banyumas",
      "Batang",
      "Blora",
      "Boyolali",
      "Brebes",
      "Cilacap",
      "Demak",
      "Grobogan",
      "Jepara",
      "Karanganyar",
      "Kebumen",
      "Kendal",
      "Klaten",
      "Kudus",
      "Magelang",
      "Pati",
      "Pekalongan",
      "Pemalang",
      "Purbalingga",
      "Purworejo",
      "Rembang",
      "Salatiga",
      "Semarang",
      "Sragen",
      "Sukoharjo",
      "Surakarta",
      "Tegal",
      "Temanggung",
      "Wonogiri",
      "Wonosobo",
    ],
    "Jawa Timur (Java Oriental)": [
      "Bangkalan",
      "Banyuwangi",
      "Blitar",
      "Bojonegoro",
      "Bondowoso",
      "Gresik",
      "Jember",
      "Jombang",
      "Kediri",
      "Lamongan",
      "Lumajang",
      "Madiun",
      "Magetan",
      "Malang",
      "Mojokerto",
      "Nganjuk",
      "Ngawi",
      "Pacitan",
      "Pamekasan",
      "Pasuruan",
      "Ponorogo",
      "Probolinggo",
      "Sampang",
      "Sidoarjo",
      "Situbondo",
      "Sumenep",
      "Surabaya",
      "Trenggalek",
      "Tuban",
      "Tulungagung",
    ],
    "Kalimantan Barat (Kalimantan Occidental)": [
      "Bengkayang",
      "Kapuas Hulu",
      "Kayong Utara",
      "Ketapang",
      "Kubu Raya",
      "Landak",
      "Melawi",
      "Mempawah",
      "Sambas",
      "Sanggau",
      "Sekadau",
      "Sintang",
    ],
    "Kalimantan Selatan (Kalimantan del Sur)": [
      "Balangan",
      "Banjar",
      "Barito Kuala",
      "Hulu Sungai Selatan",
      "Hulu Sungai Tengah",
      "Hulu Sungai Utara",
      "Kotabaru",
      "Tabalong",
      "Tanah Bumbu",
      "Tanah Laut",
      "Tapin",
    ],
    "Kalimantan Tengah (Kalimantan Central)": [
      "Barito Selatan",
      "Barito Timur",
      "Barito Utara",
      "Gunung Mas",
      "Kapuas",
      "Katingan",
      "Kotawaringin Barat",
      "Kotawaringin Timur",
      "Lamandau",
      "Murung Raya",
      "Pulang Pisau",
      "Sukamara",
    ],
    "Kalimantan Timur (Kalimantan del Este)": [
      "Berau",
      "Kutai Barat",
      "Kutai Kartanegara",
      "Kutai Timur",
      "Mahakam Ulu",
      "Paser",
      "Penajam Paser Utara",
    ],
    "Kalimantan Utara (Kalimantan del Norte)": [
      "Bulungan",
      "Malinau",
      "Nunukan",
      "Tana Tidung",
    ],
    "Kepulauan Riau (Islas Riau)": [
      "Bintan",
      "Karimun",
      "Kepulauan Anambas",
      "Lingga",
      "Natuna",
      "Tanjung Pinang",
    ],
    Lampung: [
      "Lampung Barat",
      "Lampung Selatan",
      "Lampung Tengah",
      "Lampung Timur",
      "Lampung Utara",
      "Mesuji",
      "Pesawaran",
      "Pesisir Barat",
      "Pringsewu",
      "Tanggamus",
      "Tulang Bawang",
      "Tulang Bawang Barat",
      "Way Kanan",
    ],
    Maluku: [
      "Ambon",
      "Aru Selatan",
      "Aru Tengah",
      "Aru Utara",
      "Buru",
      "Buru Selatan",
      "Kepulauan Sula",
      "Maluku Barat Daya",
      "Maluku Tengah",
      "Maluku Tenggara",
      "Maluku Tenggara Barat",
      "Seram Bagian Barat",
      "Seram Bagian Timur",
    ],
    "Maluku Utara (Molucas del Norte)": [
      "Halmahera Barat",
      "Halmahera Selatan",
      "Halmahera Tengah",
      "Halmahera Timur",
      "Halmahera Utara",
      "Morotai",
      "Pulau Taliabu",
      "Ternate",
      "Tidore Kepulauan",
    ],
    "Nusa Tenggara Barat": [
      "Bima",
      "Dompu",
      "Lombok Barat",
      "Lombok Tengah",
      "Lombok Timur",
      "Lombok Utara",
      "Sumbawa",
      "Sumbawa Barat",
    ],
    "Nusa Tenggara Timur": [
      "Alor",
      "Belu",
      "Ende",
      "Flores Timur",
      "Kupang",
      "Lembata",
      "Malaka",
      "Manggarai",
      "Manggarai Barat",
      "Manggarai Timur",
      "Nagekeo",
      "Ngada",
      "Rote Ndao",
      "Sabu Raijua",
      "Sikka",
      "Sumba Barat",
      "Sumba Barat Daya",
      "Sumba Tengah",
      "Sumba Timur",
      "Timor Tengah Selatan",
      "Timor Tengah Utara",
    ],
    Papua: [
      "Asmat",
      "Biak Numfor",
      "Boven Digoel",
      "Deiyai",
      "Dogiyai",
      "Intan Jaya",
      "Jayapura",
      "Jayawijaya",
      "Keerom",
      "Kepulauan Yapen",
      "Lanny Jaya",
      "Mamberamo Raya",
      "Mamberamo Tengah",
      "Mappi",
      "Merauke",
      "Mimika",
      "Nabire",
      "Nduga",
      "Paniai",
      "Pegunungan Bintang",
      "Puncak",
      "Puncak Jaya",
      "Sarmi",
      "Supiori",
      "Tolikara",
      "Waropen",
      "Yahukimo",
      "Yalimo",
    ],
    "Papua Barat ": [
      "Fakfak",
      "Kaimana",
      "Manokwari",
      "Manokwari Selatan",
      "Maybrat",
      "Pegunungan Arfak",
      "Raja Ampat",
      "Sorong",
      "Sorong Selatan",
      "Tambrauw",
      "Teluk Bintuni",
      "Teluk Wondama",
    ],
    Riau: [
      "Bengkalis",
      "Indragiri Hilir",
      "Indragiri Hulu",
      "Kampar",
      "Kepulauan Meranti",
      "Kuantan Singingi",
      "Pelalawan",
      "Rokan Hilir",
      "Rokan Hulu",
      "Siak",
    ],
    "Sulawesi Barat ": [
      "Majene",
      "Mamasa",
      "Mamuju",
      "Mamuju Tengah",
      "Mamuju Utara",
      "Polewali Mandar",
    ],
    "Sulawesi Selatan": [
      "Bantaeng",
      "Barru",
      "Bone",
      "Bulukumba",
      "Enrekang",
      "Gowa",
      "Jeneponto",
      "Kepulauan Selayar",
      "Luwu",
      "Luwu Timur",
      "Luwu Utara",
      "Maros",
      "Pangkajene dan Kepulauan",
      "Pinrang",
      "Sidenreng Rappang",
      "Sinjai",
      "Soppeng",
      "Takalar",
      "Tana Toraja",
      "Toraja Utara",
      "Wajo",
    ],
    "Sulawesi Tengah ": [
      "Banggai",
      "Banggai Kepulauan",
      "Banggai Laut",
      "Buol",
      "Donggala",
      "Morowali",
      "Morowali Utara",
      "Parigi Moutong",
      "Poso",
      "Sigi",
      "Tojo Una-Una",
      "Tolitoli",
    ],
    "Sulawesi Tenggara": [
      "Bombana",
      "Buton",
      "Buton Selatan",
      "Buton Tengah",
      "Buton Utara",
      "Kolaka",
      "Kolaka Timur",
      "Kolaka Utara",
      "Konawe",
      "Konawe Kepulauan",
      "Konawe Selatan",
      "Konawe Utara",
      "Muna",
      "Muna Barat",
      "Wakatobi",
    ],
    "Sulawesi Utara ": [
      "Bolaang Mongondow",
      "Bolaang Mongondow Selatan",
      "Bolaang Mongondow Timur",
      "Bolaang Mongondow Utara",
      "Kepulauan Sangihe",
      "Kepulauan Siau Tagulandang Biaro",
      "Kepulauan Talaud",
      "Minahasa",
      "Minahasa Selatan",
      "Minahasa Tenggara",
      "Minahasa Utara",
    ],
    "Sumatera Barat ": [
      "Agam",
      "Dharmasraya",
      "Kepulauan Mentawai",
      "Lima Puluh Kota",
      "Padang Pariaman",
      "Pasaman",
      "Pasaman Barat",
      "Pesisir Selatan",
      "Sijunjung",
      "Solok",
      "Solok Selatan",
      "Tanah Datar",
    ],
    "Sumatera Selatan (Sumatra del Sur)": [
      "Banyuasin",
      "Empat Lawang",
      "Lahat",
      "Muara Enim",
      "Musi Banyuasin",
      "Musi Rawas",
      "Ogan Ilir",
      "Ogan Komering Ilir",
      "Ogan Komering Ulu",
      "Ogan Komering Ulu Selatan",
      "Ogan Komering Ulu Timur",
      "Penukal Abab Lematang Ilir",
    ],
    "Sumatera Utara (Sumatra del Norte)": [
      "Asahan",
      "Batubara",
      "Dairi",
      "Deli Serdang",
      "Humbang Hasundutan",
      "Karo",
      "Labuhan Batu",
      "Labuhan Batu Selatan",
      "Labuhan Batu Utara",
      "Langkat",
      "Mandailing Natal",
      "Medan",
      "Nias",
      "Nias Barat",
      "Nias Selatan",
      "Padang Lawas",
      "Padang Lawas Utara",
      "Pakpak Bharat",
      "Samosir",
      "Serdang Bedagai",
      "Simalungun",
      "Tapanuli Selatan",
      "Tapanuli Tengah",
      "Tapanuli Utara",
      "Toba Samosir",
    ],
    "Yogyakarta (Yogyakarta Especial)": [
      "Caturtunggal",
      "Danurejan",
      "Depok",
      "Gamping",
      "Gondokusuman",
      "Gondomanan",
      "Jetis",
      "Kalasan",
      "Kaliurang",
      "Kotagede",
      "Mergangsan",
      "Ngaglik",
      "Ngemplak",
      "Pakualaman",
      "Prambanan",
      "Seyegan",
      "Sleman",
      "Tegalrejo",
      "Tembalang",
      "Umbulharjo",
      "Wirobrajan",
    ],
  },
  "Inglaterra / England": {
    Bedfordshire: ["Bedford", "Central Bedfordshire", "Luton"],
    Berkshire: [
      "West Berkshire",
      "Reading",
      "Wokingham",
      "Bracknell Forest",
      "Windsor and Maidenhead",
      "Slough",
    ],
    Brístol: ["Brístol"],
    Buckinghamshire: [
      "South Bucks",
      "Chiltern",
      "Wycombe",
      "Aylesbury Vale",
      "Milton Keynes",
    ],
    Cambridgeshire: ["Cambridge", "Huntingdon", "Peterborough", "Soham"],
    Cheshire: [
      "Ellesmere Port and Neston",
      "Chester",
      "Crewe and Nantwich",
      "Congleton",
      "Macclesfield",
      "Vale Royal",
      "Halton",
      "Warrington",
    ],
    Cornwall: [
      "Penwith",
      "Kerrier",
      "Carrick",
      "Restormel",
      "Caradon",
      "North Cornwall",
    ],
    Cumbria: [
      "Barrow-in-Furness",
      "South Lakeland",
      "Copeland",
      "Allerdale",
      "Eden",
      "Carlisle",
    ],
    Derbyshire: [
      "High Peak",
      "Derbyshire Dales",
      "South Derbyshire",
      "Erewash",
      "Amber Valley",
      "North East Derbyshire",
      "Chesterfield",
      "Bolsover",
      "Derby",
    ],
    Devon: [
      "East Devon",
      "Exeter",
      "Mid Devon",
      "North Devon",
      "Plymouth",
      "South Hams",
      "Teignbridge",
      "Torquay",
      "Torridge",
      "West Devon",
    ],
    Dorset: [
      "Bournemouth",
      "Christchurch",
      "East Dorset",
      "North Dorset",
      "Poole",
      "Purbeck",
      "West Dorset",
      "Weymouth and Portland",
    ],
    Durham: [
      "Condado de Durham",
      "Hartlepool",
      "Darlington",
      "Stockton-on-Tees",
    ],
    Essex: [
      "Basildon",
      "Braintree",
      "Brentwood",
      "Castle Point",
      "Chelmsford",
      "Colchester",
      "Epping Forest",
      "Harlow",
      "Maldon",
      "Rochford",
      "Southend-on-Sea",
      "Tendring",
      "Thurrock",
      "Uttlesford",
    ],
    Gloucestershire: [
      "Cheltenham",
      "Cotswold",
      "Forest of Dean",
      "Gloucester",
      "South Gloucestershire",
      "Stroud",
      "Tewkesbury",
    ],
    "Gran Londres": [
      "Barking y Dagenham",
      "Barnet",
      "Bexley",
      "Brent",
      "Bromley",
      "Camden",
      "Ciudad de Londres",
      "Ciudad de Westminster",
      "Croydon",
      "Ealing",
      "Enfield",
      "Greenwich",
      "Hackney",
      "Hammersmith y Fulham",
      "Haringey",
      "Harrow",
      "Havering",
      "Hillingdon",
      "Hounslow",
      "Islington",
      "Kensington y Chelsea",
      "Kingston upon Thames",
      "Lambeth",
      "Lewisham",
      "Merton",
      "Newham",
      "Redbridge",
      "Richmond upon Thames",
      "Southwark",
      "Sutton",
      "Tower Hamlets",
      "Waltham Forest",
      "Wandsworth",
    ],
    "Gran Mánchester": [
      "Bolton",
      "Bury",
      "Mánchester",
      "Oldham",
      "Rochdale",
      "Salford",
      "Stockport",
      "Tameside",
      "Trafford",
      "Wigan",
    ],
    Hampshire: [
      "Basingstoke y Deane",
      "East Hampshire",
      "Eastleigh",
      "Fareham",
      "Gosport",
      "Hart",
      "Havant",
      "New Forest",
      "Portsmouth",
      "Rushmoor",
      "Southampton",
      "Test Valley",
      "Winchester",
    ],
    Hertfordshire: [
      "Herefordshire",
      "Broxbourne",
      "Dacorum",
      "East Hertfordshire",
      "Hertsmere",
      "North Hertfordshire",
      "St. Albans",
      "Stevenage",
      "Three Rivers",
      "Watford",
      "Welwyn Hatfield",
    ],
    "Isla de Wight": ["isla de Wight"],
    Kent: [
      "Ashford",
      "Canterbury",
      "Dartford",
      "Dover",
      "Folkestone and Hythe",
      "Gravesham",
      "Maidstone",
      "Sevenoaks",
      "Swale",
      "Thanet",
      "Tonbridge and Malling ",
      "Tunbridge Wells",
    ],
    Lancashire: [
      "Blackburn with Darwen",
      "Blackpool",
      "Burnley",
      "Chorley",
      "Fylde",
      "Hyndburn",
      "Lancaster",
      "Pendle",
      "Preston",
      "Ribble Valley",
      "Rossendale",
      "South Ribble",
      "West Lancashire",
      "Wyre",
    ],
    Leicestershire: [
      "Blaby",
      "Charnwood",
      "Harborough",
      "Hinckley and Bosworth",
      "Leicester",
      "Melton",
      "North West Leicestershire",
      "Oadby and Wigston",
    ],
    Lincolnshire: [
      "Boston",
      "Lincoln",
      "Lindsey Este",
      "Lindsey Oeste",
      "North East Lincolnshire",
      "North Kesteven",
      "North Lincolnshire",
      "South Holland",
      "South Kesteven",
    ],
    Merseyside: ["Knowsley", "Liverpool", "Sefton", "St.Helens", "Wirral"],
    Norfolk: [
      "Breckland",
      "Broadland",
      "Great Yarmouth",
      "King's Lynn and West Norfolk",
      "North Norfolk",
      "Norwich",
      "South Norfolk",
    ],
    Northamptonshire: [
      "Corby",
      "Daventry",
      "East Northamptonshire",
      "Kettering",
      "Northampton",
      "South Northamptonshire",
      "Wellingborough",
    ],
    Northumberland: [
      "Alnwick",
      "Berwick",
      "Blyth",
      "Corbridge",
      "Embleton",
      "Hexham",
      "Morpeth",
      "Warkworth",
    ],
    Nottinghamshire: [
      "Ashfield",
      "Bassetlaw",
      "Broxtowe",
      "Gedling",
      "Mansfield",
      "Newark and Sherwood",
      "Nottingham (Unitario)",
      "Rushcliffe",
    ],
    Oxfordshire: [
      "Cherwell",
      "Oxford",
      "South Oxfordshire",
      "Vale of White Horse",
      "West Oxfordshire",
    ],
    Rutland: ["Oakham", "Uppingham"],
    Shropshire: ["Telford", "Wrekin"],
    Somerset: [
      "Bath and North East Somerset",
      "Mendip",
      "North Somerset",
      "Sedgemoor",
      "South Somerset",
      "Taunton Deane",
      "West Somerset",
    ],
    Staffordshire: [
      "Cannock Chase",
      "East Staffordshire",
      "Lichfield",
      "Newcastle-under-Lyme",
      "South Staffordshire",
      "Staffordshire Moorlands",
      "Stoke-on-Trent",
      "Tamworth",
    ],
    Suffolk: [
      "Babergh",
      "Bury St Edmunds",
      "Forest Heath",
      "Ipswich",
      "Mid Suffolk",
      "Suffolk Coastal",
      "Waveney",
    ],
    Surrey: [
      "Elmbridge",
      "Epsom and Ewell",
      "Guildford",
      "Mole Valley",
      "Reigate and Banstead",
      "Runnymede",
      "Spelthorne",
      "Surrey Heath",
      "Tandridge",
      "Waverley",
      "Woking",
    ],
    "Sussex del Este": [
      "Brighton y Hove",
      "Eastbourne",
      "Hastings",
      "Lewes",
      "Rother",
      "Wealden",
    ],
    "Sussex del Oeste": [
      "Adur",
      "Arun",
      "Chichester",
      "Crawley",
      "Horsham",
      "Mid Sussex",
      "Worthing",
    ],
    "Tierras Medias Occidentales": [
      "Birmingham",
      "Coventry",
      "Dudley",
      "Sandwell",
      "Soilhull",
      "Walsall",
      "Wolverhampton",
    ],
    "Tyne y Wear": [
      "Gateshead",
      "Newcastle upon Tyne",
      "North Tyneside",
      "South Tyneside",
      "Sunderland",
    ],
    Warwickshire: [
      "North Warwickshire",
      "Nuneaton and Bedworth",
      "Rugby",
      "Stratford-on-Avon",
      "Warwick",
    ],
    Wiltshire: ["Consejo de Wiltshire", "Swindon"],
    Worcestershire: [
      "Bromsgrove",
      "Malvern Hills",
      "Redditch",
      "Worcester",
      "Wychavon",
      "Wyre Forest",
    ],
    "Yorkshire del Este": ["East Riding of Yorkshire", "Kingston upon Hull"],
    "Yorkshire del Norte": [
      "Craven",
      "Hambleton",
      "Harrogate",
      "Middlesbrough",
      "Redcar and Cleveland",
      "Richmondshire",
      "Ryedale",
      "Scarborough",
      "Selby",
      "Stockton-on-Tees ",
      "York",
    ],
    "Yorkshire del Oeste": [
      "Bradford",
      "Calderdale",
      "Kirklees",
      "Leeds",
      "Wakefield",
    ],
    "Yorkshire del Sur": ["Barnsley.", "Doncaster", "Rotherham", "Sheffield"],
  },
  "Irlanda/ireland": {
    Úlster: [
      "Antrim ",
      "Armagh",
      "Cavan",
      "Derry/Londonderry",
      "Donegal",
      "Down",
      "Fermanagh",
      "Monaghan",
      "Tyrone",
    ],
    Connacht: ["Galway", "Leitrim", "Mayo", "Roscommon", "Sligo"],
    Munster: ["Clare", "Cork", "Kerry", "Limerick", "Tipperary", "Waterford"],
    Leinster: [
      "Carlow",
      "Dublín",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Longford",
      "Louth",
      "Meath",
      "Offaly",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
  },
  "Iraq / Irak": {
    Bagdad: [
      "Adhamiya",
      "Karkh",
      "Kadhimiyah",
      "Mansour",
      "Rusafa",
      "Sadr City",
      "Al-Rashid",
      "Al-Mada'in",
      "Tarmiya",
    ],
    Saladino: [
      "Tikrit",
      "Baiji",
      "Balad",
      "Samarra",
      "Ad-Dujail",
      "Al-Dour",
      "Yethrib",
      "Al Sherqat",
      "Touz",
      "Al Ishaqi",
      "Amirli",
      "Al Seniyah",
      "Al Dhuluiya",
      "Sa'ad",
      "Al Faris",
    ],
    Diala: [
      "Al Khalis",
      "Al Mokdadia",
      "Baquba",
      "Baladruz",
      "Khanaqin",
      "Kifri",
    ],
    Wasit: [
      "Al-Kut",
      "Al-Hai",
      "Al-Aziziyah",
      "Badra",
      "Kut Al-Imara",
      "Numaniya",
    ],
    Maysan: [
      "Amarah",
      "Ali Al-Gharbi",
      "Ali Al-Sharqi",
      "Al-Kahla",
      "Al-Maimouna",
    ],
    Basora: [
      "Basora",
      "Al-Zubair",
      "Abu Al-Khaseeb",
      "Al-Qurna",
      "Shatt Al-Arab",
    ],
    "Dhi Qar": [
      "Nasiriya",
      "Al-Rifai",
      "Al-Chibayish",
      "Shatrah",
      "Ghammas",
      "Suq Al-Shuyoukh",
    ],
    Muthanna: ["Samawa", "Al-Rumaitha", "Al-Karama"],
    Qadisiyah: [
      "Al-Diwaniyah",
      "Al-Gharraf",
      "Al-Hamza",
      "Al-Musayab",
      "Al-Shamiya",
    ],
    Babilonia: [
      "Al-Hilla",
      "Al-Mahawil",
      "Al-Musayab",
      "Hashimiya",
      "Al-Qasim",
      "Al-Iskandariya",
      "Al-Madain",
      "Al-Hamza",
      "Al-Hashim",
      "Al-Imam",
      "Al-Madrassa",
      "Al-Qadisiya",
    ],
    Kerbala: ["Ain at-Tamur", "al-Hindiyya", "Kerbala"],
    Nayaf: [
      "Kufa",
      "Al-Manathera",
      "Al-Meshkhab",
      "Al-Haidariya",
      "Al-Qadisiya",
      "Al-Furat",
      "Al-Sadda",
      "Al-Maamoon",
      "Al-Musaib",
      "Al-Mahdiya",
      "Al-Nibras",
      "Al-Sharqiya",
      "Al-Ashraf",
      "Al-Zahraa",
      "Al-Najaf Al-Jadida",
    ],
    Ambar: [
      "Abu Ghurayb",
      "Akashat",
      "Al Qaim",
      "Al Yazira",
      "An Nukhayb",
      "Ramadi",
      "Habbaniyah",
      "Arab Husain",
      "As Salmaniyat",
      "Hadithah ",
      "Hatim",
      "Khais",
      "Faluya",
      "Rummana",
      "Shayk abd al Jabbar",
    ],
    Nínive: [
      "Mosul",
      "Tel Afar",
      "Sinjar",
      "Bashiqa",
      "Al-Hamdaniya",
      "Tilkaif",
      "Al-Baaj",
      "Al-Shikhan",
      "Al-Hadar",
    ],
    Duhok: ["Duhok", "Zakho", "Sumel", "Amedi"],
    Erbil: [
      "Erbil",
      "Soran",
      "Koy Sanjaq",
      "Makhmur",
      "Shaqlawa",
      "Rawanduz",
      "Harir",
      "Choman",
      "Zakho",
      "Hajiawa",
    ],
    Kirkuk: ["Kirkuk", "Altun Kupri", "Dibis", "Hawija"],
    Sulaymaniyah: ["Sulaymaniyah", "Halabja", "Darbandikhan", "Rania"],
  },
  "Islas Malvinas": {
    "Malvinas/Falkland": [
      "Puerto Argentino/Stanley",
      "San Carlos",
      "Darwin",
      "Lafonia",
      "Monte Agradable",
      "Fox Bay",
      "Goose Green",
      "Port Howard",
      "Port San Carlos",
      "Puerto Soledad",
      "Saunders Island",
      "West Point Island",
      "Carcass Island",
      "Weddell Island",
      "New Island",
      "Fitzroy",
      "Mare Harbour",
      "Chartres",
      "Salvador",
      "Ajax Bay",
      "Hill Cove",
      "Walker Creek",
    ],
  },
  Israel: {
    "Distrito Norte (HaZafon)": [
      "Acre",
      "Acre",
      "Nahariya",
      "Shlomi",
      "Karmiel",
      "Ma'alot-Tarshiha",
      "Afula",
      "Yarka",
      "Kabul",
      "Shefa-'Amr",
      "Kaukab Abu al-Hija",
      "Majd al-Krum",
      "Deir al-Asad",
      "Tamra",
      "Tiberíades ",
      "Kineret",
      "Tiberíades",
      "Migdal",
      "Ramot",
      "Gadot",
      "Ein Gev",
      "Had Nes",
      "Kinneret",
      "Moshavat Kinneret",
      "Baja Galilea",
      "Nazaret",
      "Caná",
      "Tzfat (Safed)",
      "Kfar Tavor",
      "Kafr Kanna",
      "Afula ",
      "Migdal HaEmek",
      "Iksal",
      "Kfar Kama",
      "Reineh",
      "Tur'an",
      "Yafia",
      "Mughar",
      "Daburiyya",
      "Maghar",
      "Alta Galilea",
      "Kiryat Shmona",
      "Metula",
      "Ma'ayan Baruch",
      "Hatzor HaGlilit",
      "Yesod HaMa'ala",
      "Majdal Shams",
      "Buq'ata",
      "Ein Qiniyye",
      "She'ar Yashuv",
      "Avivim",
      "Margaliyyot",
      "Sde Nehemia",
    ],
    "Distrito de Haifa (Hefa)": [
      "Haifa",
      "Haifa",
      "Nesher",
      "Tirat Carmel",
      "Kiryat Tiv'on",
      "Or Akiva",
      "Pardes Hanna-Karkur",
      "Zikhron Ya'akov",
      "Binyamina-Giv'at Ada",
      "Atlit",
      "Hof HaCarmel",
      "Yokneam Illit",
      "Balfouria",
      "Fureidis",
      "Jisr az-Zarqa",
      "Ein Hod",
      "Nir Etzion",
      "Hadera",
      "Hadera",
      "Binyamina-Giv'at Ada",
      "Caesarea",
      "Zichron Ya'akov",
      "Or Akiva",
      "Pardes Hanna-Karkur",
      "Avihayil",
      "Fureidis",
      "Ein Vered",
      "Beit Hanania",
      "Beit Herut",
      "Geva Carmel",
      "Neve Yam",
      "Talmei Elazar",
      "Kfar Vitkin",
      "Afula",
      "Afula",
      "Migdal HaEmek",
      "Yokneam Illit",
      "Nazaret Illit",
      "Kfar Tavor",
      "Ramat Yishai",
      "Beit She'an",
      "Ma'ale Gilboa",
      "Migdal HaEmek",
      "Ilaniya",
      "Givat Ela",
    ],
    "Distrito Central (HaMerkaz)": [
      "Sharon",
      "Hod HaSharon",
      "Kfar Saba",
      "Ra'anana",
      "Herzliya",
      "Ramat HaSharon",
      "Herzliya Pituah",
      "Bnei Atarot",
      "Glil Yam",
      "Yakum",
      "Arsuf",
      "Rishpon",
      "Even Yehuda",
      "Kadima-Tzoran",
      "Ein Vered",
      "Kochav Yair",
      "Tel Mond",
      "Petah Tikva",
      "Petah Tikva",
      "Bnei Brak",
      "Kiryat Ono",
      "Giv'at Shmuel",
      "Ramat Gan",
      "Ganei Tikva",
      "El'ad",
      "Or Yehuda",
      "Yehud-Monosson",
      "Savyon",
      "Kfar Sirkin",
      "Beit Dagan",
      "Ramla",
      "Ramla",
      "Lod",
      "Kiryat Ekron",
      "Mazkeret Batya",
      "Ness Ziona",
      "Yavne",
      "Modi'in-Maccabim-Re'ut",
      "Shoham",
      "Beit Shemesh",
      "Nesher HaGadol",
      "Rehovot",
      "Rehovot",
      "Ness Ziona",
      "Yavne",
      "Ashdod",
      "Kiryat Malakhi",
      "Ashkelon",
      "Gedera",
      "Beit Oved",
      "Bnei Aish",
    ],
    " Tel Aviv (Gush Dan)": [
      "Tel Aviv",
      "Tel Aviv-Yafo",
      "Ramat Gan",
      "Giv'atayim",
      "Bnei Brak",
      "Bat Yam",
      "Holon",
      "Azor",
      "Givat Shmuel",
      "Kiryat Ono",
      "Kiryat Ata",
      "Kiryat Haim",
      "Kiryat Yam",
      "Or Akiva",
      "Shfayim",
      "Hasharon",
      "Herzliya",
      "Kfar Saba",
      "Ra'anana",
      "Hod HaSharon",
      "Ramat HaSharon",
      "Even Yehuda",
      "Kadima-Tzoran",
      "Arsuf",
      "Glil Yam",
      "Yakum",
      "Rishpon",
      "Kochav Yair",
      "Tel Mond",
    ],
    "Jerusalén (Yerushalayim)": [
      "Jerusalén",
      "Jerusalén",
      "Abu Ghosh",
      "Mevaseret Zion",
      "Tzur Hadassah",
      "Beit Zayit",
      "Ein Rafa",
      "Ora",
      "Sho'eva",
      "Aminadav",
      "Bar Giora",
      "Beit Hakerem",
      "Beit HaArava",
      "Givat Shaul",
      "Givat Ze'ev",
      "Har Adar",
      "Kiryat Anavim",
      "Ma'ale Adumim",
      "Mateh Yehuda",
      "Mevasseret Zion",
      "Neve Ilan",
      "Ora",
      "Sho'eva",
      "Yad Hashmona",
      " Mateh Yehuda",
      "Beit Shemesh",
      "Yehud-Monosson",
      "Abu Ghosh",
      "Mevaseret Zion",
      "Tzur Hadassah",
      "Beit Zayit",
      "Sho'eva",
      "Nes Harim",
      "Shoresh",
      "Sho'eva",
      "Beit Meir",
      "Givat Ye'arim",
      "Har Adar",
      "Kiryat Anavim",
      "Neve Ilan",
      "Ora",
      "Yad Hashmona",
      " Jerusalén Sur",
      "Betar Illit",
      "Efrat",
      "Gush Etzion",
      "Kiryat Arba",
      "Ma'ale Adumim",
      "Mateh Binyamin",
      "Modi'in Illit",
    ],
    "Distrito Sur (HaDarom)": [
      " Ashkelon",
      "Ashkelon",
      "Ashdod",
      "Kiryat Gat",
      "Kiryat Malakhi",
      "Gan Yavne",
      "Lachish",
      "Yoav",
      "Bnei Ayish",
      "Beit Kama",
      "Kfar Silver",
      "Kfar Warburg",
      "Lehavim",
      "Mishmar HaNegev",
      "Nevatim",
      "Netivot",
      "Ofaqim",
      "Sderot",
      "Sedot Yam",
      "Sha'ar HaNegev",
      "Sufa",
      "Yad Mordechai",
      " Be'er Sheva",
      "Be'er Sheva",
      "Arad",
      "Dimona",
      "Netivot",
      "Ofakim",
      "Sderot",
      "Ashdod",
      "Ashkelon",
      "Ashkelon Industrial Zone",
      "Avdat",
      "Aviezer",
      "Ashalim",
      "Be'er Milka",
      "Ezuz",
      "Giv'ot Bar",
      "Givot Olam",
      "Hatzeva",
      "Idan",
      "Kadesh Barnea",
      "Mashabei Sadeh",
      "Mitzpe Ramon",
      "Nitzana",
      "Paran",
      "Revivim",
      "Retamim",
      "Shizzafon",
      "Sde Boker",
      "Sde Yoav",
      "Tlalim",
      "Tlalim Industrial Zone",
      "Tsofar",
      "Yeroham",
    ],
    Cisjordania: [
      "Jenin",
      "Tulkarem",
      "Nablus",
      "Qalqilya",
      "Salfit",
      "Ramallah y Al-Bireh",
      "Jericó",
      "Jerusalén (este)",
      "Bethlehem",
      "Hebrón",
      "Tubas",
    ],
    Gaza: ["Gaza", "Deir al-Balah", "Al-Nusayrat"],
    "Jan Yunis": ["Jan Yunis", "Khan Yunis"],
  },
  Italia: {
    Abruzos: ["Chieti", "L'Aquila", "Pescara", "Teramo"],
    Apulia: [
      "Bari",
      "Barletta-Andria-Trani",
      "Brindisi",
      "Foggia",
      "Lecce",
      "Tarento",
    ],
    Basilicata: ["Matera", "Potenza"],
    Calabria: [
      "Catanzaro",
      "Cosenza",
      "Crotona",
      "Regio de Calabria",
      "Vibo Valentia",
    ],
    Campania: ["Avellino", "Benevento", "Caserta", "Nápoles", "Salerno"],
    Cerdeña: ["Cagliari", "Cerdeña del Sur", "Nuoro", "Oristán", "Sácer"],
    "Emilia-Romaña": [
      "Bolonia",
      "Ferrara",
      "Forlì-Cesena",
      "Módena",
      "Parma",
      "Plasencia",
      "Rávena",
      "Reggio Emilia",
      "Rímini",
    ],
    "Friuli-Venecia Julia": ["Gorizia", "Pordenone", "Trieste", "Udine"],
    Lacio: ["Frosinone", "Latina", "Rieti", "Roma", "Viterbo"],
    Liguria: ["Génova", "Imperia", "La Spezia", "Savona"],
    Lombardía: [
      "Bérgamo",
      "Brescia",
      "Como",
      "Cremona",
      "Lecco",
      "Lodi",
      "Mantua",
      "Milán",
      "Monza y Brianza",
      "Pavía",
      "Sondrio",
      "Varese",
    ],
    Marcas: ["Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro y Urbino"],
    Molise: ["Campobasso", "Isernia"],
    Piamonte: [
      "Alessandria",
      "Asti",
      "Biella",
      "Cuneo",
      "Novara",
      "Turín",
      "Verbano-Cusio-Ossola",
      "Vercelli",
    ],
    Sicilia: [
      "Agrigento",
      "Caltanissetta",
      "Catania",
      "Enna",
      "Mesina",
      "Palermo",
      "Ragusa",
      "Siracusa",
      "Trapani",
    ],
    Toscana: [
      "Arezzo",
      "Florencia",
      "Grosseto",
      "Livorno",
      "Lucca",
      "Massa y Carrara",
      "Pisa",
      "Pistoya",
      "Prato",
      "Siena",
    ],
    "Trentino-Alto Adigio": ["Bolzano", "Trento"],
    Umbría: ["Perusa", "Terni"],
    "Valle de Aosta": ["Valle de Aosta"],
    Véneto: [
      "Belluno",
      "Padua",
      "Rovigo",
      "Treviso",
      "Venecia",
      "Verona",
      "Vicenza",
    ],
  },
  "Japón / Nippon": {
    Chūbu: [
      "Aichi",
      "Fukui",
      "Gifu",
      "Ishikawa",
      "Nagano",
      "Niigata",
      "Shizuoka",
      "Toyama",
      "Yamanashi",
    ],
    Chūgoku: ["Hiroshima", "Okayama", "Shimane", "Tottori", "Yamaguchi"],
    Hokkaidō: ["Hokkaidō"],
    Kantō: [
      "Chiba",
      "Gunma",
      "Ibaraki",
      "Kanagawa",
      "Saitama",
      "Tochigi",
      "Tokio",
    ],
    Kinki: ["Hyōgo", "Kioto", "Mie", "Nara", "Osaka", "Shiga", "Wakayama"],
    Kyūshū: [
      "Fukuoka",
      "Kagoshima",
      "Kumamoto",
      "Miyazaki",
      "Nagasaki",
      "Ōita",
      "Okinawa",
      "Saga",
    ],
    Shikoku: ["Ehime", "Kagawa", "Kōchi", "Tokushima"],
    Tōhoku: ["Akita", "Aomori", "Fukushima", "Iwate", "Miyagi", "Yamagata"],
  },
  Kenia: {
    "Región Central": ["Nyandarua", "Nyeri", "Kirinyaga", "Murang'a", "Kiambu"],
    "Región Costera": [
      "Mombasa",
      "Kwale",
      "Kilifi",
      "Tana River",
      "Lamu",
      "Taita-Taveta",
    ],
    "Región Oriental": ["Embu", "Meru", "Tharaka-Nithi"],
    "Región del Norte": ["Marsabit", "Isiolo", "Mandera", "Wajir", "Garissa"],
    "Región Occidental": ["Kakamega", "Vihiga", "Bungoma", "Busia"],
    "Región de Nyanza": [
      "Kisumu",
      "Homa Bay",
      "Migori",
      "Kisii",
      "Nyamira",
      "Siaya",
    ],
    "Región del Rift Valley": [
      "Turkana",
      "West Pokot",
      "Samburu",
      "Trans-Nzoia",
      "Uasin Gishu",
      "Elgeyo-Marakwet",
      "Nandi",
      "Baringo",
      "Laikipia",
      "Nakuru",
      "Narok",
      "Kajiado",
      "Kericho",
      "Bomet",
    ],
    "Región de Nairobi": ["Nairobi"],
    "Región del Este": ["Machakos", "Kitui", "Makueni"],
  },
  Laos: {
    Attapeu: [
      "Sanamxay",
      "Ban Hinlath",
      "Ban Houaylao",
      "Ban Taoy",
      "Ban Namkoy",
      "Ban Houaytam",
      "Ban Phonamphon",
      "Ban Laoxin",
      "Ban Samong",
      "Ban Konam",
      "Ban Nongxong",
    ],
    Phouvong: [
      "Ban Nongkouay",
      "Ban Nongnokkhane",
      "Ban Nalae",
      "Ban Namoung",
      "Ban Khounkham",
      "Ban Houayhoung",
      "Ban Huaykhi",
      "Ban Lao-Ngarm",
      "Ban Pha-Oudom",
      "Ban Sopkhong",
    ],
    Sanxay: [
      "Ban Angkhang",
      "Ban Angnam",
      "Ban Bon",
      "Ban Chasavanh",
      "Ban Chasoung",
      "Ban Daklao",
      "Ban Doungphay",
      "Ban Houayhong",
      "Ban Houaynong",
      "Ban Houayphay",
      "Ban Houaythong",
      "Ban Houayyap",
      "Ban Huayphatthana",
      "Ban Kengkham",
      "Ban Kengngeun",
      "Ban Kengsavanh",
      "Ban Khoktan",
      "Ban Kian",
      "Ban Lakhone",
      "Ban Lakhonesy",
      "Ban Lame",
      "Ban Namheuang",
      "Ban Naphanxay",
      "Ban Nathom",
      "Ban Nikhom",
      "Ban Phalang",
      "Ban Phanhin",
      "Ban Phonsa-at",
      "Ban Phonthong",
      "Ban Saiseesouk",
      "Ban Sanamxay",
      "Ban Saphai",
      "Ban Simouang",
      "Ban Souangphoung",
      "Ban Takham",
      "Ban Thakha",
      "Ban Thalang",
      "Ban Thalangkouang",
      "Ban Thamphan",
      "Ban Thongtou",
      "Ban Viengkeo",
    ],
    Samakkhixay: [
      "Ban Angkham",
      "Ban Baket",
      "Ban Bouchang",
      "Ban Dakdao",
      "Ban Daknong",
      "Ban Doung",
      "Ban Houayhoung",
      "Ban Houaymak",
      "Ban Houaynamy",
      "Ban Houaypong",
      "Ban Houaysa",
      "Ban Houaytang",
      "Ban Houayyot",
      "Ban Kengkham",
      "Ban Kengsai",
      "Ban Kengtong",
      "Ban Khokhoy",
      "Ban Khoksomboon",
      "Ban Kiat",
      "Ban Kian",
      "Phin",
      "Phouvong",
    ],
    Bokeo: ["Houayxay", "Tonpheung", "Meung", "Paktha", "Ngeun"],
    Bolikhamxay: ["Pakxanh", "Khamkeuth", "Bolikhan", "Thaphabath", "Paksane"],
    Champasak: [
      "Champasak",
      "Mounlapamok",
      "Pakxe",
      "Pathoumphone",
      "Sanasomboun",
    ],
    Houaphanh: ["Xamneua", "Viengthong", "Huameuang", "Xamneua", "Kua"],
    Khammouane: ["Nakai", "Thakhek", "Xaybuathong", "Hinboun", "Mahaxay"],
    Louangnamtha: ["Louangnamtha", "Sing", "Viengphoukha", "Namtha", "Nalae"],
    Louangphabang: ["Louangphabang", "Phonxay", "Pakou", "Nan", "Nambak"],
    Oudomxay: ["Oudomxay", "Nga", "Xay", "Beng", "Houn"],
    Phongsaly: ["Phongsaly", "Bounneua", "Gnot-Ou", "Samphanh", "Mai"],
    Salavan: ["Salavan", "Lao-ngam", "Lakhonepheng", "Toumlan", "Ta-oy"],
    Savannakhet: ["Sepon", "Vilabouly", "Champhone", "Xepon", "Thapangthong"],
    Vientiane: [
      "Xaythany",
      "Sisattanak",
      "Chanthabouly",
      "Sikhottabong",
      "Hadxaifong",
    ],
    "Vientiane Capital": [
      "Xaysettha",
      "Sikhottabong",
      "Chanthabouly",
      "Sisattanak",
      "Naxaithong",
    ],
    Xaignabouli: ["Xaignabouli", "Thongmixay", "Boten", "Pha-Oudom", "Paktha"],
    Xekong: ["Dakcheung", "Lamarm", "Thateng", "Kaleum", "Ta-oy"],
    Xiangkhouang: ["Pek", "Phaxay", "Kham", "Nonghet", "Khoune"],
  },
  Letonia: {
    Aizkraukle: ["Aizkraukle ", "Aizkraukles pagasts"],
    Alūksne: [
      "Alsviķu ",
      "Alūksne ",
      "Annas ",
      "Ilzenes ",
      "Jaunalūksnes ",
      "Jaunannas ",
      "Jaunlaicenes ",
      "Kalncempju ",
      "Liepnas ",
      "Malienas ",
      "Mālupes ",
      "Mārkalnes ",
      "Pededzes",
      "Veclaicenes ",
      "Zeltiņu ",
      "Ziemera ",
    ],
    Balvi: [
      "Balvi ",
      "Balvu ",
      "Bērzkalnes ",
      "Bērzpils ",
      "Briežuciema ",
      "Krišjāņu ",
      "Kubuļu ",
      "Lazdulejas ",
      "Tilžas ",
      "Vectilžas ",
      "Vīksnas ",
    ],
    Bauska: [
      "Bauska ",
      "Brunavas ",
      "Ceraukstes ",
      "Codes ",
      "Dāviņu ",
      "Gailīšu ",
      "Īslīces ",
      "Mežotnes ",
      "Vecsaules ",
    ],
    Cēsis: [" Cēsis ", " Vaives "],
    Dobele: [
      "Annenieku ",
      "Auru ",
      "Bērzes ",
      "Bikstu ",
      "Dobeles ",
      "Dobele",
      "Jaunbērzes ",
      "Krimūnu ",
      "Naudītes ",
      "Penkules ",
      "Zebrenes ",
    ],
    Gulbene: [
      "Beļavas ",
      "Daukstu ",
      "Druvienas ",
      "Galgauskas ",
      "Gulbene ",
      "Jaungulbenes ",
      "Lejasciema ",
      "Litenes ",
      "Lizuma ",
      "Līgo ",
      "Rankas ",
      "Stāmerienas ",
      "Stradu ",
      "Tirzas ",
    ],
    Jelgava: [
      "Elejas ",
      "Glūdas ",
      "Jaunsvirlaukas ",
      "Kalnciems ",
      "Lielplatones ",
      "Līvbērzes ",
      "Platones ",
      "Sesavas ",
      "Svētes ",
      "Valgundes ",
      "Vilces ",
      "Vircavas ",
      "Zaļenieku ",
    ],
    Jēkabpils: [
      "Ābeļu ",
      "Dignājas ",
      "Dunavas ",
      "Kalna ",
      "Leimaņu ",
      "Rubenes ",
      "Zasas ",
    ],
    Ķekava: ["Baloži ", "Daugmales ", "Ķekavas "],
    Krāslava: [
      "Aulejas ",
      "Indras ",
      "Izvaltas ",
      "Kalniešu ",
      "Kaplavas ",
      "Krāslava ",
      "Kombuļu ",
      "Krāslavas ",
      "Piedrujas ",
      "Robežnieku ",
      "Skaistas ",
      "Ūdrīšu ",
    ],
    Kuldīga: [
      "Ēdoles ",
      "Gudenieku ",
      "Īvandes ",
      "Kabiles ",
      "Kuldīga ",
      "Kurmāles ",
      "Laidu ",
      "Padures ",
      "Pelču ",
      "Rendas ",
      "Rumbas ",
      "Snēpeles ",
      "Turlavas ",
      "Vārmes ",
    ],
    Limbaži: [
      "Limbaži ",
      "Katvaru ",
      "Limbažu ",
      "Pāles ",
      "Skultes ",
      "Umurgas ",
      "Vidrižu ",
      "Viļķenes",
    ],
    Ludza: [
      "Ludza ",
      "Briģu ",
      "Cirmas ",
      "Isnaudas ",
      "Istras ",
      "Nirzas ",
      "Ņukšu ",
      "Pildas ",
      "Pureņu ",
      "Rundēnu ",
    ],
    Madona: [
      "Madona",
      "Aronas ",
      "Barkavas ",
      "Bērzaunes ",
      "Dzelzavas ",
      "Kalsnavas ",
      "Lazdonas ",
      "Liezēres ",
      "Ļaudonas ",
      "Mārcienas ",
      "Mētrienas ",
      "Ošupes ",
      "Prauliena",
      "Sarkaņu ",
      "Vestienas ",
    ],
    Mārupe: ["Jaunmārupe ", "Mārupe ", "Skulte ", "Tīraine ", "Mārupes "],
    Ogre: [
      "Ogre ",
      "Krapes ",
      "Ķeipenes ",
      "Lauberes ",
      "Madlienas ",
      "Mazozolu ",
      "Meņģeles ",
      "Ogresgala ",
      "Suntažu ",
      "Taurupes ",
    ],
    Olaine: ["Olaine ", "Olaines"],
    Preiļi: [
      "Preiļi ",
      "Aizkalnes ",
      "Pelēču ",
      "Preiļu ",
      "Preiļu ",
      "Saunas ",
    ],
    Rēzekne: [
      "Audriņu ",
      "Bērzgales ",
      "Čornajas ",
      "Dricānu ",
      "Feimaņu ",
      "Gaigalavas ",
      "Griškānu ",
      "Ilzeskalna ",
      "Kantinieku ",
      "Kaunatas ",
      "Lendžu ",
      "Lūznavas ",
      "Mākoņkalna ",
      "Maltas ",
      "Nagļu ",
      "Nautrēnu ",
      "Ozolaines ",
      "Ozolmuižas ",
      "Pušas ",
      "Rikavas ",
      "Sakstagala ",
      "Silmalas",
      "Stoļerovas ",
      "Stružānu ",
      "Vērēmu ",
    ],
    Ropaži: ["Ropažu pagasts "],
    Salaspils: ["Salaspils "],
    Saldus: [
      "Ezeres ",
      "Jaunauces ",
      "Jaunlutriņu ",
      "Kursīšu ",
      "Lutriņu ",
      "Nīgrandes ",
      "Novadnieku ",
      "Pampāļu ",
      "Rubas ",
      "Saldus ",
      "Saldus ",
      "Šķēdes ",
      "Vadakstes ",
      "Zaņas ",
      "Zirņu ",
      "Zvārdes ",
    ],
    Saulkrasti: ["Saulkrasti"],
    Sigulda: ["Allažu ", "Mores ", "Sigulda", "Siguldas "],
    Smiltene: [
      "Bilskas ",
      "Blomes ",
      "Brantu ",
      "Grundzāles ",
      "Launkalnes ",
      "Palsmanes ",
      "Smiltene",
      "Smiltenes ",
      "Variņu ",
    ],
    Talsi: [
      "Abavas ",
      "Ārlavas ",
      "Balgales ",
      "Ģibuļu ",
      "Īves ",
      "Ķūļciema ",
      "Laidzes ",
      "Laucienes",
      "Lībagu ",
      "Lubes ",
      "Sabile ",
      "Stende ",
      "Strazdes ",
      "Talsi ",
      "Valdemārpils ",
      "Valdgales ",
      "Vandzenes ",
      "Virbu ",
    ],
    Tukums: [
      "Cēre",
      "Degole",
      "Džūkste",
      "Engure",
      "Irlava",
      "Jaunpils",
      "Jaunsāti",
      "Kandava",
      "Lapmežciems",
      "Lestene",
      "Matkule",
      "Pūre",
      "Sēme",
      "Slampe",
      "Smārde",
      "Tume",
      "Vāne",
      "Viesati",
      "Zante",
      "Zemīte",
      "Zentene",
    ],
    Valka: [
      "Ērģemes ",
      "Kārķu ",
      "Valka",
      "Valkas ",
      "Vijciema ",
      "Zvārtavas ",
    ],
    Valmiera: ["Bērzaines ", "Dikļu ", "Kocēnu ", "Vaidavas ", "Zilākalna "],
    Varakļāni: ["Murmastienes ", "Varakļāni ", "Varakļānu "],
    Ventspils: [
      "Ances ",
      "Jūrkalnes ",
      "Piltenes  ",
      "Popes ",
      "Puzes ",
      "Tārgales ",
      "Ugāles ",
      "Usmas ",
      "Užavas ",
      "Vārves ",
      "Ziru ",
      "Zlēku ",
    ],
    Vidzeme: [
      "Riga (Rīga)",
      "Valmiera",
      "Cesis",
      "Sigulda",
      "Limbazi",
      "Madona",
    ],
    Kurzeme: ["Liepaja (Liepāja)", "Ventspils", "Kuldiga", "Talsi", "Saldus"],
    Latgale: ["Daugavpils", "Rezekne", "Ludza", "Preili", "Kraslava"],
    Zemgale: ["Jelgava", "Jekabpils", "Bauska", "Dobele", "Ozolnieki"],
    Riga: ["Riga (Rīga)", "Jurmala", "Ogre", "Tukums", "Salaspils"],
  },
  Lituania: {
    Alytus: ["Alytus ", "Alytus ", "Druskininkai "],
    Kaunas: [
      "Kaunas",
      "Birštonas ",
      "Jonava ",
      "Kaunas ",
      "Kaišiadorys ",
      "Kėdainiai",
      "Prienai ",
      "Raseiniai ",
    ],
    Klaipėda: [
      "Klaipėda",
      "Klaipėda ",
      "Kretinga ",
      "Neringa ",
      "Palanga ",
      "Šilutė ",
      "Skuodas ",
    ],
    Marijampolė: [
      "Marijampolė ",
      "Kalvarija ",
      "Kazlų Rūda ",
      "Marijampolė ",
      "Šakiai ",
      "Vilkaviškis ",
    ],
    Panevėžys: [
      "Panevėžys ",
      "Biržai ",
      "Kupiškis ",
      "Panevėžys ",
      "Pasvalys ",
      "Rokiškis ",
    ],
    Šiauliai: [
      "Šiauliai ",
      "Joniškis ",
      "Kelmė ",
      "Pakruojis ",
      "Radviliškis",
      "Šiauliai ",
    ],
    Tauragė: ["Tauragė ", "Jurbarkas ", "Pagėgiai ", "Šilalė ", "Tauragė "],
    Telšiai: [
      "Telšiai ",
      "Mažeikiai ",
      "Plungė ",
      "Rietavas ",
      "Akmenė ",
      "Kretinga ",
      "Mažeikiai ",
      "Palanga ",
      "Skuodas ",
      "Telšiai ",
    ],
    Utena: ["Utena ", "Ignalina ", "Molėtai ", "Utena ", "Visaginas "],
    Vilnius: ["Vilnius ", "Elektrėnai ", "Šalčininkai ", "Vilnius"],
  },
  Libia: {
    "Al Butnan": ["Tobruk", "Derna", "Al-Jaghboub", "Tmassah"],
    "Al Jabal al Akhdar": [
      "Bayda",
      "Derna",
      "Shahat",
      "Tocra",
      "Abiar Al-Gharbiya",
      "Al-Qubah",
      "Hoon",
      "Ajdabiya",
    ],
    "Al Jfara": ["Aziziya", "Al-Ajaylat", "Zliten", "Mizdah"],
    "Al Jufra": ["Hun", "Waddan", "Sokna"],
    "Al Kufra": ["Al Jawf", "Tazirbu", "Kufra"],
    "Al Marj": ["Al Marj", "Zawyat Al-Jabal", "Al-Qubba"],
    "Al Marqab": ["Zliten", "Khoms", "Bani Walid", "Tarhuna"],
    "Al Wahat": ["Ajdabiya", "Abyar", "Jalu", "Kufra", "Tazirbu", "Zueitina"],
    "An Nuqat al Khams": ["Sabratha", "Surman", "Zuwarah", "Zawiya"],
    "Az Zawiyah": ["Zawiya", "Sabratha", "Al-Jumayl"],
    Benghazi: ["Benghazi", "Al Marj", "Ajdabiya", "Abyar"],
    Derna: ["Derna", "Shahat"],
    Ghat: ["Ghat", "Ubari"],
    Misratah: ["Misratah", "Khoms"],
    Murzuq: ["Murzuq", "Qatrun"],
    Nalut: ["Nalut", "Kabaw"],
    Sabha: ["Sabha", "Murzuq", "Wadi Al Hayaa"],
    Surt: ["Surt", "Al-Jufrah"],
    Tripoli: ["Tripoli", "Tajura", "Aziziya"],
    "Wadi al Hayaa": ["Sabha", "Murzuq", "Wadi Al Hayaa"],
    "Wadi al Shatii": ["Al Shatii", "Al Jufrah"],
    Zawiya: ["Zawiya", "Al-Jumayl"],
  },
  Malasia: {
    Johor: [
      "Batu Pahat",
      "Johor Bahru",
      "Kluang",
      "Kota Tinggi",
      "Kulai",
      "Mersing",
      "Muar",
      "Pontian",
      "Segamat",
      "Tangkak",
    ],
    Kedah: [
      "Baling",
      "Bandar Baharu",
      "Kota Setar",
      "Kubang Pasu",
      "Kulim",
      "Langkawi",
      "Padang Terap",
      "Pendang",
      "Pokok Sena",
      "Sik",
      "Yan",
    ],
    Kelantan: [
      "Bachok",
      "Gua Musang",
      "Jeli",
      "Kota Bharu",
      "Kuala Krai",
      "Machang",
      "Pasir Mas",
      "Pasir Puteh",
      "Tanah Merah",
      "Tumpat",
    ],
    Melaka: ["Alor Gajah", "Jasin", "Central Malacca"],
    "Negeri Sembilan": [
      "Jelebu",
      "Jempol",
      "Kuala Pilah",
      "Port Dickson",
      "Rembau",
      "Seremban",
      "Tampin",
    ],
    Pahang: [
      "Bentong",
      "Bera",
      "Cameron Highlands",
      "Jerantut",
      "Kuantan",
      "Lipis",
      "Maran",
      "Pekan",
      "Raub",
      "Rompin",
      "Temerloh",
    ],
    Perak: [
      "Batang Padang",
      "Hilir Perak",
      "Hulu Perak",
      "Kampar",
      "Kerian",
      "Kinta",
      "Kuala Kangsar",
      "Larut, Matang and Selama",
      "Manjung",
      "Muallim",
      "Perak Tengah",
    ],
    Sabah: [
      "Beaufort",
      "Beluran",
      "Donggongon",
      "Keningau",
      "Kinabatangan",
      "Kota Belud",
      "Kota Kinabalu",
      "Kota Marudu",
      "Kuala Penyu",
      "Kudat",
      "Kunak",
      "Lahad Datu",
      "Nabawan",
      "Papar",
      "Penampang",
      "Pitas",
      "Ranau",
      "Sandakan",
      "Semporna",
      "Sipitang",
      "Tambunan",
      "Tawau",
      "Tenom",
      "Tongod",
    ],
    Sarawak: [
      "Asajaya",
      "Bau",
      "Betong",
      "Bintulu",
      "Dalat",
      "Daro",
      "Julau",
      "Kanowit",
      "Kapit",
      "Kuching",
      "Lawas",
      "Limbang",
      "Lubok Antu",
      "Lundu",
      "Marudi",
      "Matu",
      "Miri",
      "Mukah",
      "Samarahan",
      "Saratok",
      "Sarikei",
      "Serian",
      "Sibu",
      "Simunjan",
      "Song",
      "Sri Aman",
      "Tatau",
    ],
    Selangor: [
      "Gombak",
      "Hulu Langat",
      "Hulu Selangor",
      "Klang",
      "Kuala Langat",
      "Kuala Selangor",
      "Petaling",
      "Sabak Bernam",
      "Sepang",
    ],
    Terengganu: [
      "Besut",
      "Dungun",
      "Hulu Terengganu",
      "Kemaman",
      "Kuala Nerus",
      "Kuala Terengganu",
      "Marang",
      "Setiu",
    ],
    Penang: [
      "Timur Laut",
      "Seberang Perai Utara",
      "Seberang Perai Tengah",
      "Seberang Perai Selatan",
    ],
    Perlis: ["Perlis Utara", "Perlis Tengah", "Perlis Selatan"],
  },
  Marruecos: {
    "Tánger/Tetuán/Alhucemas": [
      " Rincón-Castillejos",
      " Tánger-Arcila",
      " Alhucemas",
      " Chauen",
      " Fahs-Anyera",
      " Larache",
      " Tetuán",
      " Uezán",
    ],
    Oriental: [
      "Oujda-Angad",
      " Berkan",
      " Driuch",
      " Figuig",
      " Guercif",
      " Yerada",
      " Nador",
      " Taurirt",
    ],
    "Fez/Mequinez": [
      " El Hayeb",
      " Ifrane",
      " Sefrou",
      " Mulay Yacub",
      " Taza",
      " Taunat",
      " Bulmán",
      " Mequinez",
      " Fez",
    ],
    "Rabat-Salé-Kenitra": [
      "Rabat",
      "Salé",
      "Sjirat-Temara",
      " Kenitra",
      "Jemisset",
      " Sidi Kacem",
      " Sidi Slimane",
    ],
    "Beni Melal-Jenifra": [
      "  Azilal",
      "  Beni Melal",
      "  Fquih Ben Salah",
      "  Jenifra",
      "  Juribga",
    ],
    "Casablanca-Settat": [
      " Casablanca",
      " Mohammedía",
      " Benslimane",
      " Berrechid",
      " El Yadida",
      " Mediuna",
      " Nouaceur",
      " Settat",
      " Sidi Bennour",
    ],
    "Marrakech-Safí": [
      "Marrakech",
      " Al Hauz",
      " Chichaoua",
      " El Kelaa des Sraghna",
      " Esauira",
      " Rehamna",
      " Safí",
      " Youssoufia",
    ],
    "Draa-Tafilalet": [
      " Errachidía",
      " Midelt",
      " Tinerhir",
      " Uarzazat",
      " Zagora",
    ],
    "Sus-Masa": [
      "Agadir-Ida Ou Tanane",
      "Inezgane-Aït Melloul",
      " Chtouka-Aït Baha",
      " Tarudant",
      " Tata",
      " Tiznit",
    ],
    "Guelmim /Río Nun": [" Assa-Zag", " Guelmim", " Sidi Ifni", " Tan-Tan"],
    "El Aaiún-Saguía el-Hamra": [
      " Bojador",
      " Esmara",
      " El Aaiún",
      " Tarfaya",
    ],
    "Dajla-Río de Oro": ["de Auserd", "Río de Oro"],
  },
  México: {
    Aguascalientes: [
      "Aguascalientes",
      "Asientos",
      "Calvillo",
      "Cosío",
      "El Llano",
      "Jesús María",
      "Pabellón de Arteaga",
      "Rincón de Romos",
      "San Francisco de los Romo",
      "San José de Gracia",
      "Tepezalá",
    ],
    "Baja California": [
      "Ensenada",
      "Mexicali",
      "Playas de Rosarito",
      "Tecate",
      "Tijuana",
    ],
    "Baja California Sur": [
      "Comondú",
      "La Paz",
      "Loreto",
      "Los Cabos",
      "Mulegé",
    ],
    Campeche: [
      "Calakmul",
      "Calkiní",
      "Campeche",
      "Candelaria",
      "Carmen",
      "Champotón",
      "Escárcega",
      "Hecelchakán",
      "Hopelchén",
      "Palizada",
      "Tenabo",
    ],
    Chiapas: [
      "Acacoyagua",
      "Acala",
      "Acapetahua",
      "Aldama",
      "Altamirano",
      "Amatán",
      "Amatenango de la Frontera",
      "Amatenango del Valle",
      "Angel Albino Corzo",
      "Arriaga",
      "Bejucal de Ocampo",
      "Bella Vista",
      "Benemérito de las Américas",
      "Berriozábal",
      "Bochil",
      "Cacahoatán",
      "Catazajá",
      "Chalchihuitán",
      "Chamula",
      "Chanal",
      "Chapultenango",
      "Chenalhó",
      "Chiapa de Corzo",
      "Chiapilla",
      "Chicoasén",
      "Chicomuselo",
      "Chilón",
      "Cintalapa",
      "Coapilla",
      "Comitán de Domínguez",
      "Copainalá",
      "El Bosque",
      "El Porvenir",
      "Escuintla",
      "Francisco León",
      "Frontera Comalapa",
      "Frontera Hidalgo",
      "Huehuetán",
      "Huitiupán",
      "Huixtán",
      "Huixtla",
      "Ixhuatán",
      "Ixtacomitán",
      "Ixtapa",
      "Ixtapangajoya",
      "Jiquipilas",
      "Jitotol",
      "Juárez",
      "La Concordia",
      "La Grandeza",
      "La Independencia",
      "La Libertad",
      "La Trinitaria",
      "Larráinzar",
      "Las Margaritas",
      "Las Rosas",
      "Mapastepec",
      "Maravilla Tenejapa",
      "Marqués de Comillas",
      "Mazapa de Madero",
      "Mazatán",
      "Metapa",
      "Mitontic",
      "Montecristo de Guerrero",
      "Motozintla",
      "Nicolás Ruíz",
      "Ocosingo",
      "Ocotepec",
      "Ocozocoautla de Espinosa",
      "Ostuacán",
      "Osumacinta",
      "Oxchuc",
      "Palenque",
      "Pantelhó",
      "Pantepec",
      "Pichucalco",
      "Pijijiapan",
      "Pueblo Nuevo Solistahuacán",
      "Rayón",
      "Reforma",
      "Sabanilla",
      "Salto de Agua",
      "San Andrés Duraznal",
      "San Cristóbal de las Casas",
      "San Fernando",
      "San Juan Cancuc",
      "San Lucas",
      "Santiago el Pinar",
      "Siltepec",
      "Simojovel",
      "Sitalá",
      "Socoltenango",
      "Solosuchiapa",
      "Soyaló",
      "Suchiapa",
      "Suchiate",
      "Sunuapa",
      "Tapachula",
      "Tapalapa",
      "Tapilula",
      "Tecpatán",
      "Tenejapa",
      "Teopisca",
      "Tila",
      "Tonalá",
      "Totolapa",
      "Tumbalá",
      "Tuxtla Chico",
      "Tuxtla Gutiérrez",
      "Tuzantán",
      "Tzimol",
      "Unión Juárez",
      "Venustiano Carranza",
      "Villa Comaltitlán",
      "Villa Corzo",
      "Villaflores",
      "Yajalón",
      "Zinacantán",
    ],
    Chihuahua: [
      "Ahumada",
      "Aldama",
      "Allende",
      "Aquiles Serdán",
      "Ascensión",
      "Bachíniva",
      "Balleza",
      "Batopilas",
      "Bocoyna",
      "Buenaventura",
      "Camargo",
      "Carichí",
      "Casas Grandes",
      "Coronado",
      "Coyame del Sotol",
      "La Cruz",
      "Cuauhtémoc",
      "Cusihuiriachi",
      "Chihuahua",
      "Chínipas",
      "Delicias",
      "Dr  Belisario Domínguez",
      "Galeana",
      "Santa Isabel",
      "Gómez Farías",
      "Gran Morelos",
      "Guachochi",
      "Guadalupe",
      "Guadalupe y Calvo",
      "Guazapares",
      "Guerrero",
      "Hidalgo del Parral",
      "Huejotitán",
      "Ignacio Zaragoza",
      "Janos",
      "Jiménez",
      "Juárez",
      "Julimes",
      "López",
      "Madera",
      "Maguarichi",
      "Manuel Benavides",
      "Matachí",
      "Matamoros",
      "Meoqui",
      "Morelos",
      "Moris",
      "Namiquipa",
      "Nonoava",
      "Nuevo Casas Grandes",
      "Ocampo",
      "Ojinaga",
      "Praxedis G Guerrero",
      "Riva Palacio",
      "Rosales",
      "Rosario",
      "San Francisco de Borja",
      "San Francisco de Conchos",
      "San Francisco del Oro",
      "Santa Bárbara",
      "Satevó",
      "Saucillo",
      "Temósachic",
      "El Tule",
      "Urique",
      "Uruachi",
      "Valle de Zaragoza",
    ],
    "Ciudad de México": [
      "Álvaro Obregón",
      "Azcapotzalco",
      "Benito Juárez",
      "Coyoacán",
      "Cuajimalpa de Morelos",
      "Cuauhtémoc",
      "Gustavo A. Madero",
      "Iztacalco",
      "Iztapalapa",
      "La Magdalena Contreras",
      "Miguel Hidalgo",
      "Milpa Alta",
      "Tláhuac",
      "Tlalpan",
      "Venustiano Carranza",
      "Xochimilco",
    ],
    "Coahuila de Zaragoza": [
      "Abasolo",
      "Acuña",
      "Allende",
      "Arteaga",
      "Candela",
      "Castaños",
      "Cuatrociénegas",
      "Escobedo",
      "Francisco I. Madero",
      "Frontera",
      "General Cepeda",
      "Guerrero",
      "Hidalgo",
      "Jiménez",
      "Juárez",
      "Lamadrid",
      "Matamoros",
      "Monclova",
      "Morelos",
      "Múzquiz",
      "Nadadores",
      "Nava",
      "Ocampo",
      "Parras",
      "Piedras Negras",
      "Progreso",
      "Ramos Arizpe",
      "Sabinas",
      "Sacramento",
      "Saltillo",
      "San Buenaventura",
      "San Juan de Sabinas",
      "San Pedro",
      "Sierra Mojada",
      "Torreón  ",
      "Viesca",
      "Villa Unión",
      "Zaragoza",
    ],
    Colima: [
      "Armería",
      "Colima",
      "Comala",
      "Coquimatlán",
      "Cuauhtémoc",
      "Ixtlahuacán",
      "Manzanillo",
      "Minatitlán",
      "Tecomán",
      "Villa de Álvarez",
    ],
    Durango: [
      "Canatlán",
      "Canelas",
      "Coneto de Comonfort",
      "Cuencamé",
      "Durango",
      "General Simón Bolívar",
      "Gómez Palacio",
      "Guadalupe Victoria",
      "Guanaceví",
      "Hidalgo",
      "Indé",
      "Lerdo",
      "Mapimí",
      "Mezquital",
      "Nazas",
      "Nombre de Dios",
      "Ocampo",
      "El Oro",
      "Otáez",
      "Pánuco de Coronado",
      "Peñón Blanco",
      "Poanas",
      "Pueblo Nuevo",
      "Rodeo",
      "San Bernardo",
      "San Dimas",
      "San Juan de Guadalupe",
      "San Juan del Río",
      "San Luis del Cordero",
      "San Pedro del Gallo",
      "Santa Clara",
      "Santiago Papasquiaro",
      "Súchil",
      "Tamazula",
      "Tepehuanes",
      "Tlahualilo",
      "Topia",
      "Vicente Guerrero",
      "Nuevo Ideal",
    ],
    Guanajuato: [
      "ABASOLO",
      "ACAMBARO",
      "ALLENDE",
      "APASEO EL ALTO",
      "APASEO EL GRANDE",
      "ATARJEA",
      "CELAYA",
      "MANUEL DOBLADO",
      "COMONFORT",
      "CORONEO",
      "CORTAZAR",
      "CUERAMARO",
      "DOCTOR MORA",
      "DOLORES HIDALGO",
      "GUANAJUATO",
      "HUANIMARO",
      "IRAPUATO",
      "JARAL DEL PROGRESO",
      "JERECUARO",
      "LEON",
      "MOROLEON",
      "OCAMPO",
      "PENJAMO",
      "PUEBLO NUEVO",
      "PURISIMA DEL RINCON",
      "ROMITA",
      "SALAMANCA",
      "SALVATIERRA",
      "SAN DIEGO DE LA UNION",
      "SAN FELIPE",
      "SAN FRANCISCO DEL RINCON",
      "SAN JOSE ITURBIDE",
      "SAN LUIS DE LA PAZ",
      "SANTA CATARINA",
      "SANTA CRUZ DE JUVENTINO ROSAS",
      "SANTIAGO MARAVATIO",
      "SILAO",
      "TARANDACUAO",
      "TARIMORO",
      "TIERRA BLANCA",
      "URIANGATO",
      "VALLE DE SANTIAGO",
      "VICTORIA",
      "VILLAGRAN",
      "XICHU",
      "YURIRIA",
    ],
    Guerrero: [
      "Acapulco de Juárez",
      "Acatepec",
      "Ahuacuotzingo",
      "Ajuchitlán del Progreso",
      "Alcozauca de Guerrero",
      "Alpoyeca",
      "Apaxtla",
      "Arcelia",
      "Atenango del Río",
      "Atlamajalcingo del Monte",
      "Atlixtac",
      "Atoyac de Álvarez",
      "Ayutla de los Libres",
      "Azoyú",
      "Benito Juárez",
      "Buenavista de Cuéllar",
      "Chilapa de Álvarez",
      "Chilpancingo de los Bravo",
      "Coahuayutla de José María Izazaga",
      "Cochoapa el Grande",
      "Cocula",
      "Copala",
      "Copalillo",
      "Copanatoyac",
      "Coyuca de Benítez",
      "Coyuca de Catalán",
      "Cuajinicuilapa",
      "Cualác",
      "Cuautepec",
      "Cuetzala del Progreso",
      "Cutzamala de Pinzón",
      "Eduardo Neri",
      "Florencio Villarreal",
      "General Canuto A. Neri",
      "General Heliodoro Castillo",
      "Huamuxtitlán",
      "Huitzuco de los Figueroa",
      "Iguala de la Independencia",
      "Igualapa",
      "Iliatenco",
      "Ixcateopan de Cuauhtémoc",
      "José Joaquín de Herrera",
      "Juan R. Escudero",
      "Juchitán",
      "La Unión de Isidoro Montes de Oca",
      "Leonardo Bravo",
      "Malinaltepec",
      "Marquelia",
      "Mártir de Cuilapan",
      "Metlatónoc",
      "Mochitlán",
      "Municipio de Tecoanapa",
      "Olinalá",
      "Ometepec",
      "Pedro Ascencio Alquisiras",
      "Petatlán",
      "Pilcaya",
      "Pungarabato",
      "Quechultenango",
      "San Luis Acatlán",
      "San Marcos",
      "San Miguel Totolapan",
      "Taxco de Alarcón",
      "Técpan de Galeana",
      "Teloloapan",
      "Tepecoacuilco de Trujano",
      "Tetipac",
      "Tixtla de Guerrero",
      "Tlacoachistlahuaca",
      "Tlacoapa",
      "Tlalchapa",
      "Tlalixtaquilla de Maldonado",
      "Tlapa de Comonfort",
      "Tlapehuala",
      "Xalpatláhuac",
      "Xochihuehuetlán",
      "Xochistlahuaca",
      "Zapotitlán Tablas",
      "Zihuatanejo de Azueta",
      "Zirándaro",
      "Zitlala",
    ],
    Hidalgo: [
      "Acatlán",
      "Acaxochitlán",
      "Actopan",
      "Agua Blanca de Iturbide",
      "Ajacuba",
      "Alfajayucan",
      "Almoloya",
      "Apan",
      "Atitalaquia",
      "Atlapexco",
      "Atotonilco de Tula",
      "Atotonilco el Grande",
      "Calnali",
      "Cardonal",
      "Chapantongo",
      "Chapulhuacán",
      "Chilcuautla",
      "Cuautepec de Hinojosa",
      "El Arenal",
      "Eloxochitlán",
      "Emiliano Zapata",
      "Epazoyucan",
      "Francisco I",
      "Huasca de Ocampo",
      "Huautla",
      "Huazalingo",
      "Huehuetla",
      "Huejutla de Reyes",
      "Huichapan",
      "Ixmiquilpan",
      "Jacala de Ledezma",
      "Jaltocán",
      "Juárez Hidalgo",
      "La Misión",
      "Lolotla",
      "Metepec",
      "Metztitlán",
      "Mineral de la Reforma",
      "Mineral del Chico",
      "Mineral del Monte",
      "Mixquiahuala de Juárez",
      "Molango de Escamilla",
      "Nicolás Flores",
      "Nopala de Villagrán",
      "Omitlán de Juárez",
      "Pachuca de Soto",
      "Pacula",
      "Pisaflores",
      "Progreso de Obregón",
      "San Agustín Metzquititlán",
      "San Agustín Tlaxiaca",
      "San Bartolo Tutotepec",
      "San Felipe Orizatlán",
      "San Salvador",
      "Santiago de Anaya",
      "Santiago Tulantepec de Lugo Guerrero",
      "Singuilucan",
      "Tasquillo",
      "Tecozautla",
      "Tenango de Doria",
      "Tepeapulco",
      "Tepehuacán de Guerrero",
      "Tepeji del Río de Ocampo",
      "Tepetitlán",
      "Tetepango",
      "Tezontepec de Aldama",
      "Tianguistengo",
      "Tizayuca",
      "Tlahuelilpan",
      "Tlahuiltepa",
      "Tlanalapa",
      "Tlanchinol",
      "Tlaxcoapan",
      "Tolcayuca",
      "Tula de Allende",
      "Tulancingo de Bravo",
      "Villa de Tezontepec",
      "Xochiatipan",
      "Xochicoatlán",
      "Yahualica",
      "Zacualtipán de Ángeles",
      "Zapotlán de Juárez",
      "Zempoala",
      "Zimapán",
    ],
    Jalisco: [
      "Acatic",
      "Acatlán de Juárez",
      "Ahualulco de Mercado",
      "Amacueca",
      "Amatitán",
      "Ameca",
      "Arandas",
      "Atemajac de Brizuela",
      "Atengo",
      "Atenguillo",
      "Atotonilco el Alto",
      "Atoyac",
      "Autlán de Navarro",
      "Ayotlán",
      "Ayutla",
      "Bolaños",
      "Cabo Corrientes",
      "Cañadas de Obregón",
      "Casimiro Castillo",
      "Chapala",
      "Chimaltitán",
      "Chiquilistlán",
      "Cihuatlán",
      "Cocula",
      "Colotlán",
      "Concepción de Buenos Aires",
      "Cuautitlán de García Barragán",
      "Cuautla",
      "Cuquío",
      "Degollado",
      "Ejutla",
      "El Arenal",
      "El Grullo",
      "El Limón",
      "El Salto",
      "Encarnación de Díaz",
      "Etzatlán",
      "Gómez Farías",
      "Guachinango",
      "Guadalajara",
      "Hostotipaquillo",
      "Huejúcar",
      "Huejuquilla el Alto",
      "Ixtlahuacán de los Membrillos",
      "Ixtlahuacán del Río",
      "Jalostotitlán",
      "Jamay",
      "Jesús María",
      "Jilotlán de los Dolores",
      "Jocotepec",
      "Juanacatlán",
      "Juchitlán",
      "La Barca",
      "La Huerta",
      "La Manzanilla de la Paz",
      "Lagos de Moreno",
      "Magdalena",
      "Mascota",
      "Mazamitla",
      "Mexticacán",
      "Mezquitic",
      "Mixtlán",
      "Ocotlán",
      "Ojuelos de Jalisco",
      "Pihuamo",
      "Poncitlán",
      "Puerto Vallarta",
      "Quitupan",
      "San Cristóbal de la Barranca",
      "San Diego de Alejandría",
      "San Gabriel",
      "San Ignacio Cerro Gordo",
      "San Juan de los Lagos",
      "San Juanito de Escobedo",
      "San Julián",
      "San Marcos",
      "San Martín de Bolaños",
      "San Martín Hidalgo",
      "San Miguel el Alto",
      "San Sebastián del Oeste",
      "Santa María de los Ángeles",
      "Santa María del Oro",
      "Sayula",
      "Tala",
      "Talpa de Allende",
      "Tamazula de Gordiano",
      "Tapalpa",
      "Tecalitlán",
      "Techaluta de Montenegro",
      "Tecolotlán",
      "Tenamaxtlán",
      "Teocaltiche",
      "Teocuitatlán de Corona",
      "Tepatitlán de Morelos",
      "Tequila",
      "Teuchitlán",
      "Tizapán el Alto",
      "Tlajomulco de Zúñiga",
      "Tlaquepaque",
      "Tolimán",
      "Tomatlán",
      "Tonalá",
      "Tonaya",
      "Tonila",
      "Totatiche",
      "Tototlán",
      "Tuxcacuesco",
      "Tuxcueca",
      "Tuxpan",
      "Unión de San Antonio",
      "Unión de Tula",
      "Valle de Guadalupe",
      "Valle de Juárez",
      "Villa Corona",
      "Villa Guerrero",
      "Villa Hidalgo",
      "Villa Purificación",
      "Yahualica de González Gallo",
      "Zacoalco de Torres",
      "Zapopan",
      "Zapotiltic",
      "Zapotitlán de Vadillo",
      "Zapotlán del Rey",
      "Zapotlán el Grande",
      "Zapotlanejo",
    ],
    "Estado de México": [
      "Acambay",
      "Acolman",
      "Aculco",
      "Almoloya de Alquisiras",
      "Almoloya de Juárez",
      "Almoloya del Río",
      "Amanalco",
      "Amatepec",
      "Amecameca",
      "Apaxco",
      "Atenco",
      "Atizapán",
      "Atizapán de Zaragoza",
      "Atlacomulco",
      "Atlautla",
      "Axapusco",
      "Ayapango",
      "Calimaya",
      "Capulhuac",
      "Chalco",
      "Chapa de Mota",
      "Chapultepec",
      "Chiautla",
      "Chicoloapan",
      "Chiconcuac",
      "Chimalhuacán",
      "Coacalco de Berriozábal",
      "Coatepec Harinas",
      "Cocotitlán",
      "Coyotepec",
      "Cuautitlán",
      "Cuautitlán Izcalli",
      "Donato Guerra",
      "Ecatepec de Morelos",
      "Ecatzingo",
      "El Oro",
      "Huehuetoca",
      "Hueypoxtla",
      "Huixquilucan",
      "Isidro Fabela",
      "Ixtapaluca",
      "Ixtapan de la Sal",
      "Ixtapan del Oro",
      "Ixtlahuaca",
      "Jaltenco",
      "Jilotepec",
      "Jilotzingo",
      "Jiquipilco",
      "Jocotitlán",
      "Joquicingo",
      "Juchitepec",
      "La Paz",
      "Lerma",
      "Luvianos",
      "Malinalco",
      "Melchor Ocampo",
      "Metepec",
      "Mexicaltzingo",
      "Morelos",
      "Naucalpan de Juárez",
      "Nextlalpan",
      "Nezahualcóyotl",
      "Nicolás Romero",
      "Nopaltepec",
      "Ocoyoacac",
      "Ocuilan",
      "Otumba",
      "Otzoloapan",
      "Otzolotepec",
      "Ozumba",
      "Papalotla",
      "Polotitlán",
      "Rayón",
      "San Antonio la Isla",
      "San Felipe del Progreso",
      "San José del Rincón",
      "San Martín de las Pirámides",
      "San Mateo Atenco",
      "San Simón de Guerrero",
      "Santo Tomás",
      "Soyaniquilpan de Juárez",
      "Sultepec",
      "Tecámac",
      "Tejupilco",
      "Temamatla",
      "Temascalapa",
      "Temascalcingo",
      "Temascaltepec",
      "Temoaya",
      "Tenancingo",
      "Tenango del Aire",
      "Tenango del Valle",
      "Teoloyucán",
      "Teotihuacán",
      "Tepetlaoxtoc",
      "Tepetlixpa",
      "Tepotzotlán",
      "Tequixquiac",
      "Texcaltitlán",
      "Texcalyacac",
      "Texcoco",
      "Tezoyuca",
      "Tianguistenco",
      "Timilpan",
      "Tlalmanalco",
      "Tlalnepantla de Baz",
      "Tlatlaya",
      "Toluca",
      "Tonanitla",
      "Tonatico",
      "Tultepec",
      "Tultitlán",
      "Valle de Bravo",
      "Valle de Chalco Solidaridad",
      "Villa de Allende",
      "Villa del Carbón",
      "Villa Guerrero",
      "Villa Victoria",
      "Xalatlaco",
      "Xonacatlán",
      "Zacazonapan",
      "Zacualpan",
      "Zinacantepec",
      "Zumpahuacán",
      "Zumpango",
    ],
    "Michoacán de Ocampo": [
      "Acuitzio",
      "Aguililla",
      "Álvaro Obregón",
      "Angamacutiro",
      "Angangueo",
      "Apatzingán",
      "Aporo",
      "Aquila",
      "Ario",
      "Arteaga",
      "Briseñas",
      "Buenavista",
      "Carácuaro",
      "Charapan",
      "Charo",
      "Chavinda",
      "Cherán",
      "Chilchota",
      "Chinicuila",
      "Chucándiro",
      "Churintzio",
      "Churumuco",
      "Coahuayana",
      "Coalcomán de Vázquez Pallares",
      "Coeneo",
      "Cojumatlán de Régules",
      "Contepec",
      "Copándaro",
      "Cotija",
      "Cuitzeo",
      "Ecuandureo",
      "Epitacio Huerta",
      "Erongarícuaro",
      "Gabriel Zamora",
      "Hidalgo",
      "Huandacareo",
      "Huaniqueo",
      "Huetamo",
      "Huiramba",
      "Indaparapeo",
      "Irimbo",
      "Ixtlán",
      "Jacona",
      "Jiménez",
      "Jiquilpan",
      "José Sixto Verduzco",
      "Juárez",
      "Jungapeo",
      "La Huacana",
      "La Piedad",
      "Lagunillas",
      "Lázaro Cárdenas",
      "Los Reyes",
      "Madero",
      "Maravatío",
      "Marcos Castellanos",
      "Morelia",
      "Morelos",
      "Múgica",
      "Nahuatzen",
      "Nocupétaro",
      "Nuevo Parangaricutiro",
      "Nuevo Urecho",
      "Numarán",
      "Ocampo",
      "Pajacuarán",
      "Panindícuaro",
      "Paracho",
      "Parácuaro",
      "Pátzcuaro",
      "Penjamillo",
      "Peribán",
      "Purépero",
      "Puruándiro",
      "Queréndaro",
      "Quiroga",
      "Sahuayo",
      "Salvador Escalante",
      "San Lucas",
      "Santa Ana Maya",
      "Senguio",
      "Susupuato",
      "Tacámbaro",
      "Tancítaro",
      "Tangamandapio",
      "Tangancícuaro",
      "Tanhuato",
      "Taretan",
      "Tarímbaro",
      "Tepalcatepec",
      "Tingambato",
      "Tingüindín",
      "Tiquicheo de Nicolás Romero",
      "Tlalpujahua",
      "Tlazazalca",
      "Tocumbo",
      "Tumbiscatío",
      "Turicato",
      "Tuxpan",
      "Tuzantla",
      "Tzintzuntzan",
      "Tzitzio",
      "Uruapan",
      "Venustiano Carranza",
      "Villamar",
      "Vista Hermosa",
      "Yurécuaro",
      "Zacapu",
      "Zamora",
      "Zináparo",
      "Zinapécuaro",
      "Ziracuaretiro",
      "Zitácuaro",
    ],
    Morelos: [
      "Amacuzac",
      "Atlatlahucan",
      "Axochiapan",
      "Ayala",
      "Coatlán del Río",
      "Cuautla",
      "Cuernavaca",
      "Emiliano Zapata",
      "Huitzilac",
      "Jantetelco",
      "Jiutepec",
      "Jojutla",
      "Jonacatepec",
      "Mazatepec",
      "Miacatlán",
      "Ocuituco",
      "Puente de Ixtla",
      "Temixco",
      "Temoac",
      "Tepalcingo",
      "Tepoztlán",
      "Tetecala",
      "Tetela del Volcán",
      "Tlalnepantla",
      "Tlaltizapán",
      "Tlaquiltenango",
      "Tlayacapan",
      "Totolapan",
      "Xochitepec",
      "Yautepec",
      "Yecapixtla",
      "Zacatepec",
      "Zacualpan",
    ],
    Nayarit: [
      "Acaponeta",
      "Ahuacatlán",
      "Amatlán de Cañas",
      "Bahía de Banderas",
      "Compostela",
      "Del Nayar",
      "Huajicori",
      "Ixtlán del Río",
      "Jala",
      "La Yesca",
      "Rosamorada",
      "Ruíz",
      "San Blas",
      "San Pedro Lagunillas",
      "Santa María del Oro",
      "Santiago Ixcuintla",
      "Tecuala",
      "Tepic",
      "Tuxpan",
      "Xalisco",
    ],
    "Nuevo León": [
      "Abasolo",
      "Agualeguas",
      "Allende",
      "Anáhuac",
      "Apodaca",
      "Aramberri",
      "Bustamante",
      "Cadereyta Jiménez",
      "Carmen",
      "Cerralvo",
      "China",
      "Ciénega de Flores",
      "Dr  Arroyo",
      "Dr  Coss",
      "Dr González",
      "Galeana",
      "García",
      "Gral  Zaragoza",
      "Gral Bravo",
      "Gral Escobedo",
      "Gral Terán",
      "Gral Treviño",
      "Gral Zuazua",
      "Guadalupe",
      "Hidalgo",
      "Higueras",
      "Hualahuises",
      "Iturbide",
      "Juárez",
      "Lampazos de Naranjo",
      "Linares",
      "Los Aldamas",
      "Los Herreras",
      "Los Ramones",
      "Marín",
      "Melchor Ocampo",
      "Mier y Noriega",
      "Mina",
      "Montemorelos",
      "Monterrey",
      "Parás",
      "Pesquería",
      "Rayones",
      "Sabinas Hidalgo",
      "Salinas Victoria",
      "San Nicolás de los Garza",
      "San Pedro Garza García",
      "Santa Catarina",
      "Santiago",
      "Vallecillo",
      "Villaldama",
    ],
    Oaxaca: [
      "Abejones",
      "Acatlán de Pérez Figueroa",
      "Asunción Cacalotepec",
      "Asunción Cuyotepeji",
      "Asunción Ixtaltepec",
      "Asunción Nochixtlán",
      "Asunción Ocotlán",
      "Asunción Tlacolulita",
      "Ayotzintepec",
      "El Barrio de la Soledad",
      "Calihualá",
      "Candelaria Loxicha",
      "Ciénega de Zimatlán",
      "Ciudad Ixtepec",
      "Coatecas Altas",
      "Coicoyán de las Flores",
      "La Compañía",
      "Concepción Buenavista",
      "Concepción Pápalo",
      "Constancia del Rosario",
      "Cosolapa",
      "Cosoltepec",
      "Cuilápam de Guerrero",
      "Cuyamecalco Villa de Zaragoza",
      "Chahuites",
      "Chalcatongo de Hidalgo",
      "Chiquihuitlán de Benito Juárez",
      "Heroica Ciudad de Ejutla de Crespo",
      "Eloxochitlán de Flores Magón",
      "El Espinal",
      "Tamazulápam del Espíritu Santo",
      "Fresnillo de Trujano",
      "Guadalupe Etla",
      "Guadalupe de Ramírez",
      "Guelatao de Juárez",
      "Guevea de Humboldt",
      "Mesones Hidalgo",
      "Villa Hidalgo",
      "Heroica Ciudad de Huajuapan de León",
      "Huautepec",
      "Huautla de Jiménez",
      "Ixtlán de Juárez",
      "Heroica Ciudad de Juchitán de Zaragoza",
      "Loma Bonita",
      "Magdalena Apasco",
      "Magdalena Jaltepec",
      "Santa Magdalena Jicotlán",
      "Magdalena Mixtepec",
      "Magdalena Ocotlán",
      "Magdalena Peñasco",
      "Magdalena Teitipac",
      "Magdalena Tequisistlán",
      "Magdalena Tlacotepec",
      "Magdalena Zahuatlán",
      "Mariscala de Juárez",
      "Mártires de Tacubaya",
      "Matías Romero Avendaño",
      "Mazatlán Villa de Flores",
      "Miahuatlán de Porfirio Díaz",
      "Mixistlán de la Reforma",
      "Monjas",
      "Natividad",
      "Nazareno Etla",
      "Nejapa de Madero",
      "Ixpantepec Nieves",
      "Santiago Niltepec",
      "Oaxaca de Juárez",
      "Ocotlán de Morelos",
      "La Pe",
      "Pinotepa de Don Luis",
      "Pluma Hidalgo",
      "San José del Progreso",
      "Putla Villa de Guerrero",
      "Santa Catarina Quioquitani",
      "Reforma de Pineda",
      "La Reforma",
      "Reyes Etla",
      "Rojas de Cuauhtémoc",
      "Salina Cruz",
      "San Agustín Amatengo",
      "San Agustín Atenango",
      "San Agustín Chayuco",
      "San Agustín de las Juntas",
      "San Agustín Etla",
      "San Agustín Loxicha",
      "San Agustín Tlacotepec",
      "San Agustín Yatareni",
      "San Andrés Cabecera Nueva",
      "San Andrés Dinicuiti",
      "San Andrés Huaxpaltepec",
      "San Andrés Huayápam",
      "San Andrés Ixtlahuaca",
      "San Andrés Lagunas",
      "San Andrés Nuxiño",
      "San Andrés Paxtlán",
      "San Andrés Sinaxtla",
      "San Andrés Solaga",
      "San Andrés Teotilálpam",
      "San Andrés Tepetlapa",
      "San Andrés Yaá",
      "San Andrés Zabache",
      "San Andrés Zautla",
      "San Antonino Castillo Velasco",
      "San Antonino el Alto",
      "San Antonino Monte Verde",
      "San Antonio Acutla",
      "San Antonio de la Cal",
      "San Antonio Huitepec",
      "San Antonio Nanahuatípam",
      "San Antonio Sinicahua",
      "San Antonio Tepetlapa",
      "San Baltazar Chichicápam",
      "San Baltazar Loxicha",
      "San Baltazar Yatzachi el Bajo",
      "San Bartolo Coyotepec",
      "San Bartolomé Ayautla",
      "San Bartolomé Loxicha",
      "San Bartolomé Quialana",
      "San Bartolomé Yucuañe",
      "San Bartolomé Zoogocho",
      "San Bartolo Soyaltepec",
      "San Bartolo Yautepec",
      "San Bernardo Mixtepec",
      "San Blas Atempa",
      "San Carlos Yautepec",
      "San Cristóbal Amatlán",
      "San Cristóbal Amoltepec",
      "San Cristóbal Lachirioag",
      "San Cristóbal Suchixtlahuaca",
      "San Dionisio del Mar",
      "San Dionisio Ocotepec",
      "San Dionisio Ocotlán",
      "San Esteban Atatlahuca",
      "San Felipe Jalapa de Díaz",
      "San Felipe Tejalápam",
      "San Felipe Usila",
      "San Francisco Cahuacuá",
      "San Francisco Cajonos",
      "San Francisco Chapulapa",
      "San Francisco Chindúa",
      "San Francisco del Mar",
      "San Francisco Huehuetlán",
      "San Francisco Ixhuatán",
      "San Francisco Jaltepetongo",
      "San Francisco Lachigoló",
      "San Francisco Logueche",
      "San Francisco Nuxaño",
      "San Francisco Ozolotepec",
      "San Francisco Sola",
      "San Francisco Telixtlahuaca",
      "San Francisco Teopan",
      "San Francisco Tlapancingo",
      "San Gabriel Mixtepec",
      "San Ildefonso Amatlán",
      "San Ildefonso Sola",
      "San Ildefonso Villa Alta",
      "San Jacinto Amilpas",
      "San Jacinto Tlacotepec",
      "San Jerónimo Coatlán",
      "San Jerónimo Silacayoapilla",
      "San Jerónimo Sosola",
      "San Jerónimo Taviche",
      "San Jerónimo Tecóatl",
      "San Jorge Nuchita",
      "San José Ayuquila",
      "San José Chiltepec",
      "San José del Peñasco",
      "San José Estancia Grande",
      "San José Independencia",
      "San José Lachiguiri",
      "San José Tenango",
      "San Juan Achiutla",
      "San Juan Atepec",
      "Ánimas Trujano",
      "San Juan Bautista Atatlahuca",
      "San Juan Bautista Coixtlahuaca",
      "San Juan Bautista Cuicatlán",
      "San Juan Bautista Guelache",
      "San Juan Bautista Jayacatlán",
      "San Juan Bautista Lo de Soto",
      "San Juan Bautista Suchitepec",
      "San Juan Bautista Tlacoatzintepec",
      "San Juan Bautista Tlachichilco",
      "San Juan Bautista Tuxtepec",
      "San Juan Cacahuatepec",
      "San Juan Cieneguilla",
      "San Juan Coatzóspam",
      "San Juan Colorado",
      "San Juan Comaltepec",
      "San Juan Cotzocón",
      "San Juan Chicomezúchil",
      "San Juan Chilateca",
      "San Juan del Estado",
      "San Juan del Río",
      "San Juan Diuxi",
      "San Juan Evangelista Analco",
      "San Juan Guelavía",
      "San Juan Guichicovi",
      "San Juan Ihualtepec",
      "San Juan Juquila Mixes",
      "San Juan Juquila Vijanos",
      "San Juan Lachao",
      "San Juan Lachigalla",
      "San Juan Lajarcia",
      "San Juan Lalana",
      "San Juan de los Cués",
      "San Juan Mazatlán",
      "San Juan Mixtepec -Dto",
      "San Juan Mixtepec -Dto",
      "San Juan Ñumí",
      "San Juan Ozolotepec",
      "San Juan Petlapa",
      "San Juan Quiahije",
      "San Juan Quiotepec",
      "San Juan Sayultepec",
      "San Juan Tabaá",
      "San Juan Tamazola",
      "San Juan Teita",
      "San Juan Teitipac",
      "San Juan Tepeuxila",
      "San Juan Teposcolula",
      "San Juan Yaeé",
      "San Juan Yatzona",
      "San Juan Yucuita",
      "San Lorenzo",
      "San Lorenzo Albarradas",
      "San Lorenzo Cacaotepec",
      "San Lorenzo Cuaunecuiltitla",
      "San Lorenzo Texmelúcan",
      "San Lorenzo Victoria",
      "San Lucas Camotlán",
      "San Lucas Ojitlán",
      "San Lucas Quiaviní",
      "San Lucas Zoquiápam",
      "San Luis Amatlán",
      "San Marcial Ozolotepec",
      "San Marcos Arteaga",
      "San Martín de los Cansecos",
      "San Martín Huamelúlpam",
      "San Martín Itunyoso",
      "San Martín Lachilá",
      "San Martín Peras",
      "San Martín Tilcajete",
      "San Martín Toxpalan",
      "San Martín Zacatepec",
      "San Mateo Cajonos",
      "Capulálpam de Méndez",
      "San Mateo del Mar",
      "San Mateo Yoloxochitlán",
      "San Mateo Etlatongo",
      "San Mateo Nejápam",
      "San Mateo Peñasco",
      "San Mateo Piñas",
      "San Mateo Río Hondo",
      "San Mateo Sindihui",
      "San Mateo Tlapiltepec",
      "San Melchor Betaza",
      "San Miguel Achiutla",
      "San Miguel Ahuehuetitlán",
      "San Miguel Aloápam",
      "San Miguel Amatitlán",
      "San Miguel Amatlán",
      "San Miguel Coatlán",
      "San Miguel Chicahua",
      "San Miguel Chimalapa",
      "San Miguel del Puerto",
      "San Miguel del Río",
      "San Miguel Ejutla",
      "San Miguel el Grande",
      "San Miguel Huautla",
      "San Miguel Mixtepec",
      "San Miguel Panixtlahuaca",
      "San Miguel Peras",
      "San Miguel Piedras",
      "San Miguel Quetzaltepec",
      "San Miguel Santa Flor",
      "Villa Sola de Vega",
      "San Miguel Soyaltepec",
      "San Miguel Suchixtepec",
      "Villa Talea de Castro",
      "San Miguel Tecomatlán",
      "San Miguel Tenango",
      "San Miguel Tequixtepec",
      "San Miguel Tilquiápam",
      "San Miguel Tlacamama",
      "San Miguel Tlacotepec",
      "San Miguel Tulancingo",
      "San Miguel Yotao",
      "San Nicolás",
      "San Nicolás Hidalgo",
      "San Pablo Coatlán",
      "San Pablo Cuatro Venados",
      "San Pablo Etla",
      "San Pablo Huitzo",
      "San Pablo Huixtepec",
      "San Pablo Macuiltianguis",
      "San Pablo Tijaltepec",
      "San Pablo Villa de Mitla",
      "San Pablo Yaganiza",
      "San Pedro Amuzgos",
      "San Pedro Apóstol",
      "San Pedro Atoyac",
      "San Pedro Cajonos",
      "San Pedro Coxcaltepec Cántaros",
      "San Pedro Comitancillo",
      "San Pedro el Alto",
      "San Pedro Huamelula",
      "San Pedro Huilotepec",
      "San Pedro Ixcatlán",
      "San Pedro Ixtlahuaca",
      "San Pedro Jaltepetongo",
      "San Pedro Jicayán",
      "San Pedro Jocotipac",
      "San Pedro Juchatengo",
      "San Pedro Mártir",
      "San Pedro Mártir Quiechapa",
      "San Pedro Mártir Yucuxaco",
      "San Pedro Mixtepec -Dto",
      "San Pedro Mixtepec -Dto",
      "San Pedro Molinos",
      "San Pedro Nopala",
      "San Pedro Ocopetatillo",
      "San Pedro Ocotepec",
      "San Pedro Pochutla",
      "San Pedro Quiatoni",
      "San Pedro Sochiápam",
      "San Pedro Tapanatepec",
      "San Pedro Taviche",
      "San Pedro Teozacoalco",
      "San Pedro Teutila",
      "San Pedro Tidaá",
      "San Pedro Topiltepec",
      "San Pedro Totolápam",
      "Villa de Tututepec de Melchor Ocampo",
      "San Pedro Yaneri",
      "San Pedro Yólox",
      "San Pedro y San Pablo Ayutla",
      "Villa de Etla",
      "San Pedro y San Pablo Teposcolula",
      "San Pedro y San Pablo Tequixtepec",
      "San Pedro Yucunama",
      "San Raymundo Jalpan",
      "San Sebastián Abasolo",
      "San Sebastián Coatlán",
      "San Sebastián Ixcapa",
      "San Sebastián Nicananduta",
      "San Sebastián Río Hondo",
      "San Sebastián Tecomaxtlahuaca",
      "San Sebastián Teitipac",
      "San Sebastián Tutla",
      "San Simón Almolongas",
      "San Simón Zahuatlán",
      "Santa Ana",
      "Santa Ana Ateixtlahuaca",
      "Santa Ana Cuauhtémoc",
      "Santa Ana del Valle",
      "Santa Ana Tavela",
      "Santa Ana Tlapacoyan",
      "Santa Ana Yareni",
      "Santa Ana Zegache",
      "Santa Catalina Quierí",
      "Santa Catarina Cuixtla",
      "Santa Catarina Ixtepeji",
      "Santa Catarina Juquila",
      "Santa Catarina Lachatao",
      "Santa Catarina Loxicha",
      "Santa Catarina Mechoacán",
      "Santa Catarina Minas",
      "Santa Catarina Quiané",
      "Santa Catarina Tayata",
      "Santa Catarina Ticuá",
      "Santa Catarina Yosonotú",
      "Santa Catarina Zapoquila",
      "Santa Cruz Acatepec",
      "Santa Cruz Amilpas",
      "Santa Cruz de Bravo",
      "Santa Cruz Itundujia",
      "Santa Cruz Mixtepec",
      "Santa Cruz Nundaco",
      "Santa Cruz Papalutla",
      "Santa Cruz Tacache de Mina",
      "Santa Cruz Tacahua",
      "Santa Cruz Tayata",
      "Santa Cruz Xitla",
      "Santa Cruz Xoxocotlán",
      "Santa Cruz Zenzontepec",
      "Santa Gertrudis",
      "Santa Inés del Monte",
      "Santa Inés Yatzeche",
      "Santa Lucía del Camino",
      "Santa Lucía Miahuatlán",
      "Santa Lucía Monteverde",
      "Santa Lucía Ocotlán",
      "Santa María Alotepec",
      "Santa María Apazco",
      "Santa María la Asunción",
      "Heroica Ciudad de Tlaxiaco",
      "Ayoquezco de Aldama",
      "Santa María Atzompa",
      "Santa María Camotlán",
      "Santa María Colotepec",
      "Santa María Cortijo",
      "Santa María Coyotepec",
      "Santa María Chachoápam",
      "Villa de Chilapa de Díaz",
      "Santa María Chilchotla",
      "Santa María Chimalapa",
      "Santa María del Rosario",
      "Santa María del Tule",
      "Santa María Ecatepec",
      "Santa María Guelacé",
      "Santa María Guienagati",
      "Santa María Huatulco",
      "Santa María Huazolotitlán",
      "Santa María Ipalapa",
      "Santa María Ixcatlán",
      "Santa María Jacatepec",
      "Santa María Jalapa del Marqués",
      "Santa María Jaltianguis",
      "Santa María Lachixío",
      "Santa María Mixtequilla",
      "Santa María Nativitas",
      "Santa María Nduayaco",
      "Santa María Ozolotepec",
      "Santa María Pápalo",
      "Santa María Peñoles",
      "Santa María Petapa",
      "Santa María Quiegolani",
      "Santa María Sola",
      "Santa María Tataltepec",
      "Santa María Tecomavaca",
      "Santa María Temaxcalapa",
      "Santa María Temaxcaltepec",
      "Santa María Teopoxco",
      "Santa María Tepantlali",
      "Santa María Texcatitlán",
      "Santa María Tlahuitoltepec",
      "Santa María Tlalixtac",
      "Santa María Tonameca",
      "Santa María Totolapilla",
      "Santa María Xadani",
      "Santa María Yalina",
      "Santa María Yavesía",
      "Santa María Yolotepec",
      "Santa María Yosoyúa",
      "Santa María Yucuhiti",
      "Santa María Zacatepec",
      "Santa María Zaniza",
      "Santa María Zoquitlán",
      "Santiago Amoltepec",
      "Santiago Apoala",
      "Santiago Apóstol",
      "Santiago Astata",
      "Santiago Atitlán",
      "Santiago Ayuquililla",
      "Santiago Cacaloxtepec",
      "Santiago Camotlán",
      "Santiago Comaltepec",
      "Santiago Chazumba",
      "Santiago Choápam",
      "Santiago del Río",
      "Santiago Huajolotitlán",
      "Santiago Huauclilla",
      "Santiago Ihuitlán Plumas",
      "Santiago Ixcuintepec",
      "Santiago Ixtayutla",
      "Santiago Jamiltepec",
      "Santiago Jocotepec",
      "Santiago Juxtlahuaca",
      "Santiago Lachiguiri",
      "Santiago Lalopa",
      "Santiago Laollaga",
      "Santiago Laxopa",
      "Santiago Llano Grande",
      "Santiago Matatlán",
      "Santiago Miltepec",
      "Santiago Minas",
      "Santiago Nacaltepec",
      "Santiago Nejapilla",
      "Santiago Nundiche",
      "Santiago Nuyoó",
      "Santiago Pinotepa Nacional",
      "Santiago Suchilquitongo",
      "Santiago Tamazola",
      "Santiago Tapextla",
      "Villa Tejúpam de la Unión",
      "Santiago Tenango",
      "Santiago Tepetlapa",
      "Santiago Tetepec",
      "Santiago Texcalcingo",
      "Santiago Textitlán",
      "Santiago Tilantongo",
      "Santiago Tillo",
      "Santiago Tlazoyaltepec",
      "Santiago Xanica",
      "Santiago Xiacuí",
      "Santiago Yaitepec",
      "Santiago Yaveo",
      "Santiago Yolomécatl",
      "Santiago Yosondúa",
      "Santiago Yucuyachi",
      "Santiago Zacatepec",
      "Santiago Zoochila",
      "Nuevo Zoquiápam",
      "Santo Domingo Ingenio",
      "Santo Domingo Albarradas",
      "Santo Domingo Armenta",
      "Santo Domingo Chihuitán",
      "Santo Domingo de Morelos",
      "Santo Domingo Ixcatlán",
      "Santo Domingo Nuxaá",
      "Santo Domingo Ozolotepec",
      "Santo Domingo Petapa",
      "Santo Domingo Roayaga",
      "Santo Domingo Tehuantepec",
      "Santo Domingo Teojomulco",
      "Santo Domingo Tepuxtepec",
      "Santo Domingo Tlatayápam",
      "Santo Domingo Tomaltepec",
      "Santo Domingo Tonalá",
      "Santo Domingo Tonaltepec",
      "Santo Domingo Xagacía",
      "Santo Domingo Yanhuitlán",
      "Santo Domingo Yodohino",
      "Santo Domingo Zanatepec",
      "Santos Reyes Nopala",
      "Santos Reyes Pápalo",
      "Santos Reyes Tepejillo",
      "Santos Reyes Yucuná",
      "Santo Tomás Jalieza",
      "Santo Tomás Mazaltepec",
      "Santo Tomás Ocotepec",
      "Santo Tomás Tamazulapan",
      "San Vicente Coatlán",
      "San Vicente Lachixío",
      "San Vicente Nuñú",
      "Silacayoápam",
      "Sitio de Xitlapehua",
      "Soledad Etla",
      "Villa de Tamazulápam del Progreso",
      "Tanetze de Zaragoza",
      "Taniche",
      "Tataltepec de Valdés",
      "Teococuilco de Marcos Pérez",
      "Teotitlán de Flores Magón",
      "Teotitlán del Valle",
      "Teotongo",
      "Tepelmeme Villa de Morelos",
      "Tezoatlán de Segura y Luna",
      "San Jerónimo Tlacochahuaya",
      "Tlacolula de Matamoros",
      "Tlacotepec Plumas",
      "Tlalixtac de Cabrera",
      "Totontepec Villa de Morelos",
      "Trinidad Zaachila",
      "La Trinidad Vista Hermosa",
      "Unión Hidalgo",
      "Valerio Trujano",
      "San Juan Bautista Valle Nacional",
      "Villa Díaz Ordaz",
      "Yaxe",
      "Magdalena Yodocono de Porfirio Díaz",
      "Yogana",
      "Yutanduchi de Guerrero",
      "Villa de Zaachila",
      "San Mateo Yucutindó",
      "Zapotitlán Lagunas",
      "Zapotitlán Palmas",
      "Santa Inés de Zaragoza",
      "Zimatlán de Álvarez",
    ],
    Puebla: [
      "Acajete",
      "Acateno",
      "Acatlán",
      "Acatzingo",
      "Acteopan",
      "Ahuacatlán",
      "Ahuatlán",
      "Ahuazotepec",
      "Ahuehuetitla",
      "Ajalpan",
      "Albino Zertuche",
      "Aljojuca",
      "Altepexi",
      "Amixtlán",
      "Amozoc",
      "Aquixtla",
      "Atempan",
      "Atexcal",
      "Atlixco",
      "Atoyatempan",
      "Atzala",
      "Atzitzihuacán",
      "Atzitzintla",
      "Axutla",
      "Ayotoxco de Guerrero",
      "Calpan",
      "Caltepec",
      "Camocuautla",
      "Caxhuacan",
      "Coatepec",
      "Coatzingo",
      "Cohetzala",
      "Cohuecan",
      "Coronango",
      "Coxcatlán",
      "Coyomeapan",
      "Coyotepec",
      "Cuapiaxtla de Madero",
      "Cuautempan",
      "Cuautinchán",
      "Cuautlancingo",
      "Cuayuca de Andrade",
      "Cuetzalan del Progreso",
      "Cuyoaco",
      "Chalchicomula de Sesma",
      "Chapulco",
      "Chiautla",
      "Chiautzingo",
      "Chiconcuautla",
      "Chichiquila",
      "Chietla",
      "Chigmecatitlán",
      "Chignahuapan",
      "Chignautla",
      "Chila",
      "Chila de la Sal",
      "Honey",
      "Chilchotla",
      "Chinantla",
      "Domingo Arenas",
      "Eloxochitlán",
      "Epatlán",
      "Esperanza",
      "Francisco Z  Mena",
      "General Felipe Ángeles",
      "Guadalupe",
      "Guadalupe Victoria",
      "Hermenegildo Galeana",
      "Huaquechula",
      "Huatlatlauca",
      "Huauchinango",
      "Huehuetla",
      "Huehuetlán el Chico",
      "Huejotzingo",
      "Hueyapan",
      "Hueytamalco",
      "Hueytlalpan",
      "Huitzilan de Serdán",
      "Huitziltepec",
      "Atlequizayan",
      "Ixcamilpa de Guerrero",
      "Ixcaquixtla",
      "Ixtacamaxtitlán",
      "Ixtepec",
      "Izúcar de Matamoros",
      "Jalpan",
      "Jolalpan",
      "Jonotla",
      "Jopala",
      "Juan C Bonilla",
      "Juan Galindo",
      "Juan N Méndez",
      "Lafragua",
      "Libres",
      "La Magdalena Tlatlauquitepec",
      "Mazapiltepec de Juárez",
      "Mixtla",
      "Molcaxac",
      "Cañada Morelos",
      "Naupan",
      "Nauzontla",
      "Nealtican",
      "Nicolás Bravo",
      "Nopalucan",
      "Ocotepec",
      "Ocoyucan",
      "Olintla",
      "Oriental",
      "Pahuatlán",
      "Palmar de Bravo",
      "Pantepec",
      "Petlalcingo",
      "Piaxtla",
      "Puebla",
      "Quecholac",
      "Quimixtlán",
      "Rafael Lara Grajales",
      "Los Reyes de Juárez",
      "San Andrés Cholula",
      "San Antonio Cañada",
      "San Diego la Mesa Tochimiltzingo",
      "San Felipe Teotlalcingo",
      "San Felipe Tepatlán",
      "San Gabriel Chilac",
      "San Gregorio Atzompa",
      "San Jerónimo Tecuanipan",
      "San Jerónimo Xayacatlán",
      "San José Chiapa",
      "San José Miahuatlán",
      "San Juan Atenco",
      "San Juan Atzompa",
      "San Martín Texmelucan",
      "San Martín Totoltepec",
      "San Matías Tlalancaleca",
      "San Miguel Ixitlán",
      "San Miguel Xoxtla",
      "San Nicolás Buenos Aires",
      "San Nicolás de los Ranchos",
      "San Pablo Anicano",
      "San Pedro Cholula",
      "San Pedro Yeloixtlahuaca",
      "San Salvador el Seco",
      "San Salvador el Verde",
      "San Salvador Huixcolotla",
      "San Sebastián Tlacotepec",
      "Santa Catarina Tlaltempan",
      "Santa Inés Ahuatempan",
      "Santa Isabel Cholula",
      "Santiago Miahuatlán",
      "Huehuetlán el Grande",
      "Santo Tomás Hueyotlipan",
      "Soltepec",
      "Tecali de Herrera",
      "Tecamachalco",
      "Tecomatlán",
      "Tehuacán",
      "Tehuitzingo",
      "Tenampulco",
      "Teopantlán",
      "Teotlalco",
      "Tepanco de López",
      "Tepango de Rodríguez",
      "Tepatlaxco de Hidalgo",
      "Tepeaca",
      "Tepemaxalco",
      "Tepeojuma",
      "Tepetzintla",
      "Tepexco",
      "Tepexi de Rodríguez",
      "Tepeyahualco",
      "Tepeyahualco de Cuauhtémoc",
      "Tetela de Ocampo",
      "Teteles de Avila Castillo",
      "Teziutlán",
      "Tianguismanalco",
      "Tilapa",
      "Tlacotepec de Benito Juárez",
      "Tlacuilotepec",
      "Tlachichuca",
      "Tlahuapan",
      "Tlaltenango",
      "Tlanepantla",
      "Tlaola",
      "Tlapacoya",
      "Tlapanalá",
      "Tlatlauquitepec",
      "Tlaxco",
      "Tochimilco",
      "Tochtepec",
      "Totoltepec de Guerrero",
      "Tulcingo",
      "Tuzamapan de Galeana",
      "Tzicatlacoyan",
      "Venustiano Carranza",
      "Vicente Guerrero",
      "Xayacatlán de Bravo",
      "Xicotepec",
      "Xicotlán",
      "Xiutetelco",
      "Xochiapulco",
      "Xochiltepec",
      "Xochitlán de Vicente Suárez",
      "Xochitlán Todos Santos",
      "Yaonáhuac",
      "Yehualtepec",
      "Zacapala",
      "Zacapoaxtla",
      "Zacatlán",
      "Zapotitlán",
      "Zapotitlán de Méndez",
      "Zaragoza",
      "Zautla",
      "Zihuateutla",
      "Zinacatepec",
      "Zongozotla",
      "Zoquiapan",
      "Zoquitlán",
    ],
    "Querétaro Arteaga": [
      "Amealco de Bonfil",
      "Arroyo Seco",
      "Cadereyta de Montes",
      "Colón",
      "Corregidora",
      "El Marqués",
      "Ezequiel Montes",
      "Huimilpan",
      "Jalpan de Serra",
      "Landa de Matamoros",
      "Pedro Escobedo",
      "Peñamiller",
      "Pinal de Amoles",
      "Querétaro",
      "San Joaquín",
      "San Juan del Río",
      "Tequisquiapan",
      "Tolimán",
    ],
    "Quintana Roo": [
      "Bacalar",
      "Benito Juárez",
      "Cozumel",
      "Felipe Carrillo Puerto",
      "Isla Mujeres",
      "José María Morelos",
      "Lázaro Cárdenas",
      "Othón P Blanco",
      "Puerto Morelos",
      "Solidaridad",
      "Tulum",
    ],
    "San Luis Potosí": [
      "Ahualulco",
      "Alaquines",
      "Aquismón",
      "Armadillo de los Infante",
      "Axtla de Terrazas",
      "Cárdenas",
      "Catorce",
      "Cedral",
      "Cerritos",
      "Cerro de San Pedro",
      "Charcas",
      "Ciudad del Maíz",
      "Ciudad Fernández",
      "Ciudad Valles",
      "Coxcatlán",
      "Ebano",
      "El Naranjo",
      "Guadalcázar",
      "Huehuetlán",
      "Lagunillas",
      "Matehuala",
      "Matlapa",
      "Mexquitic de Carmona",
      "Moctezuma",
      "Rayón",
      "Rioverde",
      "Salinas",
      "San Antonio",
      "San Ciro de Acosta",
      "San Luis Potosí",
      "San Martín Chalchicuautla",
      "San Nicolás Tolentino",
      "San Vicente Tancuayalab",
      "Santa Catarina",
      "Santa María del Río",
      "Santo Domingo",
      "Soledad de Graciano Sánchez",
      "Tamasopo",
      "Tamazunchale",
      "Tampacán",
      "Tampamolón Corona",
      "Tamuín",
      "Tancanhuitz",
      "Tanlajás",
      "Tanquián de Escobedo",
      "Tierra Nueva",
      "Vanegas",
      "Venado",
      "Villa de Arista",
      "Villa de Arriaga",
      "Villa de Guadalupe",
      "Villa de la Paz",
      "Villa de Ramos",
      "Villa de Reyes",
      "Villa Hidalgo",
      "Villa Juárez",
      "Xilitla",
      "Zaragoza",
    ],
    Sinaloa: [
      "Ahome",
      "Angostura",
      "Badiraguato",
      "Choix",
      "Concordia",
      "Cosalá",
      "Culiacán",
      "El Fuerte",
      "Elota",
      "Escuinapa",
      "Guasave",
      "Mazatlán",
      "Mocorito",
      "Navolato",
      "Rosario",
      "Salvador Alvarado",
      "San Ignacio",
      "Sinaloa",
    ],
    Sonora: [
      "Aconchi",
      "Agua Prieta",
      "Alamos",
      "Altar",
      "Arivechi",
      "Arizpe",
      "Atil",
      "Bacadéhuachi",
      "Bacanora",
      "Bacerac",
      "Bacoachi",
      "Bácum",
      "Banámichi",
      "Baviácora",
      "Bavispe",
      "Benito Juárez",
      "Benjamín Hill",
      "Caborca",
      "Cajeme",
      "Cananea",
      "Carbó",
      "Cucurpe",
      "Cumpas",
      "Divisaderos",
      "Empalme",
      "Etchojoa",
      "Fronteras",
      "General Plutarco Elías Calles",
      "Granados",
      "Guaymas",
      "Hermosillo",
      "Huachinera",
      "Huásabas",
      "Huatabampo",
      "Huépac",
      "Imuris",
      "La Colorada",
      "Magdalena",
      "Mazatán",
      "Moctezuma",
      "Naco",
      "Nácori Chico",
      "Nacozari de García",
      "Navojoa",
      "Nogales",
      "Onavas",
      "Opodepe",
      "Oquitoa",
      "Pitiquito",
      "Puerto Peñasco",
      "Quiriego",
      "Rayón",
      "Rosario",
      "Sahuaripa",
      "San Felipe de Jesús",
      "San Ignacio Río Muerto",
      "San Javier",
      "San Luis Río Colorado",
      "San Miguel de Horcasitas",
      "San Pedro de la Cueva",
      "Santa Ana",
      "Santa Cruz",
      "Sáric",
      "Soyopa",
      "Suaqui Grande",
      "Tepache",
      "Trincheras",
      "Tubutama",
      "Ures",
      "Villa Hidalgo",
      "Villa Pesqueira",
      "Yécora",
    ],
    Tabasco: [
      "Balancán",
      "Cárdenas",
      "Centla",
      "Centro",
      "Comalcalco",
      "Cunduacán",
      "Emiliano Zapata",
      "Huimanguillo",
      "Jalapa",
      "Jalpa de Méndez",
      "Jonuta",
      "Macuspana",
      "Nacajuca",
      "Paraíso",
      "Tacotalpa",
      "Teapa",
      "Tenosique",
    ],
    Tamaulipas: [
      "Abasolo",
      "Aldama",
      "Altamira",
      "Antiguo Morelos",
      "Burgos",
      "Bustamante",
      "Camargo",
      "Casas",
      "Ciudad Madero",
      "Cruillas",
      "El Mante",
      "Gómez Farías",
      "González",
      "Güémez",
      "Guerrero",
      "Gustavo Díaz Ordaz",
      "Hidalgo",
      "Jaumave",
      "Jiménez",
      "Llera",
      "Mainero",
      "Matamoros",
      "Méndez",
      "Mier",
      "Miguel Alemán",
      "Miquihuana",
      "Nuevo Laredo",
      "Nuevo Morelos",
      "Ocampo",
      "Padilla",
      "Palmillas",
      "Reynosa",
      "Río Bravo",
      "San Carlos",
      "San Fernando",
      "San Nicolás",
      "Soto la Marina",
      "Tampico",
      "Tula",
      "Valle Hermoso",
      "Victoria",
      "Villagrán",
      "Xicoténcatl",
    ],
    Tlaxcala: [
      "Acuamanala de Miguel Hidalgo",
      "Amaxac de Guerrero",
      "Apetatitlán de Antonio Carvajal",
      "Apizaco",
      "Atlangatepec",
      "Atltzayanca",
      "Benito Juárez",
      "Calpulalpan",
      "Chiautempan",
      "Contla de Juan Cuamatzi",
      "Cuapiaxtla",
      "Cuaxomulco",
      "El Carmen Tequexquitla",
      "Emiliano Zapata",
      "Españita",
      "Huamantla",
      "Hueyotlipan",
      "Ixtacuixtla de Mariano Matamoros",
      "Ixtenco",
      "La Magdalena Tlaltelulco",
      "Lázaro Cárdenas",
      "Mazatecochco de José María Morelos",
      "Muñoz de Domingo Arenas",
      "Nanacamilpa de Mariano Arista",
      "Natívitas",
      "Panotla",
      "Papalotla de Xicohténcatl",
      "San Damián Texóloc",
      "San Francisco Tetlanohcan",
      "San Jerónimo Zacualpan",
      "San José Teacalco",
      "San Juan Huactzinco",
      "San Lorenzo Axocomanitla",
      "San Lucas Tecopilco",
      "San Pablo del Monte",
      "Sanctórum de Lázaro Cárdenas",
      "Santa Ana Nopalucan",
      "Santa Apolonia Teacalco",
      "Santa Catarina Ayometla",
      "Santa Cruz Quilehtla",
      "Santa Cruz Tlaxcala",
      "Santa Isabel Xiloxoxtla",
      "Tenancingo",
      "Teolocholco",
      "Tepetitla de Lardizábal",
      "Tepeyanco",
      "Terrenate",
      "Tetla de la Solidaridad",
      "Tetlatlahuca",
      "Tlaxcala",
      "Tlaxco",
      "Tocatlán",
      "Totolac",
      "Tzompantepec",
      "Xaloztoc",
      "Xaltocan",
      "Xicohtzinco",
      "Yauhquemehcan",
      "Zacatelco",
      "Ziltlaltépec de Trinidad Sánchez Santos",
    ],
    Veracruz: [
      "Acajete",
      "Acatlán",
      "Acayucan",
      "Actopan",
      "Acula",
      "Acultzingo",
      "Agua Dulce",
      "Álamo Temapache",
      "Alpatláhuac",
      "Alto Lucero de Gutiérrez Barrios",
      "Altotonga",
      "Alvarado",
      "Amatitlán",
      "Amatlán de los Reyes",
      "Angel R Cabada",
      "Apazapan",
      "Aquila",
      "Astacinga",
      "Atlahuilco",
      "Atoyac",
      "Atzacan",
      "Atzalan",
      "Ayahualulco",
      "Banderilla",
      "Benito Juárez",
      "Boca del Río",
      "Calcahualco",
      "Camarón de Tejeda",
      "Camerino Z Mendoza",
      "Carlos A Carrillo",
      "Carrillo Puerto",
      "Castillo de Teayo",
      "Catemaco",
      "Cazones de Herrera",
      "Cerro Azul",
      "Chacaltianguis",
      "Chalma",
      "Chiconamel",
      "Chiconquiaco",
      "Chicontepec",
      "Chinameca",
      "Chinampa de Gorostiza",
      "Chocamán",
      "Chontla",
      "Chumatlán",
      "Citlaltépetl",
      "Coacoatzintla",
      "Coahuitlán",
      "Coatepec",
      "Coatzacoalcos",
      "Coatzintla",
      "Coetzala",
      "Colipa",
      "Comapa",
      "Córdoba",
      "Cosamaloapan de Carpio",
      "Cosautlán de Carvajal",
      "Coscomatepec",
      "Cosoleacaque",
      "Cotaxtla",
      "Coxquihui",
      "Coyutla",
      "Cuichapa",
      "Cuitláhuac",
      "El Higo",
      "Emiliano Zapata",
      "Espinal",
      "Filomeno Mata",
      "Fortín",
      "Gutiérrez Zamora",
      "Hidalgotitlán",
      "Huatusco",
      "Huayacocotla",
      "Hueyapan de Ocampo",
      "Huiloapan de Cuauhtémoc",
      "Ignacio de la Llave",
      "Ilamatlán",
      "Isla",
      "Ixcatepec",
      "Ixhuacán de los Reyes",
      "Ixhuatlán de Madero",
      "Ixhuatlán del Café",
      "Ixhuatlán del Sureste",
      "Ixhuatlancillo",
      "Ixmatlahuacan",
      "Ixtaczoquitlán",
      "Jalacingo",
      "Jalcomulco",
      "Jáltipan",
      "Jamapa",
      "Jesús Carranza",
      "Jilotepec",
      "José Azueta",
      "Juan Rodríguez Clara",
      "Juchique de Ferrer",
      "La Antigua",
      "La Perla",
      "Landero y Coss",
      "Las Choapas",
      "Las Minas",
      "Las Vigas de Ramírez",
      "Lerdo de Tejada",
      "Los Reyes",
      "Magdalena",
      "Maltrata",
      "Manlio Fabio Altamirano",
      "Mariano Escobedo",
      "Martínez de la Torre",
      "Mecatlán",
      "Mecayapan",
      "Medellín",
      "Miahuatlán",
      "Minatitlán",
      "Misantla",
      "Mixtla de Altamirano",
      "Moloacán",
      "Nanchital de Lázaro Cárdenas del Río",
      "Naolinco",
      "Naranjal",
      "Naranjos Amatlán",
      "Nautla",
      "Nogales",
      "Oluta",
      "Omealca",
      "Orizaba",
      "Otatitlán",
      "Oteapan",
      "Ozuluama de Mascareñas",
      "Pajapan",
      "Pánuco",
      "Papantla",
      "Paso de Ovejas",
      "Paso del Macho",
      "Perote",
      "Platón Sánchez",
      "Playa Vicente",
      "Poza Rica de Hidalgo",
      "Pueblo Viejo",
      "Puente Nacional",
      "Rafael Delgado",
      "Rafael Lucio",
      "Río Blanco",
      "Saltabarranca",
      "San Andrés Tenejapan",
      "San Andrés Tuxtla",
      "San Juan Evangelista",
      "San Rafael",
      "Santiago Sochiapan",
      "Santiago Tuxtla",
      "Sayula de Alemán",
      "Sochiapa",
      "Soconusco",
      "Soledad Atzompa",
      "Soledad de Doblado",
      "Soteapan",
      "Tamalín",
      "Tamiahua",
      "Tampico Alto",
      "Tancoco",
      "Tantima",
      "Tantoyuca",
      "Tatahuicapan de Juárez",
      "Tatatila",
      "Tecolutla",
      "Tehuipango",
      "Tempoal",
      "Tenampa",
      "Tenochtitlán",
      "Teocelo",
      "Tepatlaxco",
      "Tepetlán",
      "Tepetzintla",
      "Tequila",
      "Texcatepec",
      "Texhuacán",
      "Texistepec",
      "Tezonapa",
      "Tierra Blanca",
      "Tihuatlán",
      "Tlachichilco",
      "Tlacojalpan",
      "Tlacolulan",
      "Tlacotalpan",
      "Tlacotepec de Mejía",
      "Tlalixcoyan",
      "Tlalnelhuayocan",
      "Tlaltetela",
      "Tlapacoyan",
      "Tlaquilpa",
      "Tlilapan",
      "Tomatlán",
      "Tonayán",
      "Totutla",
      "Tres Valles",
      "Tuxpan",
      "Tuxtilla",
      "Ursulo Galván",
      "Uxpanapa",
      "Vega de Alatorre",
      "Veracruz",
      "Villa Aldama",
      "Xalapa",
      "Xico",
      "Xoxocotla",
      "Yanga",
      "Yecuatla",
      "Zacualpan",
      "Zaragoza",
      "Zentla",
      "Zongolica",
      "Zontecomatlán de López y Fuentes",
      "Zozocolco de Hidalgo",
    ],
    Yucatán: [
      "Abalá",
      "Acanceh",
      "Akil",
      "Baca",
      "Bokobá",
      "Buctzotz",
      "Cacalchén",
      "Calotmul",
      "Cansahcab",
      "Cantamayec",
      "Celestún",
      "Cenotillo",
      "Chacsinkín",
      "Chankom",
      "Chapab",
      "Chemax",
      "Chichimilá",
      "Chicxulub Pueblo",
      "Chikindzonot",
      "Chocholá",
      "Chumayel",
      "Conkal",
      "Cuncunul",
      "Cuzamá",
      "Dzán",
      "Dzemul",
      "Dzidzantún",
      "Dzilam de Bravo",
      "Dzilam González",
      "Dzitás",
      "Dzoncauich",
      "Espita",
      "Halachó",
      "Hocabá",
      "Hoctún",
      "Homún",
      "Huhí",
      "Hunucmá",
      "Ixil",
      "Izamal",
      "Kanasín",
      "Kantunil",
      "Kaua",
      "Kinchil",
      "Kopomá",
      "Mama",
      "Maní",
      "Maxcanú",
      "Mayapán",
      "Mérida",
      "Mocochá",
      "Motul",
      "Muna",
      "Muxupip",
      "Opichén",
      "Oxkutzcab",
      "Panabá",
      "Peto",
      "Progreso",
      "Quintana Roo",
      "Río Lagartos",
      "Sacalum",
      "Samahil",
      "San Felipe",
      "Sanahcat",
      "Santa Elena",
      "Seyé",
      "Sinanché",
      "Sotuta",
      "Sucilá",
      "Sudzal",
      "Suma",
      "Tahdziú",
      "Tahmek",
      "Teabo",
      "Tecoh",
      "Tekal de Venegas",
      "Tekantó",
      "Tekax",
      "Tekit",
      "Tekom",
      "Telchac Pueblo",
      "Telchac Puerto",
      "Temax",
      "Temozón",
      "Tepakán",
      "Tetiz",
      "Teya",
      "Ticul",
      "Timucuy",
      "Tinum",
      "Tixcacalcupul",
      "Tixkokob",
      "Tixmehuac",
      "Tixpéhual",
      "Tizimín",
      "Tunkás",
      "Tzucacab",
      "Uayma",
      "Ucú",
      "Umán",
      "Valladolid",
      "Xocchel",
      "Yaxcabá",
      "Yaxkukul",
      "Yobaín",
    ],
    Zacatecas: [
      "Apozol",
      "Apulco ",
      "Atolinga ",
      "Benito Juárez ",
      "Calera",
      "Cañitas de Felipe Pescador",
      "Chalchihuites",
      "Concepción del Oro",
      "Cuauhtémoc",
      "El Plateado de Joaquín Amaro ",
      "El Salvador ",
      "Fresnillo",
      "Genaro Codina",
      "General Enrique Estrada",
      "General Francisco R Murguía",
      "General Pánfilo Natera",
      "Guadalupe",
      "Huanusco ",
      "Jalpa",
      "Jerez",
      "Jiménez del Teul ",
      "Juan Aldama",
      "Juchipila",
      "Loreto",
      "Luis Moya",
      "Mazapil",
      "Melchor Ocampo ",
      "Mezquital del Oro ",
      "Miguel Auza",
      "Momax ",
      "Monte Escobedo",
      "Morelos",
      "Moyahua de Estrada",
      "Nochistlán de Mejía",
      "Noria de Ángeles",
      "Ojocaliente",
      "Pánuco",
      "Pinos",
      "Río Grande",
      "Sain Alto",
      "Santa María de la Paz ",
      "Sombrerete",
      "Susticacán ",
      "Tabasco",
      "Tepechitlán",
      "Tepetongo",
      "Teúl de González Ortega",
      "Tlaltenango de Sánchez Román",
      "Trancoso",
      "Trinidad García de la Cadena ",
      "Valparaíso",
      "Vetagrande",
      "Villa de Cos",
      "Villa García",
      "Villa González Ortega",
      "Villa Hidalgo",
      "Villanueva",
      "Zacatecas",
    ],
  },
  Mozambique: {
    Niassa: [
      "Chimbonila",
      "Cuamba",
      "Lago",
      "Lichinga",
      "Majune",
      "Mandimba",
      "Marrupa",
      "Maúa",
      "Mavago",
      "Mecanhelas",
      "Mecula",
      "Metarica",
      "Muembe",
      "N'gauma",
      "Nipepe",
      "Sanga",
    ],
    "Cabo Delgado": [
      "Ancuabe",
      "Balama",
      "Chiúre",
      "Ibo",
      "Macomia",
      "Mecufi",
      "Meluco",
      "Metuge",
      "Montepuez",
      "Mocimboa da Praia",
      "Namuno",
      "Nangade",
      "Palma",
      "Pemba-Metuge",
      "Quissanga",
    ],
    Nampula: ["Ciudad de Nampula", "Muatala", "Muhala", "Namikopo", "Natikire"],
    "Angoche, ciudad de Angoche": ["Aube", "Namaponda", "Boila-Nametoria"],
    "Namapa-Eráti (Eráti)": ["Alua", "Namiroa"],
    "Isla de Mozambique ": ["Lumbo"],
    Lalaua: ["Meti"],
    Malema: ["Chihulo", "Mutuali"],
    Meconta: ["Corrane", "Namialo"],
    Mecubúri: ["Milhana", "Muite", "Namima"],
    Memba: ["Chipene", "Lurio", "Mazue"],
    Mogincual: ["Naminge", "Quinga", "Chunga", "Quixaxe", "Liupo"],
    "Mogovolas, Nametil, sede": ["Calipo", "Ilute", "Muatua", "Nanhupo Río"],
    "Moma, Macone, sede": ["Chalaua", "Larde", "Mucuali"],
    Monapo: ["Itoculo", "Netia"],
    Mossuril: ["Lunga", "Matibane"],
    Muecate: ["Imala", "Muculuone"],
    Murrupula: ["Chinga", "Nehessine"],
    "Ciudad de Nacala-Puerto": ["Maiaia", "Mutiva", "Muanona"],
    "Nacala-a-Velha": ["Covo"],
    "Nacaroa (Nacarôa)": ["Intete", "Saua-Saua"],
    Ribaué: ["Kunle", "Iapala"],
    Tete: [
      "Angónia",
      "Cahora-Bassa",
      "Changara",
      "Chifunde",
      "Chiuta",
      "Dôa",
      "Macanga",
      "Magoé",
      "Marara",
      "Marávia",
      "Moatize",
      "Mutarara",
      "Tete",
      "Tsangano",
      "Zumbo",
    ],
    Zambezia: [
      "Alto Molócue",
      "Chinde",
      "Gilé",
      "Gurué",
      "Ile",
      "Inhassunge",
      "Lugela",
      "Maganja da Costa",
      "Milange",
      "Mocuba",
      "Mopeia",
      "Morrumbala",
      "Namacurra",
      "Namarroi",
      "Nicoadala",
      "Pebane",
    ],
    Manica: [
      "Báruè",
      "Chimoio",
      "Gondola",
      "Guro",
      "Macate",
      "Machaze",
      "Macossa",
      "Manica",
      "Mossurize",
      "Sussundenga",
      "Tambara",
      "Vanduzi",
    ],
    Sofala: [
      "Beira",
      "Búzi",
      "Caia",
      "Chemba",
      "Cheringoma",
      "Chibabava",
      "Dondo",
      "Gorongosa",
      "Machanga",
      "Maringué",
      "Marromeu",
      "Muanza",
      "Nhamatanda",
    ],
    Gaza: [
      "Bilene Macia",
      "Macia, sede.",
      "Chissano",
      "Mazivila",
      "Messano",
      "Playa de Bilene",
      "Macuane",
    ],
    Chibuto: [
      "Chibuto, sede.",
      "Alto Changane",
      "Chaimite",
      "Changanine",
      "Godide",
      "Malehice",
    ],
    Chicualacuala: ["Mapai", "Pafuri"],
    Chigubo: ["Chigubo", "Ndindiza"],
    Chókwè: ["Ciudad de Chókwè", "Lionde", "Macarretane", "Chilembene"],
    Guijá: ["Canisado", "Chivonguene", "Mubanguene", "Nalazi"],
    Mandlakazi: [
      "Mandlakazi",
      "Chalala",
      "Chibonzane",
      "Chidenguele",
      "Macuacua",
      "Madzucane",
      "Nguzene",
    ],
    Mabalane: ["Mabalane", "Combomune", "Ntlavene"],
    Massangena: ["Massangena", "Mavue"],
    Massingir: ["Massingir", "Mavodze", "Zulo"],
    "Xai-Xai": ["Chicumbane", "Chongoene", "Zonguene", "Ciudad de Xai-Xai"],
    Inhambane: ["Ciudad de Inhambane", "Funhalouro", "Tomé"],
    Govuro: ["Nueva Mambone", "Save"],
    Homoíne: ["Homoíne", "Pembe"],
    Inharrime: ["Inharrime", "Mocumbi"],
    Inhassoro: ["Inhassoro", "Bazaruto"],
    Jangamo: ["Jangamo", "Cumbana"],
    Mabote: ["Mabote", "Zimane", "Zinave"],
    Massinga: ["Massinga", "Chicomo"],
    "Ciudad de Maxixe": ["Morrumbene", "Morrumbene", "Mucodoene"],
    Panda: ["Panda", "Mawayela", "Urrene"],
    Vilanculos: ["Vilankulo", "Mapiñane"],
    Zavala: ["Quissico", "Zandamela"],
    Maputo: [
      "Kamubukwana",
      "KaMaxaquene",
      "KaMavota",
      "KaMubukwana",
      "KaNyaka",
      "KaTembe",
      "Nlhamankulu",
      "Nlhamankulu/Guava",
      "Xefina Pequena",
      "Boane",
      "Magude",
      "Manhiça",
      "Marracuene",
      "Matola",
      "Matutuíne",
      "Moamba",
      "Namaacha",
    ],
    Barrios: [
      "Alto-Maé",
      "Baixa",
      "Bairro Central",
      "Bairro Militar",
      "Costa do Sol",
      "Coop",
      "Ferroviário",
      "Hulene",
      "Jardim",
      "Laulane",
      "Mafalala",
      "Malambe",
      "Mao Tse-Tung",
      "Maxaquene",
      "Museu",
      "Polana",
      "Polana Caniço",
      "Sommerschield",
      "Tsalala",
      "Urbanização",
      "Zimpeto",
      "Boane",
      "Boane",
      "Matola Río",
    ],
    Magude: ["Magude ", "Mapulanguene", "Motaze", "Mahele", "Panjane"],
    Manhiça: [
      "Mañisa, ",
      "Calanga",
      "Isla de Josina Machel",
      "Maluana",
      "Xinavane",
    ],
    Marracuene: ["Marracuene", "Machubo"],
    Missevene: [
      "Missevene, sede Missevene-Bela Vista",
      "Catembe-Nsime",
      "Catuane",
      "Macangulo",
    ],
    Moamba: ["Moamba ", "Pessene", "Ressano García", "Sábiè"],
    Namaacha: ["Namaacha ", "Changalane"],
  },
  "New Zealand": {
    Auckland: ["Auckland"],
    "Bay of Plenty": [
      "Kawerau",
      "Opotiki",
      "Rotorua",
      "Taupo",
      "Tauranga City",
      "Western Bay of Plenty",
      "Whakatane",
    ],
    Canterbury: [
      "Ashburton",
      "Christchurch City",
      "Hurunui",
      "Kaikoura",
      "Mackenzie",
      "Selwyn",
      "Timaru",
      "Waimakariri",
      "Waimate",
      "Waitaki",
    ],
    Gisborne: ["Gisborne"],
    "Hawke's Bay": [
      "Central Hawke's Bay",
      "Hastings",
      "Napier City",
      "Rangitikei",
      "Taupo",
      "Wairoa",
    ],
    "Manawatu-Wanganui": [
      "Horowhenua",
      "Manawatu",
      "Palmerston North City",
      "Rangitikei",
      "Ruapehu",
      "Stratford",
      "Tararua",
      "Taupo",
      "Waitomo",
      "Whanganui",
    ],
    Marlborough: ["Marlborough"],
    Nelson: ["Nelson City"],
    Northland: ["Far North", "Kaipara", "Whangarei"],
    Otago: [
      "Central Otago",
      "Clutha",
      "Dunedin City",
      "Queenstown-Lakes",
      "Waitaki",
    ],
    Southland: ["Gore", "Invercargill City", "Southland"],
    Taranaki: ["New Plymouth", "South Taranaki", "Stratford"],
    Tasman: ["Tasman"],
    Waikato: [
      "Hamilton City",
      "Hauraki",
      "Matamata-Piako",
      "Otorohanga",
      "Rotorua",
      "South Waikato",
      "Taupo",
      "Thames-Coromandel",
      "Waikato",
      "Waipa",
      "Waitomo",
    ],
    Wellington: [
      "Carterton",
      "Kapiti Coast",
      "Lower Hutt City",
      "Masterton",
      "Porirua City",
      "South Wairarapa",
      "Tararua",
      "Upper Hutt City",
      "Wellington City",
    ],
    "West Coast": ["Buller", "Grey", "Westland"],
    "Area Outside Region": [
      "Area Outside Territorial Authority",
      "Chatham Islands Territory",
    ],
  },
  Nicaragua: {
    Boaco: [
      "Boaco",
      "Camoapa",
      "San José de los Remates",
      "San Lorenzo",
      "Santa Lucía",
      "Teustepe",
    ],
    Carazo: [
      "Diriamba",
      "Dolores",
      "El Rosario",
      "Jinotepe",
      "La Conquista",
      "La Paz de Oriente",
      "San Marcos",
      "Santa Teresa",
    ],
    Chinandega: [
      "Chichigalpa",
      "Chinandega",
      "Cinco Pinos",
      "Corinto",
      "El Realejo",
      "El Viejo",
      "Posoltega",
      "Puerto Morazán",
      "San Francisco del Norte",
      "San Pedro del Norte",
      "Santo Tomás del Norte",
      "Somotillo",
      "Villanueva",
    ],
    Chontales: [
      "Acoyapa",
      "Comalapa",
      "Cuapa",
      "El Coral",
      "Juigalpa",
      "La Libertad",
      "San Pedro de Lóvago",
      "Santo Domingo",
      "Santo Tomás",
      "Villa Sandino",
    ],
    "Costa Caribe Norte": [
      "Bonanza",
      "Mulukukú",
      "Prinzapolka",
      "Puerto Cabezas",
      "Rosita",
      "Siuna",
      "Waslala",
      "Waspán",
    ],
    "Costa Caribe Sur": [
      "Bluefields",
      "Corn Island",
      "Desembocadura de Río Grande",
      "El Ayote",
      "El Rama",
      "El Tortuguero",
      "Kukra Hill",
      "La Cruz de Río Grande",
      "Laguna de Perlas",
      "Muelle de los Bueyes",
      "Nueva Guinea",
      "Paiwas",
    ],
    Estelí: [
      "Condega",
      "Estelí",
      "La Trinidad",
      "Pueblo Nuevo",
      "San Juan de Limay",
      "San Nicolás",
    ],
    Granada: ["Diriá", "Diriomo", "Granada", "Nandaime"],
    Jinotega: [
      "El Cuá",
      "Jinotega",
      "La Concordia",
      "San José de Bocay",
      "San Rafael del Norte",
      "San Sebastián de Yalí",
      "Santa María de Pantasma",
      "Wiwilí de Jinotega",
    ],
    León: [
      "Achuapa",
      "El Jicaral",
      "El Sauce",
      "La Paz Centro",
      "Larreynaga",
      "León",
      "Nagarote",
      "Quezalguaque",
      "Santa Rosa del Peñón",
      "Telica",
    ],
    Madriz: [
      "Las Sabanas",
      "Palacagüina",
      "San José de Cusmapa",
      "San Juan de Río Coco",
      "San Lucas",
      "Somoto",
      "Telpaneca",
      "Totogalpa",
      "Yalagüina",
    ],
    Managua: [
      "Ciudad Sandino",
      "El Crucero",
      "Managua",
      "Mateare",
      "San Francisco Libre",
      "San Rafael del Sur",
      "Ticuantepe",
      "Tipitapa",
      "Villa El Carmen",
    ],
    Masaya: [
      "Catarina",
      "La Concepción",
      "Masatepe",
      "Masaya",
      "Nandasmo",
      "Nindirí",
      "Niquinohomo",
      "San Juan de Oriente",
      "Tisma",
    ],
    Matagalpa: [
      "Ciudad Darío",
      "El Tuma - La Dalia",
      "Esquipulas",
      "Matagalpa",
      "Matiguás",
      "Muy Muy",
      "Rancho Grande",
      "Río Blanco",
      "San Dionisio",
      "San Isidro",
      "San Ramón",
      "Sébaco",
      "Terrabona",
    ],
    "Nueva Segovia": [
      "Ciudad Antigua",
      "Dipilto",
      "El Jícaro",
      "Jalapa",
      "Macuelizo",
      "Mozonte",
      "Murra",
      "Ocotal",
      "Quilalí",
      "San Fernando",
      "Santa María",
      "Wiwilí",
    ],
    "Río San Juan": [
      "El Almendro",
      "El Castillo",
      "Morrito",
      "San Carlos",
      "San Juan del Norte",
      "San Miguelito",
    ],
    Rivas: [
      "Altagracia",
      "Belén",
      "Buenos Aires",
      "Cárdenas",
      "Moyogalpa",
      "Potosí",
      "Rivas",
      "San Jorge",
      "San Juan del Sur",
      "Tola",
    ],
  },
  Nigeria: {
    Abia: [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Obi Ngwa",
      "Ohafia",
      "Osisioma Ngwa",
      "Ugwunagbo",
      "Ukwa East",
      "Ukwa West",
      "Umuahia North",
      "Umuahia South",
      "Umu-Nneochi",
    ],
    Adamawa: [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi North",
      "Mubi South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South",
    ],
    "Akwa Ibom": [
      "Abak",
      "Eastern Obolo",
      "Eket",
      "Esit Eket",
      "Essien Udim",
      "Etim Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo Asutan",
      "Ibiono Ibom",
      "Ika",
      "Ikono",
      "Ikot Abasi",
      "Ikot Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat Enin",
      "Nsit Atai",
      "Nsit Ibom",
      "Nsit Ubium",
      "Obot Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung Uko",
      "Ukanafun",
      "Uruan",
      "Urue-Offong/Oruko",
      "Uyo",
    ],
    Anambra: [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili North",
      "Idemili South",
      "Ihiala",
      "Njikoka",
      "Nnewi North",
      "Nnewi South",
      "Ogbaru",
      "Onitsha North",
      "Onitsha South",
      "Orumba North",
      "Orumba South",
      "Oyi",
    ],
    Bauchi: [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas/Gadau",
      "Jama'are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki",
    ],
    Bayelsa: [
      "Brass",
      "Ekeremor",
      "Kolokuma/Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern Ijaw",
      "Yenagoa",
    ],
    Benue: [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer East",
      "Gwer West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Obi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Oturkpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya",
    ],
    Borno: [
      "Abadam",
      "Askira/Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala/Balge",
      "Konduga",
      "Kukawa",
      "Kwaya Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani",
    ],
    "Cross River": [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi [→ Cameroon (2008)]",
      "Bekwara",
      "Biase",
      "Boki",
      "Calabar Municipal",
      "Calabar South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala",
    ],
    Delta: [
      "Aniocha North",
      "Aniocha South",
      "Bomadi",
      "Burutu",
      "Ethiope East",
      "Ethiope West",
      "Ika North East",
      "Ika South",
      "Isoko North",
      "Isoko South",
      "Ndokwa East",
      "Ndokwa West",
      "Okpe",
      "Oshimili North",
      "Oshimili South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli North",
      "Ughelli South",
      "Ukwuani",
      "Uvwie",
      "Warri North",
      "Warri South",
      "Warri South West",
    ],
    Ebonyi: [
      "Abakaliki",
      "Afikpo North",
      "Afikpo South",
      "Ebonyi",
      "Ezza North",
      "Ezza South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaozara",
      "Ohaukwu",
      "Onicha",
    ],
    Edo: [
      "Akoko-Edo",
      "Egor",
      "Esan Central",
      "Esan North East",
      "Esan South East",
      "Esan West",
      "Etsako Central",
      "Etsako East",
      "Etsako West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia North East",
      "Ovia South West",
      "Owan East",
      "Owan West",
      "Uhunmwonde",
    ],
    Ekiti: [
      "Ado Ekiti",
      "Aiyekire (Gbonyin)",
      "Efon",
      "Ekiti East",
      "Ekiti South West",
      "Ekiti West",
      "Emure",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun/Ifelodun",
      "Ise/Orun",
      "Moba",
      "Oye",
    ],
    Enugu: [
      "Aninri",
      "Awgu",
      "Enugu East",
      "Enugu North",
      "Enugu South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze North",
      "Igbo-Eze South",
      "Isi-Uzo",
      "Nkanu East",
      "Nkanu West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani",
    ],
    "Federal Capital Territory (Abuja)": [
      "Abaji",
      "Abuja Municipal Area Council",
      "Bwari",
      "Gwagwalada",
      "Kuje",
      "Kwali",
    ],
    Gombe: [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shomgom",
      "Yamaltu/Deba",
    ],
    Imo: [
      "Abo-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato North",
      "Ideato South",
      "Ihitte/Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji/Egbema",
      "Okigwe",
      "Orlu",
      "Orsu",
      "Oru East",
      "Oru West",
      "Owerri Municipal",
      "Owerri North",
      "Owerri West",
      "Unuimo",
    ],
    Jigawa: [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri Kasama",
      "Kiyawa",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule Tankarkar",
      "Taura",
      "Yankwashi",
    ],
    Kaduna: [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'a",
      "Kachia",
      "Kaduna North",
      "Kaduna South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria",
    ],
    Kano: [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin Kudu",
      "Dawakin Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garum Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun Wada",
      "Ungogo",
      "Warawa",
      "Wudil",
    ],
    Katsina: [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dandume",
      "Danja",
      "Dan Musa",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai'adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango",
    ],
    Kebbi: [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko/Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru",
    ],
    Kogi: [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Bassa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba/Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori/Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Yagba East",
      "Yagba West",
    ],
    Kwara: [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti",
      "Ifelodun",
      "Ilorin East",
      "Ilorin South",
      "Ilorin West",
      "Irepodun",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi",
    ],
    Lagos: [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju/Lekki",
      "Ifako-Ijaye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos Island",
      "Lagos Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
    ],
    Nasarawa: [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Toto",
      "Wamba",
    ],
    Níger: [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Muya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi",
    ],
    Ogun: [
      "Abeokuta North",
      "Abeokuta South",
      "Ado-Odo/Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu East",
      "Ijebu North",
      "Ijebu North East",
      "Ijebu Ode",
      "Ikenne",
      "Imeko Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun Waterside",
      "Remo North",
      "Sagamu",
      "Yewa North (Egbado North)",
      "Yewa South (Egbado South)",
    ],
    Ondo: [
      "Akoko North East",
      "Akoko North West",
      "Akoko South East",
      "Akoko South West",
      "Akure North",
      "Akure South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo East",
      "Ondo West",
      "Ose",
      "Owo",
    ],
    Osun: [
      "Aiyedaade",
      "Aiyedire",
      "Atakunmosa East",
      "Atakunmosa West",
      "Boluwaduro",
      "Boripe",
      "Ede North",
      "Ede South",
      "Egbedore",
      "Ejigbo",
      "Ife Central",
      "Ifedayo",
      "Ife East",
      "Ifelodun",
      "Ife North",
      "Ife South",
      "Ila",
      "Ilesha East",
      "Ilesha West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola-Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo",
    ],
    Oyo: [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North East",
      "Ibadan North West",
      "Ibadan South East",
      "Ibadan South West",
      "Ibarapa Central",
      "Ibarapa East",
      "Ibarapa North",
      "Ido",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogbomosho North",
      "Ogbomosho South",
      "Ogo Oluwa",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori Ire",
      "Oyo East",
      "Oyo West",
      "Saki East",
      "Saki West",
      "Surulere",
    ],
    Plateau: [
      "Barkin Ladi",
      "Bassa",
      "Bokkos",
      "Jos East",
      "Jos North",
      "Jos South",
      "Kanam",
      "Kanke",
      "Langtang North",
      "Langtang South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase",
    ],
    Rivers: [
      "Abua - Odual",
      "Ahoada East",
      "Ahoada West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio/Akpor",
      "Ogba - Egbema - Ndoni",
      "Ogu - Bolo",
      "Okrika",
      "Omumma",
      "Opobo - Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai",
    ],
    Sokoto: [
      "Binji",
      "Bodinga",
      "Dange Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Isa",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto North",
      "Sokoto South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamakko",
      "Wurno",
      "Yabo",
    ],
    Taraba: [
      "Ardo-Kola",
      "Bali",
      "Disputed Areas",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing",
    ],
    Yobe: [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmua",
      "Yunusari",
      "Yusufari",
    ],
    Zamfara: [
      "Anka",
      "Bakura",
      "Birnin Magaji",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Kaura Namoda",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata Mafara",
      "Tsafe",
      "Zurmi",
    ],
  },
  "Noruega / Norway": {
    Agder: [
      "Åmli",
      "Arendal",
      "Åseral",
      "Birkenes",
      "Bygland",
      "Bykle",
      "Evje og Hornnes",
      "Farsund",
      "Flekkefjord",
      "Froland",
      "Gjerstad",
      "Grimstad",
      "Hægebostad",
      "Iveland",
      "Kristiansand",
      "Kvinesdal",
      "Lillesand",
      "Lindesnes",
      "Lyngdal",
      "Risør",
      "Sirdal",
      "Tvedestrand",
      "Valle",
      "Vegårshei",
      "Vennesla",
    ],
    Innlandet: [
      "Alvdal",
      "Åmot",
      "Åsnes",
      "Dovre",
      "Eidskog",
      "Elverum",
      "Engerdal",
      "Etnedal",
      "Folldal",
      "Gausdal",
      "Gjøvik",
      "Gran",
      "Grue",
      "Hamar",
      "Kongsvinger",
      "Lesja",
      "Lillehammer",
      "Lom",
      "Løten",
      "Nord-Aurdal",
      "Nord-Fron",
      "Nord-Odal",
      "Nordre Land",
      "Os",
      "Østre Toten",
      "Øyer",
      "Øystre Slidre",
      "Rendalen",
      "Ringebu",
      "Ringsaker",
      "Sel",
      "Skjåk",
      "Søndre Land",
      "Sør-Aurdal",
      "Sør-Fron",
      "Sør-Odal",
      "Stange",
      "Stor-Elvdal",
      "Tolga",
      "Trysil",
      "Tynset",
      "Vågå",
      "Våler",
      "Vang",
      "Vestre Slidre",
      "Vestre Toten",
    ],
    "Møre og Romsdal": [
      "Ålesund",
      "Aukra",
      "Aure",
      "Averøy",
      "Fjord",
      "Giske",
      "Gjemnes",
      "Hareid",
      "Herøy",
      "Hustadvika",
      "Kristiansund",
      "Molde",
      "Ørsta",
      "Rauma",
      "Sande",
      "Smøla",
      "Stranda",
      "Sula",
      "Sunndal",
      "Surnadal",
      "Sykkylven",
      "Tingvoll",
      "Ulstein",
      "Vanylven",
      "Vestnes",
      "Volda",
    ],
    Nordland: [
      "Alstahaug",
      "Andøy",
      "Beiarn",
      "Bindal",
      "Bø",
      "Bodø",
      "Brønnøy",
      "Dønna",
      "Evenes",
      "Fauske",
      "Flakstad",
      "Gildeskål",
      "Grane",
      "Hadsel",
      "Hamarøy",
      "Hattfjelldal",
      "Hemnes",
      "Herøy",
      "Leirfjord",
      "Lødingen",
      "Lurøy",
      "Meløy",
      "Moskenes",
      "Narvik",
      "Nesna",
      "Øksnes",
      "Rana",
      "Rødøy",
      "Røst",
      "Saltdal",
      "Sømna",
      "Sørfold",
      "Sortland",
      "Steigen",
      "Træna",
      "Værøy",
      "Vågan",
      "Vefsn",
      "Vega",
      "Vestvågøy",
      "Vevelstad",
    ],
    Oslo: ["Oslo"],
    Rogaland: [
      "Bjerkreim",
      "Bokn",
      "Eigersund",
      " Gjesdal",
      " Hå",
      " Haugesund",
      " Hjelmeland",
      " Karmøy",
      " Klepp",
      " Kvitsøy",
      " Lund",
      " Randaberg",
      " Sandnes",
      " Sauda",
      " Sokndal",
      " Sola",
      " Stavanger",
      " Strand",
      " Suldal",
      " Time",
      " Tysvær",
      " Utsira",
      "Vindafjord",
    ],
    "Troms og Finnmark": [
      " Ålesund",
      " Aukra",
      " Aure",
      " Averøy",
      " Fjord",
      " Giske",
      " Gjemnes",
      " Hareid",
      " Herøy",
      " Hustadvika",
      " Kristiansund",
      " Molde",
      " Ørsta",
      " Rauma",
      " Sande",
      " Smøla",
      " Stranda",
      " Sula",
      " Sunndal",
      " Surnadal",
      " Sykkylven",
      " Tingvoll",
      " Ulstein",
      " Vanylven",
      " Vestnes",
      " Volda",
    ],
    Trøndelag: [
      " Åfjord",
      " Flatanger",
      " Frosta",
      " Frøya",
      " Grong",
      " Hitra",
      " Holtålen",
      " Høylandet",
      " Inderøy",
      " Indre Fosen",
      " Leka",
      " Levanger",
      " Lierne",
      " Malvik",
      " Melhus",
      " Meråker",
      " Midtre Gauldal",
      " Nærøysund",
      " Namsos",
      " Namsskogan",
      " Oppdal",
      " Orkland",
      " Ørland",
      " Osen",
      " Overhalla",
      " Rennebu",
      " Rindal",
      " Røros",
      " Røyrvik",
      " Selbu",
      " Skaun",
      " Snåsa",
      " Steinkjer",
      " Stjørdal",
      " Trondheim",
      " Tydal",
      " Verdal",
      "Heim",
    ],
    "Vestfold og Telemark": [
      "Bamble",
      "Drangedal",
      "Færder",
      "Fyresdal",
      "Hjartdal",
      "Holmestrand",
      "Horten",
      "Kragerø",
      "Kviteseid",
      "Larvik",
      "Midt-Telemark",
      "Nissedal",
      "Nome",
      "Notodden",
      "Porsgrunn",
      "Sandefjord",
      "Seljord",
      "Siljan",
      "Skien",
      "Tinn",
      "Tokke",
      "Tønsberg",
      "Vinje",
    ],
    Vestland: [
      " Alver",
      " Årdal",
      " Askøy",
      " Askvoll",
      " Aurland",
      " Austevoll",
      " Austrheim",
      " Bergen",
      " Bjørnafjorden",
      " Bømlo",
      " Bremanger",
      " Eidfjord",
      " Etne",
      " Fedje",
      " Fitjar",
      " Fjaler",
      " Gloppen",
      " Gulen",
      " Høyanger",
      " Hyllestad",
      " Kinn",
      " Kvam",
      " Kvinnherad",
      " Lærdal",
      " Luster",
      " Masfjorden",
      " Modalen",
      " Osterøy",
      " Øygarden",
      " Samnanger",
      " Sogndal",
      " Solund",
      " Stad",
      " Stord",
      " Stryn",
      " Sunnfjord",
      " Sveio",
      " Tysnes",
      " Ullensvang",
      " Ulvik",
      " Vaksdal",
      " Vik",
      " Voss",
    ],
    Viken: [
      "Ål",
      "Aremark",
      "Ås",
      "Asker",
      "Aurskog-Høland",
      "Bærum",
      "Drammen",
      "Eidsvoll",
      "Enebakk",
      "Flå",
      "Flesberg",
      "Fredrikstad",
      "Frogn",
      "Gjerdrum",
      "Gol",
      "Halden",
      "Hemsedal",
      "Hol",
      "Hole",
      "Hurdal",
      "Hvaler",
      "Indre Østfold",
      "Jevnaker",
      "Kongsberg",
      "Krødsherad",
      "Lier",
      "Lillestrøm",
      "Lørenskog",
      "Lunner",
      "Marker",
      "Modum",
      "Moss",
      "Nannestad",
      "Nes",
      "Nesbyen",
      "Nesodden",
      "Nittedal",
      "Nordre Follo",
      "Nore og Uvdal",
      "Øvre Eiker",
      "Råde",
      "Rælingen",
      "Rakkestad",
      "Ringerike",
      "Rollag",
      "Sarpsborg",
      "Sigdal",
      "Skiptvet",
      "Ullensaker",
      "Våler",
      "Vestby",
    ],
  },
  "Países Bajos / Nederland": {
    Drenthe: [
      "Aa en Hunze",
      "Assen",
      "Borger-Odoorn",
      "Coevorden",
      "De Wolden",
      "Emmen",
      "Hoogeveen",
      "Meppel",
      "Midden-Drenthe",
      "Noordenveld",
      "Tynaarlo",
      "Westerveld",
    ],
    Flevoland: [
      "Almere",
      "Dronten",
      "Lelystad",
      "Noordoostpolder",
      "Urk",
      "Zeewolde",
    ],
    Friesland: [
      "Achtkarspelen",
      "Ameland",
      "Dantumadiel",
      "De Fryske Marren",
      "Harlingen",
      "Heerenveen",
      "Leeuwarden",
      "Noardeast-Fryslân",
      "Ooststellingwerf",
      "Opsterland",
      "Schiermonnikoog",
      "Smallingerland",
      "Súdwest-Fryslân",
      "Terschelling",
      "Tytsjerksteradiel",
      "Vlieland",
      "Waadhoeke",
      "Weststellingwerf",
    ],
    Gelderland: [
      "Aalten",
      "Apeldoorn",
      "Arnhem",
      "Barneveld",
      "Berg en Dal",
      "Berkelland",
      "Beuningen",
      "Bronckhorst",
      "Brummen",
      "Buren",
      "Culemborg",
      "Doesburg",
      "Doetinchem",
      "Druten",
      "Duiven",
      "Ede",
      "Elburg",
      "Epe",
      "Ermelo",
      "Harderwijk",
      "Hattem",
      "Heerde",
      "Heumen",
      "Lingewaard",
      "Lochem",
      "Maasdriel",
      "Montferland",
      "Neder-Betuwe",
      "Nijkerk",
      "Nijmegen",
      "Nunspeet",
      "Oldebroek",
      "Oost Gelre",
      "Oude IJsselstreek",
      "Overbetuwe",
      "Putten",
      "Renkum",
      "Rheden",
      "Rozendaal",
      "Scherpenzeel",
      "Tiel",
      "Voorst",
      "Wageningen",
      "West Betuwe",
      "West Maas en Waal",
      "Westervoort",
      "Wijchen",
      "Winterswijk",
      "Zaltbommel",
      "Zevenaar",
      "Zutphen",
    ],
    Groningen: [
      "Eemsdelta",
      "Groningen",
      "Het Hogeland",
      "Midden-Groningen",
      "Oldambt",
      "Pekela",
      "Stadskanaal",
      "Veendam",
      "Westerkwartier",
      "Westerwolde",
    ],
    Limburg: [
      "Beek",
      "Beekdaelen",
      "Beesel",
      "Bergen",
      "Brunssum",
      "Echt-Susteren",
      "Eijsden-Margraten",
      "Gennep",
      "Gulpen-Wittem",
      "Heerlen",
      "Horst aan de Maas",
      "Kerkrade",
      "Landgraaf",
      "Leudal",
      "Maasgouw",
      "Maastricht",
      "Meerssen",
      "Mook en Middelaar",
      "Nederweert",
      "Peel en Maas",
      "Roerdalen",
      "Roermond",
      "Simpelveld",
      "Sittard-Geleen",
      "Stein",
      "Vaals",
      "Valkenburg aan de Geul",
      "Venlo",
      "Venray",
      "Voerendaal",
      "Weert",
    ],
    "Noord-Brabant": [
      "Alphen-Chaam",
      "Altena",
      "Asten",
      "Baarle-Nassau",
      "Bergeijk",
      "Bergen op Zoom",
      "Bernheze",
      "Best",
      "Bladel",
      "Boekel",
      "Boxmeer",
      "Boxtel",
      "Breda",
      "Cranendonck",
      "Cuijk",
      "Deurne",
      "Dongen",
      "Drimmelen",
      "Eersel",
      "Eindhoven",
      "Etten-Leur",
      "Geertruidenberg",
      "Geldrop-Mierlo",
      "Gemert-Bakel",
      "Gilze en Rijen",
      "Goirle",
      "Grave",
      "Halderberge",
      "Heeze-Leende",
      "Helmond",
      "Heusden",
      "Hilvarenbeek",
      "Laarbeek",
      "Landerd",
      "Loon op Zand",
      "Meierijstad",
      "Mill en Sint Hubert",
      "Moerdijk",
      "Nuenen, Gerwen en Nederwetten",
      "Oirschot",
      "Oisterwijk",
      "Oosterhout",
      "Oss",
      "Reusel-De Mierden",
      "Roosendaal",
      "Rucphen",
      "'s-Hertogenbosch",
      "Sint Anthonis",
      "Sint-Michielsgestel",
      "Someren",
      "Son en Breugel",
      "Steenbergen",
      "Tilburg",
      "Uden",
      "Valkenswaard",
      "Veldhoven",
      "Vught",
      "Waalre",
      "Waalwijk",
      "Woensdrecht",
      "Zundert",
    ],
    "Noord-Holland": [
      "Aalsmeer",
      "Alkmaar",
      "Amstelveen",
      "Amsterdam",
      "Beemster",
      "Bergen",
      "Beverwijk",
      "Blaricum",
      "Bloemendaal",
      "Castricum",
      "Den Helder",
      "Diemen",
      "Drechterland",
      "Edam-Volendam",
      "Enkhuizen",
      "Gooise Meren",
      "Haarlem",
      "Haarlemmermeer",
      "Heemskerk",
      "Heemstede",
      "Heerhugowaard",
      "Heiloo",
      "Hilversum",
      "Hollands Kroon",
      "Hoorn",
      "Huizen",
      "Koggenland",
      "Landsmeer",
      "Langedijk",
      "Laren",
      "Medemblik",
      "Oostzaan",
      "Opmeer",
      "Ouder-Amstel",
      "Purmerend",
      "Schagen",
      "Stede Broec",
      "Texel",
      "Uitgeest",
      "Uithoorn",
      "Velsen",
      "Waterland",
      "Weesp",
      "Wijdemeren",
      "Wormerland",
      "Zaanstad",
      "Zandvoort",
    ],
    Overijssel: [
      "Almelo",
      "Borne",
      "Dalfsen",
      "Deventer",
      "Dinkelland",
      "Enschede",
      "Haaksbergen",
      "Hardenberg",
      "Hellendoorn",
      "Hengelo",
      "Hof van Twente",
      "Kampen",
      "Losser",
      "Oldenzaal",
      "Olst-Wijhe",
      "Ommen",
      "Raalte",
      "Rijssen-Holten",
      "Staphorst",
      "Steenwijkerland",
      "Tubbergen",
      "Twenterand ",
      "Wierden",
      "Zwartewaterland",
      "Zwolle",
    ],
    Utrecht: [
      "Amersfoort",
      "Baarn",
      "Bunnik",
      "Bunschoten",
      "De Bilt",
      "De Ronde Venen",
      "Eemnes",
      "Houten",
      "IJsselstein",
      "Leusden",
      "Lopik",
      "Montfoort",
      "Nieuwegein",
      "Oudewater",
      "Renswoude",
      "Rhenen",
      "Soest",
      "Stichtse Vecht",
      "Utrecht",
      "Utrechtse Heuvelrug",
      "Veenendaal",
      "Vijfheerenlanden",
      "Wijk bij Duurstede",
      "Woerden",
      "Woudenberg",
      "Zeist",
    ],
    Zeeland: [
      "Borsele",
      "Goes",
      "Hulst",
      "Kapelle",
      "Middelburg",
      "Noord-Beveland",
      "Reimerswaal",
      "Schouwen-Duiveland",
      "Sluis",
      "Terneuzen",
      "Tholen",
      "Veere",
      "Vlissingen",
      "Walcheren",
      "Zuid-Beveland",
    ],
    "Zuid-Holland": [
      "Alblasserdam",
      "Albrandswaard",
      "Alphen aan den Rijn",
      "Barendrecht",
      "Bodegraven-Reeuwijk",
      "Brielle",
      "Capelle aan den IJssel",
      "Delft",
      "Dordrecht",
      "Goeree-Overflakkee",
      "Gorinchem",
      "Gouda",
      "Hardinxveld-Giessendam",
      "Hellevoetsluis",
      "Hendrik-Ido-Ambacht",
      "Hillegom",
      "Hoeksche Waard",
      "Kaag en Braassem",
      "Katwijk",
      "Krimpen aan den IJssel",
      "Krimpenerwaard",
      "Lansingerland",
      "Leiden",
      "Leiderdorp",
      "Leidschendam-Voorburg",
      "Lisse",
      "Maassluis",
      "Midden-Delfland",
      "Molenlanden",
      "Nieuwkoop",
      "Nissewaard",
      "Noordwijk",
      "Oegstgeest",
      "Papendrecht",
      "Pijnacker-Nootdorp",
      "Ridderkerk",
      "Rijswijk",
      "Rotterdam",
      "Schiedam",
      "Sliedrecht",
      "Teylingen",
      "The Hague",
      "Vlaardingen",
      "Voorschoten",
      "Waddinxveen",
      "Wassenaar",
      "Westland",
      "Westvoorne",
      "Zoetermeer",
      "Zoeterwoude",
      "Zuidplas",
      "Zwijndrecht",
    ],
  },
  Panamá: {
    "Bocas del Toro": [
      "Almirante",
      "Bocas del Toro",
      "Changuinola",
      "Chiriquí Grande",
    ],
    Chiriquí: [
      "Alanje",
      "Barú",
      "Boquerón",
      "Boquete",
      "Bugaba",
      "David",
      "Dolega",
      "Gualaca",
      "Remedios",
      "Renacimiento",
      "San Félix",
      "San Lorenzo",
      "Tierras Altas",
      "Tolé",
    ],
    Coclé: ["Aguadulce", "Antón", "Colón", "Donoso", "La Pintada", "Natá"],
    Colón: [
      "Nuevo Chagres",
      "Olá",
      "Omar Torrijos Herrera",
      "Penonomé",
      "Portobelo",
      "Santa Isabel",
    ],
    Darién: ["Chepigana", "Pinogana", "Santa Fe", "Guna de Wargandí"],
    "Emberá-Wounaan": ["Cémaco", "Sambú"],
    "Guna Yala": ["Ailigandí", "Narganá", "Puerto Obaldía", "Tubuala"],
    Herrera: [
      "Chitré",
      "Las Minas",
      "Los Pozos",
      "Ocú",
      "Parita",
      "Pesé",
      "Santa María",
    ],
    "Kuna de Madugandí": ["Kuna de Madugandí"],
    "Kuna de Wargandí": ["Kuna de Wargandí"],
    "Los Santos": [
      "Guararé",
      "Las Tablas",
      "Los Santos",
      "Macaracas",
      "Pedasí",
      "Pocrí",
      "Tonosí",
    ],
    "Ngäbe-Buglé": [
      "Besikó",
      "Jirondai",
      "Kankintú",
      "Kusapín",
      "Mironó",
      "Müna",
      "Nole Duima",
      "Ñürüm",
      "Santa Catalina o Calovébora",
    ],
    Panamá: ["Balboa", "Chepo", "Chimán", "Panamá", "San Miguelito", "Taboga"],
    "Panamá Oeste": [
      "Arraiján",
      "Capira",
      "Chame",
      "La Chorrera",
      "San Carlos",
    ],
    Veraguas: [
      "Atalaya",
      "Calobre",
      "Cañazas",
      "La Mesa",
      "Las Palmas",
      "Mariato",
      "Montijo",
      "Río de Jesús",
      "San Francisco",
      "Santa Fe",
      "Santiago",
      "Soná",
    ],
  },
  Paraguay: {
    "Alto Paraguay": [
      "Bahía Negra",
      "Capitán Carmelo Peralta",
      "Fuerte Olimpo",
      "Puerto Casado",
    ],
    "Alto Paraná": [
      "Ciudad del Este",
      "Doctor Juan León Mallorquín",
      "Doctor Raúl Peña",
      "Domingo Martínez de Irala",
      "Hernandarias",
      "Iruña",
      "Itakyry",
      "Juan Emilio O'Leary",
      "Los Cedrales",
      "Mbaracayú",
      "Minga Guazú",
      "Minga Porá",
      "Naranjal",
      "Ñacunday",
      "Presidente Franco",
      "San Alberto",
      "San Cristóbal",
      "Santa Fe del Paraná",
      "Santa Rita",
      "Santa Rosa del Monday",
      "Tavapy",
      "Yguazú",
    ],
    Amambay: [
      "Bella Vista",
      "Capitán Bado",
      "Cerro Corá",
      "Karapaí",
      "Pedro Juan Caballero",
      "Zanja Pytá",
    ],
    Asunción: ["Asunción"],
    Boquerón: [
      "Filadelfia",
      "Loma Plata",
      "Mariscal José Félix Estigarribia",
      "Neuland",
    ],
    Caaguazú: [
      "Caaguazú",
      "Carayaó",
      "Coronel Oviedo",
      "Doctor Cecilio Báez",
      "Doctor Juan Eulogio Estigarribia",
      "Doctor Juan Manuel Frutos",
      "José Domingo Ocampos",
      "La Pastora",
      "Mariscal Francisco Solano López",
      "Nueva Londres",
      "Nueva Toledo",
      "Raúl Arsenio Oviedo",
      "Regimiento de Infantería Tres Corrales",
      "Repatriación",
      "San Joaquín",
      "San José de los Arroyos",
      "Santa Rosa del Mbutuy",
      "Simón Bolívar",
      "Tembiaporá",
      "Tres de Febrero",
      "Vaquería",
      "Yhú",
    ],
    Caazapá: [
      "Abaí",
      "Buena Vista",
      "Caazapá",
      "Doctor Moisés S. Bertoni",
      "Fulgencio Yegros",
      "General Higinio Morínigo",
      "Maciel",
      "San Juan Nepomuceno",
      "Tavaí",
      "Tres de Mayo",
      "Yuty",
    ],
    Canindeyú: [
      "Corpus Christi",
      "Curuguaty",
      "General Francisco Caballero Álvarez",
      "Itanará",
      "Katueté",
      "La Paloma del Espíritu Santo",
      "Laurel",
      "Maracaná",
      "Nueva Esperanza",
      "Puerto Adela",
      "Salto del Guairá",
      "Villa Ygatimí",
      "Yasy Cañy",
      "Yby Pytá",
      "Ybyrarobaná",
      "Ypejhú",
    ],
    Central: [
      "Areguá",
      "Capiatá",
      "Fernando de la Mora",
      "Guarambaré",
      "Itá",
      "Itauguá",
      "Saldívar",
      "Lambaré",
      "Limpio",
      "Luque",
      "Mariano Roque Alonso",
      "Nueva Italia",
      "Ñemby",
      "San Antonio",
      "San Lorenzo",
      "Villa Elisa",
      "Villeta",
      "Ypacaraí",
      "Ypané",
    ],
    Concepción: [
      "Arroyito",
      "Azotey",
      "Belén",
      "Concepciòn",
      "Horqueta",
      "Loreto",
      "Paso Barreto",
      "San Alfredo",
      "San Carlos del Apa",
      "San Lázaro",
      "Sargento José Félix López",
      "Yby Yaú",
      "Paso Horqueta",
      "Itacuá4​",
    ],
    Cordillera: [
      "Altos",
      "Arroyos y Esteros",
      "Atyrá",
      "Caacupé",
      "Caraguatay",
      "Emboscada",
      "Eusebio Ayala",
      "Isla Pucú",
      "Itacurubí de la Cordillera",
      "Juan de Mena",
      "Loma Grande",
      "Mbocayaty del Yhaguy",
      "Nueva Colombia",
      "Piribebuy",
      "Primero de Marzo",
      "San Bernardino",
      "San José Obrero",
      "Santa Elena",
      "Tobatí",
      "Valenzuela",
    ],

    Guairá: [
      "Borja",
      "Capitán Mauricio José Troche",
      "Coronel Martínez",
      "Doctor Bottrell",
      "Félix Pérez Cardozo",
      "General Eugenio A. Garay",
      "Independencia",
      "Itapé",
      "Iturbe",
      "José Fassardi",
      "Mbocayaty",
      "Natalicio Talavera",
      "Ñumí",
      "Paso Yobai",
      "San Salvador",
      "Tebicuary",
      "Villarrica",
      "Yataity",
    ],
    Itapúa: [
      "Alto Verá",
      "Bella Vista",
      "Cambyretá",
      "Capitán Meza",
      "Capitán Miranda",
      "Carlos Antonio López",
      "Carmen del Paraná",
      "Coronel José Félix Bogado",
      "Edelira",
      "Encarnación",
      "Fram",
      "General Artigas",
      "General Delgado",
      "Hohenau",
      "Itapúa Poty",
      "Jesús",
      "José Leandro Oviedo",
      "La Paz",
      "Mayor Julio Dionisio Otaño",
      "Natalio",
      "Nueva Alborada",
      "Obligado",
      "Pirapó",
      "San Cosme y Damián",
      "San Juan del Paraná",
      "San Pedro del Paraná",
      "San Rafael del Paraná",
      "Tomás Romero Pereira",
      "Trinidad",
      "Yatytay",
    ],
    Misiones: [
      "Ayolas",
      "San Ignacio",
      "San Juan Bautista",
      "San Miguel",
      "San Patricio",
      "Santa María",
      "Santa Rosa",
      "Santiago",
      "Villa Florida",
      "Yabebyry",
    ],
    Ñeembucú: [
      "Alberdi",
      "Cerrito",
      "Desmochados",
      "General José Eduvigis Díaz",
      "Guazú Cuá",
      "Humaitá",
      "Isla Umbú",
      "Laureles",
      "Mayor José de Jesús Martínez",
      "Paso de Patria",
      "Pilar",
      "San Juan Bautista del Ñeembucú",
      "Tacuaras",
      "Villa Franca",
      "Villa Oliva",
      "Villalbín",
    ],
    Paraguarí: [
      "Acahay",
      "Caapucú",
      "Carapeguá",
      "Escobar",
      "General Bernardino Caballero",
      "La Colmena",
      "María Antonia",
      "Mbuyapey",
      "Paraguarí",
      "Pirayú",
      "Quiindy",
      "Quyquyhó",
      "San Roque González de Santa Cruz",
      "Sapucai",
      "Tebicuarymí",
      "Yaguarón",
      "Ybycuí",
      "Ybytymí",
    ],
    "Presidente Hayes": [
      "Benjamín Aceval",
      "Campo Aceval",
      "General José María Bruguez",
      "José Falcón",
      "Nanawa",
      "Nueva Asunción6​",
      "Puerto Pinasco",
      "Teniente Esteban Martínez",
      "Teniente Primero Manuel Irala Fernández",
      "Villa Hayes",
    ],
    "San Pedro": [
      "25 de Diciembre",
      "Antequera",
      "Capiibary",
      "Choré",
      "General Elizardo Aquino",
      "General Isidoro Resquín",
      "Guayaibí",
      "Itacurubí del Rosario",
      "Liberación",
      "Lima",
      "Nueva Germania",
      "San Estanislao",
      "San José del Rosario",
      "San Pablo",
      "San Pedro del Ycuamandyyú",
      "San Vicente Pancholo",
      "Santa Rosa del Aguaray",
      "Tacuatí",
      "Unión",
      "Villa del Rosario",
      "Yataity del Norte",
      "Yrybucuá",
    ],
  },
  Perú: {
    Amazonas: [
      "Bagua",
      "Bongará",
      "Chachapoyas",
      "Condorcanqui",
      "Luya",
      "Rodríguez de Mendoza",
      "Utcubamba",
    ],
    Áncash: [
      "Aija",
      "Antonio Raymondi",
      "Asunción",
      "Bolognesi",
      "Carhuaz",
      "Carlos F. Fitzcarrald",
      "Casma",
      "Corongo",
      "Huaraz",
      "Huari",
      "Huarmey",
      "Huaylas",
      "Mariscal Luzuriaga",
      "Ocros",
      "Pallasca",
      "Pomabamba",
      "Recuay",
      "Santa",
      "Sihuas",
      "Yungay",
    ],
    Apurímac: [
      "Abancay",
      "Andahuaylas",
      "Antabamba",
      "Aymaraes",
      "Cotabambas",
      "Chincheros",
      "Grau",
    ],
    Arequipa: [
      "Arequipa",
      "Camaná",
      "Caravelí",
      "Castilla",
      "Caylloma",
      "Condesuyos",
      "Islay",
      "La Unión",
    ],
    Ayacucho: [
      "Huamanga",
      "Cangallo",
      "Huanca Sancos",
      "Huanta",
      "La Mar",
      "Lucanas",
      "Parinacochas",
      "Páucar del Sara Sara",
      "Sucre",
      "Víctor Fajardo",
      "Vilcashuamán",
    ],
    Cajamarca: [
      "Cajamarca",
      "Cajabamba",
      "Celendín",
      "Chota",
      "Contumazá",
      "Cutervo",
      "Hualgayoc",
      "Jaén",
      "San Ignacio",
      "San Marcos",
      "San Miguel",
      "San Pablo",
      "Santa Cruz",
    ],
    Callaonota: [
      "Callao",
      "Bellavista",
      "Carmen de La Legua-Reynoso",
      "La Perla",
      "La Punta",
      "Ventanilla",
      "Mi Perú",
    ],
    Cuzco: [
      "Acomayo",
      "Anta",
      "Calca",
      "Canas",
      "Canchis",
      "Chumbivilcas",
      "Cusco",
      "Espinar",
      "La Convención",
      "Paruro",
      "Paucartambo",
      "Quispicanchi",
      "Urubamba",
    ],
    Huancavelica: [
      "Acobamba",
      "Angaraes",
      "Castrovirreyna",
      "Churcampa",
      "Huancavelica",
      "Huaytará",
      "Tayacaja",
    ],
    Huánuco: [
      "Ambo",
      "Dos de Mayo",
      "Huacaybamba",
      "Huamalíes",
      "Huánuco",
      "Lauricocha",
      "Leoncio Prado",
      "Marañón",
      "Pachitea",
      "Puerto Inca",
      "Yarowilca",
    ],
    Ica: ["Chincha", "Ica", "Nazca", "Palpa", "Pisco"],
    Junín: [
      "Chanchamayo",
      "Chupaca",
      "Concepción",
      "Huancayo",
      "Jauja",
      "Junín",
      "Satipo",
      "Tarma",
      "Yauli",
    ],
    "La Libertad": [
      "Ascope",
      "Bolívar",
      "Chepén",
      "Gran Chimú",
      "Julcán",
      "Otuzco",
      "Pacasmayo",
      "Pataz",
      "Sánchez Carrión",
      "Santiago de Chuco",
      "Trujillo",
      "Virú",
    ],
    Lambayeque: ["Chiclayo", "Ferreñafe", "Lambayeque"],
    Lima: [
      "Barranca",
      "Cajatambo",
      "Canta",
      "Cañete",
      "Huaral",
      "Huarochirí",
      "Huaura",
      "Lima",
      "Oyón",
      "Yauyos",
    ],
    Loreto: [
      "Alto Amazonas",
      "Datem del Marañón",
      "Loreto",
      "Mariscal Ramón Castilla",
      "Maynas",
      "Putumayo",
      "Requena",
      "Ucayali",
    ],
    "Madre de Dios": ["Manu", "Tahuamanu", "Tambopata"],
    Moquegua: ["General Sánchez Cerro", "Ilo", "Mariscal Nieto"],
    Pasco: ["Daniel A. Carrión", "Oxapampa", "Pasco"],
    Piura: [
      "Ayabaca",
      "Huancabamba",
      "Morropón",
      "Paita",
      "Piura",
      "Sechura",
      "Sullana",
      "Talara",
    ],
    Puno: [
      "Azángaro",
      "Carabaya",
      "Chucuito",
      "El Collao",
      "Huancané",
      "Lampa",
      "Melgar",
      "Moho",
      "Puno",
      "San Antonio de Putina",
      "San Román",
      "Sandia",
      "Yunguyo",
    ],
    "San Martín": [
      "Bellavista",
      "El Dorado",
      "Huallaga",
      "Lamas",
      "Mariscal Cáceres",
      "Moyobamba",
      "Picota",
      "Rioja",
      "San Martín",
      "Tocache",
    ],
    Tacna: ["Candarave", "Jorge Basadre", "Tacna", "Tarata"],
    Tumbes: ["Contralmirante Villar", "Tumbes", "Zarumilla"],
    Ucayali: ["Atalaya", "Coronel Portillo", "Padre Abad", "Purús"],
  },
  Portugal: {
    Aveiro: [
      "Águeda",
      "Albergaria-a-Velha",
      "Anadia",
      "Arouca",
      "Aveiro",
      "Castelo de Paiva",
      "Espinho",
      "Estarreja",
      "Ílhavo",
      "Mealhada",
      "Murtosa",
      "Oliveira de Azeméis",
      "Oliveira do Bairro",
      "Ovar",
      "Santa Maria da Feira",
      "São João da Madeira",
      "Sever do Vouga",
      "Vagos",
      "Vale de Cambra",
    ],
    Beja: [
      "Aljustrel",
      "Almodôvar",
      "Alvito",
      "Barrancos",
      "Beja",
      "Castro Verde",
      "Cuba",
      "Ferreira do Alentejo",
      "Mértola",
      "Moura",
      "Odemira",
      "Ourique",
      "Serpa",
      "Vidigueira",
    ],
    Braga: [
      "Amares",
      "Barcelos",
      "Braga",
      "Cabeceiras de Basto",
      "Celorico de Basto",
      "Esposende",
      "Fafe",
      "Guimarães",
      "Póvoa de Lanhoso",
      "Terras de Bouro",
      "Vieira do Minho",
      "Vila Nova de Famalicão",
      "Vila Verde",
      "Vizela",
    ],
    Braganza: [
      "Alfândega da Fé",
      "Braganza",
      "Carrazeda de Ansiães",
      "Freixo de Espada à Cinta",
      "Macedo de Cavaleiros",
      "Miranda de Duero",
      "Mirandela",
      "Mogadouro",
      "Torre de Moncorvo",
      "Vila Flor",
      "Vimioso",
      "Vinhais",
    ],
    Castelo_Branco: [
      "Belmonte",
      "Castelo Branco",
      "Covillana",
      "Fundão",
      "Idanha-a-Nova",
      "Oleiros",
      "Penamacor",
      "Proença-a-Nova",
      "Sertã",
      "Vila de Rei",
      "Vila Velha de Ródão",
    ],
    Coímbra: [
      "Arganil",
      "Cantanhede",
      "Coímbra",
      "Condeixa-a-Nova",
      "Figueira da Foz",
      "Góis",
      "Lousã",
      "Mira",
      "Miranda do Corvo",
      "Montemor-o-Velho",
      "Oliveira do Hospital",
      "Pampilhosa da Serra",
      "Penacova",
      "Penela",
      "Soure",
      "Tábua",
      "Vila Nova de Poiares",
    ],
    Évora: [
      "Alandroal",
      "Arraiolos",
      "Borba",
      "Estremoz",
      "Évora",
      "Montemor-o-Novo",
      "Mora",
      "Mourão",
      "Portel",
      "Redondo",
      "Reguengos de Monsaraz",
      "Vendas Novas",
      "Viana do Alentejo",
      "Vila Viçosa",
    ],
    Faro: [
      "Albufeira",
      "Alcoutim",
      "Aljezur",
      "Castro Marim",
      "Faro",
      "Lagoa",
      "Lagos",
      "Loulé",
      "Monchique",
      "Olhão",
      "Portimão",
      "São Brás de Alportel",
      "Silves",
      "Tavira",
      "Vila do Bispo",
      "Vila Real de Santo António",
    ],
    Guarda: [
      "Região Norte",
      "Duero",
      "Vila Nova de Foz Côa",
      "Região Centro",
      "Beira Interior Norte",
      "Almeida",
      "Celorico da Beira",
      "Figueira de Castelo Rodrigo",
      "Guarda",
      "Manteigas",
      "Mêda",
      "Pinhel",
      "Sabugal",
      "Trancoso",
      "Dão-Lafões",
      "Aguiar da Beira",
      "Sierra de la Estrella",
      "Fornos de Algodres",
      "Gouveia",
      "Seia",
    ],
    Leiría: [
      "Alcobaça",
      "Alvaiázere",
      "Ansião",
      "Batalha",
      "Bombarral",
      "Caldas da Rainha",
      "Castanheira de Pêra",
      "Figueiró dos Vinhos",
      "Leiría",
      "Marinha Grande",
      "Nazaré",
      "Óbidos",
      "Pedrógão Grande",
      "Peniche",
      "Pombal",
      "Porto de Mós",
    ],
    Lisboa: [
      "Alenquer ",
      "Amadora ",
      "Arruda dos Vinhos ",
      "Azambu",
      "Cadaval ",
      "Cascaes ",
      "Lisboa ",
      "Loures ",
      "Lourinhã ",
      "Mafra ",
      "Odivelas ",
      "Oeiras ",
      "Sintra ",
      "Sobral de Monte Agraço ",
      "Torres Vedras",
      "Vila Franca de Xira",
    ],
    Portalegre: [
      "Alto Alentejo",
      "Alentejo Central",
      "Región del Alentejo",
      "Alter do Chão",
      "Arronches",
      "Avis",
      "Campo Maior",
      "Castelo de Vide",
      "Crato",
      "Elvas",
      "Fronteira",
      "Gavião",
      "Marvão",
      "Monforte",
      "Nisa",
      "Ponte de Sor",
      "Portalegre",
      "Sousel",
    ],
    Oporto: [
      "Amarante",
      "Baião",
      "Felgueiras",
      "Gondomar",
      "Lousada",
      "Maia",
      "Marco de Canaveses",
      "Matosinhos",
      "Paços de Ferreira",
      "Paredes",
      "Penafiel",
      "Oporto",
      "Póvoa de Varzim",
      "Santo Tirso",
      "Trofa",
      "Valongo",
      "Vila do Conde",
      "Vila Nova de Gaia",
    ],
    Santarém: [
      "Abrantes",
      "Alcanena",
      "Almeirim",
      "Alpiarça",
      "Benavente",
      "Cartaxo",
      "Chamusca",
      "Constância",
      "Coruche",
      "Entroncamento",
      "Ferreira do Zêzere",
      "Golegã",
      "Mação",
      "Ourém",
      "Rio Maior",
      "Salvaterra de Magos",
      "Santarén",
      "Sardoal",
      "Tomar",
      "Torres Novas",
      "Vila Nova da Barquinha",
    ],
    Setúbal: [
      "Alcácer do Sal",
      "Alcochete",
      "Almada",
      "Barreiro",
      "Grândola",
      "Moita",
      "Montijo",
      "Palmela",
      "Santiago do Cacém",
      "Seixal",
      "Sesimbra",
      "Setúbal",
      "Sines",
    ],
    Viana_do_Castelo: [
      "Arcos de Valdevez",
      "Caminha",
      "Melgazo",
      "Monzón",
      "Paredes de Coura",
      "Ponte da Barca",
      "Ponte de Lima",
      "Valença",
      "Viana do Castelo",
      "Vila Nova de Cerveira",
    ],
    Vila_Real: [
      "Alijó",
      "Boticas",
      "Chaves",
      "Mesão Frio",
      "Mondim de Basto",
      "Montalegre",
      "Murça",
      "Peso da Régua",
      "Ribeira de Pena",
      "Sabrosa",
      "Santa Marta de Penaguião",
      "Valpaços",
      "Vila Pouca de Aguiar",
      "Vila Real",
    ],
    Viseo: [
      "Armamar",
      "Carregal do Sal",
      "Castro Daire",
      "Cinfães",
      "Lamego",
      "Mangualde",
      "Moimenta da Beira",
      "Mortágua",
      "Nelas",
      "Oliveira de Frades",
      "Penalva do Castelo",
      "Penedono",
      "Resende",
      "Santa Comba Dão",
      "São João da Pesqueira",
      "São Pedro do Sul",
      "Sátão",
      "Sernancelhe",
      "Tabuaço",
      "Tarouca",
      "Tondela",
      "Vila Nova de Paiva",
      "Viseo",
      "Vouzela",
    ],
    Azores: [
      "Angra do Heroísmo ",
      "Praia da Vitória",
      "Velas",
      "Calheta ",
      "Horta	",
      "Lagoa",
      "Nordeste",
      "Ponta Delgada",
      "Povoação",
      "Ribeira Grande",
      "Vila Franca do Campo",
      "Lajes das Flores",
      "Santa Cruz das Flores",
      "Lajes do Pico",
      "Madalena",
      "São Roque do Pico",
      "Santa Cruz da Graciosa",
      "Vila do Corvo",
      "Vila do Porto",
    ],
    Madeira: [
      "Calheta",
      "Câmara de Lobos",
      "Funchal",
      "Machico",
      "Ponta do Sol",
      "Porto Moniz",
      "Porto Santo",
      "Ribeira Brava ",
      "Santa Cruz ",
      "Santana",
      "São Vicente ",
    ],
  },
  "Poland / Polonia": {
    "Greater Poland / wielkopolskie": [
      "Poznań",
      "Kalisz",
      "Konin",
      "Leszno",
      "Chodzież",
      "Czarnków",
      "Gniezno",
      "Gostyń",
      "Grodzisk Wielkopolski",
      "Jarocin",
      "Kalisz",
      "Kępno",
      "Koło",
      "Konin",
      "Kościan",
      "Krotoszyn",
      "Leszno",
      "Międzychód",
      "Nowy Tomyśl",
      "Oborniki",
      "Ostrów Wielkopolski",
      "Ostrzeszów",
      "Piła",
      "Pleszew",
      "Poznań",
      "Rawicz",
      "Słupca",
      "Śrem",
      "Środa Wielkopolska",
      "Szamotuły",
      "Turek",
      "Wągrowiec",
      "Wolsztyn",
      "Września",
      "Złotów",
    ],
    "Kuyavia-Pomerania / kujawsko-pomorskie": [
      "Aleksandrów Kujawski",
      "Brodnica",
      "Bydgoszcz",
      "Chełmno",
      "Golub-Dobrzyń",
      "Grudziądz",
      "Inowrocław",
      "Lipno",
      "Mogilno",
      "Nakło nad Notecią",
      "Radziejów",
      "Rypin",
      "Sępólno Krajeńskie",
      "Świecie",
      "Toruń",
      "Tuchola",
      "Wąbrzeźno",
      "Włocławek",
      "Żnin",
    ],
    "Lesser Poland / małopolskie": [
      "Bochnia",
      "Brzesko",
      "Chrzanów",
      "Dąbrowa Tarnowska",
      "Gorlice",
      "Kraków",
      "Kraków",
      "Limanowa",
      "Miechów",
      "Myślenice",
      "Nowy Sącz",
      "Nowy Targ",
      "Olkusz",
      "Oświęcim",
      "Proszowice",
      "Sucha Beskidzka",
      "Tarnów",
      "Tarnów",
      "Wadowice",
      "Wieliczka",
      "Zakopane",
    ],
    "Łódź / łódzkie": [
      "Bełchatów",
      "Brzeziny",
      "Kutno",
      "Łask",
      "Łęczyca",
      "Łódź",
      "Łowicz",
      "Opoczno",
      "Pabianice",
      "Pajęczno",
      "Piotrków Trybunalski",
      "Poddębice",
      "Radomsko",
      "Rawa Mazowiecka",
      "Sieradz",
      "Skierniewice",
      "Tomaszów Mazowiecki",
      "Wieluń",
      "Wieruszów",
      "Zduńska Wola",
      "Zgierz",
    ],
    "Lower Silesia / dolnośląskie": [
      "Bolesławiec",
      "Dzierżoniów",
      "Głogów",
      "Góra",
      "Jawor",
      "Jelenia Góra",
      "Kamienna Góra",
      "Kłodzko",
      "Legnica",
      "Lubań",
      "Lubin",
      "Lwówek Śląski",
      "Milicz",
      "Oława",
      "Oleśnica",
      "Polkowice",
      "Środa Śląska",
      "Strzelin",
      "Świdnica",
      "Trzebnica",
      "Wałbrzych",
      "Wołów",
      "Wrocław",
      "Ząbkowice Śląskie",
      "Zgorzelec",
      "Złotoryja",
    ],
    "Lublin / lubelskie": [
      "Biała Podlaska",
      "Biłgoraj",
      "Chełm",
      "Hrubieszów",
      "Janów Lubelski",
      "Kraśnik",
      "Krasnystaw",
      "Łęczna",
      "Lubartów",
      "Lublin",
      "Łuków",
      "Opole Lubelskie",
      "Parczew",
      "Puławy",
      "Radzyń Podlaski",
      "Ryki",
      "Świdnik",
      "Tomaszów Lubelski",
      "Włodawa",
      "Zamość",
    ],
    "Lubusz / lubuskie": [
      "Gorzów Wielkopolski",
      "Krosno Odrzańskie",
      "Międzyrzecz",
      "Nowa Sól",
      "Słubice",
      "Strzelce Krajeńskie",
      "Sulęcin",
      "Świebodzin",
      "Wschowa",
      "Żagań",
      "Żary",
      "Zielona Góra",
    ],
    "Masovia / mazowieckie": [
      "Białobrzegi",
      "Ciechanów",
      "Garwolin",
      "Gostynin",
      "Grodzisk Mazowiecki",
      "Grójec",
      "Kozienice",
      "Legionowo",
      "Lipsko",
      "Łosice",
      "Maków Mazowiecki",
      "Mińsk Mazowiecki",
      "Mława",
      "Nowy Dwór Mazowiecki",
      "Ostrołęka",
      "Ostrów Mazowiecka",
      "Otwock",
      "Ożarów Mazowiecki",
      "Piaseczno",
      "Płock",
      "Płońsk",
      "Pruszków",
      "Przasnysz",
      "Przysucha",
      "Pułtusk",
      "Radom",
      "Siedlce",
      "Sierpc",
      "Sochaczew",
      "Sokołów Podlaski",
      "Szydłowiec",
      "Węgrów",
      "Wołomin",
      "Wyszków",
      "Żuromin",
      "Zwoleń",
      "Żyrardów",
    ],
    "Opole / opolskie": [
      "Brzeg",
      "Głubczyce",
      "Kędzierzyn-Koźle",
      "Kluczbork",
      "Krapkowice",
      "Namysłów",
      "Nysa",
      "Olesno",
      "Opole",
      "Prudnik",
      "Strzelce Opolskie",
    ],
    "Podlasie / podlaskie": ["Podlasie / podlaskie"],
    "Pomerania / pomorskie": [
      "Bytów",
      "Chojnice",
      "Człuchów",
      "Kartuzy",
      "Kościerzyna",
      "Kwidzyn",
      "Lębork",
      "Malbork",
      "Nowy Dwór Gdański",
      "Pruszcz Gdański",
      "Puck",
      "Słupsk *",
      "Starogard Gdański",
      "Sztum",
      "Tczew",
      "Wejherowo",
    ],
    "Silesia / śląskie": [
      "Będzin",
      "Bielsko-Biała",
      "Bieruń",
      "Bytom",
      "Chorzów",
      "Cieszyn",
      "Częstochowa",
      "Dąbrowa Górnicza",
      "Gliwice",
      "Jastrzębie-Zdrój",
      "Jaworzno",
      "Katowice",
      "Kłobuck",
      "Lubliniec",
      "Mikołów",
      "Mysłowice",
      "Myszków",
      "Piekary Śląskie",
      "Pszczyna",
      "Racibórz",
      "Ruda Śląska",
      "Rybnik",
      "Siemianowice Śląskie",
      "Sosnowiec",
      "Świętochłowice",
      "Tarnowskie Góry",
      "Tychy",
      "Wodzisław Śląski",
      "Zabrze",
      "Zawiercie",
      "Żory",
      "Żywiec",
    ],
    "Subcarpathia / podkarpackie": [
      "Brzozów",
      "Dębica",
      "Jarosław",
      "Jasło",
      "Kolbuszowa",
      "Krosno",
      "Łańcut",
      "Lesko",
      "Leżajsk",
      "Lubaczów",
      "Mielec",
      "Nisko",
      "Przemyśl",
      "Przeworsk",
      "Ropczyce",
      "Rzeszów",
      "Sanok",
      "Stalowa Wola",
      "Strzyżów",
      "Tarnobrzeg",
      "Ustrzyki Dolne",
    ],
    "Świętokrzyskie / świętokrzyskie": [
      "Busko-Zdrój",
      "Jędrzejów",
      "Kazimierza Wielka",
      "Kielce",
      "Końskie",
      "Opatów",
      "Ostrowiec Świętokrzyski",
      "Pińczów",
      "Sandomierz",
      "Skarżysko-Kamienna",
      "Starachowice",
      "Staszów",
      "Włoszczowa",
    ],
    "Warmia-Masuria / warmińsko-mazurskie": [
      "Bartoszyce",
      "Braniewo",
      "Działdowo",
      "Elbląg",
      "Ełk",
      "Giżycko",
      "Gołdap",
      "Iława",
      "Kętrzyn",
      "Lidzbark Warmiński",
      "Mrągowo",
      "Nidzica",
      "Nowe Miasto Lubawskie",
      "Olecko",
      "Olsztyn",
      "Ostróda",
      "Pisz",
      "Szczytno",
      "Węgorzewo",
    ],
    "West Pomerania / zachodniopomorskie": [
      "Białogard",
      "Choszczno",
      "Drawsko Pomorskie",
      "Goleniów",
      "Gryfice",
      "Gryfino",
      "Kamień Pomorski",
      "Kołobrzeg",
      "Koszalin",
      "Łobez",
      "Myślibórz",
      "Police",
      "Pyrzyce",
      "Sławno",
      "Stargard",
      "Świdwin",
      "Szczecinek",
      "Wałcz",
    ],
  },
  "Puerto Rico": {
    Adjuntas: ["Adjuntas"],
    Aguada: ["Aguada"],
    Aguadilla: ["Aguadilla"],
    "Aguas Buenas": ["Aguas Buenas"],
    Aibonito: ["Aibonito"],
    Añasco: ["Añasco"],
    Arecibo: ["Arecibo"],
    Arroyo: ["Arroyo"],
    Barceloneta: ["Barceloneta"],
    Barranquitas: ["Barranquitas"],
    Bayamón: ["Bayamón"],
    "Cabo Rojo": ["Cabo Rojo"],
    Caguas: ["Caguas"],
    Camuy: ["Camuy"],
    Canóvanas: ["Canóvanas"],
    Carolina: ["Carolina"],
    Cataño: ["Cataño"],
    Cayey: ["Cayey"],
    Ceiba: ["Ceiba"],
    Ciales: ["Ciales"],
    Cidra: ["Cidra"],
    Coamo: ["Coamo"],
    Comerío: ["Comerío"],
    Corozal: ["Corozal"],
    Culebra: ["Culebra"],
    Dorado: ["Dorado"],
    Fajardo: ["Fajardo"],
    Florida: ["Florida"],
    Guánica: ["Guánica"],
    Guayama: ["Guayama"],
    Guayanilla: ["Guayanilla"],
    Guaynabo: ["Guaynabo"],
    Gurabo: ["Gurabo"],
    Hatillo: ["Hatillo"],
    Hormigueros: ["Hormigueros"],
    Humacao: ["Humacao"],
    Isabela: ["Isabela"],
    Jayuya: ["Jayuya"],
    "Juana Díaz": ["Juana Díaz"],
    Juncos: ["Juncos"],
    Lajas: ["Lajas"],
    Lares: ["Lares"],
    "Las Marías": ["Las Marías"],
    "Las Piedras": ["Las Piedras"],
    Loíza: ["Loíza"],
    Luquillo: ["Luquillo"],
    Manatí: ["Manatí"],
    Maricao: ["Maricao"],
    Maunabo: ["Maunabo"],
    Mayagüez: ["Mayagüez"],
    Moca: ["Moca"],
    Morovis: ["Morovis"],
    Naguabo: ["Naguabo"],
    Naranjito: ["Naranjito"],
    Orocovis: ["Orocovis"],
    Patillas: ["Patillas"],
    Peñuelas: ["Peñuelas"],
    Ponce: ["Ponce"],
    Quebradillas: ["Quebradillas"],
    Rincón: ["Rincón"],
    "Río Grande": ["Río Grande"],
    "Sabana Grande": ["Sabana Grande"],
    Salinas: ["Salinas"],
    "San Germán": ["San Germán"],
    "San Juan": ["San Juan"],
    "San Lorenzo": ["San Lorenzo"],
    "San Sebastián": ["San Sebastián"],
    "Santa Isabel": ["Santa Isabel"],
    "Toa Alta": ["Toa Alta"],
    "Toa Baja": ["Toa Baja"],
    "Trujillo Alto": ["Trujillo Alto"],
    Utuado: ["Utuado"],
    "Vega Alta": ["Vega Alta"],
    "Vega Baja": ["Vega Baja"],
    Vieques: ["Vieques"],
    Villalba: ["Villalba"],
    Yabucoa: ["Yabucoa"],
    Yauco: ["Yauco"],
  },
  "República Dominicana": {
    Azua: [
      "Azua de Compostela",
      "Estebanía",
      "Guayabal",
      "Hatillo",
      "Las Charcas",
      "Las Yayas de Viajama",
      "Padre Las Casas",
      "Peralta",
      "Pueblo Viejo",
      "Sabana Yegua",
      "Tábara Arriba",
    ],
    Bahoruco: ["Galvan", "Los Rios", "Neiba", "Tamayo", "Villa Jaragua"],
    Barahona: [
      "Cabral",
      "El Peñón",
      "Enriquillo",
      "Fundación",
      "Jaquimeyes",
      "La Ciénaga",
      "Las Salinas",
      "Paraíso",
      "Polo",
      "Santa Cruz de Barahona",
      "Vicente Noble",
    ],
    Dajabón: [
      "Dajabón",
      "El Pino",
      "Loma de Cabrera",
      "Partido",
      "Restauración",
    ],
    "Distrito Nacional": ["Distrito Nacional"],
    Duarte: [
      "Arenoso",
      "Castillo",
      "Eugenio María de Hostos",
      "Las Guáranas",
      "Pimentel",
      "San Francisco de Macorís",
      "Villa Riva",
    ],
    "El Seibo": ["Santa Cruz del Seibo", "Miches"],
    "Elías Piña": [
      "Bánica",
      "Comendador",
      "El llano",
      "Hondo Valle",
      "Juan Santiago",
      "Pedro Santana",
    ],
    Espaillat: [
      "Cayetano Germosén",
      "Gaspar Hernández",
      "Jamao al Norte",
      "Moca",
      "San Víctor",
    ],
    "Hato Mayor": ["El Valle", "Hato Mayor del Rey", "Sabana de la Mar"],
    "Hermanas Mirabal": ["Salcedo", "Tenares", "Villa Tapia"],
    Independencia: [
      "Cristóbal",
      "Duvergé",
      "Jimaní",
      "La Descubierta",
      "Mella",
      "Postrer Río",
    ],
    "La Altagracia": ["Higüey", "San Rafael del Yuma"],
    "La Romana": ["Caleta", "Guaymate", "La Romana", "Villa Hermosa"],
    "La Vega": ["Constanza", "Jarabacoa", "Jima Abajo", "La Vega"],
    "María Trinidad Sánchez": ["Cabrera", "El Factor", "Nagua", "Río San Juan"],
    "Monseñor Nouel": ["Bonao", "Maimón", "Piedra Blanca"],
    "Monte Cristi": [
      "Castañuelas",
      "Guayubín",
      "Las Matas de Santa Cruz",
      "Monte Cristi",
      "Pepillo Salcedo",
      "Villa Vásquez",
    ],
    "Monte Plata": [
      "Monte Plata",
      "Bayaguana",
      "Peralvillo",
      "Sabana Grande de Boyá",
      "Yamasá",
    ],
    Pedernales: ["Pedernales"],
    Peravia: ["Peravia"],
    "Puerto Plata": [
      "Altamira",
      "Guananico",
      "Imbert",
      "Los Hidalgos",
      "Luperón",
      "San Felipe de Puerto Plata",
      "Sosúa",
      "Villa Isabela",
      "Villa Montellano",
    ],
    Samaná: ["Las Terrenas", "Sánchez", "Santa Bárbara de Samaná"],
    "San Cristóbal": [
      "Bajos de Haina",
      "Cambita Garabito",
      "Los Cacaos",
      "Sabana Grande de Palenque",
      "San Cristóbal",
      "San Gregorio de Nigua",
      "Villa Altagracia",
      "Yaguate",
    ],
    "San José de Ocoa": ["Rancho Arriba", "Sabana Larga", "San José de Ocoa"],
    "San Juan": [
      "Bohechío",
      "El Cercado",
      "Juan de Herrera",
      "Las Matas de Farfán",
      "San Juan de la Maguana",
      "Vallejuelo",
    ],
    "San Pedro de Macorís": [
      "Consuelo",
      "Guayacanes",
      "Quisqueya",
      "Ramón Santana",
      "San José de Los Llanos",
      "San Pedro de Macorís",
    ],
    "Sánchez Ramírez": ["Cevicos", "Cotuí", "Fantino", "La Mata"],
    Santiago: [
      "Baitoa",
      "Jánico",
      "Licey al Medio",
      "Puñal",
      "Sabana Iglesia",
      "San José de Las Matas",
      "Santiago",
      "Tamboril",
      "Villa Bisonó",
      "Villa González",
    ],
    "Santiago Rodríguez": [
      "Monción",
      "San Ignacio de Sabaneta",
      "Villa Los Almácigos",
    ],
    "Santo Domingo": [
      "Boca Chica",
      "Los Alcarrizos",
      "Pedro Brand",
      "San Antonio de Guerra",
      "Santo Domingo Este",
      "Santo Domingo Norte",
      "Santo Domingo Oeste",
    ],
    Valverde: ["Mao", "Esperanza", "Laguna Salada"],
  },
  "República Democrática del Congo": {
    Kinsasa: [
      "Funa",
      "Bandalungwa",
      "Bumbu",
      "Kalamu",
      "Kasa-Vubu",
      "Makala",
      "Ngiri-Ngiri",
      "Selembao",
      "Lukunga",
      "Barumbu",
      "La Gombe",
      "Kinsasa",
      "Kintambo",
      "Lingwala",
      "Mont Ngafula",
      "Ngaliema",
      "Mont Amba",
      "Kisenso",
      "Lemba",
      "Limete",
      "Matete",
      "Ngaba",
      "Tshangu​",
      "Kimbanseke",
      "Maluku",
      "Masina",
      "Ndjili",
      "Nsele",
    ],
    "Congo Central": [
      "Bas-Fleuve",
      "Boma",
      "Inga",
      "Lukaya",
      "Kasangulu",
      "Cataratas",
      "Kimpese",
      "Kimvula",
      "Kinzau-Mvuete",
      "Lukula",
      "Luozi",
      "Madimba",
      "Matadi",
      "Mbanza-Ngungu",
      "Muanda",
      "Seke-Banza",
      "Songololo",
      "Tshela",
    ],
    Kwango: ["Feshi", "Kahemba", "Kasongo Lunda", "Kenge", "Popokabaka"],
    Kwilu: [
      "Bagata",
      "Bulungu-Kikwit",
      "Gungu",
      "Idiofa",
      "Mangai",
      "Masi-Manimba",
    ],
    "Mai-Ndombe": [
      " Bolobo",
      " Inongo",
      " Kiri",
      " Kutu",
      " Kwamouth",
      " Mushie",
      " Oshwe",
      " Yumbi",
    ],
    Kasai: ["Dekese", "Ilebo", "Luebo", "Mweka", "Tshikapa", "Tshikapa"],
    "Kasai Central": [
      "Demba",
      "Dibaya",
      "Dimbelenge",
      "Kananga",
      "Kazumba",
      "Luiza",
    ],
    "Kasai Oriental": [
      "Kabeya-Kamwanga",
      "Katanda",
      "Lupatapata",
      "Miabi",
      "Tshilenge",
    ],
    Lomami: ["Kabinda", "Kamiji", "Lubao", "Luilu", "Mwene-Ditu", "Ngandajika"],
    Sankuru: ["Katako-Kombe", "Kole", "Lodja", "Lomela", "Lubefu", "Lusambo"],
    Maniema: ["Kailo", "Kasongo", "Kibombo", "Lubutu", "Pangi"],
    "Kivu del Sur": ["Fizi", "Kabare", "Kalehe", "Mwenga", "Uvira"],
    "Kivu del Norte": [
      "Beni",
      "Lubero",
      "Masisi",
      "Rutshuru",
      "Nyiragongo",
      "Kayna",
      "Karisimbi",
      "Walikale",
      "Masisi",
      "Bikoro",
      "Goma",
      "Butembo",
      "Bunia",
      "Mahagi",
      "Watsa",
      "Bafwasende",
      "Irumu",
      "Rwampara",
      "Béni-ville",
      "Kamango",
      "Kasindi",
      "Kirumba",
      "Manguredjipa",
      "Nobili",
      "Oicha",
      "Pinga",
    ],
    Ituri: ["Aru", "Djugu", "Irumu", "Mahagi", "Mambasa"],
    "Alto Uele": ["Dungu", "Faradje", "Niangara", "Rungu", "Wamba", "Watsa"],
    Tshopo: [
      "Bafwasende",
      "Banalia",
      "Basoko",
      "Isangi",
      "Opala",
      "Ubundu",
      "Yahuma",
    ],
    "Bajo Uele": ["Aketi", "Ango", "Bambesa", "Bondo", "Buta", "Poko"],
    "Ubangi del Norte": ["Businga", "Bosobolo", "Mobayi-Mbongo", "Yakoma"],
    Mongala: ["Bongandanga", "Bumba", "Lisala", "Yambuku"],
    "Ubangi del Sur": ["Budjala", "Gemena", "Kungu", "Libenge"],
    Équateur: [
      "Bikoro",
      "Lukolela",
      "Basankusu",
      "Makanza",
      "Bolomba",
      "Bomongo",
      "Ingende",
    ],
    Tshuapa: ["Befale", "Boende", "Bokungu", "Djolu", "Ikela", "Monkoto"],
    Tanganica: ["Kabalo", "Kalemie", "Kongolo", "Manono", "Moba", "Nyunzu"],
    "Alto Lomami": ["Bukama", "Kabongo", "Kamina", "Kaniama", "Malemba-Nkulu"],
    Lualaba: ["Dilolo", "Kapanga", "Kolwezi", "Lubudi", "Mutshatsha", "Sandoa"],
    "Alto Katanga": [
      "Lubumbashi",
      "Likasi",
      "Kambove",
      "Kasenga",
      "Kipushi",
      "Mitwaba",
      "Pweto",
      "Sakania",
    ],
  },
  Rusia: {
    Central: [
      "Óblast de Belgorod",
      "Óblast de Bryansk",
      "Óblast de Vladímir",
      "Óblast de Vorónezh",
      "Óblast de Ivanovo",
      "Óblast de Kaluga",
      "Óblast de Kostroma",
      "Óblast de Kursk",
      "Óblast de Lipetsk",
      "Moscú",
      "Óblast de Moscú",
      "Óblast de Oriol",
      "Óblast de Riazán",
      "Óblast de Smolensk",
      "Óblast de Tambov",
      "Óblast de Tver",
      "Óblast de Tula",
      "Óblast de Yaroslavl",
    ],
    Sur: [
      "República de Adigueya",
      "Óblast de Astracán",
      "Óblast de Volgogrado",
      "República de Kalmukia",
      "Krai de Krasnodar",
      "República de Crimea",
      "Óblast de Rostov",
      "Sebastopol",
    ],
    Noroeste: [
      "Óblast de Arcángel",
      "Óblast de Vólogda",
      "Óblast de Kaliningrado",
      "República de Carelia",
      "República de Komi",
      "Óblast de Leningrado",
      "Óblast de Múrmansk",
      "Distrito Autónomo de Nenetsia",
      "Óblast de Nóvgorod",
      "Óblast de Pskov",
      "San Petersburgo",
    ],
    "Lejano Oriente": [
      "Óblast de Amur",
      "República de Buriatia",
      "Óblast Autónomo Hebreo",
      "Krai de Zabaikalie",
      "Krai de Kamchatka",
      "Óblast de Magadán",
      "Krai de Primorie",
      "República de Sajá",
      "Óblast de Sajalín",
      "Krai de Jabárovsk",
      "Distrito autónomo de Chukotka",
    ],
    Siberia: [
      "República de Altái",
      "Krai de Altái",
      "Óblast de Irkutsk",
      "Óblast de Kemerovo",
      "Krai de Krasnoyarsk",
      "Óblast de Novosibirsk",
      "Óblast de Omsk",
      "Óblast de Tomsk",
      "República de Tuvá",
      "República de Jakasia",
    ],
    Ural: [
      "Óblast de Kurgán",
      "Óblast de Sverdlovsk",
      "Óblast de Tiumén",
      "Distrito autónomo de Janty-Mansi",
      "Óblast de Cheliábinsk",
      "Distrito autónomo de Yamalia-Nenetsia",
    ],
    Volga: [
      "República de Baskortostán",
      "Óblast de Kirov",
      "República de Mari-El",
      "República de Mordovia",
      "Óblast de Nizhny Novgorod",
      "Óblast de Oremburgo",
      "Óblast de Penza",
      "Krai de Perm",
      "Óblast de Samara",
      "Óblast de Sarátov",
      "República de Tartaristán",
      "República de Udmurtia",
      "Óblast de Uliánovsk",
      "República de Chuvasia",
    ],
    "Cáucaso Norte": [
      "República de Daguestán",
      "República de Ingusetia",
      "República de Kabardia-Balkaria",
      "República de Karacháyevo-Cherkesia",
      "República de Osetia del Norte-Alania",
      "Krai de Stávropol",
      "República de Chechenia",
    ],
  },
  Rumania: {
    Alba: ["Alba Iulia", "Aiud", "Sebeș"],
    Arad: ["Arad", "Ineu", "Lipova"],
    Argeș: ["Pitești", "Mioveni", "Câmpulung"],
    Bacău: ["Bacău", "Onești", "Moinesti"],
    Bihor: ["Oradea", "Deva", "Salonta"],
    Bistrița_Năsăud: ["Bistrița", "Năsăud", "Beclean"],
    Botoșani: ["Botoșani", "Dorohoi", "Săveni"],
    Brașov: ["Brașov", "Sfântu Gheorghe", "Râșnov"],
    Brăila: ["Brăila", "Ianca", "Făurei"],
    Buzău: ["Buzău", "Râmnicu Sărat", "Nehoiu"],
    Caraș_Severin: ["Reșița", "Oravița", "Caransebeș"],
    Călărași: ["Călărași", "Oltenița", "Fundulea"],
    Cluj: ["Cluj-Napoca", "Dej", "Gherla"],
    Constanța: ["Constanța", "Medgidia", "Mangalia"],
    Covasna: ["Sfântu Gheorghe", "Târgu Secuiesc", "Miercurea Ciuc"],
    Dâmbovița: ["Târgoviște", "Moreni", "Fieni"],
    Dolj: ["Craiova", "Bailești", "Bechet"],
    Galați: ["Galați", "Tecuci", "Berești"],
    Giurgiu: ["Giurgiu", "Bolintin Vale", "Mihăilești"],
    Gorj: ["Târgu Jiu", "Motru", "Rovinari"],
    Harghita: ["Miercurea Ciuc", "Băile Tușnad", "Miercurea Nirajului"],
    Hunedoara: ["Deva", "Hunedoara", "Petroșani"],
    alomița: ["Slobozia", "Urziceni", "Fetești"],
    Iași: ["Iași", "Pașcani", "Roman"],
    Ilfov: ["București", "Voluntari", "Buftea"],
    Maramureș: ["Baia Mare", "Sighetu Marmației", "Borșa"],
    Mehedinți: ["Drobeta-Turnu Severin", "Orșova", "Strehaia"],
    Mureș: ["Târgu Mureș", "Sighișoara", "Reghin"],
    Neamț: ["Piatra Neamț", "Roman", "Târgu Neamț"],
    Olt: ["Slatina", "Caracal", "Balș"],
    Prahova: ["Ploiești", "Câmpina", "Sinaia"],
    Satu_Mare: ["Satu Mare", "Carei", "Ardud"],
    Sălaj: ["Zalău", "Șimleu Silvaniei", "Jibou"],
    Sibiu: ["Sibiu", "Mediaș", "Avrig"],
    Suceava: ["Suceava", "Rădăuți", "Câmpulung Moldovenesc"],
    Teleorman: ["Alexandria", "Roșiorii de Vede", "Turnu Măgurele"],
    Timiș: ["Timișoara", "Lugoj", "Sânnicolau Mare"],
    Tulcea: ["Tulcea", "Sulina", "Isaccea"],
    Vâlcea: ["Râmnicu Vâlcea", "Drăgășani", "Băbeni"],
    Vaslui: ["Vaslui", "Bârlad", "Huși"],
    Vrancea: ["Focșani", "Adjud", "Odobești"],
  },
  Senegal: {
    Dakar: ["Dakar", "Guediawaye", "Pikine", "Rufisque"],

    Diourbel: ["Bambey", "Diourbel", "Mbacke", "Ndoulo"],
    Fatick: ["Fatick", "Foundiougne", "Gossas", "Toubacouta"],
    Kaffrine: ["Birkilane", "Kaffrine", "Koungheul", "Malem Hodar"],
    Kaolack: ["Guinguineo", "Kaolack", "Nioro du Rip", "Touba Mosquee"],
    Kedougou: ["Kedougou", "Salemata", "Saraya", "Tambacounda"],
    Kolda: ["Kolda", "Medina Yoro Foulah", "Velingara", "Bounkiling"],
    Louga: ["Kebemer", "Linguere", "Louga", "Thiel"],
    Matam: ["Kanel", "Matam", "Ranerou", "Roudie"],
    "Saint/Louis": ["Dagana", "Podor", "Saint-Louis", "Saint-Louis Nord"],
    Sedhiou: ["Bounkiling", "Goudomp", "Sedhiou", "Tambacounda"],
    Tambacounda: ["Bakel", "Goudiry", "Koumpentoum", "Tambacounda"],
    Bakel: ["Goudiry", "Koumpentoum", "Tambacounda"],
    Thies: ["Mbour", "Tivaouane", "Thies", "Thies Ouest"],
    Ziguinchor: ["Bignona", "Oussouye", "Ziguinchor", "Ziguinchor Nord"],
  },
  Somalia: {
    Awdal: ["Baki", "Borama", "Lughaya", "Zeylac"],
    Bakool: ["Hudur", "Rabdhure"],
    Banaadir: [
      "Abdiaziz",
      "Bondhere",
      "Dharkenley",
      "Hamarweyne",
      "Hodan",
      "Karkaar",
      "Karan",
      "Shangani",
      "Shibis",
      "Shingani",
    ],
    Bari: ["Alula", "Bosaso", "Iskushuban", "Qandala", "Qardho"],
    Galguduud: ["Abudwak", "Adado", "Dhusamareb", "Guriel"],
    Gedo: ["Beled Hawo", "Dolow", "Garbaharey", "Luuq"],
    Hiiraan: ["Beledweyne", "Buloburte", "Jalalaqsi", "Mahas"],
    "Jubbada Dhexe": ["Bu'ale", "Docol", "Jilib", "Sakow"],
    "Jubbada Hoose": ["Afmadow", "Badhaadhe", "Kismayo"],
    Mudug: ["Galkayo", "Harardhere", "Hobyo", "Jariban"],
    Nugaal: ["Garowe", "Eyl", "Qardho"],
    Sanaag: ["Badhan", "Erigavo", "Laasqoray"],
    "Shabelle Dhexe": ["Adan Yabaal", "Balcad", "Jowhar"],
    "Shabelle Hoose": ["Afgooye", "Baraawe", "Kurtunwaarey", "Marka"],
    Sool: ["Aynaba", "Caynaba", "Taleex"],
    Togdheer: ["Burao", "Oodweyne", "Sheikh"],
    "Woqooyi Galbeed": ["Berbera", "Gabiley", "Hargeisa", "Wajaale"],
    Bay: ["Baidoa", "Qasahdhere", "Diinsoor", "Bardale"],
  },
  Sudáfrica: {
    "Eastern Cape": [
      "Alfred Nzo",
      "Amatole",
      "Buffalo City",
      "Chris Hani",
      "Joe Gqabi",
      "Nelson Mandela Bay",
      "OR Tambo",
      "Sarah Baartman",
      "Walter Sisulu",
      "Bhisho",
      "Port Elizabeth",
      "East London",
      "Mthatha",
      "Uitenhage",
      "Grahamstown",
      "King William's Town",
      "Queenstown",
      "Port Alfred",
    ],
    "Free State": [
      "Fezile Dabi",
      "Moqhaka",
      "Ngwathe",
      "Metsimaholo",
      "Mafube",
      " Thabo Mofutsanyane",
      "Maluti un Phofung",
      "Dihlabeng",
      "Setsoto",
      "Nektoana",
      "Phumelela",
      " Motheo",
      "Mangaung",
      "Mantsopa",
      "Naledi",
      " Xhariep",
      "Kopanong",
      "Letsemeng",
      "Mohokare",
      " Lejweleputswa",
      "Matjhabeng",
      "Nala",
      "Masilonyana",
      "Tswelopele",
      "Tokologo",
    ],
    Gauteng: [
      "City of Tshwane",
      "Ekurhuleni",
      "Sedibeng",
      "West Rand",
      "Johannesburg",
      "Alberton",
      "Benoni",
      "Boksburg",
      "Bryanston",
      "Heidelberg",
      "Pretoria",
      "Randburg",
      "Roodepoort",
      "Sandton",
      "Soweto",
      "Springs",
      "Vanderbjilpark",
      "Vereeniging",
    ],
    "KwaZulu-Natal": [
      "Amajuba",
      "Harry Gwala",
      "iLembe",
      "King Cetshwayo",
      "Ugu",
      "uMgungundlovu",
      "uMkhanyakude",
      "Umzinyathi",
      "Uthukela",
      "Uthungulu",
      "Zululand",
      "Pietermaritzburg",
      "Dundee",
      "Empangeni",
      "Estcourt",
      "Glencoe",
      "Howick",
      "Kokstad",
      "Ladysmith",
      "Madadeni",
      "Mpumalanga",
      "Newcastle",
      "Port Shepstone",
      "Richards Bay",
      "Stanger",
      "Tongaat",
      "Ulundi",
      "Vryheid",
      "Ballito",
      "Babanango",
      "Colenso",
      "Eshowe",
      "Greytown",
      "Ingwavuma",
      "Margate",
      "Nongoma",
      "Scottburgh",
      "Umzinto",
    ],
    Limpopo: [
      " Capricornio",
      "Aganang",
      "Blouberg",
      "Lepelle Nkumpi",
      "Mole mole",
      "Polokwane",
      " Mopani",
      "Baphalaborwa",
      "Giovanni",
      "Letaba",
      "Tzaneen",
      " Sekhukhune",
      "Fetakgomo Fetakgomo",
      "Grobersdal Grobersdal",
      "Makhuduthamaga Makhuduthamaga",
      "Marble Hall Marble Hall",
      "Tubatse Tubatse",
      "Vhembe District Vhembe ",
      "Makhado Makhado",
      "Musina Musina",
      "Mutale Mutale",
      "Thulamela Thulamela",
      " Waterberg",
      "Falabella",
      "Lephalale",
      "Modimolle",
      "Mogalakwena",
      "Naboomspruit",
      "Thabazimbi",
    ],
    Mpumalanga: [
      "Ehlanzeni",
      "Gert Sibande",
      "Nkangala",
      "Nelspruit",
      " Ehlanzeni",
      "Bushbruckridge",
      "Mbombela",
      "Nkomazi",
      "Thaba Chweu",
      "Umjindi",
      " Gert Sibande",
      "Albert Luthuli",
      "Dipaleseng",
      "Govan Mbeki",
      "Lekwa",
      "Mkhondo",
      "Msukaligwa",
      "Pixley ka Seme",
      " Nkangala",
      "Delmas",
      "Dr JS Moroka",
      "Emalahleni",
      "Highlands",
      "Steve Tshwete",
      "Thembisile",
    ],
    "North West": [
      "Bojanala Platinum",
      "Dr Ruth Segomotsi Mompati",
      "Ngaka Modiri Molema",
      "Mahikeng",
      " Bojanala Platinum",
      "Moretele",
      "Madibeng",
      "Rustenburg",
      "Kgetlengrivier",
      "Moses Kotane",
      " Dr. Ruth Segomotsi Mompati",
      "Naledi",
      "Mamusa",
      "Greater Taung",
      "Kagisano-Molopo",
      "Lekwa-Teemane",
      " Ngaka Modiri Molema",
      "Ratlou",
      "Tswaing",
      "Mahikeng",
      "Ditsobotla",
      "Ramotshere",
      " Dr. Kenneth Kaunda",
      "Ventersdorp",
      "Tlokwe",
      "Matlosana",
      "Maquassi Hills",
    ],
    "Northern Cape": [
      "Frances Baard",
      "John Taolo Gaetsewe",
      "Namakwa",
      "Pixley ka Seme",
      "ZF Mgcawu",
      "Kimberley",
      "Sol Plaatje ",
      "Kimberley ",
      "Dikgatlong",
      "Magareng",
      "Phokwane",
      "Moshaweng",
      "Ga-Segonyana",
      "Gamagara",
      "Kathu",
      "Richtersveld",
      "Alexander Bay ",
      "Nama Khoi",
      "Vioolsdrif",
      "Springbok",
      "Kamiesberg",
      "Hantam",
      "Karoo Hoogland",
      "Khâi-Ma",
      "Ubuntu (Pixley ka Seme)",
      "Umsobomvu",
      "Emthanjeni",
      "De Aar",
      "Kareeberg",
      "Orania (Sudáfrica)",
      "Renosterberg",
      "Thembelihle",
      "Siyathemba",
      "Siyancuma",
      "Kai !Garib",
      "Augrabies",
      "Dawid Kruiper",
      "Upington",
      "!Kheis",
      "Tsantsabane",
      "Kgatelopele",
    ],
    "Western Cape": [
      "Cape Winelands",
      "Central Karoo",
      "City of Cape Town",
      "Eden",
      "Overberg",
      "West Coast",
      "Cape Town",
      "Matzikama",
      "Cederberg",
      " Bergrivier",
      "Saldanha Bay",
      "Swartland",
      "Witzenberg",
      "Drakenstein",
      " Stellenbosch",
      "Breede Valley",
      "Langeberg",
      "Theewaterskloof",
      "Overstrand",
      "Cape Agulhas",
      "Swellendam",
      "Kannaland ",
      "Hessequa",
      "Mossel Bay ",
      "George ",
      "Oudtshoorn ",
      "Bitou",
      "Knysna",
      "Laingsburg ",
      "Prince Albert",
      "Beaufort West",
    ],
  },
  Sudán: {
    "Al Jazirah": [
      " Al Manaqil",
      " Al Wihda",
      " Balabilah",
      " Ghubaysh",
      " Jammama",
      " Umm Rawaba",
    ],
    "Al Qadarif": [" Al Qadarif", " Gallabat", " Tambura"],
    Kassala: [" Kassala", " Hamashkoraib", " Telkuk", " Halfa Al Jadida"],
    "Mar Rojo": ["Port Sudan", " Sinkat"],
    "Al Khartum": [" Al Khartum", " Omdurman", " Khartum Norte"],
    "Nilo Azul": ["Ed Damazin", " Ad Dindar", " Kurmuk", " Geissan"],
    "Al Bahar al Ahmar": [
      " Al Qutaynah",
      " Ar Rusayris",
      " Atbara",
      " Berber",
      " Abu Hamed",
    ],
    "Al Jazirah al Wusta": [
      " Al Matamma",
      " Abu Zabad",
      " Al Qeili",
      " Yabus",
      " Al Mijlad",
    ],
    "Nahr an Nil": [" Kosti", " Rabak", " Ed Dueim"],
    "Shamal Darfur": ["Al Fashir", " Al Kuma", " Kutum"],
    "Janub Darfur": [" Nyala", " Ed Daein", " Buram"],
    "Gharb Darfur": [" Geneina", " Zalingei", " Mornay"],
    "Janub Kurdufan": [" Kaduqli", " Dilling", " Talodi"],
    "Shamal Kurdufan": [" El Obeid", " Bara", " Umm Ruwaba"],
    Sinnar: [" Sinnar", " Singa", " Abu Hugar"],
    Gedarif: [" Gedarif", " Gallabat", " Qadarif"],
    "An Nil al Abyad": [" Kosti", " El Muglad", " Umm Keddada"],
    "An Nil al Azraq": [" Ed Damazin", " Kurmuk"],
  },
  "Suecia / Sverige": {
    Götaland: [
      "Blekinge",
      "Escania (Skåne län)",
      "Gotland",
      "Halland",
      "Jönköping",
      "Kalmar",
      "Kronoberg",
      "Östergötland",
      "Västra Götaland",
    ],
    Norrland: [
      "Gävleborg",
      "Jämtland",
      "Norrbotten",
      "Västerbotten",
      "Västernorrland",
    ],
    Svealand: [
      "Dalarna",
      "Estocolmo",
      "Örebro",
      "Södermanland o Sörmland",
      "Upsala",
      "Värmland",
      "Västmanland",
    ],
  },
  "Suiza / Switzerland": {
    Aargau: [
      "Aarau",
      "Baden",
      "Bremgarten",
      "Brugg",
      "Kulm",
      "Laufenburg",
      "Lenzburg",
      "Muri",
      "Rheinfelden",
      "Zofingen",
      "Zurzach",
    ],
    "Appenzell Ausserrhoden": ["Hinterland", "Mittelland", "Vorderland"],
    "Appenzell Innerrhoden": [
      "Appenzell",
      "Gonten",
      "Oberegg",
      "Schlatt-Haslen",
      "Schwende-Rüte",
    ],
    "Basel-Landschaft": [
      "Arlesheim",
      "Laufen",
      "Liestal",
      "Sissach",
      "Waldenburg",
    ],
    "Basel-Stadt": ["Basel-Stadt"],
    Bern: [
      "Bern-Mittelland",
      "Biel/Bienne",
      "Emmental",
      "Frutigen-Niedersimmental",
      "Interlaken-Oberhasli",
      "Jura bernois",
      "Oberaargau",
      "Obersimmental-Saanen",
      "Seeland",
      "Thun",
    ],
    Fribourg: [
      "Broye",
      "Glâne",
      "Gruyère",
      "Sarine",
      "See/Lac",
      "Sense",
      "Veveyse",
    ],
    Genève: ["Genève"],
    Glarus: ["Glarus"],
    Graubünden: [
      "Albula",
      "Bernina",
      "Engiadina Bassa/Val Müstair",
      "Imboden",
      "Landquart",
      "Maloja",
      "Moesa",
      "Plessur",
      "Prättigau/Davos",
      "Surselva",
      "Viamala",
    ],
    Jura: ["Delémont", "Porrentruy", "Franches-Montagnes"],
    Lucerne: ["Entlebuch", "Hochdorf", "Luzern", "Sursee", "Willisau"],
    Neuchâtel: [
      "Boudry",
      "La Chaux-de-Fonds",
      "Le Locle",
      "Neuchâtel",
      "Val-de-Ruz",
      "Val-de-Travers",
    ],
    Nidwalden: ["Nidwalden"],
    Obwalden: ["Obwalden"],
    Schaffhausen: [
      "Stein",
      "Schaffhausen",
      "Schleitheim",
      "Oberklettgau",
      "Unterklettgau",
      "Reiat",
    ],
    Schwyz: ["Einsiedeln", "Gersau", "Höfe", "Küssnacht", "March", "Schwyz"],
    Solothurn: [
      "Bucheggberg",
      "Dorneck",
      "Gäu",
      "Gösgen",
      "Lebern",
      "Olten",
      "Solothurn",
      "Thal",
      "Thierstein",
      "Wasseramt",
    ],
    "St. Gallen": [
      "Rheintal",
      "Rorschach",
      "Sarganserland",
      "See-Gaster",
      "St. Gallen",
      "Toggenburg",
      "Werdenberg",
      "Wil",
    ],
    Thurgau: ["Arbon", "Frauenfeld", "Kreuzlingen", "Münchwilen", "Weinfelden"],
    Ticino: [
      "Bellinzona",
      "Blenio",
      "Leventina",
      "Locarno",
      "Lugano",
      "Mendrisio",
      "Riviera",
      "Vallemaggia",
    ],
    Uri: ["Uri"],
    Valais: [
      "Brig",
      "Conthey",
      "Entremont",
      "Goms",
      "Hérens",
      "Leuk",
      "Martigny",
      "Monthey",
      "Saint-Maurice",
      "Sierre",
      "Sion",
      "Visp",
    ],
    Vaud: [
      "Aigle",
      "Broye-Vully",
      "Gros-de-Vaud",
      "Jura-North Vaudois",
      "Lausanne",
      "Lavaux-Oron",
      "Morges",
      "Nyon",
      "Riviera-Pays-d'Enhaut",
      "Ouest Lausannois",
    ],
    Zug: ["Zug"],
    Zürich: [
      "Affoltern",
      "Andelfingen",
      "Bülach",
      "Dielsdorf",
      "Dietikon",
      "Hinwil",
      "Horgen",
      "Meilen",
      "Pfäffikon",
      "Uster",
      "Winterthur",
      "Zürich",
    ],
  },
  Tanzania: {
    Arusha: [
      "Baraa",
      "Elerai",
      "Engutoto",
      "Daraja Mbili",
      "Kaloleni",
      "Kati",
      "Kimandolu",
      "Lemara",
      "Levolosi",
      "Oloirien",
      "Ngarenaro",
      "Sekei",
      "Sokoni",
      "Sombetini",
      "Themi",
      "Unga LTD",
    ],
    "Dar es Salaam": ["Ilala", "Kinondoni", "Temeke", "Kigamboni", "Ubungo"],
    Dodoma: ["Dodoma ", "Dodoma ", "Kondoa", "Mpwapwa", "Chamwino", "Kongwa"],
    Geita: ["Bukombe", "Chato", "Geita", "Mbogwe", "Nyang'hwale"],
    Iringa: ["Iringa ", "Iringa ", "Kilolo", "Mafinga", "Mufindi"],
    Kagera: [
      "Biharamulo",
      "Bukoba ",
      "Bukoba ",
      "Karagwe",
      "Kyerwa",
      "Missenyi",
      "Muleba",
      "Ngara",
    ],
    Katavi: [
      "Mlele",
      "Mpanda ",
      "Mpanda Town",
      "Tanganyika",
      "Kahama Town Council",
      "Urambo",
      "Kongwa",
    ],
    Kigoma: [
      "Buhigwe",
      "Kakonko",
      "Kasulu ",
      "Kasulu Town",
      "Kibondo",
      "Kigoma ",
      "Kigoma-Ujiji ",
      "Uvinza",
    ],
    Kilimanjaro: ["Hai", "Moshi ", "Moshi ", "Mwanga", "Rombo", "Same", "Siha"],
    Lindi: ["Kilwa", "Lindi ", "Lindi ", "Liwale", "Nachingwea", "Ruangwa"],
    Manyara: ["Babati", "Babati ", "Hanang", "Kiteto", "Mbulu", "Simanjiro"],
    Mara: [
      "Bunda",
      "Butiama",
      "Musoma ",
      "Musoma Municipal",
      "Rorya",
      "Serengeti",
      "Tarime",
    ],
    Mbeya: [
      "Busokelo",
      "Chunya",
      "Kyela",
      "Mbarali",
      "Mbeya ",
      "Mbeya a",
      "Rungwe",
    ],
    Morogoro: [
      "Gairo",
      "Kilombero",
      "Kilosa",
      "Morogoro ",
      "Morogoro a",
      "Mvomero",
      "Ulanga",
      "Malinyi",
    ],
    Mtwara: [
      "Masasi",
      "Mtwara ",
      "Mtwara a",
      "Nanyumbu",
      "Newala",
      "Tandahimba",
    ],
    Mwanza: [
      "Ilemela",
      "Kwimba",
      "Magu",
      "Misungwi",
      "Nyamagana",
      "Sengerema",
      "Ukerewe",
      "Njombe",
    ],
    "Pemba del Norte": ["Chake Chake", "Micheweni", "Wete"],
    "Pemba Sur": [
      "Chambani",
      "Mkoani",
      "Mkanyageni",
      "Mjini",
      "Mtambwe",
      "Shengeju",
      "Tumbe",
    ],
    Pwani: [
      "Bagamoyo ",
      "Kibaha ",
      "Kisarawe ",
      "Mafia ",
      "Mkuranga ",
      "Rufiji ",
    ],
    Rukwa: ["Kalambo ", "Nkasi ", "Sumbawanga "],
    Ruvuma: ["Mbinga ", "Songea  ", "Songea  ", "Tunduru "],
    Shinyanga: ["Kahama ", "Kishapu ", "Shinyanga ", "Shinyanga  "],
    Simiyu: ["Bariadi ", "Busega ", "Itilima ", "Maswa ", "Meatu "],
    Singida: [
      "Ikungi ",
      "Iramba ",
      "Manyoni ",
      "Mkalama ",
      "Singida ",
      "Singida  ",
    ],
    Songwe: ["Ileje ", "Mbozi ", "Momba ", "Songwe "],
    Tabora: [
      "Igunga ",
      "Kaliua ",
      "Nzega ",
      "Sikonge ",
      "Tabora ",
      "Urambo ",
      "Uyui ",
    ],
    tanga: [
      "Handeni ",
      "Kilindi ",
      "Korogwe ",
      "Lushoto ",
      "Muheza ",
      "Mkinga ",
      "Pangani Town",
      "Pangani ",
      "Tanga City Council",
    ],
    "Unguja Norte": ["Kaskazini A ", "Kaskazini B ", "Unguja "],
    "Unguja Sur": ["Kati ", "Kusini ", "Unguja "],
    "Zanzíbar Central/Sur": [
      "Kati ",
      "Kusini ",
      "Magharibi ",
      "Mjini ",
      "Unguja West Regional Council",
    ],
  },
  Tailandia: {
    "Chiang Mai": [
      "Mueang Chiang Mai",
      "Chom Thong",
      "Mae Chaem",
      "Chiang Dao",
      "Doi Saket",
      "Mae Taeng",
      "Mae Rim",
      "Samoeng",
      "Fang",
      "Mae Ai",
      "Phrao",
      "San Pa Tong",
      "San Kamphaeng",
      "San Sai",
      "Hang Dong",
      "Hot",
      "Doi Tao",
      "Omkoi",
      "Saraphi",
      "Wiang Haeng",
      "Chai Prakan",
      "Mae Wang",
      "Mae On",
      "Doi Lo",
    ],
    "Chiang Rai": [
      "Mueang Chiang Rai",
      "Wiang Chai",
      "Chiang Khong",
      "Thoeng",
      "Phan",
      "Pa Daet",
      "Mae Chan",
      "Chiang Saen",
      "Mae Sai",
      "Mae Suai",
      "Wiang Pa Pao",
      "Phaya Mengrai",
      "Wiang Kaen",
      "Khun Tan",
      "Mae Fa Luang",
      "Mae Lao",
      "Wiang Chiang Rung",
      "Doi Luang",
    ],
    "Kamphaeng Phet": [
      "Mueang Kamphaeng Phet",
      "Sai Ngam",
      "Khlong Lan",
      "Khanu Woralaksaburi",
      "Khlong Khlung",
      "Phran Kratai",
      "Lan Krabue",
      "Sai Thong Watthana",
      "Pang Sila Thong",
      "Bueng Samakkhi",
      "Kosamphi Nakhon",
    ],
    Lampang: [
      "Mueang Lampang",
      "Mae Mo",
      "Ko Kha",
      "Soem Ngam",
      "Ngao",
      "Chae Hom",
      "Wang Nuea",
      "Thoen",
      "Mae Phrik",
      "Mae Tha",
      "Sop Prap",
      "Hang Chat",
      "Mueang Pan",
    ],
    Lamphun: [
      "Mueang Lamphun",
      "Mae Tha",
      "Ban Hong",
      "Li",
      "Thung Hua Chang",
      "Pa Sang",
      "Ban Thi",
      "Wiang Nong Long",
    ],
    "Mae Hong Son": [
      "Mae Hong Son",
      "Khun Yuam",
      "Pai",
      "Mae Sariang",
      "Mae La Noi",
      "Sop Moei",
      "Pangmapha",
    ],
    "Nakhon Sawan": [
      " Mueang Nakhon Sawan",
      " Krok Phra",
      " Chum Saeng",
      " Nong Bua",
      " Banphot Phisai",
      " Kao Liao",
      " Takhli",
      " Tha Tako",
      " Phaisali",
      " Phayuha Khiri",
      " Lat Yao",
      " Tak Fa",
      " Mae Wong",
      " Mae Poen",
      " Chum Ta Bong",
    ],
    Nan: [
      "Mueang Nan",
      "Mae Charim",
      "Ban Luang",
      "Na Noi",
      "Pua",
      "Tha Wang Pha",
      "Wiang Sa",
      "Thung Chang",
      "Chiang Klang",
      "Na Muen",
      "Santi Suk",
      "Bo Kluea",
      "Song Khwae",
      "Phu Phiang",
      "Chaloem Phra Kiat",
    ],
    Phayao: [
      "Mueang Phayao",
      "Chun",
      "Chiang Kham",
      "Chiang Muan",
      "Dok Khamtai",
      "Pong",
      "Mae Chai",
      "Phu Sang",
      "Phu Kamyao",
    ],
    Phetchabun: [
      "Mueang Phetchabun",
      "Chon Daen",
      "Lom Sak",
      "Lom Kao",
      "Wichian Buri",
      "Si Thep",
      "Nong Phai",
      "Bueng Sam Phan",
      "Nam Nao",
      "Wang Pong",
      "Khao Kho",
    ],
    Phichit: [
      "Mueang Phichit",
      "Wang Sai Phun",
      "Pho Prathap Chang",
      "Taphan Hin",
      "Bang Mun Nak",
      "Pho Thale",
      "Sam Ngam",
      "Tap Khlo",
      "Wachirabarami",
      "Sak Lek",
      "Bueng Na Rang",
      "Dong Charoen",
    ],
    Phitsanulok: [
      "Mueang Phitsanulok",
      "Nakhon Thai",
      "Chat Trakan",
      "Bang Rakam",
      "Bang Krathum",
      "Phrom Phiram",
      "Wat Bot",
      "Wang Thong",
      "Noen Maprang",
    ],
    Phrae: [
      "Den Chai",
      "Long",
      "Muang",
      "Nong Muang Kai",
      "Rong Kwang",
      "Song",
      "Sung Men",
      "Wang Chin",
    ],
    Sukhothai: [
      "Mueang Sukhothai",
      "Ban Dan Lan Hoi",
      "Khiri Mat",
      "Kong Krailat",
      "Si Satchanalai",
      "Si Samrong",
      "Sawankhalok",
      "Si Nakhon",
      "Thung Saliam",
    ],
    Tak: [
      "Mueang Tak",
      "Ban Tak",
      "Sam Ngao",
      "Mae Ramat",
      "Tha Song Yang",
      "Mae Sot",
      "Phop Phra",
      "Umphang",
      "Wang Chao",
    ],
    "Uthai Thani": [
      "Mueang Uthai Thani",
      "Thap Than",
      "Sawang Arom",
      "Nong Chang",
      "Nong Khayang",
      "Ban Rai",
      "Lan Sak",
      "Huai Khot",
    ],
    Uttaradit: [
      "Mueang Uttaradit",
      "Tron",
      "Tha Pla",
      "Nam Pat",
      "Fak Tha",
      "Ban Khok",
      "Phichai",
      "Laplae",
      "Thong Saen Khan",
    ],
    "Amnat Charoen": [
      "Mueang Amnat Charoen",
      "Chanuman",
      "Pathum Ratchawongsa",
      "Phana",
      "Senangkhanikhom",
      "Hua Taphan",
      "Lue Amnat",
    ],
    "Buri Ram": [
      "Mueang Buriram",
      "Khu Mueang",
      "Krasang",
      "Nang Rong",
      "Nong Ki",
      "Lahan Sai",
      "Prakhon Chai",
      "Ban Kruat",
      "Phutthaisong",
      "Lam Plai Mat",
      "Satuek",
      "Pakham",
      "Na Pho",
      "Nong Hong",
      "Phlapphla Chai",
      "Huai Rat",
      "Non Suwan",
      "Chamni",
      "Ban Mai Chaiyaphot",
      "Non Din Daeng",
      "Ban Dan",
      "Khaen Dong",
      "Chaloem Phra Kiat",
    ],
    Chaiyaphum: [
      "Bamnet Narong",
      "Ban Khwao",
      "Ban Thaen",
      "Chaiyaphum",
      "Chatturat",
      "Kaeng Khro",
      "Kaset Sombun",
      "Khon San",
      "Khon Sawan",
      "Noen Sa-nga",
      "Nong Bua Daeng",
      "Nong Bua Rawe",
      "Phakdi Chumphon",
      "Phu Khiao",
      "Sap Yai ",
      "Thep Sathit",
    ],
    Kalasin: [
      "Mueang Kalasin",
      "Na Mon",
      "Kamalasai",
      "Rong Kham",
      "Kuchinarai",
      "Khao Wong",
      "Yang Talat",
      "Huai Mek",
      "Sahatsakhan",
      "Kham Muang",
      "Tha Khantho",
      "Nong Kung Si",
      "Somdet",
      "Huai Phueng",
      "Sam Chai",
      "Na Khu",
      "Don Chan",
      "Khong Chai",
    ],
    "Khon Kaen": [
      "Mueang Khon Kaen",
      "Ban Fang",
      "Phra Yuen",
      "Nong Ruea",
      "Chum Phae",
      "Si Chomphu",
      "Nam Phong",
      "Ubolratana",
      "Kranuan",
      "Ban Phai",
      "Pueai Noi",
      "Phon",
      "Waeng Yai",
      "Waeng Noi",
      "Nong Song Hong",
      "Phu Wiang",
      "Mancha Khiri",
      "Chonnabot",
      "Khao Suan Kwang",
      "Phu Pha Man",
      "Sam Sung",
      "Khok Pho Chai",
      "Nong Na Kham",
      "Ban Haet",
      "Non Sila",
      "Wiang Kao",
    ],
    Loei: [
      "Mueang Loei",
      "Na Duang",
      "Chiang Khan",
      "Pak Chom",
      "Dan Sai",
      "Na Haeo",
      "Phu Ruea",
      "Tha Li",
      "Wang Saphung",
      "Phu Kradueng",
      "Phu Luang",
      "Pha Khao",
      "Erawan",
      "Nong Hin",
    ],
    "Maha Sarakham": [
      "Mueang Maha Sarakham",
      "Kae Dam",
      "Kosum Phisai",
      "Kantharawichai",
      "Chiang Yuen",
      "Borabue",
      "Na Chueak",
      "Phayakkhaphum Phisai",
      "Wapi Pathum",
      "Na Dun",
      "Yang Sisurat",
      "Kut Rang",
      "Chuen Chom",
    ],
    Mukdahan: [
      "Mueang Mukdahan",
      "Nikhom Kham Soi",
      "Don Tan",
      "Dong Luang",
      "Khamcha-i",
      "Wan Yai",
      "Nong Sung",
    ],
    "Nakhon Phanom": [
      "Mueang Nakhon Phanom",
      "Pla Pak",
      "Tha Uthen",
      "Ban Phaeng",
      "That Phanom",
      "Renu Nakhon",
      "Na Kae",
      "Si Songkhram",
      "Na Wa",
      "Phon Sawan",
      "Na Thom",
      "Wang Yang",
    ],
    "Nakhon Ratchasima": [
      "Mueang Nakhon Ratchasima",
      "Khon Buri",
      "Soeng Sang",
      "Khong",
      "Ban Lueam",
      "Chakkarat",
      "Chok Chai",
      "Dan Khun Thot",
      "Non Thai",
      "Non Sung",
      "Kham Sakaesaeng",
      "Bua Yai",
      "Prathai",
      "Pak Thong Chai",
      "Phimai",
      "Huai Thalaeng",
      "Chum Phuang",
      "Sung Noen",
      "Kham Thale So",
      "Sikhio",
      "Pak Chong",
      "Nong Bun Mak",
      "Kaeng Sanam Nang",
      "Non Daeng",
      "Wang Nam Khiao",
      "Chaloem Phra Kiat¹",
      "Thepharak",
      "Mueang Yang",
      "Phra Thong Kham",
      "Lam Thamenchai",
      "Bua Lai",
      "Sida",
    ],
    "Nongbua Lamphu": [
      "Mueang Nongbua Lamphu",
      "Na Klang",
      "Non Sang",
      "Si Bun Rueang",
      "Suwannakhuha",
      "Na Wang",
    ],
    "Nong Khai": [
      "Mueang Nong Khai",
      "Tha Bo",
      "Bueng Kan",
      "Phon Charoen",
      "Phon Phisai",
      "So Phisai",
      "Si Chiang Mai",
      "Sangkhom",
      "Seka",
      "Pak Khat",
      "Bueng Khong Long",
      "Si Wilai",
      "Bung Khla",
      "Sakhrai",
      "Fao Rai",
      "Rattanawapi",
      "Pho Tak",
    ],
    "Roi Et": [
      "Mueang Roi Et",
      "Kaset Wisai",
      "Pathum Rat",
      "Chaturaphak Phiman",
      "Thawat Buri",
      "Phanom Phrai",
      "Phon Thong",
      "Pho Chai",
      "Nong Phok",
      "Selaphum",
      "Suwannaphum",
      "Mueang Suang",
      "Phon Sai",
      "At Samat",
      "Moei Wadi",
      "Si Somdet",
      "Changhan",
      "Chiang Khwan",
      "Nong Hi",
      "Thung Khao Luang",
    ],
    " Sakon Nakhon": [
      "Mueang Sakon Nakhon",
      "Kusuman",
      "Kut Bak",
      "Phanna Nikhom",
      "Phang Khon",
      "Waritchaphum",
      "Nikhom Nam Un",
      "Wanon Niwat",
      "Kham Ta Kla",
      "Ban Muang",
      "Akat Amnuai",
      "Sawang Daen Din",
      "Song Dao",
      "Tao Ngoi",
      "Khok Si Suphan",
      "Charoen Sin",
      "Phon Na Kaeo",
      "Phu Phan",
    ],
    Sisaket: [
      "Mueang Sisaket",
      "Yang Chum Noi",
      "Kanthararom",
      "Kantharalak",
      "Khukhan",
      "Phrai Bueng",
      "Prang Ku",
      "Khun Han",
      "Rasi Salai",
      "Uthumphon Phisai",
      "Bueng Bun",
      "Huai Thap Than",
      "Non Khun",
      "Si Rattana",
      "Nam Kliang",
      "Wang Hin",
      "Phu Sing",
      "Mueang Chan",
      "Benchalak",
      "Phayu",
      "Pho Si Suwan",
      "Sila Lat",
    ],
    Surin: [
      "Mueang Surin",
      "Chumphon Buri",
      "Tha Tum",
      "Chom Phra",
      "Prasat",
      "Kap Choeng",
      "Rattanaburi",
      "Sanom",
      "Sikhoraphum",
      "Sangkha",
      "Lamduan",
      "Samrong Thap",
      "Buachet",
      "Phanom Dong Rak",
      "Si Narong",
      "Khwao Sinarin",
      "Non Narai",
    ],
    " Ubon Ratchathani": [
      "Mueang Ubon Ratchathani",
      "Si Mueang Mai",
      "Khong Chiam",
      "Khueang Nai",
      "Khemarat",
      "Det Udom",
      "Na Chaluai",
      "Nam Yuen",
      "Buntharik",
      "Trakan Phuet Phon",
      "Kut Khaopun",
      "Muang Sam Sip",
      "Warin Chamrap",
      "Phibun Mangsahan",
      "Tan Sum",
      "Pho Sai",
      "Samrong",
      "Don Mot Daeng",
      "Sirindhorn",
      "Thung Si Udom",
      "Na Yia",
      "Na Tan",
      "Lao Suea Kok",
      "Sawang Wirawong",
      "Nam Khun",
    ],
    "Udon Thani": [
      "Mueang Udon Thani",
      "Kut Chap",
      "Nong Wua So",
      "Kumphawapi",
      "Non Sa-at",
      "Nong Han",
      "Thung Fon",
      "Chai Wan",
      "Si That",
      "Wang Sam Mo",
      "Ban Dung",
      "Ban Phue",
      "Nam Som",
      "Phen",
      "Sang Khom",
      "Nong Saeng",
      "Na Yung",
      "Phibun Rak",
      "Ku Kaeo",
      "Prachaksinlapakhom",
    ],
    Yasothon: [
      "Mueang Yasothon",
      "Sai Mun",
      "Kut Chum",
      "Kham Khuean Kaeo",
      "Pa Tio",
      "Maha Chana Chai",
      "Kho Wang",
      "Loeng Nok Tha",
      "Thai Charoen",
    ],
    "Bueng Kan": [
      "Mueang Bueng Kan",
      "Phon Charoen",
      "So Phisai",
      "Seka",
      "Pak Khat",
      "Bueng Khong Long",
      "Si Wilai",
      "Bung Khla",
    ],
    " Ang Thong": [
      "Mueang Ang Thong",
      "Chaiyo",
      "Pa Mok",
      "Pho Thong",
      "Sawaeng Ha",
      "Wiset Chai Chan",
      "Samko",
    ],
    "Phra Nakhon Si Ayutthaya": [
      "Phra Nakhon Si Ayutthaya",
      "Tha Ruea",
      "Nakhon Luang",
      "Bang Sai",
      "Bang Ban",
      "Bang Pa-in",
      "Bang Pahan",
      "Phak Hai",
      "Phachi",
      "Lat Bua Luang",
      "Wang Noi",
      "Sena",
      "Bang Sai ",
      "Uthai",
      "Maha Rat",
      "Ban Phraek",
    ],
    Bangkok: [
      "Phra Nakhon",
      "Dusit",
      "Nong Chok",
      "Bang Rak",
      "Bang Khen",
      "Bang Kapi",
      "Pathum Wan",
      "Pom Prap Sattru Phai",
      "Phra Khanong",
      "Min Buri",
      "Lat Krabang",
      "Yan Nawa",
      "Samphanthawong",
      "Phaya Thai",
      "Thon Buri",
      "Bangkok Yai",
      "Huai Khwang",
      "Khlong San",
      "Taling Chan",
      "Bangkok Noi",
      "Bang Khun Thian",
      "Phasi Charoen",
      "Nong Khaem",
      "Rat Burana",
      "Bang Phlat",
      "Din Dang",
      "Bueng Kum",
      "Sathon",
      "Bang Sue",
      "Chatuchak",
      "Bang Kho Laem",
      "Prawet",
      "Khlong Toei",
      "Suan Luang",
      "Chom Thong",
      "Don Mueang",
      "Ratchathewi",
      "Lat Phrao",
      "Watthana",
      "Bang Khae",
      "Lak Si",
      "Sai Mai",
      "Khan Na Yao",
      "Saphan Sung",
      "Wang Thonglang",
      "Khlong Sam Wa",
      "Bang Na",
      "Thawi Watthana",
      "Thung Khru",
      "Bang Bon",
    ],
    Chainat: [
      "Mueang Chainat",
      "Manorom",
      "Wat Sing",
      "Sapphaya",
      "Sankhaburi",
      "Hankha",
      "Nong Mamong",
      "Noen Kham",
    ],
    Kanchanaburi: [
      "Mueang Kanchanaburi",
      "Sai Yok",
      "Bo Phloi",
      "Si Sawat",
      "Tha Maka",
      "Tha Muang",
      "Thong Pha Phum",
      "Sangkhla Buri",
      "Phanom Thuan",
      "Lao Khwan",
      "Dan Makham Tia",
      "Nong Prue",
      "Huai Krachao",
    ],
    Lopburi: [
      "Mueang Lop Buri",
      "Phatthana Nikhom",
      "Khok Samrong",
      "Chai Badan",
      "Tha Wung",
      "Ban Mi",
      "Tha Luang",
      "Sa Bot",
      "Khok Charoen",
      "Lam Sonthi",
      "Nong Muang",
    ],
    "Nakhon Nayok": ["Mueang Nakhon Nayok", "Pak Phli", "Ban Na", "Ongkharak"],
    "Nakhon Pathom": [
      "Mueang Nakhon Pathom",
      "Kamphaeng Saen",
      "Nakhon Chai Si",
      "Don Tum",
      "Bang Len",
      "Sam Phran",
      "Phutthamonthon",
    ],
    Nonthaburi: [
      "Mueang Nonthaburi",
      "Bang Kruai",
      "Bang Yai",
      "Bang Bua Thong",
      "Sai Noi",
      "Pak Kret",
    ],
    "Pathum Thani": [
      "Mueang Pathum Thani",
      "Khlong Luang",
      "Thanyaburi",
      "Nong Suea",
      "Lat Lum Kaeo",
      "Lam Luk Ka",
      "Sam Khok",
    ],
    Phetchaburi: [
      "Mueang Phetchaburi",
      "Khao Yoi",
      "Nong Ya Plong",
      "Cha-am",
      "Tha Yang",
      "Ban Lat",
      "Ban Laem",
      "Kaeng Krachan",
    ],
    " Prachuap Khiri Khan": [
      "Mueang Prachuap Khiri Khan",
      "Kui Buri",
      "Thap Sakae",
      "Bang Saphan",
      "Bang Saphan Noi",
      "Pran Buri",
      "Hua Hin",
      "Sam Roi Yot",
    ],
    Ratchaburi: [
      "Mueang Ratchaburi",
      "Chom Bueng",
      "Suan Phueng",
      "Damnoen Saduak",
      "Ban Pong",
      "Bang Phae",
      "Photharam",
      "Pak Tho",
      "Wat Phleng",
    ],
    "Samut Prakan": [
      "Mueang Samut Prakan",
      "Bang Bo",
      "Bang Phli",
      "Phra Pradaeng",
      "Phra Samut Chedi",
      "Bang Sao Thong",
    ],
    "Samut Sakhon": ["Mueang Samut Sakhon", "Krathum Baen", "Ban Phaeo"],
    "Samut Songkhram": ["Mueang Samut Songkhram", "Bang Khonthi", "Amphawa"],
    Saraburi: [
      "Mueang Saraburi",
      "Kaeng Khoi",
      "Nong Khae",
      "Wihan Daeng",
      "Nong Saeng",
      "Ban Mo",
      "Don Phut",
      "Nong Don",
      "Phra Phutthabat",
      "Sao Hai",
      "Muak Lek",
      "Wang Muang",
      "Chaloem Phra Kiat",
    ],
    "Sing Buri": [
      "Mueang Sing Buri",
      "Bang Rachan",
      "Khai Bang Rachan",
      "Phrom Buri",
      "Tha Chang",
      "In Bur",
    ],
    Suphanburi: [
      "Mueang Suphan Buri",
      "Doem Bang Nang Buat",
      "Dan Chang",
      "Bang Pla Ma",
      "Si Prachan",
      "Don Chedi",
      "Song Phi Nong",
      "Sam Chuk",
      "U Thong",
      "Nong Ya Sai",
    ],
    Chachoengsao: [
      "Mueang Chachoengsao",
      "Bang Khla",
      "Bang Nam Priao",
      "Bang Pakong",
      "Ban Pho",
      "Phanom Sarakham",
      "Ratchasan",
      "Sanam Chai Khet",
      "Plaeng Yao",
      "Tha Takiap",
      "Khlong Khuean",
    ],
    Chanthaburi: [
      "Mueang Chanthaburi",
      "Khlung",
      "Tha Mai",
      "Pong Nam Ron",
      "Makham",
      "Laem Sing",
      "Soi Dao",
      "Kaeng Hang Maeo",
      "Na Yai Am",
      "Khao Khitchakut",
    ],
    "Chon Buri": [
      "Mueang Chon Buri",
      "Ban Bueng",
      "Nong Yai",
      "Bang Lamung",
      "Phan Thong",
      "Phanat Nikhom",
      "Si Racha",
      "Ko Sichang",
      "Sattahip",
      "Bo Thong",
      "Ko Chan",
    ],
    Prachinburi: [
      "Mueang Prachinburi",
      "Kabin Buri",
      "Na Di",
      "Ban Sang",
      "Prachantakham",
      "Si Maha Phot",
      "Si Mahosot",
    ],
    Rayong: [
      "Mueang Rayong",
      "Ban Chang",
      "Klaeng",
      "Wang Chan",
      "Ban Khai",
      "Pluak Daeng",
      "Khao Chamao",
      "Nikhom Phatthana",
    ],
    " Sa Kaeo": [
      "Mueang Sa Kaeo",
      "Khlong Hat",
      "Ta Phraya",
      "Wang Nam Yen",
      "Watthana Nakhon",
      "Aranyaprathet",
      "Khao Chakan",
      "Khok Sung",
      "Wang Sombun",
    ],
    Trat: [
      "Mueang Trat",
      "Khlong Yai",
      "Khao Saming",
      "Bo Rai",
      "Laem Ngop",
      "Ko Kut",
      "Ko Chang",
    ],
    Chumphon: [
      "Mueang Chumphon",
      "Tha Sae",
      "Pathio",
      "Lang Suan",
      "Lamae",
      "Phato",
      "Sawi",
      "Thung Tako",
    ],
    Krabi: [
      "Mueang Krabi",
      "Khao Phanom",
      "Ko Lanta",
      "Khlong Thom",
      "Ao Luek",
      "Plai Phraya",
      "Lam Thap",
      "Nuea Khlong",
    ],
    "Nakhon Si Thammarat": [
      "Mueang Nakhon Si Thammarat",
      "Phrom Khiri",
      "Lan Saka",
      "Chawang",
      "Phipun",
      "Chian Yai",
      "Cha-uat",
      "Tha Sala",
      "Thung Song",
      "Na Bon",
      "Thung Yai",
      "Pak Phanang",
      "Ron Phibun",
      "Sichon",
      "Khanom",
      "Hua Sai",
      "Bang Khan",
      "Tham Phannara",
      "Chulabhorn",
      "Phra Phrom",
      "Nopphitam",
      "Chang Klang",
      "Chaloem Phra Kiat",
    ],
    Narathiwat: [
      "Mueang Narathiwat",
      "Tak Bai",
      "Bacho",
      "Yi-ngo",
      "Ra-ngae",
      "Rueso",
      "Si Sakhon",
      "Waeng",
      "Sukhirin",
      "Su-ngai Kolok",
      "Su-ngai Padi",
      "Chanae",
      "Cho-airong",
    ],
    Pattani: [
      "Mueang Pattani",
      "Khok Pho",
      "Nong Chik",
      "Panare",
      "Mayo",
      "Thung Yang Daeng",
      "Sai Buri",
      "Mai Kaen",
      "Yaring",
      "Yarang",
      "Kapho",
      "Mae Lan",
    ],
    "Phang Nga": [
      "Mueang Phangnga",
      "Ko Yao",
      "Kapong",
      "Takua Thung",
      "Takua Pa",
      "Khura Buri",
      "Thap Put",
      "Thai Mueang",
    ],
    Phatthalung: [
      "Mueang Phatthalung",
      "Kong Ra",
      "Khao Chaison",
      "Tamot",
      "Khuan Khanun",
      "Pak Phayun",
      "Si Banphot",
      "Pa Bon",
      "Bang Kaeo",
      "Pa Phayom",
      "Srinagarindra",
    ],
    Phuket: ["Mueang Phuket", "Kathu", "Thalang"],
    Ranong: ["Mueang Ranong", "La-un", "Kapoe", "Kra Buri", "Suk Samran"],
    Satun: [
      "Mueang Satun",
      "Khuan Don",
      "Khuan Kalong",
      "Tha Phae",
      "La-ngu",
      "Thung Wa",
      "Manang",
    ],
    Songkhla: [
      "Mueang Songkhla ",
      "Sathing Phra",
      "Chana ",
      "Na Thawi ",
      "Thepha ",
      "Saba Yoi ",
      "Ranot ",
      "Krasae Sin",
      "Rattaphum",
      "Sadao",
      "Hat Yai",
      "Na Mom",
      "Khuan Niang",
      "Bang Klam",
      "Singhanakhon",
      "Khlong Hoi Khong",
    ],
    "Surat Thani": [
      "Mueang Surat Thani",
      "Kanchanadit",
      "Don Sak",
      "Ko Samui",
      "Ko Pha Ngan",
      "Chaiya",
      "Tha Chana",
      "Khiri Rat Nikhom",
      "Ban Ta Khun",
      "Phanom",
      "Tha Chang",
      "Ban Na San",
      "Ban Na Doem",
      "Khian Sa",
      "Wiang Sa",
      "Phrasaeng",
      "Phunphin",
      "Chai Buri",
      "Vibhavadi",
    ],
    Trang: [
      "Mueang Trang",
      "Kantang",
      "Yan Ta Khao",
      "Palian",
      "Sikao",
      "Huai Yot",
      "Wang Wiset",
      "Na Yong",
      "Ratsada",
      "Hat Samran",
    ],
    Yala: [
      "Mueang Yala",
      "Betong",
      "Bannang Sata",
      "Than To",
      "Yaha",
      "Raman",
      "Kabang",
      "Krong Pinang",
    ],
  },
  "Turquia / Turkey": {
    Adana: [
      "Adana",
      "Aladağ",
      "Ceyhan",
      "Feke",
      "İmamoğlu",
      "Karaisalı",
      "Karataş",
      "Kozan",
      "Pozantı",
      "Saimbeyli",
      "Tufanbeyli",
      "Yumurtalik",
    ],
    Adıyaman: [
      "Adıyaman",
      "Besni",
      "Çelikhan",
      "Gerger",
      "Gölbaşı",
      "Kahta",
      "Samsat",
      "Sincik",
      "Tut",
    ],
    Afyonkarahisar: [
      "Afyonkarahisar",
      "Başmakçı",
      "Bayat",
      "Bolvadin",
      "Çay",
      "Çobanlar",
      "Dazkırı",
      "Dinar",
      "Emirdağ",
      "Evciler",
      "Hocalar",
      "İhsaniye",
      "İscehisar",
      "Kızılören",
      "Sandıklı",
      "Sinanpaşa",
      "Sultandağı",
      "Şuhut",
    ],
    Ağrı: [
      "Ağrı",
      "Diyadin",
      "Doğubayazıt",
      "Eleşkirt",
      "Hamur",
      "Patnos",
      "Taşlıçay",
      "Tutak",
    ],
    Aksaray: [
      "Ağaçören",
      "Aksaray",
      "Eskil",
      "Gülağaç",
      "Güzelyurt",
      "Ortaköy",
      "Sarıyahşi",
      "Sultanhanı",
    ],
    Amasya: [
      "Amasya",
      "Göynücek",
      "Gümüşhacıköy",
      "Hamamözü",
      "Merzifon",
      "Suluova",
      "Taşova",
    ],
    Ankara: [
      "Ankara",
      "Akyurt",
      "Ayaş",
      "Bala",
      "Beypazarı",
      "Çamlıdere",
      "Çubuk",
      "Elmadağ",
      "Evren",
      "Güdül",
      "Haymana",
      "Kahramankazan",
      "Kalecik",
      "Kızılcahamam",
      "Nallıhan",
      "Polatlı",
      "Şereflikoçhisar",
    ],
    Antalya: [
      "Akseki",
      "Alanya",
      "Antalya",
      "Demre",
      "Elmalı",
      "Finike",
      "Gazipaşa",
      "Gündoğmuş",
      "İbradı",
      "Kaş",
      "Kemer",
      "Korkuteli",
      "Kumluca",
      "Manavgat",
      "Serik",
    ],
    Ardahan: ["Ardahan", "Çıldır", "Damal", "Göle", "Hanak", "Posof"],
    Artvin: [
      "Ardanuç",
      "Arhavi",
      "Artvin",
      "Borçka",
      "Hopa",
      "Kemalpaşa",
      "Murgul",
      "Şavşat",
      "Yusufeli",
    ],
    Aydın: [
      "Aydın",
      "Bozdoğan",
      "Buharkent",
      "Çine",
      "Didim",
      "Efeler",
      "Germencik",
      "İncirliova",
      "Karacasu",
      "Karpuzlu",
      "Koçarlı",
      "Köşk",
      "Kuşadası",
      "Kuyucak",
      "Nazilli",
      "Söke",
      "Sultanhisar",
      "Yenipazar",
    ],
    Balıkesir: [
      "Ayvalık",
      "Balıkesir",
      "Balya",
      "Bandırma",
      "Bigadiç",
      "Burhaniye",
      "Dursunbey",
      "Edremit",
      "Erdek",
      "Gömeç",
      "Gönen",
      "Havran",
      "İvrindi",
      "Kepsut",
      "Manyas",
      "Mármara",
      "Savaştepe",
      "Sındırgı",
      "Susurluk",
    ],
    Bartın: ["Amasra", "Bartın", "Kurucaşile", "Ulus"],
    Batman: ["Batman", "Beşiri", "Gercüş", "Hasankeyf", "Kozluk", "Sason"],
    Bayburt: ["Aydıntepe", "Bayburt", "Demirözü"],
    Bilecik: [
      "Bilecik",
      "Bozüyük",
      "Gölpazarı",
      "Inhisar",
      "Osmaneli",
      "Pazaryeri",
      "Söğüt",
      "Yenipazar",
    ],
    Bingöl: [
      "Adaklı",
      "Bingöl",
      "Genç",
      "Karlıova",
      "Kiğı",
      "Solhan",
      "Yayladere",
      "Yedisu",
    ],
    Bitlis: [
      "Adilcevaz",
      "Ahlat",
      "Bitlis",
      "Güroymak",
      "Hizan",
      "Mutki",
      "Tatvan",
    ],
    Bolu: [
      "Bolu",
      "Dörtdivan",
      "Gerede",
      "Göynük",
      "Kıbrıscık",
      "Mengen",
      "Mudurnu",
      "Seben",
      "Yeniçağa",
    ],
    Burdur: [
      "Ağlasun",
      "Altınyayla",
      "Bucak",
      "Burdur",
      "Çavdır",
      "Çeltikçi",
      "Gölhisar",
      "Karamanlı",
      "Kemer",
      "Tefenni",
      "Yeşilova",
    ],
    Bursa: [
      "Bursa",
      "Büyükorhan",
      "Gemlik",
      "Harmancık",
      "İnegöl",
      "İznik",
      "Karacabey",
      "Keles",
      "Mudanya",
      "Mustafakemalpaşa",
      "Orhaneli",
      "Orhangazi",
      "Yenişehir",
    ],
    Çanakkale: [
      "Ayvacı",
      "Bayramiç",
      "Biga",
      "Bozcaada",
      "Çan",
      "Çanakkale",
      "Eceabat",
      "Ezine",
      "Galípoli",
      "Gökçeada",
      "Lapseki",
      "Yenice",
    ],
    Cankiri: ["Cankiri"],
    Çorum: [
      "Alaca",
      "Bayat",
      "Boğazkale",
      "Çorum",
      "Dodurga",
      "İskilip",
      "Kargi",
      "Laçin",
      "Mecitözü",
      "Oğuzlar",
      "Ortaköy",
      "Osmancık",
      "Sungurlu",
      "Uğurludağ",
    ],
    Denizli: [
      "Acıpayam",
      "Babadağ",
      "Baklan",
      "Bekilli",
      "Beyağaç",
      "Bozkurt",
      "Buldan",
      "Çal",
      "Çameli",
      "Çardak",
      "Çivril",
      "Denizli",
      "Güney",
      "Honaz",
      "Kale",
      "Sarayköy",
      "Serinhisar",
      "Tavas",
    ],
    Diyarbakır: [
      "Bismil",
      "Çermik",
      "Çınar",
      "Çüngüş",
      "Dicle",
      "Diyarbakır",
      "Eğil",
      "Ergani",
      "Hani",
      "Hazro",
      "Kocaköy",
      "Kulp",
      "Lice",
      "Silvan",
    ],
    Düzce: [
      "Akçakoca",
      "Cumayeri",
      "Çilimli",
      "Düzce",
      "Gölyaka",
      "Gümüşova",
      "Kaynaşlı",
      "Yığılca",
    ],
    Edirne: [
      "Edirne",
      "Enez",
      "Havsa",
      "İpsala",
      "Keşan",
      "Lalapaşa",
      "Meriç",
      "Süloğlu",
      "Uzunköprü",
    ],
    Elazığ: [
      "Ağın",
      "Alacakaya",
      "Aricak",
      "Baskil",
      "Elazığ",
      "Karakoçan",
      "Keban",
      "Kovancılar",
      "Maden",
      "Palu",
      "Sivrice",
    ],
    Erzincan: [
      "Çayırlı",
      "Erzincan",
      "İliç",
      "Kemah",
      "Kemaliye",
      "Otlukbeli",
      "Refahiye",
      "Tercan",
      "Üzümlü",
    ],
    Erzurum: [
      "Aşkale",
      "Aziziye",
      "Çat",
      "Erzurum",
      "Hınıs",
      "Horasan",
      "İspir",
      "Karaçoban",
      "Karayazi",
      "Köprüköy",
      "Narman",
      "Oltu",
      "Olur",
      "Pasinler",
      "Pazaryolu",
      "Şenkaya",
      "Tekman",
      "Tortum",
      "Uzundere",
    ],
    Eskişehir: [
      "Alpu",
      "Beylikova",
      "Çifteler",
      "Eskişehir",
      "Günyüzü",
      "Han",
      "İnönü",
      "Mahmudiye",
      "Mihalgazi",
      "Mihalıççık",
      "Sarıcakaya",
      "Seyitgazi",
      "Sivrihisar",
    ],
    Esmirna: [
      "Aliağa",
      "Bergama",
      "Dikili",
      "Foça",
      "Kınık",
      "Menemen",
      "Bayındır",
      "Beydağ",
      "Çeşme",
      "Karaburun",
      "Kemalpaşa",
      "Kiraz",
      "Menderes",
      "Ödemiş",
      "Seferihisar",
      "Selçuk",
      "Tire",
      "Torbalı",
      "Urla",
      "Esmirna",
    ],
    Estambul: [
      "Adalar",
      "Ataşehir",
      "Beykoz",
      "Çekmeköy",
      "Kadıköy",
      "Kartal",
      "Maltepe",
      "Pendik",
      "Sancaktepe",
      "Sultanbeyli",
      "Şile",
      "Tuzla",
      "Ümraniye",
      "Üsküdar",
      "Arnavutköy",
      "Avcılar",
      "Bağcılar",
      "Bahçelievler",
      "Bakırköy",
      "Başakşehir",
      "Bayrampaşa",
      "Beşiktaş",
      "Beylikdüzü",
      "Beyoğlu",
      "Büyükçekmece",
      "Çatalca",
      "Esenler",
      "Esenyurt",
      "Eyüpsultan",
      "Fatih",
      "Gaziosmanpaşa",
      "Güngören",
      "Kağıthane",
      "Küçükçekmece",
      "Sarıyer",
      "Silivri",
      "Sultangazi",
      "Şişli",
      "Zeytinburnu",
      "Estambul",
    ],
    Gaziantep: [
      "Araban",
      "Gaziantep",
      "İslahiye",
      "Karkamış",
      "Nizip",
      "Nurdağı",
      "Oğuzeli",
      "Yavuzeli",
    ],
    Giresun: [
      "Alucra",
      "Bulancak",
      "Çamoluk",
      "Çanakçı",
      "Dereli",
      "Doğankent",
      "Espiye",
      "Eynesil",
      "Giresun",
      "Görele",
      "Güce",
      "Keşap",
      "Piraziz",
      "Şebinkarahisar",
      "Tirebolu",
      "Yağlıdere",
    ],
    Gümüşhane: ["Gümüşhane", "Kelkit", "Köse", "Kürtün", "Şiran", "Torul"],
    Hakkâri: ["Çukurca", "Derecik", "Hakkâri", "Şemdinli", "Yüksekova"],
    Hatay: [
      "Altınözü",
      "Arsuz",
      "Belen",
      "Dörtyol",
      "Erzin",
      "Hassa",
      "Antakya",
      "Defne",
      "İskenderun",
      "Kırıkhan",
      "Kumlu",
      "Payas",
      "Reyhanlı",
      "Samandağ",
      "Yayladağı",
    ],
    Iğdır: ["Aralik", "Iğdır", "Karakoyunlu", "Tuzluca"],
    Isparta: [
      "Aksu",
      "Atabey",
      "Eğirdir",
      "Gelendost",
      "Gönen",
      "Isparta",
      "Keçiborlu",
      "Senirkent",
      "Sütçüler",
      "Şarkikaraağaç",
      "Uluborlu",
      "Yalvaç",
      "Yenişarbademli",
    ],
    Kahramanmaraş: [
      "Afşin",
      "Andırın",
      "Çağlayancerit",
      "Ekinözü",
      "Elbistan",
      "Göksun",
      "Kahramanmaraş",
      "Nurhak",
      "Pazarcık",
      "Türkoğlu",
    ],
    Karabük: [
      "Eflani",
      "Eskipazar",
      "Karabük",
      "Ovacık",
      "Safranbolu",
      "Yenice",
    ],
    Karaman: [
      "Ayrancı",
      "Başyayla",
      "Ermenek",
      "Karaman",
      "Kazimkarabekir",
      "Sarıveliler",
    ],
    Kars: [
      "Akyaka",
      "Arpaçay",
      "Digor",
      "Kağızman",
      "Kars",
      "Sarıkamış",
      "Selim",
      "Susuz",
    ],
    Kastamonu: [
      "Abana",
      "Ağlı",
      "Araç",
      "Azdavay",
      "Bozkurt",
      "Cide",
      "Çatalzeytin",
      "Daday",
      "Devrekani",
      "Doğanyurt",
      "Hanönü",
      "İhsangazi",
      "İnebolu",
      "Kastamonu",
      "Küre",
      "Pınarbaşı",
      "Seydiler",
      "Şenpazar",
      "Taşköprü",
      "Tosya",
    ],
    Kayseri: [
      "Akkışla",
      "Bünyan",
      "Develi",
      "Felahiye",
      "Hacılar",
      "İncesu",
      "Kayseri",
      "Özvatan",
      "Pınarbaşı",
      "Sarıoğlan",
      "Sarız",
      "Tomarza",
      "Yahyalı",
      "Yeşilhisar",
    ],
    Kilis: ["Elbeyli", "Kilis", "Musabeyli", "Polateli"],
    Kirklareli: ["Kirklareli"],
    Kırıkkale: [
      "Bahsili",
      "Balışeyh",
      "Çelebi",
      "Delice",
      "Karakeçili",
      "Keskin",
      "Kırıkkale",
      "Sulakyurt",
      "Yahşihan",
    ],
    Kırşehir: [
      "Akçakent",
      "Akpınar",
      "Boztepe",
      "Çiçekdağı",
      "Kaman",
      "Kırşehir",
      "Mucur",
    ],
    Kocaeli: [
      "Başiskele",
      "Çayırova",
      "Darica",
      "Derince",
      "Dilovasi",
      "Gebze",
      "Gölcük",
      "Izmit",
      "Kandıra",
      "Karamürsel",
      "Kartepe",
      "Körfez",
    ],
    Konya: [
      "Ahırlı",
      "Akören",
      "Akşehir",
      "Altınekin",
      "Beyşehir",
      "Bozkır",
      "Cihanbeyli",
      "Çeltik",
      "Çumra",
      "Derbent",
      "Derebucak",
      "Doğanhisar",
      "Emirgazi",
      "Ereğli",
      "Güneysınır",
      "Hadim",
      "Halkapınar",
      "Hüyük",
      "Ilgın",
      "Kadınhanı",
      "Karapınar",
      "Konya",
      "Kulu",
      "Sarayönü",
      "Seydişehir",
      "Taşkent",
      "Tuzlukçu",
      "Yalıhüyük",
      "Yunak",
    ],
    Kütahya: [
      "Altıntaş",
      "Aslanapa",
      "Çavdarhisar",
      "Domaniç",
      "Dumlupınar",
      "Emet",
      "Gediz",
      "Hisarcık",
      "Kütahya",
      "Pazarlar",
      "Simav",
      "Şaphane",
      "Tavşanlı",
    ],
    Malatya: [
      "Akçadağ",
      "Arapgir",
      "Arguvan",
      "Darende",
      "Doğanşehir",
      "Doğanyol",
      "Hekimhan",
      "Kale",
      "Kuluncak",
      "Malatya",
      "Pütürge",
      "Yazıhan",
    ],
    Manisa: [
      "Ahmetli",
      "Akhisar",
      "Alaşehir",
      "Demirci",
      "Gölmarmara",
      "Gördes",
      "Kırkağaç",
      "Köprübaşı",
      "Kula",
      "Manisa",
      "Salihli",
      "Sarıgöl",
      "Saruhanlı",
      "Selendi",
      "Soma",
      "Turgutlu",
    ],
    Mardin: [
      "Artuklu",
      "Dargeçit",
      "Derik",
      "Kızıltepe",
      "Mazıdağı",
      "Midyat",
      "Nusaybin",
      "Ömerli",
      "Savur",
      "Yeşilli",
    ],
    Mersin: [
      "Anamur",
      "Aydıncık",
      "Bozyazi",
      "Çamlıyayla",
      "Erdemli",
      "Gülnar",
      "Mersin",
      "Mut",
      "Silifke",
      "Tarso",
    ],
    Muğla: [
      "Muğla",
      "Bodrum",
      "Dalaman",
      "Datça",
      "Fethiye",
      "Kavaklıdere",
      "Köyceğiz",
      "Marmaris",
      "Menteşe",
      "Milas",
      "Ortaca",
      "Seydikemer",
      "Ula",
      "Yatağan",
    ],
    Mus: ["Bulanık", "Hasköy", "Korkut", "Malazgirt", "Muş", "Varto"],
    Nevsehir: [
      "Acıgöl",
      "Avanos",
      "Derinkuyu",
      "Gülşehir",
      "Hacıbektaş",
      "Kozaklı",
      "Nevşehir",
      "Ürgüp",
    ],
    Niğde: ["Altunhisar", "Bor", "Çamardı", "Çiftlik", "Niğde", "Ulukışla"],
    Ordu: [
      "Akkuş",
      "Aybastı",
      "Çamaş",
      "Çatalpınar",
      "Çaybaşı",
      "Fatsa",
      "Gölköy",
      "Gülyalı",
      "Gürgentepe",
      "İkizce",
      "Kabadüz",
      "Kabataş",
      "Korgan",
      "Kumru",
      "Mesudiye",
      "Ordu",
      "Perşembe",
      "Ulubey",
      "Ünye",
    ],
    Osmaniye: [
      "Bahçe",
      "Düziçi",
      "Hasanbeyli",
      "Kadirli",
      "Osmaniye",
      "Sumbas",
      "Toprakkale",
    ],
    Rize: [
      "Ardeşen",
      "Çamlıhemşin",
      "Çayeli",
      "Derepazarı",
      "Fındıklı",
      "Güneysu",
      "Hemşin",
      "İkizdere",
      "İyidere",
      "Kalkandere",
      "Pazar",
      "Rize",
    ],
    Sakarya: [
      "Akyazı",
      "Ferizli",
      "Geyve",
      "Hendek",
      "Karapürçek",
      "Karasu",
      "Kaynarca",
      "Kocaali",
      "Pamukova",
      "Sakarya",
      "Sapanca",
      "Söğütlü",
      "Taraklı",
    ],
    Samsun: [
      "Alaçam",
      "Asarcık",
      "Ayvacık",
      "Bafra",
      "Çarşamba",
      "Havza",
      "Kavak",
      "Ladik",
      "Ondokuz Mayıs",
      "Salıpazarı",
      "Samsun",
      "Tekkeköy",
      "Terme",
      "Vezirköprü",
      "Yakakent",
    ],
    Şanlıurfa: ["Şanlıurfa"],
    Siirt: [
      "Baykan",
      "Eruh",
      "Kurtalan",
      "Pervari",
      "Siirt",
      "Şirvan",
      "Tillo",
    ],
    Sinope: [
      "Ayancık",
      "Boyabat",
      "Dikmen",
      "Durağan",
      "Erfelek",
      "Gerze",
      "Saraydüzü",
      "Sinope",
      "Türkeli",
    ],
    Sivas: [
      "Akıncılar",
      "Altınyayla",
      "Divriği",
      "Doğanşar",
      "Gemerek",
      "Gölova",
      "Gürün",
      "Hafik",
      "İmranlı",
      "Kangal",
      "Koyulhisar",
      "Sivas",
      "Suşehri",
      "Şarkışla",
      "Ulas",
      "Yıldızeli",
      "Zara",
    ],
    Şırnak: [
      "Beytüşşebap",
      "Cizre",
      "Güçlükonak",
      "İdil",
      "Silopi",
      "Şırnak",
      "Uludere",
    ],
    Tekirdağ: [
      "Tekirdağ",
      "Çerkezköy",
      "Çorlu",
      "Ergene",
      "Hayrabolu",
      "Kapaklı",
      "Malkara",
      "Marmaraereğlisi",
      "Muratlı",
      "Saray",
      "Süleymanpaşa",
      "Şarköy",
    ],
    Tokat: [
      "Almus",
      "Artova",
      "Başçiftlik",
      "Erbaa",
      "Niksar",
      "Pazar",
      "Reşadiye",
      "Sulusaray",
      "Tokat",
      "Turhal",
      "Yeşilyurt",
      "Zile",
    ],
    Trabzon: ["Trabzon"],
    Tunceli: [
      "Çemişgezek",
      "Hozat",
      "Mazgirt",
      "Nazımiye",
      "Ovacık",
      "Pertek",
      "Pülümür",
      "Tunceli",
    ],
    Uşak: ["Banaz", "Eşme", "Karahallı", "Sivaslı", "Ulubey", "Uşak"],
    Van: [
      "Bahçesaray",
      "Başkale",
      "Çaldıran",
      "Çatak",
      "Edremit",
      "Erciş",
      "Gevaş",
      "Gürpınar",
      "Muradiye",
      "Özalp",
      "Saray",
      "Van",
    ],
    Yalova: [
      "Altınova",
      "Armutlu",
      "Çınarcık",
      "Çiftlikköy",
      "Termal",
      "Yalova",
    ],
    Yozgat: [
      "Akdağmadeni",
      "Aydıncık",
      "Boğazlıyan",
      "Çandır",
      "Çayıralan",
      "Çekerek",
      "Kadışehri",
      "Saraykent",
      "Sarıkaya",
      "Şefaatli",
      "Sorgun",
      "Yenifakılı",
      "Yerköy",
      "Yozgat",
    ],
    Zonguldak: [
      "Alaplı",
      "Çaycuma",
      "Devrek",
      "Ereğli",
      "Gökçebey",
      "Kilimli",
      "Kozlu",
      "Zonguldak",
    ],
  },
  Túnez: {
    Ariana: [
      "Túnez",
      "La Goulette",
      "Carthage",
      "Sidi Bou Said",
      "La Marsa",
      "Le Bardo",
      "La Manouba",
      "Raoued",
    ],
    Béja: [
      "Amdoun",
      "Béja Nord",
      "Béja Sud",
      "Goubellat",
      "Medjez el-Bab",
      "Nefza",
      "Téboursouk",
      "Testour",
      "Thibar",
    ],
    "Ben Arous": [
      "Ben Arous",
      "Bou Mhel el-Bassatine",
      "El Mourouj",
      "Ezzahra",
      "Fouchana",
      "Hammam Chôtt",
      "Hammam Lif",
      "Medina Jedida",
      "Mégrine",
      "Mohamedia",
      "Mornag",
      "Radès",
    ],
    Bizerta: [
      "Bizerte Nord",
      "Bizerte Sud",
      "El Alia",
      "Ghar El Melh",
      "Ghezala",
      "Joumine",
      "Mateur",
      "Menzel Bourguiba",
      "Menzel Jemil",
      "Ras Jebel",
      "Sejnane",
      "Tinja",
      "Utique",
      "Zarzouna",
    ],
    Gabes: [
      "El Hamma",
      "Gabès Médina",
      "Gabès Ouest",
      "Gabès Sud",
      "Ghannouch",
      "Mareth",
      "Matmata",
      "Menzel El Habib",
      "Métouia",
      "Nouvelle Matmata",
    ],
    Gafsa: [
      "Belkhir",
      "El Guettar",
      "El Ksar",
      "Gafsa Nord",
      "Gafsa Sud",
      "Mdhila",
      "Métlaoui",
      "Moularès",
      "Redeyef",
      "Sened",
      "Sidi Aïch",
    ],
    Jendouba: [
      "Aïn Draham",
      "Balta - Bou Aouane",
      "Bou Salem",
      "Fernana",
      "Ghardimaou",
      "Jendouba",
      "Jendouba Nord",
      "Oued Meliz",
      "Tabarka",
    ],
    Kairouan: [
      "Bou Hajla",
      "Chebika",
      "Echrarda",
      "El Alâa",
      "Haffouz",
      "Hajeb el Ayoun",
      "Kairouan Nord",
      "Kairouan Sud",
      "Nasrallah",
      "Oueslatia",
      "Sbikha",
    ],
    Kasserine: [
      "El Ayoun",
      "Ezzouhour",
      "Fériana",
      "Foussana",
      "Haïdra",
      "Hassi El Ferid",
      "Jedelienne",
      "Kasserine Nord",
      "Kasserine Sud",
      "Majel Bel Abbès",
      "Sbeïtla",
      "Sbiba",
      "Thala",
    ],
    Kebili: [
      "Douz Nord",
      "Douz Sud",
      "Faouar",
      "Kébili Nord",
      "Kébili Sud",
      "Souk Lahad",
    ],
    "Al-Kāf": [
      "Dahmani",
      "El Ksour",
      "Jérissa",
      "Kalâat Khasba",
      "Kalaat Senan",
      "Kef Est",
      "Kef Ouest",
      "Nebeur",
      "Sakiet Sidi Youssef",
      "Sers",
      "Tajerouine",
    ],
    Mahdía: [
      "Bou Merdès",
      "Chebba",
      "Chorbane",
      "El Jem",
      "Essouassi",
      "Hebira",
      "Ksour Essef",
      "Mahdia",
      "Melloulèche",
      "Ouled Chamekh",
      "Sidi Alouane",
    ],
    Manouba: [
      "Borj El Amri",
      "Djedeida",
      "Douar Hicher",
      "El Batan",
      "Manouba",
      "Mornaguia",
      "Oued Ellil",
      "Tebourba",
    ],
    Medenine: [
      "Ben Gardane",
      "Beni Khedache",
      "Djerba Ajim",
      "Djerba Houmet Souk",
      "Djerba Midoun",
      "Médenine Nord",
      "Médenine Sud",
      "Sidi Makhlouf",
      "Zarzis",
    ],
    Monastir: [
      "bekalta",
      "bembla",
      "beni hassen",
      "jemmal",
      "ksar hellal",
      "ksibet el-médiouni",
      "moknine",
      "monastir",
      "ouerdanine",
      "sahline",
      "sayada-lamta-bou hajar",
      "téboulba",
      "zéramdine",
    ],
    Nabeul: [
      "Béni Khalled",
      "Béni Khiar",
      "Bou Argoub",
      "Dar Châabane El Fehri",
      "El Haouaria",
      "El Mida",
      "Grombalia",
      "Hammamet",
      "Hammam Ghezèze",
      "Kélibia",
      "Korba",
      "Menzel Bouzelfa",
      "Menzel Temime",
      "Nabeul",
      "Soliman",
      "Takelsa",
    ],
    Sfax: [
      "Agareb",
      "Bir Ali Ben Khalifa",
      "El Amra",
      "El Hencha",
      "Graïba",
      "Jebiniana",
      "Kerkennah",
      "Mahrès",
      "Menzel Chaker",
      "Sakiet Eddaïer",
      "Sakiet Ezzit",
      "Sfax Ouest",
      "Sfax Sud",
      "Sfax Ville",
      "Skhira",
      "Thyna",
    ],
    "Sidi Bouzid": [
      " Bir El Hafey",
      " Cebbala Ouled Asker",
      " Jilma",
      " Meknassy",
      " Menzel Bouzaïenne",
      " Mezzouna",
      " Ouled Haffouz",
      " Regueb",
      " Sidi Ali Ben Aoûn",
      " Sidi Bouzid Est",
      " Sidi Bouzid Ouest",
      " Souk Jedid",
    ],
    Siliana: [
      "Bargou",
      "Bou Arada",
      "El Aroussa",
      "El Krib",
      "Gaâfour",
      "Kesra",
      "Makthar",
      "Rouhia",
      "Sidi Bou Rouis",
      "Siliana Nord",
      "Siliana Sud",
    ],
    Susa: [
      "Akouda",
      "Bouficha",
      "Enfida",
      "Hammam Sousse",
      "Hergla",
      "Kalâa Kebira",
      "Kalâa Seghira",
      "Kondar",
      "M'saken",
      "Sidi Bou Ali",
      "Sidi El Héni",
      "Sousse Jawhara",
      "Sousse Médina",
      "Sousse Riadh",
      "Sousse Sidi Abdelhamid",
      "Zaouia - Ksiba - Thrayet",
    ],
    Tataouine: [
      "Bir Lahmar",
      "Dehiba",
      "Ghomrassen",
      "Remada",
      "Smâr",
      "Tataouine Nord",
      "Tataouine Sud",
    ],
    Tozeur: ["Degache", "Hazoua", "Nefta", "Tameghza", "Tozeur"],
    Túnez: [
      "Bab El Bhar",
      "Bab Souika",
      "Carthage",
      "Cité El Khadra",
      "Ciudad de Túnez",
      "Djebel Jelloud",
      "El Hraïria",
      "El Kabaria",
      "El Menzah",
      "El Omrane",
      "El Omrane Supérieur",
      "El Ouardia",
      "Ettahrir",
      "Ezzouhour",
      "La Goulette",
      "La Marsa",
      "Le Bardo",
      "Le Kram",
      "Medina",
      "Séjoumi",
      "Sidi El Béchir",
      "Sidi Hassine",
    ],
    Zaghouan: [
      "Bir Mcherga",
      "El Fahs",
      "Nadhour",
      "Saouaf",
      "Zaghouan",
      "Zriba",
    ],
  },
  Uruguay: {
    Artigas: ["Baltasar Brum", "Bella Unión", "Tomás Gomensoro"],
    Canelones: [
      " 18 de Mayo",
      " Aguas Corrientes",
      " Atlántida",
      " Barros Blancos",
      " Canelones",
      " Ciudad de la Costa",
      " Colonia Nicolich",
      " Empalme Olmos",
      " La Floresta",
      " La Paz",
      " Las Piedras",
      " Los Cerrillos",
      " Migues",
      " Montes",
      " Pando",
      " Parque del Plata",
      " Paso Carrasco",
      " Progreso",
      " Salinas",
      " San Antonio",
      " San Bautista",
      " San Jacinto",
      " San Ramón",
      " Santa Lucía",
      " Santa Rosa",
      " Sauce",
      " Soca",
      " Suárez",
      " Tala",
      " Toledo",
    ],
    "Cerro Largo": [
      "Aceguá",
      "Arbolito",
      "Arévalo",
      "Bañado de Medina",
      "Centurión",
      "Cerro de las Cuentas",
      "Fraile Muerto",
      "Isidoro Noblía",
      "Las Cañas",
      "Municipio de Quebracho",
      "Plácido Rosas",
      "Ramón Trigo",
      "Río Branco",
      "Tres Islas",
      "Tupambaé",
    ],
    Colonia: [
      "Carmelo",
      "Colonia Miguelete",
      "Colonia Valdense",
      "Florencio Sánchez",
      "Juan L. Lacaze",
      "La Paz",
      "Nueva Helvecia",
      "Nueva Palmira",
      "Ombúes de Lavalle",
      "Rosario",
      "Tarariras",
    ],
    Durazno: ["Sarandí del Yí", "Villa del Carmen"],
    Flores: ["Flores"],
    Florida: ["Casupá", "Fray Marcos", "Sarandí Grande"],
    Lavalleja: [
      "José Batlle y Ordóñez",
      "José Pedro Varela",
      "Mariscala",
      "Solís de Mataojo",
    ],
    Maldonado: [
      "Aiguá",
      "Garzón",
      "Maldonado",
      "Pan de Azúcar",
      "Piriápolis",
      "Punta del Este",
      "San Carlos",
      "Solís Grande",
    ],
    Montevideo: ["Montevideo"],
    Paysandú: [
      "Chapicuy",
      "Guichón",
      "Lorenzo Geyres",
      "Piedras Coloradas",
      "Porvenir",
      "Quebracho",
      "Tambores",
    ],
    "Río Negro": ["Nuevo Berlín", "San Javier", "Young"],
    Rivera: ["Minas de Corrales", "Tranqueras", "Vichadero"],
    Rocha: ["Chuy", "Castillos", "Lascano", "La Paloma"],
    Salto: [
      "Belén",
      "Colonia Lavalleja",
      "Mataojo",
      "Rincón de Valentín",
      "San Antonio",
      "Villa Constitución",
    ],
    "San José": [
      "Ciudad del Plata",
      "Ecilda Paullier",
      "Libertad",
      "Rodríguez",
    ],
    Soriano: ["Cardona", "Dolores", "José Enrique Rodó", "Palmitas"],
    Tacuarembó: ["Ansina", "Paso de los Toros", "San Gregorio de Polanco"],
    "Treinta y Tres": [
      "Cerro Chato",
      "General Enrique Martínez",
      "Rincón",
      "Santa Clara de Olimar",
      "Vergara",
      "Villa Sara",
    ],
  },
  Uzbekistan: {
    Andijon: [
      "Andijon (Kuyganyar)",
      "Asaka (Asaka)",
      "Baliqchi (Baliqchi)",
      "Boz (Boz)",
      "Buloqboshi (Buloqboshi)",
      "Izboskan (Paytug)",
      "Jalalkuduk (Akhunbabaev)",
      "Khodjaobad (Khodjaobad)",
      "Kurgontepa (Kurgontepa)",
      "Marhamat (Marhamat)",
      "Oltinkol (Oltinkol)",
      "Pakhtaabad (Pakhtaabad)",
      "Shakhrihon (Shakhrihon)",
      "Ulugnor (Okoltin)",
    ],
    Bujará: [
      "Bujará",
      "Koshmedrese",
      "Poyi Kalon",
      "Ulugbek",
      "Jondor:",
      "Dustlik",
      "Jangal",
      "Kok-Ming",
      "Poyi Kurgan",
      "Gijduvan:",
      "Buloqboshi",
      "Nurota",
      "Oqtepa",
      "Tinchlik",
      "Kagan:",
      "Kamolon",
      "Qayron",
      "Sogbon",
      "Karmana:",
      "Mirishkor",
      "Qushbegi",
      "Xojaqishloq",
      "Peshku:",
      "Chimbay",
      "Qoziyabad",
      "Tegirmayli",
      "Romitan:",
      "Khojambor",
      "Mirobod",
      "Sayxunobod",
      "Shafirkan:",
      "Jomboy",
      "Qorategin",
      "Uchqoqli",
      "Shofirkon:",
      "Guliston",
      "Kichik Buloqboshi",
      "Kichik Qo'rg'on",
      "Vabkent:",
      "Qamqo'rg'on",
      "Seshankent",
      "Vabkent",
      "Zafarobod:",
      "Aktepa",
      "Buston",
      "Qul",
    ],
    Ferganá: [
      "Beshariq",
      "Andijan",
      "Beshbulok",
      "Bozoriyav",
      "Gulshan",
      "Qaynar",
      "Qirg'oq",
      "Qishloq",
      "Sodir",
      "Yangiobod",
      "Bag'dod",
      "Alpomish",
      "Bag'dod",
      "Durmon",
      "Jangobod",
      "Kuyguncha",
      "Mashxadiyobod",
      "O'rtatosh",
      "Sovetskiy",
      "Temir",
      "Yunusobod",
      "Ferganá",
      "Beshyog'och",
      "Cholpon",
      "Farg'ona",
      "Gulbahor",
      "Iftixon",
      "Kattaqo'rg'on",
      "Komsomol",
      "M. Koshon",
      "Oqqo'rg'on",
      "Oqtepa",
      "Shodlik",
      "Sodiq",
      "Tinchlik",
      "To'qimachilik",
      "O'zbekiston",
      "Alisher Navoiy",
      "Andijon",
      "Buvayda",
      "Guliston",
      "Hamid Alimjan",
      "Mashrab",
      "Navro'z",
      "Nijora",
      "O'zbekiston",
      "Qorg'oncha",
      "Saykhunobod",
      "Yakkabog'",
      "Qo'shtepa",
      "Baland",
      "Burchimullo",
      "Dangara",
      "Dukchi",
      "Kiziltepa",
      "Lutfiy",
      "Navro'zobod",
      "Parkent",
      "Yangiqishloq",
      "Quva",
      "Bobojon G'ofurov",
      "Chirchik",
      "Chon-Qora",
      "Firdavsiy",
      "Guliston",
      "Istiklol",
      "Jizzax",
      "Khodjaobod",
      "Parkent",
      "Qarshi",
      "Qushbegi",
      "Qushlar",
      "Qurshut",
      "Shaxriston",
      "Shokirjon",
      "Rishton",
      "Beshyog'och",
      "Chomion",
      "Ikbol",
      "Kattaqo'rg'on",
      "Kayragach",
      "Khamid Olimjon",
      "Kiziltepa",
      "Kuvik",
      "Lutfiy",
      "Mashrab",
      "Navbakhor",
      "Niyazmukhammad",
      "Okkurgon",
      "Qorako'l",
      "Rakhimov",
      "Rishton",
      "So'x",
      "Boburshoh",
      "Chimbay",
      "Dangara",
      "Doston",
      "Ergash",
      "Guldor",
      "Jirgatol",
      "Kuyguncha",
      "Kyzyljar",
      "Oqoltin",
      "Toshloq",
      "Aqmasjid",
      "Buyuk Ipak Yo'l",
      "Chayzaq",
      "G'alaba",
      "Guliston",
      "Karimov",
      "Kuyguncha",
      "Kyzyltepa",
      "Nishon",
      "Oqqibolo",
      "Parkent",
      "Saboq",
      "Toshloq",
      "To'g'oncha",
      "Uchkuprik",
      "Buyuk Ipak Yo'l",
      "Chonqara",
      "G'ulobod",
      "Imeni Molotova",
      "Janggoh",
      "Kattaqishloq",
      "Mahalla",
      "Navro'zobod",
      "Niyozkul",
      "O'zgur",
      "Ozodlik",
      "Shohimardon",
      "Uchkuprik",
      "Xalqobod",
      "Oltiariq",
      "Buyuk Ipak Yo'l",
      "Gulshan",
      "Imeni Kalinina",
      "Imeni Kirova",
      "Imeni Stalina",
      "Iqtisodiy",
      "Janggoh",
      "Navruz",
      "Nizomiy",
      "Nuriy",
      "Oltiariq",
      "Poygumbaz",
      "Xonqa",
      "Yozyovon",
      "Chirchiq",
      "Kattaqorgon",
      "Kuyguncha",
      "Kyzylchi",
      "Mahalla",
      "Navro'z",
      "Novbuloq",
      "O'zgur",
      "Ozodlik",
      "Pahlavon",
      "Qalacha",
      "Qurilishchi",
      "Yozyovon",
      "Qo'rg'on",
      "Alpomish",
      "Baxt",
      "Bol'shevik",
      "Buyuk Ipak Yo'l",
      "Chog'uchor",
      "Imeni Pushkina",
      "Imeni S. Ainiy",
      "Kattaqo'rg'on",
      "Kuyguncha",
      "Mahalla",
      "Navbakhor",
      "Navoiy",
      "Qo'rg'on",
      "Sovetskiy",
      "Yangiqishloq",
      "Shohimardon",
      "Beshariq",
      "Buyuk Ipak Yo'l",
      "Chilonzor",
      "Guliston",
      "Imeni Kosmonavta Yuriya Gagarina",
      "Imeni Lenina",
      "Kolkhozchi",
      "Kuyguncha",
      "Mahalla",
      "Navro'z",
      "O'zgur",
      "Ozodlik",
      "Qozonlik",
      "Shohimardon",
      "Marg'ilon",
      "Al-Qoziy",
      "Buyuk Ipak Yo'l",
      "Chilonzor",
      "Gulshan",
      "Imeni Avicenny",
      "Imeni Ismail Somoni",
      "Imeni Mirzo Ulug'bek",
      "Kattaqo'rg'on",
      "Kuyguncha",
      "Mahalla",
      "Navbakhor",
      "Navoi",
    ],
    Dzhizak: [
      "Dzhizak Shahri",
      "11-16 kvartal",
      "Akmal Ikromov",
      "Amir Temur",
      "Chinor",
      "Dachnyy",
      "Imeni Kirova",
      "Kattaqo'rg'on",
      "M. G'afurov",
      "Mustaqillik",
      "Nuri Abidov",
      "Qo'chqor",
      "Tinchlik",
      "Yangi",
      "Yuzhnyy",
      "Arnasoy Shahri",
      "Abdurakhmon Avloniy",
      "Abubakr No'monov",
      "Aral",
      "Chilonzor",
      "Chorvoq",
      "Imeni R. Kamalova",
      "Kelajak",
      "Ko'ngil",
      " Qurilishi",
      "Navro'z",
      "Nizomiy",
      "O'rtacha",
      "Osiyo",
      "Bakhmal Shahri",
      "1-6 kvartal",
      "Amir Temur",
      "Beshbulok",
      "Buyuk Ipak Yo'l",
      "Davlat",
      "G'uliston",
      "Janubiy",
      "Kattaqo'rg'on",
      "Navoiy",
      "Sirdaryo",
      "Tinchlik",
      "Uyg'ur",
      "Forish Shahri",
      "1-3 kvartal",
      "Baland",
      "Boychechak",
      "Buyuk Ipak Yo'l",
      "Farovon",
      "Forishiy",
      "Ilkovo",
      "Kattaqo'rg'on",
      "Navruz",
      "Sanoat",
      "Shahriqul",
      "Tinchlik",
      "Tojikiston",
      "Gagarin Shahri",
      "Amir Temur",
      "Aral",
      "Bobo-Ata",
      "Boychechak",
      "Chilonzor",
      "Cholpon",
      "Dustlik",
      "Gagarin",
      "Kattaqo'rg'on",
      " Qurilishi",
      "Navro'z",
      "Osiyo",
      "Shahriston",
      "Sharof Rashidov Shahri",
      "Amir Temur",
      "Chinor",
      "Chorvoq",
      "Erkinlik",
      "Karamiqsoy",
      "Kattaqo'rg'on",
      "Kattapir",
      "Kho'jaobod",
      "Ko'ngil",
      "Ko'pgari",
      " Qurilishi",
      "Navro'z",
      "Shohida",
      "Tinchlik",
      "Zafarobod Shahri",
      "Abdullaxon ",
      "Adolat ",
      "Amir Temur ",
      "Chinor ",
      "Gulshan ",
      "Hayoti Nav ",
      "Kattahayot ",
      "Mirzo Ulugbek ",
      "Navoiy ",
      "Parkent ",
      "Rashidov ",
      "Shakhrihon ",
      "Zafarobod ",
      "Zarbdor Shahri",
      "Amir Temur ",
      "Aral ",
      "Chilonzor ",
      "Chorvoq ",
      "G'azalkent ",
      "Gulobod ",
      "Jo'shqinoy ",
      "Kattaqo'rg'on ",
      "Qurilishi",
      "Navro'z ",
      "Sayfullo Khodjaev ",
      "Sirdaryo ",
      "Mirzachul Shahri",
      "1-9 kvartal ",
      "Abdurauf Fitrat ",
      "Buyuk Ipak Yo'l ",
      "Chilonzor ",
      "Imeni Ismoil Somoni ",
      "Jo'raqo'l ",
      "Qurilishi",
      "Navro'z ",
      "Olimjon Sobirov ",
      "Oqtepa ",
      "Osiyo ",
      "Shahriston ",
      "Pakhtakor Shahri",
      "1-4 kvartal ",
      "Abdulla Qodiriy ",
      "Chinor ",
      "Chorvoq ",
      "G'allaorol ",
      "Guliston ",
      "Kattaqo'rg'on ",
      "Qurilishi",
      "Navro'z ",
      "Qo'rg'onboy ",
      "Sharaf Rashidov ",
      "Zulfiya ",
      "Yangiabad Shahri",
      "1-2 kvartal ",
      "Amir Temur ",
      "Asaka ",
      "Buyuk Ipak Yo'l ",
      "Chinor ",
      "Imeni Navoiy ",
      "Imeni Rudakiy ",
      "Kattaqo'rg'on ",
      "Qurilishi",
      "Navro'z ",
      "Osiyo ",
      "Shahrisabz ",
    ],
    Xorazm: [
      "Beruniy",
      "Alimqul ",
      "Andijon ",
      "Barchinoy ",
      "Bo'zor ",
      "Fayzullo khodjaev ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Mirabad ",
      "Namozgoh ",
      "Navro'z ",
      "Sardoba ",
      "Sharq ",
      "Yangi ",
      "Bogot",
      "Asatulla ",
      "Chinor ",
      "Chorsu ",
      "Guldasta ",
      "Imeni Kirov ",
      "Imeni Navoiy ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Mekhnat ",
      "Navro'z ",
      "Qo'ziy ",
      "Sahovat ",
      "Gurlan",
      "Burchimullo ",
      "Daryo ",
      "Fayzullo khodjaev ",
      "Imeni Oybek ",
      "Imeni Sobir Rahimov ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Navro'z ",
      "Novza ",
      "Sog'inch ",
      "Sulton ",
      "Khiva",
      "Bahoriston ",
      "Buyuk Ipak Yo'l ",
      "Chilonzor ",
      "Chodiriy ",
      "Chorsu ",
      "Dustlik ",
      "Imeni Navoiy ",
      "Imeni Sadriddin Ainiy ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Navro'z ",
      "Oybek ",
      "Qo'ziy ",
      "Sayram ",
      "Shohidlar ",
      "Qo'ng'irot",
      "Angor ",
      "Buyuk Ipak Yo'l ",
      "Chinor ",
      "Farg'ona ",
      "Guliston ",
      "Imeni Abdulla Qodiriy ",
      "Imeni Navoiy ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Navro'z ",
      "Qarshi ",
      "Shahriston ",
      "Qoshkopir",
      "Aksaray ",
      "Andijon ",
      "Chinor ",
      "Do'stlik ",
      "Haydarov ",
      "Imeni Bobur ",
      "Imeni G'afur G'ulom ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Navro'z ",
      "Qoshkopir ",
      "To'qimachilik ",
      "Shovot",
      "Andijon ",
      "Bogishamol ",
      "Buyuk Ipak Yo'l ",
      "Chinor ",
      "Guldasta ",
      "Imeni Beruniy ",
      "Imeni Sotim Ulug'bek ",
      "Kattaqo'rg'on ",
      " Qurilishi",
      "Navro'z ",
      "Urganch shahri",
      "Al-Xorazmiy ",
      "Alisher Navoiy ",
      "Andijon ",
      "Aral ",
      "Bog'bon ",
      "Boshqal'a ",
      "Chilonzor ",
      "Gullikurgon ",
      "Qong'irat ",
      "Qurbon ",
      "Qurilish ",
      "Shodmon ",
      "Shoxbuloq ",
      "Temiryulchi ",
      "Tinchlik ",
      "Urganch-1 ",
      "Urganch-2 ",
      "Urganch-3 ",
      "Urganch-4 ",
      "Urganch-5 ",
      "Yangi Urganch ",
      "Baxt shahri",
      "Archa ",
      "Baxt ",
      "Chinor ",
      "Duzxon ",
      "Ishonchi ",
      "Jigarxona ",
      "Navruz ",
      "Ozodlik ",
      "Qizilqum ",
      "Shohidlar ",
      "Gurlan shahri",
      "Akcha ",
      "Guliston ",
      "Gurlan ",
      "Ichkiyo'l ",
      "Kattabog' ",
      "Navruz ",
      "Shahidlar ",
      "Qo'shko'pir shahri",
      "Akmal-ota ",
      "Beshariq ",
      "Bobur ",
      "G'azalkent ",
      "Kallar ",
      "Kattaqurqon ",
      "Kirachi ",
      "Ko'kcha ",
      "Kurgancha ",
      "Qo'shko'pir ",
      "Qurama ",
      "Shohidlar ",
      "Shahrixon shahri",
      "Andijon ",
      "Beshikchi ",
      "Botxona ",
      "G'oliblik ",
      "Imom Bukhariy ",
      "Karaqo'l ",
      "Navro'z ",
      "Oqso'qol ",
      "Poytaxt ",
      "Shahrixon-1 ",
      "Shahrixon-2 ",
      "Shifo ",
      "Temiryulchi ",
      "Urganch tumani",
      "Asaka ",
      "Bo'zbuloq ",
      "Buyuk Ipak Yo'l ",
      "Charvak ",
      "Chinobod ",
      "Chonqora ",
      "Farg'ona-ota ",
      "Gallaorol ",
      "G'ovun ",
      "Ishonchi ",
      "Koshkupir ",
      "Oqoltin ",
      "Qorasuv ",
      "Ravnaq ",
      "Tagaytay ",
    ],
    Namangán: [
      "Chartak",
      "Burchmulla ",
      "Gulshan ",
      "Sanoatchi ",
      "Juma ",
      "Adirsoat ",
      "Achichkan ",
      "Qumriq ",
      "Toshgumbaz ",
      "Sheroziy ",
      "Gulzor ",
      "Istiklol ",
      "Imkoniyat ",
      "Poytug` ",
      "Kattag`on ",
      "Achichkan qishloq",
      "Chust",
      "Kichik qirg`iz ",
      "Katta qirg`iz ",
      "Qochqor ",
      "Qorg`on ",
      "Boshchiq ",
      "Oftobchi ",
      "Burchmulla ",
      "Buyraqchi ",
      "Jomaldiq ",
      "Qishloqchi ",
      "Yunusobod ",
      "Jiyonchi ",
      "Kasansay",
      "Markhamat ",
      "Sog`lom avlod ",
      "Shifo ",
      "Poytug` ",
      "Kasansay qishloq",
      "Kosonsoy",
      "Kosonsoy ",
      "Qoshko`pir ",
      "Dukchachi ",
      "Andijon qishloq",
      "Kamchik qishloq",
      "Namangan shahri",
      "Mirzo Ulug`bek ",
      "Sho`rchi ",
      "Navoiy ",
      "Yashnobod ",
      "Andijon ",
      "Qirg`iziston ",
      "Alisher Navoiy ",
      "Akmal Ikromov ",
      "Alpomish ",
      "Buvayda ",
      "Bogishamol ",
      "Tozabog` ",
      "Katta qishloq",
      "Kichik qishloq",
      "Norin",
      "Norin ",
      "Oltintepa ",
      "Javlon ",
      "Urganch qishloq",
      "Pop",
      "Pop ",
      "Jom`yoz ",
      "Gulshan ",
      "Bog`ishamol ",
      "Ergashev ",
      "Turakurgon qishloq",
      "Davlat qishloq",
      "Amir Temur qishloq",
      "Navruz qishloq",
      "Toraqorg`on",
      "Toraqorg`on ",
      "Beshkent ",
      "Dilovar ",
      "Imeni Kalinina ",
      "Mahmudxon ",
      "Navoiy ",
      "Okkurgan ",
      "Oqtepa ",
      "Saliq ",
      "Toqiman ",
      "Qushchi ",
      "Kichik qishloq",
      "Katta qishloq",
    ],
    Navoi: [
      "Karmana",
      "Avazkhon",
      "Bagishamal",
      "Chakar",
      "Farmonov",
      "Kalandar",
      "Kattakurgan",
      "Navruz",
      "Niyozbek",
      "Oltinsoy",
      "Pirmatov",
      "Qiziltepa",
      "Sanoatchi",
      "Konimekh",
      "Kyzyltepa",
      "Guldursun",
      "Javonon",
      "Mirobod",
      "Mollabegim",
      "Nurafshon",
      "Oltinkon",
      "Ozodlik",
      "Parkent",
      "Registon",
      "Shahrisabz",
      "Navbakhor",
      "Akmal-Abad",
      "Beruniy",
      "Gulshan",
      "Katta-Chirchik",
      "Navbakhor",
      "Qushbegi",
      "Ruzgar",
      "Shakhristan",
      "Yangiobod",
      "Nurota",
      "Andijon",
      "Bunyodkor",
      "Dustlik",
      "Hamidulla",
      "Iftixor",
      "Imom Buxoriy",
      "Mahmudxoja",
      "Navruz",
      "Nurota",
      "Oqtepa",
      "Sardoba",
      "Shoqman",
      "Zafar",
      "Tomdi",
    ],
    Kashkadar: [
      "Beshkent",
      "Boz-Bulok",
      "Katta-Tura",
      "Quyliq-Tura",
      "Qushrabot",
      "Yangi-Tura",
      "Chirakchi",
      "Achchi-Qayraton",
      "Chirakchi",
      "Kaltakirgan",
      "Yangi-Kishlak",
      "Dehkanabad",
      "Dehkanabad",
      "Kuyganyor",
      "Navoiy",
      "Oltinkan",
      "Tashkent",
      "Guzar",
      "Asaka",
      "Bostanlyk",
      "Guzar",
      "Jumadulla",
      "Tegirmay",
      "Kamashi",
      "Boborahim",
      "Kamashi",
      "Kanay",
      "Qushmurod",
      "Karshi",
      "Ciudad de Karshi",
      "Gallyaaral",
      "Gallyaaral Shahri",
      "Karshinskiy Rayon",
      "Tura-Tepa",
      "Kasbi",
      "Kasbi",
      "Kuchkak",
      "Zirabulak",
      "Kitab",
      "Ciudad de Shakhrisabz",
      "Baysun",
      "Kitab",
      "Shahrisabz",
      "Yakkabog",
      "Koson",
      "Gulshan",
      "Kosonsoy",
      "Ozodoy",
      "Sharaf-Rashidov",
      "Mirishkar",
      "Karabulak",
      "Mirishkar",
      "Urganchi",
      "Muborak",
      "Gulistan",
      "Muborak",
      "Zarbdor",
      "Myrishkor",
      "Khojamurod",
      "Myrishkor",
      "Yangiariq",
      "Nishon",
      "Bekabad",
      "Nishon",
      "Peshku",
      "Qizirik",
      "Yakkabag",
      "Chinaz",
      "Tashguzar",
      "Yangikurgan",
      "Yakkabag",
    ],
    Karakalpakistán: [
      "Amudarya",
      "Aktanysh",
      "Arnasay",
      "Berikul",
      "Chimbay",
      "Ichinay",
      "Ilbinsky",
      "Shavat",
      "Beruniy",
      "Askyndyr",
      "Beruniy",
      "Kamlyuk",
      "Karauzak",
      "Madaniyat",
      "Yalangach",
      "Chimbay",
      "Ciudad de Chimbay",
      "Ellikqala",
      "Aklak",
      "Bekabad",
      "Ellikqala",
      "Novobog",
      "Sultan-Uvays",
      "Tuytepa",
      "Kegeyli",
      "Aktobe",
      "Bala-Khanym",
      "Gurlen",
      "Kegeyli",
      "Kuyganysh",
      "Kurbonboy",
      "Khojayli",
      "Baynau",
      "Keles",
      "Khojayli",
      "Myrza",
      "Sagban",
      "Tugay",
      "Kungrad",
      "Aral",
      "Chyga",
      "Dzhangalik",
      "Koshkupir",
      "Kungrad",
      "Muykul",
      "Muynak",
      "Bostanlyk",
      "Kepez",
      "Muynak",
      "Nukus",
      "Tereklisay",
      "Nukus",
      "Ciudad de Nukus",
      "Karakalpakstan",
      "Qushokiy",
      "Shumanay",
      "Alat",
      "Arystanbab",
      "Kasansay",
      "Shumanay",
      "Tozay",
      "Takhtakupir",
      "Araltuz",
      "Bozarslan",
      "Izbek",
      "Takhtakupir",
      "Taxiatosh",
      "Kuyuk-Mazar",
      "Taxiatosh",
      "Temir",
      "Zhanibek",
      "Turtkul",
      "Buyuk Gorgan",
      "Karauzak",
      "Kyzyl-Arvat",
      "Novoturtkul",
      "Turtkul",
      "Xo'jayli",
      "Altyn-Terek",
      "Doshshamly",
      "Kyrk-Oba",
      "Novo-Shikhany",
      "Qishloq xo'jaligi jamoasi X",
    ],
    Samarcanda: [
      "Urgut",
      "Achakaynar",
      "Beshkent",
      "Bogishamol",
      "Chetgandzh",
      "Chinor",
      "Gijduvan",
      "Karatepa",
      "Karatokyo",
      "Katta-Urgut",
      "Koshriq",
      "Kungrad",
      "Makhram",
      "Qoqoncha",
      "Sufiyan",
      "Urgut Qal'asi",
      "Katta-Kurgan",
      "Katta-Kurgan",
      "Amanata",
      "Chinaz",
      "Ichinazar",
      "Izboskan",
      "Katta-Siratoya",
      "Kichik-Siratoya",
      "Kolkand",
      "Kushrabat",
      "Qiyatli",
      "Salar",
      "Ura-Tyube",
      "Ishtikhon",
      "Ishtikhon",
      "Avzak",
      "Bulun",
      "Chinartepa",
      "Gagarin",
      "Kanatli",
      "Kurkul",
      "Madrasa",
      "Mirsharif",
      "Nishan",
      "Padshakent",
      "Qilichli",
      "Sayyod",
      "Shahriston",
      "Tabar",
      "Tandyrchi",
      "Jambay",
      "Jambay",
      "Abdirakhman",
      "Bakhmal",
      "Boboatma",
      "Chinaz",
      "Chitgar",
      "Gajimardon",
      "Kaltakishlak",
      "Kichik-Ata",
      "Kukcha",
      "Kumash",
      "Pishan",
      "Qoqonchiq",
      "Rovat",
      "Shahimardon",
      "Sultonobod",
      "Tavallukan",
      "Tomdibuloq",
      "Toshlar",
      "Narpay",
      "Narpay",
      "Ayak-Kuz",
      "Boykovut",
      "Chorakishlak",
      "Ghazimardon",
      "Guldursun",
      "Ishobuloq",
      "Iskandar",
      "Khojabad",
      "Kichik-Ata",
      "Kuchak",
      "Pakhtachi",
      "Pakhtachi",
      "Bakhmal",
      "Burkhon",
      "Chimbay",
      "Chinaz",
      "Gallaorol",
      "Ishtikhan",
      "Jomboy",
      "Karatokyo",
      "Khojaabad",
      "Kiziltepa",
      "Navobod",
      "Qoqoncha",
      "Ravot",
      "Shahriston",
      "Payariq",
      "Payariq",
      "Alikovut",
      "Altynsoy",
      "Bogiston",
      "Chinoz",
      "Khodzhabakirgan",
      "Kyzylsuv",
      "Muminobod",
      "Novkul",
      "Payshakhantepa",
      "Qorakalpog'iston",
      "Shakhrikhan",
      "Pastdargom",
      "Pastdargom",
      "Bakhmal",
      "Bogdodon",
      "Chiroqchi",
      "Dargom",
      "Hazorasp",
      "Kattakurgan",
      "Keles",
      "Kuylyuk",
      "Navbakhor",
      "Ovchiq",
      "Oqoltin",
      "Pashshaabad",
      "Qoratol",
      "Qushkupir",
      "Tomsu",
      "Samarcanda",
      "Samarcanda",
      "Abdujalil",
      "Akdarmon",
      "Bakhmal",
      "Bulungur",
      "Darbant",
      "Dekhanabad",
      "Guli Surkh",
      "Istiklol",
      "Jambay",
      "Kuylyuk",
      "Mingbulok",
      "Muborak",
      "Nurabad",
      "Olmagul",
      "Qibray",
      "Saykhunabad",
      "Tumanny",
      "Yagly",
      "Taylak",
      "Taylak",
      "Bakhmal",
      "Balakchi",
      "Dashman",
      "Ishomkul",
      "Kharzamin",
      "Kukcha",
      "Kumushkan",
      "Navruz",
      "Niyari",
      "Oqtemir",
      "Taylak",
      "Tohiryo'ldosh",
      "Bulungur",
      "Bulungur",
      "Amanqul",
      "Angor",
      "Burchmullo",
      "Chorkozi",
      "Chimgan",
      "Dalvarzintepa",
      "Gizhduvan",
      "Katta-Tut",
      "Niyazov",
      "Ohangaron",
      "Qizilqum",
      "Sayyod",
      "Shahrikon",
      "Uchkuduk",
      "Yangiariq",
      "Konimex",
      "Konimex",
      "Qibray",
      "Qurgancha",
      "Shafirkan",
      "Shakhrikhan",
      "Teshiktosh",
      "Nurobod",
      "Nurobod",
      "Bagat",
      "Bagishamol",
      "Dasht",
      "Dukchi",
      "Gazalkent",
      "Gulbaqor",
      "Koshkupir",
      "Nafosat",
      "Shavkat",
      "Ulugbek",
      "Oqdarya",
      "Oqdarya",
      "Bog'iston",
      "Burchimullo",
      "Chirakchi",
      "Galdaryk",
      "Gulzor",
      "Kalacha",
      "Kamchik",
      "Kasbi",
      "Navbakhor",
      "Parykchi",
      "Rukhabad",
      "Yakkabag",
    ],
    Syrdarya: [
      "(Akaltyn)",
      "Akaltyn",
      "Bayankul",
      "Chapandoz",
      "Chinor",
      "Guldursun",
      "Karasaray",
      "Kattatopkan",
      "Oqoltin",
      "Payariq",
      "Toshlik",
      "(Gulistan)",
      "Asqar",
      "Bekmurod",
      "Gallaorol",
      "Gulistan",
      "Mahalla Yangi",
      "Niyozmurod",
      "Qiziltepa",
      "(Mirzaabad)",
      "Akchakchi",
      "Amirobod",
      "Buyukmirzaabad",
      "Chorkesar",
      "Karakulchi",
      "Khojikent",
      "Kuksaroy",
      "Kurgancha",
      "Navruz",
      "Novvoz",
      "(Saykhunabad)",
      "Bogiston",
      "Farovon",
      "Oqoltin",
      "Paygumbaz",
      "Saykhun",
      "Tohirabod",
      "(Sardoba)",
      "Akaltyn",
      "Beruniy",
      "Beshariq",
      "Chilanzar",
      "Dostlik",
      "Khurmatuy",
      "Qalacha",
      "Sardoba",
      "Yangi Sardoba",
      "(Sherali)",
      "Akmal-Abad",
      "Alpomish",
      "Boboyor",
      "Boshkuy",
      "Karabog",
      "Mashal",
      "Navbahor",
      "Paxtakor",
      "Sherali",
      "Shomil",
      "Uchtepa",
      "Zangiota",
      "(Yangiyer)",
      "Akmal-Abad",
      "Baland",
      "Chortok",
      "Inoqchi",
      "Kangly",
      "Mirkishilgan",
      "Musabot",
      "Namozgokh",
      "Shosh",
      "Toghdumbulak",
      "Umrabuloq",
      "Uzun",
      "Yangiyer",
      "Yangiyul",
      "(Yazyavan)",
      "Abdurashidxon",
      "Akshokh",
      "Burchmulla",
      "Farhad",
      "Gavkhoni",
      "Imomkhon",
      "Qoraboy",
      "Qushbegi",
      "Yazyavan",
    ],
    Surjandaria: [
      "(Boysun) ",
      "Boyteghay ",
      "Qorako‘l ",
      "Shovaliq ",
      "Shurobod ",
      "Tuyabug‘iz ",
      "(Denov)",
      "Balandcha ",
      "Dangara ",
      "Denov ",
      "Kizirik ",
      "Navbahor ",
      "(Jarqo'rg'on) ",
      "Bog‘ot ",
      "Buston ",
      "Gulzor ",
      "Jarqo‘rg‘on ",
      "Navruz ",
      "(Kumqo'rg'on) ",
      "Bekobod ",
      "Kumqo‘rg‘on ",
      "Nishon ",
      "(Muzrabot) ",
      "Karshi makhalla ",
      "Khalkobod ",
      "Muzrabot ",
      "Sabirabad ",
      "(Oltinsoy) ",
      "Karatog ",
      "Olmos ",
      "Oltinsoy ",
      "(Sariosiyo( ",
      "Guliston ",
      "Sariosiyo ",
      "(Sherobod) ",
      "Kaltako‘l ",
      "Kattaqo‘rg‘on ",
      "Navbakhor ",
      "Qalacha ",
      "Sherobod ",
      "(Shurchi) ",
      "Chiroqchi ",
      "Nishonchi ",
      "Shurchi ",
      "(Termiz shahar)",
      "Alpomish ",
      "Chilonzor ",
      "Shahrixon ",
      "Termiz-1 ",
      "Termiz-2 ",
      "Xo‘jayli ",
      "(Uzun) ",
      "Markhamat ",
      "Paygambar ",
      "Qorasuv ",
      "Uzun ",
      "(Baysun) ",
      "Baysun ",
      "Sariaso‘t ",
      "(Qiziriq) ",
      "G‘uzor ",
      "Qiziriq ",
      "(Angor) ",
      "Angor ",
      "Darband ",
      "(Bandixon) ",
      "Bandixon ",
      "Qushrabot ",
    ],
    Taskent: [
      "(Akhangaran)",
      "Akhangaran",
      "Akhangaranskiy",
      "Bektemir",
      "Chirchiq",
      "Jangeldi",
      "Yangibazar",
      "(Bekabad)",
      "Bekabad",
      "Gagarin",
      "Novobekabad",
      "Yangiobod",
      "(Bostanliq)",
      "Buka",
      "Burchimullo",
      "Karamazar",
      "Kushkoroy",
      "Parkent",
      "Shovvoz",
      "(Changyrabad)",
      "Chirchik",
      "Akhangaran",
      "Aral",
      "Buka",
      "Chirchikchi",
      "Yangiabad",
      "(Chinaz)",
      "Chinaz",
      "Ak-Tepa",
      "Bayaut",
      "Chimion",
      "Urtacha",
      "(Ohangaron)",
      "Ohangaron",
      "Chinor",
      "Khodjaobad",
      "Nurafshon",
      "Uchtepa",
      "(Parkent)",
      "Parkent",
      "Dustlik",
      "Karakamysh",
      "Mirzatag",
      "Pakhtaabad",
      "(Pskent)",
      "Pskent",
      "Boyovut",
      "Gulbakhor",
      "Kuyumazar",
      "Yangikurgan",
      "(Quyichirchiq)",
      "Quyichirchiq",
      "Katta-Chirchik",
      "Kiziljar",
      "Malik",
      "Uzun",
      "(Tashkent)",
      "Almazar",
      "Bektemir",
      "Chilonzor",
      "Mirabad",
      "Sergeli",
      "Shaykhontohur",
      "Uchtepa",
      "Yunusabad",
      "Yakkasaray",
      "(Yangiyol)",
      "Yangiyol",
      "Chinorsoy",
      "Gulbahor",
      "Qushbegi",
      "Zangiatin",
      "(Yukori-Chirchiq)",
      "Yangibazar",
      "Akmechet",
      "Bostanlyk",
      "Chirchikchi",
      "Saksaulskiy",
      "(Zangiota)",
      "Zangiota",
      "Burchimullo",
      "Karakul",
      "Kuyganyor",
      "Yukorichirchik",
      "(Zafarobod)",
      "Zafarobod",
      "Agol",
      "Burchmullo",
      "Navoiy",
      "Ulugbek",
      "(Dustlik)",
      "Dustlik",
      "Akaltyn",
      "Altynkul",
      "Tinchlik",
      "Urganch",
    ],
  },
  Venezuela: {
    Amazonas: [
      "Alto Orinoco",
      "Atabapo",
      "Atures",
      "Autana",
      "Manapiare",
      "Maroa",
      "Río Negro",
    ],
    Anzoátegui: [
      "Anaco",
      "Aragua",
      "Bolívar",
      "Bruzual",
      "Cajigal",
      "Carvajal",
      "Diego Bautista Urbaneja",
      "Freites",
      "Guanipa",
      "Guanta",
      "Independencia",
      "Libertad",
      "McGregor",
      "Miranda",
      "Monagas",
      "Peñalver",
      "Píritu",
      "San Juan de Capistrano",
      "Santa Ana",
      "Simón Rodríguez",
      "Sotillo",
    ],
    Apure: [
      "Achaguas",
      "Biruaca",
      "Camejo",
      "Muñoz",
      "Páez",
      "Rómulo Gallegos",
      "San Fernando",
    ],
    Aragua: [
      "Bolívar",
      "Camatagua",
      "Francisco Linares Alcántara",
      "Girardot",
      "José Ángel Lamas",
      "José Félix Ribas",
      "José Rafael Revenga",
      "Libertador",
      "Mario Briceño Iragorry",
      "Ocumare de la Costa de Oro",
      "San Casimiro",
      "San Sebastián",
      "Santiago Mariño",
      "Santos Michelena",
      "Sucre",
      "Tovar",
      "Urdaneta",
      "Zamora",
    ],
    Barinas: [
      "Arismendi",
      "Barinas",
      "Blanco",
      "Bolívar",
      "Cruz Paredes",
      "Obispos",
      "Pedraza",
      "Rojas",
      "Sosa",
      "Sucre",
      "Torrealba",
      "Zamora",
    ],
    Bolívar: [
      "Angostura",
      "Angostura del Orinoco",
      "Caroní",
      "Cedeño",
      "Chien",
      "El Callao",
      "Gran Sabana",
      "Piar",
      "Roscio",
      "Sifontes",
      "Sucre",
    ],
    Carabobo: [
      "Bejuma",
      "Carlos Arvelo",
      "Diego Ibarra",
      "Guacara",
      "Juan José Mora",
      "Libertador",
      "Los Guayos",
      "Miranda",
      "Montalbán",
      "Naguanagua",
      "Puerto Cabello",
      "San Diego",
      "San Joaquín",
      "Valencia",
    ],
    Cojedes: [
      "Anzoátegui",
      "Ezequiel Zamora",
      "Girardot",
      "Lima Blanco",
      "Pao de San Juan Bautista",
      "Ricaurte",
      "Rómulo Gallegos",
      "Tinaco",
      "Tinaquillo",
    ],
    "Delta Amacuro": ["Antonio Díaz", "Casacoima", "Pedernales", "Tucupita"],
    "Dependencias Federales": ["Dependencias Federales"],
    "Distrito Capital": ["Distrito Capital"],
    Falcón: [
      "Acosta",
      "Bolívar",
      "Buchivacoa",
      "Carirubana",
      "Colina",
      "Dabajuro",
      "Democracia",
      "Falcón",
      "Federación",
      "Iturriza",
      "Jacura",
      "Los Taques",
      "Manaure",
      "Mauroa",
      "Miranda",
      "Palmasola",
      "Petit",
      "Píritu",
      "San Francisco",
      "Silva",
      "Sucre",
      "Tocópero",
      "Unión",
      "Urumaco",
      "Zamora",
    ],
    Guárico: [
      "Camaguán",
      "Chaguaramas",
      "El Socorro",
      "Francisco de Miranda",
      "José Félix Ribas",
      "José Tadeo Monagas",
      "Juan Germán Roscio",
      "Juan José Rondón",
      "Julián Mellado",
      "Leonardo Infante",
      "Ortiz",
      "San Gerónimo de Guayabal",
      "San José de Guaribe",
      "Santa María de Ipire",
      "Zaraza",
    ],
    "La Guaira": ["La Guaira"],
    Lara: [
      "Andrés Eloy Blanco",
      "Crespo",
      "Iribarren",
      "Jiménez",
      "Morán",
      "Palavecino",
      "Simón Planas",
      "Torres",
      "Urdaneta",
    ],
    Mérida: [
      "Alberto Adriani",
      "Andrés Bello",
      "Antonio Pinto Salinas",
      "Aricagua",
      "Arzobispo Chacón",
      "Campo Elías",
      "Caracciolo Parra Olmedo",
      "Cardenal Quintero",
      "Guaraque",
      "Julio César Salas",
      "Justo Briceño",
      "Libertador",
      "Miranda",
      "Obispo Ramos de Lora",
      "Padre Noguera",
      "Pueblo Llano",
      "Rangel",
      "Rivas Dávila",
      "Santos Marquina",
      "Sucre",
      "Tovar",
      "Tulio Febres Cordero",
      "Zea",
    ],
    Miranda: [
      "Acevedo",
      "Andrés Bello",
      "Baruta",
      "Bolívar",
      "Brión",
      "Buroz",
      "Carrizal",
      "Chacao",
      "Cristóbal Rojas",
      "El Hatillo",
      "Guaicaipuro",
      "Gual",
      "Independencia",
      "Lander",
      "Los Salias",
      "Páez",
      "Paz Castillo",
      "Plaza",
      "Sucre",
      "Urdaneta",
      "Zamora",
    ],
    Monagas: [
      "Acosta",
      "Aguasay",
      "Bolívar",
      "Caripe",
      "Cedeño",
      "Libertador",
      "Maturín",
      "Piar",
      "Punceres",
      "Santa Bárbara",
      "Sotillo",
      "Uracoa",
      "Zamora",
    ],
    Nueva_Esparta: [
      "Antolín del Campo",
      "Arismendi",
      "Antonio Díaz",
      "García",
      "Gómez",
      "Maneiro",
      "Marcano",
      "Mariño",
      "Macanao",
      "Tubores",
      "Villalba",
    ],
    Portuguesa: [
      "Agua Blanca",
      "Araure",
      "Esteller",
      "Guanare",
      "Guanarito",
      "José Vicente de Unda",
      "Ospino",
      "Páez",
      "Papelón",
      "San Genaro de Boconoíto",
      "San Rafael de Onoto",
      "Santa Rosalía",
      "Sucre",
      "Turén",
    ],
    Sucre: [
      "Andrés Eloy Blanco",
      "Andrés Mata",
      "Arismendi",
      "Benítez",
      "Bermúdez",
      "Bolívar",
      "Cajigal",
      "Cruz Salmerón Acosta",
      "Libertador",
      "Mariño",
      "Mejía",
      "Montes",
      "Ribero",
      "Sucre",
      "Valdez",
    ],
    Táchira: [
      "Andrés Bello",
      "Antonio Rómulo Costa",
      "Ayacucho",
      "Bolívar",
      "Cárdenas",
      "Córdoba",
      "Fernández Feo",
      "Francisco de Miranda",
      "García de Hevia",
      "Guásimos",
      "Independencia",
      "Jáuregui",
      "José María Vargas",
      "Junín",
      "Libertad",
      "Libertador",
      "Lobatera",
      "Michelena",
      "Panamericano",
      "Pedro María Ureña",
      "Rafael Urdaneta",
      "Samuel Dario Maldonado",
      "San Cristóbal",
      "San Judas Tadeo",
      "Seboruco",
      "Simón Rodríguez",
      "Sucre",
      "Torbes",
      "Uribante",
    ],
    Trujillo: [
      "Andrés Bello",
      "Boconó",
      "Bolívar",
      "Campo Elías",
      "Candelaria",
      "Carache",
      "Carvajal",
      "Escuque",
      "La Ceiba",
      "Márquez Cañizales",
      "Miranda",
      "Monte Carmelo",
      "Motatán",
      "Pampán",
      "Pampanito",
      "Rangel",
      "Sucre",
      "Trujillo",
      "Urdaneta",
      "Valera",
    ],
    Yaracuy: [
      "Bastidas",
      "Bolívar",
      "Bruzual",
      "Cocorote",
      "Independencia",
      "La Trinidad",
      "Monge",
      "Nirgua",
      "Páez",
      "Peña",
      "San Felipe",
      "Sucre",
      "Urachiche",
      "Veroes",
      "Yaracuy",
    ],
    Zulia: [
      "Almirante Padilla",
      "Baralt",
      "Cabimas",
      "Catatumbo",
      "Colón",
      "Francisco Javier Pulgar",
      "Guajira",
      "Jesús Enrique Lossada",
      "Jesús María Semprúm",
      "La Cañada de Urdaneta",
      "Lagunillas",
      "Machiques de Perijá",
      "Mara",
      "Maracaibo",
      "Miranda",
      "Rosario de Perijá",
      "San Francisco",
      "Santa Rita",
      "Simón Bolívar",
      "Sucre",
      "Valmore Rodríguez",
    ],
  },
  Vietnam: {
    "Bắc Ninh": [
      "Bắc Ninh",
      "Yên Phong",
      "Quế Võ",
      "Tiên Du",
      "Thuận Thành",
      "Gia Bình",
      "Lương Tài",
    ],
    "Hà Nam": ["Bình Lục", "Đại Từ", "Kim Bảng", "Lý Nhân", "Thanh Liêm"],
    "Hải Dương": [
      "Bình Giang",
      "Cẩm Giàng",
      "Gia Lộc",
      "Kim Thành",
      "Kim Động",
      "Thanh Hà",
      "Thanh Miện",
      "Tứ Kỳ",
      "Xã Đàn",
      "Nam Sách",
    ],
    "Hưng Yên": [
      "An Thi",
      "Khoái Châu",
      "Kim Động",
      "Mỹ Hào",
      "Phù Cừ",
      "Tiên Lữ",
      "Văn Giang",
      "Văn Lâm",
      "Yên Mỹ",
    ],
    "Nam Định": [
      "Giao Thủy",
      "Hải Hậu",
      "Mỹ Lộc",
      "Nam Định",
      "Nam Trực",
      "Nghĩa Hưng",
      "Trực Ninh",
      "Vụ Bản",
      "Xuân Trường",
      "Ý Yên",
    ],
    "Ninh Binh": [
      "Gia Viễn",
      "Hoa Lư",
      "Kim Sơn",
      "Yên Khánh",
      "Yên Mô",
      "Nho Quan",
      "Tân Sơn",
    ],
    "Thái Bình": [
      "Đông Hưng",
      "Hưng Hà",
      "Kiến Xương",
      "Quỳnh Phụ",
      "Thái Thụy",
      "Tiền Hải",
      "Vũ Thư",
    ],
    "Vĩnh Phúc": [
      "Bình Xuyên",
      "Lập Thạch",
      "Sông Lô",
      "Tam Đảo",
      "Tam Dương",
      "Vĩnh Tường",
      "Yên Lạc",
    ],
    Hanói: [
      "Ba Đình",
      "Cầu Giấy",
      "Đống Đa",
      "Hà Đông",
      "Hai Bà Trưng",
      "Hoàn Kiếm",
      "Hoàng Mai",
      "Long Biên",
      "Nam Từ Liêm",
      "Tây Hồ",
      "Thanh Xuân",
      "Sơn Tây",
    ],
    "Hải Phòng": [
      "An Dương",
      "An Lão",
      "Bạch Long Vĩ",
      "Cát Hải",
      "Đồ Sơn",
      "Dương Kinh",
      "Hải An",
      "Hồng Bàng",
      "Kiến An",
      "Lê Chân",
      "Ngô Quyền",
      "Tiên Lãng",
      "Vĩnh Bảo",
    ],
    "Hà Tĩnh": [
      "Cẩm Xuyên",
      "Can Lộc",
      "Hương Khê",
      "Hương Sơn",
      "Kỳ Anh",
      "Kỳ Lợi",
      "Nghi Xuân",
      "Thạch Hà",
      "Vũ Quang",
      "Đức Thọ",
    ],
    "Nghệ An": [
      "Anh Sơn",
      "Con Cuông",
      "Diễn Châu",
      "Đô Lương",
      "Hưng Nguyên",
      "Kỳ Sơn",
      "Nam Đàn",
      "Nam Giang",
      "Nghi Lộc",
      "Quế Phong",
      "Quỳ Châu",
      "Quỳ Hợp",
      "Quỳnh Lưu",
      "Tân Kỳ",
      "Thái Hòa",
      "Thanh Chương",
      "Tương Dương",
      "Yên Thành",
    ],
    "Quảng Bình": [
      "Ba Đồn",
      "Bố Trạch",
      "Đồng Hới",
      "Lệ Thủy",
      "Minh Hóa",
      "Quảng Ninh",
      "Quảng Trạch",
      "Tuyên Hóa",
    ],
    "Quảng Trị": [
      "Căm Lộc",
      "Cồn Cỏ",
      "Đa Krông",
      "Gio Linh",
      "Hải Lăng",
      "Hướng Hóa",
      "Triệu Phong",
      "Vĩnh Linh",
    ],
    "Thanh Hóa": [
      "Bá Thước",
      "Cẩm Thủy",
      "Đông Sơn",
      "Hà Trung",
      "Hậu Lộc",
      "Hoằng Hóa",
      "Lang Chánh",
      "Mường Lát",
      "Nga Sơn",
      "Ngọc Lặc",
      "Như Thanh",
      "Như Xuân",
      "Nông Cống",
      "Quan Hóa",
      "Quan Sơn",
      "Quảng Xương",
      "Thạch Thành",
      "Thiệu Hóa",
      "Thọ Xuân",
      "Thường Xuân",
      "Tĩnh Gia",
      "Triệu Sơn",
      "Vĩnh Lộc",
      "Yên Định",
    ],
    "Thừa Thiên-Huế": [
      "A Lưới",
      "Phong Điền",
      "Phú Lộc",
      "Phú Vang",
      "Quảng Điền",
      "Huế ",
    ],
    "Bắc Giang": [
      "Bắc Giang ",
      "Hiep Hoa",
      "Lạng Giang",
      "Lục Nam",
      "Lục Ngạn",
      "Hiệp Hòa",
      "Thanh Miện",
      "Sơn Động",
      "Việt Yên",
      "Yên Dũng",
    ],
    "Bắc Kạn": [
      "Bắc Kạn ",
      "Bạch Thông",
      "Chợ Đồn",
      "Chợ Mới",
      "Na Rì",
      "Ngân Sơn",
      "Ba Bể",
    ],
    "Cao Bằng": [
      "Bảo Lạc",
      "Bảo Lâm",
      "Hạ Lang",
      "Ha Thanh",
      "Hòa An",
      "Nguyên Bình",
      "Phục Hòa",
      "Quảng Uyên",
      "Thạch An",
      "Thạch An",
      "Thống Nhất",
      "Trùng Khánh",
    ],
    "Hà Giang": [
      "Bắc Quang",
      "Đồng Văn",
      "Hà Giang (distrito urbano)",
      "Hoàng Su Phì",
      "Mèo Vạc",
      "Quản Bạ",
      "Quang Bình",
      "Vị Xuyên",
      "Xín Mần",
      "Yên Minh",
    ],
    "Lạng Sơn": [
      "Ba Chẽ",
      "Chi Lăng",
      "Cao Lộc",
      "Đình Lập",
      "Hữu Lũng",
      "Lộc Bình",
      "Tràng Định",
      "Văn Lãng",
      "Văn Quan",
    ],
    "Lào Cai": [
      "Bắc Hà",
      "Bảo Thắng",
      "Bảo Yên",
      "Sa Pa",
      "Văn Bàn",
      "Bát Xát",
      "Mường Khương",
      "Si Ma Cai",
    ],
    "Phú Thọ": [
      "Cẩm Khê",
      "Đoan Hùng",
      "Hạ Hoà",
      "Lâm Thao",
      "Phù Ninh",
      "Phú Thọ (distrito urbano)",
      "Tam Nông",
      "Tân Sơn",
      "Thanh Ba",
      "Thanh Sơn",
      "Thanh Thủy",
    ],
    "Quảng Ninh": [
      "Ba Chẽ",
      "Bình Liêu",
      "Cô Tô",
      "Đầm Hà",
      "Đông Triều",
      "Hải Hà",
      "Hòn Gai",
      "Móng Cái ",
      "Quảng Yên",
      "Tiên Yên",
      "Uông Bí ",
      "Vân Đồn",
    ],
    "Thái Nguyên": [
      "Đại Từ",
      "Định Hóa",
      "Đồng Hỷ",
      "Phổ Yên",
      "Phú Bình",
      "Sông Công ",
      "Thái Nguyên ",
      "Võ Nhai",
    ],
    "Tuyên Quang": [
      "Chiêm Hóa",
      "Hàm Yên",
      "Lâm Bình",
      "Na Hang",
      "Sơn Dương",
      "Tuyên Quang ",
      "Yên Sơn",
    ],
    "Yên Bái": [
      "Lục Yên",
      "Mù Cang Chải",
      "Nghĩa Lộ",
      "Trạm Tấu",
      "Trấn Yên",
      "Văn Chấn",
      "Văn Yên",
      "Yên Bình",
      "Yên Bình",
      "Yên Bình",
      "Yên Thế",
    ],
    "Điện Biên": [
      "Điện Biên ",
      "Điện Biên Đông",
      "Điện Biên Phủ",
      "Mường Ảng",
      "Mường Chà",
      "Mường Nhé",
      "Nậm Pồ",
      "Tủa Chùa",
      "Tủa Thắng",
    ],
    "Hòa Bình": [
      "Cao Phong",
      "Đà Bắc",
      "Hòa Bình",
      "Kim Bôi",
      "Kỳ Sơn",
      "Lạc Sơn",
      "Lạc Thủy",
      "Lương Sơn",
      "Mai Châu",
      "Tân Lạc",
      "Yên Thủy",
    ],
    "Lai Châu": [
      "Mường Tè",
      "Phong Thổ",
      "Sìn Hồ",
      "Tân Uyên",
      "Tủa Chùa",
      "Than Uyên",
      "Nậm Nhùn",
      "Lai Châu",
    ],
    "Sơn La": [
      "A Mú Sung",
      "Bắc Yên",
      "Điện Biên Đông",
      "Mai Sơn",
      "Mộc Châu",
      "Phù Yên",
      "Quỳnh Nhai",
      "Sông Mã",
      "Sốp Cộp",
      "Thuận Châu",
      "Vân Hồ",
      "Yên Châu",
      "Sơn La ",
    ],
    "Đắk Lắk": [
      "Buôn Đôn",
      "Cư Kuin",
      "Cư M'gar",
      "Ea H'leo",
      "Ea Kar",
      "Ea Súp",
      "Krông Ana",
      "Krông Bông",
      "Krông Buk",
      "Krông Năng",
      "Krông Pắc",
      "Lắk",
      "M'Đrắk",
    ],
    "Đăk Nông": [
      "Cư Jút",
      "Đắk Glong",
      "Đắk Mil",
      "Đắk R'Lấp",
      "Đắk Song",
      "Krông Nô",
      "Tuy Đức",
    ],
    "Gia Lai": [
      "Chư Păh",
      "Chư Prông",
      "Chư Pưh",
      "Chư Sê",
      "Đăk Đoa",
      "Đăk Pơ",
      "Ia Grai",
      "Ia Pa",
      "KBang",
      "Kông Chro",
      "Krông Pa",
      "Mang Yang",
      "Phú Thiện",
    ],
    "Kon Tum": [
      "Đắk Glei",
      "Đắk Hà",
      "Đắk Tô",
      "Ia H'Drai",
      "Kon Plông",
      "Kon Rẫy",
      "Ngọc Hồi",
      "Sa Thầy",
      "Tu Mơ Rông",
    ],
    "Lâm Đồng": [
      "Bảo Lâm",
      "Bảo Lộc",
      "Cát Tiên",
      "Đạ Huoai",
      "Đạ Tẻh",
      "Đam Rông",
      "Di Linh",
      "Đơn Dương",
      "Đức Trọng",
      "Lạc Dương",
    ],
    "Bình Định": [
      "An Lão",
      "An Nhơn",
      "Hoài Ân",
      "Hoài Nhơn",
      "Phù Cát",
      "Phù Mỹ",
      "Quy Nhơn",
      "Tây Sơn",
      "Vân Canh",
      "Vĩnh Thạnh",
    ],
    "Khánh Hòa": [
      "Cam Lâm",
      "Cam Ranh",
      "Diên Khánh",
      "Khánh Sơn",
      "Khánh Vĩnh",
      "Ninh Hòa",
      "Trường Sa",
    ],
    "Phú Yên": [
      "Đông Hòa",
      "Đồng Xuân",
      "Phú Hòa",
      "Phú Lộc",
      "Sông Cầu",
      "Sông Hinh",
      "Sơn Hòa",
      "Tây Hòa",
      "Tuy An",
    ],
    "Quảng Nam": [
      " Bắc Trà My",
      "Đại Lộc",
      "Điện Bàn",
      "Duy Xuyên",
      "Hiệp Đức",
      "Nam Giang",
      "Nam Trà My",
      "Nông Sơn",
      "Núi Thành",
      "Phú Ninh",
      "Phước Sơn",
      "Quế Sơn",
      "Tây Giang",
      "Thăng Bình",
      "Tiên Phước",
      "Hội An",
      "Tam Kỳ",
    ],
    "Quảng Ngãi": [
      "Bình Sơn",
      "Bình Thuận",
      "Đức Phổ",
      "Lý Sơn",
      "Minh Long",
      "Mộ Đức",
      "Nghĩa Hành",
      "Sơn Hà",
      "Sơn Tây",
      "Sơn Tịnh",
      "Tây Trà",
      "Trà Bồng",
      "Tư Nghĩa",
      "Ba Tơ",
      "Sa Huỳnh",
      "Son Tinh",
    ],
    "Đà Nẵng": [
      "Liên Chiểu",
      "Thanh Khê",
      "Hải Châu",
      "Sơn Trà",
      "Ngũ Hành Sơn",
      "Cẩm Lệ",
      "Hòa Vang",
    ],
    "Bà Rịa-Vũng Tàu": [
      "Châu Đức",
      "Côn Đảo",
      "Đất Đỏ",
      "Long Điền",
      "Tân Thành",
      "Vũng Tàu",
    ],
    "Bình Dương": [
      "Thủ Dầu Một",
      "Bàu Bàng",
      "Bến Cát",
      "Dầu Tiếng",
      "Phú Giáo",
      "Tân Uyên",
      "Thuận An",
      "Di An",
      "Thuận An",
    ],
    "Bình Phước": [
      "Đồng Phú",
      "Bù Đăng",
      "Chơn Thành",
      "Hớn Quản",
      "Bù Đốp",
      "Phú Riềng",
      "Đồng Xoài",
      "Bình Long",
      "Lộc Ninh",
      "Ân Thiện",
      "Phước Long",
      "Định Quán",
    ],
    "Bình Thuận": [
      "Phan Thiết",
      "La Gi",
      "Tuy Phong",
      "Bắc Bình",
      "Hàm Thuận Bắc",
      "Hàm Thuận Nam",
      "Đức Linh",
      "Tánh Linh",
      "Hàm Tân",
      "Phú Quý",
    ],
    "Đồng Nai": [
      "Biên Hòa",
      "Cẩm Mỹ",
      "Định Quán",
      "Long Khánh",
      "Long Thành",
      "Nhơn Trạch",
      "Tân Phú",
      "Thống Nhất",
      "Trảng Bom",
      "Vĩnh Cửu",
      "Xuân Lộc",
    ],
    "Ninh Thuận": [
      "Phan Rang-Tháp Chàm",
      "Bác Ái",
      "Ninh Hải",
      "Ninh Phước",
      "Ninh Sơn",
      "Thuận Bắc",
      "Thuận Nam",
    ],
    "Tây Ninh": [
      "Châu Thành",
      "Dương Minh Châu",
      "Gò Dầu",
      "Hòa Thành",
      "Tân Biên",
      "Tân Châu",
      "Thạnh Tân",
      "Trảng Bàng",
    ],
    "Ho Chi Minh": [
      "Quận 1",
      "Quận 2",
      "Quận 3",
      "Quận 4",
      "Quận 5",
      "Quận 6",
      "Quận 7",
      "Quận 8",
      "Quận 9",
      "Quận 10",
      "Quận 11",
      "Quận 12",
      "Gò Vấp",
      "Bình Thạnh",
      "Tân Bình",
      "Tân Phú",
      "Phú Nhuận",
      "Bình Tân",
      "Củ Chi",
      "Hóc Môn",
      "Bình Chánh",
      "Nhà Bè",
      "Cần Giờ",
    ],
    "An Giang": [
      "An Phú",
      "Châu Đốc",
      "Châu Phú",
      "Chợ Mới",
      "Phú Tân",
      "Thạch An",
      "Tịnh Biên",
      "Tri Tôn",
    ],
    "Bạc Liêu": [
      "Thị xã Bạc Liêu",
      "Huyện Đông Hải",
      "Huyện Giá Rai",
      "Huyện Hoà Bình",
      "Huyện Hồng Dân",
      "Huyện Phước Long",
    ],
    "Bến Tre": [
      "Châu Thành",
      "Chợ Lách",
      "Mỏ Cày Bắc",
      "Mỏ Cày Nam",
      "Thạnh Phú",
      "Giong Trom",
      "Bình Đại",
      "Ba Tri",
      "Thanh Phu",
      "Mỹ Tho",
    ],
    " Cà Mau": [
      "Thành phố Cà Mau",
      "Thị xã Ngọc Hiển",
      "Huyện Cái Nước",
      "Huyện Đầm Dơi",
      "Huyện Năm Căn",
      "Huyện Phú Tân",
      "Huyện Thới Bình",
      "Huyện Trần Văn Thời",
      "Huyện U Minh",
      "Huyện Thới Lai",
    ],
    "Đồng Tháp": [
      "Cao Lãnh",
      "Châu Thành",
      "Hồng Ngự",
      "Lai Vung",
      "Lấp Vò",
      "Tam Nông",
      "Tân Hồng",
      "Thanh Bình",
      "Tháp Mười",
      "Châu Thành A",
    ],
    "Hậu Giang": [
      "Thành phố Vị Thanh",
      "Huyện Châu Thành",
      "Huyện Châu Thành A",
      "Huyện Long Mỹ",
      "Huyện Phụng Hiệp",
      "Huyện Vị Thủy",
      "Thị xã Ngã Bảy",
    ],
    "Kiên Giang": [
      "Thành phố Rạch Giá",
      "Thị xã Hà Tiên",
      "Huyện An Biên",
      "Huyện An Minh",
      "Huyện Châu Thành",
      "Huyện Giang Thành",
      "Huyện Giồng Riềng",
      "Huyện Gò Quao",
      "Huyện Hòn Đất",
      "Huyện Kiên Hải",
      "Huyện Kiên Lương",
      "Huyện Phú Quốc",
      "Huyện Tân Hiệp",
      "Huyện U Minh Thượng",
      "Huyện Vĩnh Thuận",
    ],
    "Long An": [
      "Thành phố Tân An",
      "Huyện Bến Lức",
      "Huyện Cần Đước",
      "Huyện Cần Giuộc",
      "Huyện Châu Thành",
      "Huyện Đức Hòa",
      "Huyện Đức Huệ",
      "Huyện Mộc Hóa",
      "Huyện Tân Hưng",
      "Huyện Tân Thạnh",
      "Huyện Tân Trụ",
      "Huyện Thạnh Hóa",
      "Huyện Thủ Thừa",
    ],
    "Sóc Trăng": [
      "Thành phố Sóc Trăng",
      "Huyện Châu Thành",
      "Huyện Cù Lao Dung",
      "Huyện Kế Sách",
      "Huyện Long Phú",
      "Huyện Mỹ Tú",
      "Huyện Mỹ Xuyên",
      "Huyện Ngã Năm",
      "Huyện Thạnh Trị",
      "Huyện Trần Đề",
      "Huyện Vĩnh Châu",
    ],
    "Tiền Giang": [
      "Thành phố Mỹ Tho",
      "Huyện Cái Bè",
      "Huyện Cai Lậy",
      "Huyện Châu Thành",
      "Huyện Chợ Gạo",
      "Huyện Gò Công Đông",
      "Huyện Gò Công Tây",
      "Huyện Tân Phú Đông",
      "Huyện Tân Phước",
      "Thị xã Gò Công",
    ],
    " Trà Vinh": [
      "Thành phố Trà Vinh",
      "Huyện Càng Long",
      "Huyện Cầu Kè",
      "Huyện Châu Thành",
      "Huyện Tiểu Cần",
      "Huyện Cầu Ngang",
      "Huyện Đại Ân",
      "Huyện Trà Cú",
      "Huyện Duyên Hải",
    ],
    "Vĩnh Long": [
      "Thành phố Vĩnh Long",
      "Huyện Bình Minh",
      "Huyện Bình Tân",
      "Huyện Long Hồ",
      "Huyện Mang Thít",
      "Huyện Tam Bình",
      "Huyện Trà Ôn",
      "Huyện Vũng Liêm",
    ],
    "Cần Thơ": [
      "Quận Ninh Kiều",
      "Quận Bình Thủy",
      "Quận Cái Răng",
      "Quận Ô Môn",
      "Quận Thốt Nốt",
      "Huyện Phong Điền",
      "Huyện Cờ Đỏ",
      "Huyện Vĩnh Thạnh",
      "Huyện Thới Lai",
    ],
  },
  Zambia: {
    Copperbelt: [
      "Kitwe",
      "Ndola",
      "Mufulira",
      "Luanshya",
      "Chingola",
      "Chililabombwe",
    ],
    Luapula: [
      "Chiengi",
      "Kawambwa",
      "Mansa",
      "Milenge",
      "Mwense",
      "Nchelenge",
      "Samfya",
    ],
    Lusaka: ["Chongwe", "Kafue", "Luangwa", "Lusaka"],
    Muchinga: [
      "Chama",
      "Chinsali",
      "Isoka",
      "Mafinga",
      "Mpika",
      "Nakonde",
      "Shiwangandu",
    ],
    "Central Zambia": [
      "Chibombo",
      "Kabwe",
      "Kapiri Mposhi",
      "Mkushi",
      "Mumbwa",
      "Serenje",
    ],
    "Norte Zambia": [
      "Chilubi",
      "Chinsali",
      "Isoka",
      "Kaputa",
      "Kasama",
      "Luwingu",
      "Mbala",
      "Mporokoso",
      "Mpika",
      "Mpulungu",
      "Mungwi",
      "Nakonde",
    ],
    "Sur Zambia": [
      "Choma",
      "Gwembe",
      "Itezhi-Tezhi",
      "Kalomo",
      "Kazungula",
      "Livingstone",
      "Mazabuka",
      "Monze",
      "Namwala",
      "Siavonga",
      "Sinazongwe",
    ],
    "Noroeste Zambia": [
      "Chavuma",
      "Kabompo",
      "Kasempa",
      "Mufumbwe",
      "Mwinilunga",
      "Solwezi",
      "Zambezi",
    ],
    "Occidental Zambia": [
      "Kalabo",
      "Kaoma",
      "Lukulu",
      "Mongu",
      "Senanga",
      "Sesheke",
      "Shangombo",
    ],
    "Oriental Zambia": [
      "Chadiza",
      "Chama",
      "Chipata",
      "Katete",
      "Lundazi",
      "Mambwe",
      "Nyimba",
      "Petauke",
    ],
  },
};

export { paises };
