import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { ProductoData } from "src/app/models/ProductoData";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { User } from "@angular/fire/auth";
import { AuthService } from "src/app/services/auth.service";
import { ProductoService } from "src/app/services/producto.service";

@Component({
  selector: "app-mis-productos",
  templateUrl: "./mis-productos.component.html",
  styleUrls: ["./mis-productos.component.css"],
})
export class MisProductosComponent implements OnInit, OnDestroy {
  @ViewChild("closebutton", { static: true }) closebutton!: ElementRef;
  @ViewChild("inputFile", { static: true }) file!: ElementRef;

  private unsubscriber: Subject<void> = new Subject();
  private uploadedFiles: { [x: string]: File } = {};
  private selectedFile: string = "";
  private selectedProduct!: string;
  public userProfile$: Observable<User | null> =
    this.authService.authStateChanged();
  public productosUser: ProductoData[] = [];
  public itemsPerPage: number = 10;
  public currentPage: number = 1;
  public idProductoEliminar: string | undefined;
  public filePaths: { [x: string]: string | ArrayBuffer | null } = {};
  public datosInteres!: string;
  public noImage: string = "assets/imagenes/noImage.png";
  public disponibilidadVenta!: number;
  public precio!: number;
  public isCollapsed: boolean = false;

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private productservice: ProductoService
  ) {}

  ngOnInit(): void {
    this.userProfile$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: async (res: User | null) => {
            if (res) {
              this.productosUser = await this.getProducts(res.uid);
            }
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async getProducts(userId: string): Promise<ProductoData[]> {
    try {
      const productos: ProductoData[] =
        await this.productservice.getProductsByUserId(userId);
      return productos.filter((producto: ProductoData) => !producto.eliminado);
    } catch (err) {
      console.error(err);
      throw new Error("Error getting products");
    }
  }

  productoEliminar(producto?: string): void {
    this.idProductoEliminar = producto ? producto : undefined;
  }

  borrarProducto(): void {
    if (this.idProductoEliminar) {
      this.productservice
        .eliminarProducto(this.idProductoEliminar)
        .then(() => {
          this.cierraPopup();
        })
        .catch((error) => {
          console.error("error eliminación", error);
        });
    }
  }

  cierraPopup(): void {
    this.closebutton.nativeElement.click();
  }

  async getImages(product: ProductoData): Promise<void> {
    if (product.id) {
      this.selectedProduct = product.id;
      this.datosInteres = product.datosInteres;
      this.disponibilidadVenta = product.disponibilidadVenta;
      this.precio = product.precio;
      this.filePaths = await this.productservice.getAllImagesObject(product.id);
    }
  }

  selectPhoto(position: number): void {
    this.selectedFile = `file_${position}`;
    this.file.nativeElement.click();
  }

  onFileChanged2(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        this.filePaths[this.selectedFile] = reader.result;
        this.uploadedFiles[this.selectedFile] = file;
      };
      reader.readAsDataURL(file);
    }
  }

  guardar(): void {
    this.productservice
      .updateImages(this.selectedProduct, this.uploadedFiles)
      .then(() =>
        this.productservice.updateProduct(
          {
            datosInteres: this.datosInteres,
            disponibilidadVenta: this.disponibilidadVenta,
            precio: this.precio,
          },
          this.selectedProduct
        )
      )
      .then(() => window.alert("Producto actualizado"))
      .catch((err: Error) => console.error(err));
  }

  open(content: TemplateRef<any>): void {
    this.modalService
      .open(content, { ariaLabelledBy: "Editar producto", size: "xl" })
      .result.then(() => {});
  }

  deleteFile(pos: number): void {
    const file: string = `file_${pos}`;
    delete this.filePaths[file];
    delete this.uploadedFiles[file];
  }
}
