<h3 class="titulo-contenido-perfil">
  {{ "calificaciones.historial" | translate }}
</h3>
<p>
  {{ "calificaciones.aquiPodras" | translate }}
</p>
<div class="table-responsive-lg">
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">No.</th>
        <th scope="col">{{ "calificaciones.calificacion" | translate }}</th>
        <th scope="col">{{ "calificaciones.comentario" | translate }}</th>
        <th scope="col">{{ "calificaciones.comprador" | translate }}</th>
        <th scope="col">{{ "formularios.fecha" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr
        *ngFor="
          let calificacion of calificaciones
            | paginate
              : { itemsPerPage: itemsPerPage, currentPage: currentPage };
          let i = index
        "
      >
        <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
        <td>
          <img
            class="icon-svg"
            src="../../assets/imagenes/star-solid-gold.svg"
            alt="estrella"
            *ngFor="let start of calificacion.puntuacion"
          />
        </td>

        <td>
          {{ calificacion.comentarios }}
        </td>
        <td>{{ calificacion.vendedor | titlecase }}</td>
        <td>{{ calificacion.tiempoCreado | timestampToString :'es':"dd/MM/yyyy" }}</td>
      </tr>
    </tbody>
  </table>
</div>
<!--paginado para los resultados-->
<pagination-controls
  class="my-pagination"
  (pageChange)="currentPage = $event"
  previousLabel="{{ 'paginacion.anterior' | translate }}"
  nextLabel="{{ 'paginacion.siguiente' | translate }}"
>
</pagination-controls>
