<div class="container-fluid mt-2">
  <div class="row tools">
    <div class="col-12">
      <h5>Buscar especie</h5>
    </div>
    <div class="col-12">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Especie a buscar"
          #especieInput
          (keyup)="filtrar(especieInput)"
        />
      </div>
    </div>
    <div class="col-12">
      <h5 class="separador">Crear especie</h5>
    </div>
    <div class="col-12">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          id="inputCrear"
          placeholder="Nombre Especie"
          #especieCrear
        />
        <button
          class="btn btn-crear"
          type="button"
          (click)="crearEspecie(especieCrear)"
        >
          Crear especie
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <table class="table table-sm table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Especie</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let especie of filtered || listaEspecies">
            <th scope="row">
              <p class="m-0" *ngIf="!especie.editando">
                {{ especie.nombre }}
              </p>

              <input
                *ngIf="especie.editando"
                (input)="actualizarNuevoNombre($event, especie)"
                type="text"
                class="form-control w-100"
                placeholder="nombre de especie"
                #inputEspecie
              />
            </th>
            <td class="text-center">
              <button
                *ngIf="!especie.editando"
                class="btn btn-primary mx-2"
                (click)="editar(especie)"
              >
                Editar
              </button>

              <button
                *ngIf="especie.editando"
                class="btn btn-primary mx-2"
                (click)="cancelarEdicion(especie)"
              >
                Cancelar
              </button>

              <button
                *ngIf="especie.editando"
                [disabled]="
                  !especie.nuevoNombre || especie.nuevoNombre === especie.nombre
                "
                class="btn btn-success mx-2"
                (click)="actualizar(especie)"
              >
                Actualizar
              </button>

              <button
                class="btn btn-danger"
                *ngIf="!especie.editando"
                (click)="openBorrar(modalBorrar, especie)"
              >
                Borrar
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #modalBorrar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      ¿Está seguro de borrar la especie?
    </h4>
  </div>

  <div class="modal-body">
    <p>Si acepta borrarla se hará de forma permanente</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">
      Aceptar
    </button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">
      Cancelar
    </button>
  </div>
</ng-template>
