import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  tap,
} from "rxjs/operators";
import { isEmpty, isEqual } from "lodash";
import { FirestoreService } from "./firestore.service";
import { StoreService } from "./store.service";
import { DEFAULT_PAGINATION, Pagination } from "../model/pagination";
import {
  CollectionLoadedState,
  CrudState,
  CrudStateOrFailure,
  DocumentLoadedState,
  DocumentModifiedState,
  LoadingCollectionState,
  LoadingDocumentState,
} from "../interface/crud-state.interface";
import { CrudServiceInterface } from "../interface/crud-service.interface";
import { Stats } from "../model/stats";
import { Failure, NotFoundFailure } from "../failure/failure";
import {
  endAt,
  limit,
  orderBy,
  QueryConstraint,
  startAt,
  where,
  startAfter,
  endBefore,
  limitToLast,
} from "@angular/fire/firestore";

/**
 * Servicio Abstracto CRUD.
 * M stands for Model.
 * S stands for State.
 */
@Injectable()
export abstract class CrudService<
    M extends { id: string },
    S extends CrudState<M>,
  >
  implements CrudServiceInterface<M>, OnDestroy
{
  protected sbs: Subscription[] = [];

  constructor(
    protected firestore: FirestoreService<M>,
    protected store: StoreService<S>
  ) {
    this.init();
  }

  get collection$(): Observable<M[]> {
    return this.store.state$.pipe(map((state) => state.collection));
  }

  get stats$(): Observable<Stats> {
    return this.store.state$.pipe(
      map((state) => state.stats),
      skipWhile((stats) => !stats),
      distinctUntilChanged(isEqual)
    );
  }

  get currentId$(): Observable<string> {
    return this.store.state$.pipe(map((state) => state.currentId));
  }

  get document$(): Observable<M> {
    return this.store.state$.pipe(map((state) => state.document));
  }

  get pagination$(): Observable<Pagination> {
    return this.store.state$.pipe(map((state) => state.pagination));
  }

  get stateOrFailure$(): Observable<CrudStateOrFailure> {
    return this.store.state$.pipe(map((state) => state.stateOrFailure));
  }

  ngOnDestroy() {
    this.sbs.forEach((s) => s.unsubscribe());
  }

  private assingQuery(query: QueryConstraint[], pagination: any): void {
    const collection = this.store.state.collection;
    const [firstDocument, lastDocument] =
      collection && collection.length > 0
        ? [collection[0], collection[collection.length - 1]]
        : [null, null];

    query = this.pagePaginationQuery(
      query,
      pagination,
      firstDocument,
      lastDocument
    );
  }

  public paginate(partialPagination: Partial<Pagination>): void {
    this.store.patch(
      {
        stateOrFailure: new LoadingCollectionState(),
      } as Partial<S>,
      `CRUD collection paginate`
    );

    // Inicializa la paginación.
    if (!this.store.state.pagination) {
      const pagination: Pagination = Object.assign(
        {},
        DEFAULT_PAGINATION,
        partialPagination
      );
      this.store.patch({ pagination } as Partial<S>, "init pagination");

      // Actualiza la paginación, validando que sí haya cambios
    } else {
      const current = this.store.state.pagination;
      const next = partialPagination;
      const pagination: Partial<Pagination> = {};
      if (
        next.page !== null &&
        next.page !== undefined &&
        next.page !== current.page
      ) {
        pagination.previousPage = current.page;
        pagination.page = next.page;
      }
      if (
        next.size !== null &&
        next.size !== undefined &&
        next.size !== current.size
      ) {
        // Reiniciar la paginación para evitar problemas
        pagination.page = 0;
        pagination.size = next.size;
      }
      if (
        next.searchString !== null &&
        next.searchString !== undefined &&
        next.searchString !== current.searchString
      ) {
        pagination.searchString = next.searchString;
      }
      if (next.filters && !isEqual(next.filters, current.filters)) {
        pagination.filters = next.filters;
      }
      if (next.sorts && !isEqual(next.sorts, current.sorts)) {
        pagination.sorts = next.sorts;
      }
      if (next.searchModes && !isEqual(next.searchModes, current.searchModes)) {
        pagination.searchModes = next.searchModes;
      }

      if (!isEmpty(pagination)) {
        const nextPagination: Pagination = Object.assign(
          {},
          this.store.state.pagination,
          pagination
        );
        this.store.patch(
          { pagination: nextPagination } as Partial<S>,
          "set pagination"
        );
      } else {
        this.store.patch(
          {
            stateOrFailure: new CollectionLoadedState(),
          } as Partial<S>,
          `CRUD collection paginate unchanged`
        );
      }
    }
  }

  public initStats(): void {
    this.sbs.push(
      this.firestore
        .doc$("--stats--")
        .pipe(
          tap({
            next: (doc) => {
              this.store.patch(
                {
                  stats: doc as unknown as Stats,
                } as Partial<S>,
                `STATS subscription`
              );
            },
            error: (e: unknown) => {
              this.store.patch(
                {
                  stateOrFailure: Failure.fromError(e),
                } as unknown as Partial<S>,
                "STATS load ERROR"
              );
            },
          })
        )
        .subscribe()
    );
  }

  public initCollection(): void {
    this.sbs.push(
      this.pagination$
        .pipe(
          skipWhile((pagination) => !pagination),
          distinctUntilChanged(isEqual),
          switchMap((pagination) => {
            let query: QueryConstraint[] = [];

            switch (pagination.paginate) {
              // Sin paginación
              case "no":
                query = this.noPaginationQuery(query, pagination);
                break;

              // Paginación por página
              case "pages":
                break;
              case "infinite":
                this.assingQuery(query, pagination);
                break;

              default:
                /**
                 * @todo: Throw NoValidPaginationSetException
                 */
                break;
            }

            return this.firestore.collection$(query).pipe(
              tap({
                next: (collection) => {
                  this.store.patch(
                    {
                      stateOrFailure: new CollectionLoadedState(),
                      collection,
                    } as Partial<S>,
                    `CRUD collection subscription`
                  );
                },
                error: (e) => {
                  const f = Failure.fromError(e);
                  f.log(
                    `CrudService.initCollection ${
                      this.firestore.collection().path
                    }`,
                    { pagination: this.store.state.pagination }
                  );
                  this.store.patch(
                    {
                      stateOrFailure: f,
                    } as unknown as Partial<S>,
                    "initCollection ERROR"
                  );
                },
              })
            );
          })
        )
        .subscribe()
    );
  }

  public currentId(id?: string) {
    this.store.patch({ currentId: id } as Partial<S>, "set document ID");
  }

  public initDocument(): void {
    this.sbs.push(
      this.currentId$
        .pipe(
          distinctUntilChanged(isEqual),
          filter((id) => id !== undefined),
          tap({
            next: (id) => {
              // Mostrar el formulario en estado 'cargando'.
              if (id !== "0") {
                this.store.patch(
                  {
                    stateOrFailure: new LoadingDocumentState(),
                  } as Partial<S>,
                  `set loading-document`
                );

                // Pero si está creando un documento, poblar un documento vacío.
              } else {
                this.store.patch(
                  {
                    stateOrFailure: new DocumentLoadedState(),
                    document: this.emptyDocument(),
                  } as Partial<S>,
                  `CRUD document empty`
                );
              }
            },
          }),
          // Evitar hacer el query si el documento cargado es vacío.
          filter((id) => id !== "0"),
          switchMap((id) =>
            this.firestore.doc$(id).pipe(
              // Evitar que se emitan dos veces un documento idéntico, para que no se sobreescriban
              // los cambios locales durante la edición.
              distinctUntilChanged(isEqual),
              tap({
                next: (doc) => {
                  // Si se encontró el documento, poblarlo.
                  if (doc !== undefined) {
                    this.store.patch(
                      {
                        stateOrFailure: new DocumentLoadedState(),
                        document: { ...doc, id } as M,
                      } as Partial<S>,
                      `CRUD document subscription`
                    );
                    // Sino, generar un NotFoundFailure.
                  } else {
                    this.store.patch(
                      {
                        stateOrFailure: new NotFoundFailure(
                          `No se encontró el documento ${this.currentId}.`
                        ),
                        document: null,
                      } as unknown as Partial<S>,
                      `CRUD document doesnt exist`
                    );
                  }
                },
                error: (e) => {
                  const f = Failure.fromError(e);
                  f.log("CrudService.initDocument", {
                    documentId: this.currentId,
                  });

                  this.store.patch(
                    {
                      documentStatus: "load-error",
                    } as unknown as Partial<S>,
                    "load ERROR"
                  );
                },
              })
            )
          )
        )
        .subscribe()
    );
  }

  public patchDocument(doc: M): void {
    this.store.patch(
      {
        stateOrFailure: new DocumentModifiedState(),
        document: doc,
      } as Partial<S>,
      "document state patched"
    );
  }

  public async save(doc: M): Promise<void> {
    this.store.patch(
      {
        stateOrFailure: new LoadingDocumentState(),
        document: doc,
      } as Partial<S>,
      "document save"
    );
    return this.firestore
      .upsert(doc)
      .then(() => {
        this.store.patch(
          {
            stateOrFailure: new DocumentLoadedState(),
          } as Partial<S>,
          "save SUCCESS"
        );
      })
      .catch((e) => {
        const f = Failure.fromError(e);
        f.log("CrudService.save", { doc, id: this.currentId });
        this.store.patch(
          {
            stateOrFailure: f,
          } as Partial<S>,
          "save ERROR"
        );
      });
  }

  public async update(id: string, data: Partial<M>): Promise<void> {
    this.store.patch(
      {
        stateOrFailure: new LoadingDocumentState(),
      } as Partial<S>,
      "document update"
    );
    return this.firestore
      .update(id, data)
      .then(() => {
        this.store.patch(
          {
            stateOrFailure: new DocumentLoadedState(),
          } as unknown as Partial<S>,
          "update SUCCESS"
        );
      })
      .catch((e) => {
        const f = Failure.fromError(e);
        f.log("CrudService.update", { updateData: { ...data, id } });
        this.store.patch(
          {
            stateOrFailure: f,
          } as unknown as Partial<S>,
          "update ERROR"
        );
      });
  }

  public async delete(id: string): Promise<void> {
    this.store.patch(
      { stateOrFailure: new LoadingDocumentState() } as Partial<S>,
      "delete"
    );
    return this.firestore
      .delete(id)
      .then(() => {
        this.store.patch(
          {
            stateOrFailure: new DocumentLoadedState(),
          } as Partial<S>,
          "delete SUCCESS"
        );
      })
      .catch((e) => {
        const f = Failure.fromError(e);
        f.log("CrudService.delete", { documentId: id });
        this.store.patch(
          {
            stateOrFailure: f,
          } as Partial<S>,
          "delete ERROR"
        );
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public createId(doc?: M): string {
    return this.firestore.createId();
  }

  public async fetchDoc(id: string): Promise<M> {
    return this.firestore.fetchDoc(id);
  }

  public async fetchCollection(query: QueryConstraint[]): Promise<M[]> {
    return this.firestore.fetchCollection(query);
  }

  public search$(
    searchString: string,
    searchField: string,
    limitProp: number = 5
  ): Observable<M[]> {
    const query: QueryConstraint[] = [];
    // Ordenar los datos por el campo.
    // Es necesario para que la búsqueda funcione.
    query.push(orderBy(searchField, "asc"));

    // Setear la búsqueda por coincidencia exacta.
    const start: string = searchString;
    const end: string = start + "\uf8ff";
    query.push(startAt(start));
    query.push(endAt(end));

    // Limitar el número de resultados
    query.push(limit(limitProp));

    return this.firestore.collection$(query);
  }

  /**
   * Construye un query que pagina para pagina.
   *
   * @param query
   * @param pagination
   * @param first
   * @param last
   * @returns FirestoreCollectionReference | FirestoreQuery
   */
  protected pagePaginationQuery(
    query: QueryConstraint[],
    pagination: Pagination,
    first: any,
    last: any
  ): QueryConstraint[] {
    let overrideSorts: boolean = false;

    // Si hay una búsqueda es neceario hacer una ordenación
    // específica para la búsqueda, para que funcione
    if ("" !== pagination.searchString) {
      /**
       * @todo: Corregir bug cuando searchModes es vacío, para que la ordenación no falle.
       * @todo: Corregir bug cuando hay filtros >= o <= y se busca, falla porque la
       * ordenación del campo de búsqueda se prioriza.
       */

      // Extraer el string de búsqueda y el operador, si hay uno.
      const operator = pagination.searchString.substring(0, 1);
      let searchMode = pagination.searchModes.find(
        (l) => l.operator === operator
      );
      let str = pagination.searchString.substring(1);
      if (!searchMode) {
        searchMode = pagination.searchModes.find((l) => l.operator === "");
        str = pagination.searchString;
      }

      if (searchMode) {
        // Forzar la ordenación para que sea por el campo de búsqueda (sino no funciona la búsqueda).
        overrideSorts = true;
        query.push(orderBy(searchMode.field, "asc"));

        // Setear la búsqueda por coincidencia exacta.
        const start: string = str;
        const end: string = start + "\uf8ff";
        query.push(startAt(start));
        query.push(endAt(end));
      }
    }

    // Ordenar, según haya búsqueda o no.
    const sorts = !overrideSorts ? pagination.sorts : [];
    for (const sort of sorts) {
      query.push(orderBy(sort.field, sort.direction));
    }

    // Filtrar
    for (const filter of pagination.filters) {
      query.push(where(filter.field, filter.queryOperator, filter.value));
    }

    // Si incrementa página
    if (
      pagination.page === 0 ||
      pagination.page - pagination.previousPage >= 0
    ) {
      let startAfterProp = null;
      if (pagination.page > 0) {
        startAfterProp =
          sorts.length > 0
            ? last && last[sorts[0].field]
              ? last[sorts[0].field]
              : null
            : last.id;
      }

      startAfterProp
        ? query.push(startAfter(startAfterProp), limit(pagination.size))
        : query.push(limit(pagination.size));

      // Si disminuye página
    } else {
      const endBeforeProp =
        sorts.length > 0
          ? first && first[sorts[0].field]
            ? first[sorts[0].field]
            : null
          : first.id;

      endBeforeProp
        ? query.push(endBefore(endBeforeProp), limitToLast(pagination.size))
        : query.push(limitToLast(pagination.size));
    }

    return query;
  }

  /**
   * Construye un query sin paginación.
   *
   * @param query
   * @param pagination
   * @returns FirestoreCollectionReference | FirestoreQuery
   */
  protected noPaginationQuery(
    query: QueryConstraint[],
    pagination: Pagination
  ): QueryConstraint[] {
    const LIMIT: number = 250;

    // Si hay una búsqueda es neceario hacer una ordenación
    // específica para la búsqueda, para que funcione
    if ("" !== pagination.searchString) {
      this.searchQuery(query, pagination);
    } else {
      // Ordenar
      for (const sort of pagination.sorts) {
        query.push(orderBy(sort.field, sort.direction));
      }
    }

    // Filtrar
    for (const filter of pagination.filters) {
      query.push(where(filter.field, filter.queryOperator, filter.value));
    }

    // Limitar el numero de documentos (por seguridad).
    query.push(limit(LIMIT));

    return query;
  }

  /**
   * Construye un query para la búsqueda
   *
   * @param query
   * @param current
   * @returns FirestoreCollectionReference | FirestoreQuery
   */
  protected searchQuery(
    query: QueryConstraint[],
    current: Pagination
  ): QueryConstraint[] {
    // Extraer el string de búsqueda y el operador, si hay uno.
    const operator = current.searchString.substring(0, 1);
    let searchMode = current.searchModes.find((l) => l.operator === operator);
    let str = current.searchString.substring(1);
    if (!searchMode) {
      searchMode = current.searchModes.find((l) => l.operator === "");
      str = current.searchString;
    }

    if (searchMode) {
      // Ordenar los datos por el campo.
      // Es necesario para que la búsqueda funcione.
      query.push(orderBy(searchMode.field, "asc"));

      // Setear la búsqueda por coincidencia exacta.
      const start: string = str;
      const end: string = start + "\uf8ff";
      query.push(startAt(start));
      query.push(endAt(end));
    }

    return query;
  }

  public abstract emptyDocument(): M;

  public abstract init(): void;
}
