import { Component, OnDestroy } from "@angular/core";
import { User } from "@angular/fire/auth";
import { DocumentData } from "@angular/fire/firestore";
import { Observable, Subject } from "rxjs";
import { takeUntil, map, tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { ComprasService } from "src/app/services/compras.service";

@Component({
  selector: "app-contabilidad",
  templateUrl: "./contabilidad.component.html",
  styleUrls: ["./contabilidad.component.css"],
})
export class ContabilidadComponent implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  private user$: Observable<User | null> = this.authSvc
    .authStateChanged()
    .pipe(takeUntil(this.unsubscriber));
  public currentPage: number = 1;
  public itemsPerPage: number = 6;
  public pedidosVentas: any;
  public pedidosCompras: any;
  public keysPedidosVentas: string[] = [];
  public keysPedidosCompras: string[] = [];
  public totalCompras!: number;
  public totalVentas!: number;

  constructor(
    private authSvc: AuthService,
    private comprasService: ComprasService
  ) {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        map((value: User | null) => value?.uid),
        tap({
          next: (userId: string | undefined) => {
            if (userId) {
              this.comprasService
                .getPedidosVendedor(userId)
                .pipe(
                  takeUntil(this.unsubscriber),
                  tap({
                    next: (pedidosVentas: DocumentData[]) => {
                      const { pedidos, total } =
                        this.ordenarPedidos(pedidosVentas);
                      this.keysPedidosVentas = Object.keys(pedidos);
                      this.pedidosVentas = pedidos;
                      this.totalVentas = total;
                    },
                    error: (error) => {
                      console.log(error);
                    },
                  })
                )
                .subscribe();

              this.comprasService
                .getPedidos(userId)
                .pipe(
                  takeUntil(this.unsubscriber),
                  tap({
                    next: (pedidosVentas: DocumentData[]) => {
                      const { pedidos, total } =
                        this.ordenarPedidos(pedidosVentas);
                      this.keysPedidosCompras = Object.keys(pedidos);
                      this.pedidosCompras = pedidos;
                      this.totalCompras = total;
                    },
                    error: (error) => {
                      console.log(error);
                    },
                  })
                )
                .subscribe();
            }
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  ordenarPedidos(pedidosVentas: DocumentData[]): any {
    const orderedPedidos: any = {};
    let total: number = 0;
    pedidosVentas.forEach((pedidosVentas: DocumentData) => {
      pedidosVentas["productos"].forEach((item: any) => {
        const { categoria } = item;
        orderedPedidos[categoria] ??= { cantidad: 0, egresos: 0 };
        orderedPedidos[categoria].cantidad += 1;
        const estado: string = item.estado?.toLowerCase() || "";
        orderedPedidos[categoria][estado] ||= 0;

        orderedPedidos[categoria][estado] += 1;

        orderedPedidos[categoria].egresos +=
          estado !== "finalizado" ? 0 : item.valorTotal;
        total += estado !== "finalizado" ? 0 : item.valorTotal;
      });
    });
    return { pedidos: orderedPedidos, total };
  }
}
