import { Injectable } from "@angular/core";
import { FirebaseFunctions } from "../functions/firebase.functions";
import { HttpsCallableResult } from "firebase/functions";

@Injectable({
  providedIn: "root",
})
export class EstadisticasService {
  constructor(private firebaseFunctions: FirebaseFunctions) {}

  getEstadisticas(anio: string): Promise<HttpsCallableResult> {
    return this.firebaseFunctions.getEstadisticas(anio);
  }
}
