import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ProductoData } from "src/app/models/ProductoData";
import { deepCopy } from "src/app/utils/deepCopy";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "src/app/state/authentication-state";
import { Select } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ModalCrearComponent } from "../modal-crear/modal-crear.component";
import { User } from "@angular/fire/auth";
import { AuthService } from "src/app/services/auth.service";
import { UserData, UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";
import { ProductoService } from "src/app/services/producto.service";
import { Timestamp } from "@angular/fire/firestore";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.css"],
})
export class FormsComponent implements OnInit, OnDestroy {
  @ViewChildren("fileInputRef")
  public fileInputReferences!: QueryList<ElementRef>;

  @Select(AuthenticationState.user)
  public user$!: Observable<AuthenticationStateModel["user"]>;
  private user: User | null = null;
  private unsubscriber: Subject<void> = new Subject();
  public currentForm!: FormGroup;
  public defaultUrl: string = "assets/imagenes/image_producto_predet.webp";
  public loading: boolean = false;
  public files: {
    [fileName: string]: {
      file: File;
      url: string | ArrayBuffer | null;
      pos: number;
    };
  } = {};
  public categoryUser: string[] = [];
  public especiesHabilitadas: string[] = [];
  public categoria!: string;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private baseFormService: BaseFormService,
    private productservice: ProductoService,
    private modalService: NgbModal
  ) {
    this.traerEspeciesHabilitadas();
    this.baseFormService.currentForm
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (form: FormGroup) => {
            this.currentForm = form;
            this.currentForm.get("categoria")?.setValue(this.categoria);
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: User | null) => {
            this.user = user;
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get hasImg():
    | { file: File; url: string | ArrayBuffer | null; pos: number }
    | undefined {
    const values = Object.values(this.files);
    const element = values.find((elem) => elem.pos === 1);
    return element;
  }

  async traerEspeciesHabilitadas(): Promise<void> {
    const currentUser: User | null = this.authService.getCurrentUser();
    if (currentUser) {
      const currentUserData: UserData = await this.userService.getUserData(
        currentUser.uid
      );
      this.especiesHabilitadas = currentUserData.especiesHabilitadas;
      this.categoryUser = currentUserData.categoryProductUser;
    }
  }

  removeFile(inputRef: HTMLInputElement): void {
    if (inputRef.files && inputRef.files[0]) {
      const fileName: string = inputRef.files[0].name;
      delete this.files[fileName];
      inputRef.value = "";
    }
  }

  onFileChanged(event: any, inputRef: HTMLInputElement, pos: number): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      const reader: FileReader = new FileReader();
      if (this.files[file.name]) {
        alert(
          "Un archivo con este nombre ya existe. Por favor cambiar el nombre o subir otro archivo."
        );
        inputRef.value = "";
      } else {
        reader.onload = () => {
          this.files[file.name] = {
            file,
            url: reader.result,
            pos,
          };
        };
        reader.readAsDataURL(file);
      }
    }
  }

  getFileUrlForPreview(
    inputRef: HTMLInputElement
  ): string | ArrayBuffer | null {
    if (inputRef.files && inputRef.files[0]) {
      const file: File | null = inputRef.files.item(0);
      if (file && this.files[file.name]?.url) {
        return this.files[file.name].url;
      }

      return this.defaultUrl;
    }

    return this.defaultUrl;
  }

  async onCreate(): Promise<void> {
    if (this.currentForm.invalid) {
      this.currentForm.markAllAsTouched();
      return;
    }

    if (!this.user?.uid) return;

    const modal: NgbModalRef = this.showLoadingModal();

    const producto: ProductoData = {
      ...this.currentForm.value,
      tiempoCreado: Timestamp.now(),
      sello: false,
      idVendedor: this.user.uid,
      nombreVendedor: "",
      hasSubasta: false,
      eliminado: false,
    };

    const files: File[] = Object.values(this.files)
      .sort((a, b) => a.pos - b.pos)
      .map((fileInfo) => fileInfo.file);

    try {
      this.loading = true;
      const productId: string = await this.productservice.addProduct(
        producto,
        files
      );
      const currentUserData: UserData = await this.userService.getUserData(
        this.user.uid
      );

      const newUserData: UserData = deepCopy(currentUserData);
      newUserData.productos = newUserData.productos || [];
      newUserData.productos.push(productId);
      this.userService.addProduct(this.user.uid, productId);

      this.resetFormAndFiles();

      setTimeout(() => {
        modal.componentInstance.isLoading = false;
      }, 5000);
      this.loading = false;
    } catch (error) {
      console.error(error);
      modal.componentInstance.isLoading = false;
    }
  }

  resetFormAndFiles(): void {
    this.currentForm.reset();
    for (const control in this.currentForm.controls) {
      if (Object.hasOwn(this.currentForm.controls, control)) {
        this.currentForm.controls[control].setErrors(null);
      }
    }

    this.files = {};
    this.fileInputReferences.forEach(
      (fileInputRef) => (fileInputRef.nativeElement.value = "")
    );
    this.categoria = "";
  }

  showLoadingModal(): NgbModalRef {
    const modal: NgbModalRef = this.modalService.open(ModalCrearComponent, {
      scrollable: false,
      size: "md",
      backdrop: "static",
    });

    modal.componentInstance.isLoading = true;

    modal.componentInstance.closeModalEvent
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (res: boolean) => {
            if (res) modal.dismiss();
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();

    return modal;
  }
}
