import { UserData } from "../services/user.service";

export class UserDataHelpers {
  /**
   * Returns the apellido(s) of the user, if they have one.
   * @param user The user data object.
   * @returns The user's apellido(s), or undefined if they do not have one.
   */
  static getApellidos(user: UserData): string {
    if ("apellidos" in user) {
      return user.apellidos;
    }

    return "";
  }
}
