import { Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Especie } from "src/app/models/especie.interface";
import { EspeciesService } from "src/app/services/especies.service";
@Component({
  selector: "app-especies",
  templateUrl: "./especies.component.html",
  styleUrls: ["./especies.component.css"],
})
export class EspeciesComponent {
  @ViewChild("especieInput") especieInput!: ElementRef;
  listaEspecies: Especie[] = [];
  filtered!: Especie[] | null; // variable para guardar temporalmente especies filtradas
  constructor(
    private especiesService: EspeciesService,
    private modalService: NgbModal
  ) {
    this.getEspecies();
  }

  async getEspecies(): Promise<void> {
    try {
      this.listaEspecies = (await this.especiesService.getAll()) as {
        id: string;
        nombre: string;
      }[];
    } catch (e) {
      console.error(e);
    }
  }

  filtrar(input?: HTMLInputElement): void {
    if (input) {
      const { value } = input;
      this.filtered = this.listaEspecies.filter((especie: Especie) =>
        especie.nombre.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      this.filtered = null;
    }
  }

  cancelarEdicion(especie: Especie): void {
    delete especie.editando;
    delete especie.nuevoNombre;
  }

  async actualizar(especie: Especie): Promise<void> {
    if (!especie.nuevoNombre) return;

    try {
      await this.especiesService.update(especie.id, especie.nuevoNombre);
      this.cancelarEdicion(especie);
      this.especieInput.nativeElement.value = "";
      this.filtrar();
      this.getEspecies();
    } catch (e) {
      console.error(e);
    }
  }

  editar(especie: Especie): void {
    especie.editando = true;
    especie.nuevoNombre = especie.nombre;
  }

  openBorrar(content: TemplateRef<any>, especie: Especie) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          if (result) {
            this.especiesService.delete(especie.id);
            this.especieInput.nativeElement.value = "";
            this.filtrar();
            this.getEspecies();
          }
        },
        () => {}
      )
      .catch((err) => {
        console.error(err);
      });
  }

  async crearEspecie(nombre: HTMLInputElement): Promise<void> {
    try {
      await this.especiesService.crear(nombre.value);
      nombre.value = "";
      this.getEspecies();
    } catch (error) {
      console.error(error);
    }
  }

  actualizarNuevoNombre(nuevoValor: any, especie: Especie): void {
    especie.nuevoNombre = nuevoValor.target.value;
  }
}
