export const subproductoNoMaderable: string[] = [
  "aceitesEsenciales",
  "aserrin",
  "carbonVegetal",
  "caucho",
  "cereal",
  "colorantes",
  "corteza",
  "fibras",
  "flores",
  "frutos",
  "gomas",
  "hojas",
  "hortaliza",
  "hongos",
  "legumbre",
  "miel",
  "raiz",
  "semillas",
  "viruta",
];
