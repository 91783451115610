<ng-template let-modal #content>
  <div class="modal-header">
    <div class="text-center">
      <h5 class="modal-title" id="exampleModalLabel">
        {{ "cotizacion.cotizacion" | translate }}
        {{ dataCotizacion.consecutivo }}
      </h5>
      <small class="id-cotizacion">
        {{ "cotizacion.identificador" | translate }}
        {{ this.cotizacionId }}</small
      >
    </div>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <img
      class="img-cotizacion"
      src="../../assets/imagenes/logo_red_forestal_png.png"
    />
    <div class="company-data">
      <h3 class="modal-title">{{ "cotizacion.datosEmisor" | translate }}</h3>
      <div class="row">
        <div class="col-3">
          <ul>
            <li>
              <b> {{ "formularios.nombre" | translate }}</b>
              {{ datosEmpresa.nombre }}
            </li>
            <li>
              <b> {{ "cotizacion.nit" | translate }}</b> {{ datosEmpresa.nit }}
            </li>
            <li>
              <b> {{ "cotizacion.email" | translate }}</b>
              {{ datosEmpresa.email }}
            </li>
          </ul>
        </div>
        <div class="col-4">
          <ul>
            <li>
              <b> {{ "cotizacion.ciudad" | translate }}</b>
              {{ datosEmpresa.ciudad }}
            </li>
            <li>
              <b> {{ "cotizacion.direccion" | translate }}</b>
              {{ datosEmpresa.direccion }}
            </li>
          </ul>
        </div>
        <div class="col-5">
          <ul>
            <li>
              <b> {{ "formularios.telefono" | translate }}</b>
              {{ datosEmpresa.telefono }}
            </li>
            <li>
              <b> {{ "formularios.fecha" | translate }}</b>
              {{ dataCotizacion.fecha | timestampToString }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="cutomer-data">
      <h3 class="modal-title">{{ "cotizacion.datosCliente" | translate }}</h3>
      <div class="row">
        <div class="col-3">
          <ul>
            <li>
              <b> {{ "formularios.nombre" | translate }}</b>
              {{ dataCotizacion.datosUsuario.nombre | titlecase }}
            </li>
            <li>
              <b>{{ dataCotizacion.datosUsuario.tdocumento }}: </b>
              {{ dataCotizacion.datosUsuario.numerodocumento }}:
            </li>
          </ul>
        </div>
        <div class="col-4">
          <ul>
            <li>
              <b>
                {{ "cotizacion.ciudad" | translate }}
                {{ dataCotizacion.datosUsuario.ciudad | titlecase }}
              </b>
            </li>
            <li>
              <b> {{ "cotizacion.direccion" | translate }}</b>
            </li>
          </ul>
        </div>
        <div class="col-5">
          <ul>
            <li>
              <b> {{ "formularios.telefono" | translate }} </b>
              {{ dataCotizacion.datosUsuario.telefono }}
            </li>
            <li>
              <b> {{ "cotizacion.email" | translate }}</b
              >{{ dataCotizacion.datosUsuario.email || "No disponible" }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <h3 class="modal-title">
        {{ "cotizacion.informacionProductos" | translate }}
      </h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ "formularios.categoria" | translate }}</th>
            <th scope="col">{{ "formularios.especie" | translate }}</th>
            <th scope="col">{{ "cotizacion.costoUnitario" | translate }}</th>
            <th scope="col">{{ "cotizacion.cantidad" | translate }}</th>
            <th scope="col">{{ "cotizacion.costoTotal" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let producto of dataCotizacion?.productos?.listaProductos || [];
              let pos = index
            "
          >
            <th scope="row">{{ pos + 1 }}</th>
            <td>{{ producto.categoria }}</td>
            <td>{{ producto.especie }}</td>
            <td>{{ producto.precio | currency: "" : "symbol" : "3.0" }}</td>
            <td>{{ producto.cantidad }}</td>
            <td>{{ producto.valorTotal | currency: "" : "symbol" : "3.0" }}</td>
          </tr>
          <tr class="table-active">
            <td colspan="5">
              <b> {{ "cotizacion.total" | translate }}</b>
            </td>
            <td>
              {{
                dataCotizacion.productos.total || 0
                  | currency: "COP " : "symbol" : "3.0"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        {{ "cotizacion.deseasVerificar" | translate }}
        <a href="https://redforestal.com/validador"
          >https://redforestal.com/validador</a
        >
        {{ "cotizacion.ingresarIdentificador" | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      (click)="close()"
      type="button"
      class="btn btn-danger"
      data-bs-dismiss="modal"
    >
      {{ "formularios.cerrar" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-warning"
      data-bs-dismiss="modal"
      (click)="generarPdf('print')"
    >
      {{ "cotizacion.imprimir" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success"
      data-bs-dismiss="modal"
      (click)="generarPdf('download')"
    >
      {{ "cotizacion.descargar" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #loading>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "cotizacion.generandoCotizacion" | translate }}
    </h4>
  </div>
  <div class="modal-body text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</ng-template>

<ng-template #modalCerrar let-c="close">
  <div class="modal-header justify-content-center">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "cotizacion.cerrarCotizacion" | translate }}
    </h4>
  </div>
  <div
    class="modal-body text-center"
    innerHTML="{{ 'cotizacion.seguroCerrarCotizacion' | translate }}"
  ></div>
  <div class="modal-footer">
    <button (click)="close(true)" type="button" class="btn btn-danger">
      {{ "formularios.si" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-warning"
      data-bs-dismiss="modal"
      (click)="c()"
    >
      {{ "formularios.no" | translate }}
    </button>
  </div>
</ng-template>
