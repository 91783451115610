import { Pipe, PipeTransform } from "@angular/core";
import { Timestamp } from "@angular/fire/firestore";
import { FieldValue } from "@angular/fire/firestore";

@Pipe({ name: "timestamp" })
export class TimestampPipe implements PipeTransform {
  transform(value: Timestamp | FieldValue | null): number | null {
    if (!(value instanceof Timestamp) || !value || !value.seconds) {
      return null;
    }

    return value.seconds * 1000;
  }
}
