import {
  AuthenticationState,
  AuthenticationStateModel,
} from "./authentication-state";

export interface RootStateModel {
  authentication: AuthenticationStateModel;
}

const rootState = [AuthenticationState];

export { rootState };
