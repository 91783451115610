<div class="container-fluid" oncopy="return false">
  <div class="row">
    <div class="col-4">
      <nav id="navbarEnlacesInteres" class="navbar navbar-light bg-light">
        <h2 class="navbar-brand">
          {{ "enlaces-de-interes.informacionInteres" | translate }}
        </h2>
        <nav class="nav nav-pills flex-column">
          <a
            *ngFor="let seccion of secciones"
            class="nav-link"
            [routerLink]="[]"
            [fragment]="seccion"
          >
            {{ "enlaces-de-interes." + seccion | translate }}
          </a>
        </nav>
      </nav>
    </div>
    <div class="col-8">
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbarEnlacesInteres"
        data-bs-offset="20"
        class="scrollspy-example-2 pr-3 pl-3"
      >
        <h3 [id]="secciones[0]">
          {{ "enlaces-de-interes." + secciones[0] | translate }}
        </h3>
        <div class="mb-3 text-center">
          <button
            type="button"
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#modalManualUsuario"
          >
            {{ "enlaces-de-interes.descarguelo" | translate }}
          </button>
        </div>
        <h3 [id]="secciones[1]">
          {{ "enlaces-de-interes." + secciones[1] | translate }}
        </h3>
        <p>
          {{ "enlaces-de-interes.secciones1" | translate }}
        </p>

        <h4 [id]="secciones[2]">
          {{ "enlaces-de-interes." + secciones[2] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones2" | translate }}
        </p>

        <h4 [id]="secciones[3]">
          {{ "enlaces-de-interes." + secciones[3] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones3" | translate }}
        </p>

        <h4 [id]="secciones[4]">
          {{ "enlaces-de-interes." + secciones[4] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones4" | translate }}
        </p>

        <h4 [id]="secciones[5]">
          {{ "enlaces-de-interes." + secciones[5] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones5" | translate }}
        </p>

        <h4 [id]="secciones[6]">
          {{ "enlaces-de-interes." + secciones[6] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones6" | translate }}
        </p>

        <h4 [id]="secciones[7]">
          {{ "enlaces-de-interes." + secciones[7] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones7" | translate }}
        </p>

        <h4 [id]="secciones[8]">
          {{ "enlaces-de-interes." + secciones[8] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones8" | translate }}
        </p>

        <h4 [id]="secciones[9]">
          {{ "enlaces-de-interes." + secciones[9] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones9" | translate }}
        </p>

        <p>
          {{ "enlaces-de-interes.secciones9-1" | translate }}
        </p>

        <h4 [id]="secciones[10]">
          {{ "enlaces-de-interes." + secciones[10] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones10" | translate }}
        </p>

        <h4 [id]="secciones[11]">
          {{ "enlaces-de-interes." + secciones[11] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones11" | translate }}
        </p>

        <h4 [id]="secciones[12]">
          {{ "enlaces-de-interes." + secciones[12] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones12" | translate }}
        </p>

        <h4 [id]="secciones[13]">
          {{ "enlaces-de-interes." + secciones[13] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones13" | translate }}
        </p>

        <h4 [id]="secciones[14]">
          {{ "enlaces-de-interes." + secciones[14] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones14" | translate }}
        </p>

        <h4 [id]="secciones[15]">
          {{ "enlaces-de-interes." + secciones[15] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones15" | translate }}
        </p>

        <h4 [id]="secciones[16]">
          {{ "enlaces-de-interes." + secciones[16] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones16" | translate }}
        </p>

        <h4 [id]="secciones[17]">
          {{ "enlaces-de-interes." + secciones[17] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones17" | translate }}
        </p>

        <h4 [id]="secciones[49]">
          {{ "enlaces-de-interes." + secciones[49] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones49" | translate }}
        </p>

        <h4 [id]="secciones[18]">
          {{ "enlaces-de-interes." + secciones[18] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones18" | translate }}
        </p>

        <h4 [id]="secciones[19]">
          {{ "enlaces-de-interes." + secciones[19] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones19" | translate }}
        </p>

        <h4 [id]="secciones[20]">
          {{ "enlaces-de-interes." + secciones[20] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones20" | translate }}
        </p>
        <h3 [id]="secciones[21]">
          {{ "enlaces-de-interes." + secciones[21] | translate }}
        </h3>
        <p>
          {{ "enlaces-de-interes.secciones21" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones21-1" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones21-2" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones21-3" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones21-4" | translate }}
        </p>
        <h4 [id]="secciones[23]">
          {{ "enlaces-de-interes." + secciones[23] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones23" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones23-1" | translate }}
        </p>
        <h4 [id]="secciones[24]">
          {{ "enlaces-de-interes." + secciones[24] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones24" | translate }}
        </p>
        <h4 [id]="secciones[25]">
          {{ "enlaces-de-interes." + secciones[25] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones25" | translate }}
        </p>
        <h4 [id]="secciones[26]">
          {{ "enlaces-de-interes." + secciones[26] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones26" | translate }}
        </p>

        <h4 [id]="secciones[27]">
          {{ "enlaces-de-interes." + secciones[27] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones27" | translate }}
        </p>
        <h4 [id]="secciones[28]">
          {{ "enlaces-de-interes." + secciones[28] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones28" | translate }}
        </p>
        <h4 [id]="secciones[29]">
          {{ "enlaces-de-interes." + secciones[29] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones29" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones29-1" | translate }}
        </p>
        <h4 [id]="secciones[30]">
          {{ "enlaces-de-interes." + secciones[30] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones30" | translate }}
        </p>

        <h4 [id]="secciones[31]">
          {{ "enlaces-de-interes." + secciones[31] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones31" | translate }}
        </p>

        <h4 [id]="secciones[32]">
          {{ "enlaces-de-interes." + secciones[32] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones32" | translate }}
        </p>

        <h4 [id]="secciones[33]">
          {{ "enlaces-de-interes." + secciones[33] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones33" | translate }}
        </p>

        <h4 [id]="secciones[34]">
          {{ "enlaces-de-interes." + secciones[34] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones34" | translate }}
        </p>

        <h4 [id]="secciones[35]">
          {{ "enlaces-de-interes." + secciones[35] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones35" | translate }}
        </p>

        <h4 [id]="secciones[36]">
          {{ "enlaces-de-interes." + secciones[36] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones36" | translate }}
        </p>

        <h4 [id]="secciones[37]">
          {{ "enlaces-de-interes." + secciones[37] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones37" | translate }}
        </p>

        <h4 [id]="secciones[50]">
          {{ "enlaces-de-interes." + secciones[50] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones50" | translate }}
        </p>

        <h4 [id]="secciones[38]">
          {{ "enlaces-de-interes." + secciones[38] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones38" | translate }}
        </p>

        <h3 [id]="secciones[39]">
          {{ "enlaces-de-interes." + secciones[39] | translate }}
        </h3>
        <h4 [id]="secciones[40]">
          {{ "enlaces-de-interes." + secciones[40] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones40" | translate }}
        </p>

        <h4 [id]="secciones[41]">
          {{ "enlaces-de-interes." + secciones[41] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones41" | translate }}
        </p>

        <h4 [id]="secciones[42]">
          {{ "enlaces-de-interes." + secciones[42] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones42" | translate }}
        </p>

        <h4 [id]="secciones[43]">
          {{ "enlaces-de-interes." + secciones[43] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones43" | translate }}
        </p>

        <h4 [id]="secciones[44]">
          {{ "enlaces-de-interes." + secciones[44] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones44" | translate }}
        </p>

        <h4 [id]="secciones[45]">
          {{ "enlaces-de-interes." + secciones[45] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones45" | translate }}
        </p>

        <h4 [id]="secciones[46]">
          {{ "enlaces-de-interes." + secciones[46] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones46" | translate }}
        </p>
        <p>
          {{ "enlaces-de-interes.secciones46-1" | translate }}
        </p>

        <h4 [id]="secciones[47]">
          {{ "enlaces-de-interes." + secciones[47] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones47" | translate }}
        </p>
        <h4 [id]="secciones[48]">
          {{ "enlaces-de-interes." + secciones[48] | translate }}
        </h4>
        <p>
          {{ "enlaces-de-interes.secciones48" | translate }}
        </p>
      </div>
    </div>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
<div
  class="modal fade"
  id="modalManualUsuario"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "enlaces-de-interes.manualUsuario" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <iframe
          class="file-modal"
          height="90%"
          width="90%"
          src="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2FManual%20de%20Usuario.pdf?alt=media&token=fae0035b-49fb-41c8-b7ed-d9eb6b34aded"
        >
        </iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
