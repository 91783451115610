import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { User } from "@angular/fire/auth";
import { Timestamp } from "@angular/fire/firestore";
import { Observable, of, Subject, zip } from "rxjs";
import { catchError, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { EspeciesService } from "src/app/services/especies.service";
import { PreferidoCompradorService } from "src/app/services/preferido-comprador.service";
import { UserData, UserService } from "src/app/services/user.service";
import { categoriasLista } from "src/assets/data/categorias";
import { paises } from "src/assets/data/paises";
import { Especie } from "src/app/models/especie.interface";
import { ProductoPreferidoComprador } from "src/app/models/ProductoPreferidoComprador";

@Component({
  selector: "app-favoritos",
  templateUrl: "./favoritos.component.html",
  styleUrls: ["./favoritos.component.css"],
})
export class FavoritosComponent {
  @ViewChild("closebutton", { static: true }) closebutton!: ElementRef;
  private unsubscriber: Subject<boolean> = new Subject();
  private user$: Observable<User | null> = this.authService.authStateChanged();
  private idEliminar!: string;
  private idUsuario!: string;
  public especieOpciones: Especie[] = [];
  public mostrarSelect2!: string;
  public preferencias!: [];
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  public paises: any = paises;
  public listaPaises: string[] = Object.keys(this.paises);
  public listaDepartamentos: string[] = Object.keys(this.paises.Colombia);
  public listaMunicipios: string[] = [];
  public categorias: string[] = categoriasLista;
  preferForm: FormGroup = new FormGroup({
    categoria: new FormControl("", Validators.required),
    pais: new FormControl("", Validators.required),
    departamento: new FormControl(""),
    municipio: new FormControl(""),
    especie: new FormControl(""),
    sistema: new FormControl(""),
  });
  preferidos: Array<any> = [];

  constructor(
    public preferidoService: PreferidoCompradorService,
    public authService: AuthService,
    private userService: UserService,
    public activeRouter: ActivatedRoute,
    private especiesService: EspeciesService
  ) {
    this.getPreferencias();
    this.getEspecies();
  }

  async getEspecies(): Promise<void> {
    this.especieOpciones = await this.especiesService.getAll();
  }

  async onCreate(): Promise<void> {
    if (this.preferForm.invalid) {
      this.preferForm.markAllAsTouched();
      return;
    }

    const preferForm: ProductoPreferidoComprador = {
      categoria: this.preferForm.value.categoria,
      pais: this.preferForm.value.pais,
      departamento: this.preferForm.value.departamento,
      municipio: this.preferForm.value.municipio,
      especie: this.preferForm.value.especie,
      sistema: this.preferForm.value.sistema,
      tiempoCreado: Timestamp.now(),
    };

    try {
      const preferId: string =
        await this.preferidoService.addPrefer(preferForm);
      const currentUser: User | null = this.authService.getCurrentUser();
      if (currentUser) {
        this.preferidoService.addPreferInUser(currentUser.uid, preferId);
        this.resetFormAndFiles();
      }
    } catch (error) {
      console.error(error);
    }
  }

  selectDepartamento(selectDep: string): void {
    const paisesTmp: any = { ...this.paises };
    const pais: string = this.preferForm.get("pais")?.value;
    this.listaMunicipios = paisesTmp[pais] ? paisesTmp[pais][selectDep] : [];
  }

  isValidField(field: string): boolean {
    return (
      (this.preferForm.get(field)?.dirty &&
        this.preferForm.get(field)?.valid) ||
      false
    );
  }

  isInvalidField(field: string): boolean {
    return (
      ((this.preferForm.get(field)?.touched ||
        this.preferForm.get(field)?.dirty) &&
        !this.preferForm.get(field)?.valid) ||
      false
    );
  }

  getPreferencias(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        map((value: User | null) => value?.uid || null),
        switchMap((uid: string | null) => {
          if (uid) {
            this.idUsuario = uid;

            return this.userService.getUserById(uid).pipe(
              map((user: UserData) => {
                return user?.preferidosComprador || [];
              }),
              catchError(() => of([]))
            );
          }

          return of([]);
        }),
        switchMap((data: string[]) => {
          if (data.length > 0) {
            const peticiones: Observable<ProductoPreferidoComprador>[] = [];
            this.preferidos = [];
            data.map((id: string) => {
              peticiones.push(this.preferidoService.getPreferById(id));
            });

            return zip(...peticiones);
          }

          return of([]);
        }),
        tap({
          next: (data: ProductoPreferidoComprador[]) => {
            this.preferidos = data;
          },
          error: (error) => console.error(error),
        })
      )
      .subscribe();
  }

  prefToDelete(id: string): void {
    this.idEliminar = id;
  }

  async borrarPref(): Promise<void> {
    try {
      await this.preferidoService.deletePrefer(this.idEliminar);
      await this.preferidoService.deletePreferInUser(
        this.idUsuario,
        this.idEliminar
      );

      this.cerrarPopUp();
    } catch (e) {
      console.warn(e);
    }
  }

  cerrarPopUp(): void {
    this.closebutton.nativeElement.click();
  }

  resetFormAndFiles(): void {
    this.preferForm.reset();
  }

  onSelectPais(pais: string): void {
    this.preferForm.get("municipio")?.setValue("");
    this.preferForm.get("departamento")?.setValue("");
    if (this.paises[pais]) {
      this.listaDepartamentos = Object.keys(this.paises[pais]);
    }
  }
}
