import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { forkJoin, from, Observable, Subject } from "rxjs";
import { CarroCompras } from "../models/CarroCompras";
import { mergeAll, take, takeUntil, tap } from "rxjs/operators";
import { Path } from "../enums/Path";
import { ProductoData } from "../models/ProductoData";
import { UserData, UserService } from "../services/user.service";
import { AuthService } from "../services/auth.service";
import { CarroComprasService } from "../services/carro-compras.service";
import { ComprasService } from "../services/compras.service";
import { ProductoService } from "../services/producto.service";
import { SubscriptionService } from "../services/subscriptions.service";
import { User } from "@angular/fire/auth";
import { Timestamp } from "@angular/fire/firestore";
import { Compra } from "../models/compra.interface";
import { SuscripcionActiva } from "../models/subscripcion.interface";

interface Cantidad {
  [x: string]: {
    cantidadActual: number;
    cantidadSolicitada: number;
  };
}

@Component({
  selector: "app-carro-compras",
  templateUrl: "./carro-compras.component.html",
  styleUrls: ["./carro-compras.component.css"],
})
export class CarroComprasComponent implements OnDestroy {
  @ViewChild("closeButton") closeButton!: ElementRef;
  private unsubscriber: Subject<void> = new Subject();
  private user$: Observable<User | null> = this.authService.authStateChanged();
  private cantidades!: Cantidad;
  path: typeof Path = Path;
  productosItem: CarroCompras[] = [];
  currenUserData!: Partial<UserData>;
  idsList!: string[];
  currentUserId!: string;
  valorTotal!: number;
  permitirCompras!: boolean;
  arrayCantidades: ProductoData[] = [];
  suscrito!: boolean;
  permitirComprar!: boolean;
  generarCotizacion!: (args: any) => void;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private carroService: CarroComprasService,
    private comprasService: ComprasService,
    private productService: ProductoService,
    private suscripcionSvc: SubscriptionService
  ) {
    this.getUserData();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async getUserData() {
    this.user$.subscribe((result) => {
      if (result) {
        const currentUser = result;
        this.currentUserId = currentUser.uid;
        this.userService
          .getUserById(this.currentUserId)
          .pipe(takeUntil(this.unsubscriber))
          .subscribe((user: UserData) => {
            this.currenUserData = { ...user, uid: currentUser.uid };
            if (!this.idsList) {
              this.idsList = user.carroCompras ? [...user.carroCompras] : [];
            }
            this.getCarro(user.carroCompras || []);
            this.getSubscripcion(this.currentUserId);
          });
      }
    });
  }

  getCarro(idsCarro: string[]): void {
    if (!idsCarro.length) {
      this.productosItem = [];
      return;
    }
    const ids: Observable<CarroCompras>[] = [];

    idsCarro.map((id: string) => {
      const observable = this.carroService
        .getCarroComprasById(id)
        .pipe(take(1));
      ids.push(observable);
    });

    forkJoin([...ids])
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (res: CarroCompras[]) => {
            this.productosItem = res;
            this.calcularTotal();
            this.verificarCantidades();
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  deleteItemCarro(idBorrar: string): void {
    this.carroService
      .updateCarroInUser(this.currentUserId, idBorrar)
      .then(() => this.carroService.deleteCarro(idBorrar))
      .then(() => {
        this.getUserData();
        this.arrayCantidades = [];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async finalizarCompra(): Promise<void> {
    if (this.permitirCompras) {
      try {
        const productosItem: CarroCompras[] = [...this.productosItem];

        for (const producto of productosItem) {
          await this.carroService.updateCarroInUser(
            this.currentUserId,
            producto.id
          );
          producto.estado = "pendiente";
        }

        const compra: Compra = {
          idComprador: this.currentUserId,
          fecha: Timestamp.now(),
          productos: productosItem,
        };

        await this.comprasService.addCompra(compra);
        this.limpiarCarroCompras(this.idsList);
        this.actualizarCantidades();
        this.closeButton.nativeElement.click();
        this.getCarro(this.currenUserData?.carroCompras || []);
      } catch (e) {
        console.error(e);
      }
    } else {
      window.alert("La cantidad de unidades no se encuntra Disponible");
    }
  }

  limpiarCarroCompras(ids: string[]): void {
    ids.map((id: string) => {
      this.carroService.deleteCarro(id).then().catch(console.error);
    });
    this.getUserData();
  }

  calcularTotal(): void {
    let contador: number = 0;
    this.productosItem.map((item: CarroCompras) => {
      contador += item.valorTotal;
    });
    this.valorTotal = contador;
  }

  verificarCantidades(): void {
    const observables: Observable<ProductoData>[] = [];
    this.productosItem.map((item: CarroCompras) => {
      const peticion: Observable<ProductoData> =
        this.productService.getProductById(item.idProducto);
      observables.push(peticion);
    });

    from(observables)
      .pipe(
        mergeAll(),
        takeUntil(this.unsubscriber),
        tap({
          next: (res: ProductoData) => {
            const pos: number = this.arrayCantidades.findIndex(
              (elem) => elem.id === res.id
            );
            if (pos !== -1) {
              this.arrayCantidades[pos] = res;
            } else {
              this.arrayCantidades.push(res);
            }

            this.cantidades = {};
            this.arrayCantidades.map((producto: ProductoData) => {
              if (producto.id && !this.cantidades[producto.id]) {
                this.cantidades[producto.id] = {
                  cantidadActual: producto.disponibilidadVenta,
                  cantidadSolicitada: 0,
                };
              }
            });
            this.productosItem.map((producto: CarroCompras) => {
              if (this.cantidades[producto.idProducto]) {
                if (!this.cantidades[producto.idProducto]?.cantidadSolicitada) {
                  this.cantidades[producto.idProducto].cantidadSolicitada =
                    producto.cantidad;
                } else {
                  this.cantidades[producto.idProducto].cantidadSolicitada +=
                    producto.cantidad;
                }
              }
            });
            this.permitirCompras = this.validarCantidadIndividual(
              this.cantidades
            );
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  validarDisponibilidad(cantidad: number, disponibilidad: number): boolean {
    if (cantidad <= disponibilidad) {
      return true;
    } else {
      return false;
    }
  }

  validarCantidadIndividual(cantidad: Cantidad): boolean {
    const keys: string[] = Object.keys(cantidad);
    let resultado: boolean = true;
    keys.map((id: string) => {
      const result: boolean = this.validarDisponibilidad(
        cantidad[id].cantidadSolicitada,
        cantidad[id].cantidadActual
      );

      if (!result) {
        resultado = false;
      }
    });
    return resultado;
  }

  async updateDisponibilidad(
    id: string,
    disponibilidadVenta: number
  ): Promise<void> {
    await this.productService.updateProduct({ disponibilidadVenta }, id);
  }

  async actualizarCantidades(): Promise<void> {
    const tmp: { id: string; valor: number }[] = Object.keys(
      this.cantidades
    ).reduce(
      (
        acc: {
          id: string;
          valor: number;
        }[],
        curr: string
      ) => {
        const obj = {
          id: curr,
          valor:
            this.cantidades[curr].cantidadActual -
            this.cantidades[curr].cantidadSolicitada,
        };
        acc.push(obj);
        return acc;
      },
      []
    );

    for (const producto of tmp) {
      await this.updateDisponibilidad(producto.id, producto.valor);
    }
  }

  traerPedidosMes(idComprador: string) {
    const month: number = new Date().getMonth();
    this.comprasService
      .getPedidoByMonth(idComprador, month)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (pedidos: Compra[]) => {
            this.permitirComprar = pedidos.length < 3;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  getSubscripcion(idUsuario: string): void {
    this.suscripcionSvc
      .getActiveSubscription(idUsuario)
      .then((r: SuscripcionActiva) => {
        this.permitirComprar = r?.suscripcionActual ? true : false;
        if (!this.permitirComprar) {
          this.traerPedidosMes(idUsuario);
        }
      })
      .catch((error) => console.error(error));
  }

  cotizar(): void {
    this.generarCotizacion({
      productos: this.productosItem,
      total: this.valorTotal,
      usuario: this.currenUserData,
    });
  }
}
