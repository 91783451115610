<h3 class="main-title">Usuarios Plataforma</h3>

<div class="btn-group btn-group-toggle mt-1 mb-1" data-bs-toggle="buttons">
  <label class="btn btn-secondary active">
    <input
      type="radio"
      name="options"
      id="todos"
      autocomplete="off"
      (click)="filtrar('todos')"
    />
    Todos
  </label>
  <label class="btn btn-secondary">
    <input
      type="radio"
      name="options"
      id="activos"
      autocomplete="off"
      (click)="filtrar('activos')"
    />
    Activos
  </label>
  <label class="btn btn-secondary">
    <input
      type="radio"
      name="options"
      id="inactivos"
      autocomplete="off"
      (click)="filtrar('inactivos')"
    />
    Inactivos
  </label>
</div>
<div class="buscador">
  <div class="form-group buscador">
    <input
      type="text"
      class="form-control"
      name="filterUser"
      id="inputName"
      placeholder="Buscador Usuario"
      #buscador
    />
  </div>
</div>
<!-- Tabla con datos de usuarios -->
<div class="table-responsive">
  <table class="table table-striped table-bordered text-center">
    <thead class="thead-dark">
      <tr class="text-center">
        <th scope="col">Fecha Registro</th>
        <th scope="col">Tipo</th>
        <th scope="col">Tamaño</th>
        <th scope="col">Nombre</th>
        <th scope="col">Apellidos</th>
        <th scope="col">Email</th>
        <th scope="col">Teléfono</th>
        <th scope="col">Departamento</th>
        <th scope="col">País</th>
        <th scope="col">Estado</th>
        <th scope="col">Suscrito</th>
        <th scope="col">Productos</th>
        <th scope="col">Acción</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of usersList; let i = index">
        <th scope="row">
          <small>
            {{ user.fecha }}
          </small>
        </th>
        <td class="align-middle">
          <ul class="list-unstyled">
            <li *ngFor="let item of getIterable(user.tipo)">
              {{ item | titlecase }}
            </li>
          </ul>
        </td>
        <td class="align-middle">{{ user.tamano }}</td>
        <td class="align-middle">{{ user.nombres | titlecase }}</td>
        <td class="align-middle">{{ user.apellidos | titlecase }}</td>
        <td class="align-middle">{{ user.email }}</td>
        <td class="align-middle">{{ user.telefono }}</td>
        <td class="align-middle">{{ user.departamento | titlecase }}</td>
        <td class="align-middle">{{ user.pais }}</td>
        <td class="align-middle">{{ user.activo ? "Activo" : "Inactivo" }}</td>
        <td class="align-middle">{{ user.suscripcion ? "Si" : "No" }}</td>
        <td class="align-middle">
          {{ (user.productos || []).length ? "Si" : "No" }}
        </td>
        <td class="align-middle">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-info"
              (click)="abirModal(user)"
            >
              Editar
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Paginador-->
<div class="w-100 text-center">
  <button
    class="btn btn-outline-primary"
    (click)="currentPage = currentPage - 1; getUsers()"
    [disabled]="currentPage === 1"
  >
    Anterior
  </button>

  <span *ngIf="!paginas?.includes(1)">
    <button
      class="btn btn-sm btn-outline-secondary mx-1"
      (click)="currentPage = 1; getUsers()"
    >
      1
    </button>
    ...
  </span>
  <button
    class="btn btn-sm btn-outline-secondary mx-1"
    [ngClass]="{ selected: currentPage === page }"
    *ngFor="let page of paginas"
    (click)="currentPage = page; getUsers()"
  >
    {{ page }}
  </button>
  <span *ngIf="!paginas?.includes(+totalPaginas)">
    ...
    <button
      class="btn btn-sm btn-outline-secondary mx-1"
      (click)="currentPage = totalPaginas; getUsers()"
    >
      {{ totalPaginas }}
    </button>
  </span>

  <button
    class="btn btn-outline-primary"
    (click)="currentPage = currentPage + 1; getUsers()"
    [disabled]="currentPage === totalPaginas"
  >
    Siguiente
  </button>
</div>
<!--modal, ventana emergente para eliminar producto de vendedor-->
<div
  class="modal fade"
  id="modalAdminDeleteProduct"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  style="z-index: 9999"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">¡Está seguro!</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        ¿Señor administrador, está seguro que desea eliminar el producto?
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Cerrar
        </button>
        <button type="button" class="btn btn-success">Continuar</button>
      </div>
    </div>
  </div>
</div>

<ng-template #modalAdmin let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Está seguro?</h5>
  </div>
  <div class="modal-body">
    ¿Señor administrador, está seguro que desea guardar los cambios?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss()">
      Cerrar
    </button>
    <button
      type="button"
      class="btn btn-success"
      (click)="save(modal.dismiss)"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      Guardar cambios
    </button>
  </div>
</ng-template>
<!-- profile modal -->
<ng-template #modalUsuario let-modal>
  <div class="container-fluid">
    <div class="w-100 d-flex justify-content-between">
      <h4 class="modal-title" id="modal-basic-title">Editar Usuario</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss()"
      ></button>
    </div>

    <div class="row">
      <!-- columna información del Usiario-->
      <div
        [ngClass]="showProductForms ? 'col-lg-6' : 'col-lg-12'"
        [formGroup]="personalDataForm"
      >
        <h3>Detalle del perfil</h3>

        <h4>Anexos</h4>

        <div class="row mb-3">
          <div class="form-group col-md-6">
            <label for="botonRut">RUT</label>
            <a #rut [href]="archivos.rut" target="_blank" hidden></a>
            <button
              class="btn btn-primary"
              type="button"
              id="botonRut"
              (click)="rut.click()"
              [disabled]="!archivos.rut"
            >
              Ver
            </button>
          </div>
          <div class="form-group col-md-6">
            <a
              #certificado
              [href]="archivos.certificado"
              target="_blank"
              hidden
            ></a>
            <label for="botonCertificado">CERTIFICADO</label>
            <button
              class="btn btn-primary"
              id="botonCertificado"
              type="button"
              (click)="certificado.click()"
              [disabled]="!archivos.certificado"
            >
              Ver
            </button>
          </div>
        </div>
        <app-entidades-usuario [user]="userToEdit"></app-entidades-usuario>
        <h4>Activación perfil</h4>
        <form [formGroup]="personalDataForm">
          <div class="form-check form-check-inline">
            <input
              formControlName="activo"
              class="form-check-input"
              type="radio"
              name="activo"
              id="exampleRadios1"
              [value]="true"
            />
            <label class="form-check-label" for="exampleRadios1">
              Activo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              formControlName="activo"
              class="form-check-input"
              type="radio"
              name="activo"
              id="exampleRadios2"
              [value]="false"
            />
            <label class="form-check-label" for="exampleRadios2">
              Inactivo
            </label>
          </div>
        </form>
        <h4>Selección de roles</h4>
        <form [formGroup]="personalDataForm">
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="row">
                <div class="col-9">
                  <div class="form-group">
                    <select
                      #selectRoles
                      class="form-select"
                      aria-label="Default select example"
                      id="seleccioneCategoria"
                    >
                      <option value="">Elegir rol</option>
                      <option [value]="rol.id" *ngFor="let rol of roles">
                        {{ rol.id | titlecase }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-3">
                  <button
                    class="btn btn-primary"
                    (click)="gestionarRoles(selectRoles.value)"
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <ul>
                <li
                  *ngFor="let rol of personalDataForm.get('tipo')?.value"
                  class="d-flex justify-content-between mt-1"
                >
                  <h5>
                    {{ rol | titlecase }}
                  </h5>
                  <button
                    class="btn btn-danger btn-sm"
                    (click)="gestionarRoles(rol, true)"
                  >
                    <img
                      class="svg-icon"
                      src="../../../assets/imagenes/minus-circle-solid.svg"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </form>
        <h4>Suscripción perfil</h4>
        <app-suscripcion-usuario
          *ngIf="userToEdit"
          [userId]="userToEdit.id"
        ></app-suscripcion-usuario>
        <h4>Datos del perfil</h4>
        <div class="form-group">
          <label for="nombresVendedor">Nombres</label>
          <input
            formControlName="nombres"
            type="text"
            id="nombresVendedor"
            class="form-control"
          />
        </div>

        <div
          class="form-group"
          *ngIf="personalDataForm.get('tamano')?.value === 'Jurídico'"
        >
          <label for="documentID">Representante legal</label>
          <input
            #rLegalInput
            formControlName="rLegal"
            type="text"
            id="rLegal"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="documentID">Número de documento</label>
          <input
            formControlName="numerodocumento"
            type="number"
            id="documentID"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="paisVendedor">País</label>
          <select
            name="seleccionePais"
            class="form-select"
            aria-label="Default select example"
            id="pais"
            formControlName="pais"
            id="paisVendedor"
          >
            <option *ngFor="let pais of listaPaisesUsuario" [value]="pais">
              {{ pais }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="departamentoVendedor">Departamento</label>
          <select
            name="departamento"
            class="form-select"
            aria-label="Default select example"
            id="departamentoVendedor"
            formControlName="departamento"
          >
            <option value="">Selecciona Departamento</option>
            <option
              *ngFor="let departamento of listaDepartamentoUsuario"
              [value]="departamento"
            >
              {{ departamento }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="municipioVendedor">Municipio</label>

          <select
            name="municipio"
            class="form-select"
            aria-label="Default select example"
            id="municipioVendedor"
            formControlName="municipio"
          >
            <option value="">Seleccione Municipio</option>
            <option
              *ngFor="let municipio of listaMunicipiosUsuario"
              [value]="municipio"
            >
              {{ municipio }}
            </option>
          </select>
        </div>
        <div class="d-flex">
          <div class="form-group w-100" disabled>
            <label for="emailVendedor">Email</label>
            <input
              formControlName="email"
              type="text"
              id="emailVendedor"
              class="form-control"
              autocomplete="false"
            />
          </div>
          <button class="btn btn-sm" (click)="abrirCambiarEmail()">
            <img
              class="svg-icon"
              src="../../../assets/imagenes/pen-square-solid.svg"
              alt="lapiz"
            />
          </button>
        </div>
        <div class="form-group">
          <div class="form-group">
            <label for="formGroupExampleInput">Teléfono</label>
            <input
              formControlName="telefono"
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              placeholder="¿Cambiaste de número? Actualízalo aquí"
            />
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput2">Dirección</label>
            <input
              formControlName="direccion"
              type="text"
              class="form-control"
              id="formGroupExampleInput2"
              placeholder="¿Cambiaste de domicilio? Actualízalo aquí"
            />
          </div>
        </div>
        <div class="text-center">
          <button
            class="btn btn-success mt-2"
            [disabled]="personalDataForm.invalid"
            (click)="selectTipo(typeSave.usuario, modalAdmin)"
          >
            Guardar
          </button>
        </div>
      </div>
      <!-- columna detalle del producto-->
      <div id="infoProduct" class="col-lg-6" *ngIf="showProductForms">
        <h3>Asignación de categoría</h3>
        <form>
          <div class="form-group row form-categorias">
            <div class="col-9">
              <select
                #selectCat
                name="selectCategory"
                class="form-select"
                aria-label="Default select example"
                id="selectCategory"
              >
                <option value="">Selecciona una categoría</option>
                <option [value]="cat" *ngFor="let cat of categorias">
                  {{ cat }}
                </option>
              </select>
            </div>
            <div class="col-sm-3 text-center">
              <button
                type="button"
                class="btn btn-success mb-3"
                (click)="addCategoria(selectCat)"
              >
                Agregar
              </button>
            </div>
          </div>
        </form>
        <h5 class="text-center mt-2">Lista de Categorías Habilitadas</h5>
        <!-- Esto debe traer la lista de categorias creadas-->
        <table class="table text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Categoría</th>
              <th scope="col">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let categoria of this.productCategoriesForm?.controls;
                let i = index
              "
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ categoria.value }}</td>
              <td>
                <button
                  class="btn btn-danger"
                  (click)="deleteCategories(categoria)"
                >
                  <img
                    class="svg-icon"
                    src="../../../assets/imagenes/minus-circle-solid.svg"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Información del producto</h3>
        <h4>Especies habilitadas</h4>
        <!--solo para vendedores, se habilitan las especies que posee en el certificado para vender-->
        <form>
          <div class="form-group row form-categorias">
            <div class="col-9">
              <select
                #selectSpecie
                name="selectSpecie"
                class="form-select"
                aria-label="Default select example"
                id="selectSpecie"
              >
                <option value="">Seleccione una especie</option>
                <option
                  [value]="especieOpcion.nombre"
                  *ngFor="let especieOpcion of especieOpciones"
                >
                  {{ especieOpcion.nombre }}
                </option>
              </select>
            </div>
            <div class="col-sm-3 text-center">
              <button
                type="button"
                class="btn btn-success mb-3"
                (click)="addEspecie(selectSpecie)"
              >
                Agregar
              </button>
            </div>
          </div>
        </form>
        <h5 class="text-center mt-2">Lista de Especies Habilitadas</h5>
        <!-- Esto debe traer la lista de especies creadas-->
        <table class="table text-center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Especie</th>
              <th scope="col">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let especie of this.productInfoForm?.controls;
                let i = index
              "
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ especie.value }}</td>
              <td>
                <button class="btn btn-danger" (click)="deleteEspecie(especie)">
                  <img
                    class="svg-icon"
                    src="../../../assets/imagenes/minus-circle-solid.svg"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <span *ngIf="listaProductos.length > 0">
          <h4>Habilitar sello a producto</h4>
          <div class="table-responsive-lg">
            <table class="table table-hover">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Categoría</th>
                  <th scope="col">Fecha de creación</th>
                  <th scope="col">Sello</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let producto of listaProductos; let i = index">
                  <th scope="row">1</th>
                  <td>{{ producto.categoria }}</td>
                  <td>
                    {{
                      producto.tiempoCreado
                        | timestampToString: "es" : "dd/MM/yyyy"
                    }}
                  </td>
                  <td>
                    <form>
                      <div class="form-check">
                        <input
                          (change)="setSello(producto, $event)"
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          [id]="'defaultCheck' + i"
                          [formControl]="producto['indicadorSello']"
                        />
                        <label
                          class="form-check-label"
                          [for]="'defaultCheck' + i"
                        >
                          Activo
                        </label>
                      </div>
                    </form>
                  </td>
                  <td>
                    <button
                      type="button"
                      id="btnDeleteProduct"
                      class="btn btn-danger"
                      data-bs-toggle="tooltip"
                      title="Eliminar producto"
                      data-bs-toggle="tooltip"
                      title="Eliminar producto"
                      data-bs-toggle="modal"
                      data-bs-target="#modalAdminDeleteProduct"
                    >
                      <img src="../../../assets/imagenes/trash-alt-solid.svg" />
                    </button>
                    <button
                      (click)="editProduct(producto)"
                      id="btnDeleteProduct"
                      type="button"
                      class="btn btn-success"
                      data-bs-toggle="tooltip"
                      title="Editar producto"
                    >
                      <img
                        src="../../../assets/imagenes/pen-square-solid.svg"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </span>
        <span *ngIf="productToEdit">
          <h4>Detalle del producto</h4>
          <form id="editarProductoPerfil" [formGroup]="productDetailForm">
            <div class="form-group">
              <label for="seleccioneCategoria">Seleccione Categoría</label>
              <select
                #mySelect
                name="Categoria"
                class="form-select"
                aria-label="Default select example"
                id="seleccioneCategoria"
                formControlName="categoria"
              >
                <option value="">Elegir categoría</option>
                <option
                  [value]="categoria"
                  *ngFor="let categoria of categorias"
                >
                  {{ categoria }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="seleccionePais">País</label>
              <select
                name="seleccionePais"
                class="form-select"
                aria-label="Default select example"
                id="pais"
                formControlName="pais"
              >
                <option *ngFor="let pais of listaPaisesProducto" [value]="pais">
                  {{ pais }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="seleccioneDepartamento">Departamento</label>
              <select
                name="departamento"
                class="form-select"
                aria-label="Default select example"
                id="seleccioneDepartamento"
                formControlName="departamento"
              >
                <option value="">Selecciona Departamento</option>
                <option
                  *ngFor="let departamento of listaDepartamentoProducto"
                  [value]="departamento"
                >
                  {{ departamento }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="seleccioneMunicipio">Municipio</label>
              <select
                name="municipio"
                class="form-select"
                aria-label="Default select example"
                id="seleccioneMunicipio"
                formControlName="municipio"
              >
                <option value="">Seleccione Municipio</option>
                <option
                  *ngFor="let municipio of listaMunicipiosProducto"
                  [value]="municipio"
                >
                  {{ municipio }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="seleccioneEspecie">Seleccione la especie</label>
              <select
                name="especie"
                class="form-select"
                aria-label="Default select example"
                id="seleccioneEspecie"
                formControlName="especie"
              >
                <option value="">Seleccione Especie</option>
                <option
                  [value]="especieOpcion.nombre"
                  *ngFor="let especieOpcion of especieOpciones"
                >
                  {{ especieOpcion.nombre }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="seleccioneSistema">Sistema</label>
              <select
                name="Sistema"
                class="form-select"
                aria-label="Default select example"
                id="seleccioneSistema"
                formControlName="sistema"
              >
                <option value="">Elegir sistema</option>
                <option [value]="sistema" *ngFor="let sistema of sistemas">
                  {{ sistema }}
                </option>
              </select>
            </div>
          </form>
          <div class="text-center">
            <button
              class="btn btn-success mt-3 mb-3"
              data-bs-toggle="modal"
              data-bs-target="#modalAdmin"
              (click)="selectTipo(typeSave.producto, modalAdmin)"
            >
              Guardar
            </button>
          </div>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center w-100">
      <b>Cargando Datos</b>
    </h4>
  </div>
  <div class="modal-body text-center">
    <div class="spinner-border text-success" role="status"></div>
  </div>
</ng-template>
