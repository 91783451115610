import { Injectable } from "@angular/core";
import {
  DocumentReference,
  orderBy,
  where,
  QueryConstraint,
  limit,
  startAfter,
  increment,
  Timestamp,
} from "@angular/fire/firestore";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import moment from "moment";
import { FiltroProducto } from "../models/FiltroProducto";
import { ProductoArbolEnPie } from "../models/ProductoArbolEnPie";
import { ProductoBosque } from "../models/ProductoBosque";
import { ProductoData } from "../models/ProductoData";
import { ProductoMaderaAserrada } from "../models/ProductoMaderaAserrada";
import { ProductoMaderaRollisa } from "../models/ProductoMaderaRollisa";
import { ProductoNomaderable } from "../models/ProductoNoMaderable";
import { ProductoVivero } from "../models/ProductoVivero";
import { StorageService } from "./storage.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { ResponseCurrentUpload } from "../models/storage.interface";

export type ProductData =
  | ProductoArbolEnPie
  | ProductoMaderaRollisa
  | ProductoBosque
  | ProductoVivero
  | ProductoMaderaAserrada
  | ProductoNomaderable;
@Injectable({
  providedIn: "root",
})
export class ProductoService {
  filtroProducto: BehaviorSubject<FiltroProducto> =
    new BehaviorSubject<FiltroProducto>({
      pais: "",
      departamento: "",
      especie: "",
      categoria: "",
      producto: "",
    });
  last: any;

  constructor(
    private firebaseRequests: FirebaseRequestsService,
    private storageService: StorageService
  ) {
    this.storageService = storageService;
  }

  async addProduct(data: ProductoData, files: File[]): Promise<string> {
    try {
      const documentRef: DocumentReference =
        await this.firebaseRequests.addDocFirebaseWithAutomaticId(
          "productos",
          data
        );

      const obs: ResponseCurrentUpload[] = [];

      files.map((file: File, index: number) => {
        const filePath: string = `productos/${documentRef.id}/file_${index}`;
        obs.push(
          this.storageService.uploadFilePercent(filePath, file, file.name)
        );
      });

      this.storageService.currentUploads.next(obs);
      return documentRef.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  async getLatestProducts(limit: number): Promise<ProductoData[]> {
    try {
      const dataProducts: ProductoData[] =
        await this.firebaseRequests.getCollectionFirebasePromiseWithId<ProductoData>(
          "productos",
          [
            orderBy("tiempoCreado", "desc"),
            where("hasSubasta", "==", false),
            where("eliminado", "==", false),
          ]
        );

      const tmpProducts: ProductoData[] = [];

      for (const product of dataProducts) {
        if (product?.id) {
          const filePathSmallSize: string = this.getStoragePath(
            product.id,
            "file_0_500x500"
          );
          const filePathAllSize: string = this.getStoragePath(
            product.id,
            "file_0"
          );
          const thumbnailUrlObservable: Observable<string> = this.storageService
            .getStoreUrlImageObservable(filePathSmallSize)
            .pipe(
              catchError(() => {
                return this.storageService.getStoreUrlImageObservable(
                  filePathAllSize
                );
              })
            );
          product["thumbnailUrl"] = thumbnailUrlObservable;
          if (
            tmpProducts.length < limit &&
            product["disponibilidadVenta"] > 0 &&
            !product["eliminado"]
          ) {
            tmpProducts.push(product as ProductoData);
          }
        }
      }

      return tmpProducts;
    } catch (error) {
      console.error(error);
      throw new Error("Error getting data");
    }
  }

  async getLatestSubastas(limit: number): Promise<ProductoData[]> {
    try {
      const dataProduct: ProductoData[] =
        await this.firebaseRequests.getCollectionFirebasePromiseWithId<ProductoData>(
          "productos",
          [
            orderBy("fInicioSubasta", "desc"),
            where("hasSubasta", "==", true),
            where("fInicioSubasta", "<", Timestamp.now()),
          ]
        );
      const tmpProducts: ProductoData[] = [];
      for (const product of dataProduct) {
        if (product.id) {
          const filePathSmallSize: string = this.getStoragePath(
            product.id,
            "file_0_500x500"
          );
          const filePathAllSize: string = this.getStoragePath(
            product.id,
            "file_0"
          );
          product["thumbnailUrl"] = this.storageService
            .getStoreUrlImageObservable(filePathSmallSize)
            .pipe(
              catchError(() => {
                return this.storageService.getStoreUrlImageObservable(
                  filePathAllSize
                );
              })
            );

          if (tmpProducts.length < limit) {
            if (product.disponibilidadVenta > 0 && !product.eliminado) {
              tmpProducts.push(product);
            }
          }
        }
      }

      return tmpProducts;
    } catch (error) {
      console.error(error);
      throw new Error("Error getting data");
    }
  }

  async getAllImages(productId: string): Promise<string[]> {
    const filePaths = [
      this.getStoragePath(productId, "file_0_500x500"),
      this.getStoragePath(productId, "file_1_500x500"),
      this.getStoragePath(productId, "file_2_500x500"),
      this.getStoragePath(productId, "file_3_500x500"),
    ];

    const filePathPromises = filePaths.map(async (filePath: string) => {
      try {
        return await this.storageService.getStoreUrlImagePromise(filePath);
      } catch {
        const urlSplit = filePath.split("_500");
        return await this.storageService.getStoreUrlImagePromise(urlSplit[0]);
      }
    });

    const filePathData: PromiseSettledResult<string>[] =
      await Promise.allSettled(filePathPromises);

    const pathsValue = filePathData
      .filter(
        (result): result is PromiseFulfilledResult<string> =>
          result.status === "fulfilled"
      )
      .map((result) => result.value);

    return pathsValue;
  }

  async getAllImagesObject(
    productId: string
  ): Promise<{ [x: string]: string }> {
    const tmp: {
      [x: string]: string;
    } = {};
    try {
      await Promise.allSettled(
        new Array(4).fill(0).map(async (elem, pos) => {
          try {
            const path: string = this.getStoragePath(
              productId,
              `file_${pos}_500x500`
            );
            tmp[`file_${pos}`] =
              (await this.storageService.getStoreUrlImagePromise(path)) || "";
          } catch (e) {
            const path: string = this.getStoragePath(productId, `file_${pos}`);
            tmp[`file_${pos}`] =
              (await this.storageService.getStoreUrlImagePromise(path)) || "";
          }
        })
      );
      return tmp;
    } catch (error) {
      console.error(error);
      throw new Error("Error getting data");
    }
  }

  getStoragePath(productId: string, fileName: string): string {
    return `productos/${productId}/${fileName}`;
  }

  crearFiltro(
    filter: FiltroProducto,
    last: ProductoData | undefined
  ): QueryConstraint[] {
    const queryConstraints: QueryConstraint[] = [
      orderBy("categoria", "asc"),
      where("disponibilidadVenta", ">", 0),
    ];

    Object.entries(filter).map(([key, value]) => {
      if (typeof value === "string" && value.trim() === "") return;

      queryConstraints.push(where(key, "==", value));
    });

    queryConstraints.push(limit(16));

    if (last) {
      const copyLast = { ...last };
      delete copyLast.thumbnailUrl;
      queryConstraints.push(startAfter(copyLast["docSnap"]));
    }

    return queryConstraints;
  }

  async getProductFiltro(last?: ProductoData): Promise<{
    productos: ProductoData[];
    last: any;
  }> {
    try {
      const filter: FiltroProducto = this.filtroProducto.getValue();
      const produtosCollection: QueryConstraint[] = this.crearFiltro(
        filter,
        last
      );
      const result: ProductoData[] =
        (await this.firebaseRequests.getCollectionFirebasePromiseWithDocSnap(
          "productos",
          produtosCollection
        )) as ProductoData[];

      this.last = result[result.length - 1] ? result[result.length - 1] : null;

      for (const producto of result) {
        if (producto.id) {
          const filePathSmallSize: string = this.getStoragePath(
            producto.id,
            "file_0_500x500"
          );
          const filePathAllSize: string = this.getStoragePath(
            producto.id,
            "file_0"
          );
          const thumbnailUrlObservable: Observable<string> = this.storageService
            .getStoreUrlImageObservable(filePathSmallSize)
            .pipe(
              catchError(() => {
                return this.storageService.getStoreUrlImageObservable(
                  filePathAllSize
                );
              })
            );
          producto["thumbnailUrl"] = thumbnailUrlObservable;
        }
      }

      return { productos: [...result], last: this.last };
    } catch (error) {
      console.error(error);
      throw new Error("Error getting data");
    }
  }

  getProductById(id: string): Observable<ProductoData> {
    return this.firebaseRequests.getDocFirebaseWithIdObservable<ProductoData>(
      `productos/${id}`
    );
  }

  getProductInfo(id: string): Observable<any> {
    return this.firebaseRequests.getDocFirebaseWithIdObservable<ProductoData>(
      `productos/${id}`
    );
  }

  async updateProduct(
    data: ProductoData | { disponibilidadVenta: any } | any,
    uid: string
  ): Promise<void> {
    try {
      await this.firebaseRequests.updateDocFirebase(`productos/${uid}`, data);
    } catch (error) {
      console.error(error);
      throw new Error("Error updating data");
    }
  }

  eliminarProducto(productId: string): Promise<void> {
    return this.firebaseRequests.updateDocFirebase(`productos/${productId}`, {
      eliminado: true,
    });
  }

  // funcion para restarurar unidades disponibles, la cantidad es la cantidad que va a sumarse a la disponibilidad
  restaurarUnidadesDisponibles(
    idProducto: string,
    cantidad: number
  ): Promise<void> {
    return this.firebaseRequests.updateDocFirebase(`productos/${idProducto}`, {
      disponibilidadVenta: increment(cantidad),
    });
  }

  async updateImages(
    productId: string,
    files: { [id: string]: File }
  ): Promise<boolean> {
    try {
      await Promise.all(
        Object.keys(files).map(async (key: string) => {
          const filePath = this.getStoragePath(productId, key);
          await lastValueFrom(
            this.storageService.uploadFile(filePath, files[key])
          );
        })
      );
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async traerProductosMes(idUsuario: string): Promise<ProductoData[]> {
    const start: Date = moment().startOf("month").toDate();
    const end: Date = moment().endOf("month").toDate();

    return this.firebaseRequests.getCollectionFirebasePromiseWithId<ProductoData>(
      "productos",
      [
        where("idVendedor", "==", idUsuario),
        where("tiempoCreado", ">=", Timestamp.fromDate(start)),
        where("tiempoCreado", "<=", Timestamp.fromDate(end)),
      ]
    );
  }

  async getProductsByUserId(userId: string): Promise<ProductoData[]> {
    return this.firebaseRequests.getCollectionFirebasePromiseWithId<ProductoData>(
      "productos",
      [orderBy("tiempoCreado", "desc"), where("idVendedor", "==", userId)]
    );
  }

  async finalizarSubasta(subastaId: string): Promise<void> {
    try {
      this.firebaseRequests.updateDocFirebase(`subastas/${subastaId}`, {
        estado: "terminada",
      });
    } catch (error) {
      console.error(error);
      throw new Error("error al finalizar subasta");
    }
  }
}
