import { Component, ElementRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CotizacionPdfService } from "../services/cotizacion-pdf.service";
import { CertificadoPdfService } from "../services/certificado-pdf.service";
import { Actions } from "../enums/Actions";

@Component({
  selector: "app-validador",
  templateUrl: "./validador.component.html",
  styleUrls: ["./validador.component.css"],
})
export class ValidadorComponent {
  @ViewChild("notFound") notFound!: ElementRef;
  idCotizacion: string = "";
  idCertificado: string = "";
  tipo!: string;
  isLoading!: boolean;
  actions: typeof Actions = Actions;

  constructor(
    private cotizacionPdfSvc: CotizacionPdfService,
    private modalService: NgbModal,
    private certificadoPdfSvc: CertificadoPdfService
  ) {}

  async searchCotizacion(): Promise<void> {
    this.tipo = "cotizacion";
    this.isLoading = true;
    try {
      this.idCotizacion &&
        (await this.cotizacionPdfSvc.generarCotizacionPdf(
          this.idCotizacion,
          "open",
          this.showNotFound.bind(this),
          () => {
            this.isLoading = false;
          }
        ));
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  async searchCertificado(accion: Actions): Promise<void> {
    this.tipo = "certificado";
    this.isLoading = true;
    try {
      this.idCertificado &&
        (await this.certificadoPdfSvc.generarCertificadoPdf(
          this.idCertificado,
          accion,
          this.showNotFound.bind(this),
          () => {
            this.isLoading = false;
          }
        ));
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  showNotFound(): void {
    this.modalService.open(this.notFound, { centered: true });
    this.isLoading = false;
  }
}
