import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class NavigateService {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  goTo(path: string): void {
    this.router.navigate([path], { relativeTo: this.route.parent });
  }
}
