import { Injectable } from "@angular/core";
import { User, UserCredential } from "@angular/fire//auth";
import { Action, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { produce } from "immer";
import * as authenticationActions from "./actions";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";

export interface AuthenticationStateModel {
  user: User | null;
  userIsActive: boolean;
  userIsNew: boolean;
}

const DEFAULT_STATE: AuthenticationStateModel = {
  user: null,
  userIsActive: false,
  userIsNew: false,
};

@State<AuthenticationStateModel>({
  name: "authentication",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AuthenticationState implements NgxsOnInit {
  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {}

  @Action(authenticationActions.LogIn)
  async logIn(
    ctx: StateContext<AuthenticationStateModel>,
    action: authenticationActions.LogIn
  ) {
    const { user } = action.payload;
    const userDocument = await this.userService.getUserData(user.uid);
    const userIsActive = userDocument.activo;

    ctx.setState(
      produce(ctx.getState(), (state) => {
        state.user = action.payload.user;
        state.userIsActive = userIsActive;
        state.userIsNew = Boolean(action.payload.userIsNew);
      })
    );

    if (!userIsActive) {
      this.authService.logout();
    }
  }

  @Action(authenticationActions.LogOut)
  logOut(ctx: StateContext<AuthenticationStateModel>) {
    this.userService.clearUser();

    ctx.setState(
      produce(ctx.getState(), (state) => {
        state.user = null;
        state.userIsActive = false;
        state.userIsNew = false;
      })
    );
  }

  @Action(authenticationActions.CreateUser)
  async createUser(
    ctx: StateContext<AuthenticationStateModel>,
    action: authenticationActions.CreateUser
  ) {
    const { email, contraseña, data, files, onlyRegister, modalService } =
      action.payload;

    try {
      const userCredential: UserCredential | undefined =
        await this.authService.createUserWithEmailAndPasswordInFirebase(
          email,
          contraseña
        );
      if (userCredential) {
        const user: User | null = this.authService.getCurrentUser();
        await this.userService.addUser(data, files, userCredential.user.uid);
        if (!onlyRegister && user) {
          ctx.dispatch(
            new authenticationActions.LogIn({ user, userIsNew: true })
          );
        }
        modalService.loading.next(false);
      }
    } catch (error: any) {
      console.error(error);
      alert(error.message);
    }
  }

  @Selector()
  static displayUserInactiveAlert(state: AuthenticationStateModel) {
    return state.user && !state.userIsActive && !state.userIsNew;
  }

  @Selector()
  static userIsActive(state: AuthenticationStateModel) {
    return state.userIsActive;
  }

  // @dev Commented out because of bug in ngxs-firestore-plugin.
  // @Selector<AuthenticationStateModel["user"]>()
  @Selector<any["user"]>([])
  static user(state: AuthenticationStateModel) {
    return state.user;
  }

  ngxsOnInit() {}
}
