export const estudiosTecnicos: string[] = [
  "Sin Estudios",
  "Primaria",
  "Bachiller",
  "Técnico Forestal",
  "Carpintero",
  "Ebanista",
  "Técnico en Operaciones Forestales",
  "Técnico en Conservación de Recursos Naturales",
  "Técnico en Aprovechamiento y Beneficio del Cultivo del Caucho Natural",
  "Técnico en Establecimiento y Mantenimiento de Plantaciones de Caucho",
  "Técnico en Construcción, Mantenimiento y Reparación de Estructuras en Guadua",
  "Tecnólogo en Silvicultura y Aprovechamiento de Plantaciones Forestales",
  "Tecnólogo en Manejo y Aprovechamiento Forestal",
  "Tecnólogo en Gestión de Recursos Naturales",
  "Tecnólogo Forestal",
  "Ingeniero Forestal",
  "Ingeniero Agroforestal",
  "Ingeniero Agrónomo",
  "Ingeniero Ambiental",
  "Biólogo",
];
