import { Injectable, OnDestroy } from "@angular/core";
import { CrudService } from "src/app/core/service/crud.service";
import { NEW_WT_USER, WtUser } from "../model/wt-user";
import { WtUserState } from "../model/wt-user.state";
import { WtUserFirestoreService } from "./wt-user-firestore.service";
import { WtUserStore } from "./wt-user.store";

@Injectable({
  providedIn: "root",
})
export class WtUserService
  extends CrudService<WtUser, WtUserState>
  implements OnDestroy
{
  constructor(
    protected override firestore: WtUserFirestoreService,
    protected override store: WtUserStore
  ) {
    super(firestore, store);
  }

  public init(): void {
    this.firestore.basePath = "wt_users";
  }

  public emptyDocument(): WtUser {
    return NEW_WT_USER;
  }
}
