import { Component, OnDestroy, OnInit } from "@angular/core";
import { DocumentData } from "@angular/fire/firestore";
import { Subject, forkJoin, switchMap, takeUntil, tap } from "rxjs";
import { Path } from "../enums/Path";
import { ProductoData } from "../models/ProductoData";
import { ProductoService } from "../services/producto.service";
import { PublicidadService } from "../services/publicidad.service";
import { StorageService } from "../services/storage.service";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit, OnDestroy {
  private sub$: Subject<void> = new Subject();
  public nuevosProductos: Array<ProductoData> = [];
  public nuevasSubastas: Array<ProductoData> = [];
  public path: typeof Path = Path;
  public slides: any = {
    slide1: {
      src: "../../assets/imagenes/img2.webp",
      href: "",
    },
    slide2: {
      src: "../../assets/imagenes/img1.webp",
      href: "",
    },
    slide3: {
      src: "../../assets/imagenes/img3.webp",
      href: "",
    },
  };

  constructor(
    public productoService: ProductoService,
    private publicidadService: PublicidadService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.getPublicidad();
    this.getProducts();
  }

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  async getProducts(): Promise<void> {
    this.nuevosProductos = await this.productoService.getLatestProducts(5);
    this.nuevasSubastas = await this.productoService.getLatestSubastas(5);
  }

  redireccionFiltro(categoria: string): void {
    this.productoService.filtroProducto.next({
      categoria,
      departamento: "",
      especie: "",
      pais: "",
      producto: "",
    });
  }

  getPublicidad(): void {
    this.publicidadService
      .getPublicidad(true)
      .pipe(
        takeUntil(this.sub$),
        switchMap((publicidad: DocumentData[]) => {
          return forkJoin(
            publicidad.map((publicidad: DocumentData) => {
              return this.storageService
                .getStoreUrlImageObservable(publicidad["imagen"])
                .pipe(
                  takeUntil(this.sub$),
                  tap((src: string) => {
                    this.slides[publicidad["nombre"]] = {
                      src,
                      href: publicidad["enlace"],
                    };
                  })
                );
            })
          );
        })
      )
      .subscribe();
  }

  openHref(enlace: string): void {
    if (enlace) {
      window.open(enlace);
    }
  }
}
