import { Component, OnInit } from "@angular/core";
import { orderBy } from "@angular/fire/firestore";
import { Observable, firstValueFrom, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { Pqr } from "src/app/models/pqr.interface";
import { FirebaseRequestsService } from "src/app/services/firebase-requests.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-comentarios",
  templateUrl: "./comentarios.component.html",
  styleUrls: ["./comentarios.component.css"],
})
export class ComentariosComponent implements OnInit {
  comentarios!: Pqr[];
  last: any;
  firstInResponse: any;
  prevStrtAt!: any[];
  paginationClickedCount!: number;
  limit: number = 5;
  disableNext!: boolean;
  disablePrev!: boolean;
  pages: any[] = [];
  pqrs!: Pqr[];
  currentPage: number = -1;

  constructor(
    private storageService: StorageService,
    private firebaseRequest: FirebaseRequestsService
  ) {}

  ngOnInit(): void {
    this.getPqrInit();
  }

  getPqrInit(): void {
    this.firebaseRequest
      .getCollectionFirebasePromiseWithId<Pqr>("pqr", [
        orderBy("fecha", "desc"),
      ])
      .then((pqrs: Pqr[]) => {
        this.pqrs = pqrs;
        this.getPages(pqrs.length);
        this.nexto();
      })
      .catch((err) => console.error(err));
  }

  getLogo(idUsuario: string): Observable<string> {
    const pathstring: string = `fotoPerfilUser/${idUsuario}`;
    return this.storageService
      .getStoreUrlImageObservable(`${pathstring}_500x500`)
      .pipe(
        catchError(() => {
          return this.storageService
            .getStoreUrlImageObservable(`${pathstring}`)
            .pipe(
              catchError(() => {
                return of("");
              })
            );
        })
      );
  }

  trackByComentario(index: number, item: any): any {
    return item.comentario;
  }

  async parseDocuments(response: Pqr[]): Promise<Pqr[]> {
    try {
      const docs: Pqr[] = await Promise.all(
        response.map(async (comentario: Pqr) => {
          const urlFoto: string = await firstValueFrom(
            this.getLogo(comentario.idUsuario || "")
          );
          comentario.foto = urlFoto;
          return comentario;
        })
      );

      return docs;
    } catch (e) {
      console.error(e);
      throw new Error("Error getting Data");
    }
  }

  getPages(size: number = 0): void {
    this.pages = new Array(Math.ceil(size / this.limit)).fill("*");
  }

  nexto(): void {
    this.currentPage++;
    this.setPage();
  }

  prevo(): void {
    this.currentPage--;
    this.setPage();
  }

  async setPage(page?: number): Promise<void> {
    if (page) {
      this.currentPage = page - 1;
    }
    const copy: Pqr[] = Object.assign([], this.pqrs);
    const posIni: number = this.limit * this.currentPage;
    const fin: number = this.limit * this.currentPage + this.limit;
    const posFin: number =
      this.pages.length === this.currentPage ? copy.length - 1 : fin;
    this.disableNext = this.pages.length === this.currentPage + 1;
    this.disablePrev = this.currentPage === 0;
    try {
      this.comentarios = await this.parseDocuments(copy.slice(posIni, posFin));
    } catch (e) {
      console.error(e);
    }
  }
}
