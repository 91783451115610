import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import moment from "moment";
moment.locale("es");
import { paises } from "src/assets/data/paises";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { ChartDatasetCustomTypesPerDataset, ChartType } from "chart.js";
import { EstadisticasService } from "src/app/services/estadisticas.service";
import { EspeciesService } from "src/app/services/especies.service";
import { Especie } from "src/app/models/especie.interface";
import { HttpsCallableResult } from "firebase/functions";

enum Secciones {
  "usuarios" = 1,
  "productos" = 2,
  "geografica" = 3,
  "suscripciones" = 4,
  "mensajes" = 5,
}

@Component({
  selector: "app-estadisticas",
  templateUrl: "./estadisticas.component.html",
  styleUrls: ["./estadisticas.component.css"],
})
export class EstadisticasComponent implements OnInit {
  // referencia a modal de carga
  @ViewChild("loadingModal") loadingModal!: ElementRef;

  public statistics: {
    [x: string]: any;
  } = {};

  public meses: string[] = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  public especies: Especie[] = [];
  public graphicTypes: { [x: string]: ChartType } = {
    usersChartData: "bar",
    typeUsersChartData: "bar",
    productsChartData: "bar",
    usersGeoCountryChartData: "bar",
    subscriptionsChartData: "bar",
    subscriptionsQtyChartData: "bar",
    usersGeoDepartmentChartData: "bar",
    entityUsersChartData: "doughnut",
    preguntasChartData: "bar",
    respuestasChartData: "bar",
  };

  public paises: string[] = Object.keys(paises);

  // ------- start charts settings ------

  public barChartOptions: any = {
    responsive: true,
    scales: {
      x: {},
      y: {
        id: "y-axis-0",
        position: "left",
      },
    },
    legend: {
      position: "top",
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end",
      },
    },
  };

  public graphics: { [key: string]: ChartDatasetCustomTypesPerDataset[] } = {};
  public barChartLabels: string[] = [];
  // ----- end charts settings -------

  public currentYear = moment().format("YYYY");
  public tmpMonths!: string[];
  public aniosSelect: number[] = [];
  public secciones: typeof Secciones = Secciones;
  public active: Secciones = this.secciones.usuarios;
  public tablas: any = {};
  public modalLoader: any;
  public maxAndMin: any = {};

  constructor(
    private estadisticas: EstadisticasService,
    private modalService: NgbModal,
    private especiesService: EspeciesService,
    public config: NgbModalConfig
  ) {
    // se deja modal de carga con backdrop estatico
    config.backdrop = "static";
    config.keyboard = false;
    this.getYears();
    this.getEspecies();
  }

  onChartTypeChange(event: ChartType) {
    console.log("El tipo de gráfico seleccionado es:", event);
    console.log(this.graphicTypes);
  }

  async getEspecies(): Promise<void> {
    try {
      this.especies = (await this.especiesService.getAll()) as Especie[];
    } catch (e) {
      console.error(e);
      this.especies = [];
    }
  }

  ngOnInit(): void {
    this.getStatistics();
  }

  // Procedimiento para traer datos de estadisticas
  async getStatistics(event?: any): Promise<void> {
    if (event) {
      Promise.resolve(null).then(
        () => event && (this.currentYear = event || this.currentYear)
      );
    }

    if (this.currentYear.includes(moment().format("YYYY"))) {
      const monthNumber = moment().format("MM");
      this.tmpMonths = [...this.meses].splice(0, +monthNumber);
    } else {
      this.tmpMonths = [...this.meses];
    }

    this.load();
    this.estadisticas
      .getEstadisticas(this.currentYear)
      .then((result: HttpsCallableResult<any>) => {
        const { usuarios, productos, suscripciones, mensajes, respuestas } =
          result.data;
        this.statistics["usuarios"] = usuarios;
        this.statistics["productos"] = productos;
        this.statistics["suscripciones"] = suscripciones;
        this.statistics["mensajes"] = mensajes;
        this.statistics["respuestas"] = respuestas;
        this.barChartLabels = [...this.tmpMonths];
        this.setGraphics();
        if (productos) this.setGraphicsProducts();
      })
      .catch((err) => {
        alert("error en backend :(");
        console.error(err);
        // this.load(true);
      });
  }

  // Función que retorna cantidad de un dato especifico
  // category: categoría de graficos ("usuarios" | "productos" | "pedidos" | "suscripciones")
  // month: mes del cual se va a traer la cantidad
  // tipo de indicador que se va a traer: ej: 'activos' | 'registrados'
  getQty(
    category:
      | "usuarios"
      | "productos"
      | "pedidos"
      | "suscripciones"
      | "mensajes"
      | "respuestas",
    month: string,
    type: string,
    child?: string
  ): number {
    try {
      if (child) {
        return this.statistics[category][child][type] || 0;
      }
      return this.statistics[category][month][type] || 0;
    } catch (e) {
      return 0;
    }
  }

  // Función que estructura data de las graficas y las tablas
  // fields: campos que se van a obtener para la grafica o tabla
  // variable: nombre de la variable donde se va a guardar la  información
  getGraphics(
    type:
      | "usuarios"
      | "productos"
      | "pedidos"
      | "suscripciones"
      | "mensajes"
      | "respuestas",
    fields: string[],
    variable: string,
    child?: string
  ): void {
    this.graphics[variable] = [];
    const tmp: any = [];
    this.tablas[variable] = [];
    if (child) {
      const labels: string[] = [];
      const data: number[] = [];
      const keys: string[] = Object.keys(this.statistics[type][child] || {});
      this.tablas[variable].labels = keys.map((k) => "(" + k.split("(")[1]);
      const totals = {};
      keys.forEach((tipo) => {
        const element = this.getQty(type, tipo, tipo, child);
        labels.push("(" + tipo.split("(")[1]);
        data.push(element);
        const pos = this.tablas[variable].findIndex(
          (t: any) => t.tipo === tipo
        );
        this.tablas[variable] ||= {};
        if (pos === -1) {
          this.tablas[variable].push({ tipo, cantidad: element });
        } else {
          this.tablas[variable][pos][tipo] = element;
        }

        const hasTotals = this.tablas[variable].findIndex((t: any) => t.total);
        if (hasTotals !== -1) this.tablas[variable].splice(hasTotals, 1);
        this.tablas[variable].push({ ...totals, total: true });
      });

      tmp.push({
        label: "Entidades",
        data,
        hoverOffset: 4,
      });
    } else {
      const totals: any = {};
      fields.forEach((tipo: string) => {
        let cont = 0;
        const obj = {
          data: [] as number[],
          label: tipo.charAt(0).toUpperCase() + tipo.slice(1),
          borderWidth: 1,
        };
        this.tmpMonths.forEach((mes: string) => {
          const element = this.getQty(type, mes, tipo);
          cont = cont + element;
          const pos = this.tablas[variable].findIndex(
            (t: any) => t.mes === mes
          );
          this.tablas[variable] ||= {};

          if (pos === -1) {
            this.tablas[variable].push({ mes, [tipo]: element });
          } else {
            this.tablas[variable][pos][tipo] = element;
          }
          obj.data.push(element);
          totals[tipo] = cont;

          const hasTotals = this.tablas[variable].findIndex(
            (t: any) => t.total
          );
          if (hasTotals !== -1) this.tablas[variable].splice(hasTotals, 1);
          this.tablas[variable].push({ ...totals, total: true });
        });
        tmp.push(obj);
      });
    }
    this.graphics[variable] = tmp;
  }

  // Función que trae máximos y minimos de la data
  // data: objeto donde se guarda la información
  maxMin(data: any): { max: string; min: string } {
    const qty = { max: 0, min: 9007199254740991 };
    const tmp = { max: "", min: "" };
    Object.keys(data).forEach((elem) => {
      if (!Object.hasOwnProperty.call(qty, "min")) {
        qty.min = data[elem];
      }
      if (data[elem] > qty.max) {
        qty.max = data[elem];
        tmp.max = elem;
      }

      if (data[elem] < qty.min) {
        qty.min = data[elem];
        tmp.min = elem;
      }
    });
    return tmp;
  }

  // Función que estructura y setea los datos de cada grafica
  setGraphics(): void {
    const data: {
      type:
        | "usuarios"
        | "productos"
        | "pedidos"
        | "suscripciones"
        | "mensajes"
        | "respuestas";
      fields: string[];
      variableName: string;
      child?: string;
    }[] = [
      {
        type: "usuarios",
        fields: ["registrados", "activados"],
        variableName: "usersChartData",
      },
      {
        type: "usuarios",
        fields: this.statistics["usuarios"]?.paises || [],
        variableName: "usersGeoCountryChartData",
      },
      {
        type: "usuarios",
        fields: this.statistics["usuarios"]?.departamentos || [],
        variableName: "usersGeoDepartmentChartData",
      },
      {
        type: "usuarios",
        fields: [
          "Natural",
          "Jurídico",
          "comprador",
          "vendedor",
          "tecnico",
          "transportador",
        ],
        variableName: "typeUsersChartData",
      },
      {
        type: "usuarios",
        fields: ["activo"],
        variableName: "entityUsersChartData",
        child: "entidades",
      },
      {
        type: "productos",
        fields: ["creado", ...(this.statistics["productos"]?.categorias || [])],
        variableName: "productsChartData",
      },
      {
        type: "suscripciones",
        fields: [1, 3, 6, 12].map(
          (cantidad) => `${cantidad} mes${cantidad > 1 ? "es" : ""}`
        ),
        variableName: "subscriptionsChartData",
      },
      {
        type: "suscripciones",
        fields: ["activo"],
        variableName: "subscriptionsQtyChartData",
      },
      {
        type: "mensajes",
        fields: ["creado"],
        variableName: "preguntasQtyChartData",
      },
      {
        type: "respuestas",
        fields: ["creado"],
        variableName: "respuestasQtyChartData",
      },
    ];

    data.forEach((data) => {
      const { type, fields, variableName, child } = data;
      this.getGraphics(type, fields, variableName, child);
    });
    this.maxMinGenerate();
    this.load(true);
  }

  // función para la generación de las graficas dinamicas de productos por categoría / especies
  setGraphicsProducts(): void {
    const data = this.statistics["productos"]?.precios || {};
    Object.keys(data).forEach((category: string) => {
      const obj: any = {
        data: [] as number[],
        label: category.charAt(0).toUpperCase() + category.slice(1),
        borderWidth: 1,
      };

      const variable: string = `${category}ProductChartData`;
      this.graphics[variable] = [];
      this.tablas[variable] = [];

      Object.keys(data[category]).forEach((specie: string) => {
        const element = data[category][specie].promedio || 0;
        this.tablas[variable] ||= [];

        this.tablas[variable].push({
          especie: specie,
          precio: element,
        });

        obj.data.push(element);
      });
      this.graphics[variable] = [obj];
    });
  }

  // Función para traer especies de una categoría especfica en las graficas de categoría / especie
  getEspeciesCategory(categoria: string): string[] {
    const data = this.statistics["productos"]?.precios || {};
    return Object.keys(data[categoria]);
  }

  // Procedimiento para traer los últimos 5 años;
  // limit: número de años a generar.
  getYears(limit: number = 5): void {
    const max = +moment().format("YYYY");
    const min = max - limit;

    const len = max - min;
    const arr = new Array(len);
    for (let i = 0; i <= len; i++) {
      arr[i] = min + i;
    }
    this.aniosSelect = arr;
  }

  // Procedimiento para mostrar modal informativa de carga de datos
  // close: parametro para indicar si cierra la modal que ya está abierta
  load(close?: boolean): void {
    if (close && this.modalLoader) {
      this.modalLoader.dismiss();
      delete this.modalLoader;
      return;
    }
    this.modalLoader = this.modalService.open(this.loadingModal, {
      ariaLabelledBy: "loading-modal",
      centered: true,
    });
  }

  // retorna la cantidad maxíma o minima de un campo especifico
  // tipo: usuarios | productos | suscripciones
  // fields: campos a los cuales se va a acceder
  getMaxMin(tipo: string, fields: string[]): number {
    try {
      let data = this.statistics[tipo];
      fields.forEach((field) => {
        data = data[field];
      });
      return data;
    } catch (e) {
      return 0;
    }
  }

  // Función para estructurar datos de  máximos y minimos que se van a mostrar
  maxMinGenerate() {
    this.maxAndMin = {
      usersChartData: [
        {
          label: "Mes con más registros: ",
          description: `${this.getMaxMin("usuarios", [
            "maxRegistrados",
            "mes",
          ])} (${this.getMaxMin("usuarios", ["maxRegistrados", "cantidad"])}) `,
          cantidad: this.getMaxMin("usuarios", ["maxRegistrados", "cantidad"]),
        },
        {
          label: "Mes con más activaciones: ",
          description: `${this.getMaxMin("usuarios", [
            "maxActivados",
            "mes",
          ])} (${this.getMaxMin("usuarios", ["maxActivados", "cantidad"])})`,
          cantidad: this.getMaxMin("usuarios", ["maxActivados", "cantidad"]),
        },
      ],
      typeUsersChartData: [
        {
          label: "Tamaño más creado: ",
          description: `${this.getMaxMin("usuarios", [
            "maxTamano",
            "tipo",
          ])} (${this.getMaxMin("usuarios", ["maxTamano", "cantidad"])})`,
          cantidad: this.getMaxMin("usuarios", ["maxTamano", "cantidad"]),
        },
        {
          label: "Tipo más creado: ",
          description: `${this.getMaxMin("usuarios", [
            "maxTipo",
            "tipo",
          ])} (${this.getMaxMin("usuarios", ["maxTipo", "cantidad"])})`,
          cantidad: this.getMaxMin("usuarios", ["maxTipo", "cantidad"]),
        },
      ],
      usersGeoCountryChartData: [
        {
          label: "País con más registros: ",
          description: `${this.getMaxMin("usuarios", [
            "maxPaises",
            "pais",
          ])} (${this.getMaxMin("usuarios", ["maxPaises", "cantidad"])})`,
          cantidad: this.getMaxMin("usuarios", ["maxPaises", "cantidad"]),
        },
      ],
      usersGeoDepartmentChartData: [
        {
          label: "Departamento con más registros: ",
          description: `${this.getMaxMin("usuarios", [
            "maxDepartamento",
            "departamento",
          ])} (${this.getMaxMin("usuarios", ["maxDepartamento", "cantidad"])})`,
          cantidad: this.getMaxMin("usuarios", ["maxDepartamento", "cantidad"]),
        },
      ],
      subscriptionsQtyChartData: [
        {
          label: "Mes con más suscripciones activas: ",
          description: `${this.getMaxMin("suscripciones", [
            "maxActivado",
            "mes",
          ])} (${this.getMaxMin("suscripciones", [
            "maxActivado",
            "cantidad",
          ])})`,
          cantidad: this.getMaxMin("suscripciones", [
            "maxActivado",
            "cantidad",
          ]),
        },
        {
          label: "Mes con menos suscripciones activas: ",
          description: `${this.getMaxMin("suscripciones", [
            "minActivado",
            "mes",
          ])} (${this.getMaxMin("suscripciones", [
            "minActivado",
            "cantidad",
          ])})`,
          cantidad: this.getMaxMin("suscripciones", [
            "minActivado",
            "cantidad",
          ]),
        },
      ],
      subscriptionsChartData: [
        {
          label: "Suscripción más paga: ",
          description: `${this.getMaxMin("suscripciones", [
            "maxPago",
            "duracion",
          ])} (${this.getMaxMin("suscripciones", ["maxPago", "cantidad"])})`,
        },
        {
          label: "Suscripción más paga: ",
          description: `${this.getMaxMin("suscripciones", [
            "maxPago",
            "duracion",
          ])} (${this.getMaxMin("suscripciones", ["maxPago", "cantidad"])})`,
          cantidad: this.getMaxMin("suscripciones", ["maxPago", "cantidad"]),
        },
      ],
      preguntasQtyChartData: [
        {
          label: "Mes con más preguntas: ",
          description: `${this.getMaxMin("mensajes", [
            "maxTipo",
            "tipo",
          ])} (${this.getMaxMin("mensajes", ["maxTipo", "cantidad"])}) `,
          cantidad: this.getMaxMin("mensajes", ["maxTipo", "cantidad"]),
        },
      ],
    };
  }

  updateModel(propName: string, event: any) {
    const value: ChartType = event.target.value;
    this.graphicTypes[propName] = value;
  }
}
