import { Component } from "@angular/core";
import { Path } from "../enums/Path";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  year: number = new Date().getFullYear();
  public path: typeof Path = Path;

  constructor() {}
}
