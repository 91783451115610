import { Component } from "@angular/core";

import { Path } from "../enums/Path";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent {
  public path: typeof Path = Path;
  // referencia elementos dom

  constructor() {}
}
