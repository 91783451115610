import { NgModule } from "@angular/core";
import { RouterModule, Routes, mapToCanActivate } from "@angular/router";
import { Path } from "../enums/Path";
import { AdminComponent } from "./admin.component";
import { ComentariosComponent } from "./comentarios/comentarios.component";
import { EstadisticasComponent } from "./estadisticas/estadisticas.component";
import { FacturacionComponent } from "./facturacion/facturacion.component";
import { PermisosComponent } from "./permisos/permisos.component";
import { PublicidadComponent } from "./publicidad/publicidad.component";
import { UsuariosComponent } from "./usuarios/usuarios.component";
import { AuthGuard, redirectUnauthorizedTo } from "@angular/fire/auth-guard";
import { AccessGuard } from "../guards/access.guard";
import { UserType } from "../enums/UserTypes";
import { AliadosComponent } from "./aliados/aliados.component";
import { EspeciesComponent } from "./especies/especies.component";
import { RevistasComponent } from "./revistas/revistas.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([Path.Login]);

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      { path: Path.comentariosAdmin, component: ComentariosComponent },
      { path: Path.usuariosAdmin, component: UsuariosComponent },
      { path: Path.estadisticasAdmin, component: EstadisticasComponent },
      { path: Path.publicidadAdmin, component: PublicidadComponent },
      { path: Path.facturacionAdmin, component: FacturacionComponent },
      { path: Path.Permisos, component: PermisosComponent },
      { path: Path.Aliados, component: AliadosComponent },
      { path: Path.Especies, component: EspeciesComponent },
      { path: Path.Revistas, component: RevistasComponent },
      {
        path: "**",
        redirectTo: Path.publicidadAdmin /* Path.usuariosAdmin*/,
        pathMatch: "full",
      },
    ],
    canActivate: mapToCanActivate([AuthGuard, AccessGuard]),
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      allowedUserTypes: [UserType.Administrador],
    },
  },
  {
    path: "**",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
