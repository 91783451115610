import { Injectable } from "@angular/core";
import { StoreService } from "src/app/core/service/store.service";
import { WtUserState } from "../model/wt-user.state";
1;

@Injectable({
  providedIn: "root",
})
export class WtUserStore extends StoreService<WtUserState> {
  public store: string =
    "wt-user-store" + "-" + Date.now().toString().slice(-3);

  constructor() {
    super({
      collection: [],
    });
  }
}
