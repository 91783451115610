<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-bottom">
  <div class="row">
    <a
      class="col-4"
      [routerLink]="[path.Home] | langRoute | async"
      routerLinkActive="active"
    >
      <img class="img-menu" src="../../assets/imagenes/home-solid.svg" />
      <h5 class="title-nav">{{ "nav-bar.inicio" | translate }}</h5>
    </a>
    <div class="contenedor" *ngIf="user && userIsActive; else login">
      <a
        class="columna"
        [routerLink]="[path.CarroCompras] | langRoute | async"
        routerLinkActive="active"
      >
        <img
          class="img-menu"
          src="../../assets/imagenes/shopping-cart-solid.svg"
        />

        <P class="title-nav">{{ "nav-bar.compras" | translate }}</P>
      </a>

      <a class="columna" (click)="seleccionPerfil()" routerLinkActive="active">
        <img class="img-menu" src="../../assets/imagenes/user-solid.svg" />
        <P class="title-nav">
          {{ "nav-bar.bienvenido" | translate }}
        </P>
      </a>
    </div>
    <ng-template #login>
      <a
        class="col-4"
        [routerLink]="[path.Auth, path.SeleccionDeRegistro] | langRoute | async"
        routerLinkActive="active"
      >
        <img
          class="img-menu"
          src="../../assets/imagenes/pen-square-solid.svg"
        />
        <h5 class="title-nav">{{ "nav-bar.registrate" | translate }}</h5>
      </a>

      <a
        class="col-4"
        [routerLink]="[path.Auth, path.Login] | langRoute | async"
        routerLinkActive="active"
      >
        <img class="img-menu" src="../../assets/imagenes/user-solid.svg" />
        <h5 class="title-nav">{{ "nav-bar.ingresar" | translate }}</h5>
      </a>
    </ng-template>
  </div>
</nav>
