<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ "noticias.modalTitle" | translate }}
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <h5 class="col-12 text-center">
          {{ "noticias.modalInicio" | translate }}
        </h5>
        <div class="col-12 text-center">
          <button
            class="btn btn-success"
            [routerLink]="[path.Auth, path.Login] | langRoute | async"
            (click)="closeEvent.next(true)"
          >
            {{ "login.iniciarSesion" | translate }}
          </button>
        </div>
      </div>
      <div class="row mt-2">
        <h5 class="col-12 text-center">
          {{ "noticias.modalRegistro" | translate }}
        </h5>
        <div class="col-12 text-center">
          <button
            class="btn btn-warning"
            [routerLink]="
              [path.Auth, path.SeleccionDeRegistro] | langRoute | async
            "
            (click)="closeEvent.next(true)"
          >
            {{ "nav-bar.registrate" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
