import { Injectable } from "@angular/core";
import {
  DocumentData,
  DocumentReference,
  increment,
} from "@angular/fire/firestore";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { DatosGenerales } from "../models/datosGenerales.interface";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CotizacionService {
  constructor(private firebaseRequest: FirebaseRequestsService) {}

  getDatosConsecutivo(): Observable<DatosGenerales> {
    return this.firebaseRequest.getDocFirebaseWithIdObservable<DatosGenerales>(
      "empresa/datosGenerales"
    );
  }

  async addCotizacion(data: any): Promise<DocumentReference> {
    try {
      const cotizacion: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "cotizaciones",
          data
        );
      await this.firebaseRequest.updateDocFirebase("empresa/datosGenerales", {
        consecutivoCotizaciones: increment(1),
      });

      return cotizacion;
    } catch (e: any) {
      console.error(e);
      throw new Error(e.message);
    }
  }

  async getCotizacionById(idcotizacion: string): Promise<DocumentData> {
    return await this.firebaseRequest.getDocFirebaseWithIdPromise<DocumentData>(
      `cotizaciones/${idcotizacion}`
    );
  }
}
