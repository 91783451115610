<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ encabezado }}</h4>
    <button type="button" class="btn btn-danger" (click)="close()" >X</button>

  </div>
  <div class="modal-body">

    <p >
        {{ cuerpo }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close(true)">SI</button>
    <button type="button" class="btn btn-danger" (click)="close()">NO</button>
</div>