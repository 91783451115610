import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CarroCompras } from "../models/CarroCompras";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  DocumentData,
  DocumentReference,
  arrayRemove,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class CarroComprasService {
  constructor(private firebaseRequests: FirebaseRequestsService) {}

  async addCarro(data: CarroCompras): Promise<string> {
    try {
      const response: DocumentReference<DocumentData> =
        await this.firebaseRequests.addDocFirebaseWithAutomaticId(
          "carritoCompras",
          data
        );
      return response.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  getCarroComprasById(id: string): Observable<CarroCompras> {
    return this.firebaseRequests.getDocFirebaseWithIdObservable<CarroCompras>(
      `carritoCompras/${id}`
    );
  }

  async deleteCarro(id: string): Promise<void> {
    return this.firebaseRequests.deleteDocFirebase(`carritoCompras/${id}`);
  }

  updateCarroInUser(idUser: string, idBorrar?: string): Promise<void> {
    if (!idBorrar) {
      throw new Error("invalid id");
    }

    return this.firebaseRequests.updateDocFirebase(`usuarios/${idUser}`, {
      carroCompras: arrayRemove(idBorrar),
    });
  }
}
