import { Component } from "@angular/core";
import { Subject } from "rxjs";
@Component({
  selector: "app-modal-confirmacion",
  templateUrl: "./modal-confirmacion.component.html",
  styleUrls: ["./modal-confirmacion.component.css"],
})
export class ModalConfirmacionComponent {
  private closeEvent: Subject<boolean | undefined> = new Subject<
    boolean | undefined
  >();
  encabezado!: string;
  cuerpo!: string;

  constructor() {}

  close(confirm?: boolean) {
    this.closeEvent.next(confirm);
  }
}
