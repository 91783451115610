import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Component({
  selector: "app-errores",
  templateUrl: "./errores.component.html",
  styleUrls: ["./errores.component.css"],
})
export class ErroresComponent {
  @Input() control!: AbstractControl | null;

  constructor() {}
}
