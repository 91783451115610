<div class="container-fluid">
  <div class="row">
    <!-- ********contenido**********-->
    <div class="col-12">
      <div *ngIf="producto?.especie">
        <h1>
          <!-- {{ "detalle-de-producto.especie" | translate }}: -->
          {{ producto.especie }}
        </h1>
      </div>

      <!-- columan de 4 espacios-->
      <div class="row" *ngIf="producto">
        <div class="col-lg-4 mt-5">
          <ngb-carousel>
            <ng-template ngbSlide *ngFor="let filePath of filePaths">
              <div class="picsum-img-wrapper">
                <img [src]="filePath" />
              </div>
            </ng-template>
          </ngb-carousel>
          <!-- botones de compartir en redes sociales-->
          <div>
            <h4 class="text-center mt-2">
              {{ "detalle-de-producto.comparteProducto" | translate }}
            </h4>
            <share-buttons
              theme="material-dark"
              [show]="4"
              [include]="['facebook', 'linkedin', 'telegram', 'whatsapp']"
              [url]="'{{ urlImageZero }}'"
              class="text-center"
            >
            </share-buttons>
          </div>
        </div>
        <!-- columan de 8 espacios-->
        <form class="col-lg-8 mt-3" [formGroup]="formUpdate">
          <fieldset disabled>
            <div class="form-group">
              <label class="label-info-product" for="precioProducto">
                {{ "detalle-de-producto.precioCop" | translate }}
                {{
                  "crear-producto." + producto.moneda | translate | titlecase
                }}
              </label>
              <input
                id="precioProducto"
                class="form-control"
                value="${{ producto.precio | number }}"
                oncopy="return false"
              />
            </div>
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label class="label-info-product" for="unidadesDisponibles">{{
                    "detalle-de-producto.cantidadDisponible" | translate
                  }}</label>
                  <input
                    id="unidadesDisponibles"
                    class="form-control"
                    value="{{ producto.disponibilidadVenta | number }}"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label class="label-info-product" for="unidadProducto">
                    {{ "detalle-de-producto.sistemaUnidades" | translate }}
                  </label>
                  <!--sistema de unidades del producto, traer de la base de datos-->
                  <input
                    id="unidadProducto"
                    type="text"
                    class="form-control"
                    value="{{
                      'crear-producto.' + producto.unidadMedida | translate
                    }}"
                  />
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="producto.cantidadMinimaVenta">
              <label class="label-info-product" for="seleccioneSistema">
                {{ "detalle-de-producto.cantidadMinimaVenta" | translate }}
              </label>
              <input
                id="seleccioneSistema"
                class="form-control"
                value="{{ producto.cantidadMinimaVenta | number }}"
              />
            </div>
          </fieldset>
          <div *ngIf="producto?.idVendedor !== user?.uid" class="form-group">
            <label class="label-info-product" for="unidadesaComprar">
              {{ "detalle-de-producto.unidadesAComprar" | translate }}
            </label>
            <input
              type="number"
              id="unidadesaComprar"
              name="unidades"
              class="form-control"
              formControlName="unidades"
              min="0"
            />
          </div>
          <fieldset disabled>
            <div class="form-group">
              <label class="label-info-product" for="total">{{
                "detalle-de-producto.total" | translate
              }}</label>
              <input
                readonly
                id="total"
                class="form-control"
                formControlName="total"
                hidden
              />
              <div>{{ formUpdate.get("total")?.value | currency }}</div>
            </div>
          </fieldset>
          <div *ngIf="producto?.idVendedor !== user?.uid" class="row">
            <div class="col-sm mt-2 text-center">
              <button
                *ngIf="user"
                [disabled]="!formUpdate.get('unidades')?.value || !user"
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#addProductCar"
              >
                {{ "detalle-de-producto.anadirCarro" | translate }}
              </button>
              <button
                *ngIf="user"
                [disabled]="!formUpdate.get('unidades')?.value || !user"
                type="button"
                class="btn btn-warning mx-1"
                data-bs-toggle="modal"
                data-bs-target="#solicitarProducto"
              >
                {{ "carro-compras.contactarVendedor" | translate }}
              </button>
              <button
                [disabled]="!user || (user && !permitirComprar)"
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#datosVendedor"
              >
                {{ "formularios.datosContacto" | translate }}
              </button>
              <app-limite-pedidos
                *ngIf="!permitirComprar && user"
              ></app-limite-pedidos>
            </div>
          </div>
          <div class="text-center" *ngIf="!user">
            <small class="small-text">{{
              "detalle-de-producto.debeEstarRegistrado" | translate
            }}</small>
          </div>
        </form>
      </div>

      <!-- ******* seller description information ****-->
      <form *ngIf="producto">
        <fieldset disabled>
          <h2 class="pt-3">
            {{ "detalle-de-producto.informacionProducto" | translate }}
          </h2>
          <div [ngSwitch]="producto.categoria" class="mt-1">
            <div class="form-group">
              <label>
                {{ "formularios.categoria" | translate }}
              </label>
              <input
                type="text"
                class="form-control"
                value="{{
                  'categorias-lista.' + producto.categoria | translate
                }}"
              />
            </div>
            <app-bosque
              *ngSwitchCase="'Bosque'"
              [visualizar]="true"
              #detailForm
            ></app-bosque>
            <app-arbol-en-pie
              *ngSwitchCase="'Árbol en pie'"
              [visualizar]="true"
              #detailForm
            ></app-arbol-en-pie>
            <app-madera-rolliza
              *ngSwitchCase="'Madera rolliza'"
              [visualizar]="true"
              #detailForm
            ></app-madera-rolliza>
            <app-madera-aserrada
              *ngSwitchCase="'Madera aserrada'"
              [visualizar]="true"
              #detailForm
            >
            </app-madera-aserrada>
            <app-insumos
              *ngSwitchCase="'Insumos'"
              [visualizar]="true"
              #detailForm
            ></app-insumos>
            <app-no-maderable
              *ngSwitchCase="'No maderable'"
              [visualizar]="true"
              #detailForm
              [categoria]="producto.categoria"
            ></app-no-maderable>
            <app-vivero
              [frutal]="false"
              *ngSwitchCase="'Vivero'"
              [visualizar]="true"
              #detailForm
            ></app-vivero>
            <app-vivero
              [frutal]="false"
              *ngSwitchCase="'Vivero ornamental'"
              [visualizar]="true"
              #detailForm
            >
            </app-vivero>
            <app-vivero
              [frutal]="true"
              *ngSwitchCase="'Vivero frutal'"
              [visualizar]="true"
              #detailForm
            ></app-vivero>
            <app-equipo-maquinaria
              *ngSwitchCase="'Equipo y Maquinaria'"
              [visualizar]="true"
              #detailForm
            >
            </app-equipo-maquinaria>
            <app-bonos-carbono
              *ngSwitchCase="'Bonos de carbono'"
              [visualizar]="true"
              #detailForm
            ></app-bonos-carbono>
            <app-producto-terminado
              *ngSwitchCase="'Producto terminado'"
              [visualizar]="true"
              #detailForm
            >
            </app-producto-terminado>
            <app-lands
              *ngSwitchCase="'Tierras para proyectos'"
              [visualizar]="true"
              #detailForm
            >
            </app-lands>
          </div>
        </fieldset>

        <!-- ******* Calificación y Comentarios sobre el vendedor ****-->
        <h2 class="pt-3">
          {{ "detalle-de-producto.calificacionComentarios" | translate }}
        </h2>
        <div class="form-group">
          <label class="label-info-product" for="calificacionVend">
            {{ "detalle-de-producto.calificacionVendedor" | translate }}
          </label>
          <br />
          <ngb-rating
            class="star"
            *ngIf="puntuacionTotal"
            [rate]="puntuacionTotal"
            [max]="5"
            [readonly]="true"
          >
          </ngb-rating>
          <p *ngIf="!puntuacionTotal">
            {{ "detalle-de-producto.sinCalificaciones" | translate }}
          </p>
        </div>
        <div id="accordion">
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  + {{ "detalle-de-producto.aquiPuedes" | translate }}
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              class="collapse hide"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordion"
            >
              <div class="card-body">
                <div class="table-responsive-lg">
                  <table class="table table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">
                          {{ "detalle-de-producto.comprador" | translate }}
                        </th>
                        <th scope="col">
                          {{ "detalle-de-producto.comentario" | translate }}
                        </th>
                        <th scope="col">
                          {{ "formularios.fecha" | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="contenido-tabla">
                      <tr *ngFor="let calif of calificaciones">
                        <td>
                          <img [src]="calif.imagen" class="foto-comentario" />
                        </td>
                        <td>
                          {{ calif.comentarios }}
                        </td>
                        <td>
                          {{
                            calif.tiempoCreado
                              | timestampToString: "es" : "dd/MM/yyyy"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ********Preguntas para el vendedor**********-->
        <div id="accordion" class="mt-3">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  class="btn btn-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  + {{ "preguntas.hazPregunta" | translate }}
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse hide"
              aria-labelledby="headingOne"
              data-bs-parent="#accordion"
            >
              <app-preguntas
                [idProducto]="idProducto"
                [idVendedor]="producto.idVendedor"
                [panelCompleto]="true"
              >
              </app-preguntas>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<app-btn-registro></app-btn-registro>
<!--modal, ventana emergente para verificar añadir al carro de compras-->
<div
  class="modal fade"
  id="addProductCar"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "detalle-de-producto.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        {{ "detalle-de-producto.seguroAnadir" | translate }}
      </div>
      <div class="modal-footer">
        <button
          #closeButton
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          (click)="addFormCarro()"
          #closeButton
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--modal, ventana emergente para contactar al vendedor-->
<div
  class="modal fade"
  id="solicitarProducto"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "carro-compras.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        {{ "carro-compras.srComprador" | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          #closeButtonSolicitar
        >
          {{ "formularios.cerrar" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="contactarVendedor()"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para ver datos del vendedor, solo debe verse cuando el usuario tiene una suscripción activa-->
<div
  class="modal fade"
  id="datosVendedor"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Información del Vendedor
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <fieldset disabled>
          <div class="form-group">
            <label class="label-info-product" for="nombreVendedor">
              Nombre:
            </label>
            <input
              type="text"
              id="nombreVendedor"
              name="nombreVendedor"
              class="form-control"
              value="{{ nombreVendedor }}"
            />
          </div>
          <div class="form-group">
            <label class="label-info-product" for="nombreVendedor">
              Correo:
            </label>
            <input
              type="email"
              id="nombreVendedor"
              name="nombreVendedor"
              class="form-control"
              value="{{ emailVendedor }}"
            />
          </div>
          <div class="form-group">
            <label class="label-info-product" for="nombreVendedor">
              Teléfono:
            </label>
            <input
              type="number"
              id="nombreVendedor"
              name="nombreVendedor"
              class="form-control"
              value="{{ telefonoVendedor }}"
            />
          </div>
        </fieldset>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          #closeButtonSolicitar
        >
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
