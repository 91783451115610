import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GetImage } from "./pipes/get-image.pipe";
import { FiltroPipe } from "./pipes/filtro.pipe";
import { GetFilePipe } from "./pipes/get-file.pipe";
import { LangRoutePipe } from "./pipes/lang-route.pipe";
import { OnlynumberDirective } from "../application-directives/only-numbers.directive";
import { TimestampToStringPipe } from "./pipes/timestamp-to-string.pipe";
import { TransformarApellidosPipe } from "./pipes/transformar-apellidos.pipe";
import { PorcentajeRoundPipe } from "./pipes/porcentaje.pipe";

const pipes = [
  GetImage,
  FiltroPipe,
  GetFilePipe,
  LangRoutePipe,
  TimestampToStringPipe,
  TransformarApellidosPipe,
  PorcentajeRoundPipe,
];
const directives = [OnlynumberDirective];
@NgModule({
  declarations: [...pipes, ...directives],
  imports: [CommonModule],
  exports: [...pipes, ...directives],
})
export class ApplicationPipesModule {}
