import { Component, Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { Path } from "../../enums/Path";

import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../../state/authentication-state";
import { takeUntil, tap } from "rxjs/operators";
import "firebase/firestore";
import { User } from "@angular/fire/auth";
import { ProductoService } from "src/app/services/producto.service";
import { SubscriptionService } from "src/app/services/subscriptions.service";
import { SuscripcionActiva } from "src/app/models/subscripcion.interface";
import { ProductoData } from "src/app/models/ProductoData";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-crear-producto",
  templateUrl: "./crear-producto.component.html",
  styleUrls: ["./crear-producto.component.css"],
})
export class CrearProductoComponent implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  private user: User | null = null;
  @Select(AuthenticationState.user)
  public user$!: Observable<AuthenticationStateModel["user"]>;
  public estadoSuscripcion!: boolean;

  public files: {
    [fileName: string]: {
      file: File;
      url: string | ArrayBuffer;
      pos: number;
    };
  } = {};
  public isOverDrop: boolean = false;
  public downloadURL: number = 0;
  public loading: boolean = false;
  public productosMes: number = 0;
  public mostrarSelect!: string;
  public path: typeof Path = Path;
  public numeroProductosUser!: number;

  // Sección Mis Productos
  public filePaths = {};

  public datosInteres!: string;
  public disponibilidadVenta: any;

  constructor(
    private router: Router,
    private suscripcionSvc: SubscriptionService,
    public productservice: ProductoService
  ) {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (value: User | null) => {
            this.user = value;
            value && this.getSubscripcion(value.uid);
          },
          error: (error: any) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  onSelectCategory(value: string): void {
    if (value) {
      this.mostrarSelect = value;
    }
  }

  redireccionarSuscripcion(): void {
    this.router.navigate([Path.Proyecto]);
  }

  getSubscripcion(id: string): void {
    if (id) {
      this.suscripcionSvc
        .getActiveSubscription(id)
        .then((r: SuscripcionActiva) => {
          this.estadoSuscripcion = r?.suscripcionActual ? true : false;
          if (!this.estadoSuscripcion) {
            this.traerProductosMes(id);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  async traerProductosMes(idComprador: string): Promise<void> {
    const productosMes: ProductoData[] =
      await this.productservice.traerProductosMes(idComprador);
    this.productosMes = productosMes.length;
  }
}
