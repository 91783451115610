<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Fecha Creación</th>
        <th scope="col">Título</th>
        <th scope="col">Descripción</th>
        <th scope="col">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let revista of revistas | async; index as i">
        <th scope="row">
          {{ timestampToDate(revista.fechaCreacion) | date: "medium" }}
        </th>
        <td>
          {{ revista.titulo | titlecase }}
        </td>
        <td>
          {{ revista.descripcion }}
        </td>
        <td>
          <button
            type="button"
            class="btn btn-guardar m-1"
            (click)="abrirPdf(revista)"
          >
            Ver
          </button>
          <button
            type="button"
            class="btn btn-warning m-1"
            (click)="seleccionarRevistaEditar(revista)"
          >
            Editar
          </button>
          <button
            type="button"
            class="btn btn-danger m-1"
            (click)="openDeleteModal(revista)"
          >
            Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
