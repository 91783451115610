import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-certificacion",
  templateUrl: "./certificacion.component.html",
  styleUrls: ["./certificacion.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class CertificacionComponent {
  constructor() {}
}
