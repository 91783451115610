<!--------Filtro de la Búsqueda para PC------------------>
<div class="caja-filtro mb-2 pb-2" [formGroup]="formFiltro">
  <div class="filtro">
    <label class="texto-filtro" for="categoriaMenu" selected>{{
      "formularios.categoria" | translate
    }}</label
    ><br />
    <select
      name="Categoria"
      class="form-select"
      aria-label="Default select example"
      id="categoriaMenu"
      formControlName="categoria"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option [value]="cat" *ngFor="let cat of categorias">
        {{ "categorias-lista." + cat | translate }}
      </option>
    </select>
  </div>
  <!--Filtro subcategoría producto terminado-->
  <div
    class="filtro"
    *ngIf="formFiltro.get('categoria')?.value === 'Producto terminado'"
  >
    <label class="texto-filtro" for="productoNoMaderable">{{
      "catalogo.product" | translate
    }}</label>
    <select
      name="producto"
      class="form-select"
      aria-label="Default select example"
      id="productoNoMaderable"
      formControlName="producto"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option
        *ngFor="let producto of listaProductoMobiliario"
        [value]="producto"
      >
        {{ "crear-producto." + producto | translate }}
      </option>
    </select>
  </div>
  <!--Filtro subcategoría producto equipos-->
  <div
    class="filtro"
    *ngIf="formFiltro.get('categoria')?.value === 'Equipo y Maquinaria'"
  >
    <label class="texto-filtro" for="productoNoMaderable">{{
      "catalogo.product" | translate
    }}</label>
    <select
      name="producto"
      class="form-select"
      aria-label="Default select example"
      id="productoNoMaderable"
      formControlName="producto"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option *ngFor="let producto of listaProductoEquipos" [value]="producto">
        {{ "crear-producto." + producto | translate }}
      </option>
    </select>
  </div>
  <!--Filtro subcategoría producto insumos-->
  <div class="filtro" *ngIf="formFiltro.get('categoria')?.value === 'Insumos'">
    <label class="texto-filtro" for="productoNoMaderable">{{
      "catalogo.product" | translate
    }}</label>
    <select
      name="producto"
      class="form-select"
      aria-label="Default select example"
      id="productoNoMaderable"
      formControlName="producto"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option *ngFor="let producto of listaProductoInsumos" [value]="producto">
        {{ "crear-producto." + producto | translate }}
      </option>
    </select>
  </div>
  <!--Filtro subcategoría productos no maderables-->
  <div
    class="filtro"
    *ngIf="formFiltro.get('categoria')?.value === 'No maderable'"
  >
    <label class="texto-filtro" for="productoNoMaderable">{{
      "catalogo.product" | translate
    }}</label>
    <select
      name="producto"
      class="form-select"
      aria-label="Default select example"
      id="productoNoMaderable"
      formControlName="producto"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option
        *ngFor="let producto of listaProductoNoMaderable"
        [value]="producto"
      >
        {{ "crear-producto." + producto | translate }}
      </option>
    </select>
  </div>
  <!--Filtro país-->
  <div class="filtro">
    <label class="texto-filtro" for="pais">{{
      "formularios.pais" | translate
    }}</label
    ><br />
    <select
      #selectPais
      id="pais"
      type="text"
      name="pais"
      class="form-select"
      aria-label="Default select example"
      formControlName="pais"
      (change)="onSelectPais(selectPais.value)"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option *ngFor="let pais of listaPaises" [value]="pais">
        {{ pais }}
      </option>
    </select>
  </div>
  <!--Filtro departamento-->
  <div class="filtro">
    <label class="texto-filtro" for="seleccioneDepartamento">
      {{ "formularios.departamento" | translate }}</label
    >
    <select
      name="departamento"
      id="seleccioneDepartamento"
      class="form-select"
      aria-label="Default select example"
      formControlName="departamento"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option
        *ngFor="let departamento of listaDepartamentos"
        [value]="departamento"
      >
        {{ departamento }}
      </option>
    </select>
  </div>
  <!--Filtro especie-->
  <div class="filtro">
    <label class="texto-filtro" for="categoriaMenu">{{
      "formularios.especie" | translate
    }}</label
    ><br />
    <select
      class="form-select"
      aria-label="Default select example"
      id="categoriaMenu"
      formControlName="especie"
    >
      <option value="" selected>
        {{ "catalogo.seleccioneCategoria" | translate }}
      </option>
      <option [value]="especie.nombre" *ngFor="let especie of especieOpciones">
        {{ especie.nombre }}
      </option>
    </select>
  </div>
</div>
