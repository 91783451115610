import { User } from "@angular/fire/auth";
import { RegistroFiles } from "../../models/RegistroFiles";
import { UserData } from "src/app/services/user.service";
import { ModalRegistroLoadingService } from "src/app/services/modal-registro-loading.service";

export class LogIn {
  static readonly type: string = "AUTHORIZATION/LOGIN";
  constructor(public payload: { user: User; userIsNew?: boolean }) {}
}

export class LogOut {
  static readonly type: string = "AUTHORIZATION/LOGOUT";
}

export class CreateUser {
  static readonly type: string = "AUTHORIZATION/CREAT_USER";
  constructor(
    public payload: {
      email: string;
      contraseña: string;
      data: UserData;
      files: RegistroFiles;
      onlyRegister: boolean;
      modalService: ModalRegistroLoadingService;
    }
  ) {}
}
