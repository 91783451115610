import { Pipe, PipeTransform } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Pipe({
  name: "getImage",
})
export class GetImage implements PipeTransform {
  private defaultThumbnailUrl: string = "assets/imagenes/noImage.png";

  transform(value: Observable<string> | undefined, defaultValue?: string) {
    if (!value) return of(defaultValue);

    return value.pipe(
      catchError(() => {
        return of(defaultValue || this.defaultThumbnailUrl);
      })
    );
  }
}
